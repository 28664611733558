import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Icon from "../../../../components/Icon";
import { ActionButton, NewButton } from "./styles";

import EditModal from "./components/EditModal";
import NewModal from "./components/NewModal";
import DeleteModal from "./components/DeleteModal";

import PlusIcon from "../../../../assets/icons/plus.svg";
import DeleteUserIcon from "../../../../assets/icons/delete.svg";
import EditUserIcon from "../../../../assets/icons/edit.svg";
import {
  getAllCategories,
  setSelectedCategory,
} from "../../../../store/actions/category";
import { CategoryDto } from "../../../../types/category.dto";
import { showModal } from "../../../../store/actions/modal";
import Loader from "../../../../components/LogoLoader";

interface ComponentProps {
  categories: CategoryDto[];
}

const Component = ({ categories }: ComponentProps) => {
  const [activeCategories, setActiveCategories] = useState<
    CategoryDto[] | null
  >(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  useEffect(() => {
    if (categories) {
      setActiveCategories(categories.filter((category) => category.active));
    }
  }, [categories]);

  const handleEdit = (row, index) => {
    dispatch(setSelectedCategory(row));
    dispatch(showModal("edit-category-modal"));
  };

  const handleDelete = (row, index) => {
    dispatch(setSelectedCategory(row));
    dispatch(showModal("delete-category-modal"));
  };

  const handleNew = (row, index) => {
    dispatch(showModal("new-category-modal"));
  };

  const columns = [
    {
      with: "100%",
      minWidth: "600px",
      name: "Nombre",
      selector: (row) => row.name,
    },
    {
      right: true,
      name: "",
      cell: (row, index) => {
        const Edit = () => (
          <ActionButton type="button" onClick={() => handleEdit(row, index)}>
            <Icon icon={EditUserIcon} color="#1673e5" size="18px" />
          </ActionButton>
        );

        const Delete = () => (
          <ActionButton type="button" onClick={() => handleDelete(row, index)}>
            <Icon icon={DeleteUserIcon} color="#fd433a" size="18px" />
          </ActionButton>
        );

        return (
          <>
            <Edit />
            <Delete />
          </>
        );
      },
    },
  ];

  const handleRowClicked = (data) => {};

  if (!activeCategories) {
    return <Loader />;
  }

  return (
    <>
      <NewButton onClick={handleNew}>
        <Icon icon={PlusIcon} size="14px" color="#1573e5" />
      </NewButton>

      <DataTableExtensions
        filterDigit={0}
        export={false}
        print={false}
        filterPlaceholder="Buscar"
        columns={columns}
        data={activeCategories}
      >
        <DataTable
          pagination
          disabled={!activeCategories}
          columns={columns}
          data={activeCategories}
          noDataComponent={<p>Sin Elementos</p>}
          onRowClicked={(row) => handleRowClicked(row)}
        />
      </DataTableExtensions>

      <EditModal />
      <NewModal />
      <DeleteModal />
    </>
  );
};

const states = ({ categoryStore }) => {
  const { data: categories } = categoryStore.allCategories;

  return {
    categories,
  };
};
export default connect(states)(Component);
