import React, { useEffect, useState } from "react";
import {
  PlayerDescriptionCardTitle,
  PlayerDescriptionDataText,
  PlayerDescriptionDropDown,
  PlayerDescriptionEntityImage,
  PlayerDescriptionParagh,
  PlayerDescriptionText,
  PlayerDescriptionWrapper,
  ShowMore,
} from "./style";
import { FlexContainer, Line, Separator } from "../StyledComponents";
import CommentSection from "../CommentSection";
import {
  AiFillClockCircle,
  AiFillDislike,
  AiFillEye,
  AiOutlineCheck,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";
import { GridComment } from "../Layout/Dashboard/styles";
import { DateFromNow } from "../../helpers/format-date";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addClick } from "../../store/actions/userTimes";
import { Img } from "../../pages/SignIn/styles";
import { overThousandFixed } from "../../helpers/number-fixed";
import ShareLink from "../ShareLink";
import {
  LikeDislikeBox,
  LikeDislikeBoxNumber,
  LikeDislikeText,
} from "../../pages/Cohort/Home/style";
import Button from "../Button";
import { AcademyDto } from "../../types/academy.dto";
import academyActions from "../../store/actions/academies";
import { showModal } from "../../store/actions/modal";
import { useTheme } from "styled-components";
import { toast } from "react-toastify";
import ModalConfirmDelete from "../ModalConfirmDelete";

const Component = ({
  content,
  user,
  academy,
  updatedAcademy,
  updateAcademyStates,
  manageDislikeLikeContent,
  currentContentAcademy,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [action, setAction] = useState<{ action: string; data: any }>();
  const [openDescription, setOpenDescription] = useState<boolean>(false);

  return (
    <>
      <PlayerDescriptionWrapper>
        <PlayerDescriptionCardTitle overflowLine={1}>
          {content.name}
        </PlayerDescriptionCardTitle>
        <Separator size={8}></Separator>
        <FlexContainer gap="3px" align="center">
          <FlexContainer gap="3px" align="center">
            <AiFillEye fill="#6a6970"></AiFillEye>
            <PlayerDescriptionParagh display="inline-block">
              {content.viewCount}
            </PlayerDescriptionParagh>
          </FlexContainer>

          <span>|</span>
          <FlexContainer gap="3px" align="center">
            <AiFillClockCircle fill="#6a6970"></AiFillClockCircle>
            <GridComment>{DateFromNow(content.createdAt)}</GridComment>
          </FlexContainer>
        </FlexContainer>
        <Separator size={11}></Separator>
        <FlexContainer
          justify="space-between"
          align="center"
          gap="2px"
          wrap="wrap"
        >
          <FlexContainer gap="8px">
            <PlayerDescriptionEntityImage
              onClick={() => {
                dispatch(
                  addClick({
                    id: content.academy._id,
                    user: user._id,
                    type: "ACADEMY",
                  })
                );
                navigate(`/dashboard/academy/${content.academy._id}`);
              }}
            >
              <Img src={content.academy.picture} />
            </PlayerDescriptionEntityImage>
            <FlexContainer align="center" gap="6px">
              <PlayerDescriptionCardTitle style={{ fontSize: "12px" }}>
                {content.academy.name}
              </PlayerDescriptionCardTitle>
              <span>|</span>
              <PlayerDescriptionDataText>
                {content.academy.userCount
                  ? overThousandFixed(content.academy.userCount)
                  : "0 usuarios"}
              </PlayerDescriptionDataText>
            </FlexContainer>

            {currentContentAcademy ? (
              !currentContentAcademy.users.find(
                (userF) => userF.user === user._id && !userF.roleEnd
              ) ? (
                !currentContentAcademy.private ? (
                  <Button
                    type="button"
                    onClick={() => {
                      dispatch(
                        academyActions.addMember({
                          _id: currentContentAcademy._id,
                          user: user._id,
                          role: "STUDENT",
                        })
                      );
                      dispatch(
                        addClick({
                          id: currentContentAcademy._id,
                          user: user._id,
                          type: "ACADEMY",
                        })
                      );
                    }}
                    options={{
                      size: "md",
                      type: "filled",
                      skin: "lightblue",
                    }}
                    loading={updateAcademyStates.loading}
                  >
                    Suscribirse
                  </Button>
                ) : (
                  <></>
                )
              ) : (
                <Button
                  type="button"
                  onClick={() => {
                    setAction({
                      data: null,
                      action: "out-of-academy",
                    });
                    dispatch(showModal("confirm-delete-item-secuencial-modal"));
                    dispatch(
                      addClick({
                        id: currentContentAcademy._id,
                        user: user._id,
                        type: "ACADEMY",
                      })
                    );
                  }}
                  options={{
                    size: "md",
                    type: "outline",
                    skin: "lightblue",
                  }}
                  loading={updateAcademyStates.loading}
                >
                  <FlexContainer gap="4px" align="center">
                    <AiOutlineCheck
                      size={14}
                      color={theme.colors.LightBlue}
                    ></AiOutlineCheck>
                    <span>Suscripto</span>
                  </FlexContainer>
                </Button>
              )
            ) : (
              <></>
            )}
          </FlexContainer>
          <FlexContainer gap="5px">
            <FlexContainer gap="12px" align="center">
              <LikeDislikeBox
                className={
                  !!content.likes.find((like) => like.user === user._id)
                    ? "active"
                    : ""
                }
                onClick={() => {
                  if (content.likes.find((like) => like.user === user._id)) {
                    dispatch(
                      manageDislikeLikeContent({
                        _id: content._id,
                        condition: "like",
                        action: "remove",
                      })
                    );
                  } else {
                    dispatch(
                      manageDislikeLikeContent({
                        _id: content._id,
                        condition: "like",
                        action: "add",
                      })
                    );
                  }
                }}
              >
                <FlexContainer gap="3px" align="center">
                  <div
                    className="like-dislike-icon"
                    style={{ background: "#fff", padding: "4px" }}
                  >
                    <AiOutlineLike size={15} fill={"#2496ed"} />
                  </div>
                  <LikeDislikeBoxNumber className="count-likes">
                    <LikeDislikeText>
                      {overThousandFixed(content.likes.length)}
                    </LikeDislikeText>
                  </LikeDislikeBoxNumber>
                </FlexContainer>
              </LikeDislikeBox>

              <LikeDislikeBox
                className={
                  !!content.dislikes.find((like) => like.user === user._id)
                    ? "active"
                    : ""
                }
                onClick={() => {
                  if (content.dislikes.find((like) => like.user === user._id)) {
                    dispatch(
                      addClick({
                        id: content._id,
                        user: user._id,
                        type: "CONTENT",
                      })
                    );
                    dispatch(
                      manageDislikeLikeContent({
                        _id: content._id,
                        condition: "dislike",
                        action: "remove",
                      })
                    );
                  } else {
                    dispatch(
                      addClick({
                        id: content._id,
                        user: user._id,
                        type: "CONTENT",
                      })
                    );
                    dispatch(
                      manageDislikeLikeContent({
                        _id: content._id,
                        condition: "dislike",
                        action: "add",
                      })
                    );
                  }
                }}
              >
                <FlexContainer gap="3px" align="center">
                  <div
                    className="like-dislike-icon"
                    style={{
                      padding: "7px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {content.dislikes.find((like) => like.user === user._id) ? (
                      <AiFillDislike fill={"#fff"} size={15}></AiFillDislike>
                    ) : (
                      <AiOutlineDislike
                        fill={"#2496ed"}
                        size={15}
                      ></AiOutlineDislike>
                    )}
                  </div>
                </FlexContainer>
              </LikeDislikeBox>

              <ShareLink />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <Separator size={10.1}></Separator>
        <Line height={1}></Line>
        <Separator size={10}></Separator>
        <PlayerDescriptionDropDown active={openDescription}>
          <ShowMore
            onClick={() => {
              setOpenDescription((state) => !state);
            }}
          >
            {openDescription ? "Ver menos" : "...Ver más"}
          </ShowMore>
          <PlayerDescriptionText>{content.description}</PlayerDescriptionText>
        </PlayerDescriptionDropDown>
        <Separator size={10}></Separator>
        <CommentSection itemId={content._id} section="content" />
      </PlayerDescriptionWrapper>
      <Separator size={20}></Separator>
      {currentContentAcademy && action?.action === "out-of-academy" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Desuscribirse",
            style: {
              color: "Danger",

              style: {
                width: "150px",
                height: "31px",
              },
              options: {
                type: "filled",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={updateAcademyStates}
          elementActions={academyActions.deleteMember({
            _id: currentContentAcademy._id,
            userId: user._id,
          })}
          onClose={() => {
            setAction(undefined);
          }}
          title={
            <FlexContainer direction="column" gap="11px">
              <span>
                {`¿Seguro quieres salir de la academia `}
                <span style={{ color: "#000" }}>
                  {currentContentAcademy.name}
                </span>
                ?
              </span>
              <p>
                Perderás todos los accesos y suscripciones relacionados a esta
                Academia y los contenidos que no hayas descargado.
              </p>
            </FlexContainer>
          }
          /*  resetState={() => {
            setAction(undefined);
          }} */
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({ userStore, academyStore }) => {
  const { data: user } = userStore;
  const { data: academy } = academyStore.selected;
  const { data: updatedAcademy, states: updateAcademyStates } =
    academyStore.update;
  return {
    user,
    academy,
    updatedAcademy,
    updateAcademyStates,
  };
};

export default connect(states)(Component);
