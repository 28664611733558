import styled from "styled-components";

export const FullScreenWrapper = styled("div")`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 20000;
  background-color: #051725;
  padding: 14px;
`;

export const Wrapper = styled("div")`
  position: relative;
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

export const CurrentSlide = styled("div")`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  margin: 0px;

  display: flex;
  justify-content: center;
  flex: 1;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  width: fit-content;
  padding: 5px;
  z-index: 2;
`;
export const Buttons = styled("div")`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ButtonArrow = styled("button")`
  position: absolute;
  padding: 0px;
  border: 0px;
  background: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #2496ed;
  display: flex;
  align-items: center;
  box-shadow: 0 0 4px 0 #fff;
  background-color: #051725;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  &.left {
    top: 50%;
    left: -50px; /* Left side of the container */
    transform: translateY(-50%); /* Center vertically */
  }
  &.right {
    top: 50%;
    right: -50px; /* Left side of the container */
    transform: translateY(-50%); /* Center vertically */
  }
  &:hover {
    background-color: #2496ed;
  }
`;
