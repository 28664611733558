import React, { useState } from "react";
import { FlexContainer } from "../../../../../../components/StyledComponents";

import InsideModal from "../../../../../../components/InsideModal";
import { ModalInsideText, ModalLink } from "./style";
import { useTheme } from "styled-components";
import { BiLink } from "react-icons/bi";
const Component = ({ node, isVisible, setIsVisible, setSelectedNode }) => {
  const theme = useTheme();
  return (
    <InsideModal
      width={"100%"}
      animation="fadeInSlideRight"
      name="node-edit"
      title={`${node.data.label}`}
      padding={"11px 5px 30px 5px"}
      position={"right"}
      styles={{
        position: "absolute",
        width: "400px",
        top: 0,
        bottom: 0,
        right: 0,
        display: isVisible ? "block" : "none",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      }}
      height={"100%"}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      onClose={() => {
        setSelectedNode(null);
      }}
    >
      <ModalInsideText>{node.data.label}</ModalInsideText>
      <FlexContainer direction="column" gap="10px">
        {node.urls?.map((url) => {
          return (
            <ModalLink href={url?.url} target="_autoblank">
              <FlexContainer gap="6px" align="center">
                <BiLink color={theme.colors.LightBlue} size={15}></BiLink>
                <ModalInsideText style={{ color: theme.colors.LightBlue }}>
                  {url?.url}
                </ModalInsideText>
              </FlexContainer>
            </ModalLink>
          );
        })}
      </FlexContainer>
    </InsideModal>
  );
};

export default Component;
