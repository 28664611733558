import { useMemo } from "react";
import { CardsGrid, EmptyStateCard, EmptyStateCardBackground } from "./styles";
import Icon from './components/Icon'
import { IconsTypes } from './components/Icon'

interface ComponentProps {
  itemsPerPage: number
  icon?: IconsTypes
  itemsPerRow: number
  size?: {
    width?: number,
    height?: number
  }
  animated?: boolean,
  spaceBetweenItems?: number
}

const Component = ({ itemsPerPage, icon, ...rest }: ComponentProps) => {
  const Elements = useMemo(() => {
    return Array.from({ length: itemsPerPage }, (_, index) =>
      <EmptyStateCard key={index} {...rest}>
        <EmptyStateCardBackground>
          <Icon icon={icon} />
        </EmptyStateCardBackground>
      </EmptyStateCard>
    );
  }, [itemsPerPage, icon])

  return (
    <CardsGrid {...rest}>
      {Elements}
    </CardsGrid>
  );
};

export default Component;
