import {
  GetAllSlideShows,
  GET_ALL_SLIDESHOWS,
  GetSlideShow,
  GET_SLIDESHOW,
  UpdateSlideShow,
  UPDATE_SLIDESHOW,
  DeleteSlideShow,
  DELETE_SLIDESHOW,
  GetSlideShowSuccess,
  GET_SLIDESHOW_SUCCESS,
  NewSlideShow,
  NEW_SLIDESHOW,
  ResetNewSlideShow,
  RESET_NEW_SLIDESHOW,
  ResetUpdateSlideShow,
  RESET_UPDATE_SLIDESHOW,
  ResetDeleteSlideShow,
  RESET_DELETE_SLIDESHOW,
  UploadMedia,
  UPLOAD_MEDIA,
  RESET_GET_SLIDESHOW,
  ADD_USER_TO_SLIDESHOW,
  SHARED_SLIDESHOW,
  GET_SLIDESHOWS_OFFSET,
  GetSlideShowsOffset,
  RESET_GET_SLIDESHOWS_OFFSET,
  ResetGetSlideShowsOffset,
  AddClickSlideShow,
  ADD_CLICK_SLIDESHOW,
  QUERY_SLIDESHOW,
  MANAGE_LIKE_DISLIKE_SLIDESHOW,
  SAVED_SLIDESHOW,
  AddViewSlideshow,
  ADD_VIEW_SLIDESHOW,
  RESET_GET_ALL_SLIDESHOW_DATA,
  RESET_GET_ONE_SLIDESHOW_DATA,
  UPDATE_ITEM_LIST_SLIDESHOW,
} from "../types/slideshow";

export const getAllSlideShows = (payload): GetAllSlideShows => {
  return { type: GET_ALL_SLIDESHOWS, payload };
};

export const updateSlideShowListItem = (payload) => {
  return { type: UPDATE_ITEM_LIST_SLIDESHOW, payload };
};

export const getSlideShow = (id): GetSlideShow => {
  return { type: GET_SLIDESHOW, payload: id };
};

export const resetGetSlideShow = () => {
  return { type: RESET_GET_SLIDESHOW, payload: null };
};
export const querySlideShow = (payload) => {
  return { type: QUERY_SLIDESHOW, payload };
};

export const setSelectedSlideShow = (payload): GetSlideShowSuccess => {
  return { type: GET_SLIDESHOW_SUCCESS, payload };
};

export const newSlideShow = (payload): NewSlideShow => {
  return { type: NEW_SLIDESHOW, payload };
};
export const uploadMediaSlideShow = (payload): UploadMedia => {
  return { type: UPLOAD_MEDIA, payload };
};

export const addClickSlideShow = (payload): AddClickSlideShow => {
  return { type: ADD_CLICK_SLIDESHOW, payload };
};

export const resetGetAllSlideShows = () => {
  return { type: RESET_GET_ALL_SLIDESHOW_DATA, payload: null };
};

export const resetGetOneSlideShow = () => {
  return { type: RESET_GET_ONE_SLIDESHOW_DATA, payload: null };
};

export const offSetSlideShows = (payload): GetSlideShowsOffset => {
  return { type: GET_SLIDESHOWS_OFFSET, payload };
};

export const resetOffsetSlideShows = (): ResetGetSlideShowsOffset => {
  return { type: RESET_GET_SLIDESHOWS_OFFSET, payload: null };
};
export const resetNewSlideShow = (): ResetNewSlideShow => {
  return { type: RESET_NEW_SLIDESHOW, payload: null };
};

export const updateSlideShow = (payload): UpdateSlideShow => {
  return { type: UPDATE_SLIDESHOW, payload };
};

export const resetUpdateSlideShow = (): ResetUpdateSlideShow => {
  return { type: RESET_UPDATE_SLIDESHOW, payload: null };
};

export const addViewSlideshow = (payload): AddViewSlideshow => {
  return { type: ADD_VIEW_SLIDESHOW, payload };
};
export const addUserToSlideShow = (payload) => {
  return { type: ADD_USER_TO_SLIDESHOW, payload };
};

export const deleteSlideShow = (payload): DeleteSlideShow => {
  return { type: DELETE_SLIDESHOW, payload };
};

export const resetDeleteSlideShow = (): ResetDeleteSlideShow => {
  return { type: RESET_DELETE_SLIDESHOW, payload: null };
};

export const shareSlideShow = (payload) => {
  return { type: SHARED_SLIDESHOW, payload };
};

export const manageLikeDislikeSlideshow = (payload) => {
  return { type: MANAGE_LIKE_DISLIKE_SLIDESHOW, payload };
};

export const savedSlideshow = (payload) => {
  return { type: SAVED_SLIDESHOW, payload };
};
