import {
  NewSuscription,
  NEW_SUSCRIPTION,
  ResetNewSuscription,
  RESET_NEW_SUSCRIPTION,
  GetAllSuscription,
  GET_ALL_SUSCRIPTION,
  ResetGetAllSuscription,
  RESET_GET_ALL_SUSCRIPTION,
  DELETE_SUSCRIPTION,
  DeleteSuscription,
  RESET_DELETE_SUSCRIPTION,
  ResetDeleteSuscription,
  UpdateSuscription,
  UPDATE_SUSCRIPTION,
  ResetUpdateSuscription,
  RESET_UPDATE_SUSCRIPTION,
  GetSuscription,
  GET_SUSCRIPTION,
  ResetGetSuscription,
  RESET_GET_SUSCRIPTION,
} from "../types/suscription";

const create = (payload): NewSuscription => {
  return { type: NEW_SUSCRIPTION, payload };
};
const resetCreate = (): ResetNewSuscription => {
  return { type: RESET_NEW_SUSCRIPTION, payload: null };
};

const getAll = (): GetAllSuscription => {
  return { type: GET_ALL_SUSCRIPTION, payload: null };
};

const resetGetAll = (): ResetGetAllSuscription => {
  return { type: RESET_GET_ALL_SUSCRIPTION, payload: null };
};

const deleteOne = (payload: { _id: string }): DeleteSuscription => {
  return { type: DELETE_SUSCRIPTION, payload };
};

const resetDeleteOne = (): ResetDeleteSuscription => {
  return { type: RESET_DELETE_SUSCRIPTION, payload: null };
};

const update = (payload): UpdateSuscription => {
  return { type: UPDATE_SUSCRIPTION, payload };
};

const getOne = (payload): GetSuscription => {
  return { type: GET_SUSCRIPTION, payload };
};

const resetGetOne = (): ResetGetSuscription => {
  return { type: RESET_GET_SUSCRIPTION, payload: null };
};

const resetUpdate = (): ResetUpdateSuscription => {
  return { type: RESET_UPDATE_SUSCRIPTION, payload: null };
};

const actions = {
  create,
  resetGetAll,
  update,
  resetCreate,
  resetDeleteOne,
  getAll,
  resetUpdate,
  deleteOne,
  getOne,
  resetGetOne,
};

export default actions;
