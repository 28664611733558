import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services/";
import {
  GET_ALL_TAGS,
  GET_ALL_TAGS_SUCCESS,
  GET_ALL_TAGS_ERROR,
  GetAllTags,
} from "../types/tag";

function* getAllTags({ payload }) {
  try {
    const { response, error } = yield call(services.tag.getAll, payload);

    if (error) {
      yield put({
        type: GET_ALL_TAGS_ERROR,
        payload: error.response.data?.message || "Error",
      });
    } else {
      yield put({ type: GET_ALL_TAGS_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_ALL_TAGS_ERROR,
      payload: "Error desconocido al listar Habilidades",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllTags>(GET_ALL_TAGS, getAllTags);
}
