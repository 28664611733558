import {
  GetAllBytes,
  GET_ALL_BYTES,
  GetByte,
  GET_BYTE,
  UpdateByte,
  UPDATE_BYTE,
  DeleteByte,
  DELETE_BYTE,
  GetByteSuccess,
  GET_BYTE_SUCCESS,
  NewByte,
  NEW_BYTE,
  ResetNewByte,
  RESET_NEW_BYTE,
  ResetUpdateByte,
  RESET_UPDATE_BYTE,
  ResetDeleteByte,
  RESET_DELETE_BYTE,
  UploadMedia,
  UPLOAD_MEDIA,
  RESET_GET_BYTE,
  ADD_USER_TO_BYTE,
  SHARED_BYTE,
  QUERY_BYTE,
  RESET_QUERY_BYTE,
  SaveByte,
  SAVE_BYTE,
  UNSAVED_BYTE,
  SAVED_BYTE,
  ADD_VIEW_BYTE,
  AddViewByte,
  ADD_CLICK_BYTE,
  MANAGE_LIKE_DISLIKE_BYTE,
  GET_BYTES_OFFSET,
  GetBytesOffset,
  RESET_GET_BYTES_OFFSET,
  ResetGetBytesOffset,
  SET_OFFSET_BYTE,
  RESET_GET_ALL_BYTE_DATA,
  RESET_GET_ONE_BYTE_DATA,
  UPDATE_ITEM_LIST_BYTE,
} from "../types/byte";

export const getAllBytes = (payload): GetAllBytes => {
  return { type: GET_ALL_BYTES, payload };
};

export const updateByteListItem = (payload) => {
  return { type: UPDATE_ITEM_LIST_BYTE, payload };
};

export const getByte = (id): GetByte => {
  return { type: GET_BYTE, payload: id };
};

export const saveByte = (payload): SaveByte => {
  return { type: SAVE_BYTE, payload };
};

export const unSavedByte = (payload) => {
  return { type: UNSAVED_BYTE, payload };
};
export const savedByte = (payload) => {
  return { type: SAVED_BYTE, payload };
};

export const resetGetAllBytes = () => {
  return { type: RESET_GET_ALL_BYTE_DATA, payload: null };
};

export const resetGetOneByte = () => {
  return { type: RESET_GET_ONE_BYTE_DATA, payload: null };
};

export const resetGetByte = () => {
  return { type: RESET_GET_BYTE, payload: null };
};

export const setSelectedByte = (payload): GetByteSuccess => {
  return { type: GET_BYTE_SUCCESS, payload };
};

export const newByte = (payload): NewByte => {
  return { type: NEW_BYTE, payload };
};
export const uploadMediaByte = (payload): UploadMedia => {
  return { type: UPLOAD_MEDIA, payload };
};
export const resetNewByte = (): ResetNewByte => {
  return { type: RESET_NEW_BYTE, payload: null };
};

export const updateByte = (payload): UpdateByte => {
  return { type: UPDATE_BYTE, payload };
};

export const resetUpdateByte = (): ResetUpdateByte => {
  return { type: RESET_UPDATE_BYTE, payload: null };
};

export const addUserToByte = (payload) => {
  return { type: ADD_USER_TO_BYTE, payload };
};

export const offSetBytes = (payload): GetBytesOffset => {
  return { type: GET_BYTES_OFFSET, payload };
};

export const resetOffsetBytes = (): ResetGetBytesOffset => {
  return { type: RESET_GET_BYTES_OFFSET, payload: null };
};

export const deleteByte = (payload): DeleteByte => {
  return { type: DELETE_BYTE, payload };
};

export const resetDeleteByte = (): ResetDeleteByte => {
  return { type: RESET_DELETE_BYTE, payload: null };
};

export const setOffsetByte = (payload) => {
  return { type: SET_OFFSET_BYTE, payload };
};

export const shareByte = (payload) => {
  return { type: SHARED_BYTE, payload };
};

export const queryByte = (payload) => {
  return { type: QUERY_BYTE, payload };
};

export const addViewByte = (payload): AddViewByte => {
  return { type: ADD_VIEW_BYTE, payload };
};

export const resetQueryByte = () => {
  return { type: RESET_QUERY_BYTE, payload: null };
};

export const addClickByte = (payload) => {
  return { type: ADD_CLICK_BYTE, payload };
};

export const manageLikeDislikeByte = (payload) => {
  return { type: MANAGE_LIKE_DISLIKE_BYTE, payload };
};
