import services from ".";
import action from "../helpers/rest-client";

export class ErrorAcademy extends Error {}

class Academy {
  public getOne({ _id }) {
    return action
      .Get({
        url: `/academies/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public manageLikeDislike({ _id, ...rest }) {
    return action
      .Patch({
        url: `/academies/${rest.action}-${rest.condition}/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public addRating({ id, ...rest }) {
    return action
      .Patch({
        url: `/academies/set-rating/${id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public new(payload) {
    return action
      .Post({
        url: "/academies",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  /* 

 const formData = new FormData();
    let noMedia = {};
     for (let key in rest) {
      const currentElement = rest[key];
      console.log("noFile", currentElement);
      if (key !== "bannerPic" && currentElement) {
        formData.append(key, currentElement);
      }
    }

for (let key in rest) {
      const currentElement = rest[key];
      if ((key === "bannerPic" || key === "picture") && currentElement) {
        
        if (typeof currentElement !== "string") {
          console.log("bunny", currentElement.name, currentElement);
          formData.append(key, currentElement, currentElement.name);
        } else {
          formData.append(key, currentElement);
        }
      } else {
        noMedia = { ...noMedia, currentElement };
      }
    }

  return action
      .Patch({
        url: `/academies/${_id}`,
        body: { formData, ...noMedia },
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });



*/
  public update({ _id, ...rest }) {
    const formData = new FormData();

    for (let key in rest) {
      const currentElement = rest[key];

      if (key !== "bannerPic" && key !== "picture" && currentElement) {
        formData.append(key, currentElement);
      }
    }

    for (let key in rest) {
      const currentElement = rest[key];
      if ((key === "bannerPic" || key === "picture") && currentElement) {
        if (typeof currentElement !== "string") {
          formData.append(key, currentElement, currentElement.name);
        } else {
          formData.append(key, currentElement);
        }
      }
    }

    return action
      .Patch({
        url: `/academies/${_id}`,
        body: formData,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public addView({ _id, ...rest }) {
    return action
      .Patch({
        url: `/academies/add-view/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public addClick({ _id, ...rest }) {
    return action
      .Patch({
        url: `/academies/add-click/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public createLink(payload) {
    return action
      .Patch({
        url: `/academies/create-link/${payload}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public deleteLink(payload) {
    return action
      .Patch({
        url: `/academies/delete-link/${payload}`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return { error };
      });
  }

  public updateRole({ _id, ...rest }) {
    return action
      .Patch({
        url: `/academies/update-role/${_id}`,
        body: rest.user,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public addMember({ _id, ...rest }) {
    return action
      .Patch({
        url: `/users/add-to-academy/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public deleteMember({ _id, ...rest }) {
    return action
      .Patch({
        url: `/users/delete-from-academy/${_id}?platform=CAMPUS`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll(payload) {
    const token = services.auth.getToken();
    if (payload.filterBy) {
      payload.filterBy = JSON.stringify(payload.filterBy);
    }
    if (payload.select) {
      payload.select = JSON.stringify(payload.select);
    }
    const queryString = new URLSearchParams(payload).toString();

    return action
      .Get({
        url: `/academies${token ? "" : "/guest/"}${
          queryString ? "?" + queryString : ""
        }`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getOffset({ offset, limit }) {
    return action
      .Get({
        url: `/academies?platform=CAMPUS&limit=${limit}&offset=${offset}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public delete(cohortId) {
    return action
      .Del({
        url: `/academies/${cohortId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const academy = new Academy();

export default academy;
