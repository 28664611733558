import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import {
  GridCard,
  GridCardImage,
  GridCardImageContainer,
  GridCardSmallContainer,
  GridCardTitle,
  GridComment,
  GridStructure,
} from "../../../../components/Layout/Dashboard/styles";
import {
  Center,
  DotContent,
  FlexContainer,
} from "../../../../components/StyledComponents";
import Button from "../../../../components/Button";
import { connect, useDispatch } from "react-redux";
import { AcademyDto } from "../../../../types/academy.dto";
import { UserDto } from "../../../../types/user.dto";
import academyActions from "../../../../store/actions/academies";

import FilterByName from "./components/FilterByName";
import { CohortDto } from "../../../../types/cohort.dto";
import { TrashIcon } from "../../Content/style";
import { BsFillTrashFill } from "react-icons/bs";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
import { StateDto } from "../../../../types/states.dto";
import {
  deleteMember,
  getAllCohorts,
  resetGetAllCohorts,
  resetUpdateCohort,
} from "../../../../store/actions/cohort";
import { showModal } from "../../../../store/actions/modal";
import Loader from "../../../../components/Loader";
import { useNavigate } from "react-router-dom";
import InfinityScroll from "../../../../components/InfinityScroll";
import { addClick } from "../../../../store/actions/userTimes";
interface ComponentProps {
  academies: AcademyDto[];
  user: UserDto;
  cohorts: CohortDto[];
  deleteCohortStates: StateDto;
  updateCohortStates: StateDto;
  cohortsStates: StateDto;
}

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  academies,
  user,
  cohorts,
  deleteCohortStates,
  updateCohortStates,
  cohortsStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [action, setAction] = useState<{ action: string; data: any }>();
  const [cohortSelected, setCohortSelected] = useState<CohortDto>();
  const [currentAcademiesList, setCurrentAcademiesList] = useState<CohortDto[]>(
    []
  );
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);

  const [
    filteredCurrentAcademiesListValues,
    setFilteredCurrentAcademiesListValues,
  ] = useState<CohortDto[]>([]);

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter(
        (applyedFilter) => applyedFilter.field === newFilter.field
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    /**
     * Se elimina el filtro
     */
    if (filterRemoved) {
      setFilters([
        ...applyedFilters.filter(
          (applyedFilter) => applyedFilter.field !== newFilter.field
        ),
      ]);
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 9,
      offset: 9 * page,
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        studentsIds: user._id,
      },
    };

    const select = {
      _id: 1,
      academy: 1,
      picture: 1,
      name: 1,
      viewCount: 1,
      studentsIds: 1,
      startDate: 1,
      endDate: 1,
      type: 1,
    };
    const sortDirection = "desc";
    dispatch(getAllCohorts({ ...payload, sortDirection, select }));
  };

  useEffect(() => {
    buildFilters({ field: "hidden", value: "no" });

    return () => {
      dispatch(resetGetAllCohorts());
    };
  }, []);

  useEffect(() => {
    if (!isLoading && !!cohorts) {
      setCurrentAcademiesList((state) => [...state, ...cohorts]);
    }
    setThereAreItems(!!cohorts && cohorts?.length > 0);
  }, [cohorts, isLoading]);

  useEffect(() => {
    setIsLoading(cohortsStates.loading);
  }, [cohortsStates]);

  useEffect(() => {
    const filteredTalents = currentAcademiesList?.filter(
      (currentTalentValues) => {
        const thereAreFilters = applyedFilters.length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter((applyedFilter) => !applyedFilter.special)
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value
          );
        });

        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );

    setFilteredCurrentAcademiesListValues(filteredTalents);
  }, [applyedFilters, currentAcademiesList]);

  useEffect(() => {
    if (!!action && !!cohortSelected) {
      dispatch(showModal("confirm-delete-item-modal"));
    }
  }, [action, cohortSelected]);

  return (
    <>
      <div style={{ maxWidth: "600px", margin: "0 auto", width: "100%" }}>
        <FilterByName
          items={filteredCurrentAcademiesListValues.map((academy) => {
            return { value: academy.name, label: academy.name };
          })}
          setFilter={buildFilters}
        ></FilterByName>
      </div>
      <Center gap="26px">
        <Button
          type="button"
          onClick={() => buildFilters({ field: "hidden", value: "no" })}
          options={{
            size: "md",
            type: "filled",
            skin: "violet",
          }}
          minwidth="0px"
          style={{
            width: "auto",
            border: "none",
            height: "32px",
            padding: "7px 8px 8px",
            borderRadius: "5px",
            color: !applyedFilters.find((filter) => filter.field === "hidden")
              ? "#fff"
              : "#2a2747",
            backgroundColor: !applyedFilters.find(
              (filter) => filter.field === "hidden"
            )
              ? theme.colors.LightBlue
              : theme.colors.LightBlueHover,
          }}
        >
          Todos
        </Button>
        <Button
          type="button"
          onClick={() => buildFilters({ field: "hidden", value: false })}
          options={{
            size: "md",
            type: "filled",
            skin: "violet",
          }}
          minwidth="0px"
          style={{
            width: "auto",
            border: "none",
            height: "32px",
            padding: "7px 8px 8px",
            borderRadius: "5px",
            color: applyedFilters.find(
              (filter) => filter.field === "hidden" && filter.value === false
            )
              ? "#fff"
              : "#2a2747",
            backgroundColor: applyedFilters.find(
              (filter) => filter.field === "hidden" && filter.value === false
            )
              ? theme.colors.LightBlue
              : theme.colors.LightBlueHover,
          }}
        >
          Cohorts Publicos
        </Button>
        <Button
          type="button"
          onClick={() => buildFilters({ field: "hidden", value: true })}
          options={{
            size: "md",
            type: "filled",
            skin: "violet",
          }}
          minwidth="0px"
          style={{
            width: "auto",
            border: "none",
            height: "32px",
            padding: "7px 8px 8px",
            borderRadius: "5px",
            color: applyedFilters.find(
              (filter) => filter.field === "hidden" && filter.value === true
            )
              ? "#fff"
              : "#2a2747",
            backgroundColor: applyedFilters.find(
              (filter) => filter.field === "hidden" && filter.value === true
            )
              ? theme.colors.LightBlue
              : theme.colors.LightBlueHover,
          }}
        >
          Cohorts Privados
        </Button>
      </Center>
      <div style={{ height: "24px" }}></div>
      <InfinityScroll
        action={fetchData}
        page={page}
        setPage={setPage}
        data={filteredCurrentAcademiesListValues}
        active={thereAreItems}
        isLoading={isLoading}
      >
        <GridStructure width="300px" maxWidth="900px">
          {filteredCurrentAcademiesListValues.map((cohort, key) => {
            return (
              <GridCard
                onClick={(ev) => {
                  ev.stopPropagation();
                  dispatch(
                    addClick({
                      id: cohort._id,
                      user: user._id,
                      type: "COHORT",
                    })
                  );
                  navigate(`/dashboard/cohort/${cohort._id}/home`);
                }}
                key={cohort._id}
              >
                <TrashIcon
                  onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    setCohortSelected(cohort);
                    setAction({
                      action: "out-cohort",
                      data: { _id: cohort._id, userId: user._id },
                    });
                  }}
                >
                  <BsFillTrashFill></BsFillTrashFill>
                </TrashIcon>
                <GridCardImageContainer>
                  <GridCardImage
                    src={
                      cohort.picture ||
                      "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                    }
                  ></GridCardImage>
                </GridCardImageContainer>

                <FlexContainer style={{ marginLeft: "5px" }} gap="12px">
                  <GridCardSmallContainer>
                    <GridCardImage
                      src={
                        cohort.academy?.picture ||
                        "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                      }
                    ></GridCardImage>
                  </GridCardSmallContainer>
                  <FlexContainer direction="column">
                    <GridCardTitle>{cohort.name}</GridCardTitle>
                    <FlexContainer gap="6px" align="center" wrap="wrap">
                      <GridComment>{cohort.academy?.name}</GridComment>
                      <GridComment>
                        {`Vistas: `}
                        <GridComment bold>{cohort.viewCount}</GridComment>
                      </GridComment>
                      <GridComment>
                        {`Inscriptos: `}
                        <GridComment bold>
                          {cohort.studentsIds.length}
                        </GridComment>
                      </GridComment>
                    </FlexContainer>
                    <FlexContainer gap="6px" align="center" wrap="wrap">
                      {cohort.startDate && cohort.type === "SYNCHRONOUS" && (
                        <FlexContainer gap="2px" align="center">
                          <DotContent></DotContent>
                          <GridComment>
                            {` Inicio: `}
                            <GridComment>
                              {new Date(cohort.startDate).toLocaleDateString(
                                "en-GB"
                              )}
                            </GridComment>
                          </GridComment>
                        </FlexContainer>
                      )}
                      {cohort.endDate && cohort.type === "SYNCHRONOUS" && (
                        <FlexContainer gap="2px" align="center">
                          <DotContent></DotContent>
                          <GridComment>
                            {` Finaliza: `}
                            <GridComment>
                              {new Date(cohort.endDate).toLocaleDateString(
                                "en-GB"
                              )}
                            </GridComment>
                          </GridComment>
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  </FlexContainer>
                </FlexContainer>
              </GridCard>
            );
          })}
        </GridStructure>
      </InfinityScroll>

      {action?.action === "out-cohort" && !!cohortSelected && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Salir",
            style: {
              style: {
                height: "26px",
                backgroundColor: "#000",
                borderColor: "#000",
                color: "#fff",
              },
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
                loading: "White",
              },
            },
          }}
          states={updateCohortStates}
          title={
            <span>
              {`¿Seguro que quiere salir del cohort `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {cohortSelected?.name}
              </span>
              ?
            </span>
          }
          elementActions={deleteMember(action.data)}
          resetAction={resetUpdateCohort}
          resetState={setCohortSelected}
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({ academyStore, userStore, cohortStore }) => {
  const { data: academies } = academyStore.all;
  const { data: user } = userStore;
  const { data: cohorts, states: cohortsStates } = cohortStore.allCohorts;
  const { states: deleteCohortStates } = cohortStore.deleteCohort;
  const { states: updateCohortStates } = cohortStore.updateCohort;
  return {
    academies,
    user,
    cohorts,
    deleteCohortStates,
    updateCohortStates,
    cohortsStates,
  };
};

export default connect(states)(Component);
