import {
  NewTalent,
  NEW_TALENT,
  ResetNewTalent,
  RESET_NEW_TALENT,
  GET_ALL_TALENTS,
  GetAllTalents,
  UPDATE_SHOWN_COHORT,
  RESET_GET_ALL_TALENT_DATA,
} from "../types/talent";

export const newTalent = (payload): NewTalent => {
  return { type: NEW_TALENT, payload };
};
export const resetNewTalent = (): ResetNewTalent => {
  return { type: RESET_NEW_TALENT, payload: null };
};

export const resetGetAllTalents = () => {
  return { type: RESET_GET_ALL_TALENT_DATA, payload: null };
};

export const getTalents = (payload): GetAllTalents => {
  return { type: GET_ALL_TALENTS, payload };
};
