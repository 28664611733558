import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Grid from "../../../../../components/Grid";
import { GridStructure } from "../../../../../components/Layout/Dashboard/styles";
import {
  Center,
  FlexContainer,
  PageTitle,
  Separator,
} from "../../../../../components/StyledComponents";

import {
  getAllVideos,
  resetGetAllVideos,
  resetNewVideo,
} from "../../../../../store/actions/video";

import { StateDto } from "../../../../../types/states.dto";
import { VideoDto } from "../../../../../types/video.dto";
import FilterModal from "./components/FilterModal";
import { UserDto } from "../../../../../types/user.dto";
import FilterByName from "./components/FilterByName";
import { useNavigate } from "react-router-dom";
import InfinityScroll from "../../../../../components/InfinityScroll";
import { addClick } from "../../../../../store/actions/userTimes";
import ContentCard from "../../../../../components/ContentCard";
import VideoTraced from "../../../../../assets/icons/video-traced.svg";
import Skeleton from "../../../../../components/Skeleton";
/* const filterValues: any = [
 {
    fieldName: ["visibility", "order"],
    value: "no",
    name: "Todos",
  },
  {
    fieldName: "order",
    value: "recent",
    name: "Recientes",
    groupal: (array: VideoDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "order",
    value: "older",
    name: "Antiguos",
    groupal: (array: VideoDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "published",
    value: true,
    name: "Publicos",
  },
  {
    fieldName: "published",
    value: false,
    name: "Privados",
  },
]; */

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  videos,
  newVideoData,
  newVideoDataStates,
  user,
  queryVideos,
  queryVideosStates,
  videosStates,
}: {
  videos: VideoDto[];
  newVideoData: VideoDto;
  newVideoDataStates: StateDto;

  user: UserDto;
  queryVideos: VideoDto[];
  queryVideosStates: StateDto;
  videosStates: StateDto;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [queryMode, setQueryMode] = useState<string | null>(null);
  const [query, setQuery] = useState<string | null>(null);
  const [currentVideosList, setCurrentVideosList] = useState<any[]>([]);
  const [filteredCurrentVideosListValues, setFilteredCurrentVideosListValues] =
    useState<VideoDto[]>([]);
  const handleQuery = (ev) => {
    const value = ev.target.value;
    setQuery(value.length === 0 ? "" : value);
  };
  const searchQuery = (ev) => {
    if (ev.keyCode === 13) {
      /*  dispatch(queryImage({ keyword: query })); */
      setQueryMode(query);
    }
  };

  const filterValues: any = [
    {
      fieldName: "private",
      value: "no",
      name: "Todos",
    },
    {
      fieldName: "private",
      value: true,
      name: "Academias Privadas",
      special: (array, value, currentItem) => {
        return currentItem.academy?.private === value;
      },
    },
    {
      fieldName: "private",
      value: false,
      name: "Academias Publicas",
      special: (array, value, currentItem) => {
        return currentItem.academy?.private === value;
      },
    },
  ];
  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 9,
      offset: 9 * Math.floor(page / 2),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        url: { $exists: true },
        active: true,
        published: true,
      },
    };
    const sortBy = page % 2 === 0 ? "createdAt" : "viewCount";

    const sortDirection = "desc";
    dispatch(
      getAllVideos({
        ...payload,
        sortBy,
        sortDirection,
        ...(queryMode && { keyword: query }),
      })
    );
  };
  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter((applyedFilter) =>
        !Array.isArray(newFilter.field)
          ? applyedFilter.field === newFilter.field
          : newFilter.field.includes(applyedFilter.field)
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    if (filterRemoved) {
      /**
       * Se elimina el filtro
       */
      if (Array.isArray(newFilter.field)) {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => !newFilter.field.includes(applyedFilter.field)
          ),
        ]);
      } else {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => applyedFilter.field !== newFilter.field
          ),
        ]);
      }
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };
  /*   useEffect(() => {
    if (!!videos && !!academies && !queryVideos) {
      const filterVideos = videos
        .filter(
          (video) => !!video.active && !!video.available && !!video.published
        )
        .filter((video) => (!!byte ? !!video.short : !video.short));
      console.log(filterVideos, videos, "filtro total");
      setCurrentVideosList(filterVideos);
    }
    if (!!videos && !!academies && !!queryVideos) {
      const filterVideos = queryVideos
        .filter(
          (video) => !!video.active && !!video.available && !!video.published
        )
        .filter((video) => (!!byte ? !!video.short : !video.short));

      setCurrentVideosList(filterVideos);
    }
  }, [videos, academies, byte, queryVideos]); */

  useEffect(() => {
    if (queryMode) {
      setPage(0);
      setIsLoading(false);
      setThereAreItems(true);
      setCurrentVideosList([]);
    }
  }, [queryMode]);

  useEffect(() => {
    const filteredTalents = currentVideosList?.filter((currentTalentValues) => {
      const thereAreFilters =
        applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
          .length > 0;
      const totalOfFilters = applyedFilters.filter(
        (applyedFilter) => !applyedFilter.groupal
      ).length;
      const passFilters = applyedFilters
        .filter(
          (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
        )
        .filter(
          (applyedFilter) =>
            currentTalentValues[applyedFilter.field] === applyedFilter.value
        ).length;
      const specialFilter = applyedFilters.filter(
        (applyedFilter) => !!applyedFilter.special
      );

      const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
        return applyedFilter.special(
          currentTalentValues,
          applyedFilter.value,
          currentTalentValues
        );
      });
      return (
        !thereAreFilters ||
        (thereAreFilters &&
          totalOfFilters === passFilters + specialFilter.length &&
          speacialFilterCheck.length === specialFilter.length)
      );
    });
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentVideosListValues(initResult);
  }, [applyedFilters, currentVideosList]);

  useEffect(() => {
    if (!isLoading && !!videos) {
      setCurrentVideosList((state) => [...state, ...videos]);
    }
    setThereAreItems(!!videos && videos.length > 0);
  }, [videos, isLoading]);

  useEffect(() => {
    setIsLoading(videosStates.loading);
  }, [videosStates]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllVideos());
    };
  }, []);

  useEffect(() => {
    if (newVideoDataStates.success) {
      dispatch(resetNewVideo());
    }
    if (newVideoDataStates.error) {
      toast.error(newVideoDataStates.error);
      dispatch(resetNewVideo());
    }
  }, [newVideoDataStates]);

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <PageTitle>Videos</PageTitle>
          </Grid.Col>
        </Grid.Row>
        <Center>
          <Grid.Row style={{ width: "100%" }}>
            <Grid.Col lg={12} md={12} sm={12} style={{ marginBottom: "14px" }}>
              <FlexContainer
                style={{ width: "100%", maxWidth: "700px", margin: "0 auto" }}
              >
                <FilterByName
                  searchQuery={searchQuery}
                  setFilter={handleQuery}
                  value={query}
                ></FilterByName>
              </FlexContainer>
            </Grid.Col>
          </Grid.Row>
        </Center>
        <Separator size={12}></Separator>
        {/*   <ToggleSlider>
          {filterValues.map((filterValue) => {
            return (
              <FilterButton
                setFilter={buildFilters}
                name={filterValue.name}
                fieldName={filterValue.fieldName}
                value={filterValue.value}
                filters={applyedFilters}
                {...((!!filterValue.special || !!filterValue.groupal) && {
                  special: !!filterValue.special
                    ? { name: "special", function: filterValue.special }
                    : { name: "groupal", function: filterValue.groupal },
                })}
              ></FilterButton>
            );
          })}
        </ToggleSlider> */}
        <Separator size={12}></Separator>
        <Grid.Row>
          <Grid.Col>
            <InfinityScroll
              action={fetchData}
              page={page}
              setPage={setPage}
              data={filteredCurrentVideosListValues}
              active={thereAreItems}
              isLoading={isLoading}
              loader={
                <Skeleton
                  itemsPerPage={9}
                  itemsPerRow={3}
                  animated
                  size={{ height: 290, width: 276 }}
                  icon="video"
                />
              }
            >
              <GridStructure
                width={"300px"}
                maxWidth="auto"
                style={{ width: "100%", margin: "0 auto" }}
              >
                {filteredCurrentVideosListValues.map((item) => {
                  return (
                    <ContentCard
                      key={item._id}
                      ContentTypeLogo={{ image: VideoTraced }}
                      item={item}
                      logoOnclick={(item) => {
                        dispatch(
                          addClick({
                            id: item.academy?._id,
                            user: user._id,
                            type: "ACADEMY",
                          })
                        );
                        navigate(`/dashboard/academy/${item.academy?._id}`);
                      }}
                      cardOnClick={(item) => {
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                        navigate(`/dashboard/videos/${item._id}`);
                      }}
                    ></ContentCard>
                  );
                })}
              </GridStructure>
            </InfinityScroll>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      <FilterModal buildFilters={buildFilters}></FilterModal>
    </>
  );
};

const states = ({ videoStore, cohortStore, academyStore, userStore }) => {
  const { data: videos, states: videosStates } = videoStore.all;
  const { data: newVideoData, states: newVideoDataStates } = videoStore.new;
  const { data: user } = userStore;
  const { data: queryVideos, states: queryVideosStates } = videoStore.query;
  return {
    videos,
    newVideoData,
    newVideoDataStates,
    videosStates,
    user,
    queryVideos,
    queryVideosStates,
  };
};

export default connect(states)(Component);
