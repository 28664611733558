import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center } from "../../../../components/StyledComponents";
import Input from "../../../../components/Form/Input";
import Submit from "../../../../components/Form/Submit";
import ReactSelect from "../../../../components/Form/ReactSelect";
import { initialValues, schema } from "../../../../constants/form/ability/new";
import { CategoryDto } from "../../../../types/category.dto";
import Button from "../../../../components/Button";
import { hideModal } from "../../../../store/actions/modal";
import { newAbility, resetNewAbility } from "../../../../store/actions/ability";
import { getAllCategories } from "../../../../store/actions/category";

interface ComponentProps {
  categories: CategoryDto[];
  categoriesStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  newAbilityStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  categories,
  newAbilityStates,
  categoriesStates,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(newAbility(values));
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  useEffect(() => {
    if (newAbilityStates?.success) {
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetNewAbility());
      }, 2000);
    }
  }, [newAbilityStates]);

  useEffect(() => {
    if (!categories) {
      dispatch(getAllCategories());
    }
  }, [dispatch, categories]);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validateOnChange={formSubmmited}
      validateOnBlur={false}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <form
            className="theme-form"
            onSubmit={(event) => {
              setFormSubmmited(true);
              handleSubmit(event);
            }}
            id="edit-event-form"
          >
            <Input
              name="name"
              error={errors["name"]}
              touched={touched["name"]}
              value={values["name"]}
              type="text"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Nombre de la habilidad",
                marginBottom: 20,
              }}
            />

            <ReactSelect
              name="categories"
              error={errors["categories"]}
              touched={touched["categories"]}
              isMulti
              {...(!!categories && {
                items: categories?.map((category) => ({
                  label: category.name,
                  value: category._id,
                })),
              })}
              disabled={categoriesStates.loading}
              placeholder="Selecciona una categoría"
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Categoría",
                marginBottom: 50,
              }}
            />

            <Center>
              <Submit
                isSubmmiting={isSubmitting}
                form="edit-event-form"
                color="Primary"
                options={{
                  type: "filled",
                  skin: "primary",
                  size: "lg",
                  marginBottom: "0px",
                }}
              >
                Confirmar
              </Submit>
              <Button
                onClick={closeModal}
                type="button"
                options={{
                  type: "outline",
                  skin: "danger",
                  size: "lg",
                  marginBottom: "0px",
                }}
                style={{ marginLeft: "10px" }}
              >
                Cancelar
              </Button>
            </Center>
          </form>
        );
      }}
    </Formik>
  );
};

const states = ({ categoryStore, abilityStore }) => {
  const { data: categories, states: categoriesStates } =
    categoryStore.allCategories;
  const { data: newAbilityStates } = abilityStore.newAbility;
  return {
    categories,
    newAbilityStates,
    categoriesStates,
  };
};

export default connect(states)(Component);
