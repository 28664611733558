import React, { useEffect, useState } from "react";

import {
  Center,
  FlexContainer,
  PageTitle,
} from "../../../components/StyledComponents";
import { connect, useDispatch } from "react-redux";
import { AcademyDto } from "../../../types/academy.dto";
import { UserDto } from "../../../types/user.dto";

import { CohortDto } from "../../../types/cohort.dto";
import notificationActions from "../../../store/actions/notification";
import academyActions from "../../../store/actions/academies";
import Loader from "../../../components/Loader";

import {
  EntityImageContainer,
  EntityTitleDescription,
  EntityTitleSemiBold,
  ListItem,
  ListWrapper,
} from "./style";
import { StateDto } from "../../../types/states.dto";
import { NotificationDto } from "../../../types/notification.dto";
/* import FilterButton from "./components/FilterButton";
import { CurrentDto } from "../../../types/current.dto"; */
import Grid from "../../../components/Grid";
import { Img } from "../../SignIn/styles";
import {
  getAllCohorts,
  resetGetAllCohorts,
} from "../../../store/actions/cohort";
import { UsersDto } from "../../../types/users.dto";
import usersActions from "../../../store/actions/users";
import { DateEStoDateEN, DateFromNow } from "../../../helpers/format-date";
import InfinityScroll from "../../../components/InfinityScroll";
interface ComponentProps {
  user: UserDto;

  notifications: NotificationDto[];
  updateManyNotificationsStates: StateDto;
  updateNotificationStates: StateDto;
  notificationsStates: StateDto;
}

/* const filterValues = [
  {
    fieldName: "type",
    value: "no",
    name: "Todos",
  },
  {
    fieldName: "type",
    value: "ACADEMY",
    name: "Academias",
  },
  {
    fieldName: "type",
    value: "ORGANIZATION",
    name: "Organizaciones",
  },
  {
    fieldName: "type",
    value: "PERSONAL",
    name: "Personal",
  },
]; */
interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  user,
  notificationsStates,
  notifications,
  updateManyNotificationsStates,
  updateNotificationStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [currentNotificationsList, setCurrentNotificationsList] = useState<
    NotificationDto[]
  >([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);

  const [
    filteredCurrentNotificationsListValues,
    setFilteredCurrentNotificationsListValues,
  ] = useState<NotificationDto[]>([]);

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter(
        (applyedFilter) => applyedFilter.field === newFilter.field
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    /**
     * Se elimina el filtro
     */
    if (filterRemoved) {
      setFilters([
        ...applyedFilters.filter(
          (applyedFilter) => applyedFilter.field !== newFilter.field
        ),
      ]);
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 9,
      offset: 9 * Math.floor(page),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
      },
    };
    const sortBy = "createdAt";

    const sortDirection = "desc";
    dispatch(notificationActions.getAll({ ...payload, sortBy, sortDirection }));
  };

  useEffect(() => {
    setThereAreItems(!!notifications && notifications?.length > 0);
  }, [notifications]);

  useEffect(() => {
    setIsLoading(notificationsStates.loading);
  }, [notificationsStates]);

  useEffect(() => {
    buildFilters({ field: "type", value: "no" });
    return () => {
      dispatch(notificationActions.resetGetAll());
    };
  }, []);

  useEffect(() => {
    if (updateManyNotificationsStates.success) {
      dispatch(notificationActions.resetGetAll());
    }
    if (updateManyNotificationsStates.error) {
      dispatch(notificationActions.resetGetAll());
    }
  }, [updateManyNotificationsStates]);

  useEffect(() => {
    if (!!notifications && !isLoading) {
      /* Me dice en que lugar estoy */
      const currentNotifications = notifications.filter((notification) =>
        user.notifications.includes(notification._id)
      );

      dispatch(
        notificationActions.updateMany({
          items: currentNotifications.map((notification) => {
            return {
              _id: notification._id,
              seen: true,
            };
          }),
        })
      );
      setCurrentNotificationsList((state) => [
        ...state,
        ...currentNotifications,
      ]);
    }
  }, [notifications, isLoading, user]);

  useEffect(() => {
    const filteredNotifications = currentNotificationsList?.filter(
      (currentTalentValues) => {
        const thereAreFilters = applyedFilters.length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter((applyedFilter) => !applyedFilter.special)
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value
          );
        });

        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    setFilteredCurrentNotificationsListValues(filteredNotifications);
  }, [applyedFilters, currentNotificationsList]);

  useEffect(() => {
    return () => {
      dispatch(notificationActions.resetGetAll());
    };
  }, []);

  return (
    <>
      <PageTitle>Notificaciones</PageTitle>
      <Grid.Row style={{ maxWidth: "1200px", margin: "0 auto" }}>
        <Grid.Col>
          <div style={{ height: "32px" }}></div>
          <FlexContainer
            style={{
              overflowY: "auto",
              maxHeight: "600px",
              padding: "2px",
              paddingBottom: "20px",
            }}
          >
            <InfinityScroll
              style={{ width: "100%", paddingBottom: "20px" }}
              action={fetchData}
              page={page}
              setPage={setPage}
              data={filteredCurrentNotificationsListValues}
              active={thereAreItems}
              isLoading={isLoading}
            >
              <FlexContainer
                direction="column"
                gap="14px"
                style={{ paddingBottom: "30px" }}
              >
                {filteredCurrentNotificationsListValues.map((notification) => {
                  return (
                    <ListItem>
                      <FlexContainer
                        wrap="wrap"
                        justify="space-between"
                        align="center"
                      >
                        <FlexContainer align="center" gap="10px">
                          <EntityImageContainer>
                            <Img src={notification.entity.picture}></Img>
                          </EntityImageContainer>
                          <EntityTitleSemiBold>
                            {`${notification.entity.name} ${
                              !!notification.entity.lastName
                                ? notification.entity.lastName
                                : ""
                            }`}
                          </EntityTitleSemiBold>
                        </FlexContainer>
                        <EntityTitleSemiBold>
                          {new Date(notification.createdAt).toLocaleDateString(
                            "en-GB"
                          )}
                        </EntityTitleSemiBold>
                        <FlexContainer gap="20px">
                          <EntityTitleSemiBold>
                            {notification.description}
                          </EntityTitleSemiBold>
                        </FlexContainer>
                      </FlexContainer>
                    </ListItem>
                  );
                })}
              </FlexContainer>
            </InfinityScroll>
          </FlexContainer>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

const states = ({
  userStore,
  cohortStore,
  invitationStore,
  academyStore,
  notificationStore,
  usersStore,
}) => {
  const { states: updateNotificationStates } = invitationStore.update;

  const { data: notifications, states: notificationsStates } =
    notificationStore.all;
  const { states: updateManyNotificationsStates } =
    notificationStore.updateMany;
  const { data: user } = userStore;

  return {
    notifications,
    user,
    notificationsStates,
    updateNotificationStates,
    updateManyNotificationsStates,
  };
};

export default connect(states)(Component);
