import Grid from "../../../../../../components/Grid";
import {
  CriterionCard,
  GrowCard,
} from "../../../../../../components/LineGraph/styles";
import UserSearched from "../UserSearched/UserSearched";
import { TitleCriterion, TitleStage } from "../NumericStats/styles";
import { BigGrade } from "../SprintStats/styles";
import { connect } from "react-redux";
import { AnalyticsUserDto } from "../../../../../../types/analytics.dto";
import RadarChart from "../../../../../../components/RadarChart/index";
import { TalentDto } from "../../../../../../types/talent.dto";
import { StudentDto } from "../../../../../../types/student.dto";
import { arrayLengthDivision } from "../../../../../../helpers/notLengthDivision";
import { roundedNumbers } from "../../../../../../helpers/rounded-numbers";
import { UsersDto } from "../../../../../../types/users.dto";
import LineGraph from "../../../../../../components/LineGraph";
interface PropsComponent {
  selectedStudent?: string;
  analyticsStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  analytics: AnalyticsUserDto[];
  talents: TalentDto[];
  students: StudentDto[];
  users: UsersDto[];
  cohortSelected: string;
}

const Component = ({
  selectedStudent,
  analyticsStates,
  analytics,
  cohortSelected,
  talents,
  students,
  users,
}: PropsComponent) => {
  const cohortAnalytic = analytics.filter(
    (analytic) => analytic.cohort === cohortSelected
  )[0];

  const allStages = Array.from(
    new Set(cohortAnalytic.skills.map((skill) => skill.stage))
  ).sort((a, b) => a - b);
  const maxStage = Math.max(...allStages);

  const maxStageSkills = cohortAnalytic.skills.filter(
    (skill) => skill.stage === maxStage
  );
  const secondMaxSkills = cohortAnalytic.skills.filter(
    (skill) => skill.stage === allStages[1]
  );
  const averageGrowStages = Object.entries(cohortAnalytic.stagesNotes).reduce(
    (prev, current) => prev + current[1],
    0
  );
  const bestCriterionQualified: {
    criterion: { _id: string; name: string } | null;
    note: number;
  } = cohortAnalytic.skills.reduce(
    (prev, current, index, array): any => {
      if (prev.note < current.note) {
        return { criterion: current.skill, note: current.note };
      } else {
        return prev;
      }
    },
    { criterion: null, note: 0 }
  );
  return (
    <>
      <Grid.Col style={{ flexDirection: "column", gap: "20px" }}>
        <Grid.Row>
          <Grid.Col sm={4}>
            <UserSearched userId={selectedStudent} />
          </Grid.Col>
          <Grid.Col sm={2}>
            <CriterionCard>
              <TitleCriterion>Promedio Global Ultima Etapa</TitleCriterion>
              <BigGrade>
                {roundedNumbers(
                  maxStageSkills.reduce((prev, current) => {
                    return prev + current.note;
                  }, 0) / arrayLengthDivision(maxStageSkills)
                )}
              </BigGrade>
            </CriterionCard>
          </Grid.Col>
          <Grid.Col sm={2}>
            <CriterionCard>
              <TitleCriterion>Promedio Global Anteultima Etapa</TitleCriterion>
              <BigGrade>
                {roundedNumbers(
                  secondMaxSkills.reduce((prev, current) => {
                    return prev + current.note;
                  }, 0) / arrayLengthDivision(secondMaxSkills)
                )}
              </BigGrade>
            </CriterionCard>
          </Grid.Col>
          <Grid.Col sm={2}>
            <CriterionCard>
              <TitleCriterion>Crecimiento por etapas promedios</TitleCriterion>
              <BigGrade>
                {`${roundedNumbers(
                  averageGrowStages /
                    arrayLengthDivision(
                      Object.entries(cohortAnalytic.stagesNotes)
                    )
                )}%`}
              </BigGrade>
            </CriterionCard>
          </Grid.Col>
          <Grid.Col sm={2}>
            {bestCriterionQualified.criterion && (
              <CriterionCard>
                <TitleCriterion>
                  {bestCriterionQualified.criterion.name}
                </TitleCriterion>
                <BigGrade>{bestCriterionQualified.note}</BigGrade>
              </CriterionCard>
            )}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row style={{ marginTop: "30px" }}>
          <Grid.Col sm={12}>
            <GrowCard>
              <TitleStage>Crecimiento por etapas:</TitleStage>
              {!!analytics && (
                <LineGraph
                  analytics={Object.entries(cohortAnalytic.stagesNotes).map(
                    (analytic) => {
                      return {
                        level: analytic[0],
                        value: analytic[1],
                      };
                    }
                  )}
                  color="#1f77b4"
                  title="Crecimiento por etapas"
                ></LineGraph>
              )}
            </GrowCard>
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>
    </>
  );
};

const states = ({ analyticStore, talentStore, studentStore, usersStore }) => {
  const { states: analyticsStates, data: analytics } =
    analyticStore.getIndividualStats;
  const { data: talents } = talentStore.allTalents;
  const { data: students } = studentStore.allStudents;
  const { data: users } = usersStore.all;
  return {
    analyticsStates,
    analytics,
    talents,
    students,
    users,
  };
};

export default connect(states)(Component);
