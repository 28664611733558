import * as Yup from "yup";

interface FormValues {
  academy: string;
  cohort: string;
}

export const schema = Yup.object().shape({
  academy: Yup.string(),
  cohort: Yup.string(),
});

export const initialValues = {
  academy: "",
  cohort: "",
};
