import React, { useState } from "react";
import {
  GridCard,
  GridCardDescription,
  GridCardImage,
  GridStructure,
} from "../../../../../components/Layout/Dashboard/styles";
import {
  Center,
  FlexContainer,
} from "../../../../../components/StyledComponents";
import { CohortMediumFont } from "../../style";
import {
  AccordeonContent,
  AccordeonContentWrapper,
  AccordeonWrapper,
  ArrowIcon,
  ImageContent,
} from "./style";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useTheme } from "styled-components";
import { useNavigate } from "react-router";
import Loader from "../../../../../components/Loader";
import { connect, useDispatch } from "react-redux";
import { addClick } from "../../../../../store/actions/userTimes";
import SyncCarousel from "../../../../../components/SyncCarousel";
import { WrapperSlide } from "../../../../../components/Slider/style";
import MediaItem from "../../../../Dashboard/Home/components/MediaItems/components/MediaItem";
import { getPathContent } from "../../../../../helpers/getPathContent";
import { getContentIcon } from "../../../../../helpers/getContentIcon";
import MediaItems from "../../../../Dashboard/Home/components/MediaItems";
import { secondsToHHMMSS } from "../../../../../helpers/format-date";

const Component = ({
  index,
  name,
  items,
  active,
  setActive,
  onClick,
  isLoadingContents,
  user,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  return (
    <AccordeonWrapper onClick={!!onClick && onClick}>
      <FlexContainer justify="space-between" align="center">
        <FlexContainer gap="1px">
          <CohortMediumFont>{`${index}: ${name}`}</CohortMediumFont>
        </FlexContainer>
        <ArrowIcon className={index === active ? "active" : ""}>
          <MdKeyboardArrowDown
            fontSize={25}
            color={theme.colors.LightBlue}
          ></MdKeyboardArrowDown>
        </ArrowIcon>
      </FlexContainer>
      <AccordeonContentWrapper
        onClick={(ev) => {
          ev.stopPropagation();
        }}
        className={index === active ? "active" : ""}
      >
        {!!isLoadingContents || index !== active ? (
          <Center>
            <Loader color="LightBlue"></Loader>
          </Center>
        ) : (
          <MediaItems
            style={{ margin: "0 auto" }}
            minCardWidth={"100px"}
            size="lg"
            items={items.map((item) => {
              return {
                ...item,
                icon: getContentIcon(item),
                ...(item.duration && {
                  bottomLabel: secondsToHHMMSS(item.duration),
                }),
              };
            })}
            title={""}
            onClick={(event, item) => {
              dispatch(
                addClick({
                  id: item._id,
                  user: user._id,
                  type: "CONTENT",
                })
              );
            }}
          />
        )}
      </AccordeonContentWrapper>
    </AccordeonWrapper>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;
  return {
    user,
  };
};

export default connect(states)(Component);
