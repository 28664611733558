import React, { useEffect, useRef, useState } from "react";
import {
  DotContent,
  FlexContainer,
  ItemDataText,
  Separator,
} from "../../../../components/StyledComponents";
import {
  AcademyPicture,
  ByteMobileFooter,
  ByteMobileFooterTitle,
  ByteMobileSide,
  ByteSkipped,
  ByteSkippedButtons,
  ByteTitle,
  ByteVideoWrapper,
  ByteWrapper,
  MediaInteractionButtons,
} from "./style";
import { GridComment } from "../../../../components/Layout/Dashboard/styles";
import Share from "../../../../components/ShareLink";
import { DateFromNow } from "../../../../helpers/format-date";
import { MdKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import CustomPlayer from "../../../../components/CustomPlayer";
import Button from "../../../../components/Button";
import { LikeDislikeBox, LikeDislikeText } from "../../SlideShow/View/style";
import {
  AiFillDislike,
  AiOutlineCheck,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";
import { overThousandFixed } from "../../../../helpers/number-fixed";
import Sidebar from "./components/SideBar";
import { FaPlay, FaSave, FaShare } from "react-icons/fa";
import { showModal } from "../../../../store/actions/modal";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addViewVideo,
  getVideo,
  offSetVideos,
  resetGetVideo,
  resetOffsetVideos,
  saveVideo,
  setOffsetVideo,
  setSelectedVideo,
} from "../../../../store/actions/video";
import { VideoDto } from "../../../../types/video.dto";
import { StateDto } from "../../../../types/states.dto";
import Loader from "../../../../components/Loader";
import "./style.css";
import { useWindowSize } from "../../../../hooks/useWindowScreen";
import MobileSideBar from "./components/MobileSideBar";
import { UserDto } from "../../../../types/user.dto";
import { BiLink } from "react-icons/bi";
import { toast } from "react-toastify";
import ShareModal from "./components/ShareModal";
import { Img } from "../../../SignIn/styles";
import useTrackTimeAndAPI from "../../../../hooks/useTrackTimeAndAPI";
import {
  addClick,
  addView,
  userTime,
} from "../../../../store/actions/userTimes";
import { ByteDto } from "../../../../types/byte.dto";
import {
  getAllBytes,
  manageLikeDislikeByte,
  resetUpdateByte,
} from "../../../../store/actions/byte";
import { LikeDislikeBoxNumber } from "../../../Cohort/Home/style";
import { useTheme } from "styled-components";
import ShareLinkCircle from "../../../../components/ShareLinkCircle";
import CommentSectionMobile from "../../../../components/CommentSectionMobile";
import academyActions from "../../../../store/actions/academies";
import { AcademyDto } from "../../../../types/academy.dto";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
const Component = ({
  offsetVideos,
  offsetVideosStates,
  dataVideo,
  getVideoStates,
  user,
  bytes,
  getBytesStates,
  updatedByte,
  updateByteStates,
  updatedAcademy,
  updateAcademyStates,
  academy,
  getAcademyStates,
}: {
  offsetVideos: VideoDto[];
  offsetVideosStates: StateDto;
  dataVideo: VideoDto;
  getVideoStates: StateDto;
  user: UserDto;
  bytes: ByteDto[];
  getBytesStates: StateDto;
  updatedByte: ByteDto;
  updateByteStates: StateDto;
  updatedAcademy: AcademyDto;
  updateAcademyStates: StateDto;
  academy: AcademyDto;
  getAcademyStates;
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [currentByteAcademy, setCurrentByteAcademy] = useState<AcademyDto>();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const { isMobile } = useWindowSize();
  const [action, setAction] = useState<{ action: string; data: any }>();
  const [currentByte, setCurrentByte] = useState<VideoDto>();
  const [slideDirection, setSlideDirection] = useState("");
  const [byteIndex, setByteIndex] = useState<number>(0);
  const [thereIsNext, setThereIsNext] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const videoRef: any = useRef();
  const startY = useRef(0);

  const apiAction = (timeSpent) => {
    dispatch(userTime({ ...timeSpent, id: id, user: user._id, type: "BYTE" })); // Dispatch action to update Redux store or make API request
  };
  useTrackTimeAndAPI(apiAction, id && user);
  /* useEffect(() => {
    if (!!offsetVideos && !currentByte) {
      dispatch(setSelectedVideo(null));
    }
  }, []); */

  useEffect(() => {
    if (id && user) {
      dispatch(addView({ id: id, user: user._id, type: "CONTENT" }));
    }
  }, [id, user]);

  /*   useEffect(() => {
    if (videoRef.current && videoRef.current.play) {
      videoRef.current.play();
    }
  }, [currentByte, videoRef.currrent]); */
  /*  useEffect(() => {
    if (!!dataVideo && !currentByte) {
      dispatch(setOffsetVideo([dataVideo]));
    }
    if (!!dataVideo && !!currentByte) {
      setCurrentByte(dataVideo);
    }
  }, [dataVideo, dispatch]); */
  /* useEffect(() => {
    if (offsetVideosStates.success) {
      dispatch(resetOffsetVideos());
    }
  }, [offsetVideosStates]); */
  /* useEffect(() => {
    if (!!id) {
      if (
        !!offsetVideos &&
        offsetVideos.length > 0 &&
        offsetVideosStates.success
      ) {
        const byte = offsetVideos.findIndex((value) => value._id === id);
        navigate(`/dashboard/bytes/${offsetVideos[byte + 1]._id}`);
        setSlideDirection("slide-out");
        setCurrentByte(offsetVideos[byte + 1]);
      } else if (!offsetVideos || offsetVideos.length === 0) {
        dispatch(getVideo({ _id: id }));
      }
    }
  }, [id, dispatch, offsetVideosStates.success]); */
  useEffect(() => {
    if (updateByteStates.success) {
      setCurrentByte(updatedByte);
      dispatch(resetUpdateByte());
    }
    if (updateByteStates.error) {
      dispatch(resetUpdateByte());
    }
  }, [updateByteStates]);
  useEffect(() => {
    if (user) {
      dispatch(
        getAllBytes({
          filterBy: { active: true, available: true },
          limit: 1,
          offset: byteIndex,
        })
      );
    }
  }, [byteIndex, user]);

  useEffect(() => {
    if (currentByte && fetchOn) {
      dispatch(academyActions.getOne({ _id: currentByte.academy._id }));
    }
  }, [currentByte, fetchOn]);

  useEffect(() => {
    if (academy && fetchOn) {
      setCurrentByteAcademy(academy);
    }
  }, [academy, fetchOn]);

  useEffect(() => {
    if (updateAcademyStates.success && currentByteAcademy) {
      setCurrentByteAcademy(updatedAcademy);
      dispatch(academyActions.resetUpdate());
    }
    if (updateAcademyStates.error) {
      toast.error(`${updateAcademyStates.error}`);
      dispatch(academyActions.resetUpdate());
    }
  }, [updateAcademyStates]);

  useEffect(() => {
    if (getBytesStates.success) {
      if (bytes[0]) {
        setCurrentByte(bytes[0]);
        navigate(`/dashboard/bytes/${bytes[0]._id}`);
      } else {
        setThereIsNext(false);
      }
    }
    if (getBytesStates.error) {
      toast.error(getBytesStates.error);
    }
  }, [getBytesStates]);

  useEffect(() => {
    if (academy) {
      setFetchOn(true);
    }
  }, [academy]);

  useEffect(() => {
    if (currentByte && !updateByteStates.success) {
      if (!!videoRef) {
        videoRef.current.setIsPlaying({ muteValue: true, playValue: true });
        videoRef.current.resetPlayer();
      }
    }
  }, [currentByte]);

  useEffect(() => {
    return () => {
      dispatch(academyActions.resetGetOneAcademy());
    };
  }, []);

  const skipByte = ({ direction }) => {
    if (direction === "down") {
      if (thereIsNext) {
        setSlideDirection("slide-out");
        setByteIndex((state) => state + 1);
      }
    } else {
      setSlideDirection("slide-in");
      setByteIndex((state) => state - 1);
    }
  };

  const handleSlide = () => {
    if (!!videoRef) {
      videoRef.current.setIsPlaying({ playValue: true });
    }
  };

  const handleEnded = () => {
    if (!!videoRef) {
      setSlideDirection("slide-out");
    }
  };

  const handleTouchStart = (e) => {
    startY.current = e.touches[0].clientY;
  };

  const handleTouchEnd = (e) => {
    const deltaY = e.changedTouches[0].clientY - startY.current;
    if (deltaY > 0) {
      skipByte({ direction: "up" });
    } else if (deltaY < 0) {
      skipByte({ direction: "down" });
    }
  };

  useEffect(() => {
    return () => {
      dispatch(academyActions.resetGetOneAcademy());
    };
  }, []);

  if (!currentByte || !videoRef) return <Loader color="LightBlue"></Loader>;
  return (
    <>
      {!isMobile && (
        <FlexContainer
          style={{
            width: "100%",
            minHeight: `calc(100vh - ${!!isMobile ? `98px` : `116px`})`,
            position: "relative",
          }}
        >
          <FlexContainer
            justify="center"
            className={`youtube-short ${slideDirection}`}
            onAnimationEnd={() => {
              setSlideDirection("");
            }}
            onAnimationStart={handleSlide}
            style={{ height: "100%", position: "relative" }}
            gap="15px"
          >
            <ByteWrapper mobile={isMobile}>
              <FlexContainer gap="8px">
                <ByteVideoWrapper
                  onClick={() => {
                    if (!!videoRef.current) {
                      videoRef.current.setIsPlaying({
                        playValue: !videoRef.current.getIsPlaying(),
                      });
                    }
                  }}
                  mobile={isMobile}
                >
                  <CustomPlayer
                    key={currentByte.url}
                    ref={videoRef}
                    noControlls
                    playerHeight="100%"
                    video={currentByte.url}
                    CustomPlay={
                      <FaPlay color={theme.colors.LightBlue} size={32}></FaPlay>
                    }
                    onEnd={handleEnded}
                    /* onPlay={handlePlay} */
                  ></CustomPlayer>
                </ByteVideoWrapper>

                {!isMobile && (
                  <FlexContainer align="center">
                    <FlexContainer gap="16px" align="center" direction="column">
                      <FlexContainer
                        gap="5px"
                        direction="column"
                        align="center"
                      >
                        <MediaInteractionButtons
                          className={
                            !!currentByte.likes.find(
                              (like) => like.user === user._id
                            )
                              ? "active"
                              : ""
                          }
                        >
                          <AiOutlineLike size={20} fill={"#2496ed"} />
                        </MediaInteractionButtons>
                        <LikeDislikeText>
                          {overThousandFixed(currentByte.likes.length)}
                        </LikeDislikeText>
                      </FlexContainer>
                      <FlexContainer
                        gap="5px"
                        direction="column"
                        align="center"
                      >
                        <MediaInteractionButtons
                          className={
                            !!currentByte.dislikes.find(
                              (like) => like.user === user._id
                            )
                              ? "active"
                              : ""
                          }
                        >
                          {currentByte.dislikes.find(
                            (like) => like.user === user._id
                          ) ? (
                            <AiFillDislike
                              fill={"#fff"}
                              size={20}
                            ></AiFillDislike>
                          ) : (
                            <AiOutlineDislike
                              fill={"#2496ed"}
                              size={20}
                            ></AiOutlineDislike>
                          )}
                        </MediaInteractionButtons>
                        <LikeDislikeText>No Me Gusta</LikeDislikeText>
                      </FlexContainer>
                      <FlexContainer
                        gap="5px"
                        direction="column"
                        align="center"
                      >
                        <ShareLinkCircle></ShareLinkCircle>
                        <LikeDislikeText>Compartir</LikeDislikeText>
                      </FlexContainer>
                    </FlexContainer>
                  </FlexContainer>
                )}
              </FlexContainer>
            </ByteWrapper>

            {!isMobile && (
              <FlexContainer style={{ paddingTop: "20px" }} direction="column">
                <h1 style={{ fontSize: "14px", color: "#051725" }}>
                  {currentByte.name}
                </h1>
                <FlexContainer
                  gap="5px"
                  align="center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/dashboard/academy/${currentByte.academy._id}`);
                    dispatch(
                      addClick({
                        id: currentByte.academy._id,
                        user: user._id,
                        type: "ACADEMY",
                      })
                    );
                  }}
                >
                  <AcademyPicture>
                    <Img src={currentByte.academy.picture}></Img>
                  </AcademyPicture>
                  <h3 style={{ fontSize: "12px", color: "#051725" }}>
                    {currentByte.academy.name}
                  </h3>
                </FlexContainer>

                <Separator size={24}></Separator>
                <CommentSectionMobile
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  itemId={currentByte._id}
                  section="content"
                ></CommentSectionMobile>
                <Separator size={24}></Separator>
              </FlexContainer>
            )}
          </FlexContainer>

          {!isMobile && (
            <ByteSkippedButtons>
              {byteIndex > 0 ? (
                <ByteSkipped
                  onClick={() => {
                    skipByte({ direction: "up" });
                    dispatch(
                      addClick({
                        id: currentByte._id,
                        user: user._id,
                        type: "CONTENT",
                      })
                    );
                  }}
                >
                  <MdOutlineKeyboardArrowUp
                    size={25}
                    color={"#2496ed"}
                  ></MdOutlineKeyboardArrowUp>
                </ByteSkipped>
              ) : (
                <div></div>
              )}
              {thereIsNext && (
                <ByteSkipped
                  onClick={(ev) => {
                    skipByte({ direction: "down" });
                    dispatch(
                      addClick({
                        id: currentByte._id,
                        user: user._id,
                        type: "CONTENT",
                      })
                    );
                  }}
                >
                  <MdKeyboardArrowDown
                    size={25}
                    color={"#2496ed"}
                  ></MdKeyboardArrowDown>
                </ByteSkipped>
              )}
            </ByteSkippedButtons>
          )}
        </FlexContainer>
      )}
      {isMobile && (
        <ByteVideoWrapper
          onClick={() => {
            if (!!videoRef.current) {
              videoRef.current.setIsPlaying({
                playValue: !videoRef.current.getIsPlaying(),
              });
            }
          }}
          mobile={isMobile}
          className={`youtube-short ${slideDirection}`}
          onAnimationEnd={() => {
            setSlideDirection("");
          }}
          onAnimationStart={handleSlide}
          {...(!!isMobile && {
            onTouchStart: handleTouchStart,
            onTouchEnd: handleTouchEnd,
          })}
        >
          <CustomPlayer
            key={currentByte.url}
            ref={videoRef}
            noControlls
            playerHeight="100%"
            video={currentByte.url}
            CustomPlay={
              <FaPlay color={theme.colors.LightBlue} size={32}></FaPlay>
            }
            onEnd={handleEnded}
            /* onPlay={handlePlay} */
          >
            <>
              <ByteMobileFooter>
                <FlexContainer direction="column" gap="10px">
                  <ByteMobileFooterTitle hideLine={2}>
                    {currentByte.name}
                  </ByteMobileFooterTitle>
                  <FlexContainer
                    align="center"
                    justify="space-between"
                    wrap="wrap"
                  >
                    <FlexContainer
                      gap="5px"
                      align="center"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(
                          `/dashboard/academy/${currentByte.academy._id}`
                        );
                        dispatch(
                          addClick({
                            id: currentByte.academy._id,
                            user: user._id,
                            type: "ACADEMY",
                          })
                        );
                      }}
                    >
                      <AcademyPicture>
                        <Img src={currentByte.academy.picture}></Img>
                      </AcademyPicture>
                      <FlexContainer direction="column" gap="3px">
                        <h3 style={{ fontSize: "12px", color: "#fff" }}>
                          {currentByte.academy.name}
                        </h3>
                        <h3 style={{ fontSize: "12px", color: "#fff" }}>
                          {`${currentByteAcademy?.userCount} suscriptos `}
                        </h3>
                      </FlexContainer>
                    </FlexContainer>
                    {currentByteAcademy ? (
                      !currentByteAcademy.users.find(
                        (userF) => userF.user === user._id && !userF.roleEnd
                      ) ? (
                        !currentByteAcademy.private ? (
                          <Button
                            type="button"
                            onClick={() => {
                              dispatch(
                                academyActions.addMember({
                                  _id: currentByteAcademy._id,
                                  user: user._id,
                                  role: "STUDENT",
                                })
                              );
                              dispatch(
                                addClick({
                                  id: currentByteAcademy._id,
                                  user: user._id,
                                  type: "ACADEMY",
                                })
                              );
                            }}
                            options={{
                              size: "md",
                              type: "filled",
                              skin: "lightblue",
                            }}
                            loading={updateAcademyStates.loading}
                          >
                            Suscribirse
                          </Button>
                        ) : (
                          <></>
                        )
                      ) : (
                        <Button
                          type="button"
                          onClick={() => {
                            setAction({
                              data: null,
                              action: "out-of-academy",
                            });
                            dispatch(
                              showModal("confirm-delete-item-secuencial-modal")
                            );
                            dispatch(
                              addClick({
                                id: currentByteAcademy._id,
                                user: user._id,
                                type: "ACADEMY",
                              })
                            );
                          }}
                          options={{
                            size: "md",
                            type: "outline",
                            skin: "lightblue",
                          }}
                          loading={updateAcademyStates.loading}
                        >
                          <FlexContainer gap="4px" align="center">
                            <AiOutlineCheck
                              size={14}
                              color={theme.colors.LightBlue}
                            ></AiOutlineCheck>
                            <span>Suscripto</span>
                          </FlexContainer>
                        </Button>
                      )
                    ) : (
                      <></>
                    )}
                  </FlexContainer>
                </FlexContainer>
              </ByteMobileFooter>
              <ByteMobileSide>
                <FlexContainer gap="6px" align="center" direction="column">
                  <FlexContainer gap="3px" direction="column" align="center">
                    <MediaInteractionButtons
                      svgColor={"#fff"}
                      className={
                        !!currentByte.likes.find(
                          (like) => like.user === user._id
                        )
                          ? "active"
                          : ""
                      }
                    >
                      <AiOutlineLike size={20} fill={"#2496ed"} />
                    </MediaInteractionButtons>
                    <LikeDislikeText>
                      {overThousandFixed(currentByte.likes.length)}
                    </LikeDislikeText>
                  </FlexContainer>
                  <FlexContainer gap="3px" direction="column" align="center">
                    <MediaInteractionButtons
                      svgColor={"#fff"}
                      className={
                        !!currentByte.dislikes.find(
                          (like) => like.user === user._id
                        )
                          ? "active"
                          : ""
                      }
                    >
                      {currentByte.dislikes.find(
                        (like) => like.user === user._id
                      ) ? (
                        <AiFillDislike fill={"#fff"} size={20}></AiFillDislike>
                      ) : (
                        <AiOutlineDislike
                          fill={"#2496ed"}
                          size={20}
                        ></AiOutlineDislike>
                      )}
                    </MediaInteractionButtons>
                  </FlexContainer>
                  <FlexContainer gap="3px" direction="column" align="center">
                    <ShareLinkCircle
                      style={{
                        icon: {
                          color: "#fff",
                          fill: "#fff",
                        },
                      }}
                    ></ShareLinkCircle>
                  </FlexContainer>
                </FlexContainer>
              </ByteMobileSide>
            </>
          </CustomPlayer>
        </ByteVideoWrapper>
      )}
      <ShareModal video={currentByte}></ShareModal>
      {currentByteAcademy && action?.action === "out-of-academy" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Desuscribirse",
            style: {
              color: "Danger",

              style: {
                width: "150px",
                height: "31px",
              },
              options: {
                type: "filled",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={updateAcademyStates}
          elementActions={academyActions.deleteMember({
            _id: currentByteAcademy._id,
            userId: user._id,
          })}
          onClose={() => {
            setAction(undefined);
          }}
          title={
            <FlexContainer direction="column" gap="11px">
              <span>
                {`¿Seguro quieres salir de la academia `}
                <span style={{ color: "#000" }}>{currentByteAcademy.name}</span>
                ?
              </span>
              <p>
                Perderás todos los accesos y suscripciones relacionados a esta
                Academia y los contenidos que no hayas descargado.
              </p>
            </FlexContainer>
          }
          /*  resetState={() => {
            setAction(undefined);
          }} */
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({ videoStore, userStore, academyStore, byteStore }) => {
  const { data: offsetVideos, states: offsetVideosStates } = videoStore.offSet;
  const { data: dataVideo, states: getVideoStates } = videoStore.video;
  const { data: bytes, states: getBytesStates } = byteStore.all;
  const { data: updatedByte, states: updateByteStates } = byteStore.update;
  const { data: user } = userStore;
  const { data: academy, states: getAcademyStates } = academyStore.selected;
  const { data: updatedAcademy, states: updateAcademyStates } =
    academyStore.update;

  return {
    updatedAcademy,
    academy,
    getAcademyStates,
    offsetVideos,
    offsetVideosStates,
    dataVideo,
    user,
    updateAcademyStates,
    getVideoStates,
    bytes,
    getBytesStates,
    updatedByte,
    updateByteStates,
  };
};

export default connect(states)(Component);
