import action from "../helpers/rest-client";

export class ErrorMeet extends Error {}

class Meet {
  public new(payload) {
    return action
      .Post({
        url: "/meets",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public update({ _id, ...rest }) {
    return action
      .Patch({
        url: `/meets/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public updateState({ _id, ...rest }) {
    return action
      .Patch({
        url: `/meets/${_id}/update-status`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll(payload) {
    if (payload.filterBy) {
      payload.filterBy = JSON.stringify(payload.filterBy);
    }
    if (payload.select) {
      payload.select = JSON.stringify(payload.select);
    }
    const queryString = new URLSearchParams(payload).toString();
    return action
      .Get({
        url: `/meets?platform=CAMPUS${queryString ? "&" + queryString : ""}`,
      })
      .then((meets) => {
        const response = meets.map((meet) => ({
          ...meet,
          start: new Date(meet.start),
          end: new Date(meet.end),
        }));
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne({ _id }) {
    return action
      .Get({
        url: `/meets/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public deleteOne({ _id }) {
    return action
      .Del({
        url: `/calendar-events/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const calendar = new Meet();
export default calendar;
