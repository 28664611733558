import { connect, useDispatch } from "react-redux";
import { Text } from "./styles";
import Submit from "../../../../../../components/Form/Submit";
import { Center } from "../../../../../../components/StyledComponents";
import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";
import { CategoryDto } from "../../../../../../types/category.dto";
import { hideModal } from "../../../../../../store/actions/modal";
import { useEffect } from "react";
import {
  resetUpdateCategory,
  updateCategory,
} from "../../../../../../store/actions/category";

interface ComponentProps {
  category: CategoryDto;
  updateCategoryStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ category, updateCategoryStates }: ComponentProps) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(hideModal());
  };

  const deleteCategory = () => {
    dispatch(updateCategory({ ...category, active: false }));
  };

  useEffect(() => {
    if (updateCategoryStates.success) {
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetUpdateCategory());
      }, 2000);
    }
  }, [updateCategoryStates]);

  return (
    <Modal name="delete-category-modal" title="Eliminar Categoría">
      <Text>
        ¿Estas seguro de eliminar la Categoría <br />{" "}
        <strong>{category?.name}</strong> ?
      </Text>
      <Center>
        <Submit
          form=""
          isSubmmiting={updateCategoryStates.loading}
          onClick={deleteCategory}
          type="button"
          style={{ marginRight: "10px" }}
        >
          Eliminar
        </Submit>
        <Button
          onClick={closeModal}
          type="button"
          options={{
            type: "outline",
            skin: "danger",
            size: "lg",
            marginBottom: "0px",
          }}
        >
          Cancelar
        </Button>
      </Center>
    </Modal>
  );
};

const states = ({ categoryStore }) => {
  const { data: category } = categoryStore.category;
  const { states: updateCategoryStates } = categoryStore.updateCategory;

  return {
    category,
    updateCategoryStates,
  };
};

export default connect(states)(Component);
