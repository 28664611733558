import styled from "styled-components";

export const Input = styled("input")`
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 40px;
  outline: none;
  width: 100%;
  min-height: calc(40px - 2px);
  height: auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  border: 2px solid #ffffff;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.LightBlue};
`;

export const BoxShadowSearchWrapper = styled("div")`
  position: relative;
  margin: 0 20px 20px 20px;
  box-shadow: 0 0 6px 0 rgba(36, 150, 237, 0.25);
  -webkit-box-shadow: 0 0 6px 0 rgba(36, 150, 237, 0.25);
  border-radius: 10px;
`;
