import moment from "moment";
import "moment/dist/locale/es";
moment.locale("es", {
  relativeTime: {
    future: "en %s",
    past: "%s",
    s: "un segundo",
    ss: "%s segundos",
    m: "un minuto",
    mm: "%d minutos",
    h: "hora",
    hh: "%d horas",
    d: "1 dia",
    dd: "%d dias",
    M: "un mes",
    MM: "%d meses",
    y: "un año",
    yy: "%d años",
  },
});

export const DateEStoDateEN = (date: string) => {
  const sliptDate = date
    .split("/")
    .reverse()
    .map((a) => Number(a));
  return new Date(sliptDate[0], sliptDate[1] - 1, sliptDate[2]).getTime();
};

export const DateFromNow = (date: Date) => {
  const dateTime = moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss");
  const dateFromNow = moment(dateTime).locale("es").fromNow(true);

  return dateFromNow.charAt(0).toUpperCase() + dateFromNow.slice(1);
};

export function secondsToHHMMSS(seconds?: number) {
  if (!seconds) return `00:00:00`;
  const duration = moment.duration(seconds, "seconds");
  const hours = duration.hours();
  const minutes = duration.minutes();
  const secondsRemaining = duration.seconds();

  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${secondsRemaining.toString().padStart(2, "0")}`;

  return formattedTime;
}

/* resto uno al meno */
