import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import Button from "../../../../../components/Button";
import Grid from "../../../../../components/Grid";
import Loader from "../../../../../components/Loader";
import {
  Center,
  FlexContainer,
  PageTitle,
  Separator,
} from "../../../../../components/StyledComponents";
import { DateEStoDateEN } from "../../../../../helpers/format-date";

import AnalyticCard from "../../components/AnalyticCard";
import { PDFExport } from "@progress/kendo-react-pdf";
import { StateDto } from "../../../../../types/states.dto";

import { CohortUserTimeDto } from "../../../../../types/analytics.dto";
import { Formik } from "formik";
import ReactSelect from "../../../../../components/Form/ReactSelect";
import NotData from "../../components/NotData";
import LineGraphHorizontal from "../../../../../components/LineGraphHorizontal";
import { CohortDto } from "../../../../../types/cohort.dto";

import {
  getAllCohorts,
  resetGetAllCohorts,
} from "../../../../../store/actions/cohort";

import { UserDto } from "../../../../../types/user.dto";
import { getCohortUserTimeStats } from "../../../../../store/actions/analytics";
import {
  formatNumber,
  overThousandFixed,
} from "../../../../../helpers/number-fixed";
const Component = ({
  analyticsCohortUserTime,
  analyticsCohortUserTimeStates,
  cohorts,
  user,
}: {
  analyticsCohortUserTime: CohortUserTimeDto;
  analyticsCohortUserTimeStates: StateDto;
  cohorts: CohortDto[];
  user: UserDto;
}) => {
  const printRef: any = React.useRef();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handleExportWithComponent = () => {
    printRef.current.save();
  };

  useEffect(() => {
    if (!cohorts && fetchOn)
      dispatch(
        getAllCohorts({ filterBy: { studentsIds: user._id, active: true } })
      );
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (!cohorts) {
      setFetchOn(true);
    }
  }, [cohorts]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohorts());
    };
  }, []);

  return (
    <Grid.Container>
      <div style={{ minHeight: "100vh" }}>
        <PageTitle>Dashboard</PageTitle>
        <Formik
          initialValues={{}}
          onSubmit={(values, actions) => {}}
          validateOnBlur={false}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {}}
                id="edit-academy-visibility-form"
              >
                <Grid.Row>
                  <Grid.Col md={3} sm={12}>
                    <ReactSelect
                      name="cohort"
                      {...(cohorts && {
                        items: cohorts.map((cohort) => ({
                          label: cohort.name,
                          value: cohort._id,
                        })),
                      })}
                      placeholder="Seleccione un Cohort"
                      onChange={handleChange}
                      onOptionSelected={(newValue) => {
                        if (newValue) {
                          dispatch(
                            getCohortUserTimeStats({
                              cohortId: newValue.value,
                              userId: user._id,
                            })
                          );
                        }
                      }}
                      disabled={!cohorts}
                      notLoading={true}
                      options={{
                        label: "Cohort",
                        marginBottom: 10,
                        skin: "base",
                      }}
                    />
                  </Grid.Col>
                </Grid.Row>

                {analyticsCohortUserTimeStates.loading ? (
                  <Loader color="Primary"></Loader>
                ) : !analyticsCohortUserTime ? (
                  <>
                    <Separator size={30}></Separator>
                    <Center>
                      <h2>Esperando seleccion</h2>
                    </Center>
                  </>
                ) : (
                  <>
                    <Grid.Col offset={{ sm: 10 }} sm={12}>
                      <Button
                        onClick={() => {
                          handleExportWithComponent();
                        }}
                        type="button"
                        options={{
                          type: "filled",
                          skin: "primary",
                          size: "md",
                          marginBottom: "10px",
                        }}
                        style={{ marginLeft: "10px" }}
                      >
                        Descargar
                      </Button>
                    </Grid.Col>

                    <PDFExport
                      fileName={`Constana General ${new Date().valueOf()}`}
                      ref={printRef}
                    >
                      <FlexContainer direction="column" gap="6px">
                        <AnalyticCard
                          title={"Horas totales de consumo."}
                          graphTitle={
                            "Horas totales de consumo de todos los contenidos del cohort- Crecimiento Histórico"
                          }
                          data={{
                            stats: [
                              {
                                label: "Últimos 30 días",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.lastMonth
                                ),
                              },
                              {
                                label: "Últimos 7 días",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.lastWeek
                                ),
                              },
                              {
                                label: "Últimas 24 horas",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.lastDay
                                ),
                              },
                            ],
                            graphData: Object.entries(
                              analyticsCohortUserTime.growth
                            )
                              .sort(
                                (a, b) =>
                                  DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                              )
                              .map((growthData) => {
                                return {
                                  name: growthData[0],
                                  value: formatNumber(growthData[1]),
                                };
                              }),
                          }}
                        ></AnalyticCard>
                        <AnalyticCard
                          title={
                            "Horas totales de consumo de contenido de videos."
                          }
                          graphTitle={
                            "Horas totales de consumo de videos de la academia- Crecimiento Histórico"
                          }
                          data={{
                            stats: [
                              {
                                label: "Últimos 30 días",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.videos.lastMonth
                                ),
                              },
                              {
                                label: "Últimos 7 días",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.videos.lastWeek
                                ),
                              },
                              {
                                label: "Últimas 24 horas",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.videos.lastDay
                                ),
                              },
                            ],
                            graphData: Object.entries(
                              analyticsCohortUserTime.videos.growth
                            )
                              .sort(
                                (a, b) =>
                                  DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                              )
                              .map((growthData) => {
                                return {
                                  name: growthData[0],
                                  value: formatNumber(growthData[1]),
                                };
                              }),
                          }}
                        ></AnalyticCard>
                        <AnalyticCard
                          title={
                            "Horas totales de consumo de contenido de Slideshows."
                          }
                          graphTitle={
                            "Horas totales de consumo de contenido de Slideshows.- Crecimiento Histórico"
                          }
                          data={{
                            stats: [
                              {
                                label: "Últimos 30 días",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.slideshows.lastMonth
                                ),
                              },
                              {
                                label: "Últimos 7 días",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.slideshows.lastWeek
                                ),
                              },
                              {
                                label: "Últimas 24 horas",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.slideshows.lastDay
                                ),
                              },
                            ],
                            graphData: Object.entries(
                              analyticsCohortUserTime.slideshows.growth
                            )
                              .sort(
                                (a, b) =>
                                  DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                              )
                              .map((growthData) => {
                                return {
                                  name: growthData[0],
                                  value: formatNumber(growthData[1]),
                                };
                              }),
                          }}
                        ></AnalyticCard>
                        <AnalyticCard
                          title={
                            "Horas totales de consumo de contenido de bytes."
                          }
                          graphTitle={
                            "Horas totales de consumo de contenido de bytes.- Crecimiento Histórico"
                          }
                          data={{
                            stats: [
                              {
                                label: "Últimos 30 días",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.bytes.lastMonth
                                ),
                              },
                              {
                                label: "Últimos 7 días",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.bytes.lastWeek
                                ),
                              },
                              {
                                label: "Últimas 24 horas",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.bytes.lastDay
                                ),
                              },
                            ],
                            graphData: Object.entries(
                              analyticsCohortUserTime.bytes.growth
                            )
                              .sort(
                                (a, b) =>
                                  DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                              )
                              .map((growthData) => {
                                return {
                                  name: growthData[0],
                                  value: formatNumber(growthData[1]),
                                };
                              }),
                          }}
                        ></AnalyticCard>
                        <AnalyticCard
                          title={
                            "Horas totales de consumo de contenido de imágenes."
                          }
                          graphTitle={
                            "Horas totales de consumo de contenido de imágenes.- Crecimiento Histórico"
                          }
                          data={{
                            stats: [
                              {
                                label: "Últimos 30 días",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.images.lastMonth
                                ),
                              },
                              {
                                label: "Últimos 7 días",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.images.lastWeek
                                ),
                              },
                              {
                                label: "Últimas 24 horas",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.images.lastDay
                                ),
                              },
                            ],
                            graphData: Object.entries(
                              analyticsCohortUserTime.images.growth
                            )
                              .sort(
                                (a, b) =>
                                  DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                              )
                              .map((growthData) => {
                                return {
                                  name: growthData[0],
                                  value: formatNumber(growthData[1]),
                                };
                              }),
                          }}
                        ></AnalyticCard>
                        <AnalyticCard
                          title={
                            "Horas totales de consumo de contenido de cdocs."
                          }
                          graphTitle={
                            "Horas totales de consumo de contenido de cdocs.- Crecimiento Histórico"
                          }
                          data={{
                            stats: [
                              {
                                label: "Últimos 30 días",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.cdocs.lastMonth
                                ),
                              },
                              {
                                label: "Últimos 7 días",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.cdocs.lastWeek
                                ),
                              },
                              {
                                label: "Últimas 24 horas",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.cdocs.lastDay
                                ),
                              },
                            ],
                            graphData: Object.entries(
                              analyticsCohortUserTime.cdocs.growth
                            )
                              .sort(
                                (a, b) =>
                                  DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                              )
                              .map((growthData) => {
                                return {
                                  name: growthData[0],
                                  value: formatNumber(growthData[1]),
                                };
                              }),
                          }}
                        ></AnalyticCard>
                        <AnalyticCard
                          title={
                            "Horas totales de consumo de contenido de listens."
                          }
                          graphTitle={
                            "Horas totales de consumo de contenido de listens.- Crecimiento Histórico"
                          }
                          data={{
                            stats: [
                              {
                                label: "Últimos 30 días",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.listens.lastMonth
                                ),
                              },
                              {
                                label: "Últimos 7 días",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.listens.lastWeek
                                ),
                              },
                              {
                                label: "Últimas 24 horas",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.listens.lastDay
                                ),
                              },
                            ],
                            graphData: Object.entries(
                              analyticsCohortUserTime.listens.growth
                            )
                              .sort(
                                (a, b) =>
                                  DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                              )
                              .map((growthData) => {
                                return {
                                  name: growthData[0],
                                  value: formatNumber(growthData[1]),
                                };
                              }),
                          }}
                        ></AnalyticCard>
                        <AnalyticCard
                          title={
                            "Horas totales de consumo de contenido de trayectos."
                          }
                          graphTitle={
                            "Horas totales de consumo de contenido de trayectos.- Crecimiento Histórico"
                          }
                          data={{
                            stats: [
                              {
                                label: "Últimos 30 días",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.roadmaps.lastMonth
                                ),
                              },
                              {
                                label: "Últimos 7 días",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.roadmaps.lastWeek
                                ),
                              },
                              {
                                label: "Últimas 24 horas",
                                value: overThousandFixed(
                                  analyticsCohortUserTime.roadmaps.lastDay
                                ),
                              },
                            ],
                            graphData: Object.entries(
                              analyticsCohortUserTime.roadmaps.growth
                            )
                              .sort(
                                (a, b) =>
                                  DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                              )
                              .map((growthData) => {
                                return {
                                  name: growthData[0],
                                  value: formatNumber(growthData[1]),
                                };
                              }),
                          }}
                        ></AnalyticCard>
                      </FlexContainer>
                    </PDFExport>
                  </>
                )}
              </form>
            );
          }}
        </Formik>
      </div>
    </Grid.Container>
  );
};

const states = ({ analyticStore, cohortStore, userStore }) => {
  const {
    data: analyticsCohortUserTime,
    states: analyticsCohortUserTimeStates,
  } = analyticStore.cohortUserTimes;
  const { data: user } = userStore;

  const { data: cohorts } = cohortStore.allCohorts;

  return {
    analyticsCohortUserTime,
    analyticsCohortUserTimeStates,
    user,
    cohorts,
  };
};

export default connect(states)(Component);
