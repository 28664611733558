import styled from "styled-components";

export const VideoPreview = styled("div")`
  position: relative;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);
  height: ${(props) => props.height};
  & .react-player__preview {
    position: relative;
  }
  &:focus {
    outline: none;
    border: none;
  }
`;

export const ShortMute = styled("div")`
  padding: 5px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-flex;
  align-center: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  right: 10px;
  top: 20px;
`;

export const ImageListening = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${(props) => (props.image ? `url(${props.image})` : ``)};
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TrianglePlay = styled("div")`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 0 50px 100px;
  border-radius: 15px 10px;
  border-color: transparent transparent transparent
    ${(props) => props.theme.colors.LightBlue};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  zoom: 1;
  &:hover {
    border-color: transparent transparent transparent #30a0f7;
  }
`;

export const SpinnerWrapper = styled("div")`
  position: absolute;
  width: 80px;
  height: 80px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LightWrapper = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
