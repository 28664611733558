export const getPathContent = (item) => {
  let path = `academy/${item?._id}`;
  if (item.type) {
    switch (item.type) {
      case "VIDEO":
        path = `videos/${item?._id}`;

        break;
      case "SLIDESHOW":
        path = `slideshows/${item?._id}`;

        break;
      case "CDOC":
        path = `cdocs/${item?._id}`;

        break;
      case "COHORT":
        path = `cohorts/${item?._id}`;

        break;
      case "ROADMAP":
        path = `roadmaps/${item?._id}`;

        break;
      case "IMAGE":
        path = `images/${item?._id}`;

        break;
      case "LISTEN":
        path = `listens/${item?._id}`;

        break;
      case "BYTE":
        path = `bytes/${item?._id}`;

        break;
    }
  }
  return `/dashboard/${path}`;
};
