import React, {
  Children,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import Loader from "../Loader";
import CenterTitle from "../CenterTitle";
import services from "../../services";

interface ComponentProps {
  data;
  children;
  active: boolean;
  action: ({ page }: { page: number }) => void;
  isLoading: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  style?;
  noMessage?: boolean;
  loader?: ReactElement;
}

const FetchDataComponent = ({
  data,
  children,
  isLoading,
  page,
  setPage,
  active,
  action,
  style,
  noMessage,
  loader,
}: ComponentProps) => {
  const observer = useRef<IntersectionObserver | null>(null); // Define observer type

  useEffect(() => {
    action({ page });
  }, []);

  const lastElementRef = useRef<HTMLDivElement>(null); // Define ref type

  useEffect(() => {
    if (!isLoading && data) {
      console.log({ data, isLoading, page, active }, "unasx");

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      };

      const handleIntersect: IntersectionObserverCallback = async (entries) => {
        console.log("mendyb", entries[0].isIntersecting, active, page);
        if (entries[0].isIntersecting && active) {
          action({ page: page + 1 });
          setPage(page + 1);
        }
      };

      observer.current = new IntersectionObserver(handleIntersect, options);

      if (lastElementRef.current) {
        observer.current.observe(lastElementRef.current);
      }
      return () => {
        if (lastElementRef.current) {
          observer.current?.unobserve(lastElementRef.current); // Use optional chaining for observer unobserve
        }
      };
    }
  }, [data, isLoading]);
  return (
    <div style={style ? style : {}}>
      {children}
      {isLoading && <>{loader ? loader : <Loader color="LightBlue" />}</>}
      {!active && !isLoading && !noMessage && (
        <CenterTitle title="No hay más elementos"></CenterTitle>
      )}
      <div ref={lastElementRef}></div>
    </div>
  );
};

export default FetchDataComponent;
