import { createRef, useState } from "react";
import { ArrayHelpers, FieldArray } from "formik";
import Input from "../Input";
import ReactSelect from "../ReactSelect";
import { FieldContainer } from "../Field";
import {
  AddButton,
  RemoveButton,
  EditButton,
  ScrollSection,
  FlexContainer,
} from "../../StyledComponents";
import { List, Item, Label, Select } from "./styles";
import { uniqueArrayValues } from "../../../helpers/unique-array-values";

interface ComponentProps {
  name: string;
  value?: (string | number)[];
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  initialValues?: string[] | Option[];
  error?: {};
  touched?: {};
  forwardRef?;
  valueFieldName: string;
  pickFunction?: (...args: any[]) => any;
  options: {
    itemOnClick?: { functionItem: (value: any) => any; value: any };
    insideDataList?: { value: string | number | any; label: string }[];
    setFieldValue?: any;
    height?: string;
    multi?: boolean;
    before?: any;
    skin?: "base" | "gray";
    type: "input" | "select";
    data?: { value: string | number | any; label: string }[];
    loading?: boolean;
    inputPlaceholder?: string;
    onClick?: (item: any, index: number, arrayHelpers: ArrayHelpers) => void;
    onOptionSelected?: (item: any, index: number, fieldValue: string[]) => void;
    inputLabel: string;
    listLabel?: string;
    specialValid?: () => boolean;
    externalData?: boolean;
    indicator?: boolean;
    marginBottom?: number;
  };
}

interface Option {
  label?: string;
  value?: string | any;
}

const Component = ({
  value,
  name,
  initialValues,
  options,
  valueFieldName,
  pickFunction,
  ...rest
}: ComponentProps) => {
  const { loading = false } = options;
  const [selectedItems, setSelectedItems] = useState<string[] | Option[]>([]);
  const inputRef = createRef<HTMLInputElement>();

  return (
    <>
      <FieldArray
        {...rest}
        name={name}
        render={(arrayHelpers) => (
          <>
            {options.type === "input" && (
              <Input
                forwardRef={inputRef}
                type="text"
                {...(!!options.inputPlaceholder && {
                  placeholder: options.inputPlaceholder,
                })}
                options={{
                  marginBottom: 30,
                  ...options,
                  label: options.inputLabel,
                  skin: options.skin,
                  after: (
                    <AddButton
                      type="button"
                      onClick={() => {
                        if (
                          !!inputRef &&
                          !!inputRef.current &&
                          !!inputRef.current.value &&
                          (!value ||
                            (!!value &&
                              !value.includes(inputRef.current.value)))
                        ) {
                          if (!!options.specialValid && options.specialValid())
                            return null;
                          arrayHelpers.push(inputRef.current.value);
                          !!options.onOptionSelected &&
                            options.onOptionSelected(
                              inputRef.current.value,
                              selectedItems.length - 1,
                              arrayHelpers.form.values[name]
                            );
                          setSelectedItems([
                            ...(selectedItems as string[]),
                            inputRef.current.value,
                          ]);
                        }
                      }}
                    />
                  ),
                }}
              />
            )}
            {options.type === "select" && (
              <ReactSelect
                items={
                  !!options.data
                    ? options.data?.filter((dataItem) => {
                        return (
                          !value ||
                          !value
                            .map((v) => v[valueFieldName])
                            .includes(dataItem.value[valueFieldName])
                        );
                      })
                    : []
                }
                onOptionSelected={(data: { label: string; value: any }) => {
                  if (
                    !value ||
                    (!!value && !value.includes(data.value[valueFieldName]))
                  ) {
                    arrayHelpers.push(
                      !!pickFunction
                        ? pickFunction(options.data, data.value, valueFieldName)
                        : data.value
                    );
                    !!options.onOptionSelected &&
                      options.onOptionSelected(
                        data.value,
                        selectedItems.length - 1,
                        arrayHelpers.form.values[name]
                      );
                    setSelectedItems([...(selectedItems as Option[]), data]);
                  }
                }}
                {...(!!options.inputPlaceholder && {
                  placeholder: options.inputPlaceholder,
                })}
                options={{
                  ...options,
                  loading: loading,
                  label: options.inputLabel,
                  marginBottom: 30,
                }}
              />
            )}

            <FieldContainer>
              {!!options.listLabel && <Label>{options.listLabel}</Label>}

              <ScrollSection
                style={{
                  ...(options.skin === "gray" && {
                    backgroundColor: "#f5f5f5",
                    boxShadow: "none",
                  }),
                  minHeight: "95px",
                  height: options?.height || "400px",
                  padding: "10px",
                }}
              >
                <List className={!!options.multi ? "multiLine" : "list"}>
                  {!!arrayHelpers.form.values[name] &&
                    arrayHelpers.form.values[name]?.map((item, index) => (
                      <Item
                        className={`${!!options.multi ? "multiLine" : "list"}`}
                        key={`${item.value}-${index}`}
                      >
                        <FlexContainer gap="10px" align="center" wrap="wrap">
                          <span>
                            {options.type === "select" &&
                              (options.data as Option[])?.filter(
                                (selectedItem) => {
                                  return (
                                    selectedItem.value["value"] ===
                                    item["value"]
                                  );
                                }
                              )[0]?.label}
                          </span>
                          {!!options.insideDataList && (
                            <div style={{ width: "119px" }}>
                              <Select
                                onChange={(ev) => {
                                  const mappedArray = arrayHelpers.form.values[
                                    name
                                  ].map((arrItem, ind) =>
                                    ind === index
                                      ? { ...arrItem, role: ev.target.value }
                                      : arrItem
                                  );
                                  options.setFieldValue(name, mappedArray);
                                }}
                              >
                                {options.insideDataList?.map((insideItem) => {
                                  return (
                                    <option
                                      selected={
                                        arrayHelpers.form.values[name][index][
                                          "role"
                                        ] === insideItem.value
                                      }
                                      value={insideItem.value}
                                    >
                                      {insideItem.label}
                                    </option>
                                  );
                                })}
                              </Select>
                            </div>
                          )}
                        </FlexContainer>
                        <span>
                          {!!options.onClick && (
                            <EditButton
                              type="button"
                              onClick={() =>
                                !!options.onClick &&
                                options.onClick(item, index, arrayHelpers)
                              }
                            />
                          )}

                          <RemoveButton
                            type="button"
                            onClick={() => {
                              arrayHelpers.remove(index);
                              if (options.type === "select") {
                                setSelectedItems(
                                  (selectedItems as Option[]).filter(
                                    (_selectedItem, selectedItemIndex) =>
                                      index !== selectedItemIndex
                                  )
                                );
                              } else {
                                setSelectedItems(
                                  (selectedItems as string[]).filter(
                                    (_selectedItem, selectedItemIndex) =>
                                      index !== selectedItemIndex
                                  )
                                );
                              }
                            }}
                          />
                        </span>
                      </Item>
                    ))}
                </List>
              </ScrollSection>
            </FieldContainer>
          </>
        )}
      />
    </>
  );
};

export default Component;

/* 


<DinamicCreateable
                    name="bootcamps"
                    error={errors["bootcamps"]}
                    touched={touched["bootcamps"]}
                    value={values["bootcamps"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      type: "select",
                      skin: "base",
                      data:
                        !!cohorts &&
                        cohorts
                          .filter((cohort) => cohort.active)
                          .filter(
                            (cohort) =>
                              cohort.abilityId === values["technology"]
                          )
                          .filter((cohort) =>
                            current.role === "ADMIN"
                              ? cohort
                              : cohort.studentsIds.includes(user._id)
                          )
                          .map((cohort) => ({
                            label: cohort.name,
                            value: cohort._id,
                          })),
                      externalData: true,
                      loading: !cohorts || cohortsStates.loading,
                      inputLabel: "Seleccionar Bootcamp",
                      inputPlaceholder: "Selecciona un bootcamp",
                      listLabel: "Bootcamps Selecciosas",
                    }}
                  />



*/
