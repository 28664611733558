import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Grid from "../../../../components/Grid";
import {
  GridCard,
  GridCardImage,
  GridCardImageContainer,
  GridCardSmallContainer,
  GridCardTitle,
  GridComment,
  GridStructure,
} from "../../../../components/Layout/Dashboard/styles";
import Loader from "../../../../components/Loader";
import {
  Center,
  FlexContainer,
  PageTitle,
  Separator,
} from "../../../../components/StyledComponents";
import { DateFromNow } from "../../../../helpers/format-date";
import {
  getAllRoadmaps,
  resetGetAllRoadmaps,
  resetNewRoadmap,
} from "../../../../store/actions/roadmap";
import { AcademyDto } from "../../../../types/academy.dto";
import { RoadmapDto } from "../../../../types/roadmap.dto";
import { CohortDto } from "../../../../types/cohort.dto";
import { StateDto } from "../../../../types/states.dto";
import { UserDto } from "../../../../types/user.dto";
import FilterButton from "./components/FilterButton";
import FilterByName from "./components/FilterByName";
import { DotContent } from "./style";
import academyActions from "../../../../store/actions/academies";
import { useNavigate } from "react-router-dom";
import InfinityScroll from "../../../../components/InfinityScroll";
import ToggleSlider from "../../../../components/ToggleSlider";
import { addClick } from "../../../../store/actions/userTimes";

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const filterValues: any = [
  {
    fieldName: ["published", "order"],
    value: "no",
    name: "Todos",
  },
  {
    fieldName: "order",
    value: "recent",
    name: "Recientes",
    groupal: (array: RoadmapDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "order",
    value: "older",
    name: "Antiguos",
    groupal: (array: RoadmapDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "published",
    value: true,
    name: "Publicos",
  },
  {
    fieldName: "published",
    value: false,
    name: "Privados",
  },
];

const Component = ({
  academies,
  roadmaps,
  newRoadmap,
  newRoadmapStates,
  cohorts,
  user,
  roadmapsStates,
}: {
  roadmaps: RoadmapDto[];
  newRoadmap: RoadmapDto;
  newRoadmapStates: StateDto;
  cohorts: CohortDto[];
  academies: AcademyDto[];
  user: UserDto;
  roadmapsStates: StateDto;
}) => {
  const navigate = useNavigate();
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (data?: any, data2?: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [currentRoadmapsList, setCurrentRoadmapsList] = useState<any[]>([]);
  const [
    filteredCurrentRoadmapsListValues,
    setFilteredCurrentRoadmapsListValues,
  ] = useState<any[]>([]);
  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter((applyedFilter) =>
        !Array.isArray(newFilter.field)
          ? applyedFilter.field === newFilter.field
          : newFilter.field.includes(applyedFilter.field)
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    if (filterRemoved) {
      /**
       * Se elimina el filtro
       */
      if (Array.isArray(newFilter.field)) {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => !newFilter.field.includes(applyedFilter.field)
          ),
        ]);
      } else {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => applyedFilter.field !== newFilter.field
          ),
        ]);
      }
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };
  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 9,
      offset: 9 * Math.floor(page / 2),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        published: true,
      },
    };
    const sortBy = page % 2 === 0 ? "createdAt" : "viewCount";

    const sortDirection = "desc";
    dispatch(getAllRoadmaps({ ...payload, sortBy, sortDirection }));
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && !!roadmaps) {
      setCurrentRoadmapsList((state) => [
        ...state,
        ...roadmaps.filter(
          (roadmap) => roadmap.active && roadmap.savedBy.includes(user._id)
        ),
      ]);
    }
    setThereAreItems(!!roadmaps && roadmaps?.length > 0);
  }, [roadmaps, isLoading]);

  useEffect(() => {
    setIsLoading(roadmapsStates.loading);
  }, [roadmapsStates]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllRoadmaps());
    };
  }, []);

  useEffect(() => {
    const filteredTalents = currentRoadmapsList?.filter(
      (currentTalentValues) => {
        const thereAreFilters =
          applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
            .length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter(
            (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
          )
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value
          );
        });
        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentRoadmapsListValues(initResult);
  }, [applyedFilters, currentRoadmapsList]);

  useEffect(() => {
    if (newRoadmapStates.success) {
      dispatch(resetNewRoadmap());
    }
    if (newRoadmapStates.error) {
      toast.error(newRoadmapStates.error);
      dispatch(resetNewRoadmap());
    }
  }, [newRoadmapStates]);

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <PageTitle>Trayectos</PageTitle>
          </Grid.Col>
        </Grid.Row>
        <Center>
          <Grid.Row style={{ width: "100%" }}>
            <Grid.Col lg={12} md={12} sm={12} style={{ marginBottom: "14px" }}>
              <FlexContainer
                style={{ width: "100%", maxWidth: "700px", margin: "0 auto" }}
                justify="space-between"
                align="center"
                gap="14px"
              >
                <FilterByName setFilter={buildFilters}></FilterByName>
              </FlexContainer>
            </Grid.Col>
          </Grid.Row>
        </Center>
        <Separator size={12}></Separator>
        {/*     <ToggleSlider>
          {filterValues.map((filterValue) => {
            return (
              <FilterButton
                setFilter={buildFilters}
                name={filterValue.name}
                fieldName={filterValue.fieldName}
                value={filterValue.value}
                filters={applyedFilters}
                {...((!!filterValue.special || !!filterValue.groupal) && {
                  special: !!filterValue.special
                    ? { name: "special", function: filterValue.special }
                    : { name: "groupal", function: filterValue.groupal },
                })}
              ></FilterButton>
            );
          })}
        </ToggleSlider> */}
        <Separator size={12}></Separator>
        <Grid.Row>
          <Grid.Col>
            <InfinityScroll
              action={fetchData}
              page={page}
              setPage={setPage}
              data={filteredCurrentRoadmapsListValues}
              active={thereAreItems}
              isLoading={isLoading}
            >
              <GridStructure
                width={"300px"}
                maxWidth="auto"
                style={{ width: "100%", margin: "0 auto" }}
              >
                {filteredCurrentRoadmapsListValues.map((item) => {
                  const academyFounded = academies.find(
                    (academy) => academy._id === item.academy
                  );
                  return (
                    <GridCard
                      onClick={() => {
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                        navigate(`/dashboard/roadmaps/${item._id}`);
                      }}
                      pd={"12px"}
                      key={item._id}
                    >
                      <GridCardImageContainer>
                        <GridCardImage
                          src={
                            item.picture ||
                            "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                          }
                        ></GridCardImage>
                      </GridCardImageContainer>

                      <FlexContainer style={{ marginLeft: "5px" }} gap="12px">
                        <GridCardSmallContainer>
                          <GridCardImage
                            src={
                              academyFounded?.picture ||
                              "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                            }
                          ></GridCardImage>
                        </GridCardSmallContainer>
                        <FlexContainer direction="column">
                          <GridCardTitle>{item.name}</GridCardTitle>
                          <FlexContainer gap="6px" align="center" wrap="wrap">
                            <FlexContainer gap="2px" align="center">
                              <DotContent></DotContent>
                              <GridComment>
                                {` Vistas: `}
                                <GridComment>{item.viewCount}</GridComment>
                              </GridComment>
                            </FlexContainer>
                            <FlexContainer gap="2px" align="center">
                              <DotContent></DotContent>
                              <GridComment>
                                {DateFromNow(item.createdAt)}
                              </GridComment>
                            </FlexContainer>
                          </FlexContainer>
                        </FlexContainer>
                      </FlexContainer>
                    </GridCard>
                  );
                })}
              </GridStructure>
            </InfinityScroll>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </>
  );
};

const states = ({ roadmapStore, cohortStore, academyStore, userStore }) => {
  const { data: roadmaps, states: roadmapsStates } = roadmapStore.all;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: newRoadmap, states: newRoadmapStates } = roadmapStore.new;
  const { data: academies } = academyStore.all;
  const { data: user } = userStore;
  return {
    roadmaps,
    newRoadmap,
    newRoadmapStates,
    cohorts,
    academies,
    user,
    roadmapsStates,
  };
};

export default connect(states)(Component);
