import styled from "styled-components";

export const AnalyticCardWrapper = styled("div")`
  padding: 10px 11px 12px 10px;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(21, 115, 229, 0.16);
  background-color: #fff;
`;
export const AnalyticCardTitle = styled("h3")`
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
`;
export const AnalyticCardSubTitle = styled("h3")`
  font-size: 10px;
  font-weight: 500;
 color: #697482;
}
`;

export const AnalyticCardSection = styled("div")`
  padding: 10px 10px 0px 10px;
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(21, 115, 229, 0.16);
  background-color: #fff;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
`;
