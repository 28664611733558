import { SignInData, SignOutData } from "../../types/auth.dto";
import {
  SignIn,
  SignOut,
  SIGN_IN,
  SIGN_OUT,
  AUTO_SIGN_IN,
  RESET_SIGN_UP,
  SignUp,
  SIGN_UP,
  RESET_SIGN_IN,
  SIGN_IN_WITH_SOCIAL,
  SIGN_UP_WITH_SOCIAL,
  RecoverPassword,
  RECOVER_PASSWORD,
  RESET_PASSWORD,
  ResetPassword,
  ResetRecoverPassword,
  RESET_RECOVER_PASSWORD,
  ResetResetPassword,
  RESET_RESET_PASSWORD,
  RESET_AUTO_SIGN_IN,
} from "../types/auth";

export const signIn = (value: SignInData): SignIn => {
  return { type: SIGN_IN, payload: value };
};

export const signInSocial = (payload) => {
  return { type: SIGN_IN_WITH_SOCIAL, payload };
};

export const signUpSocial = (payload) => {
  return { type: SIGN_UP_WITH_SOCIAL, payload };
};

export const resetSignIn = () => {
  return { type: RESET_SIGN_IN, payload: null };
};

export const signOut = (value: SignOutData = {}): SignOut => {
  return { type: SIGN_OUT, payload: value };
};

export const autoSignIn = () => {
  return { type: AUTO_SIGN_IN, payload: null };
};
export const resetSignUp = () => {
  return { type: RESET_SIGN_UP, payload: null };
};

export const resetAutoSignIn = () => {
  return { type: RESET_AUTO_SIGN_IN, payload: null };
};

export const signUp = (value: any): SignUp => {
  return { type: SIGN_UP, payload: value };
};

export const recoverPassword = (payload: {
  email: string;
}): RecoverPassword => {
  return { type: RECOVER_PASSWORD, payload };
};

export const resetRecoverPassword = (): ResetRecoverPassword => {
  return { type: RESET_RECOVER_PASSWORD, payload: null };
};

export const resetPassword = (payload: {
  id: string;
  password: string;
}): ResetPassword => {
  return { type: RESET_PASSWORD, payload };
};

export const resetResetPassword = (): ResetResetPassword => {
  return { type: RESET_RESET_PASSWORD, payload: null };
};
