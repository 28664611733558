import { GeneralContentDto } from "../../types/generalContent.dto";

export const GET_ALL_GENERALCONTENTS = "GET_ALL_GENERALCONTENT";
export const GET_ALL_GENERALCONTENTS_ERROR = "GET_ALL_GENERALCONTENT_ERROR";
export const GET_ALL_GENERALCONTENTS_SUCCESS = "GET_ALL_GENERALCONTENT_SUCCESS";
export const GET_GENERALCONTENT = "GET_GENERALCONTENT";
export const GET_GENERALCONTENT_ERROR = "GET_GENERALCONTENT_ERROR";
export const GET_GENERALCONTENT_SUCCESS = "GET_GENERALCONTENT_SUCCESS";
export const RESET_GET_GENERALCONTENT = "RESET_GET_GENERALCONTENT";
export const SET_GET_GENERALCONTENT = "SET_GET_GENERALCONTENT";
export const NEW_GENERALCONTENT = "NEW_GENERALCONTENT";
export const NEW_GENERALCONTENT_ERROR = "NEW_GENERALCONTENT_ERROR";
export const NEW_GENERALCONTENT_SUCCESS = "NEW_GENERALCONTENT_SUCCESS";
export const RESET_NEW_GENERALCONTENT = "RESET_NEW_GENERALCONTENT";
export const UPDATE_GENERALCONTENT = "UPDATE_GENERALCONTENT";
export const UPDATE_GENERALCONTENT_ERROR = "UPDATE_GENERALCONTENT_ERROR";
export const UPDATE_GENERALCONTENT_SUCCESS = "UPDATE_GENERALCONTENT_SUCCESS";
export const RESET_UPDATE_GENERALCONTENT = "RESET_UPDATE_GENERALCONTENT";
export const DELETE_GENERALCONTENT = "DELETE_GENERALCONTENT";
export const DELETE_GENERALCONTENT_ERROR = "DELETE_GENERALCONTENT_ERROR";
export const DELETE_GENERALCONTENT_SUCCESS = "DELETE_GENERALCONTENT_SUCCESS";
export const RESET_DELETE_GENERALCONTENT = "RESET_DELETE_GENERALCONTENT";
export const UPLOAD_MEDIA = "UPLOAD_MEDIA";
export const UPDATE_GENERALCONTENT_LOADING = "UPDATE_GENERALCONTENT_LOADING";
export const ADD_USER_TO_GENERALCONTENT = "ADD_USER_TO_GENERALCONTENT";
export const SHARED_GENERALCONTENT = "SHARED_GENERALCONTENT";
export const UNSAVED_GENERALCONTENT = "UNSAVED_GENERALCONTENT";
export const SAVED_GENERALCONTENT = "SAVED_GENERALCONTENT";
export const QUERY_GENERALCONTENT = "QUERY_GENERALCONTENT";
export const QUERY_GENERALCONTENT_SUCCESS = "QUERY_GENERALCONTENT_SUCCESS";
export const QUERY_GENERALCONTENT_ERROR = "QUERY_GENERALCONTENT_ERROR";
export const RESET_QUERY_GENERALCONTENT = "RESET_QUERY_GENERALCONTENT";
export const ADD_CLICK_GENERALCONTENT = "ADD_CLICK_GENERALCONTENT";
export const ADD_VIEW_GENERALCONTENT = "ADD_VIEW_GENERALCONTENT";
export const UPDATE_CURRENT_GENERALCONTENT_LIST =
  "UPDATE_CURRENT_GENERALCONTENT_LIST";
export const MANAGE_LIKE_DISLIKE_GENERALCONTENT =
  "MANAGE_LIKE_DISLIKE_GENERALCONTENT";
export const UPDATE_ONE_GENERALCONTENT = "UPDATE_ONE_GENERALCONTENT";
export const UPDATE_ONE_GENERALCONTENT_SUCCESS =
  "UPDATE_ONE_GENERALCONTENT_SUCCESS";
export const UPDATE_ONE_GENERALCONTENT_ERROR =
  "UPDATE_ONE_GENERALCONTENT_ERROR";
export const RESET_UPDATE_ONE_GENERALCONTENT =
  "RESET_UPDATE_ONE_GENERALCONTENT";
export const GET_GENERALCONTENTS_OFFSET = "GET_GENERALCONTENTS_OFFSET";
export const GET_GENERALCONTENTS_OFFSET_SUCCESS =
  "GET_GENERALCONTENTS_OFFSET_SUCCESS";
export const GET_GENERALCONTENTS_OFFSET_ERROR =
  "GET_GENERALCONTENTS_OFFSET_ERROR";
export const RESET_GET_GENERALCONTENTS_OFFSET =
  "RESET_GET_GENERALCONTENTS_OFFSET";
export const RESET_GET_ALL_GENERALCONTENT_DATA =
  "RESET_GET_ALL_GENERALCONTENT_DATA";
export const RESET_GET_ONE_GENERALCONTENT_DATA =
  "RESET_GET_ONE_GENERALCONTENT_DATA";
export const UPDATE_ITEM_LIST_GENERALCONTENT =
  "UPDATE_ITEM_LIST_GENERALCONTENT";

export interface ResetGetAllGeneralContentData {
  type: typeof RESET_GET_ALL_GENERALCONTENT_DATA;
  payload: null;
}

export interface UpdateItemListGeneralContent {
  type: typeof UPDATE_ITEM_LIST_GENERALCONTENT;
  payload: any;
}

export interface ResetGetOneGeneralContentData {
  type: typeof RESET_GET_ONE_GENERALCONTENT_DATA;
  payload: null;
}

export interface GetGeneralContentsOffset {
  type: typeof GET_GENERALCONTENTS_OFFSET;
  payload: { limit: number; offset: number };
}

export interface GetGeneralContentsOffsetError {
  type: typeof GET_GENERALCONTENTS_OFFSET_ERROR;
  payload: string | boolean;
}

export interface GetGeneralContentsOffsetSuccess {
  type: typeof GET_GENERALCONTENTS_OFFSET_SUCCESS;
  payload: GeneralContentDto[];
}

export interface ResetGetGeneralContentsOffset {
  type: typeof RESET_GET_GENERALCONTENTS_OFFSET;
  payload: null;
}
export interface UpdateOneGeneralContent {
  type: typeof UPDATE_ONE_GENERALCONTENT;
  payload: any;
}
export interface UpdateOneGeneralContentSuccess {
  type: typeof UPDATE_ONE_GENERALCONTENT_SUCCESS;
  payload: GeneralContentDto;
}
export interface UpdateOneGeneralContentError {
  type: typeof UPDATE_ONE_GENERALCONTENT_ERROR;
  payload: boolean | string;
}
export interface ResetUpdateOneGeneralContent {
  type: typeof RESET_UPDATE_ONE_GENERALCONTENT;
  payload: null;
}
export interface ManageLikeDislikeGeneralContent {
  type: typeof MANAGE_LIKE_DISLIKE_GENERALCONTENT;
  payload: {
    _id: string;
    user: string;
    condition: "like" | "dislike";
    action: "add" | "remove";
  };
}

export interface AddClickGeneralContent {
  type: typeof ADD_CLICK_GENERALCONTENT;
  payload: { _id: string; currentCohort?: string; currentAcademy?: string };
}
export interface AddViewGeneralContent {
  type: typeof ADD_VIEW_GENERALCONTENT;
  payload: { _id: string };
}

export interface SavedGeneralContent {
  type: typeof SAVED_GENERALCONTENT;
  payload: string;
}
export interface UnSavedGeneralContent {
  type: typeof UNSAVED_GENERALCONTENT;
  payload: string;
}
export interface SharedGeneralContent {
  type: typeof SHARED_GENERALCONTENT;
  payload: any;
}
export interface GetAllGeneralContents {
  type: typeof GET_ALL_GENERALCONTENTS;
  payload: null;
}

export interface GetAllGeneralContentsSuccess {
  type: typeof GET_ALL_GENERALCONTENTS_SUCCESS;
  payload: GeneralContentDto[];
}

export interface SetGetGeneralContent {
  type: typeof SET_GET_GENERALCONTENT;
  payload: GeneralContentDto | null;
}

export interface GetAllGeneralContentsError {
  type: typeof GET_ALL_GENERALCONTENTS_ERROR;
  payload: boolean | string;
}

export interface UpdateCurrentGeneralContentList {
  type: typeof UPDATE_CURRENT_GENERALCONTENT_LIST;
  payload: GeneralContentDto[];
}

export interface AddUserToGeneralContent {
  type: typeof ADD_USER_TO_GENERALCONTENT;
  payload: any;
}

export interface GetGeneralContent {
  type: typeof GET_GENERALCONTENT;
  payload: { id: string };
}

export interface GetGeneralContentSuccess {
  type: typeof GET_GENERALCONTENT_SUCCESS;
  payload: GeneralContentDto;
}

export interface GetGeneralContentError {
  type: typeof GET_GENERALCONTENT_ERROR;
  payload: boolean | string;
}

export interface QueryGeneralContent {
  type: typeof QUERY_GENERALCONTENT;
  payload: string;
}
export interface QueryGeneralContentSuccess {
  type: typeof QUERY_GENERALCONTENT_SUCCESS;
  payload: GeneralContentDto[];
}
export interface QueryGeneralContentError {
  type: typeof QUERY_GENERALCONTENT_ERROR;
  payload: string | boolean;
}

export interface ResetQueryGeneralContent {
  type: typeof RESET_QUERY_GENERALCONTENT;
  payload: null;
}

export interface NewGeneralContent {
  type: typeof NEW_GENERALCONTENT;
  payload: GeneralContentDto;
}

export interface NewGeneralContentSuccess {
  type: typeof NEW_GENERALCONTENT_SUCCESS;
  payload: GeneralContentDto;
}

export interface ResetGetGeneralContent {
  type: typeof RESET_GET_GENERALCONTENT;
  payload: null;
}

export interface NewGeneralContentError {
  type: typeof NEW_GENERALCONTENT_ERROR;
  payload: boolean | string;
}

export interface UploadMedia {
  type: typeof UPLOAD_MEDIA;
  payload: any;
}

export interface ResetNewGeneralContent {
  type: typeof RESET_NEW_GENERALCONTENT;
  payload: null;
}

export interface UpdateGeneralContent {
  type: typeof UPDATE_GENERALCONTENT;
  payload: any;
}

export interface UpdateGeneralContentLoading {
  type: typeof UPDATE_GENERALCONTENT_LOADING;
  payload: GeneralContentDto;
}

export interface UpdateGeneralContentSuccess {
  type: typeof UPDATE_GENERALCONTENT_SUCCESS;
  payload: GeneralContentDto;
}

export interface UpdateGeneralContentError {
  type: typeof UPDATE_GENERALCONTENT_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateGeneralContent {
  type: typeof RESET_UPDATE_GENERALCONTENT;
  payload: null;
}

export interface DeleteGeneralContent {
  type: typeof DELETE_GENERALCONTENT;
  payload: { _id: string };
}

export interface DeleteGeneralContentSuccess {
  type: typeof DELETE_GENERALCONTENT_SUCCESS;
  payload: null;
}

export interface DeleteGeneralContentError {
  type: typeof DELETE_GENERALCONTENT_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteGeneralContent {
  type: typeof RESET_DELETE_GENERALCONTENT;
  payload: null;
}

export interface State {
  all: {
    data: GeneralContentDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  generalContent: {
    data: GeneralContentDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  offSet: {
    data: GeneralContentDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  new: {
    data: GeneralContentDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  query: {
    data: null | GeneralContentDto[];
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateOne: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  query: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  offSet: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  generalContent: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  new: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateOne: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllGeneralContents
  | GetAllGeneralContentsSuccess
  | GetAllGeneralContentsError
  | GetGeneralContent
  | GetGeneralContentSuccess
  | GetGeneralContentError
  | NewGeneralContent
  | NewGeneralContentSuccess
  | NewGeneralContentError
  | ResetNewGeneralContent
  | UpdateGeneralContent
  | UpdateGeneralContentSuccess
  | UpdateGeneralContentError
  | ResetUpdateGeneralContent
  | DeleteGeneralContent
  | DeleteGeneralContentSuccess
  | DeleteGeneralContentError
  | ResetDeleteGeneralContent
  | UploadMedia
  | UpdateGeneralContentLoading
  | ResetGetGeneralContent
  | AddUserToGeneralContent
  | SharedGeneralContent
  | SetGetGeneralContent
  | SavedGeneralContent
  | UnSavedGeneralContent
  | QueryGeneralContent
  | QueryGeneralContentSuccess
  | QueryGeneralContentError
  | ResetQueryGeneralContent
  | AddClickGeneralContent
  | UpdateCurrentGeneralContentList
  | AddViewGeneralContent
  | ManageLikeDislikeGeneralContent
  | UpdateOneGeneralContent
  | UpdateOneGeneralContentSuccess
  | UpdateOneGeneralContentError
  | ResetUpdateOneGeneralContent
  | GetGeneralContentsOffset
  | GetGeneralContentsOffsetError
  | GetGeneralContentsOffsetSuccess
  | ResetGetGeneralContentsOffset
  | ResetGetAllGeneralContentData
  | ResetGetOneGeneralContentData
  | UpdateItemListGeneralContent;
