import styled from "styled-components";
import minus from "../../assets/icons/minus.svg";
import plus from "../../assets/icons/plus.svg";
import edit from "../../assets/icons/edit.svg";

export const PageTitle = styled("h1")`
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.Night};
  margin-top: 0px;
  margin-bottom: 40px;
  text-align: left;
`;

export const Line = styled("div")`
  height: ${(props) => props.height}px;
  background-color: rgba(209, 209, 209, 0.6);
`;

export const Input = styled("input")`
  position: relative;
  outline: none;
  border: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 40px;
  outline: none;
  font-size: 12px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  /*  border: 2px solid #ffffff; */
  /* box-shadow: 0 0 6px 0 rgba(79, 38, 162, 0.16); */
  background: #ffffff inset !important;
  &::placeholder {
    color: rgba(106, 105, 112, 0.6);
  }
  color: ${(props) => props.theme.colors.LightBlue};
`;

export const ButtonFilter = styled("div")`
  padding: 6px;
  border-radius: 10px;
  width: 32px;
  height: 32px;
  border: solid 1px ${(props) => props.theme.colors.LightBlue};
  background-color: #fff;
  color: ${(props) => props.theme.colors.LightBlue};
  &:hover {
    background-color: ${(props) => props.theme.colors.LightBlue};
    color: #fff;
  }
`;

export const FlexContainer = styled("div")`
  display: flex;
  align-items: ${(props) => (!!props.align ? props.align : "stretch")};
  justify-content: ${(props) => (!!props.justify ? props.justify : "stretch")};
  flex-direction: ${(props) => (!!props.direction ? props.direction : "row")};
  flex-wrap: ${(props) => (!!props.wrap ? props.wrap : "nowrap")};
  gap: ${(props) => props.gap};
  ${(props) => !!props.style && props.style};

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SectionTitle = styled("h2")`
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.Black};
`;

export const LabelScore = styled("h5")`
  font-size: 16px;
  font-weight: 500;
  opacity: 0.8;
  color: ${({ theme }) => theme.colors.Black};
`;

export const GridSwitchStructure = styled("div")`
  ${(props: any) => {
    return `
    display: grid;
  width: 90%;
  max-width: ${
    !!props.maxWidth ? (!!props.grid ? props.maxWidth : props.maxWidthLine) : ""
  };
  justify-content: center;
  margin: 0 auto;
  gap: 20px;
  ${
    !!props.grid
      ? ` grid-template-columns: ${`repeat(${
          !!props.type ? props.type : "auto-fill"
        }, minmax(${props.width}, 1fr))`};`
      : `grid-template-columns: 1fr`
  }
    
    `;
  }}
`;

// export const ScrollTable = styled("div")`
//   width: 100%;
//   display: flex;
//   ::-webkit-scrollbar {
//     width: 20px;
//     height: 15px;
//   }
//   ::-webkit-scrollbar-track {
//     border-radius: 10px;
//     background: rgba(0, 0, 0, 0.1);
//   }
//   ::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     background: rgba(0, 0, 0, 0.2);
//   }
//   ::-webkit-scrollbar-thumb:hover {
//     background: rgba(0, 0, 0, 0.4);
//   }
//   ::-webkit-scrollbar-thumb:active {
//     background: rgba(0, 0, 0, 0.9);
//   }
// `;

export const AddButton = styled("button")`
  border: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.Primary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:before {
    content: "";
    mask: url(${plus});
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    width: 100%;
    height: 100%;
    background-color: white;
  }
`;

export const Separator = styled("div")`
  height: ${(props) => props.size}px;
`;

export const DotContent = styled("div")`
  width: 6px;
  height: 6px;
  background-color: #d9d9d9;
`;

export const SearchMagnifying = styled("span")`
  position: relative;
  display: inline-block;
  background: #fff;
  border-radius: 30px;
  height: 10px;
  width: 10px;
  border: 2px solid #888;

  &:after {
    content: "";
    height: 2px;
    width: 6px;
    background: #888;
    position: absolute;
    top: 7px;
    left: 5px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
  }
`;

export const ItemDataText = styled("h5")`
  font-size: 12px;
  color: #6a6970;
`;

export const EditButton = styled("button")`
  border: 0;
  width: 29px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;

  &:before {
    content: "";
    mask: url(${edit});
    mask-repeat: no-repeat;
    mask-size: cover;
    mask-position: center;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.Primary};
  }
`;

export const RemoveButton = styled("button")`
  border: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.Danger};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:before {
    content: "";
    mask: url(${minus});
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    width: 100%;
    height: 100%;
    background-color: white;
  }
`;

export const Center = styled("div")<{ gap?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${(props) => (props.gap ? props.gap : 0)};
`;

export const Card = styled("div")`
  padding: 10px;
  border-radius: 20px;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
`;

export const ScrollSection = styled("div")`
  width: 100%;
  overflow-y: auto;
  border-radius: ${(props) =>
    !!props.boderRadius ? props.boderRadius : "20px"};
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: white;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const NoteField = styled("input")`
  margin-left: 27px;
  width: 65px;
  height: 26px;
  border-radius: 3px;
  border: none;
  background-color: #f5f5f5;
`;

export const InLine = styled("div")<{
  justify: "center" | "space-between" | "flex-start" | "flex-end";
  align: "center" | "space-between" | "flex-start" | "flex-end";
}>`
  display: flex;
  position: relative;
  align-items: ${({ align }) => (align ? align : "center")};
  justify-content: ${({ justify }) => (justify ? justify : "center")};
`;
