// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rbc-event-content {
  display: block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  color: white !important;
  font-size: 10px !important;
  background-color: #2496ed !important;
  border-radius: 5px !important;
  padding: 0px 5px !important;
  border: 1px solid white !important;
}

.rbc-active {
  color: #2496ed !important;
  background-color: transparent;
}

.rbc-active::before {
  background-color: #2496ed !important;
}

.rbc-month-view {
  border-radius: 40px !important;
  box-shadow: 0 0 6px 0 rgba(79, 38, 162, 0.16) !important;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #2496ed !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Calendar/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,2BAA2B;EAC3B,kCAAkC;EAClC,8BAA8B;EAC9B,uBAAuB;EACvB,0BAA0B;EAC1B,oCAAoC;EACpC,6BAA6B;EAC7B,2BAA2B;EAC3B,kCAAkC;AACpC;;AAEA;EACE,yBAAyB;EACzB,6BAA6B;AAC/B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,8BAA8B;EAC9B,wDAAwD;AAC1D;;AAEA;;EAEE,oCAAoC;AACtC","sourcesContent":[".rbc-event-content {\r\n  display: block !important;\r\n  overflow: hidden !important;\r\n  text-overflow: ellipsis !important;\r\n  white-space: nowrap !important;\r\n  color: white !important;\r\n  font-size: 10px !important;\r\n  background-color: #2496ed !important;\r\n  border-radius: 5px !important;\r\n  padding: 0px 5px !important;\r\n  border: 1px solid white !important;\r\n}\r\n\r\n.rbc-active {\r\n  color: #2496ed !important;\r\n  background-color: transparent;\r\n}\r\n\r\n.rbc-active::before {\r\n  background-color: #2496ed !important;\r\n}\r\n\r\n.rbc-month-view {\r\n  border-radius: 40px !important;\r\n  box-shadow: 0 0 6px 0 rgba(79, 38, 162, 0.16) !important;\r\n}\r\n\r\n.rbc-event.rbc-selected,\r\n.rbc-day-slot .rbc-selected.rbc-background-event {\r\n  background-color: #2496ed !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
