import React, { useEffect, useState } from "react";
import {
  addViewVideo,
  getAllVideos,
  getVideo,
  queryVideo,
  resetGetAllVideos,
  saveVideo,
} from "../../../../store/actions/video";
import { connect, useDispatch } from "react-redux";
import { VideoDto } from "../../../../types/video.dto";
import { AcademyDto } from "../../../../types/academy.dto";
import { FaSave, FaShare } from "react-icons/fa";
import { BiLink } from "react-icons/bi";
import { toast } from "react-toastify";
import { AiOutlineCloudDownload } from "react-icons/ai";
import academyActions from "../../../../store/actions/academies";
import Loader from "../../../../components/Loader";
import { StateDto } from "../../../../types/states.dto";
import { saveAs } from "file-saver";
import usersActions from "../../../../store/actions/users";
import ListedVideos from "./components/ListedVideos";
import ShareModal from "./components/ShareModal";
import { showModal } from "../../../../store/actions/modal";
import { UserDto } from "../../../../types/user.dto";
import { useParams } from "react-router-dom";
import CommentSection from "../../../../components/CommentSection";
import { Separator } from "../../../../components/StyledComponents";
import {
  addClick,
  addView,
  userTime,
} from "../../../../store/actions/userTimes";
import useTrackTimeAndAPI from "../../../../hooks/useTrackTimeAndAPI";

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  queryVideos,
  videos,
  academies,
  queryVideosStates,
  video,
  user,
  videosStates,
}: {
  queryVideos: VideoDto[];
  videos: VideoDto[];
  academies: AcademyDto[];
  queryVideosStates: StateDto;
  user: UserDto;
  video: VideoDto;
  videosStates: StateDto;
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [queryMode, setQueryMode] = useState<string | null>(null);
  const [query, setQuery] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [filteredCurrentVideosListValues, setFilteredCurrentVideosListValues] =
    useState<VideoDto[]>([]);
  const [currentVideosList, setCurrentVideosList] = useState<VideoDto[]>([]);
  const [openDropDown, setOpenDropDown] = useState<string[]>([]);

  const handleQuery = (ev) => {
    const value = ev.target.value;
    setQuery(value.length === 0 ? "" : value);
  };
  const searchQuery = (ev) => {
    if (ev.keyCode === 13) {
      /*  dispatch(queryImage({ keyword: query })); */
      setQueryMode(query);
    }
  };
  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter((applyedFilter) =>
        !Array.isArray(newFilter.field)
          ? applyedFilter.field === newFilter.field
          : newFilter.field.includes(applyedFilter.field)
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    if (filterRemoved) {
      /**
       * Se elimina el filtro
       */
      if (Array.isArray(newFilter.field)) {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => !newFilter.field.includes(applyedFilter.field)
          ),
        ]);
      } else {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => applyedFilter.field !== newFilter.field
          ),
        ]);
      }
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };
  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 6,
      offset: 6 * Math.floor(page / 2),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        published: true,
      },
    };
    const sortBy = page % 2 === 0 ? "createdAt" : "viewCount";

    const sortDirection = "desc";
    dispatch(
      getAllVideos({
        ...payload,
        sortBy,
        sortDirection,
        ...(queryMode && { keyword: query }),
      })
    );
  };

  useEffect(() => {
    const filteredTalents = currentVideosList?.filter((currentTalentValues) => {
      const thereAreFilters =
        applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
          .length > 0;
      const totalOfFilters = applyedFilters.filter(
        (applyedFilter) => !applyedFilter.groupal
      ).length;
      const passFilters = applyedFilters
        .filter(
          (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
        )
        .filter(
          (applyedFilter) =>
            currentTalentValues[applyedFilter.field] === applyedFilter.value
        ).length;
      const specialFilter = applyedFilters.filter(
        (applyedFilter) => !!applyedFilter.special
      );

      const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
        return applyedFilter.special(
          currentTalentValues,
          applyedFilter.value,
          currentTalentValues
        );
      });
      return (
        !thereAreFilters ||
        (thereAreFilters &&
          totalOfFilters === passFilters + specialFilter.length &&
          speacialFilterCheck.length === specialFilter.length)
      );
    });
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentVideosListValues(initResult);
  }, [applyedFilters, currentVideosList]);
  const apiAction = (timeSpent) => {
    dispatch(
      userTime({ ...timeSpent, id: params.id, user: user._id, type: "VIDEO" })
    ); // Dispatch action to update Redux store or make API request
  };
  useTrackTimeAndAPI(apiAction, params.id && user);
  useEffect(() => {
    if (!isLoading && !!videos) {
      setCurrentVideosList((state) => [
        ...state,
        ...videos.filter((image) => image._id !== params.id),
      ]);
    }
    setThereAreItems(!!videos && videos?.length > 0);
  }, [videos, isLoading, params.id]);

  useEffect(() => {
    setIsLoading(videosStates.loading);
  }, [videosStates]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllVideos());
    };
  }, []);
  useEffect(() => {
    if (
      ((params.id && !video) ||
        (!!params.id && !!video && params.id !== video?._id)) &&
      !!user
    ) {
      dispatch(addView({ id: params.id, user: user._id, type: "CONTENT" }));
      dispatch(getVideo({ _id: params.id }));
    }
  }, [params.id, user]);
  const menu = (item) => [
    /*  {
      Icon: FaShare,
      onAvailable: () => !!item.available && !!item.published,
      onClick: () => {
        dispatch(showModal("share-user-video"));
      },
    }, */
    {
      Icon: BiLink,
      onClick: () => {
        navigator.clipboard.writeText(
          `https://academia.catel.org.ar/dashboard/video/${item._id}`
        );
        toast.success(`Link copiado`);
        dispatch(
          addClick({
            id: item._id,
            user: user._id,
            type: "CONTENT",
          })
        );
      },
    },

    /*  {
      Icon: FaSave,
      activeFunction: () => item.savedBy.includes(user._id),
      onClick: () => {
        dispatch(
          saveVideo({ _id: item._id, condition: "save", __v: item.__v })
        );
      },
      onClickActive: () => {
        dispatch(
          saveVideo({ _id: item._id, condition: "unsave", __v: item.__v })
        );
      },
    }, */
    /*  {
      Icon: AiOutlineCloudDownload,
      onClick: (ev) => {
        saveAs(item.url, `${item.name}.mp4`);
        toast.success(`Cdoc Descargado`);
      },
    }, */
  ];

  return (
    <>
      {!video ? (
        <Loader color="LightBlue"></Loader>
      ) : (
        <>
          <ListedVideos
            queryOptions={
              /* { handleQuery, searchQuery, query } */ {
                searchQuery: searchQuery,
                setFilter: handleQuery,
                value: query,
              }
            }
            scrollOptions={{
              fetchData,
              page,
              setPage,
              thereAreItems,
              isLoading,
            }}
            video={video}
            currentVideosList={filteredCurrentVideosListValues}
            menu={menu(video)}
            openDropDown={openDropDown}
            setOpenDropDown={setOpenDropDown}
          />

          <ShareModal video={video}></ShareModal>
        </>
      )}
    </>
  );
};

const states = ({ videoStore, academyStore, userStore }) => {
  const { data: queryVideos, states: queryVideosStates } = videoStore.query;
  const { data: video } = videoStore.video;
  const { data: videos, states: videosStates } = videoStore.all;
  const { data: academies } = academyStore.all;
  const { data: user } = userStore;
  return {
    queryVideos,
    videos,
    video,
    academies,
    queryVideosStates,
    user,
    videosStates,
  };
};

export default connect(states)(Component);
