import {
  initialState,
  Actions,
  State,
  GET_ALL_BYTES,
  GET_ALL_BYTES_ERROR,
  GET_ALL_BYTES_SUCCESS,
  GET_BYTE,
  GET_BYTE_ERROR,
  GET_BYTE_SUCCESS,
  NEW_BYTE,
  NEW_BYTE_ERROR,
  NEW_BYTE_SUCCESS,
  RESET_NEW_BYTE,
  UPDATE_BYTE,
  UPDATE_BYTE_ERROR,
  UPDATE_BYTE_SUCCESS,
  RESET_UPDATE_BYTE,
  DELETE_BYTE,
  DELETE_BYTE_ERROR,
  DELETE_BYTE_SUCCESS,
  RESET_DELETE_BYTE,
  UPLOAD_MEDIA,
  UPDATE_BYTE_LOADING,
  RESET_GET_BYTE,
  ADD_USER_TO_BYTE,
  SHARED_BYTE,
  QUERY_BYTE,
  QUERY_BYTE_SUCCESS,
  QUERY_BYTE_ERROR,
  RESET_QUERY_BYTE,
  SAVE_BYTE,
  UPDATE_CURRENT_BYTE_LIST,
  UPDATE_CURRENT_BYTE,
  SAVED_BYTE,
  UNSAVED_BYTE,
  ADD_VIEW_BYTE,
  RESET_UPDATE_ONE_BYTE,
  UPDATE_ONE_BYTE,
  UPDATE_ONE_BYTE_ERROR,
  UPDATE_ONE_BYTE_SUCCESS,
  MANAGE_LIKE_DISLIKE_BYTE,
  SET_GET_BYTE,
  GET_BYTES_OFFSET,
  GET_BYTES_OFFSET_ERROR,
  GET_BYTES_OFFSET_SUCCESS,
  RESET_GET_BYTES_OFFSET,
  SET_OFFSET_BYTE,
  RESET_GET_ONE_BYTE_DATA,
  RESET_GET_ALL_BYTE_DATA,
  UPDATE_ITEM_LIST_BYTE,
} from "../types/byte";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_BYTES:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_BYTES_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case RESET_GET_ONE_BYTE_DATA:
      return {
        ...state,
        byte: initialState.byte,
      };
    case RESET_GET_ALL_BYTE_DATA:
      return {
        ...state,
        all: initialState.all,
      };
    case GET_ALL_BYTES_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case SET_OFFSET_BYTE:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          data: action.payload,
        },
      };
    case UPDATE_ITEM_LIST_BYTE:
      return {
        ...state,
        update: {
          data: null,
          states: {
            success: false,
            error: false,
            loading: true,
          },
        },
      };
    case UPDATE_CURRENT_BYTE:
      return {
        ...state,
        byte: {
          ...state.byte,
          data: action.payload,
        },
      };
    case GET_BYTES_OFFSET:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case GET_BYTES_OFFSET_ERROR:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case GET_BYTES_OFFSET_SUCCESS:
      return {
        ...state,
        offSet: {
          data: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case RESET_GET_BYTES_OFFSET:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: false,
            success: false,
            error: false,
          },
        },
      };
    case UPDATE_CURRENT_BYTE_LIST:
      return {
        ...state,
        all: {
          ...state.all,
          data: action.payload,
        },
      };
    case SAVED_BYTE:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case UPDATE_ONE_BYTE:
      return {
        ...state,
        updateOne: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_ONE_BYTE_ERROR:
      return {
        ...state,
        updateOne: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case UPDATE_ONE_BYTE_SUCCESS:
      return {
        ...state,
        updateOne: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case RESET_UPDATE_ONE_BYTE:
      return {
        ...state,
        updateOne: {
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case MANAGE_LIKE_DISLIKE_BYTE:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SET_GET_BYTE:
      return {
        ...state,
        byte: {
          ...state.byte,
          data: action.payload,
        },
      };
    case ADD_VIEW_BYTE:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UNSAVED_BYTE:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SAVE_BYTE:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case QUERY_BYTE:
      return {
        ...state,
        query: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case QUERY_BYTE_SUCCESS:
      return {
        ...state,
        query: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case QUERY_BYTE_ERROR:
      return {
        ...state,
        query: {
          data: state.query.data,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_QUERY_BYTE:
      return {
        ...state,
        query: {
          ...state.query,
          states: initialState.query.states,
        },
      };
    case UPLOAD_MEDIA:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case ADD_USER_TO_BYTE:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SHARED_BYTE:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case RESET_GET_BYTE:
      return {
        ...state,
        byte: {
          ...state.byte,
          states: initialState.byte.states,
        },
      };
    case UPDATE_BYTE_LOADING:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_BYTE:
      return {
        ...state,
        byte: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_BYTE_SUCCESS:
      return {
        ...state,
        byte: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_BYTE_ERROR:
      return {
        ...state,
        byte: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_BYTE:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_BYTE_SUCCESS:
      return {
        ...state,
        new: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_BYTE_ERROR:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_BYTE:
      return {
        ...state,
        new: {
          data: state.new.data,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_BYTE:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_BYTE_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_BYTE_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_BYTE:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_BYTE:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_BYTE_SUCCESS:
      return {
        ...state,
        update: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_BYTE_ERROR:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_BYTE:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
