import Skeleton from "../../../../../components/Skeleton";
import Grid from "../../../../../components/Grid";
import { ListMediaTitle } from "./styles";
import { FlexContainer } from "../../../../../components/StyledComponents";

const Component = () => {
  return (
    <FlexContainer direction="column" gap="13px">
      <Skeleton
        itemsPerPage={1}
        itemsPerRow={1}
        animated
        size={{ height: 600 }}
        icon="cohort"
      />
      <Skeleton
        itemsPerPage={1}
        itemsPerRow={1}
        animated
        size={{ height: 300 }}
        icon="cohort"
      />
      <Skeleton
        itemsPerPage={1}
        itemsPerRow={1}
        animated
        size={{ height: 300 }}
        icon="cohort"
      />
      <Skeleton
        itemsPerPage={1}
        itemsPerRow={1}
        animated
        size={{ height: 200 }}
        icon="cohort"
      />
    </FlexContainer>
  );
};

export default Component;
