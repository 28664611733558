import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import {
  GrowingStage,
  GrowingStageArrow,
  GrowingStageValue,
  GrowingText,
} from "./styles";

interface ComponentProps {
  value: number;
  label: string;
}

const Component = ({ value, label }: ComponentProps) => {
  return (
    <GrowingStage>
      <GrowingStageValue>{`${value}%`}</GrowingStageValue>
      <GrowingText>{label}</GrowingText>
      {value > 0 ? (
        <GrowingStageArrow>
          <FaArrowUp color="#30ff22" fontSize={20} />
        </GrowingStageArrow>
      ) : (
        <GrowingStageArrow>
          <FaArrowDown color="#ff2222" fontSize={20} />
        </GrowingStageArrow>
      )}
    </GrowingStage>
  );
};

export default Component;
