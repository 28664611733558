import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import VarietySlide from "./components/VarietySlide";
import VideoSlide from "./components/VideoSlide";
import CdocSlide from "./components/CdocSlide";
import Carousel from "../../../../../../../components/AsynCarousel";
import { BannerTitle } from "../../../../../components/Banner/style";
import ModalConfirmDelete from "../../../../../../../components/ModalConfirmDelete";
import { getAllCDOCs } from "../../../../../../../store/actions/cdoc";
import { getAllVideos } from "../../../../../../../store/actions/video";
import contentActions from "../../../../../../../store/actions/content";
import { AcademyDto } from "../../../../../../../types/academy.dto";
import { ContentDto } from "../../../../../../../types/content.dto";
import { VideoDto } from "../../../../../../../types/video.dto";
import { CDocDto } from "../../../../../../../types/cdoc.dto";
import { StateDto } from "../../../../../../../types/states.dto";
import { showModal } from "../../../../../../../store/actions/modal";
import { UserDto } from "../../../../../../../types/user.dto";
import { getAllSlideShows } from "../../../../../../../store/actions/slideshow";
import { getAllImages } from "../../../../../../../store/actions/image";
import { ImageDto } from "../../../../../../../types/image.dto";
import { SlideShowDto } from "../../../../../../../types/slideshow.dto";
import { GeneralContentDto } from "../../../../../../../types/generalContent.dto";
import MediaItems from "../../../../../../Dashboard/Home/components/MediaItems";
import { addClick } from "../../../../../../../store/actions/userTimes";
import MediaItem from "../../../../../../Dashboard/Home/components/MediaItems/components/MediaItem";
import { HiPresentationChartBar } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { BsFillImageFill, BsFillPlayFill } from "react-icons/bs";
import { WrapperSlide } from "../../../../../../../components/Slider/style";

const ELEMENTS_PER_PAGE = 6;

interface FetchProps {
  page: number;
  filterBy?: { [key: string]: string | number | boolean | null };
}

type Content = ImageDto | VideoDto | SlideShowDto;

type ExtendedContent = Content & {
  type: "image" | "video" | "slideshow" | "unknow";
};

interface ComponentProps {
  contents: ContentDto[];
  contentsStates: StateDto;
  updateContentStates: StateDto;
  videos: VideoDto[];
  videosStates: StateDto;
  updateVideoStates: StateDto;
  cdocs: CDocDto[];
  cdocsStates: StateDto;
  updateCdocStates: StateDto;
  academy: AcademyDto;
  user: UserDto;
  images: ImageDto[];
  imagesStates: StateDto;
  slideshows: SlideShowDto[];
  slideshowsStates: StateDto;
}

const Component = ({
  contents,
  contentsStates,
  updateContentStates,
  videos,
  videosStates,
  updateVideoStates,
  cdocs,
  cdocsStates,
  updateCdocStates,
  academy,
  user,
  images,
  imagesStates,
  slideshows,
  slideshowsStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [action, setAction] = useState<{ action: string; data: any }>();
  const [currentSection, setCurrentSection] = useState<string | undefined>();
  const [currentStates, setCurrentStates] = useState<StateDto>();
  // const [selectedContent, setSelectedContent] = useState<{
  //   name: string;
  //   _id: string;
  // }>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [items, setItems] = useState<ExtendedContent[]>([]);

  const getCohorts = ({ page, filterBy }: FetchProps) => {
    /** REFACTORIZAR ESTO CUANDO CONTEMOS CON UN ENDPOINT PARA TRAERNOS TODO AGREGANDO FILTERBY */
    const fetchData = {
      videos: {
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          available: true,
          academy: academy._id,
        },
      },
      slideshows: {
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          available: true,
          academy: academy._id,
        },
      },
      images: {
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          available: true,
          academy: academy._id,
        },
      },
    };

    dispatch(getAllVideos(fetchData.videos));
    dispatch(getAllSlideShows(fetchData.slideshows));
    dispatch(getAllImages(fetchData.images));
  };

  useEffect(() => {
    if (!isLoading && !!academy._id) {
      /** REFACTORIZAR ESTO CUANDO CONTEMOS CON UN ENDPOINT PARA TRAERNOS TODO AGREGANDO FILTERBY */
      const allItems: (SlideShowDto | VideoDto | ImageDto)[] = [
        ...(slideshows && slideshows?.length > 0 ? slideshows : []),
        ...(videos && videos?.length > 0 ? videos : []),
        ...(images && images?.length > 0 ? images : []),
      ].filter(
        (item) =>
          item.active &&
          item.published &&
          item.academy._id === academy._id &&
          item.available
      );

      // Create a new array with the "type" property added to each item
      const allItemsWithType: ExtendedContent[] = allItems.map(
        (item): ExtendedContent => {
          if (images?.includes(item as ImageDto)) {
            return { ...item, type: "image" };
          } else if (videos?.includes(item as VideoDto)) {
            return { ...item, type: "video" };
          } else if (slideshows?.includes(item as SlideShowDto)) {
            return { ...item, type: "slideshow" };
          }
          return { ...item, type: "unknow" };
        }
      );

      const allItemsSortedByViewed = allItemsWithType
        .sort((a, b) => a.viewCount - b.viewCount)
        .reverse();
      const first18Items = allItemsSortedByViewed.slice(0, 18);

      setItems((states) => [...states, ...first18Items]);

      setThereAreItems(
        (!!images &&
          images?.length > 0 &&
          images?.length === ELEMENTS_PER_PAGE) ||
          (!!videos &&
            videos?.length > 0 &&
            videos?.length === ELEMENTS_PER_PAGE) ||
          (!!slideshows &&
            slideshows?.length > 0 &&
            slideshows?.length === ELEMENTS_PER_PAGE)
      );
    }
  }, [
    images,
    videos,
    slideshows,
    academy._id,
    /*  updateContentStates,
    updateVideoStates,
    updateCdocStates, */
    isLoading,
  ]);

  useEffect(() => {
    setIsLoading(
      slideshowsStates.loading || videosStates.loading || imagesStates.loading
    );
  }, [slideshowsStates, videosStates, imagesStates]);

  useEffect(() => {
    if (currentSection === "cdoc") {
      setCurrentStates(updateCdocStates);
    }
    if (currentSection === "video") {
      setCurrentStates(updateVideoStates);
    }
    if (currentSection === "variety") {
      setCurrentStates(updateContentStates);
    }
  }, [
    currentSection,
    updateContentStates,
    updateVideoStates,
    updateCdocStates,
  ]);

  return (
    <>
      <BannerTitle size="16px">Contenidos disponibles</BannerTitle>
      <div style={{ padding: "0px 20px" }}>
        <Carousel
          data={items}
          isLoading={isLoading}
          active={thereAreItems}
          action={getCohorts}
          setPage={setPage}
          page={page}
        >
          {items.map((content: ExtendedContent) =>
            content.type === "slideshow" ? (
              <WrapperSlide>
                <MediaItem
                  cardStyle={{
                    maxWidthCard: "278px",
                    cardImageHeight: "80px",
                  }}
                  size={"lg"}
                  onClick={() => {
                    navigate(`/dashboard/slideshows/${content._id}`);
                  }}
                  item={{ ...content, icon: HiPresentationChartBar }}
                  logoOnclick={() => {
                    navigate(`/dashboard/academy/${content.academy._id}`);
                  }}
                ></MediaItem>
              </WrapperSlide>
            ) : content.type === "video" ? (
              <WrapperSlide>
                <MediaItem
                  cardStyle={{
                    maxWidthCard: "278px",
                    cardImageHeight: "80px",
                  }}
                  size={"lg"}
                  onClick={() => {
                    navigate(`/dashboard/videos/${content._id}`);
                  }}
                  item={{ ...content, icon: BsFillPlayFill }}
                  logoOnclick={() => {
                    navigate(`/dashboard/academy/${content.academy._id}`);
                  }}
                ></MediaItem>
              </WrapperSlide>
            ) : content.type === "image" ? (
              <WrapperSlide>
                <MediaItem
                  size={"lg"}
                  onClick={() => {
                    navigate(`/dashboard/images/${content._id}`);
                  }}
                  item={{ ...content, icon: BsFillImageFill }}
                  logoOnclick={() => {
                    navigate(`/dashboard/academy/${content.academy._id}`);
                  }}
                  cardStyle={{
                    maxWidthCard: "278px",
                    cardImageHeight: "80px",
                  }}
                ></MediaItem>
              </WrapperSlide>
            ) : null
          )}
        </Carousel>
      </div>

      {action?.action === "unsave-content" && currentStates && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Quitar",
            style: {
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={currentStates}
          title={
            <span>
              {`¿Seguro que quiere quitar el contenido `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {action.data.name}
              </span>
              ?
            </span>
          }
          externalFunction={action.data.externalFunction}
          elementActions={action.data.action}
          resetAction={action.data.resetAction}
          resetState={setAction}
        />
      )}
    </>
  );
};

const states = ({
  academyStore,
  contentStore,
  videoStore,
  cdocStore,
  userStore,
  slideshowStore,
  imageStore,
}) => {
  const { data: academy } = academyStore.selected;
  const { data: contents, states: contentsStates } = contentStore.all;
  const { data: videos, states: videosStates } = videoStore.all;
  const { data: cdocs, states: cdocsStates } = cdocStore.all;
  const { data: images, states: imagesStates } = imageStore.all;
  const { data: slideshows, states: slideshowsStates } = slideshowStore.all;
  const { states: updateCdocStates } = cdocStore.update;
  const { states: updateVideoStates } = videoStore.update;
  const { states: updateContentStates } = contentStore.update;
  const { data: user } = userStore;
  return {
    contents,
    contentsStates,
    updateContentStates,
    videos,
    videosStates,
    updateVideoStates,
    cdocs,
    cdocsStates,
    updateCdocStates,
    academy,
    user,
    images,
    imagesStates,
    slideshows,
    slideshowsStates,
  };
};

export default connect(states)(Component);
