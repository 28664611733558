import styled from "styled-components";

export const CardWrapper = styled("div")`
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(79, 38, 162, 0.16);
  width: 100%;
  padding: 20px 50px;
  ${(props) => (!!props.isMobile ? "padding:10px 4px" : "")};
  background-color: #fff;
`;

export const GeneralContent = styled("div")`
  border-radius: 10px;
  border: solid 1px #2496ed;
  width: 100px;
  height: 100px;
  cursor: pointer;
  overflow: hidden;
`;

export const AcademyLogo = styled("div")`
  width: 32px;
  height: 32px;
  border-radius: 5px;
  overflow: hidden;
`;

export const AcademyLogoText = styled("h3")`
  font-size: 13px;
  font-weight: ${(props) => (props.bl ? "bold" : "normal")};
  color: ${(props) => (props.bl ? props.theme.colors.Night : "#6a6970")};
  word-break: break-all;
`;

export const LikeDislikeBoxNumber = styled("div")`
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out;
  h3 {
    transition: color 0.3s ease-in-out;
  }
`;

export const LikeDislikeBox = styled("div")`
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;
  border: solid 1px #2496ed;

  &:not(.active):hover .count-likes {
    background-color: rgba(36, 150, 237, 0.15);
  }

  &.active .count-likes {
    background-color: #2496ed;
    h3 {
      color: #fff;
    }
  }
  & .like-dislike-icon {
    background-color: #fff;
  }
  &.active .like-dislike-icon {
    background-color: #2496ed;
  }

  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
`;

export const LikeDislikeText = styled("h3")`
  font-size: 12px;
  font-weight: 600;
  color: #2496ed;
  transition: color 0.3s ease-in-out;
`;

export const BannerCohortPhoto = styled("div")`
  border-radius: 5px;
  min-width: 60px;
  width: 60px;
  height: 60px;
  overflow: hidden;
  box-shadow: 0 0 3px 0 rgba(79, 38, 162, 0.16);
`;

export const BannerImageContainer = styled("div")`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  height: 203px;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 0 16px 0;
  width: 100%;
`;

export const CohortBoldFont = styled("h3")`
  font-family: Lato;
  font-size: ${(props) => (!!props.size ? props.size : "16px")};
  font-weight: bold;
  color: ${(props) => props.theme.colors.Night};
`;

export const CohortLabelFont = styled("h3")`
  font-family: Lato;
  font-size: 12px;
  font-weight: 300;
  color: ${(props) => props.theme.colors.Night};
`;

export const CohortMediumFont = styled("h3")`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  color: ${(props) => props.theme.colors.Night};
`;
