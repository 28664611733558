import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Document, Page } from "react-pdf";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { getCookie, setCookie } from "../../helpers/cookies-handler";
import {
  Wrapper,
  CurrentSlide,
  Buttons,
  Prev,
  Next,
  FullScreenButton,
  ArrowMobileButton,
} from "./styles";
import { BsFullscreen } from "react-icons/bs";
import FullScreenSlideshow from "../../pages/Dashboard/SlideShow/View/components/FullScreenSlideshow";
import { useWindowSize } from "../../hooks/useWindowScreen";
import { FlexContainer } from "../StyledComponents";
import { useTheme } from "styled-components";
interface ComponentProps {
  src: string;
  id?: string;
  startFrom?: number;
  style?: CSSProperties;
  pdfUrl: string;
}

const Component = ({
  src,
  id,
  startFrom = 1,
  style,
  pdfUrl,
}: ComponentProps) => {
  const cookieName = "DEVPLACE_VIEWED_SLIDESHOWS";
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(startFrom);
  const [onFullScreen, setOnFullScreen] = useState(false);
  const [doc, setDoc] = useState<DocumentCallback>();
  const pdfRef: any = useRef(null);
  const { isMobile } = useWindowSize();
  const numPages = useMemo(() => doc?.numPages, [doc]);
  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (
      event.key === "ArrowRight" &&
      !!numPages &&
      currentPage < numPages
    ) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      saveProgress(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (numPages && currentPage < numPages) {
      setCurrentPage(currentPage + 1);
      saveProgress(currentPage + 1);
    }
  };

  const handleOnLoad = (doc) => {
    setDoc(doc);
  };

  const saveProgress = (current) => {
    const cookie = getCookie(cookieName);

    if (cookie) {
      const slideshowsViewed = JSON.parse(cookie);
      const otherSlideshowsViewed = slideshowsViewed.filter(
        (viewedslideshow) => viewedslideshow.id !== id
      );
      const slideshowViewed = slideshowsViewed.find(
        (viewedslideshow) => viewedslideshow.id === id
      );

      if (slideshowViewed) {
        setCookie(
          cookieName,
          JSON.stringify([
            ...otherSlideshowsViewed,
            { ...slideshowViewed, startFrom: current },
          ])
        );
      }

      if (!slideshowViewed) {
        setCookie(
          cookieName,
          JSON.stringify([...otherSlideshowsViewed, { id, startFrom: current }])
        );
      }
    } else {
      setCookie(cookieName, JSON.stringify([{ id, startFrom: current }]));
    }
  };

  useEffect(() => {
    const cookie = getCookie(cookieName);

    if (cookie) {
      const slideshowsViewed = JSON.parse(cookie);
      const slideshow = slideshowsViewed.find(
        (viewedslideshow) => viewedslideshow.id === id
      );

      if (slideshow) {
        setCurrentPage(slideshow.startFrom);
      }
    }
  }, [src]);

  useEffect(() => {
    if (doc && pdfRef) {
      pdfRef.current.focus();
    }
  }, [doc]);

  return (
    <>
      <Wrapper
        ref={pdfRef}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        style={{ ...style, ...(isMobile && { borderRadius: "0px" }) }}
      >
        {!isMobile && (
          <CurrentSlide>
            {currentPage} / {numPages}
          </CurrentSlide>
        )}

        <Document
          onLoadSuccess={handleOnLoad}
          file={src}
          noData={<h2>No se encontro</h2>}
        >
          <Page
            pageNumber={currentPage}
            scale={1.1}
            key={`page_${currentPage}`}
          />
        </Document>

        {!isMobile && (
          <Buttons>
            <Prev onClick={handlePrevPage}>
              <IoIosArrowBack size={30} color="white" />
            </Prev>
            <Next onClick={handleNextPage}>
              <IoIosArrowForward size={30} color="white" />
            </Next>
          </Buttons>
        )}
        <FullScreenButton
          onClick={() => {
            setOnFullScreen(true);
          }}
        >
          <BsFullscreen
            color={isMobile ? theme.colors.LightBlue : theme.colors.DarkGray}
            size={isMobile ? 14 : 42}
          ></BsFullscreen>
        </FullScreenButton>
      </Wrapper>
      {isMobile && (
        <FlexContainer
          justify="center"
          style={{
            background: "rgba(217, 217, 217, 0.25);",
            padding: "6px",
          }}
        >
          <FlexContainer gap="6px" align="center">
            <ArrowMobileButton onClick={handlePrevPage}>
              <IoIosArrowBack size={8} color={theme.colors.LightBlue} />
            </ArrowMobileButton>
            <span>{`${currentPage} de ${numPages}`}</span>
            <ArrowMobileButton onClick={handleNextPage}>
              <IoIosArrowForward size={8} color={theme.colors.LightBlue} />
            </ArrowMobileButton>
          </FlexContainer>
        </FlexContainer>
      )}
      {onFullScreen && (
        <FullScreenSlideshow
          setFullScreen={setOnFullScreen}
          currentPage={currentPage}
          handleKeyDown={handleKeyDown}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          numPages={numPages}
          src={pdfUrl}
        ></FullScreenSlideshow>
      )}
    </>
  );
};

export default Component;
