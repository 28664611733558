import { connect } from "react-redux";
import { AcademyDto } from "../../types/academy.dto";
import { CurrentDto } from "../../types/current.dto";
import { OrganizationDto } from "../../types/organization.dto";

import { Logo } from "../Header/styles";
import image from "../../assets/images/team-icon-24.png";
import {
  Profile,
  Name,
  FirstName,
  LastName,
  TalentAvatar,
  ProfileNoLink,
} from "./styles";
import UserShadow from "../../assets/images/usershadow.png";
import { Img } from "../../pages/SignIn/styles";
interface ComponentProps {
  user;
  sidebarCollapsed?: boolean;
  style?;
}

const Component = ({
  user,
  sidebarCollapsed,

  ...rest
}: ComponentProps) => {
  return (
    <>
      {user ? (
        <Profile
          sidebarCollapsed={sidebarCollapsed}
          to={"/dashboard/profile"}
          {...rest}
        >
          <>
            <TalentAvatar borderRadius="100%" height={"32px"} width={"32px"}>
              <Img src={user.picture || UserShadow}></Img>
            </TalentAvatar>

            <Name>
              <FirstName>
                {!!user.name && user.name} {!!user.lastName && user.lastName}
              </FirstName>
              <LastName>{!!user && user.position}</LastName>
            </Name>
          </>
        </Profile>
      ) : (
        <ProfileNoLink sidebarCollapsed={sidebarCollapsed} {...rest}>
          <TalentAvatar borderRadius="100%" height={"32px"} width={"32px"}>
            <Img
              src={
                "https://cdn1.iconfinder.com/data/icons/user-pictures/100/unknown-512.png"
              }
            ></Img>
          </TalentAvatar>

          <Name>
            <FirstName>Guest</FirstName>
          </Name>
        </ProfileNoLink>
      )}
    </>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;

  return {
    user,
  };
};

export default connect(states)(Component);

/* {current.currentSection === "personal" && (
        <>
          <Avatar
            size="big"
            style={{ marginLeft: "64px", marginTop: "-40px" }}
          />

          <Name>
            {!!user && user.name} <Company>{!!user && user.institute}</Company>
          </Name>
          <h4>{!!user && user.position}</h4>
        </>
      )}
      {current.currentSection === "academy" ||
        (current.currentSection === "organization" && (
          <>
            <Avatar
              size="big"
              style={{ marginLeft: "64px", marginTop: "-40px" }}
            />
            <Name>
              {!!entityFounded?.name && entityFounded?.name}{" "}
              <Company>{current.currentSection}</Company>
            </Name>
            <h4>{!!current.role && current.role}</h4>
          </>
        ))} */
