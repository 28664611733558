import action from "../helpers/rest-client";

export class ErrorCohort extends Error {}

class Cohort {
  public new(payload) {
    return action
      .Post({
        url: "/cohorts",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOffset({ offset, limit }) {
    return action
      .Get({
        url: `/cohorts?platform=CAMPUS&limit=${limit}&offset=${offset}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public update({ _id, ...rest }) {
    return action
      .Patch({
        url: `/cohorts/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public addRating({ id, ...rest }) {
    return action
      .Patch({
        url: `/cohorts/set-rating/${id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public manageLikeDislike({ _id, ...rest }) {
    return action
      .Patch({
        url: `/cohorts/${rest.action}-${rest.condition}/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public deleteMember({ _id, ...rest }) {
    return action
      .Patch({
        url: `/users/remove-from-cohort/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll(payload) {
    if (payload?.filterBy) {
      payload.filterBy = JSON.stringify(payload?.filterBy);
    }

    if (payload?.select) {
      payload.select = JSON.stringify(payload?.select);
    }

    const queryString = new URLSearchParams(payload).toString();

    return action
      .Get({
        url: `/cohorts?platform=CAMPUS${queryString ? "&" + queryString : ""}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne({ _id }) {
    return action
      .Get({
        url: `/cohorts/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public addView({ _id, ...rest }) {
    return action
      .Patch({
        url: `/cohorts/add-view/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public addClick({ _id }) {
    return action
      .Patch({
        url: `/cohorts/add-click/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public query({ keyword }) {
    return action
      .Get({
        url: `/cohorts/keyword?keyword=${keyword}&platform=CAMPUS`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public addMember({ _id, ...rest }) {
    return action
      .Patch({
        url: `/users/add-to-cohort/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getFromAcademy({ _id, ...payload }) {
    if (payload?.filterBy) {
      payload.filterBy = JSON.stringify(payload?.filterBy);
    }

    if (payload?.select) {
      payload.select = JSON.stringify(payload?.select);
    }
    const queryString = new URLSearchParams(payload).toString();
    return action
      .Get({
        url: `/cohorts/academy/${_id}?platform=CAMPUS${
          queryString ? "&" + queryString : ""
        }`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public delete(cohortId) {
    return action
      .Del({
        url: `/cohorts/${cohortId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const cohort = new Cohort();
export default cohort;
