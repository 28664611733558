import { PaginationDto } from "../../types/pagination.dto";
import { UsersDto } from "../../types/users.dto";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const RESET_GET_ALL_USERS = "RESET_GET_ALL_USERS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR";
export const UPDATE_USERS = "UPDATE_USERS";
export const RESET_UPDATE_USERS = "RESET_UPDATE_USERS";
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS";
export const UPDATE_USERS_ERROR = "UPDATE_USERS_ERROR";
export const RESET_GET_ALL_USERS_DATA = "RESET_GET_ALL_USERS_DATA";
export const RESET_GET_ONE_USERS_DATA = "RESET_GET_ONE_USERS_DATA";
export const GET_USERS_BY_ACADEMY = "GET_USERS_BY_ACADEMY";

export interface ResetGetAllUserData {
  type: typeof RESET_GET_ALL_USERS_DATA;
  payload: null;
}

export interface ResetGetOneUserData {
  type: typeof RESET_GET_ONE_USERS_DATA;
  payload: null;
}

export interface GetAllUsers {
  type: typeof GET_ALL_USERS;
  payload: PaginationDto;
}
export interface GetAllUsersSuccess {
  type: typeof GET_ALL_USERS_SUCCESS;
  payload: UsersDto[];
}

export interface GetUsersByAcademy {
  type: typeof GET_USERS_BY_ACADEMY;
  payload: PaginationDto & { _id: string };
}

export interface GetAllUsersError {
  type: typeof GET_ALL_USERS_ERROR;
  payload: string | boolean;
}
export interface ResetGetAllUsers {
  type: typeof RESET_GET_ALL_USERS;
  payload: null;
}
export interface UpdateUsers {
  type: typeof UPDATE_USERS;
  payload: any;
}
export interface UpdateUsersSuccess {
  type: typeof UPDATE_USERS_SUCCESS;
  payload: any;
}
export interface UpdateUsersError {
  type: typeof UPDATE_USERS_ERROR;
  payload: string | boolean;
}
export interface ResetUpdateUsers {
  type: typeof RESET_UPDATE_USERS;
  payload: null;
}

export interface State {
  all: {
    data: UsersDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};
export type Actions =
  | GetAllUsers
  | GetAllUsersSuccess
  | GetAllUsersError
  | ResetGetAllUsers
  | UpdateUsers
  | UpdateUsersSuccess
  | UpdateUsersError
  | ResetUpdateUsers
  | ResetGetAllUserData
  | ResetGetOneUserData
  | GetUsersByAcademy;
