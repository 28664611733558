import {
  NewInvitation,
  NEW_INVITATION,
  ResetNewInvitation,
  RESET_NEW_INVITATION,
  GetAllInvitation,
  GET_ALL_INVITATION,
  ResetGetAllInvitation,
  RESET_GET_ALL_INVITATION,
  DELETE_INVITATION,
  DeleteInvitation,
  RESET_DELETE_INVITATION,
  ResetDeleteInvitation,
  UpdateInvitation,
  UPDATE_INVITATION,
  ResetUpdateInvitation,
  RESET_UPDATE_INVITATION,
  GetInvitation,
  GET_INVITATION,
  ResetGetInvitation,
  RESET_GET_INVITATION,
  RESET_GET_ALL_INVITATION_DATA,
  GET_INVITATIONS_USER_BY_ENTITY,
} from "../types/invitation";

const create = (payload): NewInvitation => {
  return { type: NEW_INVITATION, payload };
};
const resetCreate = (): ResetNewInvitation => {
  return { type: RESET_NEW_INVITATION, payload: null };
};

const getAll = (payload): GetAllInvitation => {
  return { type: GET_ALL_INVITATION, payload };
};

const resetGetAll = (): ResetGetAllInvitation => {
  return { type: RESET_GET_ALL_INVITATION, payload: null };
};

const deleteOne = (payload: { _id: string }): DeleteInvitation => {
  return { type: DELETE_INVITATION, payload };
};

const getAllByUserEntity = (payload) => {
  return { type: GET_INVITATIONS_USER_BY_ENTITY, payload };
};

const resetDeleteOne = (): ResetDeleteInvitation => {
  return { type: RESET_DELETE_INVITATION, payload: null };
};

const update = (payload): UpdateInvitation => {
  return { type: UPDATE_INVITATION, payload };
};

const getOne = (payload): GetInvitation => {
  return { type: GET_INVITATION, payload };
};

const resetGetAllInvitations = () => {
  return { type: RESET_GET_ALL_INVITATION_DATA, payload: null };
};

const resetGetOne = (): ResetGetInvitation => {
  return { type: RESET_GET_INVITATION, payload: null };
};

const resetUpdate = (): ResetUpdateInvitation => {
  return { type: RESET_UPDATE_INVITATION, payload: null };
};

const actions = {
  create,
  resetGetAll,
  update,
  resetCreate,
  resetDeleteOne,
  getAll,
  resetUpdate,
  deleteOne,
  getOne,
  resetGetOne,
  resetGetAllInvitations,
  getAllByUserEntity,
};

export default actions;
