import styled from "styled-components";

export const SocialButton = styled("button")`
  display: flex;
  align-items: center;
  border: 0px;
  border-radius: 10px;
  gap: 15px;
  border: solid 0.8px rgba(0, 0, 0, 0.5);
  color: ${(props) => props.color};
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  padding: 0px 10px;
  user-select: none;
  height: 29.5px;
  transition: background 0.3s ease-in-out;
  background: ${(props) => props.background};
  &:hover {
    background-color: #051725;
  }
  &:hover h3 {
    color: ${(props) => props.theme.colors.White};
    font-weight: 500;
  }
  & > h3 {
    font-size: 19px;
    font-weight: 400;
    @media (max-width: 500px) {
      font-size: 12px;
      font-weight: normal;
    }
  }
`;
