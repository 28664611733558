import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Icon from "../../../../components/Icon";
import { ActionButton, NewButton, CategoryLabel } from "./styles";

import EditModal from "./components/EditModal";
import NewModal from "./components/NewModal";
import DeleteModal from "./components/DeleteModal";

import PlusIcon from "../../../../assets/icons/plus.svg";
import DeleteUserIcon from "../../../../assets/icons/delete.svg";
import EditUserIcon from "../../../../assets/icons/edit.svg";
import {
  getAllAbilities,
  setSelectedAbility,
} from "../../../../store/actions/ability";
import { AbilityDto } from "../../../../types/ability.dto";
import { showModal } from "../../../../store/actions/modal";
import Loader from "../../../../components/LogoLoader";
import { getAllCategories } from "../../../../store/actions/category";
import { CategoryDto } from "../../../../types/category.dto";
import { eventsTypes } from "../../../../constants/events-types";

interface ComponentProps {
  abilities: AbilityDto[];
  categories: CategoryDto[];
}

const Component = ({ abilities, categories }: ComponentProps) => {
  const [activeAbilities, setActiveAbilities] = useState<AbilityDto[] | null>(
    null
  );
  const dispatch = useDispatch();

  useEffect(() => {
    !abilities && dispatch(getAllAbilities());
  }, [dispatch, abilities]);

  useEffect(() => {
    if (!categories) {
      dispatch(getAllCategories());
    }
  }, [categories, dispatch]);

  useEffect(() => {
    if (!!abilities) {
      setActiveAbilities(abilities?.filter((ability) => ability.active));
    }
  }, [abilities]);

  const handleEdit = (row, index) => {
    dispatch(setSelectedAbility(row));
    dispatch(showModal("edit-ability-modal"));
  };

  const handleDelete = (row, index) => {
    dispatch(setSelectedAbility(row));
    dispatch(showModal("delete-ability-modal"));
  };

  const handleNew = (row, index) => {
    dispatch(showModal("new-ability-modal"));
  };

  const columns = [
    {
      with: "100%",
      minWidth: "300px",
      name: "Nombre de la habilidad",
      selector: (row) => row.name,
    },
    {
      with: "100%",
      minWidth: "300px",
      name: "Categorías",
      cell: (row) => {
        return row.categories.map((rowCategory) => {
          return (
            <CategoryLabel>
              {
                categories?.filter(
                  (category) => rowCategory === category._id
                )[0].name
              }
            </CategoryLabel>
          );
        });
      },
    },
    {
      with: "100%",
      name: "Tipo",
      cell: (row) => {
        if (!!row.type) {
          return eventsTypes.filter(
            (eventType) => eventType.value === row.type
          )[0].label;
        }
      },
    },
    {
      right: true,
      name: "",
      cell: (row, index) => {
        const Edit = () => (
          <ActionButton type="button" onClick={() => handleEdit(row, index)}>
            <Icon icon={EditUserIcon} color="#1673e5" size="18px" />
          </ActionButton>
        );

        const Delete = () => (
          <ActionButton type="button" onClick={() => handleDelete(row, index)}>
            <Icon icon={DeleteUserIcon} color="#fd433a" size="18px" />
          </ActionButton>
        );

        return (
          <>
            <Edit />
            <Delete />
          </>
        );
      },
    },
  ];

  const handleRowClicked = (data) => {};

  if (!activeAbilities) {
    return <Loader />;
  }

  return (
    <>
      <NewButton onClick={handleNew}>
        <Icon icon={PlusIcon} size="14px" color="#1573e5" />
      </NewButton>

      <DataTableExtensions
        filterDigit={0}
        export={false}
        print={false}
        filterPlaceholder="Buscar"
        columns={columns}
        data={activeAbilities}
      >
        <DataTable
          pagination
          disabled={!activeAbilities}
          columns={columns}
          data={activeAbilities}
          noDataComponent={<p>Sin Elementos</p>}
          onRowClicked={(row) => handleRowClicked(row)}
        />
      </DataTableExtensions>

      <EditModal />
      <NewModal />
      <DeleteModal />
    </>
  );
};

const states = ({ abilityStore, categoryStore }) => {
  const { data: abilities } = abilityStore.allAbilities;
  const { data: categories } = categoryStore.allCategories;

  return {
    abilities,
    categories,
  };
};

export default connect(states)(Component);
