import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_CDOC,
  GET_CDOC_SUCCESS,
  GET_CDOC_ERROR,
  NEW_CDOC,
  NEW_CDOC_SUCCESS,
  NEW_CDOC_ERROR,
  GET_ALL_CDOCS,
  GET_ALL_CDOCS_SUCCESS,
  GET_ALL_CDOCS_ERROR,
  UPDATE_CDOC,
  UPDATE_CDOC_SUCCESS,
  UPDATE_CDOC_ERROR,
  DELETE_CDOC,
  DELETE_CDOC_SUCCESS,
  DELETE_CDOC_ERROR,
  GetCDOC,
  NewCDOC,
  GetAllCDOCs,
  UpdateCDOC,
  DeleteCDOC,
  AddUserCdoc,
  ADD_USER_CDOC,
  SHARED_CDOC,
  SharedCdoc,
  UNSAVED_CDOC,
  SAVED_CDOC,
  QUERY_CDOC_ERROR,
  QUERY_CDOC_SUCCESS,
  QueryCdoc,
  QUERY_CDOC,
  AddViewCdoc,
  ADD_VIEW_CDOC,
  SET_GET_CDOC,
  AddClickCdoc,
  ADD_CLICK_CDOC,
  ManageLikeDislikeCdoc,
  MANAGE_LIKE_DISLIKE_CDOC,
  UPDATE_CURRENT_CDOC_LIST,
  GET_CDOCS_OFFSET_ERROR,
  GET_CDOCS_OFFSET_SUCCESS,
  GetCdocsOffset,
  GET_CDOCS_OFFSET,
  UPDATE_ITEM_LIST_CDOC,
} from "../types/cdoc";

const cdocsOffset = ({ cdocStore }) => cdocStore.offSet.data;
const allCdocs = ({ cdocStore }) => cdocStore.all.data;
const user = ({ userStore }) => userStore.data;

function* getAllCdocs({ payload }) {
  const { response, error } = yield call(services.cdoc.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_CDOCS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_CDOCS_SUCCESS, payload: response });
  }
}

function* getCdoc({ payload }) {
  const { response, error } = yield call(services.cdoc.getOne, payload);

  if (error) {
    yield put({
      type: GET_CDOC_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_CDOC_SUCCESS, payload: response });
  }
}

function* queryCdoc({ payload }) {
  const { response, error } = yield call(services.cdoc.query, payload);

  if (error) {
    yield put({
      type: QUERY_CDOC_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: QUERY_CDOC_SUCCESS, payload: response });
  }
}

function* addView({ payload }) {
  const { response, error } = yield call(services.cdoc.addView, payload);

  /* if (error) {
    yield put({
      type: UPDATE_CDOC_ERROR,
      payload: error,
    });
  } else {
    const newListVideo = yield select(videoList);
       const newListVideoAdded = newListVideo.map((item) =>
      item._id === response._id ? item : response
    ); 
    yield all([put({ type: UPDATE_CDOC_SUCCESS, payload: response })]);
  } */
}

function* manageContent({ payload }) {
  const { response, error } = yield call(services.cdoc.manageContent, payload);

  if (error) {
    yield put({
      type: UPDATE_CDOC_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const currentCdocs = yield select(allCdocs);
    const currentUser = yield select(user);
    const updatedCurrentCdocs = currentCdocs.map((item) => {
      if (item._id === payload._id) {
        if (payload.condition === "unsave") {
          const index = item.savedBy.indexOf(currentUser._id);

          if (index !== -1) {
            item.savedBy.splice(index, 1);
          }

          return item;
        } else {
          return { ...item, savedBy: [...item.savedBy, currentUser._id] };
        }
      } else {
        return item;
      }
    });

    yield all([
      put({ type: UPDATE_CDOC_SUCCESS, payload: response }),
      ...(!!payload.individual
        ? [put({ type: SET_GET_CDOC, payload: response })]
        : [put({ type: GET_ALL_CDOCS_SUCCESS, payload: updatedCurrentCdocs })]),
    ]);
  }
}

function* newCdoc({ payload }) {
  const { response, error } = yield call(services.cdoc.new, payload);

  if (error) {
    yield put({
      type: NEW_CDOC_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_CDOC_SUCCESS, payload: response })]);
  }
}

function* updateCdoc({ payload }) {
  const { response, error } = yield call(services.cdoc.update, payload);

  if (error) {
    yield put({
      type: UPDATE_CDOC_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_CDOC_SUCCESS, payload: response })]);
  }
}

function* deleteCdoc({ payload }) {
  const { response, error } = yield call(services.cdoc.delete, payload);

  if (error) {
    yield put({
      type: DELETE_CDOC_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_CDOC_SUCCESS, payload: response })]);
  }
}

function* addUserCdoc({ payload }) {
  const { response, error } = yield call(services.cdoc.addUserCdoc, payload);

  if (error) {
    yield put({
      type: UPDATE_CDOC_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_CDOC_SUCCESS, payload: response })]);
  }
}

function* shareCdoc({ payload }) {
  const { response, error } = yield call(services.cdoc.shareCdoc, payload);

  if (error) {
    yield put({
      type: UPDATE_CDOC_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_CDOC_SUCCESS, payload: response })]);
  }
}

function* addClick({ payload }) {
  const { response, error } = yield call(services.cdoc.addClick, payload);
  /*   if (error) {
  } else {
    const currentListOfCohorts = yield select(cohortsList);
    const newListofCohorts = currentListOfCohorts?.map((cohort) => {
      if (cohort._id === response._id) {
        return response;
      }
      return cohort;
    });
    yield put({
      type: UPDATE_CURRENT_COHORT_LIST,
      payload: newListofCohorts,
    });
  } */
}
function* manageLikeDislike({ payload }) {
  const { response, error } = yield call(
    services.cdoc.manageLikeDislike,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_CDOC_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_CDOC_SUCCESS, payload: response });

    if (!!payload.individual) {
      yield put({ type: SET_GET_CDOC, payload: response });
    }
  }
}

function* getCdocsOffset({ payload }) {
  const { response, error } = yield call(services.cdoc.getOffset, payload);
  if (error) {
    yield put({
      type: GET_CDOCS_OFFSET_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const offsetCdocs = yield select(cdocsOffset);
    const newOffsetCdocs = !offsetCdocs
      ? response
      : [...offsetCdocs, ...response];
    yield put({
      type: GET_CDOCS_OFFSET_SUCCESS,
      payload: newOffsetCdocs,
    });
  }
}

function* updateCdocListItem({ payload }) {
  const { response, error } = yield call(services.cdoc.update, payload);

  if (error) {
    yield put({
      type: UPDATE_CDOC_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_CDOC_SUCCESS, payload: response }),
      put({ type: SET_GET_CDOC, payload: response }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllCDOCs>(GET_ALL_CDOCS, getAllCdocs);
  yield takeLatest<GetCDOC>(GET_CDOC, getCdoc);
  yield takeLatest<NewCDOC>(NEW_CDOC, newCdoc);
  yield takeLatest<UpdateCDOC>(UPDATE_CDOC, updateCdoc);
  yield takeLatest<DeleteCDOC>(DELETE_CDOC, deleteCdoc);
  yield takeLatest<any>(UNSAVED_CDOC, manageContent);
  yield takeLatest<any>(SAVED_CDOC, manageContent);
  yield takeLatest<AddUserCdoc>(ADD_USER_CDOC, addUserCdoc);
  yield takeLatest<SharedCdoc>(SHARED_CDOC, shareCdoc);
  yield takeLatest<QueryCdoc>(QUERY_CDOC, queryCdoc);
  yield takeLatest<AddViewCdoc>(ADD_VIEW_CDOC, addView);
  yield takeLatest<AddClickCdoc>(ADD_CLICK_CDOC, addClick);
  yield takeLatest<ManageLikeDislikeCdoc>(
    MANAGE_LIKE_DISLIKE_CDOC,
    manageLikeDislike
  );
  yield takeLatest<GetCdocsOffset>(GET_CDOCS_OFFSET, getCdocsOffset);
  yield takeLatest<any>(UPDATE_ITEM_LIST_CDOC, updateCdocListItem);
}
