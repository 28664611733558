import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Grid from "../../../../components/Grid";
import {
  GridCard,
  GridCardImage,
  GridCardImageContainer,
  GridCardSmallContainer,
  GridCardTitle,
  GridComment,
  GridStructure,
} from "../../../../components/Layout/Dashboard/styles";
import Loader from "../../../../components/Loader";
import {
  Center,
  FlexContainer,
  PageTitle,
  Separator,
} from "../../../../components/StyledComponents";
import { DateFromNow } from "../../../../helpers/format-date";

import {
  addClickCdoc,
  getAllCDOCs,
  queryCdoc,
  resetGetAllCdocs,
  resetNewCDOCs,
} from "../../../../store/actions/cdoc";

import academyActions from "../../../../store/actions/academies";

import { CohortDto } from "../../../../types/cohort.dto";

import { StateDto } from "../../../../types/states.dto";
import { CDocDto } from "../../../../types/cdoc.dto";
import FilterButton from "./components/FilterButton";
import FilterModal from "./components/FilterModal";
import { DotContent, ItemDataText } from "./style";
import { AcademyDto } from "../../../../types/academy.dto";
import { UserDto } from "../../../../types/user.dto";
import FilterByName from "./components/FilterByName";
import { useNavigate } from "react-router-dom";
import InfinityScroll from "../../../../components/InfinityScroll";
import ToggleSlider from "../../../../components/ToggleSlider";
import { addClick } from "../../../../store/actions/userTimes";
import ContentCard from "../../../../components/ContentCard";
import { HiDocumentText } from "react-icons/hi";
import Skeleton from "../../../../components/Skeleton";

/* const filterValues: any = [
  {
    fieldName: ["visibility", "order"],
    value: "no",
    name: "Todos",
  },
  {
    fieldName: "order",
    value: "recent",
    name: "Recientes",
    groupal: (array: CDocDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "order",
    value: "older",
    name: "Antiguos",
    groupal: (array: CDocDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "published",
    value: true,
    name: "Publicos",
  },
  {
    fieldName: "published",
    value: false,
    name: "Privados",
  },
]; */

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  cdocs,
  newCdocData,
  newCdocDataStates,
  cohorts,
  academies,
  user,
  queryCdocs,
  queryCdocsStates,
  cdocsStates,
}: {
  cdocs: CDocDto[];
  newCdocData: CDocDto;
  newCdocDataStates: StateDto;
  cohorts: CohortDto[];
  academies: AcademyDto[];
  user: UserDto;
  queryCdocs: CDocDto[];
  queryCdocsStates: StateDto;
  cdocsStates: StateDto;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filterValues: any = [
    {
      fieldName: "private",
      value: "no",
      name: "Todos",
    },
    {
      fieldName: "private",
      value: true,
      name: "Academias Privadas",
      special: (array, value, currentItem) => {
        return currentItem.academy.private === value;
      },
    },
    {
      fieldName: "private",
      value: false,
      name: "Academias Publicas",
      special: (array, value, currentItem) => {
        return currentItem.academy.private === value;
      },
    },
  ];
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [queryMode, setQueryMode] = useState<string | null>(null);
  const [query, setQuery] = useState<string | null>(null);
  const [currentCdocsList, setCurrentCdocsList] = useState<any[]>([]);
  const [filteredCurrentCdocsListValues, setFilteredCurrentCdocsListValues] =
    useState<CDocDto[]>([]);
  const handleQuery = (ev) => {
    const value = ev.target.value;
    setQuery(value.length === 0 ? "" : value);
  };
  const searchQuery = (ev) => {
    if (ev.keyCode === 13) {
      /*  dispatch(queryImage({ keyword: query })); */
      setQueryMode(query);
    }
  };
  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter((applyedFilter) =>
        !Array.isArray(newFilter.field)
          ? applyedFilter.field === newFilter.field
          : newFilter.field.includes(applyedFilter.field)
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    if (filterRemoved) {
      /**
       * Se elimina el filtro
       */
      if (Array.isArray(newFilter.field)) {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => !newFilter.field.includes(applyedFilter.field)
          ),
        ]);
      } else {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => applyedFilter.field !== newFilter.field
          ),
        ]);
      }
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 9,
      offset: 9 * Math.floor(page / 2),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        published: true,
      },
    };
    const sortBy = page % 2 === 0 ? "createdAt" : "viewCount";
    const select = {
      _id: 1,
      name: 1,
      picture: 1,
      academy: 1,
      viewCount: 1,
      createdAt: 1,
    };
    const sortDirection = "desc";
    dispatch(
      getAllCDOCs({
        ...payload,
        sortBy,
        sortDirection,
        select,
        ...(queryMode && { keyword: query }),
      })
    );
  };
  useEffect(() => {
    if (!isLoading && !!cdocs) {
      setCurrentCdocsList((state) => [...state, ...cdocs]);
    }
    setThereAreItems(!!cdocs && cdocs?.length > 0);
  }, [cdocs, isLoading]);

  useEffect(() => {
    setIsLoading(cdocsStates.loading);
  }, [cdocsStates]);

  useEffect(() => {
    const filteredTalents = currentCdocsList?.filter((currentTalentValues) => {
      const thereAreFilters =
        applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
          .length > 0;
      const totalOfFilters = applyedFilters.filter(
        (applyedFilter) => !applyedFilter.groupal
      ).length;
      const passFilters = applyedFilters
        .filter(
          (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
        )
        .filter(
          (applyedFilter) =>
            currentTalentValues[applyedFilter.field] === applyedFilter.value
        ).length;
      const specialFilter = applyedFilters.filter(
        (applyedFilter) => !!applyedFilter.special
      );

      const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
        return applyedFilter.special(
          currentTalentValues,
          applyedFilter.value,
          currentTalentValues
        );
      });
      return (
        !thereAreFilters ||
        (thereAreFilters &&
          totalOfFilters === passFilters + specialFilter.length &&
          speacialFilterCheck.length === specialFilter.length)
      );
    });
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });

    setFilteredCurrentCdocsListValues(initResult);
  }, [applyedFilters, currentCdocsList]);

  useEffect(() => {
    if (queryMode) {
      setPage(0);
      setIsLoading(false);
      setThereAreItems(true);
      setCurrentCdocsList([]);
    }
  }, [queryMode]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCdocs());
    };
  }, []);

  useEffect(() => {
    if (newCdocDataStates.success) {
      navigate(newCdocData._id);

      dispatch(resetNewCDOCs());
    }
    if (newCdocDataStates.error) {
      toast.error(newCdocDataStates.error);
      dispatch(resetNewCDOCs());
    }
  }, [newCdocDataStates]);

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <PageTitle>Cdocs</PageTitle>
          </Grid.Col>
        </Grid.Row>
        <Center>
          <Grid.Row style={{ width: "100%" }}>
            <Grid.Col lg={12} md={12} sm={12} style={{ marginBottom: "14px" }}>
              <FlexContainer
                style={{ width: "100%", maxWidth: "700px", margin: "0 auto" }}
              >
                <FilterByName
                  searchQuery={searchQuery}
                  setFilter={handleQuery}
                  value={query}
                ></FilterByName>
              </FlexContainer>
            </Grid.Col>
          </Grid.Row>
        </Center>
        <Separator size={12}></Separator>
        {/*   <ToggleSlider>
          {filterValues.map((filterValue) => {
            return (
              <FilterButton
                setFilter={buildFilters}
                name={filterValue.name}
                fieldName={filterValue.fieldName}
                value={filterValue.value}
                filters={applyedFilters}
                {...((!!filterValue.special || !!filterValue.groupal) && {
                  special: !!filterValue.special
                    ? { name: "special", function: filterValue.special }
                    : { name: "groupal", function: filterValue.groupal },
                })}
              ></FilterButton>
            );
          })}
        </ToggleSlider> */}
        <Separator size={12}></Separator>
        <InfinityScroll
          action={fetchData}
          page={page}
          setPage={setPage}
          data={filteredCurrentCdocsListValues}
          active={thereAreItems}
          isLoading={isLoading}
          loader={
            <Skeleton
              itemsPerPage={9}
              itemsPerRow={3}
              animated
              size={{ height: 290, width: 276 }}
              icon="cdoc"
            />
          }
        >
          <GridStructure
            width={"275px"}
            maxWidth="auto"
            style={{ width: "100%", margin: "0 auto" }}
            limitCard={"275px"}
          >
            {filteredCurrentCdocsListValues.map((item) => {
              return (
                <ContentCard
                  ContentTypeLogo={{ icon: HiDocumentText }}
                  key={item._id}
                  item={item}
                  logoOnclick={(item) => {
                    dispatch(
                      addClick({
                        id: item.academy?._id,
                        user: user._id,
                        type: "ACADEMY",
                      })
                    );
                    navigate(`/dashboard/academy/${item.academy?._id}`);
                  }}
                  cardOnClick={(item) => {
                    dispatch(
                      addClick({
                        id: item._id,
                        user: user._id,
                        type: "CONTENT",
                      })
                    );
                    navigate(`/dashboard/cdocs/${item._id}`);
                  }}
                ></ContentCard>
              );
            })}
          </GridStructure>
        </InfinityScroll>
      </Grid.Container>
      {!!cohorts && <FilterModal buildFilters={buildFilters}></FilterModal>}
    </>
  );
};

const states = ({ cdocStore, cohortStore, academyStore, userStore }) => {
  const { data: cdocs, states: cdocsStates } = cdocStore.all;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: newCdocData, states: newCdocDataStates } = cdocStore.new;
  const { data: academies } = academyStore.all;
  const { data: user } = userStore;
  const { data: queryCdocs, states: queryCdocsStates } = cdocStore.query;
  return {
    cdocs,
    newCdocData,
    newCdocDataStates,
    academies,
    cohorts,
    user,
    queryCdocs,
    queryCdocsStates,
    cdocsStates,
  };
};

export default connect(states)(Component);
