import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Card,
  CardRow,
  CardCol,
  Avatar,
  Information,
  Name,
  Profile,
  Location,
  ItemLink,
  Commets,
  List,
  Item,
  SmallCardChart,
  BigCardChart,
  NoteCard,
  TitleStage,
} from "./styles";
import Link from "../../../../components/Button";
import envelopeIcon from "../../../../assets/icons/envelope.svg";
import cv from "../../../../assets/icons/cv.svg";
import github from "../../../../assets/icons/github.svg";
import linkedin from "../../../../assets/icons/linkedin.svg";
import briefcase from "../../../../assets/icons/briefcase.svg";
import RadarChart from "../../../../components/RadarChart";
import { useNavigate } from "react-router-dom";
import { TalentDto } from "../../../../types/talent.dto";
import { UserDto } from "../../../../types/user.dto";
import { Center, FlexContainer } from "../../../StyledComponents";
import Icon from "../../../Icon";
import Loader from "../../../Loader";
import { getIndividualStats } from "../../../../store/actions/analytics";
import { UsersDto } from "../../../../types/users.dto";
import { CohortDto } from "../../../../types/cohort.dto";
import { AnalyticsUserDto } from "../../../../types/analytics.dto";
import { arrayLengthDivision } from "../../../../helpers/notLengthDivision";
import { roundedNumbers } from "../../../../helpers/rounded-numbers";
interface ComponentProps {
  stats: AnalyticsUserDto[];
  talents: TalentDto[];
  user: UserDto;
  users: UsersDto[];
  cohorts: CohortDto[];
  cohort: string;
}

const subarrays = (data): any[] => {
  return data.reduce((result, obj) => {
    const category = obj.skill._id;
    if (!result[category]) {
      result[category] = [];
    }
    result[category].push(obj);
    return result;
  }, {});
};

const Component = ({
  stats,
  talents,
  user,
  users,
  cohorts,
  cohort,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && cohort) {
      dispatch(getIndividualStats({ _id: user._id, cohorts: [cohort] }));
    }
  }, [user, dispatch, cohort]);

  if (!cohorts || !stats) return <Loader color="LightBlue"></Loader>;
  const statsFromCohort = stats?.filter((stat) => stat.cohort === cohort)[0];
  const notesSum = statsFromCohort?.skills?.reduce((prev, current) => {
    return prev + current.note;
  }, 0);

  const promByCriterion = Object.values(
    subarrays(statsFromCohort?.skills ?? [])
  )
    .flat(1)
    .reduce((prev: any, current, index, array) => {
      if (prev.find((item) => item.criterion._id === current.skill._id))
        return prev;
      else {
        const promCurrentCriterion = array
          .filter((item) => item.skill._id === current.skill._id)
          .map((item) => item.note);
        return [
          ...prev,
          {
            criterion: current.skill,
            average: roundedNumbers(
              promCurrentCriterion.reduce(
                (prev, current) => current + prev,
                0
              ) / arrayLengthDivision(promCurrentCriterion)
            ),
          },
        ];
      }
    }, []);

  return (
    <Center>
      <Card>
        <CardCol style={{ maxWidth: "265px" }}>
          <CardRow>
            <Avatar avatar={user.picture} />
            <Information>
              <Name>{`${user.name} ${user.lastName} `}</Name>
              <Profile>
                {user.cohorts.length > 0
                  ? cohorts?.filter(
                      (cohort) => cohort._id === user.cohorts[0]
                    )[0]?.name
                  : "Bootcamp"}
              </Profile>
              <Location>
                <span>{user.gender}</span>
              </Location>
            </Information>
          </CardRow>
          <CardRow style={{ margin: "20px 0px" }}>
            <List>
              {user.email && (
                <Item>
                  <ItemLink target="_blank" href={`mailto:${user.email}`}>
                    <Icon icon={envelopeIcon} size="18px" color="#697482" />
                  </ItemLink>
                </Item>
              )}

              {user?.github && (
                <Item>
                  <ItemLink target="_blank" href={`http://${user?.github}`}>
                    <Icon icon={github} size="18px" color="#697482" />
                  </ItemLink>
                </Item>
              )}

              {user?.curriculum && (
                <Item>
                  <ItemLink target="_blank" href={`http://${user?.curriculum}`}>
                    <Icon icon={cv} size="18px" color="#697482" />
                  </ItemLink>
                </Item>
              )}

              {user?.portfolio && (
                <Item>
                  <ItemLink target="_blank" href={`http://${user?.portfolio}`}>
                    <Icon icon={briefcase} size="18px" color="#697482" />
                  </ItemLink>
                </Item>
              )}

              {user?.linkedinProfile && (
                <Item>
                  <ItemLink
                    target="_blank"
                    href={`http://${user?.linkedinProfile}`}
                  >
                    <Icon icon={linkedin} size="18px" color="#697482" />
                  </ItemLink>
                </Item>
              )}
            </List>
          </CardRow>

          <CardRow>
            <Commets>{user?.comments}</Commets>
          </CardRow>
        </CardCol>
        {stats && stats.length > 0 && statsFromCohort && (
          <CardCol>
            <CardRow>
              <CardCol style={{ marginRight: "10px" }}>
                <BigCardChart>
                  <TitleStage>Promedio Global</TitleStage>

                  <FlexContainer
                    direction="column"
                    justify="center"
                    align="center"
                    style={{ height: "100%" }}
                  >
                    <NoteCard>
                      {Math.round(
                        notesSum / arrayLengthDivision(statsFromCohort.skills)
                      )}
                    </NoteCard>
                  </FlexContainer>
                </BigCardChart>
              </CardCol>
              <CardCol>
                <BigCardChart>
                  <TitleStage>Mis criterios</TitleStage>
                  <RadarChart
                    labels={promByCriterion.map((prom) => prom.criterion.name)}
                    data={promByCriterion.map((prom) => prom.average)}
                  />
                </BigCardChart>
              </CardCol>
            </CardRow>
            <CardRow>
              <Center>
                <Link
                  onClick={() => {
                    navigate(`/dashboard/personal/myanalytics`);
                  }}
                  type="button"
                  options={{
                    type: "filled",
                    skin: "lightblue",
                    size: "sm",
                    marginBottom: "0px",
                  }}
                  style={{
                    marginLeft: "10px",
                    marginTop: "15px",
                    padding: "0px 9px",
                    minwidth: "0px",
                  }}
                >
                  Ver performance completa
                </Link>
              </Center>
            </CardRow>
          </CardCol>
        )}
      </Card>
    </Center>
  );
};

const states = ({
  analyticStore,
  talentStore,
  userStore,
  usersStore,
  cohortStore,
}) => {
  const { data: stats } = analyticStore.getIndividualStats;
  const { data: talents } = talentStore.allTalents;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: user } = userStore;
  const { data: users } = usersStore.all;
  return {
    stats,
    talents,
    user,
    users,
    cohorts,
  };
};

export default connect(states)(Component);
