import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import AuthProvider from "../provider/AuthProvider";
import ThemeProvider from "../provider/ThemeProvider";

import ScrollToTop from "../components/ScrollTop";
import RequireAuth from "../components/RequireAuth";
import InDeveloping from "./InDevolping";
import SignIn from "./SignIn";

import Page404 from "./NotFound";

import DashboardLayout from "../components/Layout/Dashboard";
import AuthLayout from "../components/Layout/Auth";

import Ability from "./Dashboard/Ability";
import Calendar from "./Dashboard/Calendar";
import Category from "./Dashboard/Category";
import Event from "./Dashboard/Event";
import Mentor from "./Dashboard/Mentor";
import Student from "./Dashboard/Student";
import { connect } from "react-redux";
import DashboardProvider from "../provider/DashboardProvider";
import SignUp from "./SignUp";
import Home from "./Dashboard/Home";
import Profile2 from "../components/Profile2";
import Content from "./Dashboard/Content";
import Home1 from "./Academy/Home/index";
import AcademyData from "../components/AcademyData";
import Outlet from "../components/Outlet";

import HomeCohort from "../pages/Cohort/Home";
import Invitations from "./Dashboard/Invitations";
import Notification from "./Dashboard/Notification";
import { GoogleOAuthProvider } from "@react-oauth/google";
import NotificationMobile from "./Mobile/Notification";
import { useWindowSize } from "../hooks/useWindowScreen";
import Personal from "./Mobile/Personal";
import Cdocs from "./Dashboard/Cdocs";
import Video from "./Dashboard/Video";
import Roadmap from "./Dashboard/Roadmap";
import ConfirmationAuth from "./Dashboard/ConfirmationAuth";
import Cohort from "./Dashboard/Cohort/List";
import Image from "./Dashboard/Image";
import MobileImageView from "./Mobile/Image/Document";
import NotificationEmail from "./Dashboard/NotificationEmail";
import Suscriptions from "./Dashboard/Suscriptions/List";
import SlideShow from "./Dashboard/SlideShow";
import { MenuPortal } from "../components/Portals/menuPortal";
import Listen from "./Dashboard/Listen";
import Byte from "./Dashboard/Byte";
import ConditionAndTerms from "./ConditionAndTerms";
import Variety from "./Dashboard/Variety/List";
import NoUserLogged from "../components/NoUserLogged";
import UnSeenNotificationsProvider from "../provider/UnSeenNotificationsProvider";

const Component = ({ user }) => {
  const { isMobile } = useWindowSize();
  return (
    <AuthProvider>
      <GoogleOAuthProvider clientId="675324732875-r7tc6ggnhb7k19jr2iad1arp3simkhs7.apps.googleusercontent.com">
        <ThemeProvider>
          <UnSeenNotificationsProvider>
            <BrowserRouter>
              <ScrollToTop useLocation={useLocation} />
              <DashboardProvider useLocation={useLocation}>
                <Routes>
                  <Route
                    path="/dashboard/*"
                    element={
                      <RequireAuth>
                        <DashboardLayout />
                      </RequireAuth>
                    }
                  >
                    <Route index element={<Navigate to={"home"} />} />

                    <Route path="home" element={<Home></Home>}></Route>
                    <Route path="" element={<NoUserLogged></NoUserLogged>}>
                      <Route path="profile" element={<Profile2 />} />
                      <Route path="ability/*" element={<Ability.List />}>
                        <Route index element={<Navigate to="list" />} />
                        <Route path="list" element={<Ability.List />} />
                        <Route path="new" element={<Ability.New />} />
                        <Route path="edit" element={<Ability.Edit />} />
                        <Route path="preview" element={<Ability.Preview />} />
                        <Route path="*" element={<Page404 />} />
                      </Route>
                      <Route path="roadmaps">
                        <Route
                          path=""
                          element={
                            isMobile ? (
                              <Roadmap.MobileList></Roadmap.MobileList>
                            ) : (
                              <Roadmap.List></Roadmap.List>
                            )
                          }
                        ></Route>
                        <Route
                          path=":id"
                          element={<Roadmap.View></Roadmap.View>}
                        ></Route>
                      </Route>
                      <Route path="images">
                        <Route
                          path=""
                          element={<Image.List></Image.List>}
                        ></Route>
                        <Route
                          path=":id"
                          element={<Image.Player></Image.Player>}
                        ></Route>
                      </Route>

                      <Route path="videos">
                        <Route
                          path=""
                          element={
                            isMobile ? <Video.ListMobile /> : <Video.List />
                          }
                        ></Route>
                        <Route
                          path=":id"
                          element={<Video.Player></Video.Player>}
                        ></Route>
                      </Route>
                      <Route path="varieties">
                        <Route path="" element={<Variety></Variety>}></Route>
                      </Route>
                      <Route path="bytes">
                        <Route
                          path=""
                          element={
                            isMobile ? (
                              <Byte.List></Byte.List>
                            ) : (
                              <Byte.List></Byte.List>
                            )
                          }
                        ></Route>
                        <Route
                          path=":id"
                          element={
                            isMobile ? (
                              <Byte.Player></Byte.Player>
                            ) : (
                              <Byte.Player></Byte.Player>
                            )
                          }
                        ></Route>
                      </Route>
                      <Route path="slideshows">
                        <Route
                          path=""
                          element={<SlideShow.List></SlideShow.List>}
                        ></Route>
                        <Route
                          path=":id"
                          element={<SlideShow.View></SlideShow.View>}
                        ></Route>
                      </Route>
                      <Route
                        path="suscriptions"
                        element={<Suscriptions></Suscriptions>}
                      ></Route>
                      {/* <Route
                      path="dashboard"
                      element={<DashboardCampusData></DashboardCampusData>}
                    >
                      <Route
                        path="professional"
                        element={
                          <DashboardCampusProfessional></DashboardCampusProfessional>
                        }
                      ></Route>
                      <Route
                        path="education"
                        element={
                          <DashboardCampusEducation></DashboardCampusEducation>
                        }
                      ></Route>
                    </Route> */}

                      <Route path="calendar" element={<Calendar />} />
                      <Route path="personal/*" element={<Personal></Personal>}>
                        {/*  <Route path="mycertificates">
                        <Route
                          path=""
                          element={<Certificate></Certificate>}
                        ></Route>
                      </Route> */}
                      </Route>

                      <Route path="category/*" element={<Category.List />}>
                        <Route index element={<Navigate to="list" />} />
                        <Route path="list" element={<Category.List />} />
                        <Route path="new" element={<Category.New />} />
                        <Route path="edit" element={<Category.Edit />} />
                        <Route path="preview" element={<Category.Preview />} />
                        <Route path="*" element={<Page404 />} />
                      </Route>
                      {/*   <Route path="certificates/*">
                      <Route
                        path=""
                        element={<Certificate></Certificate>}
                      ></Route>
                    </Route> */}
                      <Route path="cohorts/*">
                        <Route path="" element={<Cohort></Cohort>}></Route>
                        <Route path="*" element={<Page404 />} />
                      </Route>

                      <Route path="contents/*">
                        <Route path="" element={<Content></Content>}></Route>
                      </Route>
                      <Route path="invitations/*">
                        <Route
                          path=""
                          element={<Invitations></Invitations>}
                        ></Route>
                      </Route>
                      <Route path="cdocs">
                        <Route
                          path=""
                          element={<Cdocs.List></Cdocs.List>}
                        ></Route>
                        <Route
                          path=":id"
                          element={<Cdocs.Document></Cdocs.Document>}
                        ></Route>
                      </Route>
                      <Route path="listens">
                        <Route
                          path=""
                          element={<Listen.List></Listen.List>}
                        ></Route>
                        <Route
                          path=":id"
                          element={<Listen.Player></Listen.Player>}
                        ></Route>
                      </Route>

                      <Route
                        path="academy/:id/*"
                        element={
                          <AcademyData>
                            <Outlet></Outlet>
                          </AcademyData>
                        }
                      >
                        <Route path="" element={<Home1></Home1>}></Route>
                      </Route>
                      <Route path="cohort/:id/*">
                        <Route
                          path="home"
                          element={<HomeCohort></HomeCohort>}
                        ></Route>
                      </Route>
                      <Route path="event/*" element={<Event.List />}>
                        <Route index element={<Navigate to="list" />} />
                        <Route path="list" element={<Event.List />} />
                        <Route path="new" element={<Event.New />} />
                        <Route path="edit" element={<Event.Edit />} />
                        <Route path="preview" element={<Event.Preview />} />
                        <Route path="*" element={<Page404 />} />
                      </Route>
                      {/*  <Route path="analytics/*">
                      <Route
                        path="individual-performance"
                        element={
                          <IndividualPerformance></IndividualPerformance>
                        }
                      ></Route>
                    </Route> */}
                      <Route path="mentor/*" element={<Mentor.List />}>
                        <Route index element={<Navigate to="list" />} />
                        <Route path="list" element={<Mentor.List />} />
                        <Route path="new" element={<Mentor.New />} />
                        <Route path="edit" element={<Mentor.Edit />} />
                        <Route path="preview" element={<Mentor.Preview />} />
                        <Route path="*" element={<Page404 />} />
                      </Route>

                      <Route path="student/*" element={<Student.List />}>
                        <Route index element={<Navigate to="list" />} />
                        <Route path="list" element={<Student.List />} />
                        <Route path="new" element={<Student.New />} />
                        <Route path="edit" element={<Student.Edit />} />
                        <Route path="preview" element={<Student.Preview />} />
                        <Route path="*" element={<Page404 />} />
                      </Route>
                      <Route path="notifications/*">
                        <Route
                          path=""
                          element={
                            isMobile ? (
                              <NotificationMobile></NotificationMobile>
                            ) : (
                              <Notification></Notification>
                            )
                          }
                        ></Route>
                      </Route>
                    </Route>

                    <Route path="*" element={<InDeveloping />} />
                  </Route>
                  <Route
                    path="/auth/accept"
                    element={
                      <RequireAuth>
                        <NotificationEmail></NotificationEmail>
                      </RequireAuth>
                    }
                  ></Route>
                  <Route path="/" element={<AuthLayout />}>
                    <Route
                      path=""
                      element={<Navigate to="/dashboard/home" replace />}
                    ></Route>
                    {/*  <Route path="/account-recovery" element={<SignIn />} /> */}
                    <Route
                      path="/auth/confirm"
                      element={<ConfirmationAuth></ConfirmationAuth>}
                    ></Route>
                    <Route
                      path="/terms"
                      element={<ConditionAndTerms></ConditionAndTerms>}
                    ></Route>
                    <Route
                      path="*"
                      element={<Navigate to="/dashboard/home" replace />}
                    />
                  </Route>

                  <Route
                    path="*"
                    element={<Navigate to="/dashboard/home" replace />}
                  />
                </Routes>
                <MenuPortal useLocation={useLocation} user={user}></MenuPortal>
              </DashboardProvider>
            </BrowserRouter>
          </UnSeenNotificationsProvider>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </AuthProvider>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;
  return {
    user,
  };
};

export default connect(states)(Component);
