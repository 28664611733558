export const eventsTypes = [
  {
    label: "Bootcamp",
    value: "BOOTCAMP",
  },
  {
    label: "Curso",
    value: "CURSO",
  },
  {
    label: "Bootcamp y Curso",
    value: "BOOTCAMP_Y_CURSO",
  },
  {
    label: "Otro",
    value: "OTRO",
  },
];
