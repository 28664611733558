import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  NewNotification,
  NEW_NOTIFICATION,
  NEW_NOTIFICATION_ERROR,
  NEW_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_ERROR,
  DELETE_NOTIFICATION_SUCCESS,
  GetAllNotification,
  GET_ALL_NOTIFICATION,
  DELETE_NOTIFICATION,
  DeleteNotification,
  GET_ALL_NOTIFICATION_ERROR,
  GET_ALL_NOTIFICATION_SUCCESS,
  UpdateNotification,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_ERROR,
  UPDATE_MANY_NOTIFICATIONS_ERROR,
  UPDATE_MANY_NOTIFICATIONS_SUCCESS,
  UpdateManyNotifications,
  UPDATE_MANY_NOTIFICATIONS,
  SEE_ALL_NOTIFICATIONS,
  SeeAllNotificactions,
} from "../types/notification";
import { NotificationDto } from "../../types/notification.dto";

function* getAllNotifications({ payload }) {
  const { response, error } = yield call(services.notification.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_NOTIFICATION_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_NOTIFICATION_SUCCESS, payload: response });
  }
}

function* updateNotification({ payload }) {
  const { response, error } = yield call(services.notification.update, payload);

  if (error) {
    yield put({
      type: UPDATE_NOTIFICATION_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_NOTIFICATION_SUCCESS, payload: response })]);
  }
}
function* updateManyNotification({ payload }) {
  let updatedList: NotificationDto[] = [];

  try {
    for (let i = 0; i < payload.items.length; i++) {
      const { _id, ...body } = payload.items[i];
      const formDataInsteadBody = body.body ? body.body : body;
      const { response, error } = yield call(services.notification.update, {
        _id,
        ...formDataInsteadBody,
      });
      if (error) {
        throw new Error(error.response.data.message);
      }
      if (response) {
        updatedList = [...updatedList, response];
      }
    }

    yield put({
      type: UPDATE_MANY_NOTIFICATIONS_SUCCESS,
      payload: updatedList,
    });
  } catch (err) {
    yield put({ type: UPDATE_MANY_NOTIFICATIONS_ERROR, payload: err });
  }
}

function* seeAllNotifiactions() {
  const { response, error } = yield call(services.notification.seeAll);
  if (error) {
    yield put({ type: UPDATE_MANY_NOTIFICATIONS_ERROR, payload: error });
  } else {
    yield put({
      type: UPDATE_MANY_NOTIFICATIONS_SUCCESS,
      payload: response,
    });
  }
}
function* newNotification({ payload }) {
  const { response, error } = yield call(services.notification.new, payload);

  if (error) {
    yield put({
      type: NEW_NOTIFICATION_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_NOTIFICATION_SUCCESS, payload: response })]);
  }
}

function* deleteNotification({ payload }) {
  const { response, error } = yield call(services.notification.delete, payload);

  if (error) {
    yield put({
      type: DELETE_NOTIFICATION_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_NOTIFICATION_SUCCESS, payload: response })]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllNotification>(
    GET_ALL_NOTIFICATION,
    getAllNotifications
  );
  yield takeLatest<DeleteNotification>(DELETE_NOTIFICATION, deleteNotification);
  yield takeLatest<NewNotification>(NEW_NOTIFICATION, newNotification);
  yield takeLatest<UpdateNotification>(UPDATE_NOTIFICATION, updateNotification);
  yield takeLatest<UpdateManyNotifications>(
    UPDATE_MANY_NOTIFICATIONS,
    updateManyNotification
  );
  yield takeLatest<SeeAllNotificactions>(
    SEE_ALL_NOTIFICATIONS,
    seeAllNotifiactions
  );
}
