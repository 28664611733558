import { Filter } from "../..";
import { BoxShadowSearchWrapper, Input } from "./style";
import { AiOutlineSearch } from "react-icons/ai";
import { useEffect } from "react";

const Component = ({ setFilter, searchQuery, value }) => {
  /*  useEffect(() => {
    setFilter({ ...appliedFilters, name: "all" });
  }, []); */

  return (
    <div
      style={{
        width: "calc(100% - 10px)",
        margin: "0 auto 10px auto",
      }}
    >
      <BoxShadowSearchWrapper>
        <AiOutlineSearch
          color="black"
          size={20}
          fontWeight={900}
          style={{
            position: "absolute",
            left: "8px",
            top: "12px",
            zIndex: 2,
          }}
        ></AiOutlineSearch>
        <Input
          name="name"
          placeholder="Busca por nombre"
          style={{ height: "45px" }}
          onChange={
            setFilter
            /* setFilter({
                      field: "name",
                      value: ev.target.value !== "" ? ev.target.value : "no",
                      special: (academy, value) => {
                        return academy.name
                          .toLowerCase()
                          .includes(value.toLowerCase());
                      },
                    }); */
          }
          onKeyDown={searchQuery}
          value={!value ? "" : value}
        />
      </BoxShadowSearchWrapper>
    </div>
  );
};

export default Component;
