import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { TrianglePlay } from "../../components/CustomPlayer/style";
import {
  getCookie,
  setCookie,
  removeCookie,
} from "../../helpers/cookies-handler"; // Import the removeCookie function
import { Wrapper, Cover, PlayButton, ReplayButton } from "./styles"; // Import the ReplayButton component
import { FaPlay } from "react-icons/fa";
import { useTheme } from "styled-components";
import { useWindowSize } from "../../hooks/useWindowScreen";

interface ComponentProps {
  src: string;
  startTime?: number;
  cover: string;
  style: CSSProperties;
  onPlay: () => void;
}

const Video = ({
  src,
  startTime = 0,
  cover = "",
  style,
  onPlay,
}: ComponentProps) => {
  const cookieName = "DEVPLACE_VIEWED_VIDEOS";
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const theme = useTheme();
  const { isMobile } = useWindowSize();
  const [startFrom, setStartFrom] = useState(startTime);
  const [showVideo, setShowVideo] = useState(false);
  const [showReplayButton, setShowReplayButton] = useState(false); // State for replay button
  console.log(src, videoRef.current, "makaxp");
  const playVideo = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = startFrom;
      setShowVideo(true);
      setShowReplayButton(false);
      !!onPlay && onPlay();

      // Check if the video is loaded before playing
      if (!videoRef.current.paused && videoRef.current.readyState >= 3) {
        videoRef.current.play();
      } else {
        // Wait for the "canplay" event before playing
        videoRef.current.addEventListener("canplay", () => {
          videoRef?.current?.play();
        });
      }
    }
  }, [startFrom, onPlay]);

  const ReplayVideo = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;

      setShowVideo(true);
      setShowReplayButton(false);
      !!onPlay && onPlay();

      // Check if the video is loaded before playing
      if (!videoRef.current.paused && videoRef.current.readyState >= 3) {
        videoRef.current.play();
      } else {
        // Wait for the "canplay" event before playing
        videoRef.current.addEventListener("canplay", () => {
          videoRef?.current?.play();
        });
      }
    }
  }, [startFrom, onPlay]);

  const saveProgress = () => {
    const cookie = getCookie(cookieName);

    if (cookie) {
      const viewedVideos = JSON.parse(cookie);
      const otherVideosViewed = viewedVideos.filter(
        (viewedVideo) => viewedVideo.src !== src
      );
      const videoViewed = viewedVideos.find(
        (viewedVideo) => viewedVideo.src === src
      );

      if (videoViewed && videoRef.current) {
        setCookie(
          cookieName,
          JSON.stringify([
            ...otherVideosViewed,
            {
              ...videoViewed,
              startTime: videoRef.current.currentTime.toString(),
            },
          ])
        );
      }

      if (!videoViewed && videoRef.current) {
        setCookie(
          cookieName,
          JSON.stringify([
            ...otherVideosViewed,
            { src, startTime: videoRef.current.currentTime.toString() },
          ])
        );
      }
      if (videoRef.current) {
        console.log("Saving Progress", videoRef.current.currentTime.toString());
      }
    } else {
      if (videoRef.current) {
        setCookie(
          cookieName,
          JSON.stringify([
            { src, startTime: videoRef.current.currentTime.toString() },
          ])
        );
      }
    }
  };

  const removeCurrentVideoCookie = () => {
    const cookie = getCookie(cookieName);

    if (cookie) {
      const viewedVideos = JSON.parse(cookie);
      const updatedVideos = viewedVideos.filter(
        (viewedVideo) => viewedVideo.src !== src
      );
      setCookie(cookieName, JSON.stringify(updatedVideos));
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = startTime;
    }
  }, [startFrom]);

  useEffect(() => {
    const cookie = getCookie(cookieName);

    if (cookie) {
      const viewedVideos = JSON.parse(cookie);
      const video = viewedVideos.find((viewedVideo) => viewedVideo.src === src);

      if (video && videoRef.current) {
        videoRef.current.currentTime = video.startTime;
        setStartFrom(video.startTime);
      }
    }
  }, [src]);

  useEffect(() => {
    return () => {
      videoRef.current = null;
    };
  }, []);

  // useEffect(() => {
  //   if (showReplayButton && videoRef.current) {
  //     videoRef.current.currentTime = 0;
  //     setStartFrom(0)
  //   }
  // }, [showReplayButton])

  useEffect(() => {
    if (videoRef.current) {
      // Add an event listener for the "play" event
      videoRef.current.addEventListener("play", () => {
        // Handle the "play" event here
        // You can call the onPlay function if it's provided
        setShowReplayButton(false);
      });
    }

    return () => {
      if (videoRef.current) {
        // Remove the event listener when the component unmounts
        videoRef.current.removeEventListener("play", () => {
          // Handle any cleanup if needed
        });
      }
    };
  }, []);

  return (
    <Wrapper style={style}>
      {!showVideo && cover && (
        <>
          <Cover style={{ backgroundImage: `url(${cover})` }} />
          <PlayButton onClick={playVideo}>
            <FaPlay
              color={theme.colors.LightBlue}
              width={"138px"}
              height={"158px"}
              size={isMobile ? 32 : 128}
            ></FaPlay>
          </PlayButton>
        </>
      )}

      <video
        key={src} // Add a key attribute to force re-render when src changes
        playsInline={isMobile}
        style={{ ...(!showVideo ? { display: "none" } : {}) }}
        width={"100%"}
        height={"100%"}
        ref={videoRef}
        /* src={src} */
        controls
        controlsList="nodownload"
        onTimeUpdate={saveProgress}
        onEnded={() => {
          setShowReplayButton(true); // Show replay button
          removeCurrentVideoCookie(); // Remove the cookie for the current video
        }}
      >
        <source src={src} type="video/mp4"></source>
      </video>

      {showReplayButton && (
        <ReplayButton onClick={ReplayVideo}>Repetir</ReplayButton>
      )}
    </Wrapper>
  );
};

export default Video;
