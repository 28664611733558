import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  NewSuscription,
  NEW_SUSCRIPTION,
  NEW_SUSCRIPTION_ERROR,
  NEW_SUSCRIPTION_SUCCESS,
  DELETE_SUSCRIPTION_ERROR,
  DELETE_SUSCRIPTION_SUCCESS,
  GetAllSuscription,
  GET_ALL_SUSCRIPTION,
  DELETE_SUSCRIPTION,
  DeleteSuscription,
  GET_ALL_SUSCRIPTION_ERROR,
  GET_ALL_SUSCRIPTION_SUCCESS,
  UpdateSuscription,
  UPDATE_SUSCRIPTION,
  UPDATE_SUSCRIPTION_SUCCESS,
  UPDATE_SUSCRIPTION_ERROR,
  GET_SUSCRIPTION_ERROR,
  GET_SUSCRIPTION_SUCCESS,
  GetSuscription,
  GET_SUSCRIPTION,
} from "../types/suscription";

function* getAllSuscriptions() {
  const { response, error } = yield call(services.suscription.getAll);

  if (error) {
    yield put({
      type: GET_ALL_SUSCRIPTION_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_SUSCRIPTION_SUCCESS, payload: response });
  }
}

function* newSuscription({ payload }) {
  const { response, error } = yield call(services.suscription.new, payload);

  if (error) {
    yield put({
      type: NEW_SUSCRIPTION_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: NEW_SUSCRIPTION_SUCCESS, payload: response }),
      put({ type: GET_ALL_SUSCRIPTION, payload: null }),
    ]);
  }
}
function* updateSuscription({ payload }) {
  const { response, error } = yield call(services.suscription.update, payload);

  if (error) {
    yield put({
      type: UPDATE_SUSCRIPTION_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_SUSCRIPTION_SUCCESS, payload: response }),
      put({ type: GET_ALL_SUSCRIPTION, payload: null }),
    ]);
  }
}

function* deleteSuscription({ payload }) {
  const { response, error } = yield call(services.suscription.delete, payload);

  if (error) {
    yield put({
      type: DELETE_SUSCRIPTION_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: DELETE_SUSCRIPTION_SUCCESS, payload: response }),
      put({ type: GET_ALL_SUSCRIPTION, payload: null }),
    ]);
  }
}

function* getSuscription({ payload }) {
  const { response, error } = yield call(services.suscription.getOne, payload);

  if (error) {
    yield put({
      type: GET_SUSCRIPTION_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: GET_SUSCRIPTION_SUCCESS, payload: response })]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllSuscription>(GET_ALL_SUSCRIPTION, getAllSuscriptions);
  yield takeLatest<DeleteSuscription>(DELETE_SUSCRIPTION, deleteSuscription);
  yield takeLatest<NewSuscription>(NEW_SUSCRIPTION, newSuscription);
  yield takeLatest<UpdateSuscription>(UPDATE_SUSCRIPTION, updateSuscription);
  yield takeLatest<GetSuscription>(GET_SUSCRIPTION, getSuscription);
}
