import * as Yup from "yup";

export const schema = Yup.object().shape({
  name: Yup.string()
    .min(4, "Al menos 4 caracteres")
    .max(40, "Maximo 40 caracteres")
    .required("Se requiere nombre"),
  lastName: Yup.string()
    .min(4, "Al menos 4 caracteres ")
    .max(40, "Maximo 40 caracteres")
    .required("Se requiere apellido"),
  username: Yup.string()
    .min(5, "Al menos 4 caracteres")
    .max(50, "Maximo 50 caracteres")
    .matches(/^\S*$/, "No se permiten espacios en blanco"),
  email: Yup.string()
    .email("El email es invalido.")
    .required("Ingresa tu correo electrónico."),
  password: Yup.string()
    .required("Ingresa la contraseña de tu cuenta.")
    .min(5, "Minimo 5 caracteres")
    .max(40, "Maximo 40 caracteres"),
  "confirm-password": Yup.string()
    .required("Confirme contraseña")
    .test("passwords-match", "La contraseña debe coincidir", function (value) {
      return this.parent.password === value;
    }),
});

interface FormValues {
  name: string;
  username: string;
  lastName: string;
  email: string;
  password: string;
  "confirm-password": string;
}

export const initialValues: FormValues = {
  name: "",
  lastName: "",
  username: "",
  email: "",
  password: "",
  "confirm-password": "",
};
