import {
  NewComment,
  NEW_COMMENT,
  ResetNewComment,
  RESET_NEW_COMMENT,
  GET_ALL_COMMENTS,
  GetAllComments,
  UpdateComment,
  UPDATE_COMMENT,
  ResetUpdateComment,
  RESET_UPDATE_COMMENT,
  GetComment,
  DeleteComment,
  DELETE_COMMENT,
  GET_COMMENT,
  RESET_DELETE_COMMENT,
  MANAGE_LIKE_DISLIKE_COMMENT,
  RESET_GET_ALL_COMMENT_DATA,
  GET_REPLIES,
  RESET_GET_REPLIES,
  RESET_GET_REPLIES_DATA,
} from "../types/comment";

const create = (payload): NewComment => {
  return { type: NEW_COMMENT, payload };
};
const resetCreate = (): ResetNewComment => {
  return { type: RESET_NEW_COMMENT, payload: null };
};

const update = (payload): UpdateComment => {
  return { type: UPDATE_COMMENT, payload };
};

const resetUpdate = (): ResetUpdateComment => {
  return { type: RESET_UPDATE_COMMENT, payload: null };
};

const getAll = (payload): GetAllComments => {
  return { type: GET_ALL_COMMENTS, payload };
};

const getOne = (payload: { _id: string }): GetComment => {
  return { type: GET_COMMENT, payload };
};

const getReplies = (payload) => {
  return { type: GET_REPLIES, payload };
};

const resetGetReplies = () => {
  return { type: RESET_GET_REPLIES, payload: null };
};

const resetGetRepliesData = () => {
  return { type: RESET_GET_REPLIES_DATA, payload: null };
};

const manageLikeDislike = (payload) => {
  return { type: MANAGE_LIKE_DISLIKE_COMMENT, payload };
};

const resetGetAllComments = () => {
  return { type: RESET_GET_ALL_COMMENT_DATA, payload: null };
};

const deleteOne = (payload: { _id: string }): DeleteComment => {
  return { type: DELETE_COMMENT, payload };
};

const resetDeleteOne = () => {
  return { type: RESET_DELETE_COMMENT, payload: null };
};

const actions = {
  create,
  resetCreate,
  update,
  resetUpdate,
  resetDeleteOne,
  getAll,
  getOne,
  deleteOne,
  manageLikeDislike,
  resetGetAllComments,
  getReplies,
  resetGetReplies,
  resetGetRepliesData,
};

export default actions;
