import { all } from "redux-saga/effects";
import auth from "./auth";
import user from "./user";
import ability from "./ability";
import category from "./category";
import event from "./event";
import mentor from "./mentor";
import student from "./student";
import cohort from "./cohort";
import meet from "./meet";
import academy from "./academy";
import users from "./users";
import talent from "./talent";
import analytics from "./analytics";
import content from "./content";
import request from "./request";
import certificate from "./certificate";
import invitation from "./invitation";
import unit from "./unit";
import notification from "./notification";
import cdoc from "./cdoc";
import video from "./video";
import confirmation from "./confirmation";
import confirmationAuth from "./confirmationAuth";
import roadmap from "./roadmap";
import image from "./image";
import slideshow from "./slideshow";
import suscription from "./suscription";
import comment from "./comment";
import listen from "./listen";
import generalContent from "./generalContent";
import byte from "./byte";
import userTimes from "./userTimes";
import tag from "./tag";
export default function* rootSaga() {
  yield all([
    auth(),
    talent(),
    video(),
    userTimes(),
    confirmation(),
    generalContent(),
    tag(),
    byte(),
    listen(),
    roadmap(),
    confirmationAuth(),
    image(),
    suscription(),
    slideshow(),
    notification(),
    unit(),
    comment(),
    certificate(),
    invitation(),
    request(),
    cdoc(),
    academy(),
    analytics(),
    content(),
    users(),
    user(),
    ability(),
    category(),
    event(),
    mentor(),
    student(),
    cohort(),
    meet(),
  ]);
}
