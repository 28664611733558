import styled from "styled-components";

export const Slide = styled("div")`
  padding: 5px;
  position: relative;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "none")};
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 0 4px 0 rgba(36, 150, 237, 0.25);
  }
`;

export const WrapperSlider = styled("div")`
  display: flex !important;
  justify-content: center;
  align-items: center;
`;

export const WrapperSlide = styled("div")`
  display: flex !important;
  justify-content: center;
  align-items: center;
`;

export const SlideImg = styled("img")`
  width: 100%;
  height: 109.1px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 7.9px;
`;

export const SlideTitle = styled("h3")`
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
`;

export const SlideSubTitle = styled("h4")`
  font-family: Lato;
  font-size: 11px;
  font-weight: ${(props) => (!!props.bold ? "bold" : "normal")};
`;

export const SlideLink = styled("a")`
  text-decoration: none;
  display: block;
`;

export const SlideBlur = styled("div")`
  position: absolute;
  cursor: no-drop;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(166, 166, 166, 0.2);
`;
