import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  GET_EVENT,
  GET_EVENT_SUCCESS,
  GET_EVENT_ERROR,
  GET_ALL_EVENTS,
  GET_ALL_EVENTS_SUCCESS,
  GET_ALL_EVENTS_ERROR,
  NEW_EVENT,
  NEW_EVENT_SUCCESS,
  NEW_EVENT_ERROR,
  UPDATE_EVENT,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_ERROR,
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
  GetEvent,
  GetAllEvents,
  NewEvent,
  UpdateEvent,
  DeleteEvent,
} from "../types/event";

function* getAllEvents({ payload }) {
  const { response, error } = yield call(services.event.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_EVENTS_ERROR,
      payload: error,
    });
  } else {
    yield put({ type: GET_ALL_EVENTS_SUCCESS, payload: response });
  }
}

function* getEvent({ payload }) {
  const { response, error } = yield call(services.event.getOne, payload);

  if (error) {
    yield put({
      type: GET_EVENT_ERROR,
      payload: error,
    });
  } else {
    yield put({ type: GET_EVENT_SUCCESS, payload: response });
  }
}

function* newEvent({ payload }) {
  const { response, error } = yield call(services.event.new, payload);

  if (error) {
    yield put({
      type: NEW_EVENT_ERROR,
      payload: error,
    });
  } else {
    yield all([
      put({ type: NEW_EVENT_SUCCESS, payload: response }),
      put({ type: GET_ALL_EVENTS, payload: null }),
    ]);
  }
}

function* updateEvent({ payload }) {
  const { response, error } = yield call(services.event.update, payload);

  if (error) {
    yield put({
      type: UPDATE_EVENT_ERROR,
      payload: error,
    });
  } else {
    yield all([
      put({ type: UPDATE_EVENT_SUCCESS, payload: response }),
      put({ type: GET_ALL_EVENTS, payload: null }),
    ]);
  }
}

function* deleteEvent({ payload }) {
  const { response, error } = yield call(services.event.delete, payload);

  if (error) {
    yield put({
      type: DELETE_EVENT_ERROR,
      payload: error,
    });
  } else {
    yield all([
      put({ type: DELETE_EVENT_SUCCESS, payload: response }),
      put({ type: GET_ALL_EVENTS, payload: null }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllEvents>(GET_ALL_EVENTS, getAllEvents);
  yield takeLatest<GetEvent>(GET_EVENT, getEvent);
  yield takeLatest<NewEvent>(NEW_EVENT, newEvent);
  yield takeLatest<UpdateEvent>(UPDATE_EVENT, updateEvent);
  yield takeLatest<DeleteEvent>(DELETE_EVENT, deleteEvent);
}
