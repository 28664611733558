import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Layout,
  Content,
  Sidebar,
  SidebarHeader,
  SidebarBody,
  SidebarToggle,
  GlobalStyles,
} from "./styles";
import Profile from "../../Profile";
import Header from "../../Header";
import Menu from "../../Menu";
import HambugerMenuImage from "../../../assets/icons/menu-burger-remix.svg";
import { useDashboardLayout } from "../../../provider/DashboardProvider";
import { connect } from "react-redux";
import { useWindowSize } from "../../../hooks/useWindowScreen";
import MenuMobile from "../../MenuMobile";
import HeaderMobile from "../../HeaderMobile";
import { useEffect } from "react";
import SignIn from "../../../pages/SignIn";
import SignUp from "../../../pages/SignUp";
import { MdOutlineClose } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
interface ComponentProps {
  modal: string | null;
}

const Component = ({ modal }: ComponentProps) => {
  const { dashboardStyles, sidebarCollapsed, setSidebarCollapsed } =
    useDashboardLayout();
  const { isMobile } = useWindowSize();

  return (
    <>
      <GlobalStyles />
      <Layout
        id="goTop"
        isMobile={isMobile}
        sidebarCollapsed={sidebarCollapsed}
      >
        {isMobile ? (
          /*   <MenuMobile></MenuMobile> */ <></>
        ) : (
          <Sidebar
            sidebarCollapsed={sidebarCollapsed}
            style={dashboardStyles.sidebar}
            modal={modal}
          >
            <SidebarHeader sidebarCollapsed={sidebarCollapsed}>
              <Profile sidebarCollapsed={sidebarCollapsed} />
              <SidebarToggle
                sidebarCollapsed={sidebarCollapsed}
                onClick={() => {
                  !!setSidebarCollapsed &&
                    setSidebarCollapsed((state) => !state);
                }}
              >
                {sidebarCollapsed ? (
                  <GiHamburgerMenu
                    size={23}
                    color="rgb(39, 50, 65)"
                  ></GiHamburgerMenu>
                ) : (
                  <MdOutlineClose size={23} color="rgb(39, 50, 65)" />
                )}
              </SidebarToggle>
            </SidebarHeader>

            <SidebarBody sidebarCollapsed={sidebarCollapsed}>
              <Menu sidebarCollapsed={sidebarCollapsed} />
            </SidebarBody>
          </Sidebar>
        )}

        {/*   {isMobile ? (
          <HeaderMobile></HeaderMobile>
        ) : ( */}
        <Header
          sidebarCollapsed={sidebarCollapsed}
          style={dashboardStyles.header}
          modal={modal}
        />
        {/*  )} */}

        <Content
          id="parentContainer"
          isMobile={isMobile}
          modal={modal}
          style={dashboardStyles.content}
        >
          <Outlet />
        </Content>
      </Layout>
      <SignIn></SignIn>
      <SignUp></SignUp>
      <ToastContainer
        enableMultiContainer
        containerId={"container2"}
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

const states = ({ modalStore }) => {
  const { visibleModal: modal } = modalStore;

  return {
    modal,
  };
};

export default connect(states)(Component);
