import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import {
  GridCard,
  GridCardDescription,
  GridCardImage,
  GridCardImageContainer,
  GridCardTitle,
  GridStructure,
} from "../../../components/Layout/Dashboard/styles";
import { saveAs } from "file-saver";
import {
  Center,
  DotContent,
  FlexContainer,
} from "../../../components/StyledComponents";
import Button from "../../../components/Button";
import { connect, useDispatch } from "react-redux";
import { AcademyDto } from "../../../types/academy.dto";
import { UserDto } from "../../../types/user.dto";
import academyActions, { addClick } from "../../../store/actions/academies";
import certificateActions from "../../../store/actions/certificate";
import FilterByName from "./components/FilterByName";
import { CohortDto } from "../../../types/cohort.dto";

import { CertificateDto } from "../../../types/certificate.dto";
import { TalentDto } from "../../../types/talent.dto";
import { Icon, TrashIcon } from "../Content/style";
import { MdVisibilityOff, MdVisibility } from "react-icons/md";
import { Img } from "../../SignIn/styles";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import ModalConfirmDelete from "../../../components/ModalConfirmDelete";
import { StateDto } from "../../../types/states.dto";
import { showModal } from "../../../store/actions/modal";
import { toast } from "react-toastify";
import {
  getAllCohorts,
  resetGetAllCohorts,
} from "../../../store/actions/cohort";
import { getTalents, resetGetAllTalents } from "../../../store/actions/talent";
import { BsCloudDownloadFill } from "react-icons/bs";
import InfinityScroll from "../../../components/InfinityScroll";

interface ComponentProps {
  user: UserDto;
  certificates: any[];
  updateCertificates: StateDto;
  certificateStates: StateDto;
}

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  user,
  certificates,
  updateCertificates,
  certificateStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [currentCertificatesList, setCurrentCertificatesList] = useState<
    CertificateDto[]
  >([]);
  const [selectedCertificate, setSelectedCertificate] = useState<{
    name: string;
    _id: string;
  } | null>(null);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (data?: any, data2?: any) => boolean;
      groupal: (...args: any) => CertificateDto[];
    }[]
  >([]);
  const [
    filteredCurrentCertificatesListValues,
    setFilteredCurrentCertificatesListValues,
  ] = useState<CertificateDto[]>([]);

  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 9,
      offset: 9 * Math.floor(page / 2),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        published: true,
      },
    };
    const sortBy = page % 2 === 0 ? "createdAt" : "viewCount";

    const sortDirection = "desc";
    dispatch(certificateActions.getAll({ ...payload, sortBy, sortDirection }));
  };

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter(
        (applyedFilter) => applyedFilter.field === newFilter.field
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    /**
     * Se elimina el filtro
     */
    if (filterRemoved) {
      setFilters([
        ...applyedFilters.filter(
          (applyedFilter) => applyedFilter.field !== newFilter.field
        ),
      ]);
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(certificateActions.resetGetAllCertificates());
    };
  }, []);

  useEffect(() => {
    if (updateCertificates.success) {
      toast("👌🏼 Publico", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });
      setSelectedCertificate(null);
      setTimeout(() => {
        dispatch(certificateActions.resetUpdate());
      }, 200);
    }

    if (updateCertificates.error) {
      toast(`😱 ${updateCertificates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });
      setTimeout(() => {
        dispatch(certificateActions.resetUpdate());
      }, 200);
    }
  }, [updateCertificates, dispatch]);

  useEffect(() => {
    if (!isLoading && !!certificates) {
      const currentCertificates = certificates.filter(
        (certificate) => certificate.talent.userId === user._id
      );
      setCurrentCertificatesList((state) => [...state, ...currentCertificates]);
    }
    setThereAreItems(!!certificates && certificates?.length > 0);
  }, [certificates, isLoading]);

  useEffect(() => {
    const filteredTalents = currentCertificatesList?.filter(
      (currentTalentValues) => {
        const thereAreFilters =
          applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
            .length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter(
            (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
          )
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value
          );
        });

        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentCertificatesListValues(initResult);
  }, [applyedFilters, currentCertificatesList]);
  useEffect(() => {
    setIsLoading(certificateStates.loading);
  }, [certificateStates]);
  useEffect(() => {
    if (!!selectedCertificate) {
      dispatch(showModal("confirm-delete-item-modal"));
    }
  }, [selectedCertificate]);

  return (
    <>
      <div style={{ maxWidth: "600px", margin: "0 auto", width: "100%" }}>
        <FilterByName
          items={filteredCurrentCertificatesListValues.map((academy) => {
            return { value: academy.name, label: academy.name };
          })}
          setFilter={buildFilters}
        ></FilterByName>
      </div>

      <Center gap="26px">
        <Button
          type="button"
          onClick={() => setFilters([])}
          options={{
            size: "md",
            type: "filled",
            skin: "violet",
          }}
          minwidth="150px"
          style={{
            width: "auto",
            border: "none",
            height: "32px",
            color: applyedFilters.length === 0 ? "#fff" : theme.colors.Night,
            padding: "7px 8px 8px",
            borderRadius: "5px",
            backgroundColor:
              applyedFilters.length === 0
                ? theme.colors.LightBlue
                : theme.colors.LightBlueHover,
          }}
        >
          Todos
        </Button>
        <Button
          type="button"
          onClick={() => {
            if (
              !applyedFilters.find(
                (applyedFilter) => applyedFilter.field === "last"
              )
            ) {
              buildFilters({
                field: "last",
                value: "",
                groupal: (array: CertificateDto[]) =>
                  array.sort(
                    (a, b) =>
                      new Date(b.createdAt).valueOf() -
                      new Date(a.createdAt).valueOf()
                  ),
              });
            } else {
              buildFilters({
                field: "last",
                value: "no",
              });
            }
          }}
          options={{
            size: "md",
            type: "filled",
            skin: "violet",
          }}
          minwidth="0px"
          style={{
            width: "auto",
            border: "none",
            height: "32px",
            color: applyedFilters.find((filter) => filter.field === "last")
              ? "#fff"
              : theme.colors.Night,
            padding: "7px 8px 8px",
            borderRadius: "5px",
            backgroundColor: applyedFilters.find(
              (filter) => filter.field === "last"
            )
              ? theme.colors.LightBlue
              : theme.colors.LightBlueHover,
          }}
        >
          Ultimos recibidos
        </Button>
      </Center>

      <div style={{ height: "24px" }}></div>

      <InfinityScroll
        action={fetchData}
        page={page}
        setPage={setPage}
        data={filteredCurrentCertificatesListValues}
        active={thereAreItems}
        isLoading={isLoading}
      >
        <GridStructure width="300px" maxWidth="900px">
          {filteredCurrentCertificatesListValues.map((certificate, key) => (
            <GridCard key={certificate._id}>
              <TrashIcon>
                {!certificate.hidden ? (
                  <MdVisibility
                    onClick={() => {
                      setSelectedCertificate({
                        _id: certificate._id,
                        name: certificate.name,
                      });
                    }}
                    fontSize={20}
                  ></MdVisibility>
                ) : (
                  <MdVisibilityOff
                    onClick={() => {
                      dispatch(
                        certificateActions.update({
                          _id: certificate._id,
                          hidden: false,
                        })
                      );
                    }}
                    fontSize={20}
                  ></MdVisibilityOff>
                )}
              </TrashIcon>
              <GridCardImageContainer>
                <GridCardImage
                  src={
                    certificate.picture ||
                    "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                  }
                ></GridCardImage>
              </GridCardImageContainer>
              <FlexContainer gap="12px">
                <Icon
                  style={{ cursor: "pointer" }}
                  onClick={(ev) => {
                    ev.preventDefault();
                    /*  dispatch(addClick({ _id: certificate.cohort.academy })); */
                    navigate(
                      `/dashboard/academy/${certificate.cohort.academy}`
                    );
                  }}
                >
                  <Img src={certificate.academy.picture}></Img>
                </Icon>
                <FlexContainer direction="column" gap="2px">
                  <GridCardTitle>
                    {certificate.cohort.certificateStyles.certificateName}
                  </GridCardTitle>
                  <FlexContainer align="center" gap="5px" wrap="wrap">
                    <GridCardDescription>
                      {certificate.academy.name}
                    </GridCardDescription>
                    <FlexContainer align="center" gap="6px">
                      <DotContent></DotContent>
                      <GridCardDescription>{`Emitido: ${new Date(
                        certificate.createdAt
                      ).toLocaleDateString("en-GB")}`}</GridCardDescription>
                    </FlexContainer>
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                direction="column"
                gap="15px"
                style={{ marginTop: "5px" }}
              >
                <FlexContainer direction="column" gap="18px">
                  <FlexContainer justify="center">
                    {!!certificate.certificateFile && (
                      <Button
                        type="button"
                        onClick={() => {
                          saveAs(
                            `${certificate.certificateFile}`,
                            `${certificate.cohort.certificateStyles.certificateName}.pdf`
                          );
                        }}
                        options={{
                          size: "md",
                          type: "filled",
                          skin: "violet",
                        }}
                        minwidth="150px"
                        style={{
                          width: "150px",
                          backgroundColor: "#fff",
                          borderColor: theme.colors.LightBlue,
                          textAlign: "center",
                        }}
                      >
                        <BsCloudDownloadFill
                          color={theme.colors.LightBlue}
                          fill={theme.colors.LightBlue}
                          fontSize={16}
                        ></BsCloudDownloadFill>
                      </Button>
                    )}
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
            </GridCard>
          ))}
        </GridStructure>
      </InfinityScroll>

      {!!selectedCertificate && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Ocultar",
            style: {
              color: "Danger",
              style: {
                height: "26px",
                backgroundColor: "#000",
                color: "#fff",
                borderColor: "#000",
              },
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
                loading: "White",
              },
            },
          }}
          states={updateCertificates}
          elementActions={certificateActions.update({
            _id: selectedCertificate._id,
            hidden: !certificates.find(
              (certificate) => certificate._id === selectedCertificate._id
            )?.hidden,
          })}
          title={
            <span>
              {`¿Seguro deseas ocultar el certificado `}
              <span style={{ color: "#000" }}>{selectedCertificate.name}</span>?
            </span>
          }
          resetAction={certificateActions.resetUpdate}
          resetState={setSelectedCertificate}
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({ userStore, certificateStore }) => {
  const { data: user } = userStore;
  const { data: certificates, states: certificateStates } =
    certificateStore.all;
  const { states: updateCertificates } = certificateStore.update;
  return {
    user,
    certificates,
    updateCertificates,
    certificateStates,
  };
};

export default connect(states)(Component);
