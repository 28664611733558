import {
  initialState,
  Actions,
  State,
  GET_CONFIRMATIONAUTH,
  GET_CONFIRMATIONAUTH_ERROR,
  GET_CONFIRMATIONAUTH_SUCCESS,
  RESET_UPDATE_CONFIRMATIONAUTH,
  UPDATE_CONFIRMATIONAUTH,
  UPDATE_CONFIRMATIONAUTH_ERROR,
  UPDATE_CONFIRMATIONAUTH_SUCCESS,
} from "../types/confirmationAuth";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_CONFIRMATIONAUTH:
      return {
        ...state,
        confirmationAuth: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_CONFIRMATIONAUTH_SUCCESS:
      return {
        ...state,
        confirmationAuth: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_CONFIRMATIONAUTH_ERROR:
      return {
        ...state,
        confirmationAuth: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    /* case RESET_UPDATE_CONFIRMATIONAUTH:
      return {
        ...state,
        confirmationAuth: initialState.confirmationAuth,
      }; */
    case UPDATE_CONFIRMATIONAUTH:
      return {
        ...state,
        updateConfirmationAuth: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_CONFIRMATIONAUTH_SUCCESS:
      return {
        ...state,
        updateConfirmationAuth: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_CONFIRMATIONAUTH_ERROR:
      return {
        ...state,
        updateConfirmationAuth: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_CONFIRMATIONAUTH:
      return {
        ...state,
        updateConfirmationAuth: initialState.updateConfirmationAuth,
      };
    default:
      return state;
  }
};

export default reducers;
