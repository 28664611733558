import Modal from "../../../../../../components/Modal";
import NewAbilityForm from "../../../New";

const Component = () => {
  return (
    <Modal name="new-ability-modal" title="Nueva Habilidad">
      <NewAbilityForm />
    </Modal>
  );
};

export default Component;
