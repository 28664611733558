import {
  GridCardImage,
  GridCardSmallContainer,
  GridCardTitle,
  GridCardTitleMobile,
  GridComment,
  GridCommentMobile,
} from "../../../../../../components/Layout/Dashboard/styles";
import VideoPlayer from "../../../../../../components/VideoPlayer";
import {
  FlexContainer,
  DotContent,
  PageTitle,
  Separator,
} from "../../../../../../components/StyledComponents";
import { DateFromNow } from "../../../../../../helpers/format-date";
import { GridStructurePlane } from "../../../../../SignIn/styles";
import { ItemDataText } from "../../../../Personal/Home/style";
import {
  GridMobileCard,
  GridMobileImageCard,
  GridMobileInfoCard,
  ShowDescriptionText,
  TrianglePlay,
} from "../../../../style";
import SideBar from "../SideBar";
import CustomPlayer from "../../../../../../components/CustomPlayer";
import { useEffect, useRef, useState } from "react";
import FilterByName from "../FilterByName";
import { connect, useDispatch } from "react-redux";
import Loader from "../../../../../../components/Loader";
import DropDownDescription from "../DropDownDescription";
import { useNavigate } from "react-router-dom";
import {
  addViewVideo,
  manageLikeDislikeVideo,
  resetUpdateVideo,
  setSelectedVideo,
} from "../../../../../../store/actions/video";
import {
  AiFillClockCircle,
  AiFillDislike,
  AiFillEye,
  AiOutlineCheck,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";
import {
  LikeDislikeBox,
  LikeDislikeBoxNumber,
  LikeDislikeText,
} from "../../../../../Cohort/Home/style";
import { overThousandFixed } from "../../../../../../helpers/number-fixed";
import Grid from "../../../../../../components/Grid";
import InfinityScroll from "../../../../../../components/InfinityScroll";
import CommentSection from "../../../../../../components/CommentSection";
import { addClick } from "../../../../../../store/actions/userTimes";
import CommentSectionMobile from "../../../../../../components/CommentSectionMobile";
import DescriptionModal from "../../../../../../components/DescriptionModal";
import Button from "../../../../../../components/Button";
import { AcademyDto } from "../../../../../../types/academy.dto";
import academyActions from "../../../../../../store/actions/academies";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import ShareLink from "../../../../../../components/ShareLink";
import ModalConfirmDelete from "../../../../../../components/ModalConfirmDelete";
const Component = ({
  currentVideosList,
  menu,
  setOpenDropDown,
  openDropDown,
  video,
  queryOptions,
  queryVideosStates,
  scrollOptions,
  user,
  updateVideoStates,
  updatedVideo,
  academy,
  updatedAcademy,
  updateAcademyStates,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDescription, setOpenDescription] = useState<boolean>(false);
  const [action, setAction] = useState<{ action: string; data: any }>();
  const [currentVideoAcademy, setCurrentVideoAcademy] = useState<AcademyDto>();
  const [openCommentsModal, setOpenCommentsModal] = useState<boolean>(false);
  const theme = useTheme();
  useEffect(() => {
    if (academy) {
      setCurrentVideoAcademy(academy);
    }
  }, [academy]);
  useEffect(() => {
    if (updateVideoStates.success) {
      dispatch(setSelectedVideo(updatedVideo));
      dispatch(resetUpdateVideo());
    }
    if (updateVideoStates.error) {
      toast.error(updateVideoStates.error);
      dispatch(resetUpdateVideo());
    }
  }, [updateVideoStates]);

  useEffect(() => {
    if (updateAcademyStates.success && currentVideoAcademy) {
      setCurrentVideoAcademy(updatedAcademy);
      dispatch(academyActions.resetUpdate());
    }
    if (updateAcademyStates.error) {
      dispatch(academyActions.resetUpdate());
    }
  }, [updateAcademyStates]);

  useEffect(() => {
    if (video) {
      dispatch(academyActions.getOne({ _id: video.academy._id }));
    }
  }, [video]);

  useEffect(() => {
    return () => {
      dispatch(academyActions.resetGetOneAcademy());
    };
  }, []);

  return (
    <>
      <div
        onClick={() => {
          dispatch(
            addClick({
              id: video._id,
              user: user._id,
              type: "CONTENT",
            })
          );
        }}
      >
        <VideoPlayer
          src={video.url}
          cover={video.picture}
          style={{
            height: "calc(100vw / 1.89)",
            width: "100%",
            maxHeight: "500px",
          }}
          onPlay={() => {
            dispatch(addViewVideo({ _id: video._id }));
          }}
        />
      </div>

      <GridMobileCard>
        <div style={{ marginTop: "4px", padding: "0 10px" }}>
          <GridCardTitle style={{ fontSize: "14px" }}>
            {video.name}
          </GridCardTitle>
          <Separator size={8}></Separator>
          <FlexContainer gap="7px" align="center">
            <GridComment>{`Vistas: ${video.viewCount}`}</GridComment>
            <span>|</span>
            <FlexContainer gap="3px" align="center">
              <AiFillClockCircle fill="#6a6970"></AiFillClockCircle>
              <GridComment>{DateFromNow(video.createdAt)}</GridComment>
            </FlexContainer>
            <ShowDescriptionText
              onClick={() => {
                setOpenDescription(true);
              }}
            >
              Ver Descripcion
            </ShowDescriptionText>
          </FlexContainer>
          <FlexContainer
            wrap="wrap"
            gap="2px"
            align="center"
            justify="space-between"
          >
            <FlexContainer gap="7px" wrap="wrap" align="center">
              <GridCardSmallContainer>
                <GridCardImage src={video.academy.picture}></GridCardImage>
              </GridCardSmallContainer>
              <FlexContainer direction="column">
                <GridCommentMobile style={{ color: theme.colors.DarkNight }}>
                  {video.academy.name}
                </GridCommentMobile>

                <GridCommentMobile>{`${currentVideoAcademy?.userCount} Suscriptos`}</GridCommentMobile>
              </FlexContainer>
            </FlexContainer>
            {currentVideoAcademy ? (
              !currentVideoAcademy.users.find(
                (userF) => userF.user === user._id && !userF.roleEnd
              ) ? (
                !currentVideoAcademy.private ? (
                  <Button
                    type="button"
                    onClick={() => {
                      dispatch(
                        academyActions.addMember({
                          _id: currentVideoAcademy._id,
                          user: user._id,
                          role: "STUDENT",
                        })
                      );
                      dispatch(
                        addClick({
                          id: currentVideoAcademy._id,
                          user: user._id,
                          type: "ACADEMY",
                        })
                      );
                    }}
                    options={{
                      size: "md",
                      type: "filled",
                      skin: "lightblue",
                    }}
                    loading={updateAcademyStates.loading}
                  >
                    Suscribirse
                  </Button>
                ) : (
                  <></>
                )
              ) : (
                <Button
                  type="button"
                  onClick={() => {
                    setAction({
                      data: null,
                      action: "out-of-academy",
                    });
                    dispatch(
                      addClick({
                        id: currentVideoAcademy._id,
                        user: user._id,
                        type: "ACADEMY",
                      })
                    );
                  }}
                  options={{
                    size: "md",
                    type: "outline",
                    skin: "lightblue",
                  }}
                  loading={updateAcademyStates.loading}
                >
                  <FlexContainer gap="4px" align="center">
                    <AiOutlineCheck
                      size={14}
                      color={theme.colors.LightBlue}
                    ></AiOutlineCheck>
                    <span>Suscripto</span>
                  </FlexContainer>
                </Button>
              )
            ) : (
              <Loader color="LightBlue"></Loader>
            )}
          </FlexContainer>
          <Separator size={10}></Separator>
          <FlexContainer gap="5px">
            <FlexContainer gap="12px" align="center">
              <LikeDislikeBox
                className={
                  !!video.likes.find((like) => like.user === user._id)
                    ? "active"
                    : ""
                }
                onClick={() => {
                  if (video.likes.find((like) => like.user === user._id)) {
                    dispatch(
                      manageLikeDislikeVideo({
                        _id: video._id,
                        condition: "like",
                        action: "remove",
                        individual: true,
                        notReload: true,
                      })
                    );
                  } else {
                    dispatch(
                      manageLikeDislikeVideo({
                        _id: video._id,
                        condition: "like",
                        action: "add",
                        individual: true,
                        notReload: true,
                      })
                    );
                  }
                }}
              >
                <FlexContainer gap="3px" align="center">
                  <div
                    className="like-dislike-icon"
                    style={{ background: "#fff", padding: "4px" }}
                  >
                    <AiOutlineLike size={15} fill={"#2496ed"} />
                  </div>
                  <LikeDislikeBoxNumber className="count-likes">
                    <LikeDislikeText>
                      {overThousandFixed(video.likes.length)}
                    </LikeDislikeText>
                  </LikeDislikeBoxNumber>
                </FlexContainer>
              </LikeDislikeBox>

              <LikeDislikeBox
                className={
                  !!video.dislikes.find((like) => like.user === user._id)
                    ? "active"
                    : ""
                }
                onClick={() => {
                  if (video.dislikes.find((like) => like.user === user._id)) {
                    dispatch(
                      addClick({
                        id: video._id,
                        user: user._id,
                        type: "CONTENT",
                      })
                    );
                    dispatch(
                      manageLikeDislikeVideo({
                        _id: video._id,
                        condition: "dislike",
                        action: "remove",
                        individual: true,
                        notReload: true,
                      })
                    );
                  } else {
                    dispatch(
                      addClick({
                        id: video._id,
                        user: user._id,
                        type: "CONTENT",
                      })
                    );
                    dispatch(
                      manageLikeDislikeVideo({
                        _id: video._id,
                        condition: "dislike",
                        action: "add",
                        individual: true,
                        notReload: true,
                      })
                    );
                  }
                }}
              >
                <FlexContainer gap="3px" align="center">
                  <div
                    className="like-dislike-icon"
                    style={{
                      padding: "7px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {video.dislikes.find((like) => like.user === user._id) ? (
                      <AiFillDislike fill={"#fff"} size={15}></AiFillDislike>
                    ) : (
                      <AiOutlineDislike
                        fill={"#2496ed"}
                        size={15}
                      ></AiOutlineDislike>
                    )}
                  </div>
                  {/*   <LikeDislikeBoxNumber className="count-likes">
                              <LikeDislikeText>
                                {overThousandFixed(video.dislikes.length)}
                              </LikeDislikeText>
                            </LikeDislikeBoxNumber> */}
                </FlexContainer>
              </LikeDislikeBox>

              <ShareLink
                style={{
                  text: {
                    color: theme.colors.White,
                  },
                  textBox: {
                    backgroundColor: theme.colors.LightBlue,
                  },
                }}
              />
            </FlexContainer>
          </FlexContainer>
          {/* <DropDownDescription text={video.description}></DropDownDescription> */}
          <Separator size={12} />
          <CommentSectionMobile
            isModalOpen={openCommentsModal}
            setIsModalOpen={setOpenCommentsModal}
            itemId={video._id}
            section="content"
          ></CommentSectionMobile>
          <Separator size={12} />
          <Grid.Row>
            <Grid.Col /* lg={8} xs={10} offset={{ lg: 3, xs: 1 }} */ xs={12}>
              {/*   <PageTitle
              style={{ margin: "14px 0px 8px 10px", fontSize: "14px" }}
            >
              Mas Videos
            </PageTitle> */}

              <FilterByName
                searchQuery={queryOptions.searchQuery}
                setFilter={queryOptions.setFilter}
                value={queryOptions.value}
              ></FilterByName>

              <FlexContainer
                style={{
                  width: "100%",
                  marginTop: "10px",
                  maxHeight: "500px",
                  overflow: "auto",
                  padding: "0 5px",
                }}
              >
                <InfinityScroll
                  action={scrollOptions.fetchData}
                  page={scrollOptions.page}
                  setPage={scrollOptions.setPage}
                  data={currentVideosList}
                  active={scrollOptions.thereAreItems}
                  isLoading={scrollOptions.isLoading}
                  style={{ width: "100%", paddingBottom: "10px" }}
                >
                  {currentVideosList.map((videoFounded) => {
                    if (videoFounded._id === video._id) return null;
                    return (
                      <GridMobileCard
                        style={{ cursor: "pointer", marginBottom: "10px" }}
                        onClick={() => {
                          dispatch(
                            addClick({
                              id: videoFounded._id,
                              user: user._id,
                              type: "CONTENT",
                            })
                          );
                          navigate(`../${videoFounded._id}`, {
                            replace: true,
                          });
                        }}
                      >
                        <GridMobileImageCard>
                          <GridCardImage
                            src={videoFounded.picture}
                          ></GridCardImage>
                        </GridMobileImageCard>
                        <GridMobileInfoCard>
                          <FlexContainer gap="12px">
                            <GridCardSmallContainer
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                dispatch(
                                  addClick({
                                    id: videoFounded.academy._id,
                                    user: user._id,
                                    type: "ACADEMY",
                                  })
                                );
                                navigate(
                                  `/dashboard/academy/${videoFounded.academy._id}`
                                );
                              }}
                            >
                              <GridCardImage
                                src={videoFounded.academy.picture}
                              ></GridCardImage>
                            </GridCardSmallContainer>
                            <FlexContainer direction="column" gap="1px">
                              <GridCardTitleMobile>
                                {videoFounded.name}
                              </GridCardTitleMobile>
                              <FlexContainer direction="column" gap="4px">
                                <FlexContainer align="center" gap="5px">
                                  <FlexContainer align="center" gap="3px">
                                    <AiFillEye fill="#6a6970"></AiFillEye>

                                    <GridCommentMobile>
                                      {videoFounded.viewCount}
                                    </GridCommentMobile>
                                  </FlexContainer>
                                  <FlexContainer align="center" gap="5px">
                                    <AiFillClockCircle fill="#6a6970"></AiFillClockCircle>

                                    <GridCommentMobile>
                                      {DateFromNow(videoFounded.createdAt)}
                                    </GridCommentMobile>
                                  </FlexContainer>
                                </FlexContainer>
                              </FlexContainer>
                            </FlexContainer>
                          </FlexContainer>
                        </GridMobileInfoCard>
                      </GridMobileCard>
                    );
                  })}
                </InfinityScroll>
              </FlexContainer>
            </Grid.Col>
          </Grid.Row>
        </div>
      </GridMobileCard>

      <DescriptionModal
        isModalOpen={openDescription}
        setIsModalOpen={setOpenDescription}
        content={video}
      ></DescriptionModal>
      {currentVideoAcademy && action?.action === "out-of-academy" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Desuscribirse",
            style: {
              color: "Danger",

              style: {
                width: "150px",
                height: "31px",
              },
              options: {
                type: "filled",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={updateAcademyStates}
          elementActions={academyActions.deleteMember({
            _id: currentVideoAcademy._id,
            userId: user._id,
          })}
          onClose={() => {
            setAction(undefined);
          }}
          title={
            <FlexContainer direction="column" gap="11px">
              <span>
                {`¿Seguro quieres salir de la academia `}
                <span style={{ color: "#000" }}>
                  {currentVideoAcademy.name}
                </span>
                ?
              </span>
              <p>
                Perderás todos los accesos y suscripciones relacionados a esta
                Academia y los contenidos que no hayas descargado.
              </p>
            </FlexContainer>
          }
          /*  resetState={() => {
            setAction(undefined);
          }} */
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({ videoStore, userStore, academyStore }) => {
  const { states: queryVideosStates } = videoStore.query;
  const { states: updateVideoStates, data: updatedVideo } = videoStore.update;
  const { data: academy } = academyStore.selected;
  const { data: updatedAcademy, states: updateAcademyStates } =
    academyStore.update;
  const { data: user } = userStore;
  return {
    queryVideosStates,
    user,
    updateVideoStates,
    updatedVideo,
    academy,
    updatedAcademy,
    updateAcademyStates,
  };
};

export default connect(states)(Component);
