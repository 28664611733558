import React, { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import { GridStructure } from "../../../../../components/Layout/Dashboard/styles";
import { FlexContainer } from "../../../../../components/StyledComponents";
import MediaItem from "./components/MediaItem";
import { ListMediaTitle } from "./style";
import { connect, useDispatch } from "react-redux";
import { UserDto } from "../../../../../types/user.dto";
import { addClick } from "../../../../../store/actions/userTimes";
import { showModal } from "../../../../../store/actions/modal";

const sizes = {
  lg: "275px",
  md: "165px",
  sm: "90px",
};
interface ComponentProps {
  title: string;
  items;
  size: "sm" | "md" | "lg";
  style?: CSSProperties;
  onClick?: (event, item) => void;
  user: UserDto;
  limitCard?: string | boolean;
  minCardWidth?: string | boolean;
}

const Component = ({
  title,
  items,
  size,
  style,
  onClick,
  user,
  limitCard,
  minCardWidth,
}: ComponentProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <FlexContainer style={{ minHeight: "200px" }} direction="column" gap="10px">
      <ListMediaTitle>{title}</ListMediaTitle>
      <GridStructure
        limitCard={limitCard ?? sizes[size]}
        width={minCardWidth ?? sizes[size]}
        style={{ width: "100%", margin: 0, ...style }}
      >
        {items.map((item) => {
          return (
            <MediaItem
              {...(user && {
                logoOnclick: (id) => {
                  dispatch(
                    addClick({ id: id, user: user._id, type: "ACADEMY" })
                  );
                  navigate(`/dashboard/academy/${item?.academy?._id}`);
                },
              })}
              onClick={(event) => {
                if (user) {
                  let path = `academy/${item?._id}`;
                  if (item.type) {
                    switch (item.type) {
                      case "VIDEO":
                        path = `videos/${item?._id}`;
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                        break;
                      case "SLIDESHOW":
                        path = `slideshows/${item?._id}`;
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                        break;
                      case "CDOC":
                        path = `cdocs/${item?._id}`;
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                        break;
                      case "COHORT":
                        path = `cohorts/${item?._id}`;
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                        break;
                      case "ROADMAP":
                        path = `roadmaps/${item?._id}`;
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                        break;
                      case "IMAGE":
                        path = `images/${item?._id}`;
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                        break;
                      case "LISTEN":
                        path = `listens/${item?._id}`;
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                        break;
                      case "BYTE":
                        path = `bytes/${item?._id}`;
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                        break;
                    }
                    navigate(`/dashboard/${path}`);
                  }
                  navigate(`/dashboard/${path}`);
                } else {
                  dispatch(showModal("sign-in-content"));
                }
              }}
              key={`media-item-${item._id}`}
              size={size}
              item={item}
            />
          );
        })}
      </GridStructure>
    </FlexContainer>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;
  return {
    user,
  };
};

export default connect(states)(Component);
