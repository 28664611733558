// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}

.fade {
  z-index: -1;
  cursor: no-drop;
}


.slick-prev-new,
.slick-next-new{
        display: block;
        width: 30px;
        height: 30px;
        border-top: 3px solid #2496ed;
        border-left: 3px solid #2496ed;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
        z-index: 200;
}


.slick-next-new {
  right: -15px;
  transform: rotate(135deg);
}

.slick-prev-new{
  left:-15px;
  transform: rotate(-45deg);
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Suscriptions/List/components/ChangeMethodModal/components/Slider/style.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;;AAGA;;QAEQ,cAAc;QACd,WAAW;QACX,YAAY;QACZ,6BAA6B;QAC7B,8BAA8B;QAC9B,kBAAkB;QAClB,QAAQ;QACR,6BAA6B;QAC7B,eAAe;QACf,YAAY;AACpB;;;AAGA;EACE,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,yBAAyB;AAC3B","sourcesContent":[".slick-slide > div {\n  margin: 0 10px;\n}\n.slick-list {\n  margin: 0 -10px;\n}\n\n.fade {\n  z-index: -1;\n  cursor: no-drop;\n}\n\n\n.slick-prev-new,\n.slick-next-new{\n        display: block;\n        width: 30px;\n        height: 30px;\n        border-top: 3px solid #2496ed;\n        border-left: 3px solid #2496ed;\n        position: absolute;\n        top: 50%;\n        transform: translate(0, -50%);\n        cursor: pointer;\n        z-index: 200;\n}\n\n\n.slick-next-new {\n  right: -15px;\n  transform: rotate(135deg);\n}\n\n.slick-prev-new{\n  left:-15px;\n  transform: rotate(-45deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
