import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { GridStructure } from "../../../components/Layout/Dashboard/styles";
import { FlexContainer, Separator } from "../../../components/StyledComponents";
import { Icon, Link, TrashIcon } from "./style";
import { connect, useDispatch } from "react-redux";
import { AcademyDto } from "../../../types/academy.dto";
import { UserDto } from "../../../types/user.dto";
import { BsFillImageFill, BsFillTrashFill } from "react-icons/bs";
import contentActions from "../../../store/actions/content";
import FilterByName from "./components/FilterByName";
import { CohortDto } from "../../../types/cohort.dto";
import { ContentDto } from "../../../types/content.dto";
import { Img } from "../../SignIn/styles";
import ModalConfirmDelete from "../../../components/ModalConfirmDelete";
import { showModal } from "../../../store/actions/modal";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import {
  getAllCDOCs,
  resetGetAllCdocs,
  resetUpdateCDOCs,
  savedCdoc,
} from "../../../store/actions/cdoc";
import {
  getAllVideos,
  resetGetAllVideos,
  resetUpdateVideo,
  saveVideo,
  savedVideo,
} from "../../../store/actions/video";
import { VideoDto } from "../../../types/video.dto";
import { CDocDto } from "../../../types/cdoc.dto";
import { StateDto } from "../../../types/states.dto";
import { DataText } from "../../../components/Profile2/style";
import { DateFromNow } from "../../../helpers/format-date";
import { SlideLink } from "../../../components/Slider/style";
import { addClick as addClickAcademy } from "../../../store/actions/academies";
import { RoadmapDto } from "../../../types/roadmap.dto";
import {
  getAllRoadmaps,
  resetGetAllRoadmaps,
  resetUpdateRoadmap,
  savedRoadmap,
} from "../../../store/actions/roadmap";

import { SlideShowDto } from "../../../types/slideshow.dto";
import { ImageDto } from "../../../types/image.dto";
import { ListenDto } from "../../../types/listen.dto";
import { PaginationDto } from "../../../types/pagination.dto";
import InfinityScroll from "../../../components/InfinityScroll";
import {
  getAllSlideShows,
  resetUpdateSlideShow,
  savedSlideshow,
  updateSlideShow,
} from "../../../store/actions/slideshow";
import {
  getAllImages,
  resetGetAllImages,
  resetUpdateImage,
  saveImage,
} from "../../../store/actions/image";
import {
  getAllListens,
  resetGetAllListens,
  resetUpdateListen,
  savedListen,
} from "../../../store/actions/listen";
import Filters from "./components/Filters";
import { ByteDto } from "../../../types/byte.dto";
import {
  getAllBytes,
  resetGetAllBytes,
  resetUpdateByte,
  savedByte,
} from "../../../store/actions/byte";
import { addClick, addView } from "../../../store/actions/userTimes";
import {
  ContentCardTitle,
  ContentCardWrapper,
  ContentDataText,
  ContentIcon,
} from "../../../components/ContentCard/style";
import ContentCard from "../../../components/ContentCard";
import VideoTraced from "../../../assets/icons/video-traced.svg";
import ByteTraced from "../../../assets/icons/byte-traced.svg";
import { HiDocumentText } from "react-icons/hi";
import { RiOrganizationChart, RiSlideshow3Fill } from "react-icons/ri";
import { MdAudiotrack } from "react-icons/md";
import Skeleton from "./components/Skeleton";

interface ComponentProps {
  user: UserDto;
  contents: ContentDto[];
  videos: VideoDto[];
  cdocs: CDocDto[];
  updateCdocStates: StateDto;
  updateVideoStates: StateDto;
  updateContentStates: StateDto;
  roadmaps: RoadmapDto[];
  updateRoadmapStates: StateDto;
  contentsStates: StateDto;
  videosStates: StateDto;
  cdocsStates: StateDto;
  roadmapsStates: StateDto;
  slideshows: SlideShowDto[];
  slideshowsStates: StateDto;
  images: ImageDto[];
  imagesStates: StateDto;
  listens: ListenDto[];
  listensStates: StateDto;
  getContent;
  getVideo;
  getCdoc;
  getRoadmap;
  getSlideShow;
  getImage;
  getListen;
  updateListenStates;
  updateImageStates;
  updateSlideShowStates;
  bytes;
  bytesStates;
  updateByteStates;
  getByte;
}

export interface Filter {
  [key: string]: string;
}

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

interface ListItems {
  videos?: VideoDto[];
  slideshows?: SlideShowDto[];
  images?: ImageDto[];
  cdocs?: CDocDto[];
  contents?: ContentDto[];
  roadmaps?: RoadmapDto[];
  listens?: ListenDto[];
  bytes?: ByteDto[];
}

const Component = ({
  user,
  contents,
  videos,
  cdocs,
  updateCdocStates,
  updateVideoStates,
  updateContentStates,
  roadmaps,
  updateRoadmapStates,
  contentsStates,
  videosStates,
  cdocsStates,
  roadmapsStates,
  slideshows,
  slideshowsStates,
  images,
  imagesStates,
  listens,
  listensStates,
  getContent,
  getVideo,
  getCdoc,
  getRoadmap,
  getSlideShow,
  getImage,
  getListen,
  updateListenStates,
  updateImageStates,
  updateSlideShowStates,
  getByte,
  bytes,
  bytesStates,
  updateByteStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [currentSection, setCurrentSection] = useState<string | undefined>();
  const [currentStates, setCurrentStates] = useState<StateDto>();
  const [action, setAction] = useState<{ action: string; data: any }>();
  const [pageSelected, setPageSelected] = useState<number>();
  const [appliedFilters, setAppliedFilters] = useState<Filter>({});
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [items, setItems] = useState<ListItems[]>([]);
  const [currentListItems, setCurrentListItem] = useState<ListItems[]>([]);
  const [queryMode, setQueryMode] = useState<string | null>(null);
  const [query, setQuery] = useState<string | null>(null);

  const navigate = useNavigate();

  const [filteredItems, setFilteredItems] = useState<ListItems[]>([]);

  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload: { [key: string]: PaginationDto } = {
      videos: {
        limit: 3,
        offset: 3 * page,
        filterBy: {
          ...(filterBy ? filterBy : {}),
          url: { $exists: true },
          savedBy: user._id,
          active: true,
          available: true,
          published: true,
        },
      },
      slideshows: {
        limit: 3,
        offset: 3 * page,
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          savedBy: user._id,
          available: true,
          published: true,
        },
      },

      images: {
        limit: 3,
        offset: 3 * page,
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          savedBy: user._id,
          available: true,
          published: true,
        },
      },
      listens: {
        limit: 3,
        offset: 3 * page,
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          savedBy: user._id,
          available: true,
          published: true,
        },
      },
      roadmaps: {
        limit: 3,
        offset: 3 * page,
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          savedBy: user._id,
          available: true,
          published: true,
        },
      },
      contents: {
        limit: 3,
        offset: 3 * page,
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          savedBy: user._id,
          available: true,
          published: true,
        },
      },
      cdocs: {
        limit: 3,
        offset: 3 * page,
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          savedBy: user._id,
          available: true,
          published: true,
        },
      },
      bytes: {
        limit: 3,
        offset: 3 * page,
        filterBy: {
          ...(filterBy ? filterBy : {}),
          url: { $exists: true },
          savedBy: user._id,
          active: true,
          available: true,
          published: true,
        },
      },
    };
    const sortBy = page % 2 === 0 ? "createdAt" : "viewCount";

    const sortDirection = "desc";

    payload = Object.entries(payload).reduce((acc, [key, value]) => {
      acc[key] = {
        ...value,
        sortBy,
        sortDirection,
        ...(queryMode && { keyword: query }),
      };
      return acc;
    }, {});
    dispatch(getAllVideos(payload.videos));
    dispatch(getAllSlideShows(payload.slideshows));
    dispatch(getAllImages(payload.images));
    dispatch(getAllRoadmaps(payload.roadmaps));
    dispatch(contentActions.getAll(payload.contents));
    dispatch(getAllListens(payload.listens));
    dispatch(getAllCDOCs(payload.cdocs));
    dispatch(getAllBytes(payload.bytes));
  };

  const handleQuery = (ev) => {
    const value = ev.target.value;
    setQuery(value.length === 0 ? "" : value);
  };
  const searchQuery = (ev) => {
    if (ev.keyCode === 13) {
      /*  dispatch(queryImage({ keyword: query })); */
      setQueryMode(query);
    }
  };

  useEffect(() => {
    if (queryMode) {
      setPage(0);
      setIsLoading(false);
      setThereAreItems(true);
      setItems([]);
    }
  }, [queryMode]);

  useEffect(() => {
    if (items && items.length > 0) {
      const filteredPages = [...items];
      /* for (const [index, filteredPage] of Object.entries(filteredPages)) {
        for (const [key, elements] of Object.entries(filteredPage)) {
          console.log(elements, filteredPage, "cwaws");
          filteredPages[index] = {
            ...filteredPages[index],
            [key]: elements.filter((element) =>
              element.savedBy.includes(user._id)
            ),
          };
        }
      } */
      setCurrentListItem(filteredPages);
    }
  }, [items]);

  useEffect(() => {
    if (currentListItems && currentListItems.length > 0) {
      const filteredList = () => {
        // If type is 'all' or name is 'all', return the original items array

        if (
          (!appliedFilters.type || appliedFilters.type === "all") &&
          (!appliedFilters.name || appliedFilters.name === "all")
        ) {
          return [...currentListItems];
        }

        // Create an empty array to store the filtered pages
        let filteredPages: ListItems[] = [...currentListItems];

        if (appliedFilters.type && appliedFilters.type !== "all") {
          filteredPages = [];
          let filteredPageType:
            | VideoDto[]
            | SlideShowDto[]
            | ImageDto[]
            | CDocDto[]
            | ListenDto[]
            | ContentDto[]
            | RoadmapDto[]
            | ByteDto[] = [];

          for (const item of currentListItems) {
            // Check if the key exists in the item
            if (appliedFilters.type in item) {
              // Add all items of the specified key to the filteredPages array
              if (item[appliedFilters.type]) {
                filteredPageType.push(...item[appliedFilters.type]);
              }
            }
          }

          const elements = { [appliedFilters.type]: filteredPageType };
          filteredPages = [elements];
        }

        if (appliedFilters.name && appliedFilters.name !== "all") {
          // Iterate over each object in the items array
          for (const [index, filteredPage] of Object.entries(filteredPages)) {
            for (const [key, elements] of Object.entries(filteredPage)) {
              filteredPages[index] = {
                ...filteredPages[index],
                [key]: elements.filter((element) =>
                  element.name
                    .toLowerCase()
                    .includes(appliedFilters.name.toLowerCase())
                ),
              };
            }
          }
        }

        return filteredPages;
      };

      setFilteredItems(filteredList());
    }
  }, [appliedFilters, currentListItems]);
  useEffect(() => {
    if (!isLoading) {
      setItems([
        ...items,
        {
          ...(roadmaps && roadmaps.length > 0 ? { roadmaps } : {}),
          ...(contents && contents.length > 0 ? { contents } : {}),
          ...(cdocs && cdocs.length > 0 ? { cdocs } : {}),
          ...(listens && listens.length > 0 ? { listens } : {}),
          ...(videos && videos.length > 0
            ? {
                videos,
              }
            : {}),
          ...(bytes && bytes.length > 0
            ? {
                bytes,
              }
            : {}),
          ...(images && images.length > 0 ? { images } : {}),
          ...(slideshows && slideshows.length > 0
            ? {
                slideshows,
              }
            : {}),
        },
      ]);

      setThereAreItems(
        (!!roadmaps && roadmaps?.length > 0) ||
          (!!contents && contents?.length > 0) ||
          (!!listens && listens?.length > 0) ||
          (!!videos && videos?.length > 0) ||
          (!!images && images?.length > 0) ||
          (!!slideshows && slideshows?.length > 0) ||
          (!!cdocs && cdocs?.length > 0) ||
          (!!bytes && bytes.length > 0)
      );
    }
  }, [
    roadmaps,
    contents,
    listens,
    videos,
    images,
    slideshows,
    cdocs,
    bytes,
    isLoading,
  ]);

  useEffect(() => {
    setIsLoading(
      videosStates.loading ||
        slideshowsStates.loading ||
        imagesStates.loading ||
        contentsStates.loading ||
        listensStates.loading ||
        roadmapsStates.loading ||
        cdocsStates.loading ||
        bytesStates.loading
    );
  }, [
    videosStates,
    cdocsStates,
    slideshowsStates,
    imagesStates,
    contentsStates,
    listensStates,
    roadmapsStates,
    bytesStates,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllVideos());
      dispatch(resetGetAllCdocs());
      dispatch(contentActions.resetGetAllContents());
      dispatch(resetGetAllRoadmaps());
      dispatch(resetGetAllImages());
      dispatch(resetGetAllListens());
      dispatch(resetGetAllBytes());
    };
  }, []);

  useEffect(() => {
    if (currentSection === "cdoc") {
      setCurrentStates(updateCdocStates);
    }
    if (currentSection === "video") {
      setCurrentStates(updateVideoStates);
    }
    if (currentSection === "variety") {
      setCurrentStates(updateContentStates);
    }
    if (currentSection === "roadmap") {
      setCurrentStates(updateRoadmapStates);
    }
    if (currentSection === "slideshow") {
      setCurrentStates(updateSlideShowStates);
    }
    if (currentSection === "byte") {
      setCurrentSection(updateByteStates);
    }
    if (currentSection === "listen") {
      setCurrentStates(updateListenStates);
    }
    if (currentSection === "image") {
      setCurrentStates(updateImageStates);
    }
  }, [
    currentSection,
    updateContentStates,
    updateVideoStates,
    updateCdocStates,
    updateByteStates,
    updateRoadmapStates,
    updateListenStates,
    updateImageStates,
    updateSlideShowStates,
  ]);

  useEffect(() => {
    if (!!getListen && updateListenStates.success) {
      setItems((state) => {
        return state.map((s, i) => {
          return {
            ...s,
            ...(!!s.listens && {
              listens: s.listens?.map((list) =>
                list._id === getListen._id ? getListen : list
              ),
            }),
          };
        });
      });
    }
  }, [getListen, updateListenStates]);
  useEffect(() => {
    if (!!getVideo && updateVideoStates.success) {
      setItems((state) => {
        return state.map((s, i) => {
          return {
            ...s,
            ...(!getVideo.short &&
              !!s.videos && {
                videos: s.videos?.map((video) =>
                  video._id === getVideo._id ? getVideo : video
                ),
              }),
            ...(!!getVideo.short &&
              !!s.bytes && {
                bytes: s.bytes?.map((video) =>
                  video._id === getVideo._id ? getVideo : video
                ),
              }),
          };
        });
      });
    }
  }, [getVideo, updateVideoStates]);
  useEffect(() => {
    if (!!getByte && updateByteStates.success) {
      setItems((state) => {
        return state.map((s, i) => {
          return {
            ...s,
            ...(!!s.bytes && {
              bytes: s.bytes?.map((byte) =>
                byte._id === getByte._id ? getByte : byte
              ),
            }),
          };
        });
      });
    }
  }, [getByte, updateByteStates]);
  useEffect(() => {
    if (!!getContent && updateContentStates.success) {
      setItems((state) => {
        return state.map((s, i) => {
          return {
            ...s,
            ...(!!s.contents && {
              contents: s.contents?.map((content) =>
                content._id === getContent._id ? getContent : content
              ),
            }),
          };
        });
      });
    }
  }, [getContent, updateContentStates]);
  useEffect(() => {
    if (!!getRoadmap && updateRoadmapStates.success) {
      setItems((state) => {
        return state.map((s, i) => {
          return {
            ...s,
            ...(!!s.roadmaps && {
              roadmaps: s.roadmaps?.map((roadmap) =>
                roadmap._id === getRoadmap._id ? getRoadmap : roadmap
              ),
            }),
          };
        });
      });
    }
  }, [getRoadmap, updateRoadmapStates]);
  useEffect(() => {
    if (!!getImage && updateImageStates.success) {
      setItems((state) => {
        return state.map((s, i) => {
          return {
            ...s,
            ...(!!s.images && {
              images: s.images?.map((list) =>
                list._id === getImage._id ? getImage : list
              ),
            }),
          };
        });
      });
    }
  }, [getImage, updateImageStates]);
  useEffect(() => {
    if (!!getCdoc && updateCdocStates.success) {
      setItems((state) => {
        return state.map((s, i) => {
          return {
            ...s,
            ...(!!s.cdocs && {
              cdocs: s.cdocs?.map((cdoc) =>
                cdoc._id === getCdoc._id ? getCdoc : cdoc
              ),
            }),
          };
        });
      });
    }
  }, [getCdoc, updateCdocStates]);
  useEffect(() => {
    if (!!getSlideShow && updateSlideShowStates.success) {
      setItems((state) => {
        return state.map((s, i) => {
          return {
            ...s,
            ...(!!s.slideshows && {
              slideshows: s.slideshows?.map((list) =>
                list._id === getSlideShow._id ? getSlideShow : list
              ),
            }),
          };
        });
      });
    }
  }, [getSlideShow, updateSlideShowStates]);

  return (
    <>
      <div style={{ maxWidth: "600px", margin: "0 auto", width: "100%" }}>
        <FilterByName
          searchQuery={searchQuery}
          setFilter={handleQuery}
          value={query}
        />
      </div>
      <div style={{ height: "24px" }}></div>
      {/*     <Center>
        <FlexContainer wrap="wrap" align="center" gap="10px"> */}
      <Filters setFilter={setAppliedFilters} appliedFilters={appliedFilters} />
      {/*       </FlexContainer>
      </Center> */}
      <div style={{ height: "24px" }}></div>
      <InfinityScroll
        action={fetchData}
        page={page}
        setPage={setPage}
        data={filteredItems}
        active={thereAreItems}
        isLoading={isLoading}
        loader={<Skeleton></Skeleton>}
      >
        {filteredItems.map((content, key) => (
          <>
            {!!content.contents && content.contents?.length > 0 && (
              <>
                <h2>Contenidos variados</h2>
                <Separator size={10}></Separator>
                <GridStructure
                  width={"275px"}
                  maxWidth="auto"
                  style={{ width: "100%", margin: "0 auto" }}
                  limitCard={"275px"}
                >
                  {content.contents?.map((content) => {
                    return (
                      <ContentCard
                        IconAbsolute={
                          <TrashIcon>
                            <BsFillTrashFill
                              color="red"
                              fontSize={16}
                            ></BsFillTrashFill>
                          </TrashIcon>
                        }
                        iconAbsoluteOnClick={(item) => {
                          setCurrentSection("variety");
                          setPageSelected(key);
                          setAction({
                            action: "unsave-content",
                            data: {
                              name: content.name,
                              externalFunction: true,
                              action: () =>
                                contentActions.saved({
                                  _id: content._id,
                                  __v: content.__v,
                                  condition: "unsave",
                                  individual: true,
                                }),
                              resetAction: () => contentActions.resetUpdate(),
                            },
                          });
                          dispatch(
                            showModal("confirm-delete-item-secuencial-modal")
                          );
                        }}
                        cardOnClick={(item) => {
                          dispatch(
                            addClick({
                              id: content._id,
                              user: user._id,
                              type: "CONTENT",
                            })
                          );
                          window.open(content.link, "_blank");
                        }}
                        item={content}
                      >
                        <SlideLink
                          href={content.link}
                          target="_blank"
                          onClick={(ev) => {
                            ev.stopPropagation();
                          }}
                        >
                          <FlexContainer gap="12px">
                            <ContentIcon
                              style={{ cursor: "pointer" }}
                              onClick={(ev) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                                navigate(
                                  `/dashboard/academy/${content.academy._id}`
                                );
                              }}
                            >
                              <Img src={content.picture}></Img>
                            </ContentIcon>
                            <FlexContainer direction="column" gap="5px">
                              <ContentCardTitle style={{ fontSize: "14px" }}>
                                {!!content.name && content.name}
                              </ContentCardTitle>

                              <ContentDataText>
                                {content.academy.name}
                              </ContentDataText>

                              <FlexContainer align="center" gap="5px">
                                <ContentDataText>{`Vistas: ${content.viewCount}`}</ContentDataText>

                                <span>|</span>

                                <ContentDataText>
                                  {DateFromNow(content.createdAt)}
                                </ContentDataText>
                              </FlexContainer>
                            </FlexContainer>
                          </FlexContainer>
                        </SlideLink>
                      </ContentCard>
                    );
                  })}
                </GridStructure>
                <Separator size={10}></Separator>
              </>
            )}
            {!!content.videos && content.videos?.length > 0 && (
              <>
                <h2>Videos</h2>
                <Separator size={10}></Separator>
                <GridStructure width="300px" maxWidth="900px">
                  {content.videos?.map((video) => {
                    return (
                      <ContentCard
                        ContentTypeLogo={{ image: VideoTraced }}
                        IconAbsolute={
                          <TrashIcon>
                            <BsFillTrashFill
                              color="red"
                              fontSize={16}
                            ></BsFillTrashFill>
                          </TrashIcon>
                        }
                        iconAbsoluteOnClick={(item) => {
                          setCurrentSection("video");
                          setPageSelected(key);
                          setAction({
                            action: "unsave-content",
                            data: {
                              name: item.name,
                              externalFunction: true,
                              action: () =>
                                saveVideo({
                                  _id: item._id,
                                  __v: item.__v,
                                  condition: "unsave",
                                  individual: true,
                                }),
                              resetAction: () => resetUpdateVideo(),
                            },
                          });
                          dispatch(
                            showModal("confirm-delete-item-secuencial-modal")
                          );
                        }}
                        key={video._id}
                        item={video}
                        logoOnclick={(item) => {
                          dispatch(
                            addClick({
                              id: item.academy?._id,
                              user: user._id,
                              type: "ACADEMY",
                            })
                          );
                          navigate(`/dashboard/academy/${item.academy?._id}`);
                        }}
                        cardOnClick={(item) => {
                          dispatch(
                            addClick({
                              id: item._id,
                              user: user._id,
                              type: "CONTENT",
                            })
                          );
                          navigate(`/dashboard/bytes/${item._id}`);
                        }}
                      ></ContentCard>
                    );
                  })}
                </GridStructure>
                <Separator size={10}></Separator>
              </>
            )}
            {!!content.cdocs && content.cdocs?.length > 0 && (
              <>
                <h2>Cdocs</h2>
                <Separator size={10}></Separator>
                <GridStructure width="300px" maxWidth="900px">
                  {content.cdocs?.map((cdoc) => {
                    return (
                      <ContentCard
                        key={cdoc._id}
                        ContentTypeLogo={{ icon: HiDocumentText }}
                        IconAbsolute={
                          <TrashIcon>
                            <BsFillTrashFill
                              color="red"
                              fontSize={16}
                            ></BsFillTrashFill>
                          </TrashIcon>
                        }
                        iconAbsoluteOnClick={(item) => {
                          setCurrentSection("cdoc");
                          setPageSelected(key);
                          setAction({
                            action: "unsave-content",
                            data: {
                              name: item.name,
                              externalFunction: true,
                              action: () =>
                                savedCdoc({
                                  _id: item._id,
                                  __v: item.__v,
                                  condition: "unsave",
                                  individual: true,
                                }),
                              resetAction: () => resetUpdateCDOCs(),
                            },
                          });
                          dispatch(
                            showModal("confirm-delete-item-secuencial-modal")
                          );
                        }}
                        item={cdoc}
                        logoOnclick={(item) => {
                          dispatch(
                            addClick({
                              id: item.academy?._id,
                              user: user._id,
                              type: "ACADEMY",
                            })
                          );
                          navigate(`/dashboard/academy/${item.academy?._id}`);
                        }}
                        cardOnClick={(item) => {
                          dispatch(
                            addClick({
                              id: item._id,
                              user: user._id,
                              type: "CONTENT",
                            })
                          );
                          navigate(`/dashboard/bytes/${item._id}`);
                        }}
                      ></ContentCard>
                    );
                  })}
                </GridStructure>
                <Separator size={10}></Separator>
              </>
            )}
            {!!content.roadmaps && content.roadmaps?.length > 0 && (
              <>
                <h2>Roadmaps</h2>
                <Separator size={10}></Separator>
                <GridStructure width="300px" maxWidth="900px">
                  {content.roadmaps?.map((roadmap) => {
                    return (
                      <ContentCard
                        key={roadmap._id}
                        ContentTypeLogo={{ icon: RiOrganizationChart }}
                        item={roadmap}
                        IconAbsolute={
                          <TrashIcon>
                            <BsFillTrashFill
                              color="red"
                              fontSize={16}
                            ></BsFillTrashFill>
                          </TrashIcon>
                        }
                        iconAbsoluteOnClick={(item) => {
                          setCurrentSection("roadmap");
                          setPageSelected(key);
                          setAction({
                            action: "unsave-content",
                            data: {
                              name: item.name,
                              externalFunction: true,
                              action: () =>
                                savedRoadmap({
                                  _id: item._id,
                                  __v: item.__v,
                                  condition: "unsave",
                                  individual: true,
                                }),
                              resetAction: () => resetUpdateRoadmap(),
                            },
                          });
                          dispatch(
                            showModal("confirm-delete-item-secuencial-modal")
                          );
                        }}
                        logoOnclick={(item) => {
                          dispatch(
                            addClick({
                              id: item.academy?._id,
                              user: user._id,
                              type: "ACADEMY",
                            })
                          );
                          navigate(`/dashboard/academy/${item.academy?._id}`);
                        }}
                        cardOnClick={(item) => {
                          dispatch(
                            addClick({
                              id: item._id,
                              user: user._id,
                              type: "CONTENT",
                            })
                          );
                          navigate(`/dashboard/bytes/${item._id}`);
                        }}
                      ></ContentCard>
                    );
                  })}
                </GridStructure>
                <Separator size={10}></Separator>
              </>
            )}
            {!!content.images && content.images?.length > 0 && (
              <>
                <h2>Images</h2>
                <Separator size={10}></Separator>
                <GridStructure width="300px" maxWidth="900px">
                  {content.images?.map((image) => {
                    return (
                      <ContentCard
                        ContentTypeLogo={{ icon: BsFillImageFill }}
                        IconAbsolute={
                          <TrashIcon>
                            <BsFillTrashFill
                              color="red"
                              fontSize={16}
                            ></BsFillTrashFill>
                          </TrashIcon>
                        }
                        iconAbsoluteOnClick={(item) => {
                          setCurrentSection("image");
                          setPageSelected(key);
                          setAction({
                            action: "unsave-content",
                            data: {
                              name: item.name,
                              externalFunction: true,
                              action: () =>
                                saveImage({
                                  _id: item._id,
                                  __v: item.__v,
                                  condition: "unsave",
                                  individual: true,
                                }),
                              resetAction: () => resetUpdateImage(),
                            },
                          });
                          dispatch(
                            showModal("confirm-delete-item-secuencial-modal")
                          );
                        }}
                        key={image._id}
                        item={image}
                        logoOnclick={(item) => {
                          dispatch(
                            addClick({
                              id: item.academy?._id,
                              user: user._id,
                              type: "ACADEMY",
                            })
                          );
                          navigate(`/dashboard/academy/${item.academy?._id}`);
                        }}
                        cardOnClick={(item) => {
                          dispatch(
                            addClick({
                              id: item._id,
                              user: user._id,
                              type: "CONTENT",
                            })
                          );
                          navigate(`/dashboard/bytes/${item._id}`);
                        }}
                      ></ContentCard>
                    );
                  })}
                </GridStructure>
                <Separator size={10}></Separator>
              </>
            )}
            {!!content.listens && content.listens?.length > 0 && (
              <>
                <h2>Listens</h2>
                <Separator size={10}></Separator>
                <GridStructure width="300px" maxWidth="900px">
                  {content.listens?.map((listen) => {
                    return (
                      <ContentCard
                        ContentTypeLogo={{ icon: MdAudiotrack }}
                        IconAbsolute={
                          <TrashIcon>
                            <BsFillTrashFill
                              color="red"
                              fontSize={16}
                            ></BsFillTrashFill>
                          </TrashIcon>
                        }
                        iconAbsoluteOnClick={(item) => {
                          setCurrentSection("listen");
                          setPageSelected(key);
                          setAction({
                            action: "unsave-content",
                            data: {
                              name: item.name,
                              externalFunction: true,
                              action: () =>
                                savedListen({
                                  _id: item._id,
                                  __v: item.__v,
                                  condition: "unsave",
                                  individual: true,
                                }),
                              resetAction: () => resetUpdateListen(),
                            },
                          });
                          dispatch(
                            showModal("confirm-delete-item-secuencial-modal")
                          );
                        }}
                        key={listen._id}
                        item={listen}
                        logoOnclick={(item) => {
                          dispatch(
                            addClick({
                              id: item.academy?._id,
                              user: user._id,
                              type: "ACADEMY",
                            })
                          );
                          navigate(`/dashboard/academy/${item.academy?._id}`);
                        }}
                        cardOnClick={(item) => {
                          dispatch(
                            addClick({
                              id: item._id,
                              user: user._id,
                              type: "CONTENT",
                            })
                          );
                          navigate(`/dashboard/bytes/${item._id}`);
                        }}
                      ></ContentCard>
                    );
                  })}
                </GridStructure>
                <Separator size={10}></Separator>
              </>
            )}
            {!!content.slideshows && content.slideshows.length > 0 && (
              <>
                <h2>SlideShows</h2>
                <Separator size={10}></Separator>
                <GridStructure width="300px" maxWidth="900px">
                  {content.slideshows?.map((slideshow) => {
                    return (
                      <ContentCard
                        ContentTypeLogo={{ icon: RiSlideshow3Fill }}
                        IconAbsolute={
                          <TrashIcon>
                            <BsFillTrashFill
                              color="red"
                              fontSize={16}
                            ></BsFillTrashFill>
                          </TrashIcon>
                        }
                        iconAbsoluteOnClick={(item) => {
                          setCurrentSection("slideshow");
                          setPageSelected(key);
                          setAction({
                            action: "unsave-content",
                            data: {
                              name: item.name,
                              externalFunction: true,
                              action: () =>
                                savedSlideshow({
                                  _id: item._id,
                                  __v: item.__v,
                                  condition: "unsave",
                                  individual: true,
                                }),
                              resetAction: () => resetUpdateSlideShow(),
                            },
                          });
                          dispatch(
                            showModal("confirm-delete-item-secuencial-modal")
                          );
                        }}
                        key={slideshow._id}
                        item={slideshow}
                        logoOnclick={(item) => {
                          dispatch(
                            addClick({
                              id: item.academy?._id,
                              user: user._id,
                              type: "ACADEMY",
                            })
                          );
                          navigate(`/dashboard/academy/${item.academy?._id}`);
                        }}
                        cardOnClick={(item) => {
                          dispatch(
                            addClick({
                              id: item._id,
                              user: user._id,
                              type: "CONTENT",
                            })
                          );
                          navigate(`/dashboard/bytes/${item._id}`);
                        }}
                      ></ContentCard>
                    );
                  })}
                </GridStructure>
                <Separator size={10}></Separator>
              </>
            )}
            {!!content.bytes && content.bytes.length > 0 && (
              <>
                <h2>Bytes</h2>
                <Separator size={10}></Separator>
                <GridStructure width="300px" maxWidth="900px">
                  {content.bytes?.map((byte) => {
                    return (
                      <ContentCard
                        ContentTypeLogo={{ image: ByteTraced }}
                        IconAbsolute={
                          <TrashIcon>
                            <BsFillTrashFill
                              color="red"
                              fontSize={16}
                            ></BsFillTrashFill>
                          </TrashIcon>
                        }
                        iconAbsoluteOnClick={(item) => {
                          setCurrentSection("byte");
                          setPageSelected(key);
                          setAction({
                            action: "unsave-content",
                            data: {
                              name: item.name,
                              externalFunction: true,
                              action: () =>
                                savedByte({
                                  _id: item._id,
                                  __v: item.__v,
                                  condition: "unsave",
                                  individual: true,
                                }),
                              resetAction: () => resetUpdateByte(),
                            },
                          });
                          dispatch(
                            showModal("confirm-delete-item-secuencial-modal")
                          );
                        }}
                        key={byte._id}
                        item={byte}
                        logoOnclick={(item) => {
                          dispatch(
                            addClick({
                              id: item.academy?._id,
                              user: user._id,
                              type: "ACADEMY",
                            })
                          );
                          navigate(`/dashboard/academy/${item.academy?._id}`);
                        }}
                        cardOnClick={(item) => {
                          dispatch(
                            addClick({
                              id: item._id,
                              user: user._id,
                              type: "CONTENT",
                            })
                          );
                          navigate(`/dashboard/bytes/${item._id}`);
                        }}
                      ></ContentCard>
                    );
                  })}
                </GridStructure>
              </>
            )}
          </>
        ))}
      </InfinityScroll>

      {action?.action === "unsave-content" && currentStates && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Quitar",
            style: {
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={currentStates}
          title={
            <span>
              {`¿Seguro que quiere quitar el contenido `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {action.data.name}
              </span>
              ?
            </span>
          }
          externalFunction={action.data.externalFunction}
          elementActions={action.data.action}
          resetAction={action.data.resetAction}
          resetState={setAction}
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({
  userStore,
  contentStore,
  videoStore,
  cdocStore,
  roadmapStore,
  slideshowStore,
  imageStore,
  listenStore,
  byteStore,
}) => {
  const { data: user } = userStore;
  const { data: contents, states: contentsStates } = contentStore.all;
  const { data: getContent } = contentStore.content;
  const { data: videos, states: videosStates } = videoStore.all;
  const { data: getVideo } = videoStore.video;
  const { data: cdocs, states: cdocsStates } = cdocStore.all;
  const { data: getCdoc } = cdocStore.selected;
  const { data: roadmaps, states: roadmapsStates } = roadmapStore.all;
  const { data: getRoadmap } = roadmapStore.roadmap;
  const { data: slideshows, states: slideshowsStates } = slideshowStore.all;
  const { states: updateSlideShowStates } = slideshowStore.update;
  const { data: getSlideShow } = slideshowStore.slideshow;
  const { data: images, states: imagesStates } = imageStore.all;
  const { data: getImage } = imageStore.image;
  const { states: updateImageStates } = imageStore.update;
  const { data: listens, states: listensStates } = listenStore.all;
  const { data: getListen } = listenStore.listen;
  const { data: bytes, states: bytesStates } = byteStore.all;
  const { data: getByte } = byteStore.byte;
  const { states: updateListenStates } = listenStore.update;
  const { states: updateCdocStates } = cdocStore.update;
  const { states: updateVideoStates } = videoStore.update;
  const { states: updateContentStates } = contentStore.update;
  const { states: updateRoadmapStates } = roadmapStore.update;
  const { states: updateByteStates } = byteStore.update;
  return {
    user,
    contents,
    updateListenStates,
    updateImageStates,
    updateSlideShowStates,
    videos,
    cdocs,
    updateCdocStates,
    updateVideoStates,
    updateContentStates,
    roadmaps,
    updateRoadmapStates,
    contentsStates,
    videosStates,
    cdocsStates,
    roadmapsStates,
    slideshows,
    slideshowsStates,
    images,
    imagesStates,
    listens,
    listensStates,
    getContent,
    getVideo,
    getCdoc,
    getRoadmap,
    getSlideShow,
    getImage,
    getListen,
    bytes,
    bytesStates,
    updateByteStates,
    getByte,
  };
};

export default connect(states)(Component);
