import styled from "styled-components";

export const Prev = styled("button")`
  display: block;
  width: 30px;
  height: 30px;
  border-top: 3px solid #2496ed;
  border-left: 3px solid #2496ed;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  z-index: 200;
  left: -15px;
  transform: rotate(-45deg);

  &:before,
  &:hover:before,
  &:focus:before,
  &:active:before {
    opacity: 0;
  }
`;

export const Next = styled("button")`
  display: block;
  width: 30px;
  height: 30px;
  border-top: 3px solid #2496ed;
  border-left: 3px solid #2496ed;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  z-index: 200;
  right: -15px;
  transform: rotate(135deg);

  &:before,
  &:hover:before,
  &:focus:before,
  &:active:before {
    opacity: 0;
  }
`;

export const SliderButton = styled("div")`
  width: 34px;
  height: 34px;
  background-color: rgba(36, 150, 237, 0.25);
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: ${(props) => props.theme.colors.LightBlue};
  }
  &:hover svg {
    color: ${(props) => props.theme.colors.White} !important;
  }
`;
