import { Center } from "../StyledComponents";
import { Title } from "./style";

const Component = ({
  title,
  mg,
  style,
  ...props
}: {
  title: string;
  mg?: number;
  size?: number;
  style?;
  props?;
}) => {
  return (
    <Center style={{ margin: `${mg}px`, ...(style ? style : {}) }}>
      <Title {...props}>{title}</Title>
    </Center>
  );
};

export default Component;
