import styled from "styled-components";

export const Icon = styled("i")<{ size; color?; icon }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  display: block;
  transition: background-color 0.3s ease-in-out;
  &:before {
    content: "";
    display: block;
    background-color: ${({ color }) => color};
    mask: url(${({ icon }) => icon});
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center center;
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`;
