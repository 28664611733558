import { Formik } from "formik";
import React from "react";
import Button from "../../../../../components/Button";
import Grid from "../../../../../components/Grid";
import Modal from "../../../../../components/Modal";
import { Center } from "../../../../../components/StyledComponents";
import { schema } from "../../../../../constants/form/ability/new";
const Component = ({ title, meetLink }) => {
  return (
    <Modal name="send-meet" title={title} showClose>
      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => {}}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                handleSubmit(event);
              }}
              id="edit-profile-profile-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Button
                      type="button"
                      onClick={() => {
                        window.open(meetLink.eventId.meetLink, "_blank");
                      }}
                      options={{
                        size: "md",
                        type: "filled",
                        skin: "violet",
                      }}
                    >
                      Entrar
                    </Button>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default Component;
