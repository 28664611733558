import React, { useEffect, useMemo, useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { FlexContainer } from "../StyledComponents";
import { FaStar } from "react-icons/fa";
import { BsStarFill } from "react-icons/bs";

const Rate = ({
  count,
  rating,
  color,
  onRating,
  passiveValue,
  staticRating,
  starSize,
}: {
  count;
  rating;
  color;
  onRating;
  passiveValue;
  staticRating?;
  starSize?: number;
}) => {
  const [hoverRating, setHoverRating] = useState<number>(0);
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  /*  const [firstHover,] */
  const getColor = (index) => {
    if (hoverRating >= index) {
      return color.filled;
    } else if (!hoverRating && rating >= index) {
      return color.filled;
    }

    return color.unfilled;
  };

  useEffect(() => {
    if (!isMouseOver && !!passiveValue) {
      setHoverRating(passiveValue);
    }
  }, [isMouseOver, passiveValue]);

  const starRating = useMemo(() => {
    return Array(count)
      .fill(0)
      .map((_, i) => i + 1)
      .map((idx) => (
        <BsStarFill
          key={idx}
          size={starSize ?? 20}
          onClick={() => onRating(idx)}
          style={{
            stroke: "#2496ed",
            strokeWidth: 0.6,
          }}
          fill={getColor(idx)}
          onMouseEnter={() => {
            setIsMouseOver(true);
            setHoverRating(idx);
          }}
          onMouseLeave={() => {
            setIsMouseOver(false);
            setHoverRating(passiveValue);
          }}
        />
      ));
  }, [count, rating, hoverRating]);

  const staticRatingComponent = useMemo(() => {
    return Array(count)
      .fill(0)
      .map((_, i) => i + 1)
      .map((idx) => (
        <BsStarFill
          key={idx}
          size={starSize ?? 20}
          style={{
            stroke: "#2496ed",
            strokeWidth: 0.6,
          }}
          fill={getColor(idx)}
        />
      ));
  }, [rating]);

  return (
    <FlexContainer gap="3px" align="center">
      {staticRating ? staticRatingComponent : starRating}
    </FlexContainer>
  );
};

export default Rate;
