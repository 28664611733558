export const eventFrecuency = [
  {
    value: "unique",
    label: "No se repite",
  },
  {
    value: "personalized",
    label: "Personalizado",
  },
];
