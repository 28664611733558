import styled from "styled-components";

export const DescriptionModalTitle = styled("h3")`
  font-size: 14px;
  font-weight: bold;
  color: #051725;
`;

export const DescriptionModalSubtitle = styled("h4")`
  font-size: 12px;
  font-weight: normal;
  color: #6a6970;
`;

export const DescriptionModalText = styled("p")`
  font-size: 12px;
  color: #051725;
`;
