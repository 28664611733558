import styled from "styled-components";

export const ContentCardWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
  width: 100%;
  cursor: pointer;
  padding: ${(props) => (props.pd ? props.pd : "3px")};
  word-break: break-word;
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 0 4px 0 rgba(36, 150, 237, 0.25);
  }
`;

export const ContentLogoWrapper = styled("div")`
  width: 34.1px;
  height: 34.1px;
  display: flex;
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: #051725;
`;

export const ContentImageBottomLabel = styled("div")`
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 2px;
  border-radius: 5px;
  font-size: 12px;
  background-color: rgba(5, 23, 37, 0.6);
  color: ${(props) => props.theme.colors.White};
`;

export const ContentIcon = styled("div")`
  display: block;
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(36, 150, 237, 0.25);
  overflow: hidden;
  ${(props) => !!props.margin && `margin:${props.margin};`}
`;

export const ContentDataText = styled("h5")`
  font-size: 12px;
  color: #6a6970;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Ajusta esto al número deseado de líneas */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
`;

export const ContentCardTitle = styled(`h3`)`
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  overflow:hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Ajusta esto al número deseado de líneas */
  -webkit-box-orient: vertical;
text-overflow: ellipsis;
white-space: normal;
  color: ${(props) => props.theme.colors.Night};
 @media (max-width: 768px) {
     font-size: 13px;
  }
}
`;
