const fontName = "Lato";
const fontSizeBase = 14;

export const fonts = {
  family: `'${fontName}', sans-serif`,
  sizes: {
    base: `${fontSizeBase}px`,
    small: `${fontSizeBase - 4}px`,
    medium: `${fontSizeBase}px`,
    large: `${fontSizeBase + 4}px`,
    h1: `${fontSizeBase + 38}px`,
    h2: `${fontSizeBase + 18}px`,
    h3: `${fontSizeBase + 12}px`,
    h4: `${fontSizeBase + 2}px`,
    h5: `${fontSizeBase}px`,
    h6: `${fontSizeBase - 2}px`,
  },
};
