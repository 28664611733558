import { put, call, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  NEW_TALENT,
  NEW_TALENT_ERROR,
  NEW_TALENT_SUCCESS,
  NewTalent,
  GET_ALL_TALENTS_ERROR,
  GET_ALL_TALENTS_SUCCESS,
  GetAllTalents,
  GET_ALL_TALENTS,
  UPDATE_TALENT,
  UPDATE_TALENT_ERROR,
  UPDATE_TALENT_SUCCESS,
  UpdateShownCohort,
  UPDATE_SHOWN_COHORT,
} from "../types/talent";

function* newTalent({ payload }) {
  const { response, error } = yield call(services.talent.new, payload);

  if (error) {
    yield put({
      type: NEW_TALENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: NEW_TALENT_SUCCESS, payload: response });
  }
}

function* shownCohort({ payload }) {
  const { response, error } = yield call(services.talent.shownCohort, payload);
  if (error) {
    yield put({
      type: UPDATE_TALENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_TALENT_SUCCESS, payload: response });
  }
}

function* getAllTalents({ payload }) {
  const { response, error } = yield call(services.talent.getAll, payload);
  if (error) {
    yield put({
      type: GET_ALL_TALENTS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_TALENTS_SUCCESS, payload: response });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewTalent>(NEW_TALENT, newTalent);
  yield takeLatest<GetAllTalents>(GET_ALL_TALENTS, getAllTalents);
  yield takeLatest<UpdateShownCohort>(UPDATE_SHOWN_COHORT, shownCohort);
}
