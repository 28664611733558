const LightTheme = {
  text: "#2a2747",
  bg: "#ffffff",
};

const DarkTheme = {
  text: "#ffffff",
  bg: "#2a2747",
};

const Themes = {
  light: LightTheme,
  dark: DarkTheme,
};

export default Themes;
