import React, { useState } from "react";
import { IconType } from "react-icons/lib";
import { NavLink, useLocation } from "react-router-dom";
import { menuMobileItems } from "../../constants/menu-mobile-items";
import { FlexContainer } from "../StyledComponents";
import { MobileMenuItemLabel, MobileNavbar } from "./style";
import { useTheme } from "styled-components";
import { connect, useDispatch } from "react-redux";
import { showModal } from "../../store/actions/modal";
import { Icon as IconMenu } from "../Icon/styles";

const iconStateComponent = (
  Icon: IconType | string,
  theme: any,
  active?: boolean,
  isImage?: boolean
) => {
  return !isImage ? (
    <Icon size={20} color={active ? theme.colors.LightBlue : "#6a6970"}></Icon>
  ) : (
    <IconMenu
      icon={Icon}
      color={active ? theme.colors.LightBlue : "#6a6970"}
      size={"20px"}
    ></IconMenu>
  );
};

const Component = ({ location, user }) => {
  const [activeDropdown, setActiveDropdown] = useState<string>();
  const dispatch = useDispatch();
  const theme = useTheme();
  return (
    <MobileNavbar>
      {menuMobileItems.map((item) => {
        const isActiveDropdown =
          item.link === activeDropdown ||
          location.pathname.includes(`/dashboard${item.link}`);
        return item.link === "/home" ? (
          <NavLink
            end
            to={`/dashboard${item.link}`}
            onClick={() => {
              setActiveDropdown(item.link);
            }}
            style={{ textDecoration: "none" }}
          >
            <FlexContainer direction="column" gap="2px" align="center">
              {iconStateComponent(
                item.icon,
                theme,
                isActiveDropdown,
                item.isImage
              )}
              <MobileMenuItemLabel active={isActiveDropdown}>
                {item.title}
              </MobileMenuItemLabel>
            </FlexContainer>
          </NavLink>
        ) : user ? (
          <NavLink
            end
            to={`/dashboard${item.link}`}
            onClick={() => {
              setActiveDropdown(item.link);
            }}
            style={{ textDecoration: "none" }}
          >
            <FlexContainer direction="column" gap="2px" align="center">
              {iconStateComponent(
                item.icon,
                theme,
                isActiveDropdown,
                item.isImage
              )}
              <MobileMenuItemLabel active={isActiveDropdown}>
                {item.title}
              </MobileMenuItemLabel>
            </FlexContainer>
          </NavLink>
        ) : (
          <div
            onClick={() => {
              dispatch(showModal("sign-in-content"));
            }}
          >
            <FlexContainer direction="column" gap="2px" align="center">
              {iconStateComponent(
                item.icon,
                theme,
                isActiveDropdown,
                item.isImage
              )}
              <MobileMenuItemLabel active={isActiveDropdown}>
                {item.title}
              </MobileMenuItemLabel>
            </FlexContainer>
          </div>
        );
      })}
    </MobileNavbar>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;
  return {
    user,
  };
};

export default connect(states)(Component);
