import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  NewInvitation,
  NEW_INVITATION,
  NEW_INVITATION_ERROR,
  NEW_INVITATION_SUCCESS,
  DELETE_INVITATION_ERROR,
  DELETE_INVITATION_SUCCESS,
  GetAllInvitation,
  GET_ALL_INVITATION,
  DELETE_INVITATION,
  DeleteInvitation,
  GET_ALL_INVITATION_ERROR,
  GET_ALL_INVITATION_SUCCESS,
  UpdateInvitation,
  UPDATE_INVITATION,
  UPDATE_INVITATION_SUCCESS,
  UPDATE_INVITATION_ERROR,
  GET_INVITATION_ERROR,
  GET_INVITATION_SUCCESS,
  GetInvitation,
  GET_INVITATION,
  GET_INVITATIONS_USER_BY_ENTITY,
} from "../types/invitation";

function* getAllInvitations({ payload }) {
  const { response, error } = yield call(services.invitation.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_INVITATION_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_INVITATION_SUCCESS, payload: response });
  }
}
function* getAllInvitationsByUserEntity({ payload }) {
  const { response, error } = yield call(
    services.invitation.getAllByUserEntity,
    payload
  );

  if (error) {
    yield put({
      type: GET_ALL_INVITATION_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_INVITATION_SUCCESS, payload: response });
  }
}

function* newInvitation({ payload }) {
  const { response, error } = yield call(services.invitation.new, payload);

  if (error) {
    yield put({
      type: NEW_INVITATION_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_INVITATION_SUCCESS, payload: response })]);
  }
}
function* updateInvitation({ payload }) {
  const { response, error } = yield call(services.invitation.update, payload);

  if (error) {
    yield put({
      type: UPDATE_INVITATION_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_INVITATION_SUCCESS, payload: response })]);
  }
}

function* deleteInvitation({ payload }) {
  const { response, error } = yield call(services.invitation.delete, payload);

  if (error) {
    yield put({
      type: DELETE_INVITATION_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_INVITATION_SUCCESS, payload: response })]);
  }
}

function* getInvitation({ payload }) {
  const { response, error } = yield call(services.invitation.getOne, payload);

  if (error) {
    yield put({
      type: GET_INVITATION_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: GET_INVITATION_SUCCESS, payload: response })]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllInvitation>(GET_ALL_INVITATION, getAllInvitations);
  yield takeLatest<GetAllInvitation>(
    GET_INVITATIONS_USER_BY_ENTITY,
    getAllInvitationsByUserEntity
  );
  yield takeLatest<DeleteInvitation>(DELETE_INVITATION, deleteInvitation);
  yield takeLatest<NewInvitation>(NEW_INVITATION, newInvitation);
  yield takeLatest<UpdateInvitation>(UPDATE_INVITATION, updateInvitation);
  yield takeLatest<GetInvitation>(GET_INVITATION, getInvitation);
}
