import { ListenDto } from "../../types/listen.dto";

export const GET_ALL_LISTENS = "GET_ALL_LISTEN";
export const GET_ALL_LISTENS_ERROR = "GET_ALL_LISTEN_ERROR";
export const GET_ALL_LISTENS_SUCCESS = "GET_ALL_LISTEN_SUCCESS";
export const GET_LISTEN = "GET_Listen";
export const GET_LISTEN_ERROR = "GET_LISTEN_ERROR";
export const SET_GET_LISTEN = "SET_GET_LISTEN";
export const GET_LISTEN_SUCCESS = "GET_LISTEN_SUCCESS";
export const RESET_GET_LISTEN = "RESET_GET_LISTEN";
export const NEW_LISTEN = "NEW_Listen";
export const NEW_LISTEN_ERROR = "NEW_LISTEN_ERROR";
export const NEW_LISTEN_SUCCESS = "NEW_LISTEN_SUCCESS";
export const RESET_NEW_LISTEN = "RESET_NEW_Listen";
export const UPDATE_LISTEN = "UPDATE_Listen";
export const UPDATE_LISTEN_ERROR = "UPDATE_LISTEN_ERROR";
export const UPDATE_LISTEN_SUCCESS = "UPDATE_LISTEN_SUCCESS";
export const RESET_UPDATE_LISTEN = "RESET_UPDATE_Listen";
export const DELETE_LISTEN = "DELETE_Listen";
export const DELETE_LISTEN_ERROR = "DELETE_LISTEN_ERROR";
export const DELETE_LISTEN_SUCCESS = "DELETE_LISTEN_SUCCESS";
export const RESET_DELETE_LISTEN = "RESET_DELETE_Listen";
export const UPLOAD_MEDIA = "UPLOAD_MEDIA";
export const UPDATE_LISTEN_LOADING = "UPDATE_LISTEN_LOADING";
export const ADD_USER_TO_LISTEN = "ADD_USER_TO_LISTEN";
export const SHARED_LISTEN = "SHARED_LISTEN";
export const QUERY_LISTEN = "QUERY_LISTEN";
export const QUERY_LISTEN_SUCCESS = "QUERY_LISTEN_SUCCESS";
export const QUERY_LISTEN_ERROR = "QUERY_LISTEN_ERROR";
export const RESET_QUERY_LISTEN = "RESET_QUERY_LISTEN";
export const SAVE_LISTEN = "SAVE_LISTEN";
export const UPDATE_CURRENT_LISTEN_LIST = "UPDATE_CURRENT_LISTEN_LIST";
export const UPDATE_CURRENT_LISTEN = "UPDATE_CURRENT_LISTEN";

export const UNSAVED_LISTEN = "UNSAVED_LISTEN";
export const SAVED_LISTEN = "SAVED_LISTEN";

export const ADD_VIEW_LISTEN = "ADD_VIEW_LISTEN";

export const ADD_CLICK_LISTEN = "ADD_CLICK_LISTEN";

export const MANAGE_LIKE_DISLIKE_LISTEN = "MANAGE_LIKE_DISLIKE_LISTEN";

export const UPDATE_ONE_LISTEN = "UPDATE_ONE_LISTEN";
export const UPDATE_ONE_LISTEN_SUCCESS = "UPDATE_ONE_LISTEN_SUCCESS";
export const UPDATE_ONE_LISTEN_ERROR = "UPDATE_ONE_LISTEN_ERROR";
export const RESET_UPDATE_ONE_LISTEN = "RESET_UPDATE_ONE_LISTEN";
export const GET_LISTENS_OFFSET = "GET_LISTENS_OFFSET";
export const GET_LISTENS_OFFSET_SUCCESS = "GET_LISTENS_OFFSET_SUCCESS";
export const GET_LISTENS_OFFSET_ERROR = "GET_LISTENS_OFFSET_ERROR";
export const RESET_GET_LISTENS_OFFSET = "RESET_GET_LISTENS_OFFSET";
export const RESET_GET_ALL_LISTEN_DATA = "RESET_GET_ALL_LISTEN_DATA";
export const RESET_GET_ONE_LISTEN_DATA = " RESET_GET_ONE_LISTEN_DATA";
export const UPDATE_ITEM_LIST_LISTEN = "UPDATE_ITEM_LIST_LISTEN";

export interface ResetGetAllListenData {
  type: typeof RESET_GET_ALL_LISTEN_DATA;
  payload: null;
}

export interface UpdateItemListListen {
  type: typeof UPDATE_ITEM_LIST_LISTEN;
  payload: any;
}

export interface ResetGetOneListenData {
  type: typeof RESET_GET_ONE_LISTEN_DATA;
  payload: null;
}

export interface GetListensOffset {
  type: typeof GET_LISTENS_OFFSET;
  payload: { limit: number; offset: number };
}

export interface GetListensOffsetError {
  type: typeof GET_LISTENS_OFFSET_ERROR;
  payload: string | boolean;
}

export interface GetListensOffsetSuccess {
  type: typeof GET_LISTENS_OFFSET_SUCCESS;
  payload: ListenDto[];
}

export interface ResetGetListensOffset {
  type: typeof RESET_GET_LISTENS_OFFSET;
  payload: null;
}
export interface UpdateOneListen {
  type: typeof UPDATE_ONE_LISTEN;
  payload: any;
}

export interface SetGetListen {
  type: typeof SET_GET_LISTEN;
  payload: ListenDto;
}

export interface UpdateOneListenSuccess {
  type: typeof UPDATE_ONE_LISTEN_SUCCESS;
  payload: ListenDto;
}
export interface UpdateOneListenError {
  type: typeof UPDATE_ONE_LISTEN_ERROR;
  payload: boolean | string;
}
export interface ResetUpdateOneListen {
  type: typeof RESET_UPDATE_ONE_LISTEN;
  payload: null;
}

export interface ManageLikeDislikeListen {
  type: typeof MANAGE_LIKE_DISLIKE_LISTEN;
  payload: {
    _id: string;
    user: string;
    condition: "like" | "dislike";
    action: "add" | "remove";
  };
}

export interface SavedListens {
  type: typeof SAVED_LISTEN;
  payload: string;
}
export interface UnSavedListens {
  type: typeof UNSAVED_LISTEN;
  payload: string;
}
export interface SharedListen {
  type: typeof SHARED_LISTEN;
  payload: any;
}

export interface AddClickListen {
  type: typeof ADD_CLICK_LISTEN;
  payload: { _id: string };
}
export interface GetAllListens {
  type: typeof GET_ALL_LISTENS;
  payload: null;
}

export interface AddViewListen {
  type: typeof ADD_VIEW_LISTEN;
  payload: { _id: string };
}

export interface SaveListen {
  type: typeof SAVE_LISTEN;
  payload: { _id: string; condition: string };
}

export interface QueryListen {
  type: typeof QUERY_LISTEN;
  payload: string;
}
export interface QueryListenSuccess {
  type: typeof QUERY_LISTEN_SUCCESS;
  payload: ListenDto[];
}
export interface QueryListenError {
  type: typeof QUERY_LISTEN_ERROR;
  payload: string | boolean;
}

export interface UpdateCurrentListenList {
  type: typeof UPDATE_CURRENT_LISTEN_LIST;
  payload: ListenDto[];
}

export interface ResetQueryListen {
  type: typeof RESET_QUERY_LISTEN;
  payload: null;
}

export interface GetAllListensSuccess {
  type: typeof GET_ALL_LISTENS_SUCCESS;
  payload: ListenDto[];
}

export interface GetAllListensError {
  type: typeof GET_ALL_LISTENS_ERROR;
  payload: boolean | string;
}

export interface AddUserToListen {
  type: typeof ADD_USER_TO_LISTEN;
  payload: any;
}

export interface GetListen {
  type: typeof GET_LISTEN;
  payload: { id: string };
}

export interface GetListenSuccess {
  type: typeof GET_LISTEN_SUCCESS;
  payload: ListenDto;
}

export interface GetListenError {
  type: typeof GET_LISTEN_ERROR;
  payload: boolean | string;
}

export interface NewListen {
  type: typeof NEW_LISTEN;
  payload: ListenDto;
}

export interface NewListenSuccess {
  type: typeof NEW_LISTEN_SUCCESS;
  payload: ListenDto;
}

export interface ResetGetListen {
  type: typeof RESET_GET_LISTEN;
  payload: null;
}

export interface NewListenError {
  type: typeof NEW_LISTEN_ERROR;
  payload: boolean | string;
}

export interface UploadMedia {
  type: typeof UPLOAD_MEDIA;
  payload: any;
}

export interface ResetNewListen {
  type: typeof RESET_NEW_LISTEN;
  payload: null;
}

export interface UpdateListen {
  type: typeof UPDATE_LISTEN;
  payload: ListenDto;
}

export interface UpdateListenLoading {
  type: typeof UPDATE_LISTEN_LOADING;
  payload: ListenDto;
}

export interface UpdateListenSuccess {
  type: typeof UPDATE_LISTEN_SUCCESS;
  payload: ListenDto;
}

export interface UpdateListenError {
  type: typeof UPDATE_LISTEN_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateListen {
  type: typeof RESET_UPDATE_LISTEN;
  payload: null;
}

export interface DeleteListen {
  type: typeof DELETE_LISTEN;
  payload: { _id: string };
}

export interface DeleteLISTENuccess {
  type: typeof DELETE_LISTEN_SUCCESS;
  payload: null;
}

export interface DeleteListenError {
  type: typeof DELETE_LISTEN_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteListen {
  type: typeof RESET_DELETE_LISTEN;
  payload: null;
}

export interface UpdateCurrentListen {
  type: typeof UPDATE_CURRENT_LISTEN;
  payload: ListenDto;
}

export interface State {
  all: {
    data: ListenDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  listen: {
    data: ListenDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  new: {
    data: ListenDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  offSet: {
    data: ListenDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  query: {
    data: ListenDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateOne: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  listen: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  new: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  offSet: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  query: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateOne: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllListens
  | GetAllListensSuccess
  | GetAllListensError
  | GetListen
  | GetListenSuccess
  | GetListenError
  | NewListen
  | NewListenSuccess
  | NewListenError
  | ResetNewListen
  | UpdateListen
  | UpdateListenSuccess
  | UpdateListenError
  | ResetUpdateListen
  | DeleteListen
  | DeleteLISTENuccess
  | DeleteListenError
  | ResetDeleteListen
  | UploadMedia
  | UpdateListenLoading
  | ResetGetListen
  | AddUserToListen
  | SharedListen
  | QueryListen
  | QueryListenSuccess
  | QueryListenError
  | ResetQueryListen
  | SaveListen
  | UpdateCurrentListenList
  | UpdateCurrentListen
  | SavedListens
  | UnSavedListens
  | AddViewListen
  | AddClickListen
  | ManageLikeDislikeListen
  | UpdateOneListen
  | UpdateOneListenSuccess
  | UpdateOneListenError
  | ResetUpdateOneListen
  | SetGetListen
  | GetListensOffset
  | GetListensOffsetError
  | GetListensOffsetSuccess
  | ResetGetListensOffset
  | ResetGetAllListenData
  | ResetGetOneListenData
  | UpdateItemListListen;
