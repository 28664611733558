import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_BYTE,
  GET_BYTE_SUCCESS,
  GET_BYTE_ERROR,
  GET_ALL_BYTES,
  GET_ALL_BYTES_SUCCESS,
  GET_ALL_BYTES_ERROR,
  NEW_BYTE,
  NEW_BYTE_ERROR,
  NEW_BYTE_SUCCESS,
  NewByte,
  UPDATE_BYTE,
  UPDATE_BYTE_SUCCESS,
  UPDATE_BYTE_ERROR,
  DELETE_BYTE,
  DELETE_BYTE_SUCCESS,
  DELETE_BYTE_ERROR,
  GetByte,
  GetAllBytes,
  UpdateByte,
  DeleteByte,
  UploadMedia,
  UPLOAD_MEDIA,
  AddUserToByte,
  ADD_USER_TO_BYTE,
  SharedByte,
  SHARED_BYTE,
  QueryByte,
  QUERY_BYTE,
  QUERY_BYTE_ERROR,
  QUERY_BYTE_SUCCESS,
  SaveByte,
  SAVE_BYTE,
  UPDATE_CURRENT_BYTE_LIST,
  UPDATE_CURRENT_BYTE,
  SAVED_BYTE,
  UNSAVED_BYTE,
  AddViewByte,
  ADD_VIEW_BYTE,
  UPDATE_ONE_BYTE_SUCCESS,
  UPDATE_ONE_BYTE_ERROR,
  SET_GET_BYTE,
  SetGetByte,
  MANAGE_LIKE_DISLIKE_BYTE,
  AddClickByte,
  ADD_CLICK_BYTE,
  ManageLikeDislikeByte,
  GET_BYTES_OFFSET,
  GET_BYTES_OFFSET_ERROR,
  GET_BYTES_OFFSET_SUCCESS,
  GetBytesOffset,
  UPDATE_ITEM_LIST_BYTE,
} from "../types/byte";

const bytesOffset = ({ byteStore }) => byteStore.offSet.data;
const allBytes = ({ byteStore }) => byteStore.all.data;
const user = ({ userStore }) => userStore.data;

function* getAllBytes({ payload }: GetAllBytes) {
  const { response, error } = yield call(services.byte.getAll, payload);
  if (error) {
    yield put({
      type: GET_ALL_BYTES_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_BYTES_SUCCESS, payload: response });
  }
}

function* getByte({ payload }) {
  const { response, error } = yield call(services.byte.getOne, payload);

  if (error) {
    yield put({
      type: GET_BYTE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_BYTE_SUCCESS, payload: response });
  }
}

function* saveByte({ payload }) {
  const { response, error } = yield call(services.byte.saveByte, payload);

  if (error) {
    yield put({
      type: UPDATE_BYTE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const currentBytes = yield select(allBytes);
    const currentUser = yield select(user);
    const updatedCurrentBytes = currentBytes.map((item) => {
      if (item._id === payload._id) {
        if (payload.condition === "unsave") {
          const index = item.savedBy.indexOf(currentUser._id);

          if (index !== -1) {
            item.savedBy.splice(index, 1);
          }

          return item;
        } else {
          return { ...item, savedBy: [...item.savedBy, currentUser._id] };
        }
      } else {
        return item;
      }
    });

    yield all([
      put({ type: UPDATE_BYTE_SUCCESS, payload: response }),
      ...(!!payload.individual
        ? [put({ type: SET_GET_BYTE, payload: response })]
        : [
            put({
              type: GET_ALL_BYTES_SUCCESS,
              payload: updatedCurrentBytes,
            }),
          ]),
    ]);
  }
}

function* queryByte({ payload }) {
  const { response, error } = yield call(services.byte.query, payload);

  if (error) {
    yield put({
      type: QUERY_BYTE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: QUERY_BYTE_SUCCESS, payload: response });
  }
}

function* newByte({ payload }) {
  const { response, error } = yield call(services.byte.new, payload);

  if (error) {
    yield put({
      type: NEW_BYTE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_BYTE_SUCCESS, payload: response })]);
  }
}

function* updateByte({ payload }) {
  const { response, error } = yield call(services.byte.update, payload);

  if (error) {
    yield put({
      type: UPDATE_BYTE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_BYTE_SUCCESS, payload: response }),
      put({ type: GET_ALL_BYTES, payload: null }),
    ]);
  }
}
function* addUserToByte({ payload }) {
  const { response, error } = yield call(services.byte.addUserByte, payload);

  if (error) {
    yield put({
      type: UPDATE_BYTE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_BYTE_SUCCESS, payload: response })]);
  }
}

function* uploadMedia({ payload }) {
  const { response, error } = yield call(services.byte.update, payload);

  if (error) {
    yield put({
      type: UPDATE_BYTE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_BYTE_SUCCESS, payload: response })]);
  }
}

function* deleteByte({ payload }) {
  const { response, error } = yield call(services.byte.delete, payload);

  if (error) {
    yield put({
      type: DELETE_BYTE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_BYTE_SUCCESS, payload: response })]);
  }
}

function* shareByte({ payload }) {
  const { response, error } = yield call(services.byte.shareByte, payload);

  if (error) {
    yield put({
      type: UPDATE_BYTE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_BYTE_SUCCESS, payload: response })]);
  }
}

function* addView({ payload }) {
  const { response, error } = yield call(services.byte.addView, payload);
  /* 
  if (error) {
    yield put({
      type: UPDATE_BYTE_ERROR, 
      payload: error,
    });
  } else {
    const newListByte = yield select(byteList);
      const newListByteAdded = newListByte.map((item) =>
      item._id === response._id ? item : response
    );
    yield all([put({ type: UPDATE_BYTE_SUCCESS, payload: response })]);
  } */
}

function* addClick({ payload }) {
  const { response, error } = yield call(services.byte.addClick, payload);
  /*  if (error) {
  } else {
    const currentListOfRoadmaps = yield select(companyList);
    const newListofRoadmaps = currentListOfRoadmaps?.map((content) => {
      if (content._id === response._id) {
        return response;
      }
      return content;
    });
    yield put({
      type: UPDATE_CURRENT_ROADMAP_LIST,
      payload: newListofRoadmaps,
    });
  } */
}

function* manageLikeDislike({ payload }) {
  const { response, error } = yield call(
    services.byte.manageLikeDislike,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_BYTE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    if (!payload.notReload) {
      yield put({ type: UPDATE_BYTE_SUCCESS, payload: response });
    }

    if (payload.individual) {
      yield put({ type: SET_GET_BYTE, payload: response });
    }
  }
}

function* getBytesOffset({ payload }) {
  const { response, error } = yield call(services.byte.getOffset, payload);
  if (error) {
    yield put({
      type: GET_BYTES_OFFSET_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const offsetBytes = yield select(bytesOffset);
    const newOffsetBytes = !offsetBytes
      ? response
      : [...offsetBytes, ...response];
    yield put({
      type: GET_BYTES_OFFSET_SUCCESS,
      payload: newOffsetBytes,
    });
  }
}

function* updateByteListItem({ payload }) {
  const { response, error } = yield call(services.byte.update, payload);

  if (error) {
    yield put({
      type: UPDATE_BYTE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_BYTE_SUCCESS, payload: response }),
      put({ type: SET_GET_BYTE, payload: response }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllBytes>(GET_ALL_BYTES, getAllBytes);
  yield takeLatest<GetByte>(GET_BYTE, getByte);
  yield takeLatest<NewByte>(NEW_BYTE, newByte);
  yield takeLatest<UpdateByte>(UPDATE_BYTE, updateByte);
  yield takeLatest<DeleteByte>(DELETE_BYTE, deleteByte);
  yield takeLatest<UploadMedia>(UPLOAD_MEDIA, uploadMedia);
  yield takeLatest<AddUserToByte>(ADD_USER_TO_BYTE, addUserToByte);
  yield takeLatest<SharedByte>(SHARED_BYTE, shareByte);
  yield takeLatest<QueryByte>(QUERY_BYTE, queryByte);
  yield takeLatest<SaveByte>(SAVED_BYTE, saveByte);
  yield takeLatest<SaveByte>(SAVE_BYTE, saveByte);
  yield takeLatest<AddViewByte>(ADD_VIEW_BYTE, addView);
  yield takeLatest<AddClickByte>(ADD_CLICK_BYTE, addClick);
  yield takeLatest<ManageLikeDislikeByte>(
    MANAGE_LIKE_DISLIKE_BYTE,
    manageLikeDislike
  );
  yield takeLatest<GetBytesOffset>(GET_BYTES_OFFSET, getBytesOffset);
  yield takeLatest<any>(UPDATE_ITEM_LIST_BYTE, updateByteListItem);
}
