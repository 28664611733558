import styled from "styled-components";

export const SuscriptionCard = styled("div")`
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(36, 150, 237, 0.25);
  border-radius: 10px;
  padding: 10px;
`;

export const SuscriptionCardLabel = styled("h4")`
  color: ${(props) => (!!props.color ? props.theme.colors[props.color] : "")};
  font-weight: 600;
  font-size: 12px;
`;
export const SuscriptionCardTitle = styled("h2")`
  color: ${(props) => (!!props.color ? props.theme.colors[props.color] : "")};
  font-size: 14px;
  font-weight: bold;
`;
