import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
interface ComponentProps {
  children: any;
  disabled?: boolean;
  onCurrentSlide?: (...args: any[]) => any;
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={"slick-next-new"} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={"slick-prev-new"} onClick={onClick} />;
}

const Component = ({ children, disabled, onCurrentSlide }: ComponentProps) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (oldIndex, newIndex) => {
      if (!!onCurrentSlide) {
        onCurrentSlide(oldIndex, newIndex);
      }
    },
    arrows: true,
    slidesToScroll: 1,
  };

  return <Slider {...settings}>{children}</Slider>;
};

export default Component;
