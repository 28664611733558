import styled from "styled-components";

export const ToolSideWrapper = styled("div")`
  padding: 4px 4px 4px 4px;
  border-radius: 6px;
  box-shadow: 0 0 3px 0 rgba(79, 38, 162, 0.25);
  height: min-content;
  max-width: min-content;
  background-color: #fff;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  ${(props) => (!!props.openDropDown ? "max-height:200px;" : "")}
`;
export const ToolItemWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px ${(props) => props.theme.colors.LightBlue};
  position: relative;
  padding: 4px 5px;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  &:hover > div {
    visibility: visible;
  }
  svg {
    color: ${(props) => props.theme.colors.LightBlue};
    transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
  }
  &.active {
    background-color: ${(props) => props.theme.colors.LightBlue};
    svg {
      color: #fff;
    }
  }

  &.dropActive {
    svg {
      transform: rotate(0deg);
    }
  }
  &:not(.dropActive):is(.arrow) {
    svg {
      transform: rotate(180deg);
    }
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.LightBlue};
    svg {
      color: #fff;
    }
  }
  &.disavailable {
    background-color: #fff;
    svg {
      color: rgba(0, 30, 108, 0.3);
    }
  }
  &.disavailable:hover {
    background-color: #fff;
    svg {
      color: rgba(0, 30, 108, 0.3);
    }
  }
`;

/* export const ToolItemWrapper = styled("div")`` */

export const ToolTipItem = styled("div")`
  position: absolute;
  visibility: hidden;
  width: max-content;
  transition: visibility 0.3s ease-in-out;
  top: 0;
  right: 50px;
  padding: 5px;
  box-shadow: 0 0 6px 0 rgba(31, 119, 180, 0.2);
  background-color: var(--white);
`;
