import { PaginationDto } from "../../types/pagination.dto";
import { ByteDto } from "../../types/byte.dto";

export const GET_ALL_BYTES = "GET_ALL_BYTE";
export const GET_ALL_BYTES_ERROR = "GET_ALL_BYTE_ERROR";
export const GET_ALL_BYTES_SUCCESS = "GET_ALL_BYTE_SUCCESS";
export const GET_BYTE = "GET_BYTE";
export const GET_BYTE_ERROR = "GET_BYTE_ERROR";
export const SET_GET_BYTE = "SET_GET_BYTE";
export const GET_BYTE_SUCCESS = "GET_BYTE_SUCCESS";
export const RESET_GET_BYTE = "RESET_GET_BYTE";
export const NEW_BYTE = "NEW_BYTE";
export const NEW_BYTE_ERROR = "NEW_BYTE_ERROR";
export const NEW_BYTE_SUCCESS = "NEW_BYTE_SUCCESS";
export const RESET_NEW_BYTE = "RESET_NEW_BYTE";
export const UPDATE_BYTE = "UPDATE_BYTE";
export const UPDATE_BYTE_ERROR = "UPDATE_BYTE_ERROR";
export const UPDATE_BYTE_SUCCESS = "UPDATE_BYTE_SUCCESS";
export const RESET_UPDATE_BYTE = "RESET_UPDATE_BYTE";
export const DELETE_BYTE = "DELETE_BYTE";
export const DELETE_BYTE_ERROR = "DELETE_BYTE_ERROR";
export const DELETE_BYTE_SUCCESS = "DELETE_BYTE_SUCCESS";
export const RESET_DELETE_BYTE = "RESET_DELETE_BYTE";
export const UPLOAD_MEDIA = "UPLOAD_MEDIA";
export const UPDATE_BYTE_LOADING = "UPDATE_BYTE_LOADING";
export const ADD_USER_TO_BYTE = "ADD_USER_TO_BYTE";
export const SHARED_BYTE = "SHARED_BYTE";
export const QUERY_BYTE = "QUERY_BYTE";
export const QUERY_BYTE_SUCCESS = "QUERY_BYTE_SUCCESS";
export const QUERY_BYTE_ERROR = "QUERY_BYTE_ERROR";
export const RESET_QUERY_BYTE = "RESET_QUERY_BYTE";
export const SAVE_BYTE = "SAVE_BYTE";
export const UPDATE_CURRENT_BYTE_LIST = "UPDATE_CURRENT_BYTE_LIST";
export const UPDATE_CURRENT_BYTE = "UPDATE_CURRENT_BYTE";

export const UNSAVED_BYTE = "UNSAVED_BYTE";
export const SAVED_BYTE = "SAVED_BYTE";

export const ADD_VIEW_BYTE = "ADD_VIEW_BYTE";

export const ADD_CLICK_BYTE = "ADD_CLICK_BYTE";

export const MANAGE_LIKE_DISLIKE_BYTE = "MANAGE_LIKE_DISLIKE_BYTE";

export const UPDATE_ONE_BYTE = "UPDATE_ONE_BYTE";
export const UPDATE_ONE_BYTE_SUCCESS = "UPDATE_ONE_BYTE_SUCCESS";
export const UPDATE_ONE_BYTE_ERROR = "UPDATE_ONE_BYTE_ERROR";
export const RESET_UPDATE_ONE_BYTE = "RESET_UPDATE_ONE_BYTE";
export const GET_BYTES_OFFSET = "GET_BYTES_OFFSET";
export const GET_BYTES_OFFSET_SUCCESS = "GET_BYTES_OFFSET_SUCCESS";
export const GET_BYTES_OFFSET_ERROR = "GET_BYTES_OFFSET_ERROR";
export const RESET_GET_BYTES_OFFSET = "RESET_GET_BYTES_OFFSET";
export const SET_OFFSET_BYTE = "SET_OFFSET_BYTE";
export const RESET_GET_ALL_BYTE_DATA = "RESET_GET_ALL_BYTE_DATA";
export const RESET_GET_ONE_BYTE_DATA = "RESET_GET_ONE_BYTE_DATA";
export const UPDATE_ITEM_LIST_BYTE = "UPDATE_ITEM_LIST_BYTE";

export interface ResetGetAllByteData {
  type: typeof RESET_GET_ALL_BYTE_DATA;
  payload: null;
}

export interface ResetGetOneByteData {
  type: typeof RESET_GET_ONE_BYTE_DATA;
  payload: null;
}

export interface UpdateItemListByte {
  type: typeof UPDATE_ITEM_LIST_BYTE;
  payload: any;
}

export interface GetBytesOffset {
  type: typeof GET_BYTES_OFFSET;
  payload: { limit: number; offset: number };
}

export interface GetBytesOffsetError {
  type: typeof GET_BYTES_OFFSET_ERROR;
  payload: string | boolean;
}

export interface SetOffsetByte {
  type: typeof SET_OFFSET_BYTE;
  payload: ByteDto[] | null;
}

export interface GetBytesOffsetSuccess {
  type: typeof GET_BYTES_OFFSET_SUCCESS;
  payload: ByteDto[];
}

export interface ResetGetBytesOffset {
  type: typeof RESET_GET_BYTES_OFFSET;
  payload: null;
}
export interface UpdateOneByte {
  type: typeof UPDATE_ONE_BYTE;
  payload: any;
}

export interface SetGetByte {
  type: typeof SET_GET_BYTE;
  payload: ByteDto;
}

export interface UpdateOneByteSuccess {
  type: typeof UPDATE_ONE_BYTE_SUCCESS;
  payload: ByteDto;
}
export interface UpdateOneByteError {
  type: typeof UPDATE_ONE_BYTE_ERROR;
  payload: boolean | string;
}
export interface ResetUpdateOneByte {
  type: typeof RESET_UPDATE_ONE_BYTE;
  payload: null;
}

export interface ManageLikeDislikeByte {
  type: typeof MANAGE_LIKE_DISLIKE_BYTE;
  payload: {
    _id: string;
    user: string;
    condition: "like" | "dislike";
    action: "add" | "remove";
  };
}

export interface SavedBytes {
  type: typeof SAVED_BYTE;
  payload: string;
}
export interface UnSavedBytes {
  type: typeof UNSAVED_BYTE;
  payload: string;
}
export interface SharedByte {
  type: typeof SHARED_BYTE;
  payload: any;
}

export interface AddClickByte {
  type: typeof ADD_CLICK_BYTE;
  payload: { _id: string };
}
export interface GetAllBytes {
  type: typeof GET_ALL_BYTES;
  payload?: PaginationDto;
}

export interface AddViewByte {
  type: typeof ADD_VIEW_BYTE;
  payload: { _id: string };
}

export interface SaveByte {
  type: typeof SAVE_BYTE;
  payload: { _id: string; condition: string };
}

export interface QueryByte {
  type: typeof QUERY_BYTE;
  payload: string;
}
export interface QueryByteSuccess {
  type: typeof QUERY_BYTE_SUCCESS;
  payload: ByteDto[];
}
export interface QueryByteError {
  type: typeof QUERY_BYTE_ERROR;
  payload: string | boolean;
}

export interface UpdateCurrentByteList {
  type: typeof UPDATE_CURRENT_BYTE_LIST;
  payload: ByteDto[];
}

export interface ResetQueryByte {
  type: typeof RESET_QUERY_BYTE;
  payload: null;
}

export interface GetAllBytesSuccess {
  type: typeof GET_ALL_BYTES_SUCCESS;
  payload: ByteDto[];
}

export interface GetAllBytesError {
  type: typeof GET_ALL_BYTES_ERROR;
  payload: boolean | string;
}

export interface AddUserToByte {
  type: typeof ADD_USER_TO_BYTE;
  payload: any;
}

export interface GetByte {
  type: typeof GET_BYTE;
  payload: { id: string };
}

export interface GetByteSuccess {
  type: typeof GET_BYTE_SUCCESS;
  payload: ByteDto;
}

export interface GetByteError {
  type: typeof GET_BYTE_ERROR;
  payload: boolean | string;
}

export interface NewByte {
  type: typeof NEW_BYTE;
  payload: ByteDto;
}

export interface NewBYTEuccess {
  type: typeof NEW_BYTE_SUCCESS;
  payload: ByteDto;
}

export interface ResetGetByte {
  type: typeof RESET_GET_BYTE;
  payload: null;
}

export interface NewByteError {
  type: typeof NEW_BYTE_ERROR;
  payload: boolean | string;
}

export interface UploadMedia {
  type: typeof UPLOAD_MEDIA;
  payload: any;
}

export interface ResetNewByte {
  type: typeof RESET_NEW_BYTE;
  payload: null;
}

export interface UpdateByte {
  type: typeof UPDATE_BYTE;
  payload: ByteDto;
}

export interface UpdateByteLoading {
  type: typeof UPDATE_BYTE_LOADING;
  payload: ByteDto;
}

export interface UpdateBYTEuccess {
  type: typeof UPDATE_BYTE_SUCCESS;
  payload: ByteDto;
}

export interface UpdateByteError {
  type: typeof UPDATE_BYTE_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateByte {
  type: typeof RESET_UPDATE_BYTE;
  payload: null;
}

export interface DeleteByte {
  type: typeof DELETE_BYTE;
  payload: { _id: string };
}

export interface DeleteBYTEuccess {
  type: typeof DELETE_BYTE_SUCCESS;
  payload: null;
}

export interface DeleteByteError {
  type: typeof DELETE_BYTE_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteByte {
  type: typeof RESET_DELETE_BYTE;
  payload: null;
}

export interface UpdateCurrentByte {
  type: typeof UPDATE_CURRENT_BYTE;
  payload: ByteDto;
}

export interface State {
  all: {
    data: ByteDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  byte: {
    data: ByteDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  new: {
    data: ByteDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  offSet: {
    data: ByteDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  query: {
    data: ByteDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    data: ByteDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateOne: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  byte: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  new: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  offSet: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  query: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateOne: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllBytes
  | GetAllBytesSuccess
  | GetAllBytesError
  | GetByte
  | GetByteSuccess
  | GetByteError
  | NewByte
  | NewBYTEuccess
  | NewByteError
  | ResetNewByte
  | UpdateByte
  | UpdateBYTEuccess
  | UpdateByteError
  | ResetUpdateByte
  | DeleteByte
  | DeleteBYTEuccess
  | DeleteByteError
  | ResetDeleteByte
  | UploadMedia
  | UpdateByteLoading
  | ResetGetByte
  | AddUserToByte
  | SharedByte
  | QueryByte
  | QueryByteSuccess
  | QueryByteError
  | ResetQueryByte
  | SaveByte
  | UpdateCurrentByteList
  | UpdateCurrentByte
  | SavedBytes
  | UnSavedBytes
  | AddViewByte
  | AddClickByte
  | ManageLikeDislikeByte
  | UpdateOneByte
  | UpdateOneByteSuccess
  | UpdateOneByteError
  | ResetUpdateOneByte
  | SetGetByte
  | GetBytesOffset
  | GetBytesOffsetError
  | GetBytesOffsetSuccess
  | ResetGetBytesOffset
  | SetOffsetByte
  | ResetGetAllByteData
  | ResetGetOneByteData
  | UpdateItemListByte;
