import Skeleton from "../../../../../components/Skeleton";
import { ListMediaTitle } from "./styles";
import { FlexContainer } from "../../../../../components/StyledComponents";

const Component = () => {
  return (
    <FlexContainer direction="column" gap="20px">
      <FlexContainer
        style={{ minHeight: "200px" }}
        direction="column"
        gap="10px"
      >
        <ListMediaTitle>Videos</ListMediaTitle>
        <Skeleton
          itemsPerPage={3}
          itemsPerRow={3}
          animated
          size={{ height: 290, width: 276 }}
          icon="video"
        />
      </FlexContainer>
      <FlexContainer
        style={{ minHeight: "200px" }}
        direction="column"
        gap="10px"
      >
        <ListMediaTitle>SlideShows</ListMediaTitle>
        <Skeleton
          itemsPerPage={3}
          itemsPerRow={3}
          animated
          size={{ height: 311, width: 276 }}
          icon="slide"
        />
      </FlexContainer>
      <FlexContainer
        style={{ minHeight: "200px" }}
        direction="column"
        gap="10px"
      >
        <ListMediaTitle>Academias</ListMediaTitle>
        <Skeleton
          itemsPerPage={6}
          itemsPerRow={6}
          animated
          size={{ height: 160, width: 176 }}
          icon="academy"
        />
      </FlexContainer>
      <FlexContainer
        style={{ minHeight: "200px" }}
        direction="column"
        gap="10px"
      >
        <ListMediaTitle>Imágenes</ListMediaTitle>
        <Skeleton
          itemsPerPage={3}
          itemsPerRow={3}
          animated
          size={{ height: 290, width: 276 }}
          icon="image"
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default Component;
