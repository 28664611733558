import * as Yup from "yup";

export const schema = Yup.object().shape({
  name: Yup.string().required("Ingresa un nombre."),
  lastName: Yup.string().required("Ingresa el tipo de evento."),
  email: Yup.string()
    .nullable()
    .email("El e-mail no es válido.")
    .max(200, "no puede superar los 200 caracteres")
    .required("Ingresa un e-mail."),
  password: Yup.string().required("Ingresa una contraseña."),
  dni: Yup.number().test(
    "len",
    "DNI no valido.",
    (val) => val?.toString().length === 8 || val?.toString().length === 9
  ),
  phone: Yup.string(),
  cohortsIds: Yup.array().nullable(),
});

interface FormValues {
  name: string;
  lastName: string;
  email: string;
  password: string;
  dni?: number;
  phone?: string;
  cohortsIds?: string[];
}

export const initialValues: FormValues = {
  name: "",
  lastName: "",
  email: "",
  password: "",
  dni: undefined,
  phone: undefined,
  cohortsIds: undefined,
};
