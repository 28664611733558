import React from "react";
import { Routes, Route } from "react-router-dom";
import Academies from "../../Dashboard/Academies";
import Certificate from "../../Dashboard/Cetificates";
import MyCohorts from "../../Dashboard/Mys/Cohort";
import Content from "../../Dashboard/Content";
import Personal from "./Home/index";
import DashboardCampusData from "../../Dashboard/Dashboard";
import DashboardCampusProfessional from "../../Dashboard/Dashboard/Professional";
import DashboardCampusEducation from "../../Dashboard/Dashboard/Learning";
import IndividualPerformance from "../../Dashboard/Analytics/IndividualPerformance";
import Invitations from "../../Dashboard/Invitations";
import InDeveloping from "../../InDevolping";
import Calendar from "../../Dashboard/Calendar";
import Roadmap from "../Roadmap/List";
import MyCdocs from "../../Dashboard/Mys/Cdoc";
import MyRoadmaps from "../../Dashboard/Mys/Roadmap";
import ContentDashboard from "../../Dashboard/Dashboard/Content";

const Component = () => {
  return (
    <Routes>
      <Route index element={<Personal></Personal>} />
      <Route path="mycertificates" element={<Certificate />} />
      <Route path="mycohorts" element={<MyCohorts></MyCohorts>}></Route>
      <Route path="myacademies" element={<Academies></Academies>}></Route>
      <Route path="mycontents" element={<Content />}></Route>
      <Route path="myinvitations" element={<Invitations></Invitations>}></Route>
      <Route path="myroadmaps" element={<MyRoadmaps />}></Route>
      <Route path="mycdocs" element={<MyCdocs></MyCdocs>}></Route>
      <Route
        path="mydashboards"
        element={<DashboardCampusData></DashboardCampusData>}
      >
        <Route
          path="professional"
          element={<DashboardCampusProfessional></DashboardCampusProfessional>}
        ></Route>
        <Route
          path="education"
          element={<DashboardCampusEducation></DashboardCampusEducation>}
        ></Route>
        <Route path="contents">
          <Route
            path="general"
            element={<ContentDashboard.General></ContentDashboard.General>}
          ></Route>
          <Route
            path="academy"
            element={<ContentDashboard.Academy></ContentDashboard.Academy>}
          ></Route>
          <Route
            path="cohort"
            element={<ContentDashboard.Cohort></ContentDashboard.Cohort>}
          ></Route>
        </Route>
      </Route>
      <Route path="mycalendar" element={<Calendar></Calendar>}></Route>
      <Route
        path="myanalytics"
        element={<IndividualPerformance></IndividualPerformance>}
      ></Route>
      <Route path="*" element={<InDeveloping></InDeveloping>}></Route>
    </Routes>
  );
};

export default Component;
