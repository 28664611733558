import styled from "styled-components";

export const CautionBox = styled("div")`
  width: 100%;
  background-color: #fff17a;
  padding: 20px;
  margin-bottom: 10px;
`;

export const CautionText = styled("h3")`
  font-size: 10px;
  color: #6a6970;
  font-weight: 600;
  line-height: 1.3;
`;

export const TermsLink = styled("a")`
  display: inline-block;
  color: ${(props) => props.theme.colors.LightBlue};
  margin: 0 2px;
  line-height: 1;
`;

export const OverlayButton = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(235, 235, 228, 0.7); /* Transparent black overlay */
  border-radius: 10px;
  z-index: 1; /* Ensure the overlay is on top of other elements */
  pointer-events: auto; /* Allow the overlay to capture pointer events */
`;

export const ErrorSpan = styled("span")`
  color: #ff0505;
`;
