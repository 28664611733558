import { NotificationDto } from "../../types/notification.dto";
import { PaginationDto } from "../../types/pagination.dto";
export const GET_ALL_NOTIFICATION = "GET_ALL_NOTIFICATION";
export const RESET_GET_ALL_NOTIFICATION = "RESET_GET_ALL_NOTIFICATION";
export const GET_ALL_NOTIFICATION_SUCCESS = "GET_ALL_NOTIFICATION_SUCCESS";
export const GET_ALL_NOTIFICATION_ERROR = "GET_ALL_NOTIFICATION_ERROR";
export const NEW_NOTIFICATION = "NEW_NOTIFICATION";
export const NEW_NOTIFICATION_SUCCESS = "NEW_NOTIFICATION_SUCCESS";
export const NEW_NOTIFICATION_ERROR = "NEW_NOTIFICATION_ERROR";
export const RESET_NEW_NOTIFICATION = "RESET_NEW_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_ERROR = "DELETE_NOTIFICATION_ERROR";
export const RESET_DELETE_NOTIFICATION = "RESET_DELETE_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_ERROR = "UPDATE_NOTIFICATION_ERROR";
export const RESET_UPDATE_NOTIFICATION = "RESET_UPDATE_NOTIFICATION";
export const UPDATE_MANY_NOTIFICATIONS = "UPDATE_MANY_NOTIFICATIONS";
export const UPDATE_MANY_NOTIFICATIONS_ERROR =
  "UPDATE_MANY_NOTIFICATIONS_ERROR";
export const UPDATE_MANY_NOTIFICATIONS_SUCCESS =
  "UPDATE_MANY_NOTIFICATIONS_SUCCESS";
export const RESET_UPDATE_MANY_NOTIFICATIONS =
  "RESET_UPDATE_MANY_NOTIFICATIONS";
export const SEE_ALL_NOTIFICATIONS = "SEE_ALL_NOTIFICATIONS";
export interface SeeAllNotificactions {
  type: typeof SEE_ALL_NOTIFICATIONS;
  payload: null;
}
export interface GetAllNotification {
  type: typeof GET_ALL_NOTIFICATION;
  payload: PaginationDto;
}
export interface GetAllNotificationSuccess {
  type: typeof GET_ALL_NOTIFICATION_SUCCESS;
  payload: NotificationDto[];
}

export interface GetAllNotificationError {
  type: typeof GET_ALL_NOTIFICATION_ERROR;
  payload: string | boolean;
}
export interface ResetGetAllNotification {
  type: typeof RESET_GET_ALL_NOTIFICATION;
  payload: null;
}

export interface UpdateManyNotifications {
  type: typeof UPDATE_MANY_NOTIFICATIONS;
  payload: { items: { _id: string; [args: string]: any }[] };
}
export interface UpdateManyNotificationsSuccess {
  type: typeof UPDATE_MANY_NOTIFICATIONS_SUCCESS;
  payload: NotificationDto[];
}
export interface UpdateManyNotificationsError {
  type: typeof UPDATE_MANY_NOTIFICATIONS_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateManyNotifications {
  type: typeof RESET_UPDATE_MANY_NOTIFICATIONS;
  payload: null;
}

export interface NewNotification {
  type: typeof NEW_NOTIFICATION;
  payload: any;
}
export interface NewNotificationSuccess {
  type: typeof NEW_NOTIFICATION_SUCCESS;
  payload: null;
}
export interface NewNotificationError {
  type: typeof NEW_NOTIFICATION_ERROR;
  payload: string | boolean;
}
export interface ResetNewNotification {
  type: typeof RESET_NEW_NOTIFICATION;
  payload: null;
}

export interface DeleteNotification {
  type: typeof DELETE_NOTIFICATION;
  payload: any;
}
export interface DeleteNotificationSuccess {
  type: typeof DELETE_NOTIFICATION_SUCCESS;
  payload: null;
}
export interface DeleteNotificationError {
  type: typeof DELETE_NOTIFICATION_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteNotification {
  type: typeof RESET_DELETE_NOTIFICATION;
  payload: null;
}

export interface UpdateNotification {
  type: typeof UPDATE_NOTIFICATION;
  payload: any;
}
export interface UpdateNotificationSuccess {
  type: typeof UPDATE_NOTIFICATION_SUCCESS;
  payload: null;
}
export interface UpdateNotificationError {
  type: typeof UPDATE_NOTIFICATION_ERROR;
  payload: string | boolean;
}
export interface ResetUpdateNotification {
  type: typeof RESET_UPDATE_NOTIFICATION;
  payload: null;
}

export interface State {
  all: {
    data: NotificationDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateMany: {
    data: null | NotificationDto[];
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateMany: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};
export type Actions =
  | GetAllNotification
  | GetAllNotificationSuccess
  | GetAllNotificationError
  | NewNotification
  | NewNotificationSuccess
  | NewNotificationError
  | DeleteNotification
  | DeleteNotificationSuccess
  | DeleteNotificationError
  | ResetDeleteNotification
  | ResetNewNotification
  | ResetGetAllNotification
  | UpdateNotification
  | UpdateNotificationSuccess
  | UpdateNotificationError
  | ResetUpdateNotification
  | UpdateManyNotifications
  | UpdateManyNotificationsSuccess
  | UpdateManyNotificationsError
  | ResetUpdateManyNotifications
  | SeeAllNotificactions;
