import React from "react";
import Button from "../../../../../../components/Button";
import { useTheme } from "styled-components";
const Component = ({
  setFilter,
  fieldName,
  value,
  filters,
  name,
  special,
}: {
  setFilter;
  fieldName: string | string[];
  value;
  filters;
  name;
  special?;
}) => {
  const theme = useTheme();
  return (
    <Button
      type="button"
      onClick={() => {
        if (!special) {
          !filters.find(
            (filter) => filter.field === fieldName && filter.value === value
          )
            ? setFilter({ field: fieldName, value: value })
            : setFilter({ field: fieldName, value: "no" });
        } else {
          !filters.find(
            (filter) => filter.field === fieldName && filter.value === value
          )
            ? setFilter({
                field: fieldName,
                value: value,
                [special.name]: special.function,
              })
            : setFilter({ field: fieldName, value: "no" });
        }
      }}
      options={{
        size: "md",
        type: "filled",
        skin: "gray",
      }}
      minwidth="0px"
      style={{
        width: "auto",
        boxShadow: "none",
        border: "none",
        backgroundColor: theme.colors.LightBlueHover,
        height: "32px",
        color: "#2a2747",
        padding: "7px 8px 8px",
        marginRight: "10px",
        borderRadius: "5px",
        ...(!!filters.find(
          (filter) => filter.field === fieldName && filter.value === value
        ) && {
          backgroundColor: theme.colors.LightBlue,
          color: "#fff",
        }),
        ...(!filters.find((filter) =>
          Array.isArray(fieldName)
            ? fieldName.includes(filter.field)
            : filter.field === fieldName
        ) &&
          value === "no" && {
            backgroundColor: theme.colors.LightBlue,
            color: "#fff",
          }),
      }}
    >
      {name}
    </Button>
  );
};

export default Component;
