import React, { useEffect, useState } from "react";

import { Center, FlexContainer } from "../../../components/StyledComponents";
import Button from "../../../components/Button";
import { connect, useDispatch } from "react-redux";
import { AcademyDto } from "../../../types/academy.dto";
import { UserDto } from "../../../types/user.dto";

import { CohortDto } from "../../../types/cohort.dto";
import notificationActions from "../../../store/actions/notification";
import Loader from "../../../components/Loader";
import academyActions from "../../../store/actions/academies";

import { InvitationDto } from "../../../types/invitation.dto";
import {
  DateFromNotification,
  EntityImageContainer,
  EntityName,
  EntityTitleDescription,
  ListItem,
} from "./style";
import { StateDto } from "../../../types/states.dto";
import { NotificationDto } from "../../../types/notification.dto";

import Grid from "../../../components/Grid";
import { Img } from "../../SignIn/styles";
import {
  getAllCohorts,
  resetGetAllCohorts,
} from "../../../store/actions/cohort";
import { UsersDto } from "../../../types/users.dto";
import InfinityScroll from "../../../components/InfinityScroll";
import { DateFromNow } from "../../../helpers/format-date";
import Skeleton from "../../../components/Skeleton";
import { NotificationContext } from "../../../provider/UnSeenNotificationsProvider";
import FormattedStrongMessage from "../../../components/FormattedStrongMessage";
import invitationsActions from "../../../store/actions/invitation";
interface ComponentProps {
  user: UserDto;
  cohorts: CohortDto[];
  notifications: NotificationDto[];
  academies: AcademyDto[];
  invitations: InvitationDto[];
  users: UsersDto[];
  notificationsStates: StateDto;
  updateManyNotificationsStates: StateDto;
  updateManyNotifications: NotificationDto[];
  updateInvitationStates: StateDto;
  updatedInvitation: InvitationDto;
}

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const OrderArrayByDate = (array: NotificationDto[]) =>
  array.sort(
    (a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
  );

const filterValues = [
  {
    fieldName: "type",
    value: "no",
    name: "Todos",
  },
  {
    fieldName: "type",
    value: "Academy",
    name: "Academias",
  },
  {
    fieldName: "type",
    value: "Organization",
    name: "Organizaciones",
  },
];

const Component = ({
  user,
  cohorts,
  notifications,
  academies,

  invitations,
  users,
  notificationsStates,
  updateManyNotificationsStates,
  updateManyNotifications,
  updateInvitationStates,
  updatedInvitation,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const { unSeenNotifications } = React.useContext(NotificationContext);
  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 9,
      offset: 9 * Math.floor(page),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
      },
    };
    const sortBy = "createdAt";

    const sortDirection = "desc";
    dispatch(notificationActions.getAll({ ...payload, sortBy, sortDirection }));
  };
  const [currentNotificationsList, setCurrentNotificationsList] = useState<
    NotificationDto[]
  >([]);

  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);

  useEffect(() => {
    dispatch(notificationActions.seeAllNotificactions());
  }, [unSeenNotifications]);

  useEffect(() => {
    if (updateInvitationStates.success) {
      setCurrentNotificationsList((state) =>
        state.filter((st) => st.invitation._id !== updatedInvitation._id)
      );
      dispatch(invitationsActions.resetUpdate());
    }
  }, [updateInvitationStates]);

  useEffect(() => {
    if (updateManyNotificationsStates.success) {
      setCurrentNotificationsList((state) =>
        OrderArrayByDate(
          (state ?? []).map((st) => {
            const notificationFounded = updateManyNotifications.filter(
              (updatedNotification) => updatedNotification._id === st._id
            )[0];
            if (notificationFounded) {
              return notificationFounded;
            } else {
              return st;
            }
          })
        )
      );
      dispatch(notificationActions.resetUpdateMany());
    }
    if (updateManyNotificationsStates.error) {
      dispatch(notificationActions.resetUpdateMany());
    }
  }, [updateManyNotificationsStates]);
  useEffect(() => {
    if (!!notifications && !isLoading) {
      const invitationNotificationAvailable = notifications.filter(
        (notification) =>
          !notification.invitation ||
          notification.invitation.status === "PENDING"
      );

      setCurrentNotificationsList((state) =>
        OrderArrayByDate([...state, ...invitationNotificationAvailable])
      );
    }
  }, [notifications, isLoading]);

  useEffect(() => {
    setThereAreItems(!!notifications && notifications?.length > 0);
  }, [notifications]);

  useEffect(() => {
    setIsLoading(notificationsStates.loading);
  }, [notificationsStates]);

  return (
    <InfinityScroll
      action={fetchData}
      page={page}
      setPage={setPage}
      data={notifications}
      active={thereAreItems}
      isLoading={isLoading}
      style={{ paddingBottom: "20px", width: "100%" }}
      loader={
        <Skeleton
          itemsPerPage={9}
          itemsPerRow={1}
          animated
          size={{ height: 160 }}
          icon="notification"
        />
      }
    >
      <FlexContainer gap="5px" direction="column">
        {currentNotificationsList.map((notification) => {
          return (
            <ListItem>
              <FlexContainer justify="space-between" align="center">
                <FlexContainer gap="10px">
                  <EntityImageContainer>
                    <Img src={notification.entity.picture}></Img>
                  </EntityImageContainer>
                  <FlexContainer direction="column" gap="6px">
                    <EntityTitleDescription>
                      <FormattedStrongMessage
                        text={notification.description}
                        normalTextStyle={{
                          fontSize: "10px",
                          color: "#292747",
                          fontWeight: "400",
                        }}
                        strongTextStyle={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          color: "#292747",
                        }}
                      ></FormattedStrongMessage>
                    </EntityTitleDescription>
                    <DateFromNotification>
                      {`Hace ${DateFromNow(notification.createdAt)}`}
                    </DateFromNotification>
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
              {notification.invitation && (
                <FlexContainer justify="center" wrap="wrap" gap="8px">
                  <Button
                    type="button"
                    loading={updateInvitationStates.loading}
                    onClick={() => {
                      dispatch(
                        invitationsActions.update({
                          _id: notification.invitation,
                          condition: "accept",
                        })
                      );
                    }}
                    style={{
                      height: "24px",
                      minWidth: "80px",
                    }}
                    options={{
                      size: "md",
                      type: "filled",
                      skin: "primary",
                    }}
                  >
                    Aceptar
                  </Button>
                  <Button
                    type="button"
                    loading={updateInvitationStates.loading}
                    onClick={() => {
                      dispatch(
                        invitationsActions.update({
                          _id: notification.invitation,
                          condition: "reject",
                        })
                      );
                    }}
                    style={{
                      height: "24px",
                      minWidth: "80px",
                    }}
                    options={{
                      size: "md",
                      type: "filled",
                      skin: "danger",
                    }}
                  >
                    Rechazar
                  </Button>
                </FlexContainer>
              )}
            </ListItem>
          );
        })}
      </FlexContainer>
    </InfinityScroll>
  );
};

const states = ({
  userStore,
  cohortStore,
  invitationStore,
  academyStore,
  notificationStore,
  usersStore,
}) => {
  const { states: updateInvitationStates, data: updatedInvitation } =
    invitationStore.update;
  const { data: invitations } = invitationStore.all;
  const { data: academies } = academyStore.all;
  const { data: notifications, states: notificationsStates } =
    notificationStore.all;
  const {
    states: updateManyNotificationsStates,
    data: updateManyNotifications,
  } = notificationStore.updateMany;
  const { data: user } = userStore;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: users } = usersStore.all;
  return {
    notifications,
    user,
    cohorts,
    academies,
    updateInvitationStates,
    invitations,
    users,
    notificationsStates,
    updateManyNotificationsStates,
    updateManyNotifications,
    updatedInvitation,
  };
};

export default connect(states)(Component);
