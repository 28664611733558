import { UserDto } from "../../types/user.dto";
import {
  GetUserInformation,
  GET_USER_INFORMATION,
  SetUserInformation,
  SET_USER_INFORMATION,
  RESET_SET_USER_INFORMATION,
  UPDATE_USER,
  RESET_UPDATE_USER,
  FORGOT_PASSWORD,
  SHOWN_COHORT,
} from "../types/user";

export const makeShownCohort = (payload) => {
  return { type: SHOWN_COHORT, payload };
};

export const getUserInformation = (id): GetUserInformation => {
  return { type: GET_USER_INFORMATION, payload: id };
};

export const setUserInformation = (payload: UserDto): SetUserInformation => {
  return { type: SET_USER_INFORMATION, payload };
};

export const resetSetUserInformation = () => {
  return { type: RESET_SET_USER_INFORMATION, payload: null };
};

export const updateUser = (payload) => {
  return { type: UPDATE_USER, payload };
};

export const resetUpdateUser = () => {
  return { type: RESET_UPDATE_USER, payload: null };
};

export const forgotPassword = (payload) => {
  return { type: FORGOT_PASSWORD, payload };
};
