import { put, call, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  GetAllStudentsStats,
  GetCampusStats,
  GetCohortsStats,
  GetIndividualStats,
  GET_ALL_STUDENTS_STATS,
  GET_ALL_STUDENTS_STATS_ERROR,
  GET_ALL_STUDENTS_STATS_SUCCESS,
  GET_CAMPUS_STATS,
  GET_CAMPUS_STATS_ERROR,
  GET_CAMPUS_STATS_SUCCESS,
  GET_COHORT_STATS,
  GET_COHORT_STATS_ERROR,
  GET_COHORT_STATS_SUCCESS,
  GET_INDIVIDUAL_STATS,
  GET_INDIVIDUAL_STATS_ERROR,
  GET_INDIVIDUAL_STATS_SUCCESS,
  GET_ACADEMY_USER_TIME_ERROR,
  GET_ACADEMY_USER_TIME_SUCCESS,
  GET_COHORT_USER_TIME_ERROR,
  GET_COHORT_USER_TIME_SUCCESS,
  GetCohortUserTime,
  GET_COHORT_USER_TIME,
  GET_ACADEMY_USER_TIME,
  GET_USER_TIME_ERROR,
  GET_USER_TIME_SUCCESS,
  GetAcademyUserTime,
  GetUserTime,
  GET_USER_TIME,
} from "../types/analytics";

function* getStudentStats({ payload }) {
  const { response, error } = yield call(services.analytics.getOne, payload);
  if (error) {
    yield put({
      type: GET_INDIVIDUAL_STATS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_INDIVIDUAL_STATS_SUCCESS, payload: response });
  }
}

function* getCampusStats({ payload }) {
  const { response, error } = yield call(
    services.analytics.getCampusStats,
    payload
  );
  if (error) {
    yield put({
      type: GET_CAMPUS_STATS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_CAMPUS_STATS_SUCCESS, payload: response });
  }
}

function* getCohortStats({ payload }) {
  const { response, error } = yield call(
    services.analytics.getOneCohort,
    payload
  );
  if (error) {
    yield put({
      type: GET_COHORT_STATS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_COHORT_STATS_SUCCESS, payload: response });
  }
}

function* getCohortUserTimeStats({ payload }) {
  const { response, error } = yield call(
    services.analytics.getOneCohortUserTime,
    payload
  );
  if (error) {
    yield put({
      type: GET_COHORT_USER_TIME_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_COHORT_USER_TIME_SUCCESS, payload: response });
  }
}
function* getUserTimeStats({ payload }) {
  const { response, error } = yield call(
    services.analytics.getOneUserTime,
    payload
  );
  if (error) {
    yield put({
      type: GET_USER_TIME_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_USER_TIME_SUCCESS, payload: response });
  }
}
function* getAcademyUserTimeStats({ payload }) {
  const { response, error } = yield call(
    services.analytics.getOneAcademyUserTime,
    payload
  );
  if (error) {
    yield put({
      type: GET_ACADEMY_USER_TIME_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ACADEMY_USER_TIME_SUCCESS, payload: response });
  }
}

function* getAllStudentsStats() {
  const { response, error } = yield call(services.analytics.getStudentsStats);
  if (error) {
    yield put({
      type: GET_ALL_STUDENTS_STATS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_STUDENTS_STATS_SUCCESS, payload: response });
  }
}

export default function* applicant() {
  yield takeLatest<GetIndividualStats>(GET_INDIVIDUAL_STATS, getStudentStats);
  yield takeLatest<GetCohortsStats>(GET_COHORT_STATS, getCohortStats);
  yield takeLatest<GetAllStudentsStats>(
    GET_ALL_STUDENTS_STATS,
    getAllStudentsStats
  );
  yield takeLatest<GetCampusStats>(GET_CAMPUS_STATS, getCampusStats);
  yield takeLatest<GetCohortUserTime>(
    GET_COHORT_USER_TIME,
    getCohortUserTimeStats
  );
  yield takeLatest<GetAcademyUserTime>(
    GET_ACADEMY_USER_TIME,
    getAcademyUserTimeStats
  );
  yield takeLatest<GetUserTime>(GET_USER_TIME, getUserTimeStats);
}
