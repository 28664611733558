import styled from "styled-components";

export const Name = styled("div")`
  margin-top: 55px;
  font-size: 20px;
  font-weight: bold;
  color: #334253;
`;

export const Title = styled("h3")`
  font-family: Lato;
  margin: 16px 0px 8px;
  font-size: 20px;
  color: #182028;
`;

export const IconCard = styled("div")`
  position: absolute;
  cursor: pointer;
  ${(props) => !!props.style && props.style}
`;
