import {
  TalentDto,
  TalentDtoCreate,
  TalentDtoUpdate,
} from "../../types/talent.dto";

export const GET_ALL_TALENTS = "GET_ALL_TALENTS";
export const GET_ALL_TALENTS_ERROR = "GET_ALL_TALENTS_ERROR";
export const GET_ALL_TALENTS_SUCCESS = "GET_ALL_TALENTS_SUCCESS";
export const GET_TALENT = "GET_TALENT";
export const GET_TALENT_ERROR = "GET_TALENT_ERROR";
export const GET_TALENT_SUCCESS = "GET_TALENT_SUCCESS";
export const NEW_TALENT = "NEW_TALENT";
export const NEW_TALENT_ERROR = "NEW_TALENT_ERROR";

export const UPDATE_SHOWN_COHORT = "UPDATE_SHOWN_COHORT";

export const NEW_TALENT_SUCCESS = "NEW_TALENT_SUCCESS";
export const RESET_NEW_TALENT = "RESET_NEW_TALENT";
export const UPDATE_TALENT = "UPDATE_TALENT";
export const UPDATE_TALENT_ERROR = "UPDATE_TALENT_ERROR";
export const UPDATE_TALENT_SUCCESS = "UPDATE_TALENT_SUCCESS";
export const RESET_UPDATE_TALENT = "RESET_UPDATE_TALENT";
export const DELETE_TALENT = "DELETE_TALENT";
export const DELETE_TALENT_ERROR = "DELETE_TALENT_ERROR";
export const DELETE_TALENT_SUCCESS = "DELETE_TALENT_SUCCESS";
export const RESET_DELETE_TALENT = "RESET_DELETE_TALENT";
export const RESET_GET_ALL_TALENT_DATA = "RESET_GET_ALL_TALENT_DATA";
export const RESET_GET_ONE_TALENT_DATA = "RESET_GET_ONE_TALENT_DATA";

export interface ResetGetAllTalentData {
  type: typeof RESET_GET_ALL_TALENT_DATA;
  payload: null;
}

export interface ResetGetOneTalentData {
  type: typeof RESET_GET_ONE_TALENT_DATA;
  payload: null;
}
export interface GetAllTalents {
  type: typeof GET_ALL_TALENTS;
  payload: null;
}

export interface GetAllTalentsSuccess {
  type: typeof GET_ALL_TALENTS_SUCCESS;
  payload: TalentDto[];
}

export interface GetAllTalentsError {
  type: typeof GET_ALL_TALENTS_ERROR;
  payload: boolean | string;
}

export interface UpdateShownCohort {
  type: typeof UPDATE_SHOWN_COHORT;
  payload: string;
}

export interface GetTalent {
  type: typeof GET_TALENT;
  payload: { id: string };
}

export interface GetTalentSuccess {
  type: typeof GET_TALENT_SUCCESS;
  payload: TalentDto;
}

export interface GetTalentError {
  type: typeof GET_TALENT_ERROR;
  payload: boolean | string;
}

export interface NewTalent {
  type: typeof NEW_TALENT;
  payload: TalentDtoCreate;
}

export interface NewTalentSuccess {
  type: typeof NEW_TALENT_SUCCESS;
  payload: TalentDto;
}

export interface NewTalentError {
  type: typeof NEW_TALENT_ERROR;
  payload: boolean | string;
}

export interface ResetNewTalent {
  type: typeof RESET_NEW_TALENT;
  payload: null;
}

export interface UpdateTalent {
  type: typeof UPDATE_TALENT;
  payload: TalentDtoUpdate;
}

export interface UpdateTalentSuccess {
  type: typeof UPDATE_TALENT_SUCCESS;
  payload: TalentDto;
}

export interface UpdateTalentError {
  type: typeof UPDATE_TALENT_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateTalent {
  type: typeof RESET_UPDATE_TALENT;
  payload: null;
}

export interface DeleteTalent {
  type: typeof DELETE_TALENT;
  payload: { id: string };
}

export interface DeleteTalentSuccess {
  type: typeof DELETE_TALENT_SUCCESS;
  payload: null;
}

export interface DeleteTalentError {
  type: typeof DELETE_TALENT_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteTalent {
  type: typeof RESET_DELETE_TALENT;
  payload: null;
}

export interface State {
  allTalents: {
    data: TalentDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  talent: {
    data: TalentDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newTalent: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  deleteTalent: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateTalent: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  allTalents: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  talent: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newTalent: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  deleteTalent: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateTalent: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllTalents
  | GetAllTalentsSuccess
  | GetAllTalentsError
  | GetTalent
  | GetTalentSuccess
  | GetTalentError
  | NewTalent
  | NewTalentSuccess
  | NewTalentError
  | ResetNewTalent
  | UpdateTalent
  | UpdateTalentSuccess
  | UpdateTalentError
  | ResetUpdateTalent
  | DeleteTalent
  | DeleteTalentSuccess
  | DeleteTalentError
  | ResetDeleteTalent
  | UpdateShownCohort
  | ResetGetAllTalentData
  | ResetGetOneTalentData;
