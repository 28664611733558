import { ImageDto } from "../../types/image.dto";
import { PaginationDto } from "../../types/pagination.dto";

export const GET_ALL_IMAGES = "GET_ALL_IMAGE";
export const GET_ALL_IMAGES_ERROR = "GET_ALL_IMAGE_ERROR";
export const GET_ALL_IMAGES_SUCCESS = "GET_ALL_IMAGE_SUCCESS";
export const GET_IMAGE = "GET_IMAGE";
export const GET_IMAGE_ERROR = "GET_IMAGE_ERROR";
export const GET_IMAGE_SUCCESS = "GET_IMAGE_SUCCESS";
export const RESET_GET_IMAGE = "RESET_GET_IMAGE";
export const SET_GET_IMAGE = "SET_GET_IMAGE";
export const NEW_IMAGE = "NEW_IMAGE";
export const NEW_IMAGE_ERROR = "NEW_IMAGE_ERROR";
export const NEW_IMAGE_SUCCESS = "NEW_IMAGE_SUCCESS";
export const RESET_NEW_IMAGE = "RESET_NEW_Image";
export const UPDATE_IMAGE = "UPDATE_IMAGE";
export const UPDATE_IMAGE_ERROR = "UPDATE_IMAGE_ERROR";
export const UPDATE_IMAGE_SUCCESS = "UPDATE_IMAGE_SUCCESS";
export const RESET_UPDATE_IMAGE = "RESET_UPDATE_IMAGE";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const DELETE_IMAGE_ERROR = "DELETE_IMAGE_ERROR";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const RESET_DELETE_IMAGE = "RESET_DELETE_IMAGE";
export const UPLOAD_MEDIA = "UPLOAD_MEDIA";
export const UPDATE_IMAGE_LOADING = "UPDATE_IMAGE_LOADING";
export const ADD_USER_TO_IMAGE = "ADD_USER_TO_IMAGE";
export const SHARED_IMAGE = "SHARED_IMAGE";
export const QUERY_IMAGE = "QUERY_IMAGE";
export const QUERY_IMAGE_SUCCESS = "QUERY_IMAGE_SUCCESS";
export const QUERY_IMAGE_ERROR = "QUERY_IMAGE_ERROR";
export const RESET_QUERY_IMAGE = "RESET_QUERY_IMAGE";
export const SAVED_IMAGE = "SAVED_IMAGE";
export const ADD_VIEW_IMAGE = "ADD_VIEW_IMAGE";
export const ADD_CLICK_IMAGE = "ADD_CLICK_IMAGE";
export const UPDATE_ONE_IMAGE = "UPDATE_ONE_IMAGE";
export const UPDATE_ONE_IMAGE_SUCCESS = "UPDATE_ONE_IMAGE_SUCCESS";
export const UPDATE_ONE_IMAGE_ERROR = "UPDATE_ONE_IMAGE_ERROR";
export const RESET_UPDATE_ONE_IMAGE = "RESET_UPDATE_ONE_IMAGE";
export const MANAGE_LIKE_DISLIKE_IMAGE = "MANAGE_LIKE_DISLIKE_IMAGE";
export const UPDATE_CURRENT_IMAGE_LIST = "UPDATE_CURRENT_IMAGE_LIST";
export const GET_IMAGES_OFFSET = "GET_IMAGES_OFFSET";
export const GET_IMAGES_OFFSET_SUCCESS = "GET_IMAGES_OFFSET_SUCCESS";
export const GET_IMAGES_OFFSET_ERROR = "GET_IMAGES_OFFSET_ERROR";
export const RESET_GET_IMAGES_OFFSET = "RESET_GET_IMAGES_OFFSET";
export const RESET_GET_ALL_IMAGE_DATA = "RESET_GET_ALL_IMAGE_DATA";
export const RESET_GET_ONE_IMAGE_DATA = "RESET_GET_ONE_IMAGE_DATA";
export const UPDATE_ITEM_LIST_IMAGE = "UPDATE_ITEM_LIST_IMAGE";

export interface ResetGetAllImageData {
  type: typeof RESET_GET_ALL_IMAGE_DATA;
  payload: null;
}

export interface UpdateItemListImage {
  type: typeof UPDATE_ITEM_LIST_IMAGE;
  payload: any;
}

export interface ResetGetOneImageData {
  type: typeof RESET_GET_ONE_IMAGE_DATA;
  payload: null;
}

export interface GetImagesOffset {
  type: typeof GET_IMAGES_OFFSET;
  payload: { limit: number; offset: number };
}

export interface GetImagesOffsetError {
  type: typeof GET_IMAGES_OFFSET_ERROR;
  payload: string | boolean;
}

export interface GetImagesOffsetSuccess {
  type: typeof GET_IMAGES_OFFSET_SUCCESS;
  payload: ImageDto[];
}

export interface ResetGetImagesOffset {
  type: typeof RESET_GET_IMAGES_OFFSET;
  payload: null;
}
export interface ManageLikeDislikeImage {
  type: typeof MANAGE_LIKE_DISLIKE_IMAGE;
  payload: {
    _id: string;
    user: string;
    condition: "like" | "dislike";
    action: "add" | "remove";
  };
}

export interface UpdateCurrentImageList {
  type: typeof UPDATE_CURRENT_IMAGE_LIST;
  payload: ImageDto[];
}

export interface UpdateOneImage {
  type: typeof UPDATE_ONE_IMAGE;
  payload: any;
}
export interface UpdateOneImageSuccess {
  type: typeof UPDATE_ONE_IMAGE_SUCCESS;
  payload: ImageDto;
}
export interface UpdateOneImageError {
  type: typeof UPDATE_ONE_IMAGE_ERROR;
  payload: boolean | string;
}
export interface ResetUpdateOneImage {
  type: typeof RESET_UPDATE_ONE_IMAGE;
  payload: null;
}

export interface AddViewImage {
  type: typeof ADD_VIEW_IMAGE;
  payload: { _id: string };
}

export interface AddClickImage {
  type: typeof ADD_CLICK_IMAGE;
  payload: { _id: string };
}

export interface SharedImage {
  type: typeof SHARED_IMAGE;
  payload: any;
}
export interface GetAllImages {
  type: typeof GET_ALL_IMAGES;
  payload?: PaginationDto;
}

export interface GetAllImagesSuccess {
  type: typeof GET_ALL_IMAGES_SUCCESS;
  payload: ImageDto[];
}

export interface SetGetImage {
  type: typeof SET_GET_IMAGE;
  payload: ImageDto | null;
}

export interface QueryImage {
  type: typeof QUERY_IMAGE;
  payload: string;
}
export interface QueryImageSuccess {
  type: typeof QUERY_IMAGE_SUCCESS;
  payload: ImageDto[];
}
export interface QueryImageError {
  type: typeof QUERY_IMAGE_ERROR;
  payload: string | boolean;
}
export interface ResetQueryImage {
  type: typeof RESET_QUERY_IMAGE;
  payload: null;
}

export interface GetAllImagesError {
  type: typeof GET_ALL_IMAGES_ERROR;
  payload: boolean | string;
}

export interface AddUserToImage {
  type: typeof ADD_USER_TO_IMAGE;
  payload: any;
}

export interface SaveImage {
  type: typeof SAVED_IMAGE;
  payload: { condition: string; _id: string };
}

export interface GetImage {
  type: typeof GET_IMAGE;
  payload: { id: string };
}

export interface GetImageSuccess {
  type: typeof GET_IMAGE_SUCCESS;
  payload: ImageDto;
}

export interface GetImageError {
  type: typeof GET_IMAGE_ERROR;
  payload: boolean | string;
}

export interface NewImage {
  type: typeof NEW_IMAGE;
  payload: ImageDto;
}

export interface NewIMAGEuccess {
  type: typeof NEW_IMAGE_SUCCESS;
  payload: ImageDto;
}

export interface ResetGetImage {
  type: typeof RESET_GET_IMAGE;
  payload: null;
}

export interface NewImageError {
  type: typeof NEW_IMAGE_ERROR;
  payload: boolean | string;
}

export interface UploadMedia {
  type: typeof UPLOAD_MEDIA;
  payload: any;
}

export interface ResetNewImage {
  type: typeof RESET_NEW_IMAGE;
  payload: null;
}

export interface UpdateImage {
  type: typeof UPDATE_IMAGE;
  payload: any;
}

export interface UpdateImageLoading {
  type: typeof UPDATE_IMAGE_LOADING;
  payload: ImageDto;
}

export interface UpdateIMAGEuccess {
  type: typeof UPDATE_IMAGE_SUCCESS;
  payload: ImageDto;
}

export interface UpdateImageError {
  type: typeof UPDATE_IMAGE_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateImage {
  type: typeof RESET_UPDATE_IMAGE;
  payload: null;
}

export interface DeleteImage {
  type: typeof DELETE_IMAGE;
  payload: { _id: string };
}

export interface DeleteIMAGEuccess {
  type: typeof DELETE_IMAGE_SUCCESS;
  payload: null;
}

export interface DeleteImageError {
  type: typeof DELETE_IMAGE_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteImage {
  type: typeof RESET_DELETE_IMAGE;
  payload: null;
}

export interface State {
  all: {
    data: ImageDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  image: {
    data: ImageDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  query: {
    data: ImageDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  new: {
    data: ImageDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  offSet: {
    data: ImageDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    data: null | ImageDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateOne: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  image: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  new: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  offSet: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateOne: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  query: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllImages
  | GetAllImagesSuccess
  | GetAllImagesError
  | GetImage
  | GetImageSuccess
  | GetImageError
  | NewImage
  | NewIMAGEuccess
  | NewImageError
  | ResetNewImage
  | UpdateImage
  | UpdateIMAGEuccess
  | UpdateImageError
  | ResetUpdateImage
  | DeleteImage
  | DeleteIMAGEuccess
  | DeleteImageError
  | ResetDeleteImage
  | UploadMedia
  | UpdateImageLoading
  | ResetGetImage
  | AddUserToImage
  | SharedImage
  | SetGetImage
  | QueryImage
  | QueryImageSuccess
  | QueryImageError
  | ResetQueryImage
  | SaveImage
  | AddViewImage
  | UpdateOneImage
  | UpdateOneImageSuccess
  | UpdateOneImageError
  | ResetUpdateOneImage
  | AddClickImage
  | ManageLikeDislikeImage
  | UpdateCurrentImageList
  | GetImagesOffset
  | GetImagesOffsetError
  | GetImagesOffsetSuccess
  | ResetGetImagesOffset
  | ResetGetAllImageData
  | ResetGetOneImageData
  | UpdateItemListImage;
