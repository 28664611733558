import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Document, Page } from "react-pdf";

import {
  Wrapper,
  CurrentSlide,
  FullScreenWrapper,
  ButtonArrow,
  Buttons,
} from "./style";
import {
  ArrowMobileButton,
  FullScreenButton,
} from "../../../../../../components/SlideshowPlayer/styles";
import { BsFullscreen } from "react-icons/bs";
import Loader from "../../../../../../components/Loader";
import axios from "axios";
import {
  FlexContainer,
  Separator,
} from "../../../../../../components/StyledComponents";
import "./style.css";
import { useWindowSize } from "../../../../../../hooks/useWindowScreen";
import { useTheme } from "styled-components";
interface ComponentProps {
  src: string;
  id?: string;
  startFrom?: number;
  style?: CSSProperties;
  currentPage: number;
  handleKeyDown;
  handlePrevPage;
  handleNextPage;
  numPages?: number;
  setFullScreen;
}

const Component = ({
  src,
  currentPage,
  handleKeyDown,
  handlePrevPage,
  handleNextPage,
  numPages,
  style,
  setFullScreen,
}: ComponentProps) => {
  const pdfRef: any = useRef(null);
  const theme = useTheme();
  const { isMobile } = useWindowSize();
  const [currentPdf, setCurrentPdf] = useState<any>();

  useEffect(() => {
    axios({
      method: "GET",
      url: src,
      responseType: "arraybuffer",
    })
      .then((response) => {
        setCurrentPdf(response.data);
      })
      .catch((err) => setCurrentPdf(undefined));
  }, [src]);
  return (
    <FullScreenWrapper>
      {currentPdf ? (
        <>
          <Wrapper
            ref={pdfRef}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            style={{
              ...style,
              height: isMobile ? "calc(100vh - 120px)" : "calc(100vh - 60px)",
              maxWidth: "900px",
              margin: "0 auto",
            }}
          >
            <Document
              className={
                isMobile ? "react-pdf-page-mobile" : "react-pdf-page-desktop"
              }
              file={currentPdf}
              noData={<h2>No se encontro</h2>}
            >
              <Page
                className={"react-pdf-page-one"}
                pageNumber={currentPage}
                scale={1.1}
                key={`page_${currentPage}`}
              />
            </Document>

            {!isMobile && (
              <>
                <ButtonArrow className="left" onClick={handlePrevPage}>
                  <IoIosArrowBack size={30} color="white" />
                </ButtonArrow>
                <ButtonArrow className="right" onClick={handleNextPage}>
                  <IoIosArrowForward size={30} color="white" />
                </ButtonArrow>
              </>
            )}
            <FullScreenButton
              onClick={() => {
                setFullScreen(false);
              }}
            >
              <BsFullscreen
                color={
                  isMobile ? theme.colors.LightBlue : theme.colors.DarkGray
                }
                size={isMobile ? 20 : 42}
              ></BsFullscreen>
            </FullScreenButton>
          </Wrapper>
          {!isMobile ? (
            <CurrentSlide>
              {currentPage} / {numPages}
            </CurrentSlide>
          ) : (
            <>
              <Separator size={5}></Separator>
              <FlexContainer justify="center">
                <FlexContainer gap="6px" align="center">
                  <ArrowMobileButton onClick={handlePrevPage}>
                    <IoIosArrowBack size={8} color={theme.colors.LightBlue} />
                  </ArrowMobileButton>
                  <span>{`${currentPage} de ${numPages}`}</span>
                  <ArrowMobileButton onClick={handleNextPage}>
                    <IoIosArrowForward
                      size={8}
                      color={theme.colors.LightBlue}
                    />
                  </ArrowMobileButton>
                </FlexContainer>
              </FlexContainer>
            </>
          )}
        </>
      ) : (
        <Loader color="LightBlue"></Loader>
      )}
    </FullScreenWrapper>
  );
};

export default Component;
