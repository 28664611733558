import * as Yup from "yup";
import { number as cardNumberValidator } from "card-validator";
export const schema = Yup.object().shape({
  cardNumber: Yup.string()
    .required("Card number is required")
    .test("valid-card-number", "Invalid card number", (value: any) => {
      const cardNumber = value.replace(/ /g, ""); // Remove spaces

      if (cardNumber.length !== 16 || !/^\d+$/.test(cardNumber)) {
        return false;
      }

      return cardNumberValidator(cardNumber).isValid;
    }),

  expirationDate: Yup.string()
    .required()
    .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, "Invalid expiration date"),
  securityCode: Yup.string()
    .required("CVC is required")
    .matches(/^[0-9]{3,4}$/, "Invalid CVC"),
  cardholderName: Yup.string().required("Cardholder name is required"),
});

interface FormValues {
  cardNumber: string;
  expirationDate: string;
  securityCode: string;
  cardholderName: string;
}

export const initialValues: FormValues = {
  cardNumber: "",
  expirationDate: "",
  securityCode: "",
  cardholderName: "",
};
