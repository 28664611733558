import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Share from "../../../../components/ShareLink";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getRoadmap,
  manageLikeDislikeRoadmap,
  resetGetRoadmap,
  resetUpdateRoadmap,
  savedRoadmap,
} from "../../../../store/actions/roadmap";
import { RoadmapDto } from "../../../../types/roadmap.dto";
import {
  FlexContainer,
  Separator,
} from "../../../../components/StyledComponents";
import {
  AiFillDislike,
  AiOutlineCloudDownload,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";
import { FaSave, FaShare } from "react-icons/fa";
import { BiLink } from "react-icons/bi";
import { BsFullscreen } from "react-icons/bs";
import ReactFlow, { Controls, Background } from "reactflow";
import SideBar from "./components/SideBar";
import { showModal } from "../../../../store/actions/modal";
import Loader from "../../../../components/Loader";
import { StateDto } from "../../../../types/states.dto";
import { toast } from "react-toastify";
import { UsersDto } from "../../../../types/users.dto";
/* import {
  RoadmapTitle,
  SidebarWrapper,
  SidebarWrapperRelative,
  ToggleStyle,
  ToggleStyleIcon,
  WrapperPage,
} from "./style"; */
import ShareModal from "./components/ShareModal";
import { PDFExport } from "@progress/kendo-react-pdf";
import { RoadmapTitle, SidebarWrapper, SidebarWrapperRelative } from "./style";
import screenfull from "screenfull";
import PreviewNodeModal from "./components/PreviewNodeModal";
import "reactflow/dist/style.css";
import {
  LikeDislikeBox,
  LikeDislikeBoxNumber,
  LikeDislikeText,
} from "../../../Cohort/Home/style";
import { overThousandFixed } from "../../../../helpers/number-fixed";
import CommentSection from "../../../../components/CommentSection";
import {
  addClick,
  addView,
  userTime,
} from "../../../../store/actions/userTimes";
import useTrackTimeAndAPI from "../../../../hooks/useTrackTimeAndAPI";
import EmptyState from "./components/EmptyState";

const Component = ({
  roadmap,
  updateStatesRoadmap,
  users,
  deleteRoadmapStates,
  theme,
  user,
  getRoadmapStates,
}: {
  roadmap: RoadmapDto;
  updateStatesRoadmap: StateDto;
  users: UsersDto[];
  deleteRoadmapStates: StateDto;
  theme: "light" | "dark";
  user: UsersDto;
  getRoadmapStates: StateDto;
}) => {
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);
  const [isFullscreen, setIsFullScreen] = useState<boolean>(false);
  const [selectedNode, setSelectedNode] = useState<any>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const params = useParams();
  const dispatch = useDispatch();
  let editorRef = React.useRef<any>(null);
  let screenDiagramRef: any = React.useRef(null);

  useEffect(() => {
    if (params.id && !!user) {
      dispatch(getRoadmap({ _id: params.id }));
      dispatch(addView({ id: params.id, user: user._id, type: "CONTENT" }));
    }
  }, [params.id, user]);

  useEffect(() => {
    if (getRoadmapStates.error) {
      toast.error(`${getRoadmapStates.error}`);
      dispatch(resetGetRoadmap());
    }
  }, [getRoadmapStates, dispatch]);

  useEffect(() => {
    if (updateStatesRoadmap.success) {
      dispatch(getRoadmap(roadmap._id));
      toast.success(`👌🏼 Roadmap guardado`);
      dispatch(resetUpdateRoadmap());
    }
    if (updateStatesRoadmap.error) {
      toast.error(updateStatesRoadmap.error);
    }
  }, [updateStatesRoadmap]);

  const apiAction = (timeSpent) => {
    dispatch(
      userTime({ ...timeSpent, id: params.id, user: user._id, type: "ROADMAP" })
    ); // Dispatch action to update Redux store or make API request
  };
  useTrackTimeAndAPI(apiAction, params.id && user);

  const handleExportWithComponent = (event) => {
    editorRef.current.save();
  };

  const isSaved = () => roadmap?.savedBy.includes(user._id);

  const menu = {
    ADMIN: [
      /*  {
        Icon: FaShare,
        toolTipName: "Compartir",
        onAvailable: () => !!roadmap.available && !!roadmap.published,
        onClick: () => {
          dispatch(showModal("share-user-roadmap"));
        },
      }, */
      {
        Icon: BiLink,
        toolTipName: "Copiar Link",
        onClick: () => {
          navigator.clipboard.writeText(
            `${process.env.REACT_APP_URL}/dashboard/roadmaps/${roadmap._id}`
          );
          toast.success(`Link copiado`);
          dispatch(
            addClick({
              id: roadmap._id,
              user: user._id,
              type: "CONTENT",
            })
          );
        },
      },

      /*  {
        Icon: FaSave,
        toolTipName: isSaved() ? "Guardado" : "Guardar",
        onClick: () => {
          dispatch(savedRoadmap({ _id: roadmap._id, condition: "save" }));
        },
        activeFunction: () => isSaved(),
        onClickActive: () => {
          dispatch(savedRoadmap({ _id: roadmap._id, condition: "unsave" }));
        },
      }, */
      /*  {
        Icon: AiOutlineCloudDownload,
        toolTipName: "Descargar",
        onClick: (ev) => {
          handleExportWithComponent(ev);
          toast.success(`Roadmap Descargado`);
        },
      }, */
    ],
    OBSERVER: {},
  };

  const fitViewOptions = {
    padding: 3,
  };

  return (
    <>
      {!roadmap ? (
        <EmptyState></EmptyState>
      ) : (
        <>
          <FlexContainer
            ref={screenDiagramRef}
            style={{
              backgroundColor: isFullscreen ? "rgb(245, 245, 245)" : "",
            }}
            justify="space-between"
            gap="10px"
          >
            <FlexContainer
              style={{ width: "100%" }}
              direction="column"
              gap="10px"
            >
              <FlexContainer justify="space-between" align="center">
                <RoadmapTitle>{roadmap.name}</RoadmapTitle>
                <FlexContainer gap="12px" align="center">
                  <LikeDislikeBox
                    className={
                      !!roadmap?.likes?.find((like) => like.user === user._id)
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      if (
                        roadmap?.likes?.find((like) => like.user === user._id)
                      ) {
                        dispatch(
                          manageLikeDislikeRoadmap({
                            _id: roadmap._id,
                            condition: "like",
                            action: "remove",
                            individual: true,
                            notReload: true,
                          })
                        );
                      } else {
                        dispatch(
                          manageLikeDislikeRoadmap({
                            _id: roadmap._id,
                            condition: "like",
                            action: "add",
                            individual: true,
                            notReload: true,
                          })
                        );
                      }
                    }}
                  >
                    <FlexContainer gap="3px" align="center">
                      <div
                        className="like-dislike-icon"
                        style={{ background: "#fff", padding: "4px" }}
                      >
                        <AiOutlineLike size={15} fill={"#2496ed"} />
                      </div>
                      <LikeDislikeBoxNumber className="count-likes">
                        <LikeDislikeText>
                          {overThousandFixed(roadmap.likes.length)}
                        </LikeDislikeText>
                      </LikeDislikeBoxNumber>
                    </FlexContainer>
                  </LikeDislikeBox>

                  <LikeDislikeBox
                    className={
                      !!roadmap.dislikes.find((like) => like.user === user._id)
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      if (
                        roadmap.dislikes.find((like) => like.user === user._id)
                      ) {
                        dispatch(
                          manageLikeDislikeRoadmap({
                            _id: roadmap._id,
                            condition: "dislike",
                            action: "remove",
                            individual: true,
                            notReload: true,
                          })
                        );
                        dispatch(
                          addClick({
                            id: roadmap._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                      } else {
                        dispatch(
                          manageLikeDislikeRoadmap({
                            _id: roadmap._id,
                            condition: "dislike",
                            action: "add",
                            individual: true,
                            notReload: true,
                          })
                        );
                        dispatch(
                          addClick({
                            id: roadmap._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                      }
                    }}
                  >
                    <FlexContainer gap="3px" align="center">
                      <div
                        className="like-dislike-icon"
                        style={{
                          padding: "7px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {roadmap.dislikes.find(
                          (like) => like.user === user._id
                        ) ? (
                          <AiFillDislike
                            fill={"#fff"}
                            size={15}
                          ></AiFillDislike>
                        ) : (
                          <AiOutlineDislike
                            fill={"#2496ed"}
                            size={15}
                          ></AiOutlineDislike>
                        )}
                      </div>
                    </FlexContainer>
                  </LikeDislikeBox>

                  <Share />
                </FlexContainer>
              </FlexContainer>
              <PDFExport fileName={`${roadmap.name}`} ref={editorRef}>
                <div
                  style={{
                    height: "600px",
                    width: "100%",
                    background: "#fff",
                    position: "relative",
                  }}
                >
                  <BsFullscreen
                    size={20}
                    onClick={() => {
                      dispatch(
                        addClick({
                          id: roadmap._id,
                          user: user._id,
                          type: "CONTENT",
                        })
                      );
                      if (screenfull.isFullscreen && screenDiagramRef.current) {
                        screenfull.exit();
                        setIsFullScreen(false);
                      } else {
                        screenfull.request(screenDiagramRef.current);
                        setIsFullScreen(true);
                      }
                    }}
                    style={{
                      position: "absolute",
                      left: "10px",
                      top: "10px",
                      zIndex: 20,
                      cursor: "pointer",
                    }}
                  ></BsFullscreen>
                  {selectedNode && (
                    <PreviewNodeModal
                      setIsVisible={setIsVisible}
                      isVisible={isVisible}
                      node={selectedNode}
                      setSelectedNode={setSelectedNode}
                    ></PreviewNodeModal>
                  )}
                  <ReactFlow
                    nodes={roadmap.graph.nodes}
                    edges={roadmap.graph.edges}
                    fitView
                    fitViewOptions={fitViewOptions}
                    nodesConnectable={false}
                    nodesDraggable={false}
                    onNodeClick={(ev, node) => {
                      dispatch(
                        addClick({
                          id: roadmap._id,
                          user: user._id,
                          type: "CONTENT",
                        })
                      );
                      setIsVisible(true);
                      setSelectedNode(node);
                    }}
                  >
                    <Controls></Controls>
                    <Background color="#1573e5"></Background>
                  </ReactFlow>
                </div>
              </PDFExport>
            </FlexContainer>
            <SidebarWrapperRelative>
              <SidebarWrapper className={!!openDropDown ? "active" : ""}>
                <SideBar
                  openDropDown={openDropDown}
                  setOpenDropDown={setOpenDropDown}
                  menu={menu["ADMIN"]}
                  openValue={roadmap?._id}
                ></SideBar>
              </SidebarWrapper>
            </SidebarWrapperRelative>
          </FlexContainer>
          <Separator size={20}></Separator>
          <CommentSection
            itemId={roadmap._id}
            section="content"
          ></CommentSection>
        </>
      )}
      {!!roadmap && <ShareModal roadmap={roadmap}></ShareModal>}
    </>
  );
};

const states = ({ roadmapStore, usersStore, themeStore, userStore }) => {
  const { data: roadmap, states: getRoadmapStates } = roadmapStore.roadmap;
  const { states: updateStatesRoadmap } = roadmapStore.update;
  const { data: users } = usersStore.all;
  const { states: deleteRoadmapStates } = roadmapStore.delete;
  const { theme } = themeStore;
  const { data: user } = userStore;
  return {
    roadmap,
    updateStatesRoadmap,
    users,
    deleteRoadmapStates,
    theme,
    user,
    getRoadmapStates,
  };
};

export default connect(states)(Component);
