import React, { useEffect, useState } from "react";
import FilterByName from "./components/FilterByName";
import {
  getAllRoadmaps,
  queryRoadmap,
  resetGetAllRoadmaps,
  savedRoadmap,
} from "../../../../store/actions/roadmap";
import { connect, useDispatch } from "react-redux";
import { RoadmapDto } from "../../../../types/roadmap.dto";
import {
  Center,
  DotContent,
  FlexContainer,
  Separator,
} from "../../../../components/StyledComponents";
import { AcademyDto } from "../../../../types/academy.dto";
import { FaSave, FaShare } from "react-icons/fa";
import { BiLink } from "react-icons/bi";
import { toast } from "react-toastify";
import { AiOutlineCloudDownload } from "react-icons/ai";
import academyActions from "../../../../store/actions/academies";
import Loader from "../../../../components/Loader";
import { StateDto } from "../../../../types/states.dto";
import { saveAs } from "file-saver";
import usersActions from "../../../../store/actions/users";
import ListedRoadmaps from "./components/ListedRoadmaps";
import ShareModal from "./components/ShareModal";
import { showModal } from "../../../../store/actions/modal";
import { UserDto } from "../../../../types/user.dto";
import {
  GridCard,
  GridCardImageContainer,
  GridCardImage,
  GridCardSmallContainer,
  GridCardTitle,
  GridComment,
} from "../../../../components/Layout/Dashboard/styles";
import { DateFromNow } from "../../../../helpers/format-date";
import { GridStructure, GridStructurePlane } from "../../../SignIn/styles";
import { GridMobileImageCard } from "../../style";
import { useNavigate } from "react-router-dom";
import SideBar from "./components/SideBar";
import InfinityScroll from "../../../../components/InfinityScroll";
import ToggleSlider from "../../../../components/ToggleSlider";
import FilterButton from "./components/FilterButton";
import { addClick } from "../../../../store/actions/userTimes";
import ContentCard from "../../../../components/ContentCard";
import { RiOrganizationChart } from "react-icons/ri";

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  queryRoadmaps,
  roadmaps,
  academies,
  queryRoadmapsStates,
  user,
  roadmapsStates,
}: {
  queryRoadmaps: RoadmapDto[];
  roadmaps: RoadmapDto[];
  academies: AcademyDto[];
  queryRoadmapsStates: StateDto;
  user: UserDto;
  roadmapsStates: StateDto;
}) => {
  const dispatch = useDispatch();
  const [queryMode, setQueryMode] = useState<string | null>(null);
  const [query, setQuery] = useState<string | null>(null);
  const [currentRoadmapsList, setCurrentRoadmapsList] = useState<RoadmapDto[]>(
    []
  );
  const [openDropDown, setOpenDropDown] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [
    filteredCurrentRoadmapsListValues,
    setFilteredCurrentRoadmapsListValues,
  ] = useState<RoadmapDto[]>([]);
  const navigate = useNavigate();
  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 9,
      offset: 9 * Math.floor(page / 2),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        published: true,
      },
    };
    const sortBy = page % 2 === 0 ? "createdAt" : "viewCount";

    const sortDirection = "desc";
    dispatch(
      getAllRoadmaps({
        ...payload,
        sortBy,
        sortDirection,
        ...(queryMode && { keyword: query }),
      })
    );
  };
  const filterValues: any = [
    {
      fieldName: "private",
      value: "no",
      name: "Todos",
    },
    {
      fieldName: "private",
      value: true,
      name: "Academias Privadas",
      special: (array, value, currentItem) => {
        return currentItem.academy?.private === value;
      },
    },
    {
      fieldName: "private",
      value: false,
      name: "Academias Publicas",
      special: (array, value, currentItem) => {
        return currentItem.academy?.private === value;
      },
    },
  ];

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter((applyedFilter) =>
        !Array.isArray(newFilter.field)
          ? applyedFilter.field === newFilter.field
          : newFilter.field.includes(applyedFilter.field)
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    if (filterRemoved) {
      /**
       * Se elimina el filtro
       */
      if (Array.isArray(newFilter.field)) {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => !newFilter.field.includes(applyedFilter.field)
          ),
        ]);
      } else {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => applyedFilter.field !== newFilter.field
          ),
        ]);
      }
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };
  const handleQuery = (ev) => {
    const value = ev.target.value;
    setQuery(value.length === 0 ? "" : value);
  };
  const searchQuery = (ev) => {
    if (ev.keyCode === 13) {
      /*  dispatch(queryImage({ keyword: query })); */
      setQueryMode(query);
    }
  };

  useEffect(() => {
    if (queryMode) {
      setPage(0);
      setIsLoading(false);
      setThereAreItems(true);
      setCurrentRoadmapsList([]);
    }
  }, [queryMode]);
  useEffect(() => {
    const filteredTalents = currentRoadmapsList?.filter(
      (currentTalentValues) => {
        const thereAreFilters =
          applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
            .length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter(
            (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
          )
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value,
            currentTalentValues
          );
        });
        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentRoadmapsListValues(initResult);
  }, [applyedFilters, currentRoadmapsList]);
  useEffect(() => {
    if (!isLoading && !!roadmaps) {
      setCurrentRoadmapsList((state) => [...state, ...roadmaps]);
    }
    setThereAreItems(!!roadmaps && roadmaps?.length > 0);
  }, [roadmaps, isLoading]);

  useEffect(() => {
    setIsLoading(roadmapsStates.loading);
  }, [roadmapsStates]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllRoadmaps());
    };
  }, []);

  const menu = (item) => [
    /* {
      Icon: FaShare,
      onAvailable: () => !!item.available && !!item.published,
      onClick: () => {
        dispatch(showModal("share-user-video"));
      },
    }, */
    {
      Icon: BiLink,
      onClick: () => {
        navigator.clipboard.writeText(
          `https://academia.catel.org.ar/dashboard/video/${item._id}`
        );
        toast.success(`Link copiado`);
        dispatch(
          addClick({
            id: item._id,
            user: user._id,
            type: "CONTENT",
          })
        );
      },
    },

    /*  {
      Icon: FaSave,
      activeFunction: () => item.savedBy.includes(user._id),
      onClick: () => {
        dispatch(
          savedRoadmap({ _id: item._id, condition: "save", __v: item.__v })
        );
      },
      onClickActive: () => {
        dispatch(
          savedRoadmap({ _id: item._id, condition: "unsave", __v: item.__v })
        );
      },
    }, */
    /* {
      Icon: AiOutlineCloudDownload,
      onClick: (ev) => {
        saveAs(item.url, `${item.name}.mp4`);
        toast.success(`Cdoc Descargado`);
      },
    }, */
  ];
  return (
    <>
      <FilterByName
        searchQuery={searchQuery}
        setFilter={handleQuery}
        value={query}
      ></FilterByName>
      <Separator size={12}></Separator>
      {/*  <ToggleSlider>
        {filterValues.map((filterValue) => {
          return (
            <FilterButton
              setFilter={buildFilters}
              name={filterValue.name}
              fieldName={filterValue.fieldName}
              value={filterValue.value}
              filters={applyedFilters}
              {...((!!filterValue.special || !!filterValue.groupal) && {
                special: !!filterValue.special
                  ? { name: "special", function: filterValue.special }
                  : { name: "groupal", function: filterValue.groupal },
              })}
            ></FilterButton>
          );
        })}
      </ToggleSlider> */}
      <Separator size={12}></Separator>
      <InfinityScroll
        action={fetchData}
        page={page}
        setPage={setPage}
        data={filteredCurrentRoadmapsListValues}
        active={thereAreItems}
        isLoading={isLoading}
      >
        <GridStructurePlane>
          {filteredCurrentRoadmapsListValues.map((item) => {
            return (
              <FlexContainer
                style={{ maxWidth: "600px", margin: "0 auto" }}
                direction="column"
                gap="10px"
              >
                <ContentCard
                  ContentTypeLogo={{ icon: RiOrganizationChart }}
                  key={item._id}
                  item={item}
                  logoOnclick={(item) => {
                    dispatch(
                      addClick({
                        id: item.academy?._id,
                        user: user._id,
                        type: "ACADEMY",
                      })
                    );
                    navigate(`/dashboard/academy/${item.academy?._id}`);
                  }}
                  cardOnClick={(item) => {
                    dispatch(
                      addClick({
                        id: item._id,
                        user: user._id,
                        type: "CONTENT",
                      })
                    );
                    navigate(`/dashboard/roadmaps/${item._id}`);
                  }}
                ></ContentCard>
                <SideBar
                  openValue={item._id}
                  menu={menu(item)}
                  setOpenDropDown={setOpenDropDown}
                  openDropDown={openDropDown}
                ></SideBar>
              </FlexContainer>
            );
          })}
        </GridStructurePlane>
      </InfinityScroll>
    </>
  );
};

const states = ({ roadmapStore, academyStore, userStore }) => {
  const { data: queryRoadmaps, states: queryRoadmapsStates } =
    roadmapStore.query;
  const { data: roadmaps, states: roadmapsStates } = roadmapStore.all;
  const { data: academies } = academyStore.all;
  const { data: user } = userStore;
  return {
    queryRoadmaps,
    roadmaps,
    academies,
    queryRoadmapsStates,
    user,
    roadmapsStates,
  };
};

export default connect(states)(Component);
