import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Icon from "../../../components/Icon";
import arrowLeft from "../../../assets/icons/angle-left.svg";
import arrowRight from "../../../assets/icons/angle-right.svg";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getAllMeets } from "../../../store/actions/calendar";
import { MeetDto } from "../../../types/meet.dto";
import Loader from "../../../components/Loader/index";
import { UserDto } from "../../../types/user.dto";
import { CohortDto } from "../../../types/cohort.dto";
import {
  getAllCohorts,
  resetGetAllCohorts,
} from "../../../store/actions/cohort";
import { EventDto } from "../../../types/event.dto";
import { AcademyDto } from "../../../types/academy.dto";
import academyActions from "../../../store/actions/academies";
import ModalGoMeet from "./components/ModalGoMeet";
import "./style.css";
import { showModal } from "../../../store/actions/modal";
import { getAllEvents } from "../../../store/actions/event";
// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer

interface ComponentProps {
  meets: MeetDto[];
  user: UserDto;
  cohorts: CohortDto[];
  events: EventDto[];
  academies: AcademyDto[];
}
const Component = ({
  meets,
  user,
  cohorts,
  events,
  academies,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [meetSelected, setMeetSelected] = useState<MeetDto>();
  useEffect(() => {
    !events && dispatch(getAllEvents({}));
  }, [events, dispatch]);

  useEffect(() => {
    !meets && dispatch(getAllMeets({}));
  }, [meets, dispatch]);

  useEffect(() => {
    !cohorts &&
      dispatch(
        getAllCohorts({
          select: { active: 1, studentsIds: 1, academy: 1, eventsIds: 1 },
        })
      );
  }, [cohorts, dispatch]);
  useEffect(() => {
    !academies && dispatch(academyActions.getAll({}));
  }, [academies, dispatch]);

  useEffect(() => {
    dispatch(resetGetAllCohorts());
    dispatch(academyActions.resetGetAllAcademies());
  }, []);

  if (!meets || !cohorts || !academies || !events) {
    return <Loader color="LightBlue"></Loader>;
  }

  const meetFromAcademies = academies
    ?.filter(
      (academy) =>
        academy.active &&
        academy.users.find((academ) => academ.user === user._id)
    )
    .map((academy) => {
      const meetsByAcademy = meets.filter(
        (meet) => meet.active && meet.eventId.academy === academy._id
      );
      return meetsByAcademy;
    })
    .flat(1);

  const meetFromCohorts = cohorts
    ?.filter((cohort) => cohort.active && cohort.studentsIds.includes(user._id))
    .map((cohort) => {
      const meetsByCohort = meets.filter(
        (meet) => meet.active && meet.eventId.academy === cohort.academy
      );
      return meetsByCohort;
    })
    .flat(1);
  const noRepeat = Array.from(
    new Set([...meetFromAcademies, ...meetFromCohorts].map((meet) => meet._id))
  ).map((meet) => {
    const meetFounded = meets.filter((meetFound) => meetFound._id === meet)[0];
    return meetFounded;
  });
  return (
    <>
      <Calendar
        localizer={localizer}
        events={noRepeat}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={(value: MeetDto) => {
          setMeetSelected(value);
          dispatch(showModal("send-meet"));
        }}
        messages={{
          week: "Semana",
          work_week: "Semana de trabajo",
          day: "Día",
          month: "Mes",
          previous: <Icon icon={arrowLeft} size="20px" color="black" />,
          next: <Icon icon={arrowRight} size="20px" color="black" />,
          today: "Hoy",
          agenda: "Diario",
          showMore: (total) => `+${total} más`,
        }}
      />
      <ModalGoMeet
        title={
          <span>
            {`¿Estas a punto de entrar a una clase del cohort `}
            <span>
              {
                cohorts.filter((cohort) =>
                  cohort.eventsIds.includes(meetSelected?.eventId.academy)
                )[0]?.name
              }
            </span>
            {` de la academia `}
            <span>
              {
                academies.filter(
                  (academy) => academy._id === meetSelected?.eventId.academy
                )[0]?.name
              }
            </span>
          </span>
        }
        meetLink={meetSelected}
      ></ModalGoMeet>
    </>
  );
};

const state = ({
  meetStore,
  userStore,
  cohortStore,
  eventStore,
  academyStore,
}) => {
  const { data: meets } = meetStore.allMeets;
  const { data: user } = userStore;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: events } = eventStore.allEvents;
  const { data: academies } = academyStore.all;
  return {
    meets,
    user,
    cohorts,
    events,
    academies,
  };
};

export default connect(state)(Component);
