import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center } from "../../../../components/StyledComponents";
import Input from "../../../../components/Form/Input";
import Submit from "../../../../components/Form/Submit";
import ReactSelect from "../../../../components/Form/ReactSelect";
import { initialValues, schema } from "../../../../constants/form/student/new";
import { AbilityDto } from "../../../../types/ability.dto";
import { getAllAbilities } from "../../../../store/actions/ability";
import Button from "../../../../components/Button";
import { hideModal } from "../../../../store/actions/modal";
import {
  newStudent,
  resetUpdateStudent,
} from "../../../../store/actions/student";

interface ComponentProps {
  abilities: AbilityDto[];
  updateStudentStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ abilities, updateStudentStates }: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [type, setType] = useState<string>();
  const [currentTypeAbilities, setCurrentTypeAbilities] =
    useState<AbilityDto[]>();

  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(newStudent(values));
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  useEffect(() => {
    if (!!abilities) {
      setCurrentTypeAbilities(
        abilities.filter((ability) => ability.type === type)
      );
    }
  }, [type, abilities]);

  useEffect(() => {
    !abilities && dispatch(getAllAbilities());
  }, []);

  useEffect(() => {
    if (updateStudentStates?.success) {
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetUpdateStudent());
      }, 2000);
    }
  }, [updateStudentStates]);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validateOnChange={formSubmmited}
      validateOnBlur={false}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <form
            className="theme-form"
            onSubmit={(student) => {
              setFormSubmmited(true);
              handleSubmit(student);
            }}
            id="edit-student-form"
          >
            <Input
              name="name"
              error={errors["name"]}
              touched={touched["name"]}
              value={values["name"]}
              type="text"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Nombre",
                marginBottom: 20,
              }}
            />

            <Input
              name="lastName"
              error={errors["lastName"]}
              touched={touched["lastName"]}
              value={values["lastName"]}
              type="text"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Apellido",
                marginBottom: 20,
              }}
            />

            <Input
              name="email"
              error={errors["email"]}
              touched={touched["email"]}
              value={values["email"]}
              type="text"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "E-mail",
                marginBottom: 20,
              }}
            />

            <Input
              name="password"
              error={errors["password"]}
              touched={touched["password"]}
              value={values["password"]}
              type="password"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Contraseña",
                marginBottom: 20,
              }}
            />

            <Input
              name="dni"
              error={errors["dni"]}
              touched={touched["dni"]}
              value={values["dni"]}
              type="number"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "DNI",
                marginBottom: 20,
              }}
            />

            <Input
              name="phone"
              error={errors["phone"]}
              touched={touched["phone"]}
              value={values["phone"]}
              type="string"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Teléfono",
                marginBottom: 20,
              }}
            />

            <ReactSelect
              name="cohort"
              error={errors["cohort"]}
              touched={touched["cohort"]}
              {...(!!currentTypeAbilities && {
                items: currentTypeAbilities?.map((ability) => ({
                  label: ability.name,
                  value: ability._id,
                })),
              })}
              isMulti
              placeholder="Asigna un Co-hort"
              disabled={!abilities}
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Co-hort",
                marginBottom: 20,
              }}
            />

            <Center>
              <Submit
                isSubmmiting={isSubmitting}
                form="edit-student-form"
                color="Primary"
                options={{
                  type: "filled",
                  skin: "primary",
                  size: "lg",
                  marginBottom: "0px",
                }}
              >
                Confirmar
              </Submit>
              <Button
                onClick={closeModal}
                type="button"
                options={{
                  type: "outline",
                  skin: "danger",
                  size: "lg",
                  marginBottom: "0px",
                }}
                style={{ marginLeft: "10px" }}
              >
                Cancelar
              </Button>
            </Center>
          </form>
        );
      }}
    </Formik>
  );
};

const states = ({ abilityStore, studentStore }) => {
  const { data: abilities } = abilityStore.allAbilities;
  const { states: updateStudentStates } = studentStore.updateStudent;

  return {
    abilities,
    updateStudentStates,
  };
};

export default connect(states)(Component);
