import React from "react";
import { FlexContainer, Line, Separator } from "../../../StyledComponents";
import { FaAccusoft, FaUserAlt } from "react-icons/fa";
import { BsFillImageFill } from "react-icons/bs";
import { HeaderDropDownTitle, ListItem, ListItemText } from "../../styles";
import { BiExit } from "react-icons/bi";
import { connect, useDispatch } from "react-redux";
import { signOut } from "../../../../store/actions/auth";
import { UserDto } from "../../../../types/user.dto";
import { showModal } from "../../../../store/actions/modal";
import { useNavigate } from "react-router-dom";

const Component = ({ user }: { user: UserDto }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const items = [
    {
      label: "Mi Pefil",
      link: "/dashboard/profile",
      icon: FaUserAlt,
    },
  ];

  return (
    <>
      {user ? (
        <HeaderDropDownTitle>{`${user.name} ${user.lastName}`}</HeaderDropDownTitle>
      ) : (
        <HeaderDropDownTitle>Invitado</HeaderDropDownTitle>
      )}
      <Separator size={8.5}></Separator>
      <Line height={0.5}></Line>
      <Separator size={7}></Separator>
      {user ? (
        <FlexContainer direction="column" gap="8px">
          {items.map((item) => {
            return (
              <ListItem
                onClick={() => {
                  navigate(`${item.link}`);
                }}
                key={item.label}
              >
                <FlexContainer align="center" gap="7px">
                  <item.icon size={20} color="#2496ed"></item.icon>
                  <ListItemText>{item.label}</ListItemText>
                </FlexContainer>
              </ListItem>
            );
          })}
          <ListItem
            onClick={() => {
              dispatch(signOut());
            }}
          >
            <FlexContainer align="center" gap="7px">
              <BiExit size={22} color="#2496ed"></BiExit>
              <ListItemText>Cerrar Sesión</ListItemText>
            </FlexContainer>
          </ListItem>
        </FlexContainer>
      ) : (
        <ListItem
          onClick={() => {
            dispatch(showModal("sign-in-content"));
          }}
        >
          <FlexContainer align="center" gap="7px">
            <FaUserAlt size={24} color="#2496ed"></FaUserAlt>
            <ListItemText>Ingresar</ListItemText>
          </FlexContainer>
        </ListItem>
      )}
    </>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;
  return { user };
};

export default connect(states)(Component);
