import {
  initialState,
  Actions,
  State,
  GET_ALL_EVENTS,
  GET_ALL_EVENTS_ERROR,
  GET_ALL_EVENTS_SUCCESS,
  GET_EVENT,
  GET_EVENT_ERROR,
  GET_EVENT_SUCCESS,
  UPDATE_EVENT,
  UPDATE_EVENT_ERROR,
  UPDATE_EVENT_SUCCESS,
  DELETE_EVENT,
  DELETE_EVENT_ERROR,
  DELETE_EVENT_SUCCESS,
  NEW_EVENT,
  NEW_EVENT_SUCCESS,
  NEW_EVENT_ERROR,
  RESET_NEW_EVENT,
  RESET_DELETE_EVENT,
  RESET_UPDATE_EVENT,
} from "../types/event";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_EVENTS:
      return {
        ...state,
        allEvents: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_EVENTS_SUCCESS:
      return {
        ...state,
        allEvents: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_EVENTS_ERROR:
      return {
        ...state,
        allEvents: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_EVENT:
      return {
        ...state,
        event: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_EVENT_SUCCESS:
      return {
        ...state,
        event: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_EVENT_ERROR:
      return {
        ...state,
        event: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_EVENT:
      return {
        ...state,
        newEvent: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_EVENT_SUCCESS:
      return {
        ...state,
        newEvent: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_EVENT_ERROR:
      return {
        ...state,
        newEvent: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_EVENT:
      return {
        ...state,
        newEvent: initialState.newEvent,
      };
    case DELETE_EVENT:
      return {
        ...state,
        deleteEvent: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        deleteEvent: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_EVENT_ERROR:
      return {
        ...state,
        deleteEvent: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_EVENT:
      return {
        ...state,
        deleteEvent: initialState.deleteEvent,
      };
    case UPDATE_EVENT:
      return {
        ...state,
        updateEvent: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        updateEvent: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_EVENT_ERROR:
      return {
        ...state,
        updateEvent: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_EVENT:
      return {
        ...state,
        updateEvent: initialState.updateEvent,
      };
    default:
      return state;
  }
};

export default reducers;
