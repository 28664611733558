import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Button from "../../../components/Button";
import Grid from "../../../components/Grid";
import Share from "../../../components/ShareLink";
import { Img } from "../../../components/Header/styles";
import {
  Center,
  FlexContainer,
  Separator,
} from "../../../components/StyledComponents";
import Lock from "../../../assets/images/lock.png";
import { CohortDto } from "../../../types/cohort.dto";
import { InvitationDto } from "../../../types/invitation.dto";
import invitationActions from "../../../store/actions/invitation";
import { UserDto } from "../../../types/user.dto";
import {
  AcademyLogo,
  AcademyLogoText,
  BannerCohortPhoto,
  BannerImageContainer,
  CardWrapper,
  CohortBoldFont,
  CohortLabelFont,
  CohortMediumFont,
  GeneralContent,
  LikeDislikeBox,
  LikeDislikeBoxNumber,
  LikeDislikeText,
} from "./style";
import {
  deleteMember,
  resetUpdateCohort,
  addCohortMember,
  selectCohort,
  addRating,
  manageLikeDislike,
  getAllCohorts,
  resetGetOneCohort,
  resetGetAllCohorts,
  getCohort,
} from "../../../store/actions/cohort";
import { RequestDto } from "../../../types/request.dto";
import {
  deleteRequest,
  getAllRequests,
  getRequestsByUserEntity,
  newRequest,
  resetDeleteRequest,
  resetGetAllRequests,
  resetNewRequest,
  resetUpdateRequest,
} from "../../../store/actions/request";
import { StateDto } from "../../../types/states.dto";
import ModalConfirmDelete from "../../../components/ModalConfirmDelete";
import { showModal } from "../../../store/actions/modal";
import DropDown from "./components/DropDown";
import { ContentDto } from "../../../types/content.dto";
import { UnitDto } from "../../../types/unit.dto";
import { AcademyDto } from "../../../types/academy.dto";
import Loader from "../../../components/Loader";
import { getAllUnits, resetGetAllUnits } from "../../../store/actions/unit";
import academyActions from "../../../store/actions/academies";
import contentActions from "../../../store/actions/content";
import { CDocDto } from "../../../types/cdoc.dto";
import { VideoDto } from "../../../types/video.dto";
import { filterCohortByRole } from "../../../helpers/roleAcademy";
import {
  addClickVideo,
  getAllVideos,
  resetGetAllVideos,
} from "../../../store/actions/video";
import {
  addClickCdoc,
  getAllCDOCs,
  resetGetAllCdocs,
} from "../../../store/actions/cdoc";
import {
  addClickImage,
  getAllImages,
  resetGetAllImages,
} from "../../../store/actions/image";
import {
  getAllRoadmaps,
  resetGetAllRoadmaps,
} from "../../../store/actions/roadmap";
import { ImageDto } from "../../../types/image.dto";
import { RoadmapDto } from "../../../types/roadmap.dto";
import { useNavigate, useParams } from "react-router-dom";
import CenterTitle from "../../../components/CenterTitle";
import Slider from "../../../components/Slider";
import Rating from "../../../components/Rating";
import {
  AiFillDislike,
  AiFillEye,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";
import { formatNumber, overThousandFixed } from "../../../helpers/number-fixed";
import CommentSection from "../../../components/CommentSection";
import { SlideShowDto } from "../../../types/slideshow.dto";
import { ListenDto } from "../../../types/listen.dto";
import {
  getAllListens,
  resetGetAllListens,
} from "../../../store/actions/listen";
import {
  getAllSlideShows,
  resetGetAllSlideShows,
} from "../../../store/actions/slideshow";
import { useWindowSize } from "../../../hooks/useWindowScreen";
import { PaginationDto } from "../../../types/pagination.dto";
import { GeneralContentDto } from "../../../types/generalContent.dto";
import GeneralContentCarousel from "./components/GeneralContentCarousel";
import ScrollUnits from "./components/ScrollUnits";
import { getAllBytes, resetGetAllBytes } from "../../../store/actions/byte";
import { ByteDto } from "../../../types/byte.dto";
import { useTheme } from "styled-components";
import {
  resetGetAllGeneralContentsData,
  resetGetGeneralContent,
} from "../../../store/actions/generalContent";
import { addClick, addView, userTime } from "../../../store/actions/userTimes";
import useTrackTimeAndAPI from "../../../hooks/useTrackTimeAndAPI";
import {
  BannerAcademyPhoto,
  BannerText,
  BannerTitle,
  BannerWrapper,
} from "../../Academy/components/Banner/style";
import { MdGroups } from "react-icons/md";
import services from "../../../services";
import ShareLink from "../../../components/ShareLink";
import { BsFillCalendarCheckFill, BsFillCalendarXFill } from "react-icons/bs";
import EmptyState from "./components/EmptyState";

const Component = ({
  cohortSelected,
  cohorts,
  user,
  invitations,
  requests,
  createRequestStates,
  updateCohortsStates,
  deleteRequestStates,
  contents,
  units,
  academies,
  cdocs,
  videos,
  images,
  roadmaps,
  slideshows,
  listens,
  contentsStates,
  videosStates,
  imagesStates,
  roadmapsStates,
  slideshowsStates,
  cdocsStates,
  listensStates,
  bytes,
  getBytes,
  getCohortStates,
  deletedRequest,
  updateRequestStates,
  updatedRequest,
  updateInvitationStates,
  updatedInvitation,
  createdRequest,
}: {
  cohorts: CohortDto[];
  cohortSelected: CohortDto;
  user: UserDto;
  invitations: InvitationDto[];
  requests: RequestDto[];
  createRequestStates: StateDto;
  updateCohortsStates: StateDto;
  deleteRequestStates: StateDto;
  contents: ContentDto[];
  units: UnitDto[];
  academies: AcademyDto[];
  cdocs: CDocDto[];
  videos: VideoDto[];
  images: ImageDto[];
  roadmaps: RoadmapDto[];
  slideshows: SlideShowDto[];
  listens: ListenDto[];
  contentsStates: StateDto;
  videosStates: StateDto;
  imagesStates: StateDto;
  roadmapsStates: StateDto;
  slideshowsStates: StateDto;
  cdocsStates: StateDto;
  listensStates: StateDto;
  bytes: ByteDto[];
  getBytes: StateDto;
  getCohortStates: StateDto;
  deletedRequest: RequestDto;
  updateRequestStates: StateDto;
  updatedRequest: RequestDto;
  updateInvitationStates: StateDto;
  updatedInvitation: InvitationDto;
  createdRequest: RequestDto;
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [active, setActive] = useState<number>();
  const [action, setAction] = useState<{ action: string; data: any }>();
  const [rating, setRating] = useState<number | undefined>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [academyRequests, setAcademyRequests] = useState<RequestDto[]>([]);
  const [academyInvitations, setAcademyInvitations] = useState<InvitationDto[]>(
    []
  );
  const [contentCount, setContentCount] = useState<number>(0);
  const [currentUnit, setCurrentUnit] = useState<string>();
  const { isMobile } = useWindowSize();
  const params = useParams();
  const [currentContent, setCurrentContent] = useState<
    ((
      | ContentDto
      | VideoDto
      | CDocDto
      | ImageDto
      | RoadmapDto
      | ListenDto
      | SlideShowDto
    ) & {
      type?: string;
      link: string;
      description?: string;
    })[]
  >([]);
  const handleItemClick = (item) => {
    if (currentUnit !== item) {
      // Only set the currentItem if it's a new item
      setCurrentUnit(item);
    }
  };
  const getUnitData = ({ unitId }: { unitId: string }) => {
    let payload: { [key: string]: PaginationDto } = {
      videos: {
        filterBy: {
          url: { $exists: true },
          active: true,
          published: true,
          units: unitId,
        },
      },
      cdocs: {
        filterBy: {
          active: true,
          published: true,
          units: unitId,
        },
      },
      slideshows: {
        filterBy: {
          active: true,
          published: true,
          units: unitId,
        },
      },
      roadmaps: {
        filterBy: {
          active: true,
          published: true,
          units: unitId,
        },
      },
      images: {
        filterBy: {
          active: true,
          published: true,
          units: unitId,
        },
      },
      contents: {
        filterBy: {
          active: true,
          published: true,
          units: unitId,
        },
      },
      listens: {
        filterBy: {
          active: true,
          published: true,
          units: unitId,
        },
      },
      bytes: {
        filterBy: {
          url: { $exists: true },
          active: true,
          published: true,
          units: unitId,
        },
      },
    };
    dispatch(contentActions.getAll(payload.contents));
    dispatch(getAllVideos(payload.videos));
    dispatch(getAllCDOCs(payload.cdocs));
    dispatch(getAllImages(payload.images));
    dispatch(getAllRoadmaps(payload.roadmaps));
    dispatch(getAllListens(payload.listens));
    dispatch(getAllSlideShows(payload.slideshows));
    dispatch(getAllBytes(payload.bytes));
  };
  useEffect(() => {
    if (cohortSelected && user) {
      dispatch(
        addView({ id: cohortSelected._id, user: user._id, type: "COHORT" })
      );
    }
  }, [cohortSelected, user]);
  useEffect(() => {
    if (!!cohortSelected && !!user) {
      setRating(
        cohortSelected.ratings.find((rating) => user._id === rating.user)?.stars
      );
    }
  }, [cohortSelected]);
  useEffect(() => {
    if (!!action) {
      dispatch(showModal("confirm-delete-item-secuencial-modal"));
    }
  }, [action]);

  useEffect(() => {
    setIsLoading(
      contentsStates.loading ||
        videosStates.loading ||
        imagesStates.loading ||
        roadmapsStates.loading ||
        slideshowsStates.loading ||
        cdocsStates.loading ||
        listensStates.loading ||
        getBytes.loading
    );
  }, [
    contentsStates,
    videosStates,
    imagesStates,
    roadmapsStates,
    slideshowsStates,
    cdocsStates,
    listensStates,
    getBytes,
  ]);

  useEffect(() => {
    if (!!currentUnit) {
      getUnitData({ unitId: currentUnit });
    }
  }, [currentUnit]);

  useEffect(() => {
    if (
      !!contents &&
      !!videos &&
      !!cdocs &&
      !!roadmaps &&
      !!images &&
      !!slideshows &&
      !!listens &&
      !!bytes
    ) {
      const currentContents = contents.map((content) => {
        return { ...content, link: content.link };
      });

      const currentVideos = videos.map((video) => {
        return {
          ...video,
          link: `/dashboard/videos/${video._id}`,
        };
      });
      const currentCdocs = cdocs.map((cdoc) => {
        return {
          ...cdoc,
          link: `/dashboard/cdocs/${cdoc._id}`,
        };
      });
      const currentImages = images.map((image) => {
        return {
          ...image,

          link: `/dashboard/images/${image._id}`,
        };
      });
      const currentSlideshows = slideshows.map((slideshow) => {
        return {
          ...slideshow,

          link: `/dashboard/slideshows/${slideshow._id}`,
        };
      });
      const currentListens = listens.map((listen) => {
        return {
          ...listen,

          link: `/dashboard/listens/${listen._id}`,
        };
      });
      const currentBytes = bytes.map((byte) => {
        return {
          ...byte,

          link: `/dashboard/bytes/${byte._id}`,
        };
      });
      const currentRoadmaps = roadmaps.map((roadmap) => {
        return {
          ...roadmap,

          link: `/dashboard/roadmaps/${roadmap._id}`,
        };
      });
      setCurrentContent([
        ...currentContents,
        ...currentCdocs,
        ...currentVideos,
        ...currentImages,
        ...currentRoadmaps,
        ...currentBytes,
        ...currentListens,
        ...currentSlideshows,
      ]);
    }
  }, [contents, cdocs, videos, images, listens, slideshows, bytes, roadmaps]);
  useEffect(() => {
    if (params.id) {
      dispatch(getCohort({ _id: params.id }));
    }
  }, [params.id]);

  useEffect(() => {
    if (cohortSelected) {
      const fetchData = async () => {
        const responseContents = await services.generalContent.getAll({
          filterBy: { cohorts: cohortSelected._id, active: true },
        });
        if (responseContents.response) {
          setContentCount(responseContents.response.length);
        }
      };
      fetchData();
    }
  }, [cohortSelected]);

  useEffect(() => {
    if (!!invitations) {
      setAcademyInvitations((state) => [...state, ...invitations]);
    }

    if (!invitations && !!cohortSelected) {
      const inivitationPayload = {
        entity: cohortSelected?._id,
        filterBy: {},
      };
      dispatch(invitationActions.getAllByUserEntity(inivitationPayload));
    }
  }, [invitations, dispatch, cohortSelected, user]);

  useEffect(() => {
    if (!!requests) {
      setAcademyRequests((state) => [...state, ...requests]);
    }
    if (!requests && !!cohortSelected) {
      const requestPayload = {
        entity: cohortSelected._id,
        filterBy: {},
      };
      dispatch(getRequestsByUserEntity(requestPayload));
    }
  }, [requests, dispatch, cohortSelected, user]);

  useEffect(() => {
    if (!!deleteRequestStates.success) {
      setAcademyRequests((state) =>
        state.filter((st) => st._id !== deletedRequest._id)
      );
    }
  }, [deleteRequestStates]);

  useEffect(() => {
    if (!!updateRequestStates.success) {
      setAcademyRequests((state) =>
        state.map((st) => (st._id === updatedRequest._id ? updatedRequest : st))
      );
      dispatch(resetUpdateRequest());
    }
  }, [updateRequestStates]);

  useEffect(() => {
    if (!!updateInvitationStates.success) {
      setAcademyInvitations((state) =>
        state.map((st) =>
          st._id === updatedInvitation._id ? updatedInvitation : st
        )
      );
      dispatch(invitationActions.resetUpdate());
    }
  }, [updateInvitationStates]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllUnits());
      dispatch(academyActions.resetGetAllAcademies());
      dispatch(contentActions.resetGetAllContents());
      dispatch(invitationActions.resetGetAllInvitations());
      dispatch(resetGetAllRequests());
      dispatch(resetGetAllVideos());
      dispatch(resetGetAllCdocs());
      dispatch(resetGetAllImages());
      dispatch(resetGetAllRoadmaps());
      dispatch(resetGetAllListens());
      dispatch(resetGetAllSlideShows());
      dispatch(resetGetAllGeneralContentsData());
      dispatch(resetGetAllBytes());
      dispatch(resetGetOneCohort());
      dispatch(resetGetAllCohorts());
    };
  }, []);

  useEffect(() => {
    if (!!updateCohortsStates.success || !!updateCohortsStates.error) {
      dispatch(resetUpdateCohort());
    }
  }, [updateCohortsStates]);

  useEffect(() => {
    if (!!createRequestStates.success) {
      setAcademyRequests((state) => [...state, createdRequest]);
      dispatch(resetNewRequest());
    }
  }, [createRequestStates]);

  if (!cohortSelected) {
    return <EmptyState></EmptyState>;
  }
  return (
    <>
      <Grid.Row>
        <Grid.Col sm={12}>
          <BannerWrapper
            style={{
              ...(isMobile && {
                margin: "10px 10px 10px 10px",
              }),
            }}
          >
            {!cohortSelected ? (
              <>
                <BannerImageContainer
                  style={!isMobile ? { height: "303px" } : {}}
                >
                  <FlexContainer
                    style={{ height: "100%", width: "100%" }}
                    align="center"
                    justify="center"
                  >
                    <Loader color="LightBlue"></Loader>
                  </FlexContainer>
                </BannerImageContainer>
                <FlexContainer
                  style={{ height: "300px" }}
                  align="center"
                  justify="center"
                >
                  <Loader color="LightBlue"></Loader>
                </FlexContainer>
              </>
            ) : (
              <>
                <BannerImageContainer>
                  <Img
                    src={
                      cohortSelected.bannerPic ??
                      "https://files.worldwildlife.org/wwfcmsprod/images/Tiger_resting_Bandhavgarh_National_Park_India/hero_small/6aofsvaglm_Medium_WW226365.jpg"
                    }
                  ></Img>
                </BannerImageContainer>
                <Separator size={10}></Separator>
                <FlexContainer gap="10px">
                  <BannerAcademyPhoto style={{ marginTop: "-40px", zIndex: 1 }}>
                    <Img
                      src={
                        cohortSelected.picture ??
                        "https://cdn.pixabay.com/photo/2015/11/19/08/52/banner-1050629__340.jpg"
                      }
                    ></Img>
                  </BannerAcademyPhoto>
                  <BannerTitle
                    hideLine={2}
                    style={{ fontWeight: "bold" }}
                    size={"16px"}
                  >
                    {cohortSelected.name}
                  </BannerTitle>
                </FlexContainer>
                <Separator size={10}></Separator>
                <FlexContainer
                  justify="space-between"
                  gap="10px"
                  wrap="wrap"
                  style={{ width: "100%" }}
                >
                  <FlexContainer
                    style={{
                      margin: "0px 0px 0px 16px",
                      ...(isMobile && { maxWidth: "100px" }),
                    }}
                    direction="column"
                    gap="16px"
                  >
                    <FlexContainer gap="12px" direction="column">
                      <FlexContainer align="center" gap="4px">
                        <FlexContainer align="center" gap="3px">
                          <MdGroups
                            color={theme.colors.LightBlue}
                            fontSize={20}
                          ></MdGroups>
                          <BannerText size={"12px"}>
                            {overThousandFixed(
                              cohortSelected.studentsIds.length
                            )}
                          </BannerText>
                        </FlexContainer>
                        <span>|</span>
                        <FlexContainer align="center" gap="3px">
                          <AiFillEye
                            color={theme.colors.LightBlue}
                            fontSize={20}
                          ></AiFillEye>
                          <BannerText size={"12px"}>
                            {overThousandFixed(cohortSelected.viewCount)}
                          </BannerText>
                        </FlexContainer>
                      </FlexContainer>
                      <BannerText size={"12px"}>{`Cant. contenidos: ${
                        contentCount ?? "-"
                      }`}</BannerText>
                      <FlexContainer gap="7px" direction="column">
                        <FlexContainer align="center" gap="3px">
                          <BannerText size={"14px"}>
                            Fecha de creación:
                          </BannerText>
                          <BannerText size={"12px"}>
                            {new Date(
                              cohortSelected.createdAt
                            ).toLocaleDateString("en-GB")}
                          </BannerText>
                        </FlexContainer>
                        <FlexContainer align="center" gap="3px">
                          <BannerText size={"14px"}>
                            Ultima actualizacion:
                          </BannerText>
                          <BannerText size={"12px"}>
                            {new Date(
                              cohortSelected.updatedAt
                            ).toLocaleDateString("en-GB")}
                          </BannerText>
                        </FlexContainer>
                      </FlexContainer>
                      <FlexContainer gap="7px" direction="column">
                        {cohortSelected.startDate &&
                          cohortSelected.type === "SYNCHRONOUS" && (
                            <FlexContainer align="center" gap="2px">
                              <BsFillCalendarCheckFill></BsFillCalendarCheckFill>
                              <BannerText size={"14px"}>
                                Fecha Inicio:
                              </BannerText>
                              <BannerText size={"12px"}>
                                {new Date(
                                  cohortSelected.startDate
                                ).toLocaleDateString("en-GB")}
                              </BannerText>
                            </FlexContainer>
                          )}
                        {cohortSelected.endDate &&
                          cohortSelected.type === "SYNCHRONOUS" && (
                            <FlexContainer align="center" gap="2px">
                              <BsFillCalendarXFill></BsFillCalendarXFill>
                              <BannerText size={"14px"}>Fecha Fin:</BannerText>
                              <BannerText size={"12px"}>
                                {new Date(
                                  cohortSelected.endDate
                                ).toLocaleDateString("en-GB")}
                              </BannerText>
                            </FlexContainer>
                          )}
                      </FlexContainer>
                    </FlexContainer>
                  </FlexContainer>
                  <FlexContainer direction="column" align="center" gap="14px">
                    <h2 style={{ fontSize: "16px", color: "#051725" }}>
                      {cohortSelected.ratings.length > 0
                        ? formatNumber(
                            cohortSelected.ratings.reduce((prev, next) => {
                              return prev + next.stars;
                            }, 0) / cohortSelected.ratings.length
                          )
                        : 0}
                    </h2>
                    <Rating
                      starSize={18}
                      color={{ filled: "#2496ed", unfilled: "transparent" }}
                      count={5}
                      passiveValue={
                        cohortSelected.ratings.length > 0
                          ? formatNumber(
                              cohortSelected.ratings.reduce((prev, next) => {
                                return prev + next.stars;
                              }, 0) / cohortSelected.ratings.length
                            )
                          : 0
                      }
                      rating={rating}
                      onRating={(rate) => {
                        dispatch(
                          academyActions.addRating({
                            id: cohortSelected._id,
                            stars: rate,
                            individual: true,
                          })
                        );
                      }}
                    ></Rating>
                    {!invitations || !requests ? (
                      <Loader
                        style={{ margin: "0px" }}
                        color="LightBlue"
                      ></Loader>
                    ) : !cohortSelected.studentsIds.find(
                        (userF) => userF === user._id
                      ) &&
                      !cohortSelected.mentorsIds.find(
                        (userF) => userF === user._id
                      ) ? (
                      cohortSelected.private ? (
                        academyInvitations?.find(
                          (invitation) =>
                            invitation.active &&
                            invitation.cohort._id === cohortSelected._id &&
                            invitation.sentBy._id === user._id &&
                            invitation.status === "PENDING"
                        ) ? (
                          <Button
                            type="button"
                            onClick={() => {
                              dispatch(
                                addClick({
                                  id: cohortSelected._id,
                                  user: user._id,
                                  type: "COHORT",
                                })
                              );
                              dispatch(
                                invitationActions.update({
                                  condition: "accept",
                                  _id: user._id,
                                })
                              );
                            }}
                            options={{
                              size: "md",
                              type: "filled",
                              skin: "lightblue",
                            }}
                          >
                            Aceptar Invitacion
                          </Button>
                        ) : !academyRequests.find(
                            (request) =>
                              request.active &&
                              request.sentBy === user._id &&
                              request.cohort === cohortSelected._id
                          ) ? (
                          <Button
                            type="button"
                            onClick={() => {
                              dispatch(
                                newRequest({
                                  platform: "CAMPUS",
                                  cohort: cohortSelected._id,
                                  sentBy: user._id,
                                })
                              );
                              dispatch(
                                addClick({
                                  id: cohortSelected._id,
                                  user: user._id,
                                  type: "COHORT",
                                })
                              );
                            }}
                            options={{
                              size: "md",
                              type: "filled",
                              skin: "lightblue",
                            }}
                            loading={createRequestStates.loading}
                          >
                            Enviar Solicitud
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            onClick={(ev) => {
                              dispatch(
                                addClick({
                                  id: cohortSelected._id,
                                  user: user._id,
                                  type: "COHORT",
                                })
                              );
                              setAction({
                                action: "cancel-request",
                                data: {
                                  _id: academyRequests.find(
                                    (request) =>
                                      !!request.active &&
                                      request.cohort === cohortSelected._id &&
                                      request.sentBy === user._id &&
                                      request.status === "PENDING"
                                  )?._id,
                                },
                              });
                            }}
                            options={{
                              size: "md",
                              type: "outline",
                              skin: "danger",
                            }}
                            minwidth="150px"
                          >
                            Cancelar Solicitud
                          </Button>
                        )
                      ) : (
                        <Button
                          type="button"
                          onClick={() => {
                            dispatch(
                              addCohortMember({
                                _id: cohortSelected._id,
                                userId: user._id,
                              })
                            );
                            dispatch(
                              addClick({
                                id: cohortSelected._id,
                                user: user._id,
                                type: "COHORT",
                              })
                            );
                          }}
                          options={{
                            size: "md",
                            type: "filled",
                            skin: "lightblue",
                          }}
                          loading={updateCohortsStates.loading}
                        >
                          Suscribirse
                        </Button>
                      )
                    ) : (
                      <Button
                        type="button"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          ev.preventDefault();
                          dispatch(
                            addClick({
                              id: cohortSelected._id,
                              user: user._id,
                              type: "COHORT",
                            })
                          );
                          setAction({
                            action: "out-academy",
                            data: { _id: cohortSelected._id, userId: user._id },
                          });
                        }}
                        options={{
                          size: "md",
                          type: "filled",
                          skin: "danger",
                        }}
                        minwidth="150px"
                        style={{
                          width: "150px",
                          backgroundColor: "#000",
                          borderColor: "#000",
                        }}
                      >
                        Salir
                      </Button>
                    )}
                    <ShareLink></ShareLink>
                    <FlexContainer gap="12px" align="center">
                      <LikeDislikeBox
                        className={
                          !!cohortSelected.likes.find(
                            (like) => like.user === user._id
                          )
                            ? "active"
                            : ""
                        }
                        onClick={() => {
                          if (
                            cohortSelected.likes.find(
                              (like) => like.user === user._id
                            )
                          ) {
                            dispatch(
                              manageLikeDislike({
                                _id: cohortSelected._id,
                                condition: "like",
                                action: "remove",
                              })
                            );
                          } else {
                            dispatch(
                              manageLikeDislike({
                                _id: cohortSelected._id,
                                condition: "like",
                                action: "add",
                              })
                            );
                          }
                        }}
                      >
                        <FlexContainer gap="3px" align="center">
                          <div
                            className="like-dislike-icon"
                            style={{ background: "#fff", padding: "4px" }}
                          >
                            <AiOutlineLike size={15} fill={"#2496ed"} />
                          </div>
                          <LikeDislikeBoxNumber className="count-likes">
                            <LikeDislikeText>
                              {overThousandFixed(cohortSelected.likes.length)}
                            </LikeDislikeText>
                          </LikeDislikeBoxNumber>
                        </FlexContainer>
                      </LikeDislikeBox>
                      <LikeDislikeBox
                        className={
                          !!cohortSelected.dislikes.find(
                            (like) => like.user === user._id
                          )
                            ? "active"
                            : ""
                        }
                        onClick={() => {
                          if (
                            cohortSelected.dislikes.find(
                              (like) => like.user === user._id
                            )
                          ) {
                            dispatch(
                              addClick({
                                id: cohortSelected._id,
                                user: user._id,
                                type: "COHORT",
                              })
                            );
                            dispatch(
                              manageLikeDislike({
                                _id: cohortSelected._id,
                                condition: "dislike",
                                action: "remove",
                              })
                            );
                          } else {
                            dispatch(
                              addClick({
                                id: cohortSelected._id,
                                user: user._id,
                                type: "COHORT",
                              })
                            );
                            dispatch(
                              manageLikeDislike({
                                _id: cohortSelected._id,
                                condition: "dislike",
                                action: "add",
                              })
                            );
                          }
                        }}
                      >
                        <FlexContainer gap="3px" align="center">
                          <div
                            className="like-dislike-icon"
                            style={{
                              padding: "7px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {cohortSelected.dislikes.find(
                              (like) => like.user === user._id
                            ) ? (
                              <AiFillDislike
                                fill={"#fff"}
                                size={15}
                              ></AiFillDislike>
                            ) : (
                              <AiOutlineDislike
                                fill={"#2496ed"}
                                size={15}
                              ></AiOutlineDislike>
                            )}
                          </div>
                        </FlexContainer>
                      </LikeDislikeBox>
                    </FlexContainer>
                  </FlexContainer>
                </FlexContainer>
              </>
            )}
          </BannerWrapper>
        </Grid.Col>
      </Grid.Row>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <FlexContainer direction="column">
              <FlexContainer direction="column" style={{ minHeight: "100px" }}>
                <Separator size={12}></Separator>
                <GeneralContentCarousel></GeneralContentCarousel>
                <Separator size={12}></Separator>
              </FlexContainer>
              <ScrollUnits
                isLoadingContents={isLoading}
                currentContent={currentContent}
                active={active}
                setActive={setActive}
                handleItemClick={handleItemClick}
              />
            </FlexContainer>
            <Separator size={12}></Separator>
            <CommentSection
              section="cohort"
              itemId={cohortSelected._id}
            ></CommentSection>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      {!!cohortSelected && action?.action === "cancel-request" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Cancelar Solicitud",
            style: {
              color: "Danger",
              style: { height: "26px" },
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
                loading: "Danger",
              },
            },
          }}
          states={deleteRequestStates}
          title={
            <span>
              {`¿Seguro que quiere cancelar la solicitud a `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {cohortSelected.name}
              </span>
              ?
            </span>
          }
          elementActions={deleteRequest(action.data)}
          resetAction={resetDeleteRequest}
          resetState={setAction}
        ></ModalConfirmDelete>
      )}
      {!!cohortSelected && action?.action === "out-academy" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Salir",
            style: {
              style: {
                height: "26px",
                backgroundColor: "#000",
                borderColor: "#000",
              },
              options: {
                type: "filled",
                skin: "violet",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={updateCohortsStates}
          title={
            <span>
              {`¿Estas seguro que quieres salir del cohort `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {cohortSelected.name}
              </span>
              ?
            </span>
          }
          elementActions={deleteMember(action.data)}
          resetAction={() => null}
          resetState={setAction}
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({
  cohortStore,
  invitationStore,
  userStore,
  requestStore,
  contentStore,
  unitStore,
  academyStore,
  cdocStore,
  videoStore,
  imageStore,
  roadmapStore,
  slideshowStore,
  listenStore,
  generalContentStore,
  byteStore,
}) => {
  const { data: cohortSelected } = cohortStore.cohort;
  const { states: updateCohortsStates } = cohortStore.updateCohort;
  const { data: invitations } = invitationStore.all;
  const { data: user } = userStore;
  const { data: requests } = requestStore.allRequests;
  const { states: createRequestStates, data: createdRequest } =
    requestStore.newRequest;
  const { states: deleteRequestStates, data: deletedRequest } =
    requestStore.deleteRequest;
  const { states: updateRequestStates, data: updatedRequest } =
    requestStore.updateRequest;
  const { states: updateInvitationStates, data: updatedInvitation } =
    invitationStore.update;
  const { data: contents, states: contentsStates } = contentStore.all;
  const { data: units } = unitStore.allUnits;
  const { data: academies } = academyStore.all;
  const { data: cdocs, states: cdocsStates } = cdocStore.all;
  const { data: bytes, states: getBytes } = byteStore.all;
  const { data: videos, states: videosStates } = videoStore.all;
  const { data: images, states: imagesStates } = imageStore.all;
  const { data: roadmaps, states: roadmapsStates } = roadmapStore.all;
  const { data: cohorts, states: getCohortStates } = cohortStore.allCohorts;
  const { data: slideshows, states: slideshowsStates } = slideshowStore.all;
  const { data: listens, states: listensStates } = listenStore.all;
  return {
    contentsStates,
    videosStates,
    imagesStates,
    roadmapsStates,
    slideshowsStates,
    cdocsStates,
    listensStates,
    cohortSelected,
    invitations,
    user,
    requests,
    createRequestStates,
    createdRequest,
    updateCohortsStates,
    deleteRequestStates,
    contents,
    units,
    academies,
    cdocs,
    videos,
    images,
    roadmaps,
    cohorts,
    slideshows,
    listens,
    bytes,
    getBytes,
    getCohortStates,
    deletedRequest,
    updateRequestStates,
    updatedRequest,
    updateInvitationStates,
    updatedInvitation,
  };
};

export default connect(states)(Component);
