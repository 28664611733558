import React from "react";
import { FlexContainer } from "../../../../../components/StyledComponents";
import { ListMediaTitle } from "./styles";
import Skeleton from "../../../../../components/Skeleton";

const Component = () => {
  return (
    <FlexContainer direction="column" gap="20px">
      <FlexContainer
        style={{ minHeight: "200px" }}
        direction="column"
        gap="10px"
      >
        <ListMediaTitle>Videos</ListMediaTitle>
        <Skeleton
          itemsPerPage={3}
          itemsPerRow={3}
          animated
          size={{ height: 290, width: 276 }}
          icon="video"
        />
      </FlexContainer>
      <FlexContainer
        style={{ minHeight: "200px" }}
        direction="column"
        gap="10px"
      >
        <ListMediaTitle>SlideShows</ListMediaTitle>
        <Skeleton
          itemsPerPage={3}
          itemsPerRow={3}
          animated
          size={{ height: 311, width: 276 }}
          icon="slide"
        />
      </FlexContainer>

      <FlexContainer
        style={{ minHeight: "200px" }}
        direction="column"
        gap="10px"
      >
        <ListMediaTitle>Cdocs</ListMediaTitle>
        <Skeleton
          itemsPerPage={6}
          itemsPerRow={6}
          animated
          size={{ height: 160, width: 176 }}
          icon="cdoc"
        />
      </FlexContainer>
      <FlexContainer
        style={{ minHeight: "200px" }}
        direction="column"
        gap="10px"
      >
        <ListMediaTitle>Imágenes</ListMediaTitle>
        <Skeleton
          itemsPerPage={3}
          itemsPerRow={3}
          animated
          size={{ height: 290, width: 276 }}
          icon="image"
        />
      </FlexContainer>
      <FlexContainer
        style={{ minHeight: "200px" }}
        direction="column"
        gap="10px"
      >
        <ListMediaTitle>Trayectos</ListMediaTitle>
        <Skeleton
          itemsPerPage={3}
          itemsPerRow={3}
          animated
          size={{ height: 290, width: 276 }}
          icon="trayecto"
        />
      </FlexContainer>
      <FlexContainer
        style={{ minHeight: "200px" }}
        direction="column"
        gap="10px"
      >
        <ListMediaTitle>Bytes</ListMediaTitle>
        <Skeleton
          itemsPerPage={6}
          itemsPerRow={6}
          animated
          size={{ height: 160, width: 176 }}
          icon="byte"
        />
      </FlexContainer>
      <FlexContainer
        style={{ minHeight: "200px" }}
        direction="column"
        gap="10px"
      >
        <ListMediaTitle>Imágenes</ListMediaTitle>
        <Skeleton
          itemsPerPage={3}
          itemsPerRow={3}
          animated
          size={{ height: 290, width: 276 }}
          icon="image"
        />
      </FlexContainer>
      <FlexContainer
        style={{ minHeight: "200px" }}
        direction="column"
        gap="10px"
      >
        <ListMediaTitle>Listens</ListMediaTitle>
        <Skeleton
          itemsPerPage={3}
          itemsPerRow={3}
          animated
          size={{ height: 290, width: 276 }}
          icon="listen"
        />
      </FlexContainer>
      <FlexContainer
        style={{ minHeight: "200px" }}
        direction="column"
        gap="10px"
      >
        <ListMediaTitle>Varios</ListMediaTitle>
        <Skeleton
          itemsPerPage={3}
          itemsPerRow={3}
          animated
          size={{ height: 290, width: 276 }}
          icon="image"
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default Component;
