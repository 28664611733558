import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Icon from "../../../../components/Icon";
import { ActionButton, NewButton } from "./styles";

import EditModal from "./components/EditModal";
import NewModal from "./components/NewModal";
import DeleteModal from "./components/DeleteModal";

import PlusIcon from "../../../../assets/icons/plus.svg";
import DeleteUserIcon from "../../../../assets/icons/delete.svg";
import EditUserIcon from "../../../../assets/icons/edit.svg";
import {
  getAllStudents,
  setSelectedStudent,
} from "../../../../store/actions/student";
import { StudentDto } from "../../../../types/student.dto";
import { showModal } from "../../../../store/actions/modal";
import Loader from "../../../../components/LogoLoader";

interface ComponentProps {
  students: StudentDto[];
}

const Component = ({ students }: ComponentProps) => {
  const [activeStudents, setActiveStudents] = useState<StudentDto[] | null>(
    null
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllStudents());
  }, []);

  useEffect(() => {
    if (students) {
      setActiveStudents(students.filter((student) => student.active));
    }
  }, [students]);

  const handleEdit = (row, index) => {
    dispatch(setSelectedStudent(row));
    dispatch(showModal("edit-student-modal"));
  };

  const handleDelete = (row, index) => {
    dispatch(setSelectedStudent(row));
    dispatch(showModal("delete-student-modal"));
  };

  const handleNew = (row, index) => {
    dispatch(showModal("new-student-modal"));
  };

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.name,
    },
    {
      name: "Apellido",
      selector: (row) => row.lastName,
    },
    {
      name: "E-mail",
      selector: (row) => row.email,
    },
    {
      right: true,
      name: "",
      cell: (row, index) => {
        const Edit = () => (
          <ActionButton type="button" onClick={() => handleEdit(row, index)}>
            <Icon icon={EditUserIcon} color="#1673e5" size="18px" />
          </ActionButton>
        );

        const Delete = () => (
          <ActionButton type="button" onClick={() => handleDelete(row, index)}>
            <Icon icon={DeleteUserIcon} color="#fd433a" size="18px" />
          </ActionButton>
        );

        return (
          <>
            <Edit />
            <Delete />
          </>
        );
      },
    },
  ];

  const handleRowClicked = (data) => {};

  if (!activeStudents) {
    return <Loader />;
  }

  return (
    <>
      <NewButton onClick={handleNew}>
        <Icon icon={PlusIcon} size="14px" color="#1573e5" />
      </NewButton>

      <DataTableExtensions
        filterDigit={0}
        export={false}
        print={false}
        filterPlaceholder="Buscar"
        columns={columns}
        data={activeStudents}
      >
        <DataTable
          pagination
          disabled={!activeStudents}
          columns={columns}
          data={activeStudents}
          noDataComponent={<p>Sin Elementos</p>}
          onRowClicked={(row) => handleRowClicked(row)}
        />
      </DataTableExtensions>

      <EditModal />
      <NewModal />
      <DeleteModal />
    </>
  );
};

const states = ({ studentStore }) => {
  const { data: students } = studentStore.allStudents;

  return {
    students,
  };
};
export default connect(states)(Component);
