import React, { useEffect, useRef, useState } from "react";
import { Transition } from "react-transition-group";
import {
  Close,
  Description,
  Modal,
  ModalAnimation,
  ModalBody,
  ModalContent,
  ModalDialog,
  ModalHeader,
  Title,
} from "../Modal/styles";
import { useDispatch } from "react-redux";
import closeIcon from "../../assets/icons/close.svg";
export interface ModalProps {
  animation?: string;
  children: any;
  showClose?: boolean;
  title?: string | JSX.Element;
  name: string;
  onClose?: () => void;
  styles?: any;
  width?: string;
  height?: string;
  position?: string;
  padding?: string;
  remove?: boolean;
  backdrop?: boolean;
  description?: string;
  onCloseModal?: () => void;
  setIsVisible: (args?) => void;
  isVisible: any;
}
const Component = ({
  animation = "fadeInSlideUp",
  showClose = true,
  children,
  title,
  name,
  onClose,
  styles,
  width,
  height,
  position,
  padding,
  remove,
  backdrop,
  description,
  setIsVisible,
  isVisible,
}: ModalProps) => {
  const ref: any = useRef();
  const handleClose = () => {
    setIsVisible(null);
    if (onClose) {
      onClose();
    }
  };
  useEffect(() => {
    const checkIfClickOutside = (e) => {
      e.stopPropagation();
      if (isVisible && e.target.getAttribute("name") === "back") {
        handleClose();
      }
    };
    document.addEventListener("click", checkIfClickOutside);
    return () => {
      document.removeEventListener("click", checkIfClickOutside);
    };
  }, [isVisible]);

  return (
    <>
      <div
        style={!!styles && { backgroundColor: "#fff", zIndex: 1050, ...styles }}
      >
        <Modal
          style={{ alignItems: "stretch" }}
          ref={ref}
          name="back"
          data="hola"
        >
          <ModalDialog width={width} name="shadow">
            <ModalContent padding={!!padding && padding} height={height}>
              <ModalHeader>
                {showClose && !remove && (
                  <Close onClick={() => handleClose()}>
                    <img src={closeIcon} alt="X" width="16px" height="16px" />
                  </Close>
                )}
                {!!title && <Title>{title}</Title>}
                {!!description && <Description>{description}</Description>}
              </ModalHeader>
              <ModalBody name="asdasd">{children}</ModalBody>
            </ModalContent>
          </ModalDialog>
        </Modal>
      </div>
    </>
  );
};

export default Component;
