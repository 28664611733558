import styled from "styled-components";

export const NumberStatWrapper = styled("div")`
  padding: 22px 30px 2px 30px;
  border-radius: 10px;
  box-shadow: 0 0 1px 0 rgba(21, 115, 229, 0.16);
  background-color: #fff;
`;

export const NumberStatLabel = styled("h3")`
  font-size: 10px;
  font-weight: normal;
  color: ${(props) => props.theme.colors.Night};
  word-break: break-word;
  text-align: center;
`;

export const NumberStatValue = styled("h3")`
  font-size: 48px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;

  color: ${(props) => props.theme.colors.LightBlue};
`;
