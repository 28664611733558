import styled from "styled-components";

export const Wrapper = styled("div")`
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;
