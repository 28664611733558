import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import {
  initialValues,
  schema,
} from "../../../../constants/form/user/profile/data";

import { toast } from "react-toastify";
import usersActions from "../../../../store/actions/users";
import { hideModal } from "../../../../store/actions/modal";
import Modal from "../../../Modal";
import Grid from "../../../Grid";
import Input from "../../../Form/Input";
import { Center } from "../../../StyledComponents";
import Submit from "../../../Form/Submit";
import { updateUser } from "../../../../store/actions/user";

const Component = ({ updateStates, users, user, updateUserStates }) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(updateUser({ _id: user._id, body: { ...values } }));
    setFormActions(actions);
  };
  useEffect(() => {
    if (updateUserStates.success) {
      setTimeout(() => {
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }
  }, [updateStates]);

  return (
    <Modal showClose name="edit-profile-contact" title="Datos de Contacto">
      <Formik
        initialValues={{
          ...initialValues(user),
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-profile-data-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Input
                    name={"phone"}
                    error={errors["phone"]}
                    touched={touched["phone"]}
                    value={values["phone"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "Teléfono", skin: "gray" }}
                  />
                  <Input
                    name={"email"}
                    error={errors["email"]}
                    touched={touched["email"]}
                    value={values["email"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "E-mail", skin: "gray" }}
                  />
                  <Input
                    name={"location"}
                    error={errors["location"]}
                    touched={touched["location"]}
                    value={values["location"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Ubicación",
                      skin: "gray",
                    }}
                  />
                  <Input
                    name={"linkedinProfile"}
                    error={errors["linkedinProfile"]}
                    touched={touched["linkedinProfile"]}
                    value={values["linkedinProfile"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "Linkedin", skin: "gray" }}
                  />
                  <Input
                    name={"github"}
                    error={errors["github"]}
                    touched={touched["github"]}
                    value={values["github"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "GitHub",
                      skin: "gray",
                    }}
                  />
                  <Input
                    name={"website"}
                    error={errors["website"]}
                    touched={touched["website"]}
                    value={values["website"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "WebSite",
                      skin: "gray",
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Submit
                      isSubmmiting={isSubmitting}
                      form="edit-profile-data-form"
                      style={{ height: "26px" }}
                      options={{
                        type: "filled",
                        loading: "White",
                        skin: "violet",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ usersStore, userStore }) => {
  const { states: updateStates } = usersStore.update;
  const { data: users } = usersStore.all;
  const { data: user } = userStore;
  const { states: updateUserStates } = userStore.update;
  return {
    updateStates,
    users,
    user,
    updateUserStates,
  };
};

export default connect(states)(Component);
