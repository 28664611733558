import * as Yup from "yup";

export const schema = Yup.object().shape({
  name: Yup.string().required("Ingresa un nombre."),
  type: Yup.string().required("Ingresa el tipo de evento."),
  abilityId: Yup.string().required("Ingresa una habilidad."),
  description: Yup.string().required("Ingresa una descripción."),
  startDate: Yup.string().required("Ingresa la fecha de inicio."),
  startHour: Yup.string().required("Ingresa la hora de inicio."),
  endHour: Yup.string().required("Ingresa la hora de fin."),
  frecuency: Yup.string().required("Ingresa la frecuencia del evento."),
  endDate: Yup.string()
    .nullable()
    .when("frecuency", {
      is: "personalized", // alternatively: (val) => val == true
      then: Yup.string().required("Ingresa una fecha de fin."),
    }),
  eventDays: Yup.array()
    .of(Yup.string())
    .nullable()
    .when("frecuency", {
      is: "personalized", // alternatively: (val) => val == true
      then: Yup.array()
        .min(1, "Ingresa que dias se dictara el curso.")
        .required("Ingresa que dias se dictara el curso."),
    }),
});

interface FormValues {
  name: string;
  type: string;
  abilityId: string;
  description: string;
  startDate: string;
  startHour: string;
  endHour: string;
  frecuency: string;
  endDate: string;
  eventDays: string[];
}

export const initialValues: FormValues = {
  name: "",
  type: "",
  abilityId: "",
  description: "",
  startDate: "",
  startHour: "",
  endHour: "",
  frecuency: "",
  endDate: "",
  eventDays: [],
};
