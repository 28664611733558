import React from "react";
import { HiArrowSmUp } from "react-icons/hi";
import { FlexContainer } from "../../../../../../components/StyledComponents";
import { ToolItemWrapper, ToolSideWrapper } from "./style";

const Component = ({ menu, setOpenDropDown, openDropDown, openValue }) => {
  return (
    <ToolSideWrapper openDropDown={openDropDown}>
      <FlexContainer direction="column" gap="10px">
        <ToolItemWrapper
          className={!openDropDown ? "dropActive arrow" : "arrow active"}
          onClick={() => {
            setOpenDropDown((state) => !state);
          }}
        >
          <HiArrowSmUp size={17}></HiArrowSmUp>
        </ToolItemWrapper>
        {menu.map((item) => {
          return (
            <>
              {!item.onAvailable ||
              (!!item.onAvailable && !!item.onAvailable()) ? (
                item.activeFunction && !!item.activeFunction() ? (
                  !!item.IconActive ? (
                    <ToolItemWrapper
                      onClick={!!item.onClickActive && item.onClickActive}
                      className="active"
                    >
                      <item.IconActive size={17}></item.IconActive>
                    </ToolItemWrapper>
                  ) : (
                    <ToolItemWrapper
                      onClick={!!item.onClickActive && item.onClickActive}
                      className="active"
                    >
                      <item.Icon size={17}></item.Icon>
                    </ToolItemWrapper>
                  )
                ) : (
                  <ToolItemWrapper onClick={!!item.onClick && item.onClick}>
                    <item.Icon size={17}></item.Icon>
                  </ToolItemWrapper>
                )
              ) : (
                <ToolItemWrapper className="disavailable">
                  <item.Icon size={17}></item.Icon>
                </ToolItemWrapper>
              )}
            </>
          );
        })}
      </FlexContainer>
    </ToolSideWrapper>
  );
};

export default Component;
