import {
  initialState,
  Actions,
  State,
  GET_ALL_GENERALCONTENTS,
  GET_ALL_GENERALCONTENTS_ERROR,
  GET_ALL_GENERALCONTENTS_SUCCESS,
  GET_GENERALCONTENT,
  GET_GENERALCONTENT_ERROR,
  GET_GENERALCONTENT_SUCCESS,
  NEW_GENERALCONTENT,
  NEW_GENERALCONTENT_ERROR,
  NEW_GENERALCONTENT_SUCCESS,
  RESET_NEW_GENERALCONTENT,
  UPDATE_GENERALCONTENT,
  UPDATE_GENERALCONTENT_ERROR,
  UPDATE_GENERALCONTENT_SUCCESS,
  RESET_UPDATE_GENERALCONTENT,
  DELETE_GENERALCONTENT,
  DELETE_GENERALCONTENT_ERROR,
  DELETE_GENERALCONTENT_SUCCESS,
  RESET_DELETE_GENERALCONTENT,
  UPLOAD_MEDIA,
  UPDATE_GENERALCONTENT_LOADING,
  RESET_GET_GENERALCONTENT,
  ADD_USER_TO_GENERALCONTENT,
  SHARED_GENERALCONTENT,
  SET_GET_GENERALCONTENT,
  UNSAVED_GENERALCONTENT,
  SAVED_GENERALCONTENT,
  QUERY_GENERALCONTENT,
  QUERY_GENERALCONTENT_SUCCESS,
  QUERY_GENERALCONTENT_ERROR,
  RESET_QUERY_GENERALCONTENT,
  UPDATE_CURRENT_GENERALCONTENT_LIST,
  ADD_VIEW_GENERALCONTENT,
  UPDATE_ONE_GENERALCONTENT,
  UPDATE_ONE_GENERALCONTENT_ERROR,
  UPDATE_ONE_GENERALCONTENT_SUCCESS,
  RESET_UPDATE_ONE_GENERALCONTENT,
  MANAGE_LIKE_DISLIKE_GENERALCONTENT,
  GET_GENERALCONTENTS_OFFSET,
  GET_GENERALCONTENTS_OFFSET_ERROR,
  GET_GENERALCONTENTS_OFFSET_SUCCESS,
  RESET_GET_GENERALCONTENTS_OFFSET,
  RESET_GET_ONE_GENERALCONTENT_DATA,
  RESET_GET_ALL_GENERALCONTENT_DATA,
  UPDATE_ITEM_LIST_GENERALCONTENT,
} from "../types/generalContent";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_GENERALCONTENTS:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_GENERALCONTENTS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };

    case RESET_GET_ONE_GENERALCONTENT_DATA:
      return {
        ...state,
        generalContent: initialState.generalContent,
      };
    case RESET_GET_ALL_GENERALCONTENT_DATA:
      return {
        ...state,
        all: initialState.all,
      };

    case UPDATE_ITEM_LIST_GENERALCONTENT:
      return {
        ...state,
        update: {
          states: {
            success: false,
            error: false,
            loading: true,
          },
        },
      };
    case UPDATE_ONE_GENERALCONTENT:
      return {
        ...state,
        updateOne: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_ONE_GENERALCONTENT_ERROR:
      return {
        ...state,
        updateOne: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case UPDATE_ONE_GENERALCONTENT_SUCCESS:
      return {
        ...state,
        updateOne: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case RESET_UPDATE_ONE_GENERALCONTENT:
      return {
        ...state,
        updateOne: {
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case MANAGE_LIKE_DISLIKE_GENERALCONTENT:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case QUERY_GENERALCONTENT:
      return {
        ...state,
        query: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case QUERY_GENERALCONTENT_SUCCESS:
      return {
        ...state,
        query: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case QUERY_GENERALCONTENT_ERROR:
      return {
        ...state,
        query: {
          data: state.query.data,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_QUERY_GENERALCONTENT:
      return {
        ...state,
        query: {
          ...state.query,
          states: initialState.query.states,
        },
      };
    case GET_GENERALCONTENTS_OFFSET:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case GET_GENERALCONTENTS_OFFSET_ERROR:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case GET_GENERALCONTENTS_OFFSET_SUCCESS:
      return {
        ...state,
        offSet: {
          data: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case RESET_GET_GENERALCONTENTS_OFFSET:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: false,
            success: false,
            error: false,
          },
        },
      };
    case ADD_VIEW_GENERALCONTENT:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_CURRENT_GENERALCONTENT_LIST:
      return {
        ...state,
        all: {
          ...state.all,
          data: action.payload,
        },
      };
    case SAVED_GENERALCONTENT:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_GENERALCONTENTS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };

    case SAVED_GENERALCONTENT:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UNSAVED_GENERALCONTENT:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPLOAD_MEDIA:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case ADD_USER_TO_GENERALCONTENT:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SHARED_GENERALCONTENT:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_GENERALCONTENT_LOADING:
      return {
        ...state,
        new: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_GENERALCONTENT:
      return {
        ...state,
        generalContent: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_GENERALCONTENT_SUCCESS:
      return {
        ...state,
        generalContent: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_GENERALCONTENT_ERROR:
      return {
        ...state,
        generalContent: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_GENERALCONTENT:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_GENERALCONTENT_SUCCESS:
      return {
        ...state,
        new: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case SET_GET_GENERALCONTENT:
      return {
        ...state,
        generalContent: {
          ...state.generalContent,
          data: !!action.payload ? action.payload : null,
        },
      };
    case RESET_GET_GENERALCONTENT:
      return {
        ...state,
        generalContent: {
          ...state.generalContent,
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case NEW_GENERALCONTENT_ERROR:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_GENERALCONTENT:
      return {
        ...state,
        new: {
          data: state.new.data,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_GENERALCONTENT:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_GENERALCONTENT_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_GENERALCONTENT_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_GENERALCONTENT:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_GENERALCONTENT:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_GENERALCONTENT_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_GENERALCONTENT_ERROR:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_GENERALCONTENT:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
