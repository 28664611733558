import {
  NewContent,
  NEW_CONTENT,
  ResetNewContent,
  RESET_NEW_CONTENT,
  GET_ALL_CONTENTS,
  GetAllContents,
  UpdateContent,
  UPDATE_CONTENT,
  ResetUpdateContent,
  RESET_UPDATE_CONTENT,
  GetContent,
  DeleteContent,
  DELETE_CONTENT,
  GET_CONTENT,
  RESET_DELETE_CONTENT,
  UNSAVED_CONTENT,
  SAVED_CONTENT,
  ADD_CLICK_CONTENT,
  AddViewContent,
  ADD_VIEW_CONTENT,
  MANAGE_LIKE_DISLIKE_CONTENT,
  GetContentsOffset,
  ResetGetContentsOffset,
  GET_CONTENTS_OFFSET,
  RESET_GET_CONTENTS_OFFSET,
  RESET_GET_ALL_CONTENT_DATA,
  UPDATE_ITEM_LIST_CONTENT,
} from "../types/content";

const create = (payload): NewContent => {
  return { type: NEW_CONTENT, payload };
};
const resetCreate = (): ResetNewContent => {
  return { type: RESET_NEW_CONTENT, payload: null };
};

const offSet = (payload): GetContentsOffset => {
  return { type: GET_CONTENTS_OFFSET, payload };
};

const resetOffset = (): ResetGetContentsOffset => {
  return { type: RESET_GET_CONTENTS_OFFSET, payload: null };
};

const update = (payload): UpdateContent => {
  return { type: UPDATE_CONTENT, payload };
};

const resetUpdate = (): ResetUpdateContent => {
  return { type: RESET_UPDATE_CONTENT, payload: null };
};

const resetGetAllContents = () => {
  return { type: RESET_GET_ALL_CONTENT_DATA, payload: null };
};

const unSaved = (payload) => {
  return { type: UNSAVED_CONTENT, payload };
};
const saved = (payload) => {
  return { type: SAVED_CONTENT, payload };
};

const updateListItem = (payload) => {
  return { type: UPDATE_ITEM_LIST_CONTENT, payload };
};

const getAll = (payload): GetAllContents => {
  return { type: GET_ALL_CONTENTS, payload };
};

const getOne = (payload: { _id: string }): GetContent => {
  return { type: GET_CONTENT, payload };
};

const manageLikeDislike = (payload) => {
  return { type: MANAGE_LIKE_DISLIKE_CONTENT, payload };
};

const deleteOne = (payload: { _id: string }): DeleteContent => {
  return { type: DELETE_CONTENT, payload };
};

const addClick = (payload: {
  _id: string;
  currentCohort?: string;
  currentAcademy?: string;
}) => {
  return { type: ADD_CLICK_CONTENT, payload };
};

const resetDeleteOne = () => {
  return { type: RESET_DELETE_CONTENT, payload: null };
};

export const addView = (payload): AddViewContent => {
  return { type: ADD_VIEW_CONTENT, payload };
};

const actions = {
  create,
  resetCreate,
  update,
  resetUpdate,
  resetDeleteOne,
  unSaved,
  getAll,
  getOne,
  deleteOne,
  saved,
  addClick,
  addView,
  manageLikeDislike,
  offSet,
  resetOffset,
  updateListItem,
  resetGetAllContents,
};

export default actions;
