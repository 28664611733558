import styled from "styled-components";
import { Input as InputBase } from "../List/components/FilterByName/style";

export const Input = styled(InputBase)`
  height: 24px;
  padding: 4px 4px 3px;
  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);
  border: solid 0.3px #697482;
  min-height: auto;
`;

export const WrapperPage = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: ${(props) => props.theme.theme.bg};
  height: 100%;
  z-index: -1;
`;

export const RoadmapTitle = styled("h2")`
  color: ${(props) => props.theme.theme.text};
  font-size: 16px;
  font-weight: bold;
`;

export const SidebarWrapper = styled("div")`
  position: absolute;
  border-radius: 6px;
  max-height: 33px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  &.active {
    max-height: 300px;
    overflow: visible;
  }
`;

export const SidebarWrapperRelative = styled("div")`
  position: relative;
  height: 100%;
  min-width: 50px;
`;
export const ToggleStyle = styled("div")`
  display: flex;
  gap: 2px;
  width: 70px;

  border-radius: 6px;
  box-shadow: 0 0 3px 0 rgba(79, 38, 162, 0.25);
  background-color: #fff;
  height: 32px;
  overflow: hidden;
  border-radius: 6px;
`;

export const ToggleStyleIcon = styled("div")`
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
  background-color: ${(props) =>
    !!props.active ? props.theme.colors.LightBlue : "#fff"};
  svg {
    transition: color 0.2s ease-in-out;
    color: ${(props) => (!!props.active ? "#fff" : "#000")};
  }
`;
