import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { CommentDto } from "../../../../types/comment.dto";
import { connect, useDispatch } from "react-redux";
import { StateDto } from "../../../../types/states.dto";
import InfinityScroll from "../../../InfinityScroll";
import commentActions from "../../../../store/actions/comment";
import { FlexContainer, Separator } from "../../../StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../constants/form/comment/create";
import Comment from "./Comment";
import Loader from "../../../Loader";
import { UserDto } from "../../../../types/user.dto";
import AddReplyToComment from "../AddReplyToComment";
import { toast } from "react-toastify";
import { AddCommentInputWrapper } from "../AddReplyToComment/style";
import { Formik } from "formik";
import Input from "../../../Form/Input";
import { DateFromNow } from "../../../../helpers/format-date";
import { HiPaperAirplane } from "react-icons/hi2";
import { useTheme } from "styled-components";
interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}
const Component = React.forwardRef(
  (
    {
      comments,
      section,
      itemId,
      getCommentStates,
      user,
      replies,
      getRepliesStates,
      setCurrentIndexFromStack,
      newComment,
      newCommentStates,
      updatedComment,
      updateCommentStates,
    }: {
      comments: CommentDto[];
      section: string;
      itemId: string;
      getCommentStates: StateDto;
      user: UserDto;
      replies: CommentDto[];
      getRepliesStates: StateDto;
      setCurrentIndexFromStack: any;
      newComment: CommentDto;
      newCommentStates: StateDto;
      updatedComment: CommentDto;
      updateCommentStates: StateDto;
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const goBackRef = useRef(null);
    const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
    const [contentStack, setContentStack] = useState<CommentDto[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [currentComments, setCurrentComments] = useState<CommentDto[]>();
    const [page, setPage] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [thereAreItems, setThereAreItems] = useState<boolean>(true);
    const [currentReplies, setCurrentReplies] = useState<CommentDto[]>();
    const pushToStack = (content: CommentDto) => {
      setContentStack((prevStack) => [...prevStack, content]);
      setCurrentIndexFromStack((state) => state + 1);
      setCurrentIndex((state) => state + 1);
    };
    const onSubmit = ({ values, actions }) => {
      dispatch(commentActions.create({ ...values, [`${section}`]: itemId }));
      setFormActions(actions);
    };
    const fetchData = ({ page, filterBy }: FetchData) => {
      let payload = {
        limit: 9,
        offset: 9 * page,
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          replyTo: { $exists: false },
          [`${section}`]: itemId,
        },
      };
      const sortBy = "createdAt";

      const sortDirection = "desc";
      dispatch(commentActions.getAll({ ...payload, sortBy, sortDirection }));
    };

    const fetchDataReplies = ({ comment }: { comment: CommentDto }) => {
      let payload = {
        _id: comment._id,
        /*   limit: 9,
      offset: 9 * page, */
        filterBy: {
          active: true,
          replyTo: comment._id,
        },
      };
      const sortBy = "createdAt";

      const sortDirection = "desc";
      dispatch(
        commentActions.getReplies({ ...payload, sortBy, sortDirection })
      );
    };
    const goBack = () => {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
        setCurrentIndexFromStack((state) => state - 1);
      }
    };

    useEffect(() => {
      if (replies) {
        setCurrentReplies(replies);
      }
    }, [replies]);
    console.log(currentIndex, contentStack, "pila");
    useEffect(() => {
      if (updateCommentStates.success) {
        if (updatedComment.replyTo) {
          setCurrentReplies((state) =>
            state?.map((st) =>
              st._id === updatedComment._id ? updatedComment : st
            )
          );
        } else {
          setCurrentComments((state) =>
            state?.map((st) =>
              st._id === updatedComment._id ? updatedComment : st
            )
          );
        }
        dispatch(commentActions.resetUpdate());
      }
      if (updateCommentStates.error) {
        toast.error(updateCommentStates.error);
        dispatch(commentActions.resetUpdate());
      }
    }, [updateCommentStates]);

    useEffect(() => {
      if (newCommentStates.success) {
        console.log(
          newComment,
          new Date(newComment.createdAt).toISOString(),
          DateFromNow(newComment.createdAt),
          "zumens"
        );
        if (!newComment.replyTo) {
          setCurrentComments((state) => [newComment, ...(state ?? [])]);
        }
        if (
          !!contentStack[currentIndex - 1] &&
          contentStack[currentIndex - 1]._id === newComment.replyTo
        ) {
          setCurrentReplies((state) => [newComment, ...(state ?? [])]);
          setCurrentComments((state) =>
            state?.map((st) => {
              return { ...st, replyCount: st.replyCount + 1 };
            })
          );
        }
        formActions?.resetForm();
        dispatch(commentActions.resetCreate());
      }
      if (newCommentStates.error) {
        toast.error(newCommentStates.error);
        dispatch(commentActions.resetCreate());
      }
    }, [newCommentStates]);

    useEffect(() => {
      if (currentIndex > 0 && !!contentStack[currentIndex - 1]) {
        fetchDataReplies({ comment: contentStack[currentIndex - 1] });
      }
    }, [currentIndex, contentStack]);

    useEffect(() => {
      if (comments && !isLoading) {
        setCurrentComments((state) => [...(state ?? []), ...comments]);
      }
      setThereAreItems(!!comments && comments?.length > 0);
    }, [comments, isLoading]);

    useEffect(() => {
      setIsLoading(getCommentStates.loading);
    }, [getCommentStates]);

    useImperativeHandle(ref, () => ({
      goBack,
    }));

    return (
      <>
        {currentIndex === 0 ? (
          <InfinityScroll
            action={fetchData}
            page={page}
            setPage={setPage}
            data={currentComments}
            active={thereAreItems}
            isLoading={isLoading}
            noMessage={true}
            style={{ paddingBottom: "62px" }}
          >
            <FlexContainer direction="column" gap="12px">
              {currentComments?.map((comment) => {
                return (
                  <Comment
                    key={comment._id}
                    noAnswer={false}
                    answerOnClick={(value) => {
                      pushToStack(value);
                    }}
                    comment={comment}
                    user={user}
                    className="parent"
                  ></Comment>
                );
              })}
            </FlexContainer>
            <AddCommentInputWrapper>
              <Formik
                initialValues={initialValues({})}
                onSubmit={(values, actions) => {
                  if (onSubmit) onSubmit({ values, actions });
                }}
                validationSchema={schema}
                validateOnChange={true}
                validateOnBlur={false}
                enableReinitialize
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => {
                  return (
                    <form
                      style={{ width: "100%" }}
                      onSubmit={(event) => {
                        handleSubmit(event);
                      }}
                      id="comment-path"
                    >
                      <FlexContainer align="center" gap="10px">
                        <Input
                          name="text"
                          error={errors["text"]}
                          touched={touched["text"]}
                          value={values["text"]}
                          type="text"
                          inputStyles={{
                            fontSize: "12px",
                            fontWeight: "500",
                            height: "29px",
                          }}
                          placeholder="Escribe un comentario"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          options={{
                            marginBottom: 0.1,
                            skin: "gray",
                          }}
                        />
                        <button
                          style={{
                            all: "unset",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          type="submit"
                        >
                          <HiPaperAirplane
                            color={theme.colors.LightBlue}
                            fontSize={20}
                          ></HiPaperAirplane>
                        </button>
                      </FlexContainer>
                    </form>
                  );
                }}
              </Formik>
              <Separator size={10}></Separator>
            </AddCommentInputWrapper>
          </InfinityScroll>
        ) : (
          <>
            {contentStack[currentIndex - 1] ? (
              <>
                <div
                  style={{
                    padding: "2px 0px",
                    borderBottom: "1px solid rgba(106, 105, 112, 0.25)",
                    marginBottom: "10px",
                  }}
                >
                  <Comment
                    comment={contentStack[currentIndex - 1]}
                    user={user}
                    className="parent"
                    noAnswer={true}
                  ></Comment>
                </div>
                <div style={{ paddingLeft: "26px" }}>
                  {getRepliesStates.loading || !currentReplies ? (
                    <Loader color="LightBlue"></Loader>
                  ) : (
                    <FlexContainer direction="column" gap="10px">
                      {currentReplies.map((reply) => {
                        return (
                          <Comment
                            key={reply._id}
                            answerOnClick={(value) => {
                              pushToStack(value);
                            }}
                            comment={reply}
                            user={user}
                            className="child"
                          ></Comment>
                        );
                      })}
                    </FlexContainer>
                  )}
                </div>
                <AddReplyToComment
                  itemId={itemId}
                  section={section}
                  replyTo={contentStack[currentIndex - 1]._id}
                ></AddReplyToComment>
              </>
            ) : (
              <Loader color="LightBlue"></Loader>
            )}
          </>
        )}
      </>
    );
  }
);

const states = ({ commentStore, userStore }) => {
  const { data: comments, states: getCommentStates } = commentStore.all;
  const { data: replies, states: getRepliesStates } = commentStore.replies;
  const { data: newComment, states: newCommentStates } = commentStore.create;
  const { data: updatedComment, states: updateCommentStates } =
    commentStore.update;
  const { data: user } = userStore;
  return {
    comments,
    getCommentStates,
    user,
    replies,
    getRepliesStates,
    newComment,
    newCommentStates,
    updatedComment,
    updateCommentStates,
  };
};

export default connect(states, null, null, { forwardRef: true })(Component);
