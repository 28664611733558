import React, { useEffect, useState } from "react";
import { useLocation, Navigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../provider/AuthProvider";
import { connect, useDispatch } from "react-redux";
import { StateDto } from "../../types/states.dto";
import { Wrapper } from "./style";
import Loader from "../Loader";
import {
  autoSignIn,
  resetAutoSignIn,
  resetSignIn,
  signInSocial,
} from "../../store/actions/auth";
import { UserDto } from "../../types/user.dto";
import { toast } from "react-toastify";
import { hideModal } from "../../store/actions/modal";

const Component = ({
  children,
  autoSignInStates,
  user,
  signinStates,
}: {
  children: JSX.Element;
  autoSignInStates: StateDto;
  user: UserDto;
  signinStates: StateDto;
}) => {
  /* let { isAuthenticated } = React.useContext(AuthContext); */
  const dispatch = useDispatch();
  const location = useLocation();
  const [verifiedTokenOrGuest, setVerifiedTokenOrGuest] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const accessToken = (
    document.cookie.split(";").find((x) => x.includes("refreshToken")) || "="
  ).split("=")[1];

  useEffect(() => {
    if (accessToken) {
      dispatch(autoSignIn());
    } else {
      setVerifiedTokenOrGuest(true);
    }
  }, []);

  useEffect(() => {
    if (autoSignInStates.success && user) {
      setVerifiedTokenOrGuest(true);
      dispatch(resetAutoSignIn());
    }
  }, [autoSignInStates, user]);

  useEffect(() => {
    if (signinStates.error) {
      toast.error(`😱 ${signinStates.error}`, {
        containerId: "container-sign-in",
        toastId: "SING-IN",
      });
      setTimeout(() => {
        dispatch(resetSignIn());
      }, 1000);
    }
    if (signinStates.success) {
      setTimeout(() => {
        document.location =
          (location.state as any)?.pathname ?? "/dashboard/home";

        dispatch(resetSignIn());
      }, 1000);
    }
  }, [signinStates]);

  useEffect(() => {
    if (searchParams.get("code")) {
      if (location.search.split("%3D")[1] === "github") {
        dispatch(
          signInSocial({ social: "github", code: searchParams.get("code") })
        );
      }
      if (location.search.split("%3D")[1] === "linkedin") {
        dispatch(
          signInSocial({ social: "linkedin", code: searchParams.get("code") })
        );
      }
    }
  }, [searchParams]);

  return (
    <>
      {!verifiedTokenOrGuest ? (
        <Wrapper>
          <Loader color="LightBlue" />
        </Wrapper>
      ) : (
        children
      )}
    </>
  );
};

const states = ({ authStore, userStore }) => {
  const { states: autoSignInStates } = authStore.autoSignIn;
  const { states: signinStates } = authStore.signin;
  const { data: user } = userStore;
  return { autoSignInStates, user, signinStates };
};
export default connect(states)(Component);
