import { put, call, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  AUTO_SIGN_IN_SUCCESS,
  SIGN_IN_SUCCESS,
  SIGN_UP_WITH_SOCIAL_SUCCESS,
} from "../types/auth";
import {
  GetUserInformation,
  GET_USER_INFORMATION,
  GET_USER_INFORMATION_SUCCESS,
  GET_USER_INFORMATION_ERROR,
  SetUserInformation,
  SET_USER_INFORMATION,
  SET_USER_INFORMATION_SUCCESS,
  SET_USER_INFORMATION_ERROR,
  UpdateUser,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  FORGOT_PASSWORD,
  ForgotPassword,
  SHOWN_COHORT,
} from "../types/user";
import { GET_ALL_USERS, UPDATE_USERS_SUCCESS } from "../types/users";

const currentUser = ({ userStore }) => userStore.userInformation;

function* getUserInformation({ payload }) {
  try {
    const { response, error } = yield call(services.user.getOne, payload._id);
    if (error) {
      yield put({
        type: SET_USER_INFORMATION_ERROR,
        payload: error.response?.data?.message || "Error",
      });
    } else {
      yield put({
        type: SET_USER_INFORMATION_SUCCESS,
        payload: { ...payload, ...response },
      });
    }
  } catch {
    yield put({
      type: SET_USER_INFORMATION_ERROR,
      payload: "Error al obtener información de usuario",
    });
  }
}

function* setUserInformation({ payload }) {
  try {
    yield put({ type: SET_USER_INFORMATION_SUCCESS, payload });
  } catch {
    yield put({
      type: SET_USER_INFORMATION_ERROR,
      payload: "Error al almacenar la infomación del usuario",
    });
  }
}

function* updateUser({ payload }) {
  const { response, error } = yield call(services.user.update, payload);
  if (error) {
    yield put({
      type: UPDATE_USER_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({
      type: SET_USER_INFORMATION_SUCCESS,
      payload: response,
    });
    yield put({
      type: GET_ALL_USERS,
      payload: null,
    });
    yield put({
      type: UPDATE_USER_SUCCESS,
      payload: null,
    });
  }
}

function* shownCohort({ payload }) {
  console.log("uyee", payload);
  const { response, error } = yield call(services.user.shownCohort, payload);
  if (error) {
    yield put({
      type: UPDATE_USER_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({
      type: UPDATE_USER_SUCCESS,
      payload: response,
    });
    yield put({
      type: SET_USER_INFORMATION_SUCCESS,
      payload: response,
    });
  }
}

function* changePassword({ payload }) {
  const { response, error } = yield call(
    services.user.forgottenPassword,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_USER_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({
      type: UPDATE_USER_SUCCESS,
      payload: response,
    });
    yield put({
      type: SET_USER_INFORMATION_SUCCESS,
      payload: response,
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetUserInformation>(
    GET_USER_INFORMATION,
    getUserInformation
  );
  yield takeLatest<SetUserInformation>(
    SET_USER_INFORMATION,
    setUserInformation
  );
  yield takeLatest<any>(SIGN_IN_SUCCESS, getUserInformation);
  yield takeLatest<any>(SIGN_UP_WITH_SOCIAL_SUCCESS, getUserInformation);
  yield takeLatest<any>(SIGN_UP_WITH_SOCIAL_SUCCESS, setUserInformation);
  yield takeLatest<any>(AUTO_SIGN_IN_SUCCESS, getUserInformation);
  yield takeLatest<UpdateUser>(UPDATE_USER, updateUser);
  yield takeLatest<ForgotPassword>(FORGOT_PASSWORD, changePassword);
  yield takeLatest<any>(SHOWN_COHORT, shownCohort);
}
