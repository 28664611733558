import { ConfirmationAuthDto } from "../../types/confirmationAuth";

export const GET_CONFIRMATIONAUTH = "GET_CONFIRMATIONAUTH";
export const GET_CONFIRMATIONAUTH_ERROR = "GET_CONFIRMATIONAUTH_ERROR";
export const GET_CONFIRMATIONAUTH_SUCCESS = "GET_CONFIRMATIONAUTH_SUCCESS";
export const RESET_GET_CONFIRMATIONAUTH = "RESET_GET_CONFIRMATIONAUTH";
export const UPDATE_CONFIRMATIONAUTH = "UPDATE_CONFIRMATIONAUTH";
export const UPDATE_CONFIRMATIONAUTH_ERROR = "UPDATE_CONFIRMATIONAUTH_ERROR";
export const UPDATE_CONFIRMATIONAUTH_SUCCESS =
  "UPDATE_CONFIRMATIONAUTH_SUCCESS";
export const RESET_UPDATE_CONFIRMATIONAUTH = "RESET_UPDATE_CONFIRMATIONAUTH";

export interface GetConfirmationAuth {
  type: typeof GET_CONFIRMATIONAUTH;
  payload: { id: string };
}

export interface GetConfirmationAuthSuccess {
  type: typeof GET_CONFIRMATIONAUTH_SUCCESS;
  payload: ConfirmationAuthDto;
}

export interface ResetGetConfirmationAuth {
  type: typeof RESET_GET_CONFIRMATIONAUTH;
  payload: null;
}

export interface GetConfirmationAuthError {
  type: typeof GET_CONFIRMATIONAUTH_ERROR;
  payload: boolean | string;
}

export interface UpdateConfirmationAuth {
  type: typeof UPDATE_CONFIRMATIONAUTH;
  payload: ConfirmationAuthDto;
}

export interface UpdateConfirmationAuthSuccess {
  type: typeof UPDATE_CONFIRMATIONAUTH_SUCCESS;
  payload: ConfirmationAuthDto;
}

export interface UpdateConfirmationAuthError {
  type: typeof UPDATE_CONFIRMATIONAUTH_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateConfirmationAuth {
  type: typeof RESET_UPDATE_CONFIRMATIONAUTH;
  payload: null;
}

export interface State {
  confirmationAuth: {
    data: ConfirmationAuthDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateConfirmationAuth: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  confirmationAuth: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateConfirmationAuth: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetConfirmationAuth
  | GetConfirmationAuthSuccess
  | GetConfirmationAuthError
  | ResetGetConfirmationAuth
  | UpdateConfirmationAuth
  | UpdateConfirmationAuthSuccess
  | UpdateConfirmationAuthError
  | ResetUpdateConfirmationAuth;
