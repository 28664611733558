import {
  NewCertificate,
  NEW_CERTIFICATE,
  ResetNewCertificate,
  RESET_NEW_CERTIFICATE,
  GET_ALL_CERTIFICATES,
  GetAllCertificates,
  UpdateCertificate,
  UPDATE_CERTIFICATE,
  ResetUpdateCertificate,
  RESET_UPDATE_CERTIFICATE,
  GetCertificate,
  DeleteCertificate,
  DELETE_CERTIFICATE,
  GET_CERTIFICATE,
  RESET_DELETE_CERTIFICATE,
  RESET_GET_ALL_CERTIFICATE_DATA,
  GET_CERTIFICATES_BY_ACADEMY,
  GetCertificatesByAcademy,
} from "../types/certificate";

const create = (payload): NewCertificate => {
  return { type: NEW_CERTIFICATE, payload };
};
const resetCreate = (): ResetNewCertificate => {
  return { type: RESET_NEW_CERTIFICATE, payload: null };
};

const resetGetAllCertificates = () => {
  return { type: RESET_GET_ALL_CERTIFICATE_DATA, payload: null };
};

const update = (payload): UpdateCertificate => {
  return { type: UPDATE_CERTIFICATE, payload };
};

const resetUpdate = (): ResetUpdateCertificate => {
  return { type: RESET_UPDATE_CERTIFICATE, payload: null };
};

const getAll = (payload): GetAllCertificates => {
  return { type: GET_ALL_CERTIFICATES, payload };
};

const getAllByAcademy = (payload): GetCertificatesByAcademy => {
  return { type: GET_CERTIFICATES_BY_ACADEMY, payload };
};

const getOne = (payload: { _id: string }): GetCertificate => {
  return { type: GET_CERTIFICATE, payload };
};

const deleteOne = (payload: { _id: string }): DeleteCertificate => {
  return { type: DELETE_CERTIFICATE, payload };
};

const resetDeleteOne = () => {
  return { type: RESET_DELETE_CERTIFICATE, payload: null };
};

const actions = {
  create,
  resetCreate,
  update,
  resetUpdate,
  resetDeleteOne,
  getAll,
  getOne,
  deleteOne,
  resetGetAllCertificates,
  getAllByAcademy,
};

export default actions;
