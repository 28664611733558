import New from "./New";
import Edit from "./Edit";
import List from "./List";
import Preview from "./Preview";

const Mentor = {
  New,
  Edit,
  Preview,
  List,
};

export default Mentor;
