import {
  GetAllRoadmaps,
  GET_ALL_ROADMAPS,
  GetRoadmap,
  GET_ROADMAP,
  UpdateRoadmap,
  UPDATE_ROADMAP,
  DeleteRoadmap,
  DELETE_ROADMAP,
  GetRoadmapSuccess,
  GET_ROADMAP_SUCCESS,
  NewRoadmap,
  NEW_ROADMAP,
  ResetNewRoadmap,
  RESET_NEW_ROADMAP,
  ResetUpdateRoadmap,
  RESET_UPDATE_ROADMAP,
  ResetDeleteRoadmap,
  RESET_DELETE_ROADMAP,
  UploadMedia,
  UPLOAD_MEDIA,
  RESET_GET_ROADMAP,
  ADD_USER_TO_ROADMAP,
  SHARED_ROADMAP,
  UNSAVED_ROADMAP,
  SAVED_ROADMAP,
  QUERY_ROADMAP,
  AddClickRoadmap,
  ADD_CLICK_ROADMAP,
  AddViewRoadmap,
  ADD_VIEW_ROADMAP,
  MANAGE_LIKE_DISLIKE_ROADMAP,
  GET_ROADMAPS_OFFSET,
  GetRoadmapsOffset,
  RESET_GET_ROADMAPS_OFFSET,
  ResetGetRoadmapsOffset,
  RESET_GET_ALL_ROADMAP_DATA,
  RESET_GET_ONE_ROADMAP_DATA,
  UPDATE_ITEM_LIST_ROADMAP,
} from "../types/roadmap";

export const getAllRoadmaps = (payload): GetAllRoadmaps => {
  return { type: GET_ALL_ROADMAPS, payload };
};

export const getRoadmap = (id): GetRoadmap => {
  return { type: GET_ROADMAP, payload: id };
};

export const updateRoadmapistItem = (payload) => {
  return { type: UPDATE_ITEM_LIST_ROADMAP, payload };
};

export const resetGetRoadmap = () => {
  return { type: RESET_GET_ROADMAP, payload: null };
};

export const unSavedRoadmap = (payload) => {
  return { type: UNSAVED_ROADMAP, payload };
};

export const queryRoadmap = (payload) => {
  return { type: QUERY_ROADMAP, payload };
};

export const savedRoadmap = (payload) => {
  return { type: SAVED_ROADMAP, payload };
};

export const setSelectedRoadmap = (payload): GetRoadmapSuccess => {
  return { type: GET_ROADMAP_SUCCESS, payload };
};

export const newRoadmap = (payload): NewRoadmap => {
  return { type: NEW_ROADMAP, payload };
};
export const uploadMediaRoadmap = (payload): UploadMedia => {
  return { type: UPLOAD_MEDIA, payload };
};
export const resetNewRoadmap = (): ResetNewRoadmap => {
  return { type: RESET_NEW_ROADMAP, payload: null };
};

export const updateRoadmap = (payload): UpdateRoadmap => {
  return { type: UPDATE_ROADMAP, payload };
};

export const resetUpdateRoadmap = (): ResetUpdateRoadmap => {
  return { type: RESET_UPDATE_ROADMAP, payload: null };
};

export const addUserToRoadmap = (payload) => {
  return { type: ADD_USER_TO_ROADMAP, payload };
};

export const deleteRoadmap = (payload): DeleteRoadmap => {
  return { type: DELETE_ROADMAP, payload };
};
export const offSetRoadmaps = (payload): GetRoadmapsOffset => {
  return { type: GET_ROADMAPS_OFFSET, payload };
};

export const resetOffsetRoadmaps = (): ResetGetRoadmapsOffset => {
  return { type: RESET_GET_ROADMAPS_OFFSET, payload: null };
};

export const resetGetAllRoadmaps = () => {
  return { type: RESET_GET_ALL_ROADMAP_DATA, payload: null };
};

export const resetGetOneRoadmap = () => {
  return { type: RESET_GET_ONE_ROADMAP_DATA, payload: null };
};

export const resetDeleteRoadmap = (): ResetDeleteRoadmap => {
  return { type: RESET_DELETE_ROADMAP, payload: null };
};

export const shareRoadmap = (payload) => {
  return { type: SHARED_ROADMAP, payload };
};

export const addClick = (payload): AddClickRoadmap => {
  return { type: ADD_CLICK_ROADMAP, payload };
};

export const addView = (payload): AddViewRoadmap => {
  return { type: ADD_VIEW_ROADMAP, payload };
};

export const manageLikeDislikeRoadmap = (payload) => {
  return { type: MANAGE_LIKE_DISLIKE_ROADMAP, payload };
};
