import styled from "styled-components";

export const ListItem = styled("div")`
  background-color: #fff;
  padding: 4px 15px 4px 20px;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(36, 150, 237, 0.25);
  -webkit-box-shadow: 0 0 6px 0 rgba(36, 150, 237, 0.25);
`;

export const ListWrapper = styled("div")`
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;
  padding: 9px 38px 44px 118px;
  background-color: #fff;
`;

export const EntityImageContainer = styled("div")`
  width: 32px;
  height: 32px;
  overflow: hidden;
  box-shadow: 0 0 1px 0 rgba(0, 30, 108, 0.16);
  border-radius: 50%;
`;

export const EntityTitleSemiBold = styled("h3")`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  color: #2a2747;
`;

export const EntityTitleDescription = styled("h3")`
  max-width: 412px;
  font-family: DMSans;
  font-size: 12px;
  font-weight: normal;
  color: #697482;
`;
