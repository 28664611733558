import Modal from "../../../../../../components/Modal";
import NewCategoryForm from "../../../New";

const Component = () => {
  return (
    <Modal name="new-category-modal" title="Nueva Categoría">
      <NewCategoryForm />
    </Modal>
  );
};

export default Component;
