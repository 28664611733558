import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import ModalRestItems from "./components/ModalRestItems";
import { Tabs, Header, Body, Toggle, Content } from "./styles";
import { useWindowSize } from "../../hooks/useWindowScreen";
import { addClick } from "../../store/actions/userTimes";
import ToggleSlider from "../ToggleSlider";
const Component = ({ children, style, selectedAcademy, user }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState();
  const { isMobile } = useWindowSize();
  return (
    <>
      <Tabs>
        {isMobile ? (
          <ToggleSlider>
            {React.Children.map(children, (child, index) => {
              if (!child) return null;

              const { id, label } = child.props;

              if (!activeTab && index === 0) {
                setActiveTab(id);
              }

              return (
                !!id && (
                  <Toggle
                    style={{ margin: "0" }}
                    type="button"
                    active={activeTab === id}
                    onClick={() => {
                      dispatch(
                        addClick({
                          id: selectedAcademy._id,
                          user: user._id,
                          type: "ACADEMY",
                        })
                      );
                      setActiveTab(id);
                    }}
                  >
                    {label}
                  </Toggle>
                )
              );
            })}
          </ToggleSlider>
        ) : (
          <Header style={style}>
            {React.Children.map(children, (child, index) => {
              if (!child) return null;

              const { id, label } = child.props;

              if (!activeTab && index === 0) {
                setActiveTab(id);
              }

              return (
                !!id && (
                  <Toggle
                    type="button"
                    active={activeTab === id}
                    onClick={() => {
                      dispatch(
                        addClick({
                          id: selectedAcademy._id,
                          user: user._id,
                          type: "ACADEMY",
                        })
                      );
                      setActiveTab(id);
                    }}
                  >
                    {label}
                  </Toggle>
                )
              );
            })}
          </Header>
        )}

        <Body isMobile={isMobile}>
          {React.Children.map(children, (child) => {
            const { id } = child.props;
            return !!id && <Content active={activeTab === id}>{child}</Content>;
          })}
        </Body>
      </Tabs>
      {/* <ModalRestItems
        setActiveTab={setActiveTab}
        tabs={children}
        activeTab={activeTab}
      ></ModalRestItems> */}
    </>
  );
};

const states = ({ academyStore, userStore }) => {
  const { data: selectedAcademy } = academyStore.selected;
  const { data: user } = userStore;
  return {
    selectedAcademy,
    user,
  };
};

export default connect(states)(Component);
