import styled from "styled-components";

export const CustomSlider = styled("div")`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  position: relative;
  padding: 4px; /* Add some padding to the slider container */
  /* Hide the scrollbar */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  ::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for Chrome, Safari, and newer versions of Edge */
  }
  width: 100%; /* Ensure the slider container takes the full width */
  transition: scroll 0.8s ease-in-out; /* Add a smooth transition for the scroll property */
`;

export const CustomSliderItem = styled("div")`
  scroll-snap-align: start;
  cursor: pointer;
  margin: 0px 10px;
`;
