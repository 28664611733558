import { put, call, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  SET_USER_INFORMATION,
  SET_USER_INFORMATION_SUCCESS,
} from "../types/user";
import {
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  GetAllUsers,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_ERROR,
  UPDATE_USERS,
  UpdateUsers,
  GetUsersByAcademy,
  GET_USERS_BY_ACADEMY,
} from "../types/users";

function* getAllCategories({ payload }) {
  const { response, error } = yield call(services.users.getAll, payload);
  if (error) {
    yield put({
      type: GET_ALL_USERS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_USERS_SUCCESS, payload: response });
  }
}
function* updateUsers({ payload }) {
  const { response, error } = yield call(services.users.update, payload);
  if (error) {
    yield put({
      type: UPDATE_USERS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_USERS_SUCCESS, payload: response });
    yield put({ type: SET_USER_INFORMATION_SUCCESS, payload: response });
  }
}
function* getUsersByAcademy({ payload }) {
  const { response, error } = yield call(
    services.users.getUsersByAcademy,
    payload
  );
  if (error) {
    yield put({
      type: GET_ALL_USERS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_USERS_SUCCESS, payload: response });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllUsers>(GET_ALL_USERS, getAllCategories);
  yield takeLatest<UpdateUsers>(UPDATE_USERS, updateUsers);
  yield takeLatest<GetUsersByAcademy>(GET_USERS_BY_ACADEMY, getUsersByAcademy);
}
