import { connect } from "react-redux";
import { UserDto } from "../../types/user.dto";
import { Avatar, Letter } from "./styles";

const sizes = {
  small: {
    fontSize: "18px",
    width: "42px",
    height: "42px",
  },
  big: {
    fontSize: "32px",
    width: "80px",
    height: "80px",
  },
};

interface ComponentProps {
  user: UserDto;
  size?: "small" | "big";
  style?;
}

const Component = ({ user, size = "small", style }: ComponentProps) => {
  return (
    <Avatar style={{ ...sizes[size], ...style }}>
      <Letter>{user?.name?.charAt(0)}</Letter>
    </Avatar>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;

  return {
    user,
  };
};

export default connect(states)(Component);
