import React, { useEffect, ReactNode, CSSProperties } from "react";
import Loader from "../Loader";
import Slider from "react-slick";
import { SliderButton } from "./styles";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import "./style.css";
import CenterTitle from "../CenterTitle";
import { Separator } from "../StyledComponents";
import { useWindowSize } from "../../hooks/useWindowScreen";
interface ComponentProps {
  disabled?: boolean;
  data;
  children;
  active: boolean;
  action: ({ page }: { page: number }) => void;
  isLoading: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  style?: CSSProperties;
  preventFirstFetch?: boolean;
  carouselSettings?: {
    afterChange?: (currentSlide: number) => void;
    dots?: boolean;
    centerMode?: boolean;
    centerPadding?: string;
    infinite?: boolean;
    speed?: number;
    slidesToShow?: number;
    slidesToScroll?: number;
    arrows?: boolean;
    className?: string;
    nextArrow?: ReactNode | string;
    prevArrow?: ReactNode | string;
    responsive?: {
      breakpoint?: number;
      settings?: {
        slidesToShow?: number;
        slidesToScroll?: number;
      };
    }[];
  };
}

const Component = ({
  disabled,
  data,
  children,
  active,
  action,
  isLoading,
  page,
  setPage,
  style,
  carouselSettings,
  preventFirstFetch,
}: ComponentProps) => {
  const { isMobile } = useWindowSize();
  console.log({ hijos: React.Children.count(children) }, "totales");
  const handleAfterChange = (currentSlide) => {
    const totalSlides = React.Children.count(children);
    console.log(totalSlides, currentSlide, "totales");
    if (!isMobile) {
      if (currentSlide % totalSlides === 0 && active) {
        action({ page: page + 1 });
        setPage(page + 1);
      }
    } else {
      if (currentSlide + 3 === totalSlides + 1 && active) {
        // You are on the last slide, trigger your function here
        action({ page: page + 1 });
        setPage(page + 1);
      }
    }
  };

  // Slick carousel settings
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,

    className: disabled ? "fade" : "",
    nextArrow: (
      <SliderButton>
        <GrFormNext size={19} color="#2496ed"></GrFormNext>
      </SliderButton>
    ),
    prevArrow: (
      <SliderButton>
        <GrFormPrevious size={19} color={"#2496ed"}></GrFormPrevious>
      </SliderButton>
    ),
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    ...carouselSettings,

    //No mover esto, siempre debe quedar en ultimo lugar del objeto settings
    afterChange: (data) => {
      handleAfterChange(data);
      carouselSettings?.afterChange && carouselSettings.afterChange(data);
    },
  };

  useEffect(() => {
    if (!preventFirstFetch) action({ page });
  }, [preventFirstFetch]);

  return (
    <>
      {!active && data && data.length === 0 ? (
        <CenterTitle
          title="No hay elementos"
          style={{ minHeight: "150px" }}
        ></CenterTitle>
      ) : (
        <Slider {...settings}>
          {children}
          {active && isMobile && (
            <div className="trigger">
              <div
                style={{
                  width: "100%",
                  height: 110,
                  background: "#dfdfdf",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 20,
                }}
              >
                <Loader color="LightBlue" />
              </div>
            </div>
          )}
          {active && !isMobile && (
            <div className="trigger">
              <div
                style={{
                  width: "100%",
                  height: 200,
                  background: "#dfdfdf",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 20,
                }}
              >
                <Loader color="LightBlue" />
              </div>
            </div>
          )}
          {active && !isMobile && (
            <div className="trigger">
              <div
                style={{
                  width: "100%",
                  height: 200,
                  background: "#dfdfdf",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 20,
                }}
              >
                <Loader color="LightBlue" />
              </div>
            </div>
          )}
          {active && !isMobile && (
            <div className="trigger">
              <div
                style={{
                  width: "100%",
                  height: 200,
                  background: "#dfdfdf",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 20,
                }}
              >
                <Loader color="LightBlue" />
              </div>
            </div>
          )}
        </Slider>
      )}
    </>
  );
};

export default Component;
