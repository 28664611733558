import { RoadmapDto } from "../../types/roadmap.dto";

export const GET_ALL_ROADMAPS = "GET_ALL_ROADMAP";
export const GET_ALL_ROADMAPS_ERROR = "GET_ALL_ROADMAP_ERROR";
export const GET_ALL_ROADMAPS_SUCCESS = "GET_ALL_ROADMAP_SUCCESS";
export const GET_ROADMAP = "GET_ROADMAP";
export const GET_ROADMAP_ERROR = "GET_ROADMAP_ERROR";
export const GET_ROADMAP_SUCCESS = "GET_ROADMAP_SUCCESS";
export const RESET_GET_ROADMAP = "RESET_GET_ROADMAP";
export const SET_GET_ROADMAP = "SET_GET_ROADMAP";
export const NEW_ROADMAP = "NEW_ROADMAP";
export const NEW_ROADMAP_ERROR = "NEW_ROADMAP_ERROR";
export const NEW_ROADMAP_SUCCESS = "NEW_ROADMAP_SUCCESS";
export const RESET_NEW_ROADMAP = "RESET_NEW_ROADMAP";
export const UPDATE_ROADMAP = "UPDATE_ROADMAP";
export const UPDATE_ROADMAP_ERROR = "UPDATE_ROADMAP_ERROR";
export const UPDATE_ROADMAP_SUCCESS = "UPDATE_ROADMAP_SUCCESS";
export const RESET_UPDATE_ROADMAP = "RESET_UPDATE_ROADMAP";
export const DELETE_ROADMAP = "DELETE_ROADMAP";
export const DELETE_ROADMAP_ERROR = "DELETE_ROADMAP_ERROR";
export const DELETE_ROADMAP_SUCCESS = "DELETE_ROADMAP_SUCCESS";
export const RESET_DELETE_ROADMAP = "RESET_DELETE_ROADMAP";
export const UPLOAD_MEDIA = "UPLOAD_MEDIA";
export const UPDATE_ROADMAP_LOADING = "UPDATE_ROADMAP_LOADING";
export const ADD_USER_TO_ROADMAP = "ADD_USER_TO_ROADMAP";
export const SHARED_ROADMAP = "SHARED_ROADMAP";
export const UNSAVED_ROADMAP = "UNSAVED_ROADMAP";
export const SAVED_ROADMAP = "SAVED_ROADMAP";
export const QUERY_ROADMAP = "QUERY_ROADMAP";
export const QUERY_ROADMAP_SUCCESS = "QUERY_ROADMAP_SUCCESS";
export const QUERY_ROADMAP_ERROR = "QUERY_ROADMAP_ERROR";
export const RESET_QUERY_ROADMAP = "RESET_QUERY_ROADMAP";
export const ADD_CLICK_ROADMAP = "ADD_CLICK_ROADMAP";
export const ADD_VIEW_ROADMAP = "ADD_VIEW_ROADMAP";
export const UPDATE_CURRENT_ROADMAP_LIST = "UPDATE_CURRENT_ROADMAP_LIST";
export const MANAGE_LIKE_DISLIKE_ROADMAP = "MANAGE_LIKE_DISLIKE_ROADMAP";
export const UPDATE_ONE_ROADMAP = "UPDATE_ONE_ROADMAP";
export const UPDATE_ONE_ROADMAP_SUCCESS = "UPDATE_ONE_ROADMAP_SUCCESS";
export const UPDATE_ONE_ROADMAP_ERROR = "UPDATE_ONE_ROADMAP_ERROR";
export const RESET_UPDATE_ONE_ROADMAP = "RESET_UPDATE_ONE_ROADMAP";
export const GET_ROADMAPS_OFFSET = "GET_ROADMAPS_OFFSET";
export const GET_ROADMAPS_OFFSET_SUCCESS = "GET_ROADMAPS_OFFSET_SUCCESS";
export const GET_ROADMAPS_OFFSET_ERROR = "GET_ROADMAPS_OFFSET_ERROR";
export const RESET_GET_ROADMAPS_OFFSET = "RESET_GET_ROADMAPS_OFFSET";
export const RESET_GET_ALL_ROADMAP_DATA = "RESET_GET_ALL_ROADMAP_DATA";
export const RESET_GET_ONE_ROADMAP_DATA = "RESET_GET_ONE_ROADMAP_DATA";
export const UPDATE_ITEM_LIST_ROADMAP = "UPDATE_ITEM_LIST_ROADMAP";

export interface ResetGetAllRoadmapData {
  type: typeof RESET_GET_ALL_ROADMAP_DATA;
  payload: null;
}

export interface UpdateItemListRoadmap {
  type: typeof UPDATE_ITEM_LIST_ROADMAP;
  payload: any;
}

export interface ResetGetOneRoadmapData {
  type: typeof RESET_GET_ONE_ROADMAP_DATA;
  payload: null;
}

export interface GetRoadmapsOffset {
  type: typeof GET_ROADMAPS_OFFSET;
  payload: { limit: number; offset: number };
}

export interface GetRoadmapsOffsetError {
  type: typeof GET_ROADMAPS_OFFSET_ERROR;
  payload: string | boolean;
}

export interface GetRoadmapsOffsetSuccess {
  type: typeof GET_ROADMAPS_OFFSET_SUCCESS;
  payload: RoadmapDto[];
}

export interface ResetGetRoadmapsOffset {
  type: typeof RESET_GET_ROADMAPS_OFFSET;
  payload: null;
}
export interface UpdateOneRoadmap {
  type: typeof UPDATE_ONE_ROADMAP;
  payload: any;
}
export interface UpdateOneRoadmapSuccess {
  type: typeof UPDATE_ONE_ROADMAP_SUCCESS;
  payload: RoadmapDto;
}
export interface UpdateOneRoadmapError {
  type: typeof UPDATE_ONE_ROADMAP_ERROR;
  payload: boolean | string;
}
export interface ResetUpdateOneRoadmap {
  type: typeof RESET_UPDATE_ONE_ROADMAP;
  payload: null;
}
export interface ManageLikeDislikeRoadmap {
  type: typeof MANAGE_LIKE_DISLIKE_ROADMAP;
  payload: {
    _id: string;
    user: string;
    condition: "like" | "dislike";
    action: "add" | "remove";
  };
}

export interface AddClickRoadmap {
  type: typeof ADD_CLICK_ROADMAP;
  payload: { _id: string; currentCohort?: string; currentAcademy?: string };
}
export interface AddViewRoadmap {
  type: typeof ADD_VIEW_ROADMAP;
  payload: { _id: string };
}

export interface SavedRoadmap {
  type: typeof SAVED_ROADMAP;
  payload: string;
}
export interface UnSavedRoadmap {
  type: typeof UNSAVED_ROADMAP;
  payload: string;
}
export interface SharedRoadmap {
  type: typeof SHARED_ROADMAP;
  payload: any;
}
export interface GetAllRoadmaps {
  type: typeof GET_ALL_ROADMAPS;
  payload: null;
}

export interface GetAllRoadmapsSuccess {
  type: typeof GET_ALL_ROADMAPS_SUCCESS;
  payload: RoadmapDto[];
}

export interface SetGetRoadmap {
  type: typeof SET_GET_ROADMAP;
  payload: RoadmapDto | null;
}

export interface GetAllRoadmapsError {
  type: typeof GET_ALL_ROADMAPS_ERROR;
  payload: boolean | string;
}

export interface UpdateCurrentRoadmapList {
  type: typeof UPDATE_CURRENT_ROADMAP_LIST;
  payload: RoadmapDto[];
}

export interface AddUserToRoadmap {
  type: typeof ADD_USER_TO_ROADMAP;
  payload: any;
}

export interface GetRoadmap {
  type: typeof GET_ROADMAP;
  payload: { id: string };
}

export interface GetRoadmapSuccess {
  type: typeof GET_ROADMAP_SUCCESS;
  payload: RoadmapDto;
}

export interface GetRoadmapError {
  type: typeof GET_ROADMAP_ERROR;
  payload: boolean | string;
}

export interface QueryRoadmap {
  type: typeof QUERY_ROADMAP;
  payload: string;
}
export interface QueryRoadmapSuccess {
  type: typeof QUERY_ROADMAP_SUCCESS;
  payload: RoadmapDto[];
}
export interface QueryRoadmapError {
  type: typeof QUERY_ROADMAP_ERROR;
  payload: string | boolean;
}

export interface ResetQueryRoadmap {
  type: typeof RESET_QUERY_ROADMAP;
  payload: null;
}

export interface NewRoadmap {
  type: typeof NEW_ROADMAP;
  payload: RoadmapDto;
}

export interface NewRoadmapSuccess {
  type: typeof NEW_ROADMAP_SUCCESS;
  payload: RoadmapDto;
}

export interface ResetGetRoadmap {
  type: typeof RESET_GET_ROADMAP;
  payload: null;
}

export interface NewRoadmapError {
  type: typeof NEW_ROADMAP_ERROR;
  payload: boolean | string;
}

export interface UploadMedia {
  type: typeof UPLOAD_MEDIA;
  payload: any;
}

export interface ResetNewRoadmap {
  type: typeof RESET_NEW_ROADMAP;
  payload: null;
}

export interface UpdateRoadmap {
  type: typeof UPDATE_ROADMAP;
  payload: any;
}

export interface UpdateRoadmapLoading {
  type: typeof UPDATE_ROADMAP_LOADING;
  payload: RoadmapDto;
}

export interface UpdateRoadmapSuccess {
  type: typeof UPDATE_ROADMAP_SUCCESS;
  payload: RoadmapDto;
}

export interface UpdateRoadmapError {
  type: typeof UPDATE_ROADMAP_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateRoadmap {
  type: typeof RESET_UPDATE_ROADMAP;
  payload: null;
}

export interface DeleteRoadmap {
  type: typeof DELETE_ROADMAP;
  payload: { _id: string };
}

export interface DeleteRoadmapSuccess {
  type: typeof DELETE_ROADMAP_SUCCESS;
  payload: null;
}

export interface DeleteRoadmapError {
  type: typeof DELETE_ROADMAP_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteRoadmap {
  type: typeof RESET_DELETE_ROADMAP;
  payload: null;
}

export interface State {
  all: {
    data: RoadmapDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  roadmap: {
    data: RoadmapDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  offSet: {
    data: RoadmapDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  new: {
    data: RoadmapDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  query: {
    data: null | RoadmapDto[];
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateOne: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  query: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  offSet: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  roadmap: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  new: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateOne: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllRoadmaps
  | GetAllRoadmapsSuccess
  | GetAllRoadmapsError
  | GetRoadmap
  | GetRoadmapSuccess
  | GetRoadmapError
  | NewRoadmap
  | NewRoadmapSuccess
  | NewRoadmapError
  | ResetNewRoadmap
  | UpdateRoadmap
  | UpdateRoadmapSuccess
  | UpdateRoadmapError
  | ResetUpdateRoadmap
  | DeleteRoadmap
  | DeleteRoadmapSuccess
  | DeleteRoadmapError
  | ResetDeleteRoadmap
  | UploadMedia
  | UpdateRoadmapLoading
  | ResetGetRoadmap
  | AddUserToRoadmap
  | SharedRoadmap
  | SetGetRoadmap
  | SavedRoadmap
  | UnSavedRoadmap
  | QueryRoadmap
  | QueryRoadmapSuccess
  | QueryRoadmapError
  | ResetQueryRoadmap
  | AddClickRoadmap
  | UpdateCurrentRoadmapList
  | AddViewRoadmap
  | ManageLikeDislikeRoadmap
  | UpdateOneRoadmap
  | UpdateOneRoadmapSuccess
  | UpdateOneRoadmapError
  | ResetUpdateOneRoadmap
  | GetRoadmapsOffset
  | GetRoadmapsOffsetError
  | GetRoadmapsOffsetSuccess
  | ResetGetRoadmapsOffset
  | ResetGetAllRoadmapData
  | ResetGetOneRoadmapData
  | UpdateItemListRoadmap;
