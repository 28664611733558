import { PaginationDto } from "../../types/pagination.dto";
import { SlideShowDto } from "../../types/slideshow.dto";

export const GET_ALL_SLIDESHOWS = "GET_ALL_SLIDESHOWS";
export const GET_ALL_SLIDESHOWS_ERROR = "GET_ALL_SLIDESHOWS_ERROR";
export const GET_ALL_SLIDESHOWS_SUCCESS = "GET_ALL_SLIDESHOWS_SUCCESS";
export const GET_SLIDESHOW = "GET_SLIDESHOW";
export const GET_SLIDESHOW_ERROR = "GET_SLIDESHOW_ERROR";
export const GET_SLIDESHOW_SUCCESS = "GET_SLIDESHOW_SUCCESS";
export const RESET_GET_SLIDESHOW = "RESET_GET_SLIDESHOW";
export const SET_GET_SLIDESHOW = "SET_GET_SLIDESHOW";
export const NEW_SLIDESHOW = "NEW_SLIDESHOW";
export const NEW_SLIDESHOW_ERROR = "NEW_SLIDESHOW_ERROR";
export const NEW_SLIDESHOW_SUCCESS = "NEW_SLIDESHOW_SUCCESS";
export const RESET_NEW_SLIDESHOW = "RESET_NEW_SLIDESHOW";
export const UPDATE_SLIDESHOW = "UPDATE_SLIDESHOW";
export const UPDATE_SLIDESHOW_ERROR = "UPDATE_SLIDESHOW_ERROR";
export const UPDATE_SLIDESHOW_SUCCESS = "UPDATE_SLIDESHOW_SUCCESS";
export const RESET_UPDATE_SLIDESHOW = "RESET_UPDATE_SLIDESHOW";
export const DELETE_SLIDESHOW = "DELETE_SLIDESHOW";
export const DELETE_SLIDESHOW_ERROR = "DELETE_SLIDESHOW_ERROR";
export const DELETE_SLIDESHOW_SUCCESS = "DELETE_SLIDESHOW_SUCCESS";
export const RESET_DELETE_SLIDESHOW = "RESET_DELETE_SLIDESHOW";
export const UPLOAD_MEDIA = "UPLOAD_MEDIA";
export const UPDATE_SLIDESHOW_LOADING = "UPDATE_SLIDESHOW_LOADING";
export const ADD_USER_TO_SLIDESHOW = "ADD_USER_TO_SLIDESHOW";
export const SAVED_SLIDESHOW = "SAVED_SLIDESHOW";
export const ADD_CLICK_SLIDESHOW = "ADD_CLICK_SLIDESHOW";
export const ADD_VIEW_SLIDESHOW = "ADD_VIEW_SLIDESHOW";
export const SHARED_SLIDESHOW = "SHARED_SLIDESHOW";
export const QUERY_SLIDESHOW = "QUERY_SLIDESHOW";
export const QUERY_SLIDESHOW_SUCCESS = "QUERY_SLIDESHOW_SUCCESS";
export const QUERY_SLIDESHOW_ERROR = "QUERY_SLIDESHOW_ERROR";
export const RESET_QUERY_SLIDESHOW = "RESET_QUERY_SLIDESHOW";
export const GET_SLIDESHOWS_OFFSET = "GET_SLIDESHOWS_OFFSET";
export const GET_SLIDESHOWS_OFFSET_SUCCESS = "GET_SLIDESHOWS_OFFSET_SUCCESS";
export const GET_SLIDESHOWS_OFFSET_ERROR = "GET_SLIDESHOWS_OFFSET_ERROR";
export const RESET_GET_SLIDESHOWS_OFFSET = "RESET_GET_SLIDESHOWS_OFFSET";
export const MANAGE_LIKE_DISLIKE_SLIDESHOW = "MANAGE_LIKE_DISLIKE_SLIDESHOW";
export const UPDATE_CURRENT_SLIDESHOW_LIST = "UPDATE_CURRENT_SLIDESHOW_LIST";
export const RESET_GET_ALL_SLIDESHOW_DATA = "RESET_GET_ALL_SLIDESHOW_DATA";
export const RESET_GET_ONE_SLIDESHOW_DATA = "RESET_GET_ONE_SLIDESHOW_DATA";
export const UPDATE_ITEM_LIST_SLIDESHOW = "UPDATE_ITEM_LIST_SLIDESHOW";

export interface ResetGetAllSlideShowData {
  type: typeof RESET_GET_ALL_SLIDESHOW_DATA;
  payload: null;
}

export interface UpdateItemListSlideShow {
  type: typeof UPDATE_ITEM_LIST_SLIDESHOW;
  payload: any;
}

export interface ResetGetOneSlideShowData {
  type: typeof RESET_GET_ONE_SLIDESHOW_DATA;
  payload: null;
}
export interface ManageLikeDislikeSlideshow {
  type: typeof MANAGE_LIKE_DISLIKE_SLIDESHOW;
  payload: {
    _id: string;
    user: string;
    condition: "like" | "dislike";
    action: "add" | "remove";
  };
}

export interface UpdateCurrentSlideshowList {
  payload: SlideShowDto[];
  type: typeof UPDATE_CURRENT_SLIDESHOW_LIST;
}

export interface SavedSlideshow {
  type: typeof SAVED_SLIDESHOW;
  payload: { _id: string; condition: string };
}

export interface AddClickSlideShow {
  type: typeof ADD_CLICK_SLIDESHOW;
  payload: { _id: string; currentCohort?: string; currentAcademy?: string };
}

export interface GetSlideShowsOffset {
  type: typeof GET_SLIDESHOWS_OFFSET;
  payload: { limit: number; offset: number };
}

export interface AddViewSlideshow {
  type: typeof ADD_VIEW_SLIDESHOW;
  payload: { _id: string };
}

export interface GetSlideShowsOffsetError {
  type: typeof GET_SLIDESHOWS_OFFSET_ERROR;
  payload: string | boolean;
}

export interface GetSlideShowsOffsetSuccess {
  type: typeof GET_SLIDESHOWS_OFFSET_SUCCESS;
  payload: SlideShowDto[];
}

export interface ResetGetSlideShowsOffset {
  type: typeof RESET_GET_SLIDESHOWS_OFFSET;
  payload: null;
}

export interface QuerySlideShow {
  type: typeof QUERY_SLIDESHOW;
  payload: string;
}
export interface QuerySlideShowSuccess {
  type: typeof QUERY_SLIDESHOW_SUCCESS;
  payload: SlideShowDto[];
}
export interface QuerySlideShowError {
  type: typeof QUERY_SLIDESHOW_ERROR;
  payload: string | boolean;
}

export interface ResetQuerySlideShow {
  type: typeof RESET_QUERY_SLIDESHOW;
  payload: null;
}
export interface SharedSlideShow {
  type: typeof SHARED_SLIDESHOW;
  payload: any;
}
export interface GetAllSlideShows {
  type: typeof GET_ALL_SLIDESHOWS;
  payload?: PaginationDto;
}

export interface GetAllSlideShowsSuccess {
  type: typeof GET_ALL_SLIDESHOWS_SUCCESS;
  payload: SlideShowDto[];
}

export interface SetGetSlideShow {
  type: typeof SET_GET_SLIDESHOW;
  payload: SlideShowDto | null;
}

export interface GetAllSlideShowsError {
  type: typeof GET_ALL_SLIDESHOWS_ERROR;
  payload: boolean | string;
}

export interface AddUserToSlideShow {
  type: typeof ADD_USER_TO_SLIDESHOW;
  payload: any;
}

export interface GetSlideShow {
  type: typeof GET_SLIDESHOW;
  payload: { id: string };
}

export interface GetSlideShowSuccess {
  type: typeof GET_SLIDESHOW_SUCCESS;
  payload: SlideShowDto;
}

export interface GetSlideShowError {
  type: typeof GET_SLIDESHOW_ERROR;
  payload: boolean | string;
}

export interface NewSlideShow {
  type: typeof NEW_SLIDESHOW;
  payload: SlideShowDto;
}

export interface NewSLIDESHOWuccess {
  type: typeof NEW_SLIDESHOW_SUCCESS;
  payload: SlideShowDto;
}

export interface ResetGetSlideShow {
  type: typeof RESET_GET_SLIDESHOW;
  payload: null;
}

export interface NewSlideShowError {
  type: typeof NEW_SLIDESHOW_ERROR;
  payload: boolean | string;
}

export interface UploadMedia {
  type: typeof UPLOAD_MEDIA;
  payload: any;
}

export interface ResetNewSlideShow {
  type: typeof RESET_NEW_SLIDESHOW;
  payload: null;
}

export interface UpdateSlideShow {
  type: typeof UPDATE_SLIDESHOW;
  payload: any;
}

export interface UpdateSlideShowLoading {
  type: typeof UPDATE_SLIDESHOW_LOADING;
  payload: SlideShowDto;
}

export interface UpdateSLIDESHOWuccess {
  type: typeof UPDATE_SLIDESHOW_SUCCESS;
  payload: SlideShowDto;
}

export interface UpdateSlideShowError {
  type: typeof UPDATE_SLIDESHOW_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateSlideShow {
  type: typeof RESET_UPDATE_SLIDESHOW;
  payload: null;
}

export interface DeleteSlideShow {
  type: typeof DELETE_SLIDESHOW;
  payload: { _id: string };
}

export interface DeleteSLIDESHOWuccess {
  type: typeof DELETE_SLIDESHOW_SUCCESS;
  payload: null;
}

export interface DeleteSlideShowError {
  type: typeof DELETE_SLIDESHOW_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteSlideShow {
  type: typeof RESET_DELETE_SLIDESHOW;
  payload: null;
}

export interface State {
  all: {
    data: SlideShowDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  slideshow: {
    data: SlideShowDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  offSet: {
    data: SlideShowDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  new: {
    data: SlideShowDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  query: {
    data: null | SlideShowDto[];
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    data: null | SlideShowDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  slideshow: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  offSet: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  query: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  new: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllSlideShows
  | GetAllSlideShowsSuccess
  | GetAllSlideShowsError
  | GetSlideShow
  | GetSlideShowSuccess
  | GetSlideShowError
  | NewSlideShow
  | NewSLIDESHOWuccess
  | NewSlideShowError
  | ResetNewSlideShow
  | UpdateSlideShow
  | UpdateSLIDESHOWuccess
  | UpdateSlideShowError
  | ResetUpdateSlideShow
  | DeleteSlideShow
  | DeleteSLIDESHOWuccess
  | DeleteSlideShowError
  | ResetDeleteSlideShow
  | UploadMedia
  | UpdateSlideShowLoading
  | ResetGetSlideShow
  | AddUserToSlideShow
  | SharedSlideShow
  | SetGetSlideShow
  | GetSlideShowsOffset
  | GetSlideShowsOffsetError
  | GetSlideShowsOffsetSuccess
  | ResetGetSlideShowsOffset
  | AddClickSlideShow
  | QuerySlideShow
  | QuerySlideShowSuccess
  | QuerySlideShowError
  | ResetQuerySlideShow
  | ManageLikeDislikeSlideshow
  | UpdateCurrentSlideshowList
  | SavedSlideshow
  | AddViewSlideshow
  | ResetGetAllSlideShowData
  | ResetGetOneSlideShowData
  | UpdateItemListSlideShow;
