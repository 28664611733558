import React, { useEffect, useRef, useState } from "react";
import { Page, Document } from "react-pdf";
import SlideshowPlayer from "../../../../components/SlideshowPlayer";
import Share from "../../../../components/ShareLink";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import axios from "axios";
import Loader from "../../../../components/Loader";
import "./style.css";
import {
  LikeDislikeBox,
  LikeDislikeText,
  PageNumber,
  PageNumberWrapper,
  SidebarWrapper,
  SlideshowDescriptionText,
  SlideshowInfoWrapper,
  SlideshowTitle,
} from "./style";
import { useNavigate, useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  addViewSlideshow,
  getAllSlideShows,
  getSlideShow,
  manageLikeDislikeSlideshow,
  querySlideShow,
  resetGetAllSlideShows,
  resetGetSlideShow,
  resetUpdateSlideShow,
  savedSlideshow,
} from "../../../../store/actions/slideshow";
import { SlideShowDto } from "../../../../types/slideshow.dto";
import { StateDto } from "../../../../types/states.dto";
import {
  Center,
  DotContent,
  FlexContainer,
  ItemDataText,
  Line,
  ScrollSection,
  Separator,
} from "../../../../components/StyledComponents";
import { AiFillClockCircle, AiFillEye } from "react-icons/ai";
import { useWindowSize } from "../../../../hooks/useWindowScreen";
import CommentSection from "../../../../components/CommentSection";
import { overThousandFixed } from "../../../../helpers/number-fixed";
import { UserDto } from "../../../../types/user.dto";
import SideBar from "./components/SideBar";
import { FaSave, FaShare } from "react-icons/fa";
import { showModal } from "../../../../store/actions/modal";
import { toast } from "react-toastify";
import { BiLink } from "react-icons/bi";
import ShareModal from "./components/ShareModal";
import Grid from "../../../../components/Grid";
import FilterByName from "./components/FilterByName";
import {
  GridCardContent,
  GridCardImage,
  GridCardTitle,
  GridComment,
  GridQuerySideImageContainer,
} from "../../../../components/Layout/Dashboard/styles";
import { DateFromNow } from "../../../../helpers/format-date";
import { AcademyDto } from "../../../../types/academy.dto";
import InfinityScroll from "../../../../components/InfinityScroll";
import { setCookie } from "../../../../helpers/cookies-handler";
import useTrackTimeAndAPI from "../../../../hooks/useTrackTimeAndAPI";
import {
  addClick,
  addView,
  userTime,
} from "../../../../store/actions/userTimes";

import DescriptionForContentsView from "../../../../components/DescriptionForContentsView";
import Skeleton from "../../../../components/Skeleton";
import DescriptionForContentsViewMobile from "../../../../components/DescriptionForContentsViewMobile";
import academyActions from "../../../../store/actions/academies";
import EmptyState from "./components/EmptyState";
import { BottomLabel } from "../../Home/components/MediaItems/components/MediaItem/style";
interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  slideshow,
  slideshowGetStates,
  user,
  slideshows,
  querySlideshows,
  querySlideshowsStates,
  academies,
  slideshowsStates,
  updatedAcademy,
  updateAcademyStates,
  academy,
  updatedSlideshow,
  updateSlideshowStates,
}: {
  slideshow: SlideShowDto;
  slideshowGetStates: StateDto;
  user: UserDto;
  slideshows: SlideShowDto[];
  querySlideshows: SlideShowDto[];
  querySlideshowsStates: StateDto;
  academies: AcademyDto[];
  slideshowsStates: StateDto;
  updatedAcademy: AcademyDto;
  updateAcademyStates: StateDto;
  academy: AcademyDto;
  updatedSlideshow: SlideShowDto;
  updateSlideshowStates: StateDto;
}) => {
  const [pdfResponse, setPdfResponse] = useState();
  const [currentSlideshowsList, setCurrentSlideshowsList] = useState<any[]>([]);
  const [currentSlideshow, setCurrentSlideshow] = useState<SlideShowDto>();
  const [page, setPage] = useState<number>(0);
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [
    filteredCurrentSlideshowsListValues,
    setFilteredCurrentSlideshowsListValues,
  ] = useState<SlideShowDto[]>([]);
  const [queryMode, setQueryMode] = useState<string | null>(null);
  const [query, setQuery] = useState<string | null>(null);
  const [currentSlideshowAcademy, setCurrentSlideshowAcademy] =
    useState<AcademyDto>();
  const { isMobile } = useWindowSize();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleQuery = (ev) => {
    const value = ev.target.value;
    setQuery(value.length === 0 ? "" : value);
  };
  const searchQuery = (ev) => {
    if (ev.keyCode === 13) {
      /*  dispatch(queryImage({ keyword: query })); */
      setQueryMode(query);
    }
  };
  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 6,
      offset: 6 * Math.floor(page / 2),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        published: true,
      },
    };
    const sortBy = page % 2 === 0 ? "createdAt" : "viewCount";

    const sortDirection = "desc";
    dispatch(
      getAllSlideShows({
        ...payload,
        sortBy,
        sortDirection,
        ...(queryMode && { keyword: query }),
      })
    );
  };

  useEffect(() => {
    const filteredTalents = currentSlideshowsList?.filter(
      (currentTalentValues) => {
        const thereAreFilters =
          applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
            .length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter(
            (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
          )
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value,
            currentTalentValues
          );
        });
        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentSlideshowsListValues(initResult);
  }, [applyedFilters, currentSlideshowsList]);

  const apiAction = (timeSpent) => {
    dispatch(
      userTime({
        ...timeSpent,
        id: params.id,
        user: user._id,
        type: "SLIDESHOW",
      })
    );
  };
  useTrackTimeAndAPI(apiAction, params.id && user);

  useEffect(() => {
    if (queryMode) {
      setPage(0);
      setIsLoading(false);
      setThereAreItems(true);
      setCurrentSlideshowsList([]);
    }
  }, [queryMode]);

  useEffect(() => {
    if (!isLoading && !!slideshows) {
      setCurrentSlideshowsList((state) => [
        ...state,
        ...slideshows.filter((image) => image._id !== params.id),
      ]);
    }
    setThereAreItems(!!slideshows && slideshows?.length > 0);
  }, [slideshows, isLoading, params.id]);

  useEffect(() => {
    setIsLoading(slideshowsStates.loading);
  }, [slideshowsStates]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllSlideShows());
    };
  }, []);
  useEffect(() => {
    if (params.id && user) {
      dispatch(getSlideShow({ _id: params.id }));
      dispatch(addView({ id: params.id, type: "CONTENT", user: user._id }));
      if (document.getElementById("scrollToTopTarget")) {
        const element: any = document.getElementById("scrollToTopTarget");

        element.scrollIntoView();
      }
    }
  }, [params.id, user]);

  useEffect(() => {
    if (updateSlideshowStates.success) {
      setCurrentSlideshow(updatedSlideshow);
      dispatch(resetUpdateSlideShow());
    }
    if (updateSlideshowStates.error) {
      toast.error(updateSlideshowStates.error);
      dispatch(resetUpdateSlideShow());
    }
  }, [updateSlideshowStates]);

  useEffect(() => {
    if (slideshow) {
      setCurrentSlideshow(slideshow);
      axios({
        method: "GET",
        url: slideshow.slides,
        responseType: "arraybuffer",
      })
        .then((response) => {
          setPdfResponse(response.data);
        })
        .catch((err) => setPdfResponse(undefined));
    }
  }, [slideshow]);

  useEffect(() => {
    if (!!slideshow) {
      setCookie(
        "DEVPLACE_VISITED_SLIDESHOW",
        JSON.stringify({ _id: slideshow._id, name: slideshow.name })
      );
    }
  }, [slideshow]);

  useEffect(() => {
    if (academy && fetchOn) {
      setCurrentSlideshowAcademy(academy);
    }
  }, [academy, fetchOn]);

  useEffect(() => {
    if (slideshow) {
      dispatch(academyActions.getOne({ _id: slideshow.academy._id }));
    }
  }, [slideshow]);

  useEffect(() => {
    if (slideshowGetStates.error) {
      toast.error(`${slideshowGetStates.error}`);
      dispatch(resetGetSlideShow());
    }
  }, [slideshowGetStates, dispatch]);

  useEffect(() => {
    if (updateAcademyStates.success && currentSlideshowAcademy) {
      setCurrentSlideshowAcademy(updatedAcademy);
      dispatch(academyActions.resetUpdate());
    }
    if (updateAcademyStates.error) {
      toast.error(updateAcademyStates.error);
      dispatch(academyActions.resetUpdate());
    }
  }, [updateAcademyStates]);

  useEffect(() => {
    if (!academy) {
      setFetchOn(true);
    }
  }, [academy]);

  useEffect(() => {
    return () => {
      dispatch(academyActions.resetGetOneAcademy());
    };
  }, []);

  return (
    <>
      <div
        id="scrollToTopTarget"
        style={{ position: "absolute", top: 0, width: "0px", height: "0px" }}
      ></div>

      <>
        <Grid.Row>
          <Grid.Col style={{ marginBottom: "20px" }} lg={8}>
            {!currentSlideshow || !pdfResponse ? (
              <EmptyState></EmptyState>
            ) : (
              <>
                {pdfResponse && (
                  <SlideshowPlayer
                    pdfUrl={currentSlideshow.slides}
                    src={pdfResponse}
                    id={params.id}
                  />
                )}
                <Separator size={15}></Separator>

                {isMobile ? (
                  <DescriptionForContentsViewMobile
                    content={currentSlideshow}
                    currentContentAcademy={currentSlideshowAcademy}
                    page={page}
                    setPage={setPage}
                    isLoading={isLoading}
                    thereAreItems={thereAreItems}
                    query={query}
                    manageLikeDislikeContent={manageLikeDislikeSlideshow}
                    searchQuery={searchQuery}
                    handleQuery={handleQuery}
                    fetchData={fetchData}
                    currentContentList={filteredCurrentSlideshowsListValues}
                  ></DescriptionForContentsViewMobile>
                ) : (
                  <DescriptionForContentsView
                    content={currentSlideshow}
                    manageDislikeLikeContent={manageLikeDislikeSlideshow}
                    currentContentAcademy={currentSlideshowAcademy}
                  ></DescriptionForContentsView>
                )}
              </>
            )}
          </Grid.Col>
          {!isMobile && (
            <Grid.Col offset={{ lg: 0.3 }}>
              <FilterByName
                searchQuery={searchQuery}
                setFilter={handleQuery}
                value={query}
              />

              <div style={{ height: 10 }} />

              <ScrollSection style={{ maxHeight: 450, boxShadow: "none" }}>
                <InfinityScroll
                  action={fetchData}
                  page={page}
                  setPage={setPage}
                  data={filteredCurrentSlideshowsListValues}
                  active={thereAreItems}
                  isLoading={isLoading}
                  style={{ width: "100%", paddingBottom: "10px" }}
                  loader={
                    <Skeleton
                      itemsPerPage={10}
                      itemsPerRow={1}
                      animated
                      size={{ height: 58, width: 297 }}
                      spaceBetweenItems={10}
                      icon="video"
                    />
                  }
                >
                  <FlexContainer
                    direction="column"
                    gap="10px"
                    align="center"
                    justify="center"
                    style={{ paddingBottom: "10px" }}
                  >
                    {filteredCurrentSlideshowsListValues
                      .filter((item) => item._id !== params.id)
                      .map((item) => {
                        return (
                          <GridCardContent
                            onClick={() => {
                              dispatch(
                                addClick({
                                  id: item._id,
                                  user: user._id,
                                  type: "CONTENT",
                                })
                              );
                              navigate(`../${item._id}`, {
                                replace: true,
                              });
                            }}
                            pd={"5px"}
                            key={item._id}
                          >
                            <FlexContainer gap="11.1px" align="center">
                              <GridQuerySideImageContainer>
                                <GridCardImage src={item.picture || ""} />
                                {item.slideCount && (
                                  <BottomLabel>{`${item.slideCount} pags`}</BottomLabel>
                                )}
                              </GridQuerySideImageContainer>

                              <FlexContainer direction="column" gap="1px">
                                <GridCardTitle style={{ fontSize: "14px" }}>
                                  {item.name}
                                </GridCardTitle>

                                <FlexContainer direction="column">
                                  <FlexContainer
                                    onClick={(ev) => {
                                      ev.stopPropagation();
                                      dispatch(
                                        addClick({
                                          id: item.academy._id,
                                          user: user._id,
                                          type: "ACADEMY",
                                        })
                                      );
                                      navigate(
                                        `/dashboard/academy/${item.academy._id}`
                                      );
                                    }}
                                    align="center"
                                    gap="5px"
                                  >
                                    <GridComment>
                                      {item.academy.name}
                                    </GridComment>
                                  </FlexContainer>
                                  <FlexContainer gap="5px" align="center">
                                    <FlexContainer align="center" gap="3px">
                                      <AiFillEye fill="#6a6970"></AiFillEye>

                                      <ItemDataText>
                                        <GridComment>
                                          {overThousandFixed(item.viewCount)}
                                        </GridComment>
                                      </ItemDataText>
                                    </FlexContainer>

                                    <span>|</span>
                                    <FlexContainer align="center" gap="3px">
                                      <AiFillClockCircle fill="#6a6970"></AiFillClockCircle>

                                      <ItemDataText>
                                        <GridComment>
                                          {DateFromNow(item.createdAt)}
                                        </GridComment>
                                      </ItemDataText>
                                    </FlexContainer>
                                  </FlexContainer>
                                </FlexContainer>
                              </FlexContainer>
                            </FlexContainer>
                          </GridCardContent>
                        );
                      })}
                  </FlexContainer>
                </InfinityScroll>
              </ScrollSection>
            </Grid.Col>
          )}
        </Grid.Row>

        {currentSlideshow && (
          <ShareModal slideshow={currentSlideshow}></ShareModal>
        )}
      </>
    </>
  );
};

const states = ({ slideshowStore, userStore, academyStore }) => {
  const { data: slideshow, states: slideshowGetStates } =
    slideshowStore.slideshow;
  const { data: user } = userStore;
  const { data: slideshows, states: slideshowsStates } = slideshowStore.all;
  const { data: querySlideshows, states: querySlideshowsStates } =
    slideshowStore.query;
  const { data: academies } = academyStore.all;
  const { data: updatedAcademy, states: updateAcademyStates } =
    academyStore.update;
  const { data: academy } = academyStore.selected;
  const { data: updatedSlideshow, states: updateSlideshowStates } =
    slideshowStore.update;
  return {
    slideshowsStates,
    slideshow,
    slideshowGetStates,
    user,
    slideshows,
    querySlideshows,
    querySlideshowsStates,
    academies,
    updatedAcademy,
    updateAcademyStates,
    academy,
    updatedSlideshow,
    updateSlideshowStates,
  };
};

export default connect(states)(Component);
