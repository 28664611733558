import React, { useState } from "react";

import { connect } from "react-redux";
import { AcademyDto } from "../../../../../types/academy.dto";

import CommentSection from "../../../../../components/CommentSection";
import { useWindowSize } from "../../../../../hooks/useWindowScreen";
import CommentSectionMobile from "../../../../../components/CommentSectionMobile";
import { Separator } from "../../../../../components/StyledComponents";
const Component = ({ selectedAcademy }: { selectedAcademy: AcademyDto }) => {
  const { isMobile } = useWindowSize();
  const [openDescription, setOpenDescription] = useState<boolean>(false);
  const [openCommentsModal, setOpenCommentsModal] = useState<boolean>(false);
  return (
    <>
      <Separator size={10}></Separator>
      {isMobile ? (
        <CommentSectionMobile
          isModalOpen={openCommentsModal}
          setIsModalOpen={setOpenCommentsModal}
          itemId={selectedAcademy._id}
          section="academy"
        ></CommentSectionMobile>
      ) : (
        <CommentSection
          section="academy"
          itemId={selectedAcademy._id}
        ></CommentSection>
      )}
      <Separator size={15}></Separator>
    </>
  );
};

const states = ({ academyStore }) => {
  const { data: selectedAcademy } = academyStore.selected;
  return {
    selectedAcademy,
  };
};

export default connect(states)(Component);
