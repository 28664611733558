import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";

import { hideModal, showModal } from "../../../../../../store/actions/modal";

import Modal from "../../../../../../components/Modal";
import {
  Center,
  FlexContainer,
  Separator,
} from "../../../../../../components/StyledComponents";
import Submit from "../../../../../../components/Form/Submit";
import { CardWrapper } from "./style";
import { Img } from "../../../../../SignIn/styles";
import { AiFillCheckCircle } from "react-icons/ai";
import suscriptionActions from "../../../../../../store/actions/suscription";
import { RiErrorWarningFill } from "react-icons/ri";
/* interface ComponentProps {
  updateUserStates: StateDto;
  user: UserDto;
} */

const Component = ({
  onClose,
  suscription,
  suscriptionUpdateStates,
  setAction,
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    /*  dispatch(forgotPassword({ _id: user._id, password: values.password })); */
    setAction({
      action: "status-suscription",
      modalName: "status-suscription-modal",
      data: {
        title: "Cambio de metodo de pago confirmado",
        /*   onClose, */
        icon: AiFillCheckCircle,
        color: "#37e828",
      },
    });
  };
  /* 
  const closeModal = () => {
    dispatch(hideModal());
  }; */

  /*   useEffect(() => {
    if (suscriptionUpdateStates.success) {
      setAction({
        action: "status-suscription",
        data: {
          title: "Cambio de metodo de pago confirmado",
          onClose,
          icon: AiFillCheckCircle,
          color: "#37e828",
        },
      });
      dispatch(suscriptionActions.resetUpdate());
      dispatch(showModal("status-suscription-modal"));
    }
    if (suscriptionUpdateStates.error) {
      setAction({
        action: "status-suscription",
        data: {
          title: "Ocurrio un error al cambiar metodo",
          onClose,
          icon: RiErrorWarningFill,
          color: "#000000",
        },
      });
      dispatch(suscriptionActions.resetUpdate());
      dispatch(showModal("status-suscription-modal"));
    }
  }, [suscriptionUpdateStates]);
 */
  return (
    <Modal
      onClose={onClose}
      description="El cambio se aplicara a partir del mes que viene."
      name="confirm-change-purchase-method"
      title="Confirmar Cambio de metodo de pago"
    >
      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="confirm-change-method"
            >
              <Separator size={18}></Separator>
              <h3>Este es el medio de pago que elegiste</h3>
              <Separator size={5}></Separator>
              <FlexContainer justify="center">
                <CardWrapper>
                  <Img
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/7/76/Seehund11cele4_edit.jpg"
                    }
                  ></Img>
                </CardWrapper>
              </FlexContainer>
              <Separator size={20}></Separator>
              <Center>
                <Submit
                  isSubmmiting={false}
                  form="confirm-change-method"
                  color="Violet"
                  options={{
                    type: "filled",
                    skin: "violet",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                >
                  Confirmar Cambio
                </Submit>
              </Center>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ suscriptionStore }) => {
  const { states: suscriptionUpdateStates } = suscriptionStore.update;
  return { suscriptionUpdateStates };
};

export default connect(states)(Component);
