import { Component, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useWindowSize } from "../../hooks/useWindowScreen";
import MenuMobile from "../MenuMobile";

export const MenuPortal = ({ useLocation, user }): any => {
  const menuPortal = document.getElementById("portal-menu") as HTMLElement;
  const location = useLocation();
  const { isMobile } = useWindowSize();

  return (
    !!isMobile &&
    createPortal(<MenuMobile location={location}></MenuMobile>, menuPortal)
  );
};
