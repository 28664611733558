import React, { useEffect, useRef, useState } from "react";
import { CommentWrapper, CommentWrapperTitle, FakeInput } from "./style";
import services from "../../services";
import { FlexContainer, Separator } from "../StyledComponents";
import { Formik } from "formik";
import { initialValues, schema } from "../../constants/form/comment/create";
import { connect, useDispatch } from "react-redux";
import commentActions from "../../store/actions/comment";
import Input from "../Form/Input";
import { AiOutlineArrowDown } from "react-icons/ai";
import MobileContentModal from "../MobileContentModal";
import { FaComment } from "react-icons/fa";
import CommentPath from "./components/CommentPath";
import { TiArrowBack } from "react-icons/ti";
import { CommentDto } from "../../types/comment.dto";
import { StateDto } from "../../types/states.dto";
const Component = ({
  section,
  itemId,
  isModalOpen,
  setIsModalOpen,
  newComment,
  newCommentStates,
}: {
  section: string;
  itemId: string;
  isModalOpen: boolean;
  setIsModalOpen;
  newComment: CommentDto;
  newCommentStates: StateDto;
}) => {
  const dispatch = useDispatch();
  const [totallyCommentsCount, setTotallyCommentsCount] = useState<number>();
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [currentIndexFromStack, setCurrentIndexFromStack] = useState<number>(0);
  const goBackRef: any = useRef();
  const onSubmit = ({ values, actions }) => {
    dispatch(commentActions.create({ ...values, [`${section}`]: itemId }));
    setFormActions(actions);
  };
  const fetchCommentsCount = async () => {
    const response: any = await services.comment.getAll({
      filterBy: {
        active: true,
        replyTo: { $exists: false },

        [`${section}`]: itemId,
      },
    });
    if (response.response) {
      setTotallyCommentsCount(response.response.length);
    }
  };
  useEffect(() => {
    if (itemId) {
      fetchCommentsCount();
    }
  }, [itemId]);

  useEffect(() => {
    if (
      newCommentStates.success &&
      totallyCommentsCount &&
      !newComment.replyTo
    ) {
      formActions?.resetForm();
      setTotallyCommentsCount((state) => (state ?? 0) + 1);
    }
  }, [newCommentStates]);

  return (
    <>
      <CommentWrapper
        onClick={(ev) => {
          ev.stopPropagation();
          setIsModalOpen(true);
        }}
      >
        <FlexContainer justify="space-between" align="center">
          <CommentWrapperTitle>{`Comentarios: ${totallyCommentsCount}`}</CommentWrapperTitle>
          <FlexContainer align="center" gap="4px">
            <span style={{ fontSize: "12px" }}>Ver</span>
            <AiOutlineArrowDown size={12}></AiOutlineArrowDown>
          </FlexContainer>
        </FlexContainer>
        <Separator size={10}></Separator>
        <FakeInput>Escribe un comentario</FakeInput>
      </CommentWrapper>
      <MobileContentModal
        isVisible={isModalOpen}
        setIsVisible={setIsModalOpen}
        title="Comentarios"
        Icon={FaComment}
        {...(currentIndexFromStack > 0 && {
          Icon: TiArrowBack,
          iconAction: () => {
            if (goBackRef.current) {
              goBackRef.current.goBack();
            }
          },
        })}
      >
        <CommentPath
          setCurrentIndexFromStack={setCurrentIndexFromStack}
          itemId={itemId}
          section={section}
          ref={goBackRef}
        ></CommentPath>
      </MobileContentModal>
    </>
  );
};

const states = ({ commentStore }) => {
  const { data: newComment, states: newCommentStates } = commentStore.create;
  return {
    newComment,
    newCommentStates,
  };
};

export default connect(states)(Component);
