import { SuscriptionDto } from "../../types/suscription.dto";
export const GET_ALL_SUSCRIPTION = "GET_ALL_SUSCRIPTION";
export const RESET_GET_ALL_SUSCRIPTION = "RESET_GET_ALL_SUSCRIPTION";
export const GET_ALL_SUSCRIPTION_SUCCESS = "GET_ALL_SUSCRIPTION_SUCCESS";
export const GET_ALL_SUSCRIPTION_ERROR = "GET_ALL_SUSCRIPTION_ERROR";
export const NEW_SUSCRIPTION = "NEW_SUSCRIPTION";
export const NEW_SUSCRIPTION_SUCCESS = "NEW_SUSCRIPTION_SUCCESS";
export const NEW_SUSCRIPTION_ERROR = "NEW_SUSCRIPTION_ERROR";
export const RESET_NEW_SUSCRIPTION = "RESET_NEW_SUSCRIPTION";
export const GET_SUSCRIPTION = "GET_SUSCRIPTION";
export const GET_SUSCRIPTION_SUCCESS = "GET_SUSCRIPTION_SUCCESS";
export const GET_SUSCRIPTION_ERROR = "GET_SUSCRIPTION_ERROR";
export const RESET_GET_SUSCRIPTION = "RESET_GET_SUSCRIPTION";
export const DELETE_SUSCRIPTION = "DELETE_SUSCRIPTION";
export const DELETE_SUSCRIPTION_SUCCESS = "DELETE_SUSCRIPTION_SUCCESS";
export const DELETE_SUSCRIPTION_ERROR = "DELETE_SUSCRIPTION_ERROR";
export const RESET_DELETE_SUSCRIPTION = "RESET_DELETE_SUSCRIPTION";
export const UPDATE_SUSCRIPTION = "UPDATE_SUSCRIPTION";
export const UPDATE_SUSCRIPTION_SUCCESS = "UPDATE_SUSCRIPTION_SUCCESS";
export const UPDATE_SUSCRIPTION_ERROR = "UPDATE_SUSCRIPTION_ERROR";
export const RESET_UPDATE_SUSCRIPTION = "RESET_UPDATE_SUSCRIPTION";
export interface GetAllSuscription {
  type: typeof GET_ALL_SUSCRIPTION;
  payload: null;
}
export interface GetAllSuscriptionSuccess {
  type: typeof GET_ALL_SUSCRIPTION_SUCCESS;
  payload: SuscriptionDto[];
}
export interface GetAllSuscriptionError {
  type: typeof GET_ALL_SUSCRIPTION_ERROR;
  payload: string | boolean;
}
export interface ResetGetAllSuscription {
  type: typeof RESET_GET_ALL_SUSCRIPTION;
  payload: null;
}

export interface GetSuscription {
  type: typeof GET_SUSCRIPTION;
  payload: { _id: string };
}

export interface GetSuscriptionSuccess {
  type: typeof GET_SUSCRIPTION_SUCCESS;
  payload: SuscriptionDto;
}

export interface GetSuscriptionError {
  type: typeof GET_SUSCRIPTION_ERROR;
  payload: string | boolean;
}

export interface ResetGetSuscription {
  type: typeof RESET_GET_SUSCRIPTION;
  payload: null;
}

export interface NewSuscription {
  type: typeof NEW_SUSCRIPTION;
  payload: any;
}
export interface NewSuscriptionSuccess {
  type: typeof NEW_SUSCRIPTION_SUCCESS;
  payload: null;
}
export interface NewSuscriptionError {
  type: typeof NEW_SUSCRIPTION_ERROR;
  payload: string | boolean;
}
export interface ResetNewSuscription {
  type: typeof RESET_NEW_SUSCRIPTION;
  payload: null;
}
export interface UpdateSuscription {
  type: typeof UPDATE_SUSCRIPTION;
  payload: any;
}
export interface UpdateSuscriptionSuccess {
  type: typeof UPDATE_SUSCRIPTION_SUCCESS;
  payload: null;
}
export interface UpdateSuscriptionError {
  type: typeof UPDATE_SUSCRIPTION_ERROR;
  payload: string | boolean;
}
export interface ResetUpdateSuscription {
  type: typeof RESET_UPDATE_SUSCRIPTION;
  payload: null;
}

export interface DeleteSuscription {
  type: typeof DELETE_SUSCRIPTION;
  payload: any;
}
export interface DeleteSuscriptionSuccess {
  type: typeof DELETE_SUSCRIPTION_SUCCESS;
  payload: null;
}
export interface DeleteSuscriptionError {
  type: typeof DELETE_SUSCRIPTION_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteSuscription {
  type: typeof RESET_DELETE_SUSCRIPTION;
  payload: null;
}

export interface State {
  all: {
    data: SuscriptionDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  suscription: {
    data: null | SuscriptionDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  suscription: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};
export type Actions =
  | GetAllSuscription
  | GetAllSuscriptionSuccess
  | GetAllSuscriptionError
  | NewSuscription
  | NewSuscriptionSuccess
  | NewSuscriptionError
  | DeleteSuscription
  | DeleteSuscriptionSuccess
  | DeleteSuscriptionError
  | ResetDeleteSuscription
  | ResetNewSuscription
  | ResetGetAllSuscription
  | UpdateSuscription
  | UpdateSuscriptionSuccess
  | UpdateSuscriptionError
  | ResetUpdateSuscription
  | GetSuscription
  | GetSuscriptionSuccess
  | GetSuscriptionError
  | ResetGetSuscription;
