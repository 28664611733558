import {
  initialState,
  Actions,
  State,
  GET_ALL_CERTIFICATES,
  GET_ALL_CERTIFICATES_ERROR,
  GET_ALL_CERTIFICATES_SUCCESS,
  NEW_CERTIFICATE,
  NEW_CERTIFICATE_ERROR,
  NEW_CERTIFICATE_SUCCESS,
  RESET_NEW_CERTIFICATE,
  UPDATE_CERTIFICATE,
  UPDATE_CERTIFICATE_ERROR,
  UPDATE_CERTIFICATE_SUCCESS,
  RESET_UPDATE_CERTIFICATE,
  DELETE_CERTIFICATE,
  DELETE_CERTIFICATE_ERROR,
  DELETE_CERTIFICATE_SUCCESS,
  RESET_DELETE_CERTIFICATE,
  RESET_GET_ALL_CERTIFICATE_DATA,
  GET_CERTIFICATES_BY_ACADEMY,
} from "../types/certificate";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_CERTIFICATES:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_CERTIFICATE_DATA:
      return {
        ...state,
        all: initialState.all,
      };
    case GET_ALL_CERTIFICATES_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_CERTIFICATES_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };

    case GET_CERTIFICATES_BY_ACADEMY:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case NEW_CERTIFICATE:
      return {
        ...state,
        create: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_CERTIFICATE_SUCCESS:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };

    case NEW_CERTIFICATE_ERROR:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_CERTIFICATE:
      return {
        ...state,
        create: initialState.create,
      };
    case DELETE_CERTIFICATE:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_CERTIFICATE_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_CERTIFICATE:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_CERTIFICATE:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_CERTIFICATE_ERROR:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_CERTIFICATE:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
