import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import {
  initialValues,
  schema,
} from "../../../../constants/form/user/profile/edit";
import UploadIcon from "../../../../assets/icons/cloud-upload.svg";
import usersActions from "../../../../store/actions/users";
import { toast } from "react-toastify";
import { hideModal } from "../../../../store/actions/modal";
import Grid from "../../../Grid";
import Input from "../../../Form/Input";
import ReactSelect from "../../../Form/ReactSelect";
import { Center } from "../../../StyledComponents";
import Button from "../../../Button";
import Submit from "../../../Form/Submit";
import Modal from "../../../Modal";
import FileUploader from "../../../Form/FileUploader";
import Icon from "../../../Icon";
import { updateUser } from "../../../../store/actions/user";
import { setFormData } from "../../../../helpers/formData";

const Component = ({ updateStates, users, user, updateUserStates }) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [avatarPreview, setAvatarPreview] = useState<HTMLInputElement>();

  const dispatch = useDispatch();
  const onSubmit = ({ values, actions }) => {
    const formValues = {
      ...values,
      ...(!!avatarPreview &&
        !!avatarPreview?.files && {
          picture: avatarPreview?.files[0],
        }),
    };

    dispatch(
      updateUser({ _id: user._id, body: setFormData(formValues, ["picture"]) })
    );
    setFormActions(actions);
  };
  useEffect(() => {
    if (updateUserStates.success) {
      setTimeout(() => {
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }
  }, [updateUserStates]);

  return (
    <Modal name="edit-user-profile" title="Datos Personales" showClose>
      <Formik
        initialValues={{
          ...initialValues({
            name: user.name,
            lastName: user.lastName,
            birthDate: user.birthDate,
            civilStatus: user.civilStatus,
            dni: user.dni,
            gender: user.gender,
            nationality: user.nationality,
          }),
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-profile-profile-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Input
                    name={"name"}
                    error={errors["name"]}
                    touched={touched["name"]}
                    value={values["name"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={"Ingrese nombre"}
                    options={{
                      label: "Nombre",
                      skin: "gray",
                    }}
                  />
                  <Input
                    name={"lastName"}
                    error={errors["lastName"]}
                    touched={touched["lastName"]}
                    value={values["lastName"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={"Ingrese Apellido"}
                    options={{
                      label: "Apellido",
                      skin: "gray",
                    }}
                  />
                  <FileUploader
                    name="picture"
                    error={errors["picture"]}
                    touched={touched["picture"]}
                    placeholder={
                      <Icon icon={UploadIcon} size="24px" color="black" />
                    }
                    onChange={(event) => {
                      setAvatarPreview(event.target);
                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    accept="image/jpg;image/png;image/jpeg;capture=camera"
                    options={{
                      label: "Foto de Perfil",
                      marginBottom: 24,
                    }}
                  />
                  <Input
                    name={"nationality"}
                    error={errors["nationality"]}
                    touched={touched["nationality"]}
                    value={values["nationality"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Nacionalidad",
                      skin: "gray",
                    }}
                  />
                  <Input
                    name="birthDate"
                    error={errors["birthDate"]}
                    touched={touched["birthDate"]}
                    value={values["birthDate"]}
                    type="date"
                    placeholder="Fecha"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Fecha de Nacimiento",
                      skin: "gray",
                      marginBottom: 20,
                    }}
                  />

                  <Input
                    name={"dni"}
                    error={errors["dni"]}
                    touched={touched["dni"]}
                    value={values["dni"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={"Ej: 42392152"}
                    options={{
                      label: "DNI",
                      skin: "gray",
                    }}
                  />

                  <ReactSelect
                    name="gender"
                    error={errors["gender"]}
                    touched={touched["gender"]}
                    items={[
                      {
                        label: "Masculino",
                        value: "male",
                      },
                      {
                        label: "Femenino",
                        value: "female",
                      },
                      {
                        label: "Otro",
                        value: "other",
                      },
                      {
                        label: "No tengo",
                        value: "none",
                      },
                    ]}
                    placeholder="Seleccione un tipo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Genero",
                      skin: "gray",
                      marginBottom: 20,
                    }}
                  />
                  <ReactSelect
                    name="civilStatus"
                    error={errors["civilStatus"]}
                    touched={touched["civilStatus"]}
                    items={[
                      {
                        label: "Casado",
                        value: "married",
                      },
                      {
                        label: "Divorciado",
                        value: "divorced",
                      },
                      {
                        label: "Soltero",
                        value: "single",
                      },
                      {
                        label: "En Pareja",
                        value: "couple",
                      },
                    ]}
                    placeholder="Seleccione un tipo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Estado Civil",
                      skin: "gray",
                      marginBottom: 40,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Submit
                      isSubmmiting={updateUserStates.loading}
                      form="edit-profile-profile-form"
                      style={{ height: "26px" }}
                      options={{
                        type: "filled",
                        loading: "White",
                        skin: "violet",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ usersStore, userStore }) => {
  const { states: updateStates } = usersStore.update;
  const { data: users } = usersStore.all;
  const { data: user } = userStore;
  const { states: updateUserStates } = userStore.update;
  return {
    updateStates,
    users,
    user,
    updateUserStates,
  };
};

export default connect(states)(Component);
