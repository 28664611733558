import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { showModal } from "../../../store/actions/modal";
import Constana from "../../../assets/images/constana.png";
import Campus from "../../../assets/images/logocampuspic.png";
import CampusLogo from "../../../assets/images/logocampus.png";
import Accepted from "../../../assets/images/accepted.png";
import Grid from "../../../components/Grid";
import { Center, FlexContainer } from "../../../components/StyledComponents";
import Button from "../../../components/Button";
import { connect, useDispatch } from "react-redux";
import {
  resetUpdateConfirmationAuth,
  updateConfirmationAuth,
  getConfirmationAuth,
} from "../../../store/actions/confirmationAuth";
import { useSearchParams } from "react-router-dom";
import Reject from "../../../assets/images/reject.png";
import Loader from "../../../components/Loader";
import { Img } from "../../../components/Header/styles";
import { toast } from "react-toastify";
const Component = ({ confirmationAuth, updateStates }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [accepted, setAccepted] = useState<boolean>();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showModal("invitation-mail"));
  }, []);

  useEffect(() => {
    if (updateStates.success) {
      setAccepted(true);
      dispatch(getConfirmationAuth(searchParams.get("token")));
      setTimeout(() => {
        dispatch(resetUpdateConfirmationAuth());
      }, 1000);
    }
    if (updateStates.error) {
      toast.error(updateStates.error);
      dispatch(getConfirmationAuth(searchParams.get("token")));
      setTimeout(() => {
        dispatch(resetUpdateConfirmationAuth());
      }, 1000);
    }
  }, [updateStates]);

  useEffect(() => {
    if (!confirmationAuth) {
      dispatch(getConfirmationAuth(searchParams.get("token")));
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      searchParams.get("token") &&
      !!confirmationAuth?.active &&
      confirmationAuth?.status === "PENDING"
    ) {
      dispatch(updateConfirmationAuth(searchParams.get("token")));
    }
  }, [searchParams, confirmationAuth]);
  return (
    <div>
      <Modal name="invitation-mail" remove>
        {(!confirmationAuth ||
          (!!confirmationAuth?.active &&
            confirmationAuth?.status === "PENDING")) && (
          <Loader color="Primary"></Loader>
        )}
        {!!confirmationAuth &&
          confirmationAuth?.status === "ACCEPTED" &&
          accepted === undefined && (
            <>
              <div style={{ position: "absolute", left: "0px" }}>
                <FlexContainer gap="10px" align="center">
                  {confirmationAuth?.platform === "CAMPUS" && (
                    <Img src={CampusLogo}></Img>
                  )}
                  <Img
                    alt="constana-icon"
                    src={
                      confirmationAuth?.platform !== "CAMPUS"
                        ? Constana
                        : Campus
                    }
                  ></Img>
                </FlexContainer>
              </div>
              <Grid.Row style={{ marginTop: "66px", marginBottom: "93px" }}>
                <Center>
                  <h2>Esta confirmacion ya ha sido utilizada</h2>
                </Center>
              </Grid.Row>
              <Grid.Row style={{ marginBottom: "125px" }}>
                <Center>
                  <div>
                    <img src={Reject} alt="status-logo" />
                  </div>
                </Center>
              </Grid.Row>
              <Grid.Row>
                <Center>
                  <Button
                    onClick={() => {
                      window.location.href = "/sign-in";
                    }}
                    type="button"
                    options={{
                      type: "filled",
                      skin: "primary",
                      size: "lg",
                      marginBottom: "0px",
                    }}
                    style={{
                      marginLeft: "10px",
                      ...(confirmationAuth.platform === "CAMPUS" && {
                        backgroundColor: `#7137c7`,
                        border: "1px solid #7137c7",
                        width: "100%",
                        height: "26px",
                      }),
                    }}
                  >
                    {confirmationAuth.platform === "CAMPUS"
                      ? "Ir a constana campus"
                      : "Ir a constana"}
                  </Button>
                </Center>
              </Grid.Row>
            </>
          )}
        {!!confirmationAuth &&
          confirmationAuth?.status === "ACCEPTED" &&
          accepted === true && (
            <>
              <Grid.Row style={{ marginTop: "66px", marginBottom: "93px" }}>
                <Center>
                  <h2>Se confirmo tu cuenta correctamente</h2>
                </Center>
              </Grid.Row>
              <Grid.Row style={{ marginBottom: "125px" }}>
                <Center>
                  <div>
                    <img src={Accepted} alt="status-logo" />
                  </div>
                </Center>
              </Grid.Row>
              <Grid.Row>
                <Center>
                  <Button
                    onClick={() => {
                      window.location.href =
                        confirmationAuth.platform === "CAMPUS"
                          ? "https://academia.catel.org.ar"
                          : "https://academy.academia.catel.org.ar/sign-in";
                    }}
                    type="button"
                    options={{
                      type: "filled",
                      skin: "primary",
                      size: "lg",
                      marginBottom: "0px",
                    }}
                    style={{
                      marginLeft: "10px",
                      ...(confirmationAuth.platform === "CAMPUS" && {
                        border: "1px solid #7137c7",
                        backgroundColor: `#7137c7`,
                      }),
                    }}
                  >
                    {confirmationAuth.platform === "CAMPUS"
                      ? "Ir a constana campus"
                      : "Ir a constana"}
                  </Button>
                </Center>
              </Grid.Row>
            </>
          )}
      </Modal>
    </div>
  );
};

const states = ({ confirmationAuthStore }) => {
  const { data: confirmationAuth } = confirmationAuthStore.confirmationAuth;
  const { states: updateStates } = confirmationAuthStore.updateConfirmationAuth;
  return {
    confirmationAuth,
    updateStates,
  };
};

export default connect(states)(Component);
