import { connect, useDispatch } from "react-redux";
import { Text } from "./styles";
import { Center } from "../../../../../../components/StyledComponents";
import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";
import { EventDto } from "../../../../../../types/event.dto";
import { hideModal } from "../../../../../../store/actions/modal";
import { useEffect } from "react";
import {
  resetUpdateEvent,
  updateEvent,
} from "../../../../../../store/actions/event";

interface ComponentProps {
  event: EventDto;
  updateEventStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ event, updateEventStates }: ComponentProps) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(hideModal());
  };

  const deleteEvent = () => {
    dispatch(updateEvent({ ...event, active: false }));
  };

  useEffect(() => {
    if (updateEventStates.success) {
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetUpdateEvent());
      }, 2000);
    }
  }, [updateEventStates]);

  return (
    <Modal name="delete-event-modal" title="Eliminar Evento">
      <Text>
        ¿Estas seguro de eliminar el Evento <br /> {event?.name}?
      </Text>
      <Center>
        <Button
          onClick={deleteEvent}
          type="button"
          options={{
            type: "filled",
            skin: "primary",
            size: "lg",
            marginBottom: "0px",
          }}
          style={{ marginRight: "10px" }}
        >
          Eliminar
        </Button>
        <Button
          onClick={closeModal}
          type="button"
          options={{
            type: "outline",
            skin: "danger",
            size: "lg",
            marginBottom: "0px",
          }}
        >
          Cancelar
        </Button>
      </Center>
    </Modal>
  );
};

const states = ({ eventStore }) => {
  const { data: event } = eventStore.event;
  const { states: updateEventStates } = eventStore.updateEvent;

  return {
    event,
    updateEventStates,
  };
};

export default connect(states)(Component);
