import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import services from "../../services";
import { autoSignIn } from "../../store/actions/auth";
import { UserDto } from "../../types/user.dto";

interface AuthContextType {
  isAuthenticated: boolean;
  user: UserDto | null;
}

interface AuthProviderType {
  children: React.ReactNode;
  user: UserDto;
}

export let AuthContext = React.createContext<AuthContextType>(null!);

const AuthProvider = ({ children, user }: AuthProviderType) => {
  return (
    <AuthContext.Provider value={{ user, isAuthenticated: !!user }}>
      {children}
    </AuthContext.Provider>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;

  return {
    user,
  };
};

export default connect(states)(AuthProvider);
