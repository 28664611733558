import styled from "styled-components";

export const SuscriptionCard = styled("div")`
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(36, 150, 237, 0.25);
  border-radius: 10px;
  padding: 10px;
`;

export const SuscriptionCardLabel = styled("h4")`
  color: ${(props) => (!!props.color ? props.theme.colors[props.color] : "")};
  font-weight: 600;
  font-size: 12px;
`;
export const SuscriptionCardTitle = styled("h2")`
  color: ${(props) => (!!props.color ? props.theme.colors[props.color] : "")};
  font-size: 14px;
  font-weight: bold;
`;

export const SuscriptionText = styled("h3")`
  font-size: ${(props) => props.size}px;
  font-weight: 500;
  color: #697482;
`;

export const FileButton = styled("div")`
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  border: solid 1px #000;
  background-color: #e7e7e7;
  cursor: pointer;
`;
