import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Grid from "../../../../components/Grid";
import { GridStructure } from "../../../../components/Layout/Dashboard/styles";
import {
  Center,
  FlexContainer,
  PageTitle,
  Separator,
} from "../../../../components/StyledComponents";
import { DateFromNow } from "../../../../helpers/format-date";

import contentActions from "../../../../store/actions/content";

import { CohortDto } from "../../../../types/cohort.dto";
import { StateDto } from "../../../../types/states.dto";
import { ContentDto } from "../../../../types/content.dto";
import FilterButton from "./components/FilterButton";
import FilterModal from "./components/FilterModal";
import { AcademyDto } from "../../../../types/academy.dto";
import { UserDto } from "../../../../types/user.dto";
import FilterByName from "./components/FilterByName";
import { useNavigate } from "react-router-dom";
import InfinityScroll from "../../../../components/InfinityScroll";
import ToggleSlider from "../../../../components/ToggleSlider";
import { addClick } from "../../../../store/actions/userTimes";
import ContentCard from "../../../../components/ContentCard";
import { SlideLink } from "../../../../components/Slider/style";
import {
  ContentCardTitle,
  ContentDataText,
  ContentIcon,
} from "../../../../components/ContentCard/style";
import { Img } from "../../../SignIn/styles";
import { HiSquares2X2 } from "react-icons/hi2";
import Skeleton from "../../../../components/Skeleton";

/* const filterValues: any = [
 {
    fieldName: ["visibility", "order"],
    value: "no",
    name: "Todos",
  },
  {
    fieldName: "order",
    value: "recent",
    name: "Recientes",
    groupal: (array: ContentDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "order",
    value: "older",
    name: "Antiguos",
    groupal: (array: ContentDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "published",
    value: true,
    name: "Publicos",
  },
  {
    fieldName: "published",
    value: false,
    name: "Privados",
  },
]; */

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  contents,
  newContentData,
  newContentDataStates,
  cohorts,
  academies,
  user,
  /*   queryContents,
  queryContentsStates, */
  contentsStates,
}: {
  contents: ContentDto[];
  newContentData: ContentDto;
  newContentDataStates: StateDto;
  cohorts: CohortDto[];
  academies: AcademyDto[];
  user: UserDto;
  /*  queryContents: ContentDto[];
  queryContentsStates: StateDto; */
  contentsStates: StateDto;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);

  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [queryMode, setQueryMode] = useState<string | null>(null);
  const [query, setQuery] = useState<string | null>(null);
  const [currentContentsList, setCurrentContentsList] = useState<any[]>([]);
  const [
    filteredCurrentContentsListValues,
    setFilteredCurrentContentsListValues,
  ] = useState<ContentDto[]>([]);
  const handleQuery = (ev) => {
    const value = ev.target.value;
    setQuery(value.length === 0 ? "" : value);
  };
  const searchQuery = (ev) => {
    if (ev.keyCode === 13) {
      /*  dispatch(queryImage({ keyword: query })); */
      setQueryMode(query);
    }
  };
  const filterValues: any = [
    {
      fieldName: "private",
      value: "no",
      name: "Todos",
    },
    {
      fieldName: "private",
      value: true,
      name: "Academias Privadas",
      special: (array, value, currentItem) => {
        return currentItem.academy.private === value;
      },
    },
    {
      fieldName: "private",
      value: false,
      name: "Academias Publicas",
      special: (array, value, currentItem) => {
        return currentItem.academy.private === value;
      },
    },
  ];
  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 9,
      offset: 9 * Math.floor(page / 2),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        published: true,
      },
    };
    const sortBy = page % 2 === 0 ? "createdAt" : "viewCount";

    const sortDirection = "desc";
    dispatch(
      contentActions.getAll({
        ...payload,
        sortBy,
        sortDirection,
        ...(queryMode && { keyword: query }),
      })
    );
  };
  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter((applyedFilter) =>
        !Array.isArray(newFilter.field)
          ? applyedFilter.field === newFilter.field
          : newFilter.field.includes(applyedFilter.field)
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    if (filterRemoved) {
      /**
       * Se elimina el filtro
       */
      if (Array.isArray(newFilter.field)) {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => !newFilter.field.includes(applyedFilter.field)
          ),
        ]);
      } else {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => applyedFilter.field !== newFilter.field
          ),
        ]);
      }
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  /* useEffect(() => {
    if (!!contents && !!academies && !queryContents) {
      const filterContents = contents.filter(
        (content) => !!content.active && !!content.available && !!content.published
      );

      setCurrentContentsList(filterContents);
    }
    if (!!contents && !!academies && !!queryContents) {
      const filterContents = queryContents.filter(
        (content) => !!content.active && !!content.available && !!content.published
      );

      setCurrentContentsList(filterContents);
    }
  }, [contents, academies, queryContents]); */

  useEffect(() => {
    if (queryMode) {
      setPage(0);
      setIsLoading(false);
      setThereAreItems(true);
      setCurrentContentsList([]);
    }
  }, [queryMode]);

  useEffect(() => {
    const filteredTalents = currentContentsList?.filter(
      (currentTalentValues) => {
        const thereAreFilters =
          applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
            .length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter(
            (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
          )
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value,
            currentTalentValues
          );
        });
        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentContentsListValues(initResult);
  }, [applyedFilters, currentContentsList]);

  useEffect(() => {
    if (!isLoading && !!contents) {
      setCurrentContentsList((state) => [...state, ...contents]);
    }
    setThereAreItems(!!contents && contents?.length > 0);
  }, [contents, isLoading]);

  useEffect(() => {
    setIsLoading(contentsStates.loading);
  }, [contentsStates]);

  useEffect(() => {
    return () => {
      dispatch(contentActions.resetGetAllContents());
    };
  }, []);

  useEffect(() => {
    if (newContentDataStates.success) {
      dispatch(contentActions.resetCreate());
    }
    if (newContentDataStates.error) {
      toast.error(newContentDataStates.error);
      dispatch(contentActions.resetCreate());
    }
  }, [newContentDataStates]);

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <PageTitle>Contents</PageTitle>
          </Grid.Col>
        </Grid.Row>
        <Center>
          <Grid.Row style={{ width: "100%" }}>
            <Grid.Col lg={12} md={12} sm={12} style={{ marginBottom: "14px" }}>
              <FlexContainer
                style={{ width: "100%", maxWidth: "700px", margin: "0 auto" }}
              >
                <FilterByName
                  searchQuery={searchQuery}
                  setFilter={handleQuery}
                  value={query}
                ></FilterByName>
              </FlexContainer>
            </Grid.Col>
          </Grid.Row>
        </Center>
        <Separator size={12}></Separator>
        {/*  <ToggleSlider>
          {filterValues.map((filterValue) => {
            return (
              <FilterButton
                setFilter={buildFilters}
                name={filterValue.name}
                fieldName={filterValue.fieldName}
                value={filterValue.value}
                filters={applyedFilters}
                {...((!!filterValue.special || !!filterValue.groupal) && {
                  special: !!filterValue.special
                    ? { name: "special", function: filterValue.special }
                    : { name: "groupal", function: filterValue.groupal },
                })}
              ></FilterButton>
            );
          })}
        </ToggleSlider> */}
        <Separator size={12}></Separator>
        <Grid.Row>
          <Grid.Col>
            <InfinityScroll
              action={fetchData}
              page={page}
              setPage={setPage}
              data={filteredCurrentContentsListValues}
              active={thereAreItems}
              isLoading={isLoading}
              loader={
                <Skeleton
                  itemsPerPage={9}
                  itemsPerRow={3}
                  animated
                  size={{ height: 290, width: 276 }}
                />
              }
            >
              <GridStructure
                width={"275px"}
                maxWidth="auto"
                style={{ width: "100%", margin: "0 auto" }}
                limitCard={"275px"}
              >
                {filteredCurrentContentsListValues.map((item) => {
                  return (
                    <ContentCard
                      ContentTypeLogo={{ icon: HiSquares2X2 }}
                      cardOnClick={(item) => {
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                        window.open(item.link, "_blank");
                      }}
                      item={item}
                    >
                      <SlideLink
                        href={item.link}
                        target="_blank"
                        onClick={(ev) => {
                          ev.stopPropagation();
                        }}
                      >
                        <FlexContainer gap="12px">
                          <ContentIcon
                            style={{ cursor: "pointer" }}
                            onClick={(ev) => {
                              ev.preventDefault();
                              ev.stopPropagation();
                              navigate(
                                `/dashboard/academy/${item.academy._id}`
                              );
                            }}
                          >
                            <Img src={item.picture}></Img>
                          </ContentIcon>
                          <FlexContainer direction="column" gap="5px">
                            <ContentCardTitle style={{ fontSize: "14px" }}>
                              {!!item.name && item.name}
                            </ContentCardTitle>

                            <ContentDataText>
                              {item.academy.name}
                            </ContentDataText>

                            <FlexContainer align="center" gap="5px">
                              <ContentDataText>{`Vistas: ${item.viewCount}`}</ContentDataText>

                              <span>|</span>

                              <ContentDataText>
                                {DateFromNow(item.createdAt)}
                              </ContentDataText>
                            </FlexContainer>
                          </FlexContainer>
                        </FlexContainer>
                      </SlideLink>
                    </ContentCard>
                  );
                })}
              </GridStructure>
            </InfinityScroll>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      {!!cohorts && <FilterModal buildFilters={buildFilters}></FilterModal>}
    </>
  );
};

const states = ({ contentStore, cohortStore, academyStore, userStore }) => {
  const { data: contents, states: contentsStates } = contentStore.all;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: newContentData, states: newContentDataStates } =
    contentStore.create;
  const { data: academies } = academyStore.all;
  const { data: user } = userStore;
  /*   const { data: queryContents, states: queryContentsStates } =
    contentStore.query; */
  return {
    contents,
    contentsStates,
    newContentData,
    newContentDataStates,
    academies,
    cohorts,
    user,
    /*   queryContents,
    queryContentsStates, */
  };
};

export default connect(states)(Component);
