import styled from "styled-components";

export const Avatar = styled("div")`
  padding: 2px;
  background: rgb(30, 120, 232);
  border-radius: 100%;
  background: linear-gradient(
    0deg,
    rgba(30, 120, 232, 1) 0%,
    rgba(219, 241, 255, 1) 100%
  );
  margin-right: 8px;
`;

export const Letter = styled("div")`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.Night};
`;
