// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-next::before {
  display: none;
}

.slick-next {
  right: -20px;
}

.slick-prev {
  left: -20px;
}

.slick-list {
  padding: 2px 0px;
}

.slick-slider {
  padding: 2px 20px;
}

.slick-prev::before {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/AsynCarousel/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".slick-next::before {\r\n  display: none;\r\n}\r\n\r\n.slick-next {\r\n  right: -20px;\r\n}\r\n\r\n.slick-prev {\r\n  left: -20px;\r\n}\r\n\r\n.slick-list {\r\n  padding: 2px 0px;\r\n}\r\n\r\n.slick-slider {\r\n  padding: 2px 20px;\r\n}\r\n\r\n.slick-prev::before {\r\n  display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
