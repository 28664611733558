import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import Input from "./components/Field";
import {
  initialValues,
  schema,
} from "../../constants/form/suscription/change-suscription";

import Modal from "../Modal";
import {
  Center,
  DotContent,
  FlexContainer,
  ItemDataText,
  Separator,
} from "../StyledComponents";
import Submit from "../Form/Submit";
import Button from "../Button";
import Slider from "./components/Slider";
import { CardWrapper } from "./components/Slider/style";
import { hideModal } from "../../store/actions/modal";
import { Img } from "../../pages/SignIn/styles";
import {
  FileButton,
  SuscriptionCard,
  SuscriptionCardLabel,
  SuscriptionCardTitle,
  SuscriptionText,
} from "./style";
import { GridCardSmallContainer } from "../Layout/Dashboard/styles";
import HoverButton from "../HoverButton";
import { BsBank2 } from "react-icons/bs";
import { FaMoneyBillWaveAlt } from "react-icons/fa";

/* interface ComponentProps {
  updateUserStates: StateDto;
  user: UserDto;
} */

const Component = ({
  title,
  description,
  onClose,
  setAction,
  onSubmitModal,
  frecuency,
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [numberCard, setNumberCard] = useState(0);

  const onSubmit = ({ values, actions }) => {
    !!onSubmitModal && onSubmitModal(values);
  };

  const selectCard = (oldIndex, newIndex) => {
    setNumberCard(newIndex);
  };

  return (
    <Modal
      onClose={onClose}
      description={description}
      name="send-transfer"
      title={title}
    >
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-event-form"
            >
              <Separator size={10}></Separator>
              <label>
                <Input
                  name="videoImg"
                  error={errors["videoImg"]}
                  touched={touched["videoImg"]}
                  accept="image/*"
                  type="file"
                  onChange={(ev) => {
                    handleChange(ev);
                  }}
                  onBlur={handleBlur}
                  options={{ marginBottom: 0.1 }}
                  containerStyles={{
                    visibility: "hidden",
                    width: "0",
                    height: "0",
                  }}
                ></Input>
                <FileButton>Adjuntar comprobante de pago</FileButton>
              </label>

              <Separator size={10}></Separator>
              <SuscriptionText>Detalle de la suscripción:</SuscriptionText>

              <SuscriptionCard>
                <FlexContainer align="center" gap="25px" wrap="wrap">
                  <GridCardSmallContainer>
                    {/*  <Img src={el}></Img> */}
                  </GridCardSmallContainer>
                  <SuscriptionCardTitle>Titulo</SuscriptionCardTitle>
                  <FlexContainer align="center" gap="3px">
                    <DotContent></DotContent>
                    <FlexContainer gap="2px" align="center">
                      <SuscriptionCardLabel color="black">
                        Miembros:
                      </SuscriptionCardLabel>
                      <ItemDataText>23</ItemDataText>
                    </FlexContainer>

                    <SuscriptionCardLabel color="Gray"></SuscriptionCardLabel>
                  </FlexContainer>
                </FlexContainer>
                <FlexContainer direction="column" gap="12px">
                  <FlexContainer gap="10px" align="center">
                    <SuscriptionCardLabel color="black">
                      Tipo de Suscripcion:
                    </SuscriptionCardLabel>
                    <ItemDataText></ItemDataText>
                  </FlexContainer>
                  <FlexContainer gap="10px" align="center">
                    <SuscriptionCardLabel color="black">
                      Suscripcion:
                    </SuscriptionCardLabel>
                    <ItemDataText></ItemDataText>
                  </FlexContainer>
                  <FlexContainer gap="10px" align="center">
                    <SuscriptionCardLabel color="black">
                      Proximo Vencimiento:
                    </SuscriptionCardLabel>
                    <ItemDataText></ItemDataText>
                  </FlexContainer>
                  <FlexContainer gap="10px" align="center">
                    <SuscriptionCardLabel color="black">
                      Metodo de Pago utilizado:
                    </SuscriptionCardLabel>
                    <SuscriptionCard></SuscriptionCard>
                  </FlexContainer>
                </FlexContainer>
              </SuscriptionCard>
              <Center>
                <Submit
                  isSubmmiting={false}
                  form="edit-event-form"
                  color="Violet"
                  options={{
                    type: "filled",
                    skin: "violet",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                >
                  Pagar Suscriptcion
                </Submit>
              </Center>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default Component;
