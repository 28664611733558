import {
  Navbar,
  Nav,
  NavItems,
  NavItem,
  NavLink,
  ButtonLink,
  DropdownMenu,
  DropdownItem,
  SubNavLink,
  Caret,
  DropdownMenuChild,
} from "./styles";

import Icon from "../Icon";
import CaretDown from "../../assets/icons/menu/caret-down.svg";
import { connect, useDispatch } from "react-redux";
import { UserDto } from "../../types/user.dto";
import { useEffect, useState } from "react";
import {
  HiDocumentText,
  HiOutlineDocumentText,
  HiPresentationChartBar,
} from "react-icons/hi";
import { IconType } from "react-icons/lib";
import {
  FaGraduationCap,
  FaHome,
  FaUserGraduate,
  FaUniversity,
  FaPlay,
  FaPaperPlane,
  FaMoneyCheckAlt,
} from "react-icons/fa";
import {
  RiOrganizationChart,
  RiDashboardFill,
  RiVideoAddFill,
} from "react-icons/ri";
import { AiFillSignal, AiFillCalendar, AiFillBell } from "react-icons/ai";
import { BiBookBookmark } from "react-icons/bi";
import { TiStarburst } from "react-icons/ti";
import { FlexContainer } from "../StyledComponents";
import { BsFillImageFill } from "react-icons/bs";
import { MdAudiotrack, MdOutlinePermMedia } from "react-icons/md";
import { showModal } from "../../store/actions/modal";
interface ComponentProps {
  user: UserDto;
  sidebarCollapsed: boolean;
  selectedAcademy;
}

const Component = ({
  user,
  sidebarCollapsed,
  selectedAcademy,
}: ComponentProps) => {
  const [activeDropdown, setActiveDropdown] = useState<string>("");
  const [activeDropdownChildren, setActiveDropdownChildren] =
    useState<string>();
  const [academyPrefix, setAcademyPrefix] = useState<string>();
  const iconStateComponent = (Icon: IconType) => {
    return <Icon size={18} color={"#6a6970"}></Icon>;
  };
  const dispatch = useDispatch();

  const makeSubMenu = (
    childrens,
    isActiveDropdown,
    mainLink,
    activeDropdown
  ) => {
    if (!childrens) return null;

    return childrens.map((children) => {
      return !!children.children && !sidebarCollapsed ? (
        <NavItem
          style={{ padding: "0" }}
          className={sidebarCollapsed ? "sidebar-collapsed" : ""}
        >
          <ButtonLink
            style={{ padding: "7px 10px", width: "100%" }}
            onClick={(event) => {
              setActiveDropdown(
                activeDropdown.includes(`${mainLink}${children.link}`)
                  ? mainLink
                  : `${mainLink}${children.link}`
              );
            }}
            className={
              activeDropdown.includes(`${mainLink}${children.link}`)
                ? "active"
                : ""
            }
          >
            <FlexContainer align="center" gap="5px">
              {!!children.icon && iconStateComponent(children.icon)}

              <span>{children.title}</span>
            </FlexContainer>

            <Caret className="caret">
              <Icon icon={CaretDown} color="#697482" size="18px" />
            </Caret>
          </ButtonLink>

          <DropdownMenuChild
            amountItems={children.children.length}
            isActiveDropdown={activeDropdown.includes(
              `${mainLink}${children.link}`
            )}
            position="bottom"
          >
            {makeSubMenu(
              children.children,
              isActiveDropdown,
              `${mainLink}${children.link}`,
              activeDropdown
            )}
          </DropdownMenuChild>
        </NavItem>
      ) : children?.children ? (
        <>
          {children?.children.map((element) => (
            <DropdownItem>
              <SubNavLink
                className={({ isActive }) => (isActive ? "active" : undefined)}
                onClick={() => {
                  setActiveDropdown(`${mainLink}${element.link}`);
                  setActiveDropdownChildren(`${mainLink}${element.link}`);
                }}
                to={`/dashboard${children.link}${element.link}`}
              >
                <FlexContainer align="center" gap="5px">
                  {!!element.icon && iconStateComponent(element.icon)}

                  <span>
                    {children?.shortTitle} - {element.title}
                  </span>
                </FlexContainer>
              </SubNavLink>
            </DropdownItem>
          ))}
        </>
      ) : (
        <DropdownItem>
          <SubNavLink
            className={({ isActive }) => (isActive ? "active" : undefined)}
            onClick={() => {
              setActiveDropdown(`${mainLink}${children.link}`);
              setActiveDropdownChildren(`${mainLink}${children.link}`);
            }}
            to={`/dashboard${mainLink}${children.link}`}
          >
            <FlexContainer align="center" gap="5px">
              {!!children.icon && iconStateComponent(children.icon)}

              <span>{children.title}</span>
            </FlexContainer>
          </SubNavLink>
        </DropdownItem>
      );
    });
  };

  useEffect(() => {
    if (!!selectedAcademy) {
      setAcademyPrefix(`/academy/${selectedAcademy._id}`);
    }
  }, [selectedAcademy]);
  const menu: {
    link: string;
    title: string;
    shortTitle?: string;
    icon: IconType;
    children?: any[];
  }[] = [
    {
      link: "/home",
      title: "Home",
      icon: FaHome,
    },
    {
      link: "/cdocs",
      title: "CDocs",
      icon: HiDocumentText,
    },
    {
      link: "/cohorts",
      title: "Cohorts",
      icon: FaGraduationCap,
    },
    {
      link: "/roadmaps",
      title: "Trayectos",
      icon: RiOrganizationChart,
    },
    {
      link: "/videos",
      title: "Videos",
      icon: FaPlay,
    },
    {
      link: "/slideshows",
      title: "SlideShows",
      icon: HiPresentationChartBar,
    },
    {
      link: "/images",
      title: "Imagenes",
      icon: BsFillImageFill,
    },
    {
      link: "/listens",
      title: "Listens",
      icon: MdAudiotrack,
    },
    /*  {
        link: "/suscriptions",
        title: "Suscripciones",
        icon: FaMoneyCheckAlt,
      }, */
    {
      link: "/bytes",
      title: "Bytes",
      icon: RiVideoAddFill,
    },
    {
      link: "/varieties",
      title: "Contenidos Varios",
      icon: MdOutlinePermMedia,
    },
    {
      link: "/personal",
      title: "Personal",
      icon: FaUserGraduate,
      children: [
        {
          link: "/myinvitations",
          title: "Mis Invitaciones",
          icon: FaPaperPlane,
        },
        {
          link: "/mycohorts",
          title: "Mis Cohorts",
          icon: FaGraduationCap,
        },
        {
          link: "/myacademies",
          title: "Mis Academias",
          icon: FaUniversity,
        },
        {
          link: "/mycontents",
          title: "Mis Contenidos",
          icon: BiBookBookmark,
        },
        {
          link: "/mycertificates",
          title: "Mis Certificados",
          icon: TiStarburst,
        },
        /*  {
          link: "/myanalytics",
          title: "Mis Analytics",
          icon: AiFillSignal,
        }, */
        {
          link: "/mydashboards",
          title: "Mis Dashboards",
          shortTitle: "Dashboard",
          icon: RiDashboardFill,
          children: [
            {
              link: "/professional",
              title: "Profesional",
              icon: RiDashboardFill,
            },
            {
              link: "/education",
              title: "Educativo",
              icon: RiDashboardFill,
            },
            {
              link: "/contents",
              title: "Contenidos",
              icon: RiDashboardFill,
              children: [
                {
                  link: "/academy",
                  title: "Academia",
                  icon: RiDashboardFill,
                },
                {
                  link: "/cohort",
                  title: "Cohort",
                  icon: RiDashboardFill,
                },
                {
                  link: "/general",
                  title: "General",
                  icon: RiDashboardFill,
                },
              ],
            },
          ],
        },
        {
          link: "/mycalendar",
          title: "Mi Calendario",
          icon: AiFillCalendar,
        },
      ],
    },
    {
      link: "/notifications",
      title: "Notificaciones",
      icon: AiFillBell,
    },
  ];

  return (
    <Navbar>
      <Nav className={sidebarCollapsed ? "sidebar-collapsed" : ""}>
        <NavItems>
          <>
            {menu.map((menuItem) => {
              const isActiveDropdown =
                menuItem.link === activeDropdown ||
                menuItem.link === activeDropdownChildren ||
                activeDropdown?.includes(menuItem.link);

              return (
                <NavItem
                  className={sidebarCollapsed ? "sidebar-collapsed" : ""}
                >
                  {menuItem.children ? (
                    <>
                      <ButtonLink
                        onClick={(event) => {
                          if (!user) {
                            dispatch(showModal("sign-in-content"));
                          } else {
                            setActiveDropdown(
                              isActiveDropdown ? "" : menuItem.link
                            );
                          }
                        }}
                        className={isActiveDropdown ? "active" : ""}
                      >
                        <FlexContainer align="center" gap="5px">
                          {!!menuItem.icon && iconStateComponent(menuItem.icon)}

                          <span>{menuItem.title}</span>
                        </FlexContainer>

                        <Caret className="caret">
                          <Icon icon={CaretDown} color="#697482" size="18px" />
                        </Caret>
                      </ButtonLink>

                      <DropdownMenu
                        amountItems={menuItem.children.length}
                        isActiveDropdown={isActiveDropdown}
                        subChilds={
                          activeDropdown?.includes(menuItem.link) &&
                          activeDropdown?.length > menuItem.link.length
                        }
                        position="bottom"
                      >
                        {makeSubMenu(
                          menuItem.children,
                          isActiveDropdown,
                          menuItem.link,
                          activeDropdown
                        )}
                      </DropdownMenu>
                    </>
                  ) : (
                    <>
                      {user || menuItem.link === "/home" ? (
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : undefined
                          }
                          to={`/dashboard${menuItem.link}`}
                          onClick={() => {
                            setActiveDropdown(
                              activeDropdown === menuItem.link
                                ? ""
                                : menuItem.link
                            );
                            setActiveDropdownChildren(menuItem.link);
                          }}
                        >
                          <FlexContainer align="center" gap="5px">
                            {!!menuItem.icon &&
                              iconStateComponent(menuItem.icon)}

                            <span>{menuItem.title}</span>
                          </FlexContainer>
                        </NavLink>
                      ) : (
                        <ButtonLink
                          onClick={() => {
                            dispatch(showModal("sign-in-content"));
                          }}
                        >
                          <FlexContainer align="center" gap="5px">
                            {!!menuItem.icon &&
                              iconStateComponent(menuItem.icon)}

                            <span>{menuItem.title}</span>
                          </FlexContainer>
                          <div></div>
                        </ButtonLink>
                      )}
                    </>
                  )}
                </NavItem>
              );
            })}
          </>
        </NavItems>
      </Nav>
    </Navbar>
  );
};

const states = ({ userStore, academyStore }) => {
  const { data: user } = userStore;
  const { data: selectedAcademy } = academyStore.selected;

  return {
    user,
    selectedAcademy,
  };
};

export default connect(states)(Component);
