import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Player from "react-player";
import VideoSpinner from "../../components/VideoSpinner";
import CustomControllers from "./components/CustomControllers";
import {
  ImageListening,
  LightWrapper,
  ShortMute,
  SpinnerWrapper,
  VideoPreview,
} from "./style";
import screenfull from "screenfull";
import { useWindowSize } from "../../hooks/useWindowScreen";
import { BsFillVolumeUpFill, BsVolumeMuteFill } from "react-icons/bs";
import { FaPause, FaPlay } from "react-icons/fa";
import { useTheme } from "styled-components";
const Component = forwardRef(
  (
    {
      video,
      CustomPlay,
      lightImage,
      children,
      playerHeight,
      audio,
      noControlls,
      onPlay,
      onEnd,
      onReady,
      key,
    }: {
      video?: string;
      CustomPlay: any;
      lightImage?: string;
      children?;
      playerHeight: string;
      audio?: any;
      noControlls?: boolean;
      onPlay?: any;
      onEnd?: any;
      key?;
      onReady?;
    },
    ref
  ) => {
    const [isPlayed, setIsPlayed] = useState<boolean>(true);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [currentSeek, setCurrentSeek] = useState<number>(0);
    const [volumeBar, setVolumeBar] = useState<number>(80);
    const [volume, setVolume] = useState<number>(0.8);
    const [totalVideoDuration, setTotalVideoDuration] = useState<number>(0);
    const [isMuted, setIsMuted] = useState<boolean>(true);
    const [lightOn, setLightOn] = useState<boolean>(true);
    const [previousVolume, setPreviousVolume] = useState<number>(0);
    const [buffering, setBuffering] = useState<boolean>(true);
    let playerRef: any = useRef();
    let playerWapperRef: any = useRef(null);
    const { isMobile } = useWindowSize();
    const theme = useTheme();
    useImperativeHandle(
      ref,
      () => ({
        resetPlayer: () => {
          if (playerRef.current) {
            setCurrentSeek(0);
            playerRef.current.seekTo(Number(0));
          }
        },
        resetLight: () => {
          if (playerRef.current) playerRef.current.showPreview();
          setLightOn(true);
        },
        play: () => {
          if (playerRef.current && playerRef.current.play)
            playerRef.current.play();
        },
        getIsPlaying: () => isPlayed,
        setIsPlaying: ({
          muteValue,
          playValue,
        }: {
          muteValue?;
          playValue?;
        }) => {
          console.log(muteValue, playValue, "yyyusa");
          !!muteValue && setIsMuted(muteValue);
          !!playValue && setIsPlayed(playValue);
        },
      }),
      [isPlayed, playerRef]
    );
    const handleVolumeChange = (e) => {
      setVolume(Number(e.target.value / 100));
      setVolumeBar(Number(e.target.value));
    };

    const handleOnProgress = (e) => {
      console.log("ended continuos");
      setCurrentSeek(e.playedSeconds);
    };
    const handleSeekChange = (e) => {
      if (playerRef.current) {
        setCurrentSeek(Number(e.target.value));
        playerRef.current.seekTo(Number(e.target.value));
      }
    };

    const onSeek = (e) => {
      console.log(totalVideoDuration, e, "iia");
      if (totalVideoDuration === e) {
        setCurrentSeek(0);
      }
    };

    const handlePlay = () => {
      if (totalVideoDuration === 0 && playerRef.current) {
        setTotalVideoDuration(playerRef.current.getDuration());
      }
      if (totalVideoDuration === currentSeek) {
        setCurrentSeek(0);
      }
      setIsPlayed(true);
    };

    const getDuration = (e) => {
      setTotalVideoDuration(e);
    };

    const handlePause = (e) => {
      setIsPlayed(false);
    };

    const handleMute = () => {
      if (!isMuted) {
        setVolume(0);
        setVolumeBar((state) => {
          setPreviousVolume(state);
          return 0;
        });
        setIsMuted(true);
      } else {
        setVolume(previousVolume / 100);
        setVolumeBar(previousVolume);
        setIsMuted(false);
      }
    };

    const handleSeekTo = (seconds) => {
      if (playerRef.current) {
        setCurrentSeek((state) => state + seconds);
        playerRef.current.seekTo(playerRef.current.getCurrentTime() + seconds);
      }
    };

    const handleFullScreen = () => {
      if (playerWapperRef.current) {
        screenfull.isFullscreen
          ? screenfull.exit()
          : screenfull.request(playerWapperRef.current);
      }
    };

    const handleBufferStart = () => {
      setBuffering(true);
    };

    const handleBufferEnd = () => {
      setBuffering(false);
    };

    const onVideoReady = React.useCallback(() => {
      setIsReady(true);
      /*  const timeToStart = 7 * 60 + 12.6;
      playerRef.current.seekTo(timeToStart, "seconds"); */
    }, []);

    useEffect(() => {
      if (volumeBar > 0 && !noControlls) {
        setIsMuted(false);
      }
    }, [volumeBar]);

    useEffect(() => {
      if (playerWapperRef && !lightOn) {
        const player = playerWapperRef.current;

        player.addEventListener("keydown", (ev) => {
          if (ev.keyCode === 32) {
            ev.preventDefault();
            setIsPlayed((state) => !state);
          }
          if (ev.which === 39) {
            handleSeekTo(5);
          }
          if (ev.which === 37) {
            handleSeekTo(-5);
          }
        });
      }
    }, [playerWapperRef, lightOn]);

    useEffect(() => {
      if (
        !!playerRef.current &&
        currentSeek === totalVideoDuration &&
        !noControlls &&
        !audio
      ) {
        playerRef.current.seekTo(0);
        setCurrentSeek(0);
        setIsPlayed(false);
      }
    }, [currentSeek, totalVideoDuration]);

    return (
      <VideoPreview tabIndex="0" ref={playerWapperRef} height={playerHeight}>
        {children}
        {!!audio && <ImageListening image={lightImage}></ImageListening>}
        {!!noControlls && (
          <ShortMute
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              setIsMuted((state) => !state);
            }}
          >
            {!!isMuted && <BsVolumeMuteFill size={20}></BsVolumeMuteFill>}
            {!isMuted && <BsFillVolumeUpFill size={20}></BsFillVolumeUpFill>}
          </ShortMute>
        )}
        <div
          style={{ height: "100%" }}
          onClick={(ev) => {
            ev.stopPropagation();
            isPlayed && noControlls ? handlePause(ev) : handlePlay();
          }}
        >
          <Player
            width={"100%"}
            height={"100%"}
            url={video}
            {...(!noControlls && !!isMobile && { playsinline: true })}
            /*  playsinline={true} */
            {...(!isMobile &&
              !noControlls && {
                playIcon: (
                  <LightWrapper onClick={() => setLightOn(false)}>
                    <CustomPlay onClick={() => setLightOn(false)}></CustomPlay>
                  </LightWrapper>
                ),
              })}
            {...(!isMobile && !noControlls && { light: lightImage })}
            playing={isPlayed}
            {...(isMobile &&
              !!playerRef.current &&
              !!isReady &&
              !noControlls && { controls: true })}
            muted={isMuted}
            config={{
              file: {
                attributes: {
                  preload: "auto",
                },
              },
            }}
            volume={volume}
            onSeek={onSeek}
            onProgress={handleOnProgress}
            {...(!!noControlls && { loop: true })}
            onClick={(ev) => {
              playerWapperRef.current.focus();
            }}
            onDuration={(e) => {
              getDuration(e);
            }}
            onBuffer={handleBufferStart}
            onBufferEnd={handleBufferEnd}
            onReady={onVideoReady}
            ref={playerRef}
          />
        </div>
        {!isMobile && !!buffering && !lightOn && (
          <SpinnerWrapper>
            <VideoSpinner></VideoSpinner>
          </SpinnerWrapper>
        )}

        {!!noControlls && (
          <SpinnerWrapper
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={(ev) =>
              isPlayed && noControlls ? handlePause(ev) : handlePlay()
            }
          >
            {!isPlayed && (
              <FaPlay
                size={40}
                color={theme.colors.LightBlue}
                onClick={(ev) =>
                  isPlayed && noControlls ? handlePause(ev) : handlePlay()
                }
              ></FaPlay>
            )}
          </SpinnerWrapper>
        )}

        {!isMobile && !noControlls && (
          <>
            {!lightOn && (
              <CustomControllers
                handleFullScreen={handleFullScreen}
                currentSeek={currentSeek}
                handlePause={handlePause}
                handlePlay={handlePlay}
                handleSeekChange={handleSeekChange}
                handleVolumeChange={handleVolumeChange}
                isPlayed={isPlayed}
                totalVideoDuration={totalVideoDuration}
                volumeBar={volumeBar}
                handleMute={handleMute}
                isMuted={isMuted}
              />
            )}
          </>
        )}
      </VideoPreview>
    );
  }
);

export default Component;
