import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center } from "../../../StyledComponents";
import Submit from "../../../Form/Submit";
import Button from "../../.././Button";
import Input from "../../../Form/Input";
import { hideModal } from "../../../../store/actions/modal";
import {
  forgotPassword,
  resetUpdateUser,
} from "../../../../store/actions/user";
import {
  initialValues,
  schema,
} from "../../../../constants/form/user/forgot-password";
import { StateDto } from "../../../../types/states.dto";
import { UserDto } from "../../../../types/user.dto";
import Modal from "../../../../components/Modal";

interface ComponentProps {
  updateUserStates: StateDto;
  user: UserDto;
}

const Component = ({ updateUserStates, user }: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(forgotPassword({ _id: user._id, password: values.password }));
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  /*   useEffect(() => {
    if (updateUserStates?.success) {
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetUpdateUser());
      }, 2000);
    }
    if (updateUserStates.error) {
      setTimeout(() => {
        dispatch(resetUpdateUser());
      }, 2000);
    }
  }, [updateUserStates]); */

  return (
    <Modal name="change-password" title="Crear contraseña para Constana">
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-event-form"
            >
              <Input
                name="password"
                error={errors["password"]}
                touched={touched["password"]}
                value={values["password"]}
                type="password"
                placeholder="Ingresar contraseña"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  label: "Ingrese la Contraseña",
                  marginBottom: 40,
                }}
              />
              <Input
                name="confirm-password"
                error={errors["confirm-password"]}
                touched={touched["confirm-password"]}
                value={values["confirm-password"]}
                type="password"
                placeholder="confirmar la contraseña"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  label: "Confirmar contraseña",
                  marginBottom: 20,
                }}
              />

              <Center>
                <Submit
                  isSubmmiting={updateUserStates.loading}
                  form="edit-event-form"
                  color="Primary"
                  options={{
                    type: "filled",
                    skin: "primary",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                >
                  Confirmar
                </Submit>
                <Button
                  onClick={closeModal}
                  type="button"
                  options={{
                    type: "outline",
                    skin: "danger",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </Button>
              </Center>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ userStore }) => {
  const { states: updateUserStates } = userStore.update;
  const { data: user } = userStore;
  return {
    updateUserStates,
    user,
  };
};

export default connect(states)(Component);
