import { Line } from "react-chartjs-2";
import type { ChartData, ChartOptions } from "chart.js";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface LineProps {
  options: ChartOptions<"line">;
  data: ChartData<"line">;
}

const Component = ({
  analytics,
  title,
  color,
}: {
  analytics?: any;
  title?: string;
  color?: string;
}) => {
  const Props: LineProps = {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
      },
      scales: {
        y: {
          min: 0,
          max: 5,
          ticks: {
            stepSize: 1,
          },
        },
      },
    },
    data: {
      datasets: [
        {
          label: `${title ? title : "Crecimiento Global"}`,
          data: analytics.map((analytic) => analytic.note),
          borderColor: `${color ? color : "red"}`,
          backgroundColor: `${color ? color : "red"}`,
        },
      ],
      labels: analytics.map((analytic) => analytic.level),
    },
  };
  return <Line data={Props.data} options={Props.options} />;
};

export default Component;
