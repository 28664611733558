import { connect, useDispatch } from "react-redux";
import { Text } from "./styles";
import { Center } from "../../../../../../components/StyledComponents";
import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";
import { StudentDto } from "../../../../../../types/student.dto";
import { hideModal } from "../../../../../../store/actions/modal";
import {
  resetUpdateStudent,
  updateStudent,
} from "../../../../../../store/actions/student";
import { useEffect } from "react";

interface ComponentProps {
  student: StudentDto;
  updateStudentStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ student, updateStudentStates }: ComponentProps) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(hideModal());
  };

  const deleteStudent = () => {
    dispatch(updateStudent({ ...student, active: false }));
  };

  useEffect(() => {
    if (updateStudentStates.success) {
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetUpdateStudent());
      }, 2000);
    }
  }, [updateStudentStates]);

  return (
    <Modal name="delete-student-modal" title="Eliminar Estudiante">
      <Text>
        ¿Estas seguro de eliminar el Estudiante <br /> {student?.name}?
      </Text>
      <Center>
        <Button
          onClick={deleteStudent}
          type="button"
          options={{
            type: "filled",
            skin: "primary",
            size: "lg",
            marginBottom: "0px",
          }}
          style={{ marginRight: "10px" }}
        >
          Eliminar
        </Button>
        <Button
          onClick={closeModal}
          type="button"
          options={{
            type: "outline",
            skin: "danger",
            size: "lg",
            marginBottom: "0px",
          }}
        >
          Cancelar
        </Button>
      </Center>
    </Modal>
  );
};

const states = ({ studentStore }) => {
  const { data: student } = studentStore.student;
  const { states: updateStudentStates } = studentStore.updateStudent;

  return {
    student,
    updateStudentStates,
  };
};

export default connect(states)(Component);
