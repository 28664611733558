import { put, call, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  NEW_CERTIFICATE,
  NEW_CERTIFICATE_ERROR,
  NEW_CERTIFICATE_SUCCESS,
  NewCertificate,
  GET_ALL_CERTIFICATES,
  GET_ALL_CERTIFICATES_ERROR,
  GET_ALL_CERTIFICATES_SUCCESS,
  GetAllCertificates,
  UPDATE_CERTIFICATE,
  UPDATE_CERTIFICATE_ERROR,
  UPDATE_CERTIFICATE_SUCCESS,
  UpdateCertificate,
  DELETE_CERTIFICATE,
  UPDATE_CURRENT_CERTIFICATE_LIST,
  DELETE_CERTIFICATE_SUCCESS,
  DELETE_CERTIFICATE_ERROR,
  GetCertificatesByAcademy,
  GET_CERTIFICATES_BY_ACADEMY,
} from "../types/certificate";

const companyList = ({ academyStore }) => academyStore.all.data;

function* newCertificate({ payload }) {
  const { response, error } = yield call(services.certificate.new, payload);

  if (error) {
    yield put({
      type: NEW_CERTIFICATE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const currentListOfTechsCertificates = yield select(companyList);
    const newListofTechsCertificates = !!currentListOfTechsCertificates
      ? [...currentListOfTechsCertificates, response]
      : null;
    yield put({
      type: UPDATE_CURRENT_CERTIFICATE_LIST,
      payload: newListofTechsCertificates,
    });
    yield put({ type: NEW_CERTIFICATE_SUCCESS, payload: response });
  }
}

function* deleteCertificate({ payload }) {
  const { response, error } = yield call(services.certificate.delete, payload);

  if (error) {
    yield put({
      type: DELETE_CERTIFICATE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: DELETE_CERTIFICATE_SUCCESS, payload: response });
    yield put({ type: GET_ALL_CERTIFICATES, payload: null });
  }
}

function* getAllCertificates({ payload }) {
  const { response, error } = yield call(services.certificate.getAll, payload);
  if (error) {
    yield put({
      type: GET_ALL_CERTIFICATES_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_CERTIFICATES_SUCCESS, payload: response });
  }
}

function* updatedCertificate({ payload }) {
  const { response, error } = yield call(services.certificate.update, payload);

  if (error) {
    yield put({
      type: UPDATE_CERTIFICATE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const currentListOfComapnies = yield select(companyList);
    const newListofComapnies = currentListOfComapnies?.data?.map((company) => {
      if (company._id === payload._id) {
        return { ...company, ...payload };
      }
      return company;
    });

    yield put({ type: UPDATE_CERTIFICATE_SUCCESS, payload: null });
    yield put({ type: GET_ALL_CERTIFICATES, payload: null });
  }
}

function* getCertificatesByAcademy({ payload }) {
  const { response, error } = yield call(
    services.certificate.getByAcademy,
    payload
  );
  if (error) {
    yield put({
      type: GET_ALL_CERTIFICATES_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_CERTIFICATES_SUCCESS, payload: response });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewCertificate>(NEW_CERTIFICATE, newCertificate);
  yield takeLatest<GetAllCertificates>(
    GET_ALL_CERTIFICATES,
    getAllCertificates
  );
  yield takeLatest<UpdateCertificate>(UPDATE_CERTIFICATE, updatedCertificate);
  yield takeLatest<UpdateCertificate>(DELETE_CERTIFICATE, deleteCertificate);
  yield takeLatest<GetCertificatesByAcademy>(
    GET_CERTIFICATES_BY_ACADEMY,
    getCertificatesByAcademy
  );
}
