import { initialState, Actions, State } from "../types/theme";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case "CHANGE_THEME":
      return {
        ...state,
        theme: action.payload,
      };

    default:
      return state;
  }
};

export default reducers;
