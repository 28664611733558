import styled from "styled-components";
import { GridCardSmallContainer } from "../Layout/Dashboard/styles";

export const PlayerDescriptionWrapper = styled("div")`
  padding: 11px;
`;

export const PlayerDescriptionDropDown = styled("div")`
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(5, 23, 37, 0.1);
  position: relative;
  padding: 8px;
  max-height: 80px;
  transition: 0.3s max-height ease-in-out;
  ${(props) => (props.openDescription ? "max-height:min-content;" : "")}
`;

export const PlayerDescriptionText = styled("h3")`
  font-size: 12px;
  font-weight: normal;
  color: #2a2747;
  min-height: 80px;
`;

export const PlayerDescriptionCardTitle = styled("h3")`
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  color: ${(props) => props.theme.colors.Night};
  @media (max-width: 768px) {
    font-size: 13px;
  }
  ${(props) =>
    props.overflowLine ? `-webkit-line-clamp:${props.overflowLine};` : ""}
`;

export const PlayerDescriptionParagh = styled("p")`
  display: ${(props) => props.display || "block"};
  font-family: Lato;
  font-size: 12px;
  color: #6a6970;
`;

export const PlayerDescriptionDataText = styled("h4")`
  font-size: 12px;
  display: inline-block;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  color: #6a6970;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Ajusta esto al número deseado de líneas */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
`;

export const ShowMore = styled("span")`
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.LightBlue};
  cursor: pointer;
`;

export const PlayerDescriptionEntityImage = styled(GridCardSmallContainer)``;
