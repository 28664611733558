import { colors } from "./colors";
import { breakpoints, mediaBreakpoints } from "./breakpoints";
import { zindex } from "./z-index";
import { fonts } from "./fonts";
import themes from "./colorTheme";

export const globals = {
  colors,
  zindex,
  fonts,
  mediaBreakpoints,
  breakpoints,
  themes,
};
