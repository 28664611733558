import moment from "moment";
import { useEffect } from "react";

const useBeforeUnload = (apiAction, dataLoaded) => {
  const startTime = Date.now();

  const handleBeforeUnload = (startTime) => {
    if (dataLoaded) {
      /*  console.log("megalodonas", startTime); */
      const endTime = Date.now();

      apiAction({
        startTime: moment(startTime).valueOf(),
        endTime: moment(endTime).valueOf(),
      }); // Call the provided API action with the time spent

      // Customize the message displayed in the confirmation dialog
    }
  };

  useEffect(() => {
    if (!dataLoaded) {
      return; // Exit early if dataLoaded is undefined
    }
    window.addEventListener("beforeunload", () => {
      handleBeforeUnload(startTime);
    });

    return () => {
      window.removeEventListener("beforeunload", () => {
        handleBeforeUnload(startTime);
      });
    };
  }, [dataLoaded]);

  useEffect(() => {
    if (!dataLoaded) {
      return; // Exit early if dataLoaded is undefined
    }
    return () => {
      console.log("megalodonia222");
      handleBeforeUnload(startTime);
    };
  }, [dataLoaded]);
};

export default useBeforeUnload;
