import styled from "styled-components";

export const PlayerSliderWrapper = styled("div")`
  position: relative;
`;

export const PlayerCounter = styled("h3")`
  position: absolute;
  left: 0;
  top: -20px;
`;
export const PlayerControllBottom = styled("div")`
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 15px;
  display: grid;
  grid-template-columns: 25px 1fr 25px 25px;
  align-items: center;
  gap: 10px;
`;
export const PlayerSlider = styled("input")`
  display: block;
  outline: none;
  width: 100%;
  height: 7px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.Night};

  -webkit-appearance: none;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: white;
    margin-top: -4px;
  }
  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    transition: background-image 0.1s ease-in-out;
    background-image: ${(props) =>
      `linear-gradient(90deg, green ${props.widthBackground}%, transparent ${props.widthBackground}%)`};
    border: none;
    border-radius: 3px;
    height: 7px;
  }
`;

export const PlayerVolumeWrapper = styled("div")`
  position: relative;
  &:hover > div {
    display: block;
  }
`;

export const PlayerVolumeSliderWrapper = styled("div")`
  height: 105px;
  position: absolute;
  display: none;
  bottom: 30px;
  right: -5px;
  border: 1px solid ${(props) => props.theme.colors.Night};
  background: ${(props) => props.theme.colors.Night};
  width: 40px;
`;

export const PlayerVolumeSlider = styled("input")`
  transform: rotate(270deg);
  outline: none;
  width: 90px;
  border-radius: 5px;
  position: absolute;
  top: 45px;
  right: -28px;
  -webkit-appearance: none;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: white;
    margin-top: -4px;
  }
  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    background-image: ${(props) =>
      `linear-gradient(90deg, green ${props.widthBackground}%, transparent ${props.widthBackground}%)`};
    border: none;
    border-radius: 3px;
    height: 7px;
  }
`;
