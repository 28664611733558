import React, { useEffect, useState } from "react";
import { NotificationCount } from "../../styles";
import { AiOutlineBell } from "react-icons/ai";
import { useTheme } from "styled-components";
import { connect } from "react-redux";
import services from "../../../../services";
import LoaderSpinner from "../../../LoaderSpinner";
import { StateDto } from "../../../../types/states.dto";
import { NotificationDto } from "../../../../types/notification.dto";
import { NotificationContext } from "../../../../provider/UnSeenNotificationsProvider";
const OrderArrayByDate = (array: NotificationDto[]) =>
  array.sort(
    (a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
  );
const Component = ({
  notifications,
  updateManyNotificationsStates,
  updateManyNotifications,
}: {
  notifications: NotificationDto[];
  updateManyNotificationsStates: StateDto;
  updateManyNotifications: NotificationDto[];
}) => {
  const theme = useTheme();
  const { unSeenNotifications } = React.useContext(NotificationContext);
  const [currentNotifications, setCurrentNotifications] =
    useState<NotificationDto[]>();

  useEffect(() => {
    const newItems = unSeenNotifications.filter(
      (res) => !currentNotifications?.find((st) => st._id === res._id)
    );
    setCurrentNotifications((state) =>
      OrderArrayByDate([...newItems, ...(state ?? [])])
    );
  }, [unSeenNotifications]);
  useEffect(() => {
    if (notifications) {
      setCurrentNotifications((state) =>
        OrderArrayByDate([...(state ?? []), ...notifications])
      );
    }
  }, [notifications]);
  useEffect(() => {
    if (updateManyNotificationsStates.success) {
      setCurrentNotifications((state) =>
        OrderArrayByDate(
          (state ?? []).map((st) => {
            const notificationFounded = updateManyNotifications.filter(
              (updatedNotification) => updatedNotification._id === st._id
            )[0];
            if (notificationFounded) {
              return notificationFounded;
            } else {
              return st;
            }
          })
        )
      );
    }
  }, [updateManyNotificationsStates]);
  return (
    <>
      {!currentNotifications ? (
        <NotificationCount
          className={`header-notifications`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoaderSpinner size={"10px"}></LoaderSpinner>
        </NotificationCount>
      ) : (
        <NotificationCount className={`header-notifications`}>
          {currentNotifications.filter((notification) => !notification.seen)
            .length > 99
            ? `99+`
            : currentNotifications.filter((notification) => !notification.seen)
                .length}
        </NotificationCount>
      )}

      <AiOutlineBell size={22} color={theme.colors.LightBlue}></AiOutlineBell>
    </>
  );
};

const states = ({ notificationStore }) => {
  const {
    states: updateManyNotificationsStates,
    data: updateManyNotifications,
  } = notificationStore.updateMany;
  const { data: notifications } = notificationStore.all;
  return {
    notifications,
    updateManyNotificationsStates,
    updateManyNotifications,
  };
};

export default connect(states)(Component);
