import action from "../helpers/rest-client";

export class ErrorCriterion extends Error {}

class Analytics {
  public getOne(payload) {
    console.log(payload, "monuasw");
    return action
      .Get({
        url: `/analytics/student/${payload._id}?cohort=${payload.cohorts}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getOneCohort(payload) {
    return action
      .Get({
        url: `/analytics/cohort/${payload}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getOneCohortUserTime(payload) {
    return action
      .Get({
        url: `analytics/user-times/campus-talent/cohort?cohortId=${payload.cohortId}&userId=${payload.userId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getOneUserTime(payload) {
    return action
      .Get({
        url: `analytics/user-times/campus-talent?userId=${payload.userId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getOneAcademyUserTime(payload) {
    return action
      .Get({
        url: `analytics/user-times/campus-talent/academy?userId=${payload.userId}&academyId=${payload.academyId}&topContents=${payload.topContents}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getCampusStats({ _id }) {
    return action
      .Get({
        url: `analytics/campus-talent/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getStudentsStats() {
    return action
      .Get({
        url: `/analytics/allstudents`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const analytics = new Analytics();

export default analytics;
