import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  ADD_CLICK,
  ADD_VIEW,
  AddClick,
  AddView,
  USER_TIME,
  UserTime,
} from "../types/userTimes";
import io from "socket.io-client";

const socket = io(`${process.env.REACT_APP_API_URL?.split("devplaces")[0]}`);

function* userTime({ payload }) {
  console.log(payload, "cuasasaawww");
  socket.emit("addUserTime", payload);
}

function* addClick({ payload }) {
  console.log(payload, "k6");
  socket.emit("addClick", payload);
}

function* addView({ payload }) {
  console.log("maka", payload);
  socket.emit("addView", payload);
}

export default function* applicant() {
  yield takeLatest<UserTime>(USER_TIME, userTime);
  yield takeLatest<AddClick>(ADD_CLICK, addClick);
  yield takeLatest<AddView>(ADD_VIEW, addView);
}
