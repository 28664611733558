import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_COHORT,
  GET_COHORT_SUCCESS,
  GET_COHORT_ERROR,
  NEW_COHORT,
  NEW_COHORT_SUCCESS,
  NEW_COHORT_ERROR,
  GET_ALL_COHORTS,
  GET_ALL_COHORTS_SUCCESS,
  GET_ALL_COHORTS_ERROR,
  UPDATE_COHORT,
  UPDATE_COHORT_SUCCESS,
  UPDATE_COHORT_ERROR,
  DELETE_COHORT,
  DELETE_COHORT_SUCCESS,
  DELETE_COHORT_ERROR,
  GetCohort,
  NewCohort,
  GetAllCohorts,
  UpdateCohort,
  DeleteCohort,
  DELETE_COHORT_MEMBER,
  DeleteCohortMember,
  AddViewCohort,
  ADD_VIEW_COHORT,
  UPDATE_CURRENT_COHORT_LIST,
  ADD_CLICK_COHORT,
  AddClickCohort,
  AddCohortMember,
  ADD_COHORT_MEMBER,
  SELECT_COHORT,
  QUERY_COHORT_ERROR,
  QUERY_COHORT_SUCCESS,
  QueryCohort,
  QUERY_COHORT,
  AddRatingCohort,
  ADD_RATING_COHORT,
  ManageLikDislikeCohort,
  MANAGE_LIKE_DISLIKE_COHORT,
  GET_COHORTS_OFFSET,
  GET_COHORTS_OFFSET_ERROR,
  GET_COHORTS_OFFSET_SUCCESS,
  GetCohortsOffset,
  GetCohortsByAcademy,
  GET_COHORTS_BY_ACADEMY,
  SET_GET_ONE_COHORT,
} from "../types/cohort";

const cohortsList = ({ cohortStore }) => cohortStore.allCohorts.data;

const cohortsOffset = ({ cohortStore }) => cohortStore.offSetCohort.data;

function* getAllCohorts({ payload }: GetAllCohorts) {
  const { response, error } = yield call(services.cohort.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_COHORTS_ERROR,
      payload: error.response?.data?.message,
    });
  } else {
    yield put({ type: GET_ALL_COHORTS_SUCCESS, payload: response });
  }
}
function* getAllCohortsByAcademy({ payload }: GetAllCohorts) {
  const { response, error } = yield call(services.cohort.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_COHORTS_ERROR,
      payload: error.response?.data?.message,
    });
  } else {
    yield put({ type: GET_ALL_COHORTS_SUCCESS, payload: response });
  }
}
function* GetCohortsByAcademies({ payload }) {
  const { response, error } = yield call(
    services.cohort.getFromAcademy,
    payload
  );

  if (error) {
    yield put({
      type: GET_ALL_COHORTS_ERROR,
      payload: error.response?.data?.message,
    });
  } else {
    yield put({ type: GET_ALL_COHORTS_SUCCESS, payload: response });
  }
}

function* getCohort({ payload }) {
  const { response, error } = yield call(services.cohort.getOne, payload);

  if (error) {
    yield put({
      type: GET_COHORT_ERROR,
      payload: error.response?.data?.message,
    });
  } else {
    yield put({ type: GET_COHORT_SUCCESS, payload: response });
  }
}

function* newCohort({ payload }) {
  const { response, error } = yield call(services.cohort.new, payload);

  if (error) {
    yield put({
      type: NEW_COHORT_ERROR,
      payload: error.response?.data?.message,
    });
  } else {
    yield all([put({ type: NEW_COHORT_SUCCESS, payload: response })]);
  }
}

function* deleteMember({ payload }) {
  const { response, error } = yield call(services.cohort.deleteMember, payload);

  if (error) {
    yield put({
      type: UPDATE_COHORT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_COHORT_SUCCESS, payload: response }),
      put({ type: SET_GET_ONE_COHORT, payload: response }),
    ]);
  }
}

function* updateCohort({ payload }) {
  const { response, error } = yield call(services.cohort.update, payload);

  if (error) {
    yield put({
      type: UPDATE_COHORT_ERROR,
      payload: error.response?.data?.message,
    });
  } else {
    yield all([put({ type: UPDATE_COHORT_SUCCESS, payload: response })]);
  }
}

function* deleteCohort({ payload }) {
  const { response, error } = yield call(services.cohort.delete, payload);

  if (error) {
    yield put({
      type: DELETE_COHORT_ERROR,
      payload: error.response?.data?.message,
    });
  } else {
    yield all([put({ type: DELETE_COHORT_SUCCESS, payload: response })]);
  }
}

function* addview({ payload }) {
  const { response, error } = yield call(services.cohort.addView, payload);
  /*  if (error) {
  } else {
    const currentListOfCohorts = yield select(cohortsList);
    const newListofCohorts = currentListOfCohorts?.map((cohort) => {
      if (cohort._id === response._id) {
        return response;
      }
      return cohort;
    });
    yield put({
      type: UPDATE_CURRENT_COHORT_LIST,
      payload: newListofCohorts,
    });
  } */
}
function* addClick({ payload }) {
  const { response, error } = yield call(services.cohort.addClick, payload);
  /*  if (error) {
  } else {
    const currentListOfCohorts = yield select(cohortsList);
    const newListofCohorts = currentListOfCohorts?.map((cohort) => {
      if (cohort._id === response._id) {
        return response;
      }
      return cohort;
    });
    yield put({
      type: UPDATE_CURRENT_COHORT_LIST,
      payload: newListofCohorts,
    });
  } */
}
function* addRating({ payload }) {
  const { response, error } = yield call(services.cohort.addRating, payload);
  if (error) {
    yield put({
      type: UPDATE_COHORT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_COHORT_SUCCESS, payload: response });

    /*  yield put({
      type: UPDATE_CURRENT_COHORT_LIST,
      payload: newListofCohorts,
    }); */
    if (!!payload.individual) {
      yield put({ type: SET_GET_ONE_COHORT, payload: response });
    }
  }
}
function* manageLikeDislike({ payload }) {
  const { response, error } = yield call(
    services.cohort.manageLikeDislike,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_COHORT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_COHORT_SUCCESS, payload: response });

    /*   yield put({
      type: UPDATE_CURRENT_COHORT_LIST,
      payload: newListofCohorts,
    }); */
    if (!!payload.individual) {
      yield put({ type: SET_GET_ONE_COHORT, payload: response });
    }
  }
}

function* queryCohort({ payload }) {
  const { response, error } = yield call(services.cohort.query, payload);

  if (error) {
    yield put({
      type: QUERY_COHORT_ERROR,
      payload: error.response?.data?.message,
    });
  } else {
    yield put({ type: QUERY_COHORT_SUCCESS, payload: response });
  }
}

function* addMember({ payload }) {
  const { response, error } = yield call(services.cohort.addMember, payload);

  if (error) {
    yield put({
      type: UPDATE_COHORT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_COHORT_SUCCESS, payload: response }),
      put({ type: SET_GET_ONE_COHORT, payload: response }),
    ]);
  }
}

function* getCohortsOffset({ payload }) {
  const { response, error } = yield call(services.cohort.getOffset, payload);
  if (error) {
    yield put({
      type: GET_COHORTS_OFFSET_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const offsetCohorts = yield select(cohortsOffset);
    const newOffsetCohorts = !offsetCohorts
      ? response
      : [...offsetCohorts, ...response];
    yield put({
      type: GET_COHORTS_OFFSET_SUCCESS,
      payload: newOffsetCohorts,
    });
  }
}
/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllCohorts>(GET_ALL_COHORTS, getAllCohorts);
  yield takeLatest<GetCohort>(GET_COHORT, getCohort);
  yield takeLatest<NewCohort>(NEW_COHORT, newCohort);
  yield takeLatest<UpdateCohort>(UPDATE_COHORT, updateCohort);
  yield takeLatest<DeleteCohort>(DELETE_COHORT, deleteCohort);
  yield takeLatest<DeleteCohortMember>(DELETE_COHORT_MEMBER, deleteMember);
  yield takeLatest<AddViewCohort>(ADD_VIEW_COHORT, addview);
  yield takeLatest<AddClickCohort>(ADD_CLICK_COHORT, addClick);
  yield takeLatest<AddCohortMember>(ADD_COHORT_MEMBER, addMember);
  yield takeLatest<QueryCohort>(QUERY_COHORT, queryCohort);
  yield takeLatest<AddRatingCohort>(ADD_RATING_COHORT, addRating);
  yield takeLatest<ManageLikDislikeCohort>(
    MANAGE_LIKE_DISLIKE_COHORT,
    manageLikeDislike
  );
  yield takeLatest<GetCohortsOffset>(GET_COHORTS_OFFSET, getCohortsOffset);
  yield takeLatest<GetCohortsByAcademy>(
    GET_COHORTS_BY_ACADEMY,
    GetCohortsByAcademies
  );
}
