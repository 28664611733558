import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";
import {
  CommentPicture,
  Comment,
  CommentUser,
  CommentContent,
  LikeDislikeBox,
  LikeDislikeText,
  ShowRepliesText,
  CommentLikeDislike,
  CommentLikeDislikeNumber,
} from "./style";
import { FlexContainer, Separator } from "../StyledComponents";
import { CommentDto } from "../../types/comment.dto";
import { Img } from "../Header/styles";
import { BiCommentDetail, BiMessageRoundedAdd } from "react-icons/bi";
import {
  AiFillClockCircle,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";
import { overThousandFixed } from "../../helpers/number-fixed";
import { Formik } from "formik";
import Input from "../Form/Input";
import { initialValues, schema } from "../../constants/form/comment/create";
import { connect, useDispatch } from "react-redux";
import commentActions from "../../store/actions/comment";
import { toast } from "react-toastify";
import { UserDto } from "../../types/user.dto";
import { StateDto } from "../../types/states.dto";
import Loader from "../Loader";
import InfinityScroll from "../InfinityScroll";
import { DateFromNow } from "../../helpers/format-date";
import ArrowLeft from "../../assets/icons/GrUndo.png";
import { HiPaperAirplane } from "react-icons/hi2";

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  comment,
  className,
  commentChilds,
  level,
  newCommentStates,
  commentReplies,
  section,
  itemId,
  user,
  isLoading,
  queueReplies,
  hasNoMoreReplies,
  alredyRepliesParent,
  newComment,
}: {
  comment: CommentDto;
  className: string;
  level: number;
  commentChilds: any;
  commentReplies: CommentDto[];
  section: string;
  itemId: string;
  newCommentStates?;
  user: UserDto;
  isLoading: boolean;
  queueReplies: string[];
  hasNoMoreReplies: string[];
  alredyRepliesParent: string[];
  newComment: CommentDto;
}) => {
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [openedReplies, setOpenedReplies] = useState<string[]>([]);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [page, setPage] = useState<number>(0);
  const [commentToReply, setCommentToReply] = useState<CommentDto>();
  const theme = useTheme();
  const inputRef: any = useRef(null);
  const dispatch = useDispatch();
  const indentStyle = {
    marginLeft: `${level * 42}px`, // Adjust the indentation width as needed
  };

  const toggleOpenedReply = (replyId) => {
    setOpenedReplies((prevOpenedReplies) => {
      if (prevOpenedReplies.includes(replyId)) {
        return prevOpenedReplies.filter((id) => id !== replyId);
      } else {
        return [...prevOpenedReplies, replyId];
      }
    });
    // Trigger the Redux-Saga action to load replies when opening a comment
    if (!alredyRepliesParent.includes(comment._id)) fetchData({ page: 0 });
  };

  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      _id: comment._id,
      /*   limit: 9,
      offset: 9 * page, */
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        replyTo: { $exists: true },
      },
    };
    const sortBy = "createdAt";

    const sortDirection = "desc";
    dispatch(commentActions.getReplies({ ...payload, sortBy, sortDirection }));
  };

  useEffect(() => {
    if (commentToReply && inputRef.current) {
      inputRef.current.focus();
    }
  }, [commentToReply, inputRef]);
  const toggleReplyForm = () => {
    setShowReplyForm(!showReplyForm);
    if (showReplyForm && inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (
      newCommentStates.success &&
      !!newComment.replyTo &&
      newComment.replyTo === comment._id
    ) {
      formActions?.resetForm();
      setShowReplyForm(false);
    }
  }, [newCommentStates, newComment]);

  const onSubmit = ({ values, actions }) => {
    dispatch(
      commentActions.create({
        ...values,
        [`${section}`]: itemId,
        replyTo: comment._id,
      })
    );
    setFormActions(actions);
  };
  return (
    <Comment style={indentStyle}>
      <FlexContainer align="center" gap="8px">
        <CommentPicture>
          <Img src={comment.createdBy.picture}></Img>
        </CommentPicture>
        <FlexContainer gap="4px" align="center">
          <CommentUser className={className}>
            {comment.createdBy.name}
          </CommentUser>
          <span>|</span>
          <FlexContainer gap="3px" align="center">
            <AiFillClockCircle fill="#6a6970"></AiFillClockCircle>
            <CommentUser className={className}>
              {DateFromNow(comment.createdAt)}
            </CommentUser>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <Separator size={4}></Separator>
      <CommentContent>{comment.text}</CommentContent>
      <Separator size={8}></Separator>
      <FlexContainer align="center" gap="5px">
        <CommentLikeDislike
          className={
            !!comment.likes.find((like) => like.user === user._id)
              ? "active"
              : ""
          }
          onClick={() => {
            if (comment.likes.find((like) => like.user === user._id)) {
              dispatch(
                commentActions.manageLikeDislike({
                  _id: comment._id,
                  condition: "like",
                  action: "remove",
                  individual: true,
                })
              );
            } else {
              dispatch(
                commentActions.manageLikeDislike({
                  _id: comment._id,
                  condition: "like",
                  action: "add",
                  individual: true,
                })
              );
            }
          }}
        >
          <FlexContainer gap="3px" align="center">
            <AiOutlineLike
              size={13}
              fill={
                comment.likes.find((like) => like.user === user._id)
                  ? "#fff"
                  : theme.colors.LightBlue
              }
            ></AiOutlineLike>
            <CommentLikeDislikeNumber>
              {overThousandFixed(comment.likes.length)}
            </CommentLikeDislikeNumber>
          </FlexContainer>
        </CommentLikeDislike>
        <CommentLikeDislike
          className={
            !!comment.dislikes.find((like) => like.user === user._id)
              ? "active"
              : ""
          }
          onClick={() => {
            if (comment.dislikes.find((like) => like.user === user._id)) {
              dispatch(
                commentActions.manageLikeDislike({
                  _id: comment._id,
                  condition: "dislike",
                  action: "remove",
                  individual: true,
                })
              );
            } else {
              dispatch(
                commentActions.manageLikeDislike({
                  _id: comment._id,
                  condition: "dislike",
                  action: "add",
                  individual: true,
                })
              );
            }
          }}
        >
          <FlexContainer gap="3px" align="center">
            <AiOutlineDislike
              fill={
                comment.dislikes.find((dislike) => dislike.user === user._id)
                  ? "#fff"
                  : theme.colors.LightBlue
              }
              size={13}
            ></AiOutlineDislike>
            {/*  <LikeDislikeText>
              {overThousandFixed(comment.dislikes.length)}
            </LikeDislikeText> */}
          </FlexContainer>
        </CommentLikeDislike>
        <FlexContainer gap="3px" align="center">
          <div
            onClick={() => {
              toggleReplyForm();
            }}
            style={{
              height: "13px",
              width: "13px",
              display: "flex",
              alignItems: "center",
              justifyItems: "center",
              cursor: "pointer",
            }}
          >
            <Img src={ArrowLeft} alt="answer-icon"></Img>
          </div>
          {(!alredyRepliesParent.includes(comment._id)
            ? comment.replyCount + commentChilds.length
            : commentChilds.length) > 0 && (
            <>
              <ShowRepliesText
                style={{ cursor: "pointer" }}
                onClick={() => toggleOpenedReply(comment._id)}
              >
                {openedReplies.includes(comment._id)
                  ? "Hide Replies"
                  : `View Replies (${
                      !alredyRepliesParent.includes(comment._id)
                        ? comment.replyCount + commentChilds.length
                        : commentChilds.length
                    })`}
              </ShowRepliesText>
              <Separator size={10}></Separator>
            </>
          )}
        </FlexContainer>
      </FlexContainer>

      <Separator size={10}></Separator>

      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize
        validationSchema={schema}
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <form
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id={`new-comment-${comment._id}`}
            >
              {showReplyForm && (
                <>
                  <FlexContainer align="center" gap="12px">
                    <Input
                      forwardRef={inputRef}
                      name="text"
                      error={errors["text"]}
                      touched={touched["text"]}
                      value={values["text"]}
                      type="text"
                      inputStyles={{
                        fontSize: "12px",
                        fontWeight: "500",
                        height: "29px",
                      }}
                      placeholder="Agregar comentario"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        marginBottom: 0,
                        skin: "gray",
                      }}
                    />
                    <button
                      style={{
                        all: "unset",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      type="submit"
                    >
                      <HiPaperAirplane
                        color={theme.colors.LightBlue}
                        fontSize={20}
                      ></HiPaperAirplane>
                    </button>
                  </FlexContainer>
                  <Separator size={15}></Separator>
                </>
              )}
            </form>
          );
        }}
      </Formik>
      {isLoading && <Loader color="LightBlue"></Loader>}
      {openedReplies.includes(comment._id) && (
        <FlexContainer
          direction="column"
          gap="10px"
          style={{ paddingBottom: "30px" }}
        >
          {commentChilds.map((child: any, hey) => {
            const commentChildrens = commentReplies?.filter(
              (com) => com.replyTo === child._id
            );
            return (
              <Component
                newComment={newComment}
                alredyRepliesParent={alredyRepliesParent}
                hasNoMoreReplies={hasNoMoreReplies}
                queueReplies={queueReplies}
                isLoading={queueReplies.includes(comment._id)}
                user={user}
                commentReplies={commentReplies}
                newCommentStates={newCommentStates}
                itemId={itemId}
                section={section}
                key={child._id}
                className="child"
                comment={child}
                commentChilds={commentChildrens}
                level={level + 1}
              ></Component>
            );
          })}
        </FlexContainer>
      )}
      <Separator size={10}></Separator>
    </Comment>
  );
};

const states = ({ commentStore, userStore }) => {
  const { states: newCommentStates, data: newComment } = commentStore.create;
  const { data: queueReplies } = commentStore.replies.states;
  const { data: user } = userStore;
  return {
    newCommentStates,
    user,
    queueReplies,
    newComment,
  };
};

export default connect(states)(Component);
