import styled from "styled-components";

export const Comment = styled("div")``;

export const CommentPicture = styled("div")`
  border-radius: 50%;
  width: 26px;
  height: 26px;
  overflow: hidden;
`;

export const ShowRepliesText = styled("h3")`
  font-size: 10px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.LightBlue};
`;

export const CommentUser = styled("h3")`
  color: #6a6970;
  &.parent {
    font-size: 14px;
  }
  &.child {
    font-size: 12px;
  }
`;

export const CommentContent = styled("p")`
  color: ${(props) => props.theme.colors.Night};
  &.parent {
    font-size: 12px;
  }
  &.child {
    font-size: 10px;
  }
`;

export const CommentLikeDislike = styled("div")`
  svg {
    transition: fill 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  cursor: pointer;
  &.active {
    svg {
      fill: #2496ed !important;
    }
  }
`;

export const CommentAnswerCount = styled("p")`
  font-size: 10px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.LightBlue};
`;

export const CommentLikeDislikeNumber = styled("h3")`
  font-size: 10px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.LightBlue};
  transition: color 0.3s ease-in-out;
`;

export const LikeDislikeBox = styled("div")`
  padding: 4px;
  cursor: pointer;
  border-radius: 5px;
  border: solid 1px #2496ed;
  svg {
    transition: fill 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  h3 {
    transition: color 0.3s ease-in-out;
  }
  &:not(.active):hover {
    svg {
      color: #fff;
      fill: #fff;
    }
    h3 {
      color: #fff;
    }
    background-color: #2496ed;
    color: #fff;
  }
  &.active {
    background-color: #2496ed;
    h3 {
      color: #fff;
    }
  }
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
`;

export const LikeDislikeText = styled("h3")`
  font-size: 10px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.LightBlue};
  transition: color 0.3s ease-in-out;
`;
