import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
#root {
  position: relative;
  z-index: 1;
}

#modal {
  position: relative;
  z-index: 2;
}
`;

export const Layout = styled("div")<{ sidebarCollapsed: boolean }>`
  padding: 44px 0px 0px 90px;
  height: 100vh;
  overflow-y: auto;
  transition: padding 0.3s ease-in-out;
  position: relative;
  ${(props) => props.isMobile && "padding:0px"}
`;

export const Locked = styled("div")`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #707070;
  opacity: 0.3;
`;

export const Content = styled("main")`
  transition: all 0.3s ease-in-out;
  padding: ${(props) => (props.isMobile ? "80px 0px 5px 0px" : "30px 20px")};
  max-height: calc(100vh - 55px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  ${(props) => (props.isMobile ? "padding:42px 0px 5px 0px;" : "")}

  @media (min-width: 1200px) {
  }
`;

export const Sidebar = styled("aside")<{ sidebarCollapsed: boolean; modal }>`
  ${(props) => console.log(props.sidebarCollapse, "diamante")}
  background-color: white;
  box-shadow: 0px 4px 6px rgba(36, 150, 237, 0.1);
  display: grid;
  max-width: ${({ sidebarCollapsed }) => (sidebarCollapsed ? "90px" : "260px")};
  overflow: ${({ sidebarCollapsed }) =>
    sidebarCollapsed ? "visible" : "hidden"};
  transition: max-width 0.3s ease-in-out;
  grid-template-rows: 95px 1fr;
  grid-row-gap: 23px;
  width: 260px;
  z-index: 7000;

  max-width: ${({ sidebarCollapsed }) => (sidebarCollapsed ? "90px" : "260px")};
  /* es lo que me hace que tenga scroll horizontal si esta colapsado, para replicarlo invertir el orden  */
  overflow-y: auto;
  overflow: ${({ sidebarCollapsed }) =>
    sidebarCollapsed ? "visible" : "hidden"};
  /*  */
  transition: max-width 0.3s ease-in-out;
  position: fixed;
  left: 0px;
  top: 44px;
  bottom: 0px;
`;

export const SidebarHeader = styled("div")`
  position: relative;
  width: 100%;
  max-width: ${({ sidebarCollapsed }) => (sidebarCollapsed ? "90px" : "260px")};
  transition: max-width 0.3s ease-in-out;
`;

export const SidebarBody = styled("div")`
  overflow: ${({ sidebarCollapsed }) =>
    sidebarCollapsed ? "visible" : "hidden"};
  overflow-y: ${({ sidebarCollapsed }) =>
    sidebarCollapsed ? "visible" : "auto"};
`;

export const GridStructure = styled("div")`
  display: grid;
  width: 90%;
  grid-template-columns: ${(props) =>
    `repeat(${props.type || "auto-fill"}, minmax(${props.width}, ${
      props.limitCard ? props.limitCard : "1fr"
    }))`};
  justify-content: center;
  max-width: ${(props) => props.maxWidth};
  margin: 0 auto;
  gap: 20px;
`;

export const GridHomeCard = styled("div")`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
  width: 100%;
  cursor: pointer;
  padding: ${(props) => (props.pd ? props.pd : "3px")};
  word-break: break-word;
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 0 4px 0 rgba(36, 150, 237, 0.25);
  }
`;

export const GridCardContent = styled("div")`
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: fit-content;
  cursor: pointer;
  padding: ${(props) => (!!props.pd ? props.pd : "3px")};
  word-break: break-word;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #051725;
  }
  &:hover * {
    color: ${(props) => props.theme.colors.White};
  }
  svg {
    transition: fill 0.3s ease-in-out;
  }
  &:hover svg {
    fill: ${(props) => props.theme.colors.White};
  }
`;

export const GridCard = styled("div")`
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: fit-content;
  cursor: pointer;
  padding: ${(props) => (!!props.pd ? props.pd : "3px")};
  word-break: break-word;
  &:hover::before {
    background-color: #2496ed;
  }
  &::before {
    border-radius: 10px;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.05;
    z-index: -1;
    background-color: transparent;
    transition: 0.3s background-color ease-in-out;
  }

  ${(props) => !!props.style && props.style}
`;

export const GridMdCard = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  word-break: break-word;
  padding: 0 0 2px;
  border-radius: 10px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  }
`;

export const GridCardImageContainer = styled("div")`
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  height: 150px;
  margin-bottom: 8px;
  @media (max-width: 768px) {
    height: 150px;
  }
`;

export const GridQuerySideImageContainer = styled("div")`
  position: relative;
  min-width: 100.8px;
  min-height: 68px;
  max-width: 100.8px;
  max-height: 68px;
  width: 100.8px;
  height: 68px;
  box-shadow: 0 0 4px 0 rgba(5, 23, 37, 0.15);
  overflow: hidden;
  border-radius: 10px;
`;

export const GridCardImage = styled("img")`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const GridCardSmallContainer = styled("div")`
  min-width: 36px;
  min-height: 36px;
  max-height: 36px;
  max-width: 36px;
  border-radius: 10px;
  overflow: hidden;
`;

export const GridCardTitle = styled(`h3`)`
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  overflow:hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Ajusta esto al número deseado de líneas */
  -webkit-box-orient: vertical;
text-overflow: ellipsis;
white-space: normal;
  color: ${(props) => props.theme.colors.Night};
 @media (max-width: 768px) {
     font-size: 13px;
  }
  ${(props) =>
    props.overflowLine ? `-webkit-line-clamp:${props.overflowLine};` : ""}
}
`;

export const GridCardTitleMobile = styled("h3")`
  font-size: 14px;
`;
export const GridCommentMobile = styled("h6")`
  font-size: 12px;
  display: inline-block;
`;

export const GridComment = styled("h6")`
  font-size: 12px;
  display: inline-block;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  color: #6a6970;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Ajusta esto al número deseado de líneas */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
`;

export const GridCardCenter = styled("div")`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const GridCardDescription = styled("p")`
  display: ${(props) => props.display || "block"};
  font-family: Lato;
  font-size: 12px;
  color: #6a6970;
`;

export const SidebarToggle = styled("button")<{ sidebarCollapsed: boolean }>`
  position: absolute;
  right: ${({ sidebarCollapsed }) => (sidebarCollapsed ? "25px" : "12px")};
  transition: right 0.3s ease-in-out;
  top: calc(50% + 4px);
  transform: translate(0%, -50%);
  border: 0;
  background: white;
  padding: 10px;
  cursor: pointer;
`;
