import styled from "styled-components";

export const Modal = styled("div")`
  min-height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  overflow: auto;
  pointer-events: auto;
`;

export const ModalLink = styled("a")`
  text-decoration: none;
  display: block;
`;

export const ModalInsideText = styled("h3")`
  color: #697482;
  font-size: 14px;
  font-weight: 500;
`;

export const InlineInput = styled("input")`
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
  padding: 1px 2px;
  &:focus {
    outline: 1px solid #1573e5;
    border-bottom: none;
  }
`;
