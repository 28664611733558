import React from "react";
import { HiArrowSmUp } from "react-icons/hi";
import { FlexContainer } from "../../../../../../components/StyledComponents";
import { ToolItemWrapper, ToolSideWrapper } from "./style";

const Component = ({ menu, setOpenDropDown, openDropDown, openValue }) => {
  const isClosed = openDropDown.includes(openValue);
  return (
    <ToolSideWrapper openDropDown={isClosed}>
      <FlexContainer direction="row" gap="10px">
        <ToolItemWrapper
          className={isClosed ? "dropActive active arrow" : "arrow"}
          onClick={() => {
            setOpenDropDown((state) =>
              isClosed
                ? state.filter((item) => item !== openValue)
                : [...state, openValue]
            );
          }}
        >
          <HiArrowSmUp size={17}></HiArrowSmUp>
        </ToolItemWrapper>
        {menu.map((item) => {
          return (
            <>
              {!item.onAvailable ||
              (!!item.onAvailable && !!item.onAvailable()) ? (
                item.activeFunction && !!item.activeFunction() ? (
                  !!item.IconActive ? (
                    <ToolItemWrapper
                      onClick={!!item.onClickActive && item.onClickActive}
                      className="active"
                    >
                      <item.IconActive size={17}></item.IconActive>
                    </ToolItemWrapper>
                  ) : (
                    <ToolItemWrapper
                      onClick={!!item.onClickActive && item.onClickActive}
                      className="active"
                    >
                      <item.Icon size={17}></item.Icon>
                    </ToolItemWrapper>
                  )
                ) : (
                  <ToolItemWrapper onClick={!!item.onClick && item.onClick}>
                    <item.Icon size={17}></item.Icon>
                  </ToolItemWrapper>
                )
              ) : (
                <ToolItemWrapper className="disavailable">
                  <item.Icon size={17}></item.Icon>
                </ToolItemWrapper>
              )}
            </>
          );
        })}
      </FlexContainer>
    </ToolSideWrapper>
  );
};

export default Component;
