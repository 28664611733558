import styled from "styled-components";

export const CommentWrapper = styled("div")`
  padding: 4px 4px 4px;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(106, 105, 112, 0.25);
`;

export const CommentWrapperTitle = styled("h4")`
  font-size: 12px;
  color: #051725;
`;

export const FakeInput = styled("div")`
  padding: 7px;
  border-radius: 10px;
  background-color: #f2f2f2;
  color: #6a6970;
  font-size: 12px;
`;
