import { CertificateDto } from "../../types/certificate.dto";
import { PaginationDto } from "../../types/pagination.dto";

export const GET_ALL_CERTIFICATES = "GET_ALL_CERTIFICATES";
export const GET_ALL_CERTIFICATES_ERROR = "GET_ALL_CERTIFICATES_ERROR";
export const GET_ALL_CERTIFICATES_SUCCESS = "GET_ALL_CERTIFICATES_SUCCESS";
export const GET_CERTIFICATE = "GET_CERTIFICATE";
export const GET_CERTIFICATE_ERROR = "GET_CERTIFICATE_ERROR";
export const GET_CERTIFICATE_SUCCESS = "GET_CERTIFICATE_SUCCESS";
export const NEW_CERTIFICATE = "NEW_CERTIFICATE";
export const NEW_CERTIFICATE_ERROR = "NEW_CERTIFICATE_ERROR";
export const NEW_CERTIFICATE_SUCCESS = "NEW_CERTIFICATE_SUCCESS";
export const RESET_NEW_CERTIFICATE = "RESET_NEW_CERTIFICATE";
export const UPDATE_CERTIFICATE = "UPDATE_CERTIFICATE";
export const UPDATE_CERTIFICATE_ERROR = "UPDATE_CERTIFICATE_ERROR";
export const UPDATE_CERTIFICATE_SUCCESS = "UPDATE_CERTIFICATE_SUCCESS";
export const RESET_UPDATE_CERTIFICATE = "RESET_UPDATE_CERTIFICATE";
export const DELETE_CERTIFICATE = "DELETE_CERTIFICATE";
export const DELETE_CERTIFICATE_ERROR = "DELETE_CERTIFICATE_ERROR";
export const DELETE_CERTIFICATE_SUCCESS = "DELETE_CERTIFICATE_SUCCESS";
export const RESET_DELETE_CERTIFICATE = "RESET_DELETE_CERTIFICATE";
export const SET_UPDATE_CERTIFICATE_DATA = "SET_UPDATE_CERTIFICATE_DATA";
export const SET_NEW_CERTIFICATE_DATA = "SET_NEW_CERTIFICATE_DATA";
export const UPDATE_CURRENT_CERTIFICATE_LIST =
  "UPDATE_CURRENT_CERTIFICATE_LIST";

export const SELECT_CERTIFICATE = "SELECT_CERTIFICATE";
export const UPDATE_CERTIFICATE_ROLE = "UPDATE_CERTIFICATE_ROLE";
export const DELETE_CERTIFICATE_MEMBER = "DELETE_CERTIFICATE_MEMBER";

export const RESET_GET_ALL_CERTIFICATE_DATA = "RESET_GET_ALL_CERTIFICATE_DATA";

export const GET_CERTIFICATES_BY_ACADEMY = "GET_CERTIFICATES_BY_ACADEMY";

export interface GetCertificatesByAcademy {
  type: typeof GET_CERTIFICATES_BY_ACADEMY;
  payload: PaginationDto & { _id: string };
}

export interface SelectCERTIFICATE {
  type: typeof SELECT_CERTIFICATE;
  payload: CertificateDto;
}

export interface ResetGetAllCertificateData {
  type: typeof RESET_GET_ALL_CERTIFICATE_DATA;
  payload: null;
}

export interface UpdateCurrentCERTIFICATEList {
  type: typeof UPDATE_CURRENT_CERTIFICATE_LIST;
  payload: CertificateDto[];
}

export interface GetAllCertificates {
  type: typeof GET_ALL_CERTIFICATES;
  payload: PaginationDto;
}

export interface GetAllCertificatesSuccess {
  type: typeof GET_ALL_CERTIFICATES_SUCCESS;
  payload: CertificateDto[];
}

export interface GetAllCertificatesError {
  type: typeof GET_ALL_CERTIFICATES_ERROR;
  payload: boolean | string;
}

export interface GetCertificate {
  type: typeof GET_CERTIFICATE;
  payload: { _id: string };
}

export interface GetCertificateSuccess {
  type: typeof GET_CERTIFICATE_SUCCESS;
  payload: CertificateDto;
}

export interface GetCertificateError {
  type: typeof GET_CERTIFICATE_ERROR;
  payload: boolean | string;
}

export interface NewCertificate {
  type: typeof NEW_CERTIFICATE;
  payload: CertificateDto;
}

export interface NewCertificateSuccess {
  type: typeof NEW_CERTIFICATE_SUCCESS;
  payload: CertificateDto;
}

export interface NewCertificateError {
  type: typeof NEW_CERTIFICATE_ERROR;
  payload: boolean | string;
}

export interface SetUpdateCertificateData {
  type: typeof SET_UPDATE_CERTIFICATE_DATA;
  payload: CertificateDto;
}

export interface SetNewCertificateData {
  type: typeof SET_NEW_CERTIFICATE_DATA;
  payload: CertificateDto;
}

export interface ResetNewCertificate {
  type: typeof RESET_NEW_CERTIFICATE;
  payload: null;
}

export interface UpdateCertificate {
  type: typeof UPDATE_CERTIFICATE;
  payload: CertificateDto;
}

export interface UpdateCertificateSuccess {
  type: typeof UPDATE_CERTIFICATE_SUCCESS;
  payload: CertificateDto;
}

export interface UpdateCertificateError {
  type: typeof UPDATE_CERTIFICATE_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateCertificate {
  type: typeof RESET_UPDATE_CERTIFICATE;
  payload: null;
}

export interface DeleteCertificate {
  type: typeof DELETE_CERTIFICATE;
  payload: { _id: string };
}

export interface DeleteCertificateSuccess {
  type: typeof DELETE_CERTIFICATE_SUCCESS;
  payload: null;
}

export interface DeleteCertificateError {
  type: typeof DELETE_CERTIFICATE_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteCertificate {
  type: typeof RESET_DELETE_CERTIFICATE;
  payload: null;
}

export interface DeleteCertificateMember {
  type: typeof DELETE_CERTIFICATE_MEMBER;
  payload: { _id: string; userId: string };
}

export interface State {
  all: {
    data: CertificateDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };

  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllCertificates
  | GetAllCertificatesSuccess
  | GetAllCertificatesError
  | GetCertificate
  | GetCertificateSuccess
  | GetCertificateError
  | NewCertificate
  | NewCertificateSuccess
  | NewCertificateError
  | ResetNewCertificate
  | UpdateCertificate
  | UpdateCertificateSuccess
  | UpdateCertificateError
  | ResetUpdateCertificate
  | DeleteCertificate
  | DeleteCertificateSuccess
  | DeleteCertificateError
  | ResetDeleteCertificate
  | ResetGetAllCertificateData
  | GetCertificatesByAcademy;
