import styled from "styled-components";

export const LogoSmallItem = styled("div")`
  ${(props) => {
    return `
    width:${props.width};
    height:${props.height};
    border-radius:10px;
    overflow:hidden;
    margin-bottom:8px;
    `;
  }}}
`;

export const TextSmallItem = styled("h3")`
  font-size: 14px;
  font-weight: 500;
  color: #2a2747;
`;

export const DescriptionSmallItem = styled("h5")`
  font-size: 10px;
  font-weight: 500;
`;

export const TitleSection = styled("h3")`
  font-size: 16px;
  font-weight: bold;
  color: #2a2747;
  margin-bottom: ${(props) => props.btm}px;
`;

export const ItemTitle = styled("h3")`
  font-size: 13px;
  font-weight: bold;
  color: #2a2747;
`;

export const ItemDataText = styled("h5")`
  font-size: 12px;
  color: #6a6970;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Ajusta esto al número deseado de líneas */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
`;
export const ListItem = styled("div")`
  padding: 2px 12px;
  border-radius: 5px;
  box-shadow: 0 0 4px 0 rgba(79, 38, 162, 0.16);
  transition: all 0.25s ease-in-out;
  &.active {
    .caret {
      transform: rotate(-180deg);
    }
  }
`;

export const ListItemLittleText = styled("h6")`
  font-size: 12px;
  font-weight: 300;
  color: #2a2747;
`;

export const NotificationBox = styled("div")`
  padding: 2px 5.5px;
  border-radius: 5px;
  background-color: #7137c7;
  color: #fff;
`;

export const DropdownMenu = styled("div")<{ amountItems; isActiveDropdown }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
  transition: height 0.3s ease-in-out, max-height 0.3s ease-in-out;
  //Dropdown
  opacity: ${({ isActiveDropdown }) => (isActiveDropdown ? 1 : 0)};
  height: ${({ amountItems }) => amountItems * 19 + 10}px;
  max-height: ${({ isActiveDropdown, amountItems }) =>
    isActiveDropdown ? amountItems * 19 + 10 : 0}px;
`;

export const DropdownItem = styled("div")`
  padding-left: 11px;
  color: #2a2747;
  font-size: 12px;
  font-weight: 300;
  align-self: flex-end;
`;
