import { useEffect, useState } from "react";
import { StudentDto } from "../../../../../../types/student.dto";
import { TalentDto } from "../../../../../../types/talent.dto";
import { connect, useDispatch } from "react-redux";
import { getTalents } from "../../../../../../store/actions/talent";
import {
  Avatar,
  CardCol,
  CardRow,
  Information,
  Name,
  Profile,
  Location,
  Card,
} from "../../styles";
import { getAllStudents } from "../../../../../../store/actions/student";
import { textComparation } from "../../../../../../helpers/text-comparation";
import { UsersDto } from "../../../../../../types/users.dto";
import ShortString from "../../../../../../components/ShortString";
import { UserDto } from "../../../../../../types/user.dto";
interface ComponentProps {
  userId?: string;
  talentsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  talents: TalentDto[];
  studentsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  students: StudentDto[];
  section?: string;
  users: UsersDto[];
  user: UserDto;
}

interface CurrentUser {
  _id?: string;
  userId: string;
  name: string;
  lastName: string;
  avatar?: string;
  country?: string;
  province?: string;
  english?: string;
  email?: string;
  portfolio?: string;
  linkedin?: string;
  curriculum?: string;
  comments?: string;
  user;
}

const Component = ({
  userId,
  user,
  section = "marketplace",
  users,
}: ComponentProps) => {
  const dispatch = useDispatch();

  return (
    <Card>
      <CardCol>
        <CardRow>
          <Avatar>
            <img src={user?.picture} alt="avatar" />
          </Avatar>
          <Information>
            <Name>
              {user?.name} {user?.lastName}
            </Name>
            <Profile title={user?.email}>
              {ShortString(user?.email, 22)}
            </Profile>
            <Location>Genero: {user?.gender ?? "Incompleto"}</Location>
          </Information>
        </CardRow>
      </CardCol>
    </Card>
  );
};

const states = ({ talentStore, studentStore, usersStore, userStore }) => {
  const { states: studentsStates, data: students } = studentStore.allStudents;
  const { states: talentsStates, data: talents } = talentStore.allTalents;
  const { data: users } = usersStore.all;
  const { data: user } = userStore;
  return {
    studentsStates,
    students,
    talentsStates,
    users,
    talents,
    user,
  };
};

export default connect(states)(Component);
