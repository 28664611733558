import Skeleton from "../../../../../../components/Skeleton";
import Grid from "../../../../../../components/Grid";
import { ListMediaTitle } from "./styles";
import { FlexContainer } from "../../../../../../components/StyledComponents";

const Component = () => {
  return (
    <FlexContainer direction="column" gap="13px">
      <Skeleton
        itemsPerPage={1}
        itemsPerRow={1}
        animated
        size={{ height: 500 }}
        icon="slide"
      />
      <Skeleton
        itemsPerPage={1}
        itemsPerRow={1}
        animated
        size={{ height: 195 }}
      />
      <Skeleton
        itemsPerPage={1}
        itemsPerRow={1}
        animated
        size={{ height: 340 }}
      />
    </FlexContainer>
  );
};

export default Component;
