import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Field, Formik } from "formik";
import { initialValues, schema } from "../../constants/form/auth/sign-up";
import { AuthContext } from "../../provider/AuthProvider";
import Input from "../../components/Form/Input";
import Submit from "../../components/Form/Submit";
import { resetSignUp, signUp, signUpSocial } from "../../store/actions/auth";
import { UserDto } from "../../types/user.dto";
import { toast, ToastContainer } from "react-toastify";
import { AuthWrapper, Img, Link, Logo, Title } from "../SignIn/styles";
import Grid from "../../components/Grid";
import { InLine } from "../../components/Form/Field";
import { useGoogleLogin } from "@react-oauth/google";
import {
  Center,
  FlexContainer,
  Separator,
} from "../../components/StyledComponents";
import {
  LoginSocialFacebook,
  LoginSocialInstagram,
} from "reactjs-social-login";
import caution from "../../assets/icons/caution.svg";
import FacebookButton from "../../components/FacebookButton";
import { FaFacebookSquare, FaGithub, FaLinkedin } from "react-icons/fa";
import SocialButton from "../../components/SocialButton";
import InstagramButton from "../../components/InstagramButton";
import { getGitHubUrl } from "../../helpers/githubUrl";
import { FcGoogle } from "react-icons/fc";
import { getLinkedinUrl } from "../../helpers/linkedinUrl";
import UserWindow from "../../hooks/useScreenSize";
import { GridStructure } from "../SignIn/styles";
import { CautionText, TermsLink } from "./style";
import ConstanaLogo from "../../assets/icons/constanalightblue.svg";
import Modal from "../../components/Modal";
import { useWindowSize } from "../../hooks/useWindowScreen";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { hideModal, showModal } from "../../store/actions/modal";
/* import { setCurrent } from "../../store/actions/current";
import userActions from "../../store/actions/users";
import { useCurrentLayout } from "../../provider/CurrentProvider"; */
interface CustomizedState {
  sigupStates: {
    loading: boolean;
    success: boolean;
    error: boolean;
  };
  from: {
    pathname: string;
    search: string;
    hash: string;
  };
}

interface ComponentProps {
  signupStates: {
    loading: boolean;
    success: boolean;
    error: boolean;
  };
  userStates: {
    loading: boolean;
    success: boolean;
    error: boolean;
  };
  user: UserDto;
  usersStore;
}

const Component = ({
  signupStates,
  user,
  userStates,
  usersStore,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  const { isMobile } = useWindowSize();
  let [searchParams, setSearchParams] = useSearchParams();
  let from = !(location.state as CustomizedState)?.from?.search
    ? (location.state as CustomizedState)?.from?.pathname || "/dashboard"
    : `${(location.state as CustomizedState)?.from?.pathname}/${
        (location.state as CustomizedState)?.from?.search
      }`;
  const signUpGoogle = useGoogleLogin({
    onSuccess: (codeResponse) =>
      dispatch(
        signUpSocial({ code: codeResponse.access_token, social: "google" })
      ),
  });
  const onSubmit = ({ values, actions }) => {
    dispatch(signUp({ ...values, platform: "CAMPUS" }));
    setFormActions(actions);
  };
  /*   useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard", { replace: true });
    }
    return () => {};
  }, [isAuthenticated]);
 */
  useEffect(() => {
    if (signupStates.success) {
      toast.success("Ahora debe confirmar su cuenta en el email");
      formActions?.resetForm();
      /* setTimeout(() => {
        dispatch(showModal("sign-in-content"));
      }, 1000); */
    }
  }, [signupStates]);

  return (
    <Modal
      padding="27px"
      width={isMobile ? `315px` : `340px`}
      name="sign-up-content"
      style={{ wrapper: { minHeight: isMobile ? `min-content` : "611px" } }}
    >
      <Center>
        <Logo>
          <div style={{ width: "160px", height: "26px" }}>
            <Img src={ConstanaLogo} />
          </div>
        </Logo>
      </Center>
      <Title>Registrarse</Title>
      <CautionText>
        Al continuar, confirmas que estás de acuerdo con los
        <TermsLink
          rel="noreferrer"
          href={`${process.env.REACT_APP_URL}/terms`}
          target="_blank"
        >
          {`Términos de uso y Política de privacidad`}
        </TermsLink>
        de DevPlace
      </CautionText>
      <Separator size={14}></Separator>

      <GridStructure>
        <SocialButton
          color={"#2a2747"}
          background={"#fff"}
          active={"rgb(239, 240, 238)"}
          text={"Continuar con Google"}
          onClick={() => {
            signUpGoogle();
          }}
          icon={<FcGoogle size={20}></FcGoogle>}
        ></SocialButton>

        <FacebookLogin
          appId="1149804909391563"
          fields="name,email,picture,gender"
          scope="public_profile, email, user_gender"
          onSuccess={(response) => {
            dispatch(
              signUpSocial({
                social: "facebook",
                body: response.userID,
                code: response.accessToken,
              })
            );
          }}
          onFail={(error) => {
            toast.error(`Error al acceder`, {
              containerId: "container-sign-up",
            });
          }}
          /* onProfileSuccess={(response) => {
                console.log("Get Profile Success!", response);
              }} */
          render={({ onClick, logout }) => (
            <SocialButton
              color={"#2a2747"}
              background={"#fff"}
              active={"rgb(239, 240, 238)"}
              text={"Continuar con Facebook"}
              onClick={onClick}
              icon={
                <FaFacebookSquare size={20} color="#0866ff"></FaFacebookSquare>
              }
            ></SocialButton>
          )}
        />

        <SocialButton
          color={"#2a2747"}
          background={"#fff"}
          active={"rgb(7, 112, 169)"}
          text={"Continuar con Linkedin"}
          onClick={() => {
            window.location.href = getLinkedinUrl(from);
          }}
          icon={<FaLinkedin size={20} color={"#177FB1"}></FaLinkedin>}
        ></SocialButton>
      </GridStructure>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          textAlign: "center",
        }}
      >
        <div
          style={{
            flexGrow: "1",
            borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
          }}
        ></div>
        <div
          style={{
            width: "49px",
            textAlign: "center",
            fontSize: "12px",
            fontWeight: "500",
          }}
        >
          ó
        </div>
        <div
          style={{
            flexGrow: "1",
            borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
          }}
        ></div>
      </div>
      <Separator size={14}></Separator>
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="sign-up-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <InLine column="1fr 1fr">
                    <>{console.log(errors, "macarem")}</>
                    <Input
                      name="name"
                      error={errors["name"]}
                      touched={touched["name"]}
                      type="text"
                      fieldStyles={{ height: "29.5px", borderRadius: "10px" }}
                      inputStyles={{
                        padding: "7.9px 9px 7.9px 9px",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                      placeholder="Nombre"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={signupStates.loading}
                      options={{
                        marginBottom: 11,
                        skin: "gray",
                      }}
                    />
                    <Input
                      name="lastName"
                      error={errors["lastName"]}
                      touched={touched["lastName"]}
                      type="text"
                      placeholder="Apellido"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={signupStates.loading}
                      fieldStyles={{ height: "29.5px", borderRadius: "10px" }}
                      inputStyles={{
                        padding: "7.9px 9px 7.9px 9px",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                      options={{
                        marginBottom: 11,
                        skin: "gray",
                      }}
                    />
                  </InLine>
                </Grid.Col>
              </Grid.Row>

              <Input
                name="username"
                error={errors["username"]}
                touched={touched["username"]}
                type="text"
                placeholder="Nombre de usuario"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={signupStates.loading}
                fieldStyles={{ height: "29.5px", borderRadius: "10px" }}
                inputStyles={{
                  padding: "7.9px 9px 7.9px 9px",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                options={{
                  marginBottom: 11,
                  skin: "gray",
                }}
              />
              <Input
                name="email"
                error={errors["email"]}
                touched={touched["email"]}
                type="text"
                placeholder="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={signupStates.loading}
                fieldStyles={{ height: "29.5px", borderRadius: "10px" }}
                inputStyles={{
                  padding: "7.9px 9px 7.9px 9px",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                options={{
                  marginBottom: 11,

                  skin: "gray",
                }}
              />

              <Input
                name="password"
                error={errors["password"]}
                touched={touched["password"]}
                type="password"
                placeholder="Contraseña"
                onChange={handleChange}
                onBlur={handleBlur}
                fieldStyles={{ height: "29.5px", borderRadius: "10px" }}
                inputStyles={{
                  padding: "7.9px 9px 7.9px 9px",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                disabled={signupStates.loading}
                options={{
                  marginBottom: 11,

                  skin: "gray",
                }}
              />

              <Input
                name="confirm-password"
                error={errors["confirm-password"]}
                touched={touched["confirm-password"]}
                type="password"
                placeholder="Confirmar Contraseña"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={signupStates.loading}
                fieldStyles={{ height: "29.5px", borderRadius: "10px" }}
                inputStyles={{
                  padding: "7.9px 9px 7.9px 9px",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                options={{
                  marginBottom: 20,

                  skin: "gray",
                }}
              />

              <Submit
                isSubmmiting={signupStates.loading}
                form="sign-up-form"
                type="submit"
                color="Primary"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  height: "29px",
                  borderRadius: "10px",
                }}
                options={{
                  block: true,
                  type: "filled",
                  skin: "lightblue",
                  loading: "LightBlue",
                  size: "sm",
                  marginBottom: "26px",
                }}
              >
                Crear Cuenta
              </Submit>
              <Center>
                <Link
                  style={{
                    fontSize: "10px",
                    fontWeight: "600",
                  }}
                  onClick={() => dispatch(showModal("sign-in-content"))}
                >
                  Ingresar
                </Link>
              </Center>
            </form>
          );
        }}
      </Formik>
      <ToastContainer
        containerId="container-sign-up"
        enableMultiContainer
        limit={1}
      ></ToastContainer>
    </Modal>
  );
};

const state = ({ authStore, userStore, usersStore }) => {
  const { data: user, states: userStates } = userStore;
  const { states: signupStates } = authStore.signup;

  return {
    user,
    signupStates,
    userStates,
    usersStore,
  };
};

export default connect(state)(Component);
