import React, { useEffect } from "react";
import { Filter } from "../..";
import { Button } from "./styles";
import ToggleSlider from "../../../../../components/ToggleSlider";

const availableFilters: {
  name: string;
  value: string;
}[] = [
  {
    name: "Todos",
    value: "all",
  },
  {
    name: "Academias",
    value: "academies",
  },
  {
    name: "Videos",
    value: "videos",
  },
  {
    name: "SlideShows",
    value: "slideshows",
  },
  {
    name: "Imagenes",
    value: "images",
  },
];

interface ComponentProps {
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
  appliedFilters: Filter;
}

const Component = ({ setFilter, appliedFilters }: ComponentProps) => {
  useEffect(() => {
    setFilter({ ...appliedFilters, type: "all" });
  }, []);

  return (
    <ToggleSlider>
      {availableFilters.map((availableFilter) => (
        <Button
          key={"home-filter-tab-" + availableFilter.value}
          type="button"
          onClick={() =>
            setFilter({ ...appliedFilters, type: availableFilter.value })
          }
          options={{
            size: "md",
            type: "filled",
            skin: "gray",
          }}
          minwidth="0px"
          active={appliedFilters.type === availableFilter.value}
        >
          {availableFilter.name}
        </Button>
      ))}
    </ToggleSlider>
  );
};

export default Component;
