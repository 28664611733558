export const grid = {
  columns: 12,
  gutter: 10,
  margin: 20,
  maxWidth: {
    md: "900px",
    lg: "1024px",
    xl: "1440px",
    sm: "100%",
  },
};
