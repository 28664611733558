import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addClickVideo,
  getAllVideos,
} from "../../../../../store/actions/video";
import { getCookie } from "../../../../../helpers/cookies-handler";
import MediaItems from "../MediaItems";
import { PaginationDto } from "../../../../../types/pagination.dto";
import services from "../../../../../services";
import { VideoDto } from "../../../../../types/video.dto";

import Skeleton from "../../../../../components/Skeleton";
import { ListMediaTitle } from "./styles";
import { UserDto } from "../../../../../types/user.dto";
import { addClick } from "../../../../../store/actions/userTimes";
import { secondsToHHMMSS } from "../../../../../helpers/format-date";
import { BsFillPlayFill } from "react-icons/bs";

interface FetchData {
  _id: string;
  filterBy?: { [key: string]: string | number | boolean };
}

interface ComponentProps {
  user: UserDto;
}

const Component = ({ user }: ComponentProps) => {
  const [visitedElement, setVisitedElement] = useState<VideoDto>();
  const [elements, setElements] = useState<VideoDto[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = async ({ _id, filterBy }: FetchData) => {
    let payload: PaginationDto = {
      limit: 3,
      offset: 0,
      sortBy: "createdAt",
      sortDirection: "desc",
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        available: true,
        published: true,
      },
      select: {
        users: 0,
        clicks: 0,
        views: 0,
      },
    };

    let responses: VideoDto[] = [];

    const { response: responseOne } = await services.video.getOne({ _id });

    if (!!responseOne) {
      responses = [responseOne];
    }

    const { response: responseTwo } = await services.video.getAll(payload);

    if (!!responseTwo) {
      responses = [...responses, ...responseTwo];
    }

    responses = responses.reduce((unique: VideoDto[], response: VideoDto) => {
      if (
        !unique.some((item) => item._id === response._id || !item.available)
      ) {
        unique.push(response);
      }
      return unique;
    }, []);

    setElements(responses.slice(0, 3));
  };

  useEffect(() => {
    const cookieName = "DEVPLACE_VISITED_VIDEO";
    const cookie = getCookie(cookieName);

    if (cookie) {
      const visitedElement = JSON.parse(cookie);
      if (visitedElement?._id) {
        fetchData({ _id: visitedElement._id });
        setVisitedElement(visitedElement);
      }
    }
  }, []);

  if (!visitedElement) {
    return <></>;
  }

  if (!!visitedElement && elements.length <= 0) {
    return (
      <>
        <ListMediaTitle>{`Porque viste el video "${visitedElement?.name}"`}</ListMediaTitle>
        <Skeleton
          itemsPerPage={3}
          itemsPerRow={3}
          animated
          size={{ height: 290, width: 276 }}
          icon="video"
        />
        <div style={{ height: "35px" }} />
      </>
    );
  }

  return (
    <>
      <MediaItems
        style={{ margin: "0 auto" }}
        size="lg"
        items={elements.map((element) => {
          return {
            ...element,
            icon: BsFillPlayFill,
            ...(element.duration && {
              bottomLabel: secondsToHHMMSS(element.duration),
            }),
          };
        })}
        title={`Por que viste el video "${visitedElement?.name}"`}
        onClick={(event, item) => {
          dispatch(addClick({ id: item._id, user: user._id, type: "CONTENT" }));
        }}
      />

      <div style={{ height: "35px" }} />
    </>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;
  return {
    user,
  };
};

export default connect(states)(Component);
