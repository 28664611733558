import styled from "styled-components";

export const CountNumber = styled("h3")`
  margin: 0;
  padding: 0;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
`;

export const DateString = styled("h3")`
  font-family: Lato;
  font-size: 12px;
  color: #6a6970;
`;

export const TrashIcon = styled("div")`
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 2px;
`;

export const Icon = styled("div")`
  display: block;
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(36, 150, 237, 0.25);
  overflow: hidden;
  ${(props) => !!props.margin && `margin:${props.margin};`}
`;

export const Link = styled("a")`
  text-decoration: none;
  display: block;
`;
