import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  GET_STUDENT,
  GET_STUDENT_SUCCESS,
  GET_STUDENT_ERROR,
  GET_ALL_STUDENTS,
  GET_ALL_STUDENTS_SUCCESS,
  GET_ALL_STUDENTS_ERROR,
  NEW_STUDENT,
  NEW_STUDENT_ERROR,
  NEW_STUDENT_SUCCESS,
  UPDATE_STUDENT,
  UPDATE_STUDENT_SUCCESS,
  UPDATE_STUDENT_ERROR,
  DELETE_STUDENT,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_ERROR,
  GetStudent,
  GetAllStudents,
  NewStudent,
  UpdateStudent,
  DeleteStudent,
} from "../types/student";

function* getAllStudents() {
  const { response, error } = yield call(services.student.getAll);

  if (error) {
    yield put({
      type: GET_ALL_STUDENTS_ERROR,
      payload: error,
    });
  } else {
    yield put({ type: GET_ALL_STUDENTS_SUCCESS, payload: response });
  }
}

function* getStudent({ payload }) {
  const { response, error } = yield call(services.student.getOne, payload);

  if (error) {
    yield put({
      type: GET_STUDENT_ERROR,
      payload: error,
    });
  } else {
    yield put({ type: GET_STUDENT_SUCCESS, payload: response });
  }
}

function* newStudent({ payload }) {
  const { response, error } = yield call(services.student.new, payload);

  if (error) {
    yield put({
      type: NEW_STUDENT_ERROR,
      payload: error,
    });
  } else {
    yield all([
      put({ type: NEW_STUDENT_SUCCESS, payload: response }),
      put({ type: GET_ALL_STUDENTS, payload: null }),
    ]);
  }
}

function* updateStudent({ payload }) {
  const { response, error } = yield call(services.student.update, payload);

  if (error) {
    yield put({
      type: UPDATE_STUDENT_ERROR,
      payload: error,
    });
  } else {
    yield all([
      put({ type: UPDATE_STUDENT_SUCCESS, payload: response }),
      put({ type: GET_ALL_STUDENTS, payload: null }),
    ]);
  }
}

function* deleteStudent({ payload }) {
  const { response, error } = yield call(services.student.delete, payload);

  if (error) {
    yield put({
      type: DELETE_STUDENT_ERROR,
      payload: error,
    });
  } else {
    yield all([
      put({ type: DELETE_STUDENT_SUCCESS, payload: response }),
      put({ type: GET_ALL_STUDENTS, payload: null }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllStudents>(GET_ALL_STUDENTS, getAllStudents);
  yield takeLatest<GetStudent>(GET_STUDENT, getStudent);
  yield takeLatest<NewStudent>(NEW_STUDENT, newStudent);
  yield takeLatest<UpdateStudent>(UPDATE_STUDENT, updateStudent);
  yield takeLatest<DeleteStudent>(DELETE_STUDENT, deleteStudent);
}
