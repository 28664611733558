import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import services from "../../services";

import { NotificationDto } from "../../types/notification.dto";

interface NotificationContextType {
  unSeenNotifications: NotificationDto[];
}

interface NotificationProviderType {
  children: React.ReactNode;
}

export let NotificationContext = React.createContext<NotificationContextType>({
  unSeenNotifications: [],
});

const NotificationProvider = ({ children }: NotificationProviderType) => {
  const [currentUnseenNotifications, setCurrentUnseenNotifications] = useState<
    NotificationDto[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      const response: any = await services.notification.getAll({
        filterBy: { seen: false },
      });
      if (response.response) {
        setCurrentUnseenNotifications(response.response);
      }
    };
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 50000);
    return () => clearInterval(interval);
  }, []);
  return (
    <NotificationContext.Provider
      value={{ unSeenNotifications: currentUnseenNotifications }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
