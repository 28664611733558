import * as Yup from "yup";

export const schema = Yup.object().shape({
  text: Yup.string()
    .min(1, "Al menos un caracteres")
    .required("Se requiere mensaje"),
});

interface FormValues {
  text: string;
}

export const initialValues = (intial): FormValues => {
  return {
    text: "",
    ...intial,
  };
};
