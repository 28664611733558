import { Formik } from "formik";
import { AiOutlineSearch } from "react-icons/ai";
import { Input } from "../../../../../../../components/StyledComponents";
interface ComponentProps {
  setFilter;
  searchQuery;
  value;
}

const Component = ({ setFilter, searchQuery, value }: ComponentProps) => {
  return (
    <div
      style={{
        width: "calc(100% - 10px)",
        margin: "0 auto 10px auto",
      }}
    >
      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => {}}
        enableReinitialize
      >
        {({ handleSubmit }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                handleSubmit(event);
              }}
              id="userid-filter-form"
            >
              <div
                style={{
                  position: "relative",
                  boxShadow: "0 0 6px 0 rgba(36, 150, 237, 0.25)",
                  borderRadius: "10px",
                  overflow: "hidden",
                  flexGrow: 1,
                }}
              >
                <AiOutlineSearch
                  color="black"
                  fontWeight={900}
                  size={20}
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "12.5px",
                    zIndex: 2,
                  }}
                ></AiOutlineSearch>
                <Input
                  name="userId"
                  placeholder="Busca por nombre"
                  style={{ height: "45px" }}
                  onChange={
                    setFilter
                    /* setFilter({
                      field: "name",
                      value: ev.target.value !== "" ? ev.target.value : "no",
                      special: (academy, value) => {
                        return academy.name
                          .toLowerCase()
                          .includes(value.toLowerCase());
                      },
                    }); */
                  }
                  onKeyDown={searchQuery}
                  value={!value ? "" : value}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Component;
