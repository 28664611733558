import styled from "styled-components";

export const Tabs = styled("div")`
  ${(props) => !!props.style && props.style}
`;
export const Header = styled("div")`
  display: flex;
  justify-content: center;

  gap: 12px;
  align-center: center;
`;
export const Body = styled("div")`
  padding: ${(props) => (props.isMobile ? " 8px 5px" : "10px 0 10px 0")};
  background: transparent;
`;

export const Tab = styled("div")``;

export const Toggle = styled("button")`
  border: 0px;
  padding: 8px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  background-color: transparent;
  margin-right: 5px;
  cursor: pointer;
  box-shadow: ${({ active }) =>
    active
      ? "0 0 6px 0 rgba(123, 104, 239, 0.16)"
      : "0 0 4px 0 rgba(36, 150, 237, 0.25)"};
  font-size: 14px;
  color: ${({ active, theme }) => {
    return active ? theme.colors.LightBlue : theme.colors.Night;
  }};
  /* &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 2px;
    background-color: ${({ active, theme }) =>
    active ? theme.colors.LightBlue : theme.colors.Night};
    transition: all 0.3s ease-in-out;
    border-radius: 1px;
  } */

  /*  &:hover {
    &:after {
      background-color: ${(props) => props.theme.colors.LightBlueHover};
    }
  } */
`;

export const Content = styled("div")<{ active }>`
  display: ${({ active }) => (active ? "block" : "none")};
`;
