import {
  ADD_CLICK_CDOC,
  GET_CDOCS_OFFSET,
  GetCdocsOffset,
  MANAGE_LIKE_DISLIKE_CDOC,
  RESET_GET_ALL_CDOC_DATA,
  RESET_GET_CDOCS_OFFSET,
  RESET_GET_ONE_CDOC_DATA,
  ResetGetCdocsOffset,
  SET_GET_CDOC,
  UPDATE_ITEM_LIST_CDOC,
} from "../types/cdoc";
import {
  GetAllCDOCs,
  GET_ALL_CDOCS,
  GetCDOC,
  GET_CDOC,
  UpdateCDOC,
  UPDATE_CDOC,
  DeleteCDOC,
  DELETE_CDOC,
  GET_CDOC_SUCCESS,
  GetCDOCSuccess,
  NewCDOC,
  NEW_CDOC,
  ResetNewCDOC,
  RESET_NEW_CDOC,
  ResetDeleteCDOC,
  RESET_DELETE_CDOC,
  ResetUpdateCDOC,
  RESET_UPDATE_CDOC,
  ADD_USER_CDOC,
  SAVE_CDOC,
  SHARED_CDOC,
  UNSAVED_CDOC,
  SAVED_CDOC,
  QUERY_CDOC,
  AddViewCdoc,
  ADD_VIEW_CDOC,
  AddClickCdoc,
} from "../types/cdoc";
import { UPDATE_ITEM_LIST_VIDEO } from "../types/video";

export const getAllCDOCs = (payload): GetAllCDOCs => {
  return { type: GET_ALL_CDOCS, payload };
};

export const getCDOCs = (_id): GetCDOC => {
  return { type: GET_CDOC, payload: { _id } };
};

export const setSelectedCDOCs = (payload): GetCDOCSuccess => {
  return { type: GET_CDOC_SUCCESS, payload };
};

export const shareCdoc = (payload) => {
  return { type: SHARED_CDOC, payload };
};

export const updateCdocListItem = (payload) => {
  return { type: UPDATE_ITEM_LIST_CDOC, payload };
};

export const updateCDOCs = (payload): UpdateCDOC => {
  return { type: UPDATE_CDOC, payload };
};

export const offSetCdocs = (payload): GetCdocsOffset => {
  return { type: GET_CDOCS_OFFSET, payload };
};

export const resetOffsetCdocs = (): ResetGetCdocsOffset => {
  return { type: RESET_GET_CDOCS_OFFSET, payload: null };
};

export const resetGetAllCdocs = () => {
  return { type: RESET_GET_ALL_CDOC_DATA, payload: null };
};

export const resetGetOneCdoc = () => {
  return { type: RESET_GET_ONE_CDOC_DATA, payload: null };
};

export const addUserToCdoc = (payload) => {
  return { type: ADD_USER_CDOC, payload };
};

export const unSavedCdoc = (payload) => {
  return { type: UNSAVED_CDOC, payload };
};
export const savedCdoc = (payload) => {
  return { type: SAVED_CDOC, payload };
};

/* export const  */

export const queryCdoc = (payload) => {
  return { type: QUERY_CDOC, payload };
};

/* export const saveCdoc = (payload) => {
  return { type: SAVE_CDOC, payload };
}; */

export const resetUpdateCDOCs = (): ResetUpdateCDOC => {
  return { type: RESET_UPDATE_CDOC, payload: null };
};

export const newCDOCs = (payload): NewCDOC => {
  return { type: NEW_CDOC, payload };
};

export const resetNewCDOCs = (): ResetNewCDOC => {
  return { type: RESET_NEW_CDOC, payload: null };
};

export const deleteCDOCs = (id): DeleteCDOC => {
  return { type: DELETE_CDOC, payload: id };
};
export const manageLikeDislikeCdoc = (payload) => {
  return { type: MANAGE_LIKE_DISLIKE_CDOC, payload };
};

export const resetDeleteCDOCs = (): ResetDeleteCDOC => {
  return { type: RESET_DELETE_CDOC, payload: null };
};

export const addViewCdoc = (payload): AddViewCdoc => {
  return { type: ADD_VIEW_CDOC, payload };
};

export const addClickCdoc = (payload): AddClickCdoc => {
  return { type: ADD_CLICK_CDOC, payload };
};

export const setGetCdoc = (payload) => {
  return { type: SET_GET_CDOC, payload };
};
