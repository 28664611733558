import { colors } from "./../globals/colors";

export const form = {
  skin: {
    base: {
      idle: {
        color: colors.Gray,
        placeHolderColor: colors.Gray,
        backgroundColor: "#fff",
        borderColor: "#fff",
        iconColor: colors.Violet,
      },
      disabled: {
        color: colors.Gray,
        placeHolderColor: colors.Black,
        backgroundColor: "#c8c8c8",
        borderColor: "#c8c8c8",
        iconColor: colors.Violet,
      },
      active: {
        color: colors.Gray,
        placeHolderColor: colors.Primary,
        backgroundColor: "#f2f2f2",
        borderColor: colors.LightBlue,
        iconColor: colors.Violet,
      },
      hover: {
        color: colors.Gray,
        placeHolderColor: colors.Black,
        backgroundColor: "#f2f2f2",
        borderColor: "#c2bfbf",
        iconColor: colors.Violet,
      },
      focus: {
        color: colors.Gray,
        placeHolderColor: colors.Primary,
        backgroundColor: "#f2f2f2",
        borderColor: colors.LightBlue,
        iconColor: colors.Violet,
      },
      error: {
        color: colors.Danger,
        placeHolderColor: colors.Danger,
        backgroundColor: "#f2f2f2",
        borderColor: colors.Danger,
        iconColor: colors.Danger,
      },
    },
    gray: {
      idle: {
        color: "#6a6970",
        placeHolderColor: colors.Gray,
        backgroundColor: "#f2f2f2",
        borderColor: "#f2f2f2",
        iconColor: colors.Gray,
      },
      disabled: {
        color: "#6a6970",
        placeHolderColor: colors.Black,
        backgroundColor: "#c8c8c8",
        borderColor: "#c8c8c8",
        iconColor: colors.Gray,
      },
      active: {
        color: "#6a6970",
        placeHolderColor: colors.Primary,
        backgroundColor: "#fff",
        borderColor: colors.LightBlue,
        iconColor: colors.Primary,
      },
      hover: {
        color: "#6a6970",
        placeHolderColor: colors.Black,
        backgroundColor: "#f2f2f2",
        borderColor: "#c2bfbf",
        iconColor: colors.Gray,
      },
      focus: {
        color: "#6a6970",
        placeHolderColor: colors.Primary,
        backgroundColor: "#fff",
        borderColor: colors.LightBlue,
        iconColor: colors.Primary,
      },
      error: {
        color: colors.Danger,
        placeHolderColor: colors.Danger,
        backgroundColor: "#f2f2f2",
        borderColor: colors.Danger,
        iconColor: colors.Danger,
      },
    },
    blur: {
      idle: {
        color: "#fff",
        placeHolderColor: "#fff",
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        borderColor: "#fff",
        iconColor: colors.Gray,
      },
      disabled: {
        color: colors.Gray,
        placeHolderColor: "#fff",
        backgroundColor: "#c8c8c8",
        borderColor: "#c8c8c8",
        iconColor: colors.Gray,
      },
      active: {
        color: "#fff",
        placeHolderColor: "#fff",
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        borderColor: "#c2bfbf",
        iconColor: colors.Primary,
      },
      hover: {
        color: "#fff",
        placeHolderColor: "#fff",
        backgroundColor: "#fff",
        borderColor: "#c2bfbf",
        iconColor: colors.Gray,
      },
      focus: {
        color: "#fff",
        placeHolderColor: "#fff",
        backgroundColor: "#fff",
        borderColor: "#c2bfbf",
        iconColor: colors.Primary,
      },
      error: {
        color: colors.Danger,
        placeHolderColor: colors.Danger,
        backgroundColor: "#fff",
        borderColor: colors.Danger,
        iconColor: colors.Danger,
      },
    },
  },
  size: {
    md: {
      fontSize: "16px",
      lineHeight: "19px",
      mobileFontSize: "14px",
      mobileLineHeight: "17px",
      height: "40px",
      padding: "20px",
    },
  },
};
