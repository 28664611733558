import React from "react";
import { FullScreenWrapper } from "../../../../SlideShow/View/components/FullScreenSlideshow/style";
import { useWindowSize } from "../../../../../../hooks/useWindowScreen";
import { Img } from "../../../../../SignIn/styles";
import { FullScreenButton } from "../../../../../../components/SlideshowPlayer/styles";
import { BsFullscreen } from "react-icons/bs";
import { useTheme } from "styled-components";
const Component = ({ src, setOnFullScreen }) => {
  const { isMobile } = useWindowSize();
  const theme = useTheme();
  return (
    <FullScreenWrapper>
      <div
        style={{
          position: "relative",
          height: isMobile ? "calc(100vh - 70px)" : "calc(100vh - 17px)",
          maxWidth: "900px",
          margin: "0 auto",
        }}
      >
        <FullScreenButton
          onClick={() => {
            setOnFullScreen(false);
          }}
        >
          <BsFullscreen
            color={theme.colors.LightBlue}
            size={isMobile ? 16 : 32}
          ></BsFullscreen>
        </FullScreenButton>
        <Img style={{ objectFit: "contain" }} src={src}></Img>
      </div>
    </FullScreenWrapper>
  );
};

export default Component;
