import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Grid from "../../../../components/Grid";
import {
  GridCard,
  GridCardImage,
  GridCardImageContainer,
  GridCardSmallContainer,
  GridCardTitle,
  GridComment,
  GridStructure,
} from "../../../../components/Layout/Dashboard/styles";
import Loader from "../../../../components/Loader";
import {
  Center,
  FlexContainer,
  GridSwitchStructure,
  PageTitle,
  Separator,
} from "../../../../components/StyledComponents";
import { DateFromNow } from "../../../../helpers/format-date";

import {
  getAllRoadmaps,
  queryRoadmap,
  resetNewRoadmap,
} from "../../../../store/actions/roadmap";

import academyActions, { addClick } from "../../../../store/actions/academies";

import { CohortDto } from "../../../../types/cohort.dto";
import { addClick as addClickRoadmap } from "../../../../store/actions/roadmap";
import { StateDto } from "../../../../types/states.dto";
import { RoadmapDto } from "../../../../types/roadmap.dto";
import FilterButton from "./components/FilterButton";
import FilterModal from "./components/FilterModal";
import { DotContent, GridButton, ItemDataText } from "./style";
import { AcademyDto } from "../../../../types/academy.dto";
import { UserDto } from "../../../../types/user.dto";
import FilterByName from "./components/FilterByName";
import { useNavigate } from "react-router-dom";
import { SuscriptionDto } from "../../../../types/suscription.dto";
import suscriptionActions from "../../../../store/actions/suscription";
import CenterTitle from "../../../../components/CenterTitle";
import { BsFillCreditCardFill, BsGrid } from "react-icons/bs";
import { AiOutlineUnorderedList } from "react-icons/ai";
import {
  SuscriptionCard,
  SuscriptionCardLabel,
  SuscriptionCardTitle,
} from "../style";
import { Img } from "../../../SignIn/styles";
import ChangeMethodModal from "./components/ChangeMethodModal";
import { showModal } from "../../../../store/actions/modal";
import ConfirmChangeMethodModal from "./components/ConfirmChangeMethodModal";
import StatusSuscriptionModal from "./components/StatusSuscriptionModal";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
import { RiErrorWarningFill } from "react-icons/ri";
import Button from "../../../../components/Button";
import AddCardModal from "../components/AddCardModal";
import { FaCreditCard } from "react-icons/fa";
import TransferModal from "../../../../components/TransferModal";
import ToggleSlider from "../../../../components/ToggleSlider";
/* const filterValues: any = [
 {
    fieldName: ["visibility", "order"],
    value: "no",
    name: "Todos",
  },
  {
    fieldName: "order",
    value: "recent",
    name: "Recientes",
    groupal: (array: RoadmapDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "order",
    value: "older",
    name: "Antiguos",
    groupal: (array: RoadmapDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "published",
    value: true,
    name: "Publicos",
  },
  {
    fieldName: "published",
    value: false,
    name: "Privados",
  },
]; */

const Component = ({
  suscriptions,
  cohorts,
  academies,
  user,
  deleteSuscriptionStates,
}: {
  suscriptions: SuscriptionDto[];
  cohorts: CohortDto[];
  academies: AcademyDto[];
  user: UserDto;
  deleteSuscriptionStates: StateDto;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [grid, setGrid] = useState<boolean>(false);
  const [action, setAction] = useState<{
    action: string;
    data: any;
    modalName: string;
  }>();
  const filterValues: any = [
    {
      fieldName: "private",
      value: "no",
      name: "Todos",
    },
    {
      fieldName: "private",
      value: true,
      name: "Academias Privadas",
      special: (array, value, currentItem) => {
        return currentItem.academy?.private === value;
      },
    },
    {
      fieldName: "private",
      value: false,
      name: "Academias Publicas",
      special: (array, value, currentItem) => {
        return currentItem.academy?.private === value;
      },
    },
  ];
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [currentRoadmapsList, setCurrentRoadmapsList] = useState<any[]>([
    { paymentId: "asdasdasd", target: "visa" },
    { paymentId: "asdasdasdssss", target: "mastercard" },
  ]);
  const [
    filteredCurrentRoadmapsListValues,
    setFilteredCurrentRoadmapsListValues,
  ] = useState<SuscriptionDto[]>([
    { paymentId: "asdasdasd", target: "visa" },
    { paymentId: "asdasdasdssss", target: "mastercard" },
  ]);

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter((applyedFilter) =>
        !Array.isArray(newFilter.field)
          ? applyedFilter.field === newFilter.field
          : newFilter.field.includes(applyedFilter.field)
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    if (filterRemoved) {
      /**
       * Se elimina el filtro
       */
      if (Array.isArray(newFilter.field)) {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => !newFilter.field.includes(applyedFilter.field)
          ),
        ]);
      } else {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => applyedFilter.field !== newFilter.field
          ),
        ]);
      }
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  /*  useEffect(() => {
    if (!!suscriptions) {
      const filterRoadmaps = suscriptions.filter(
        (roadmap) =>
          !!roadmap.active && !!roadmap.available && !!roadmap.published
      );

      setCurrentRoadmapsList(filterRoadmaps);
    }

      setCurrentRoadmapsList(filterRoadmaps);
    }
  }, [suscriptions]); */

  useEffect(() => {
    const filteredTalents = currentRoadmapsList?.filter(
      (currentTalentValues) => {
        const thereAreFilters =
          applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
            .length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter(
            (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
          )
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value,
            currentTalentValues
          );
        });
        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentRoadmapsListValues(initResult);
  }, [applyedFilters, currentRoadmapsList]);

  useEffect(() => {
    if (!!action) {
      dispatch(showModal(action.modalName));
    }
  }, [action]);

  useEffect(() => {
    if (!suscriptions) dispatch(suscriptionActions.getAll());
  }, [suscriptions, dispatch]);
  useEffect(() => {
    if (!academies) dispatch(academyActions.getAll({}));
  }, [academies, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(academyActions.resetGetAllAcademies());
    };
  }, []);

  /*   if (!suscriptions) return <Loader color="LightBlue"></Loader>; */
  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <PageTitle>Suscripciones</PageTitle>
          </Grid.Col>
        </Grid.Row>
        {/*  <Grid.Row>
          <Grid.ContainerFluid>
            <Grid.Row
              style={{
                marginBottom: "20px",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Grid.Col lg={7} offset={{ lg: 2 }}>
                <FilterByName items={[]} setFilter={buildFilters} />
              </Grid.Col>
              <Grid.Col style={{ alignSelf: "center" }}>
                <FlexContainer gap="10px" justify="center">
                  <GridButton
                    active={grid === true}
                    onClick={() => {
                      setGrid(true);
                    }}
                  >
                    <BsGrid size={22}></BsGrid>
                  </GridButton>
                  <GridButton
                    active={grid === false}
                    onClick={() => {
                      setGrid(false);
                    }}
                  >
                    <AiOutlineUnorderedList size={22}></AiOutlineUnorderedList>
                  </GridButton>
                </FlexContainer>
              </Grid.Col>
              <Grid.Col>
                <Button
                  onClick={() => {
                    dispatch(showModal("new-payment-card"));
                  }}
                  type="button"
                  options={{
                    type: "filled",
                    skin: "violet",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                >
                  <FlexContainer align="center" gap="10px">
                    <span>Añadir Tarjeta</span>
                    <FaCreditCard size={20} color="#fff"></FaCreditCard>
                  </FlexContainer>
                </Button>
              </Grid.Col>
            </Grid.Row>
          </Grid.ContainerFluid>
        </Grid.Row> */}
        <Center>
          <Grid.Row style={{ width: "100%" }}>
            <Grid.Col lg={9} md={10} sm={12} style={{ marginBottom: "14px" }}>
              <FlexContainer
                style={{ width: "100%", maxWidth: "700px", margin: "0 auto" }}
                justify="end"
                align="center"
                gap="14px"
              >
                <FilterByName items={[]} setFilter={buildFilters} />
                <FlexContainer gap="10px" justify="center">
                  <GridButton
                    active={grid === true}
                    onClick={() => {
                      setGrid(true);
                    }}
                  >
                    <BsGrid size={22}></BsGrid>
                  </GridButton>
                  <GridButton
                    active={grid === false}
                    onClick={() => {
                      setGrid(false);
                    }}
                  >
                    <AiOutlineUnorderedList size={22}></AiOutlineUnorderedList>
                  </GridButton>
                </FlexContainer>
              </FlexContainer>
            </Grid.Col>
            <Grid.Col lg={3} md={2} sm={10} style={{ marginBottom: "14px" }}>
              <Button
                onClick={() => {
                  dispatch(showModal("new-payment-card"));
                }}
                type="button"
                options={{
                  type: "filled",
                  skin: "violet",
                  size: "lg",
                  marginBottom: "0px",
                }}
              >
                <FlexContainer align="center" gap="10px">
                  <span>Añadir Tarjeta</span>
                  <FaCreditCard size={20} color="#fff"></FaCreditCard>
                </FlexContainer>
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Center>
        <Separator size={15}></Separator>
        <ToggleSlider>
          {filterValues.map((filterValue) => {
            return (
              <FilterButton
                setFilter={buildFilters}
                name={filterValue.name}
                fieldName={filterValue.fieldName}
                value={filterValue.value}
                filters={applyedFilters}
                {...((!!filterValue.special || !!filterValue.groupal) && {
                  special: !!filterValue.special
                    ? { name: "special", function: filterValue.special }
                    : { name: "groupal", function: filterValue.groupal },
                })}
              ></FilterButton>
            );
          })}
        </ToggleSlider>
        <Separator size={12}></Separator>
        <Grid.Row>
          <Grid.Col>
            {filteredCurrentRoadmapsListValues.length === 0 ? (
              <CenterTitle title="No hay resultados"></CenterTitle>
            ) : (
              <GridSwitchStructure
                maxWidthLine={"350px"}
                width="350px"
                grid={grid}
                maxWidth="800px"
              >
                {filteredCurrentRoadmapsListValues.map((el) => {
                  return (
                    <SuscriptionCard>
                      <FlexContainer align="center" gap="25px" wrap="wrap">
                        <GridCardSmallContainer>
                          <Img src={el}></Img>
                        </GridCardSmallContainer>
                        <SuscriptionCardTitle>Titulo</SuscriptionCardTitle>
                        <FlexContainer align="center" gap="3px">
                          <DotContent></DotContent>
                          <FlexContainer gap="2px" align="center">
                            <SuscriptionCardLabel color="black">
                              Miembros:
                            </SuscriptionCardLabel>
                            <ItemDataText>23</ItemDataText>
                          </FlexContainer>

                          <SuscriptionCardLabel color="Gray"></SuscriptionCardLabel>
                        </FlexContainer>
                      </FlexContainer>
                      <FlexContainer direction="column" gap="12px">
                        <FlexContainer gap="10px" align="center">
                          <SuscriptionCardLabel color="black">
                            Tipo de Suscripcion:
                          </SuscriptionCardLabel>
                          <ItemDataText></ItemDataText>
                        </FlexContainer>
                        <FlexContainer gap="10px" align="center">
                          <SuscriptionCardLabel color="black">
                            Suscripcion:
                          </SuscriptionCardLabel>
                          <ItemDataText></ItemDataText>
                        </FlexContainer>
                        <FlexContainer gap="10px" align="center">
                          <SuscriptionCardLabel color="black">
                            Proximo Vencimiento:
                          </SuscriptionCardLabel>
                          <ItemDataText></ItemDataText>
                        </FlexContainer>
                        <FlexContainer gap="10px" align="center">
                          <SuscriptionCardLabel color="black">
                            Metodo de Pago utilizado:
                          </SuscriptionCardLabel>
                          <ItemDataText></ItemDataText>
                        </FlexContainer>
                      </FlexContainer>
                      <Separator size={48}></Separator>
                      <FlexContainer
                        direction="column"
                        gap="12px"
                        style={{ alignItems: "flex-start" }}
                      >
                        <SuscriptionCardLabel
                          color="LightBlue"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setAction({
                              action: "change-purchase-method",
                              modalName: "change-purchase-method",
                              data: {},
                            });
                          }}
                        >
                          Cambiar metodo de pago
                        </SuscriptionCardLabel>
                        <SuscriptionCardLabel
                          color="Danger"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setAction({
                              action: "unsuscription",
                              data: {
                                name: /* el.academy.name */ "adsa" || "",
                                id: { _id: /* el._id */ "ASDSADA" },
                              },
                              modalName: "confirm-delete-item-secuencial-modal",
                            });
                          }}
                        >
                          Darse de baja
                        </SuscriptionCardLabel>
                        <SuscriptionCardLabel
                          color="Danger"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            dispatch(showModal("send-transfer"));
                          }}
                        >
                          Darse de baja
                        </SuscriptionCardLabel>
                      </FlexContainer>
                    </SuscriptionCard>
                  );
                })}
              </GridSwitchStructure>
            )}
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      <TransferModal
        description={`Ten en cuenta que la verificacion del pago y la confirmacion para accedas a tu cohort pagando de esta forma puede tardar hasta 7 dias habiles. Recibiras un mail de confirmacion una vez que corroboremos el pago.`}
        frecuency={"unique"}
        onClose={() => console.log("a")}
        onSubmitModal={() => console.log("first")}
        setAction={() => console.log("first")}
        title={`Transferencia bancaria o deposito para suscripcion unica a {nombre}`}
      ></TransferModal>
      {!!cohorts && <FilterModal buildFilters={buildFilters}></FilterModal>}
      {action?.action === "change-purchase-method" && (
        <ChangeMethodModal
          setAction={setAction}
          onClose={() => {
            setAction(undefined);
          }}
        ></ChangeMethodModal>
      )}
      {action?.action === "confirm-change-purchase-method" && (
        <ConfirmChangeMethodModal
          setAction={setAction}
          suscription={{}}
          onClose={() => {
            setAction(undefined);
          }}
        ></ConfirmChangeMethodModal>
      )}
      {action?.action === "status-suscription" && (
        <StatusSuscriptionModal
          onClose={action.data.onClose}
          title={action.data.title}
        >
          <action.data.icon
            size={250}
            fill={action.data.color}
          ></action.data.icon>
        </StatusSuscriptionModal>
      )}
      {action?.action === "unsuscription" && (
        <ModalConfirmDelete
          description={`Te daras de baja automaticamente y perderas todos los accesos a ${action?.data.name} de manera inmediata.`}
          bntConfig={{
            content: "Dar de baja",
            style: {
              color: "Danger",
              style: { height: "26px", width: "150px", borderRadius: "5px" },
              options: {
                type: "filled",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
                loading: "Danger",
              },
            },
          }}
          states={deleteSuscriptionStates}
          sucessAction={() => {
            setAction({
              action: "status-suscription",
              modalName: "status-suscription-modal",
              data: {
                title: "Ocurrio un error al cambiar metodo",
                onClose: () => {
                  setAction(undefined);
                },
                icon: RiErrorWarningFill,
                color: "#000000",
              },
            });
          }}
          title={
            <span>
              {`¿Seguro que quiere darle de baja a `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {action?.data.name}
              </span>
              ?
            </span>
          }
          elementActions={suscriptionActions.deleteOne(action?.data.id)}
          resetAction={suscriptionActions.resetDeleteOne}
          resetState={setAction}
        ></ModalConfirmDelete>
      )}
      <AddCardModal></AddCardModal>
    </>
  );
};

const states = ({
  roadmapStore,
  cohortStore,
  academyStore,
  userStore,
  suscriptionStore,
}) => {
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: academies } = academyStore.all;
  const { data: user } = userStore;
  const { data: suscriptions } = suscriptionStore.all;
  const { states: deleteSuscriptionStates } = suscriptionStore.delete;
  return {
    suscriptions,
    academies,
    cohorts,
    user,
    deleteSuscriptionStates,
  };
};

export default connect(states)(Component);
