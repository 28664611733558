import React, { useState } from "react";

import { FlexContainer, Separator } from "../StyledComponents";
import { BsFillPenFill } from "react-icons/bs";
import {
  DescriptionModalSubtitle,
  DescriptionModalText,
  DescriptionModalTitle,
} from "./style";
import { GeneralContentDto } from "../../types/generalContent.dto";
import { DateFromNow } from "../../helpers/format-date";
import MobileContentModal from "../MobileContentModal";

const Component = ({
  setIsModalOpen,
  isModalOpen,
  content,
}: {
  setIsModalOpen: any;
  isModalOpen: boolean;
  content: GeneralContentDto;
}) => {
  return (
    <MobileContentModal
      title={"Descripción"}
      setIsVisible={setIsModalOpen}
      Icon={BsFillPenFill}
      isVisible={isModalOpen}
    >
      <DescriptionModalTitle>{content.name}</DescriptionModalTitle>
      <Separator size={10}></Separator>
      <FlexContainer gap="6px" align="center" wrap="wrap">
        <DescriptionModalSubtitle>
          {content.academy.name}
        </DescriptionModalSubtitle>
        <span>|</span>
        <DescriptionModalSubtitle>
          {`Vistas: ${content.viewCount}`}
        </DescriptionModalSubtitle>
        <span>|</span>
        <DescriptionModalSubtitle>
          {DateFromNow(content.createdAt)}
        </DescriptionModalSubtitle>
      </FlexContainer>
      <Separator size={9}></Separator>
      <div
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "rgba(106, 105, 112, 0.25)",
          marginBottom: "10px",
        }}
      ></div>
      <Separator size={10}></Separator>
      {content.description && (
        <DescriptionModalText>{content.description}</DescriptionModalText>
      )}
    </MobileContentModal>
  );
};

export default Component;
