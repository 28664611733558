import React from "react";
import { CommentDto } from "../../../../../types/comment.dto";
import { UserDto } from "../../../../../types/user.dto";
import {
  AiOutlineLike,
  AiOutlineDislike,
  AiFillDislike,
  AiFillLike,
  AiFillClockCircle,
} from "react-icons/ai";
import { DateFromNow } from "../../../../../helpers/format-date";
import { overThousandFixed } from "../../../../../helpers/number-fixed";
import {
  CommentPicture,
  CommentUser,
  CommentContent,
  CommentLikeDislike,
  CommentLikeDislikeNumber,
  LikeDislikeText,
  CommentAnswerCount,
} from "../../../../Comment/style";
import { Img } from "../../../../Header/styles";
import { FlexContainer, Separator } from "../../../../StyledComponents";
import { useDispatch } from "react-redux";
import commentActions from "../../../../../store/actions/comment";
import { useTheme } from "styled-components";
import { GrUndo } from "react-icons/gr";
const Component = ({
  comment,
  user,
  className = "child",
  answerOnClick,
  noAnswer,
}: {
  comment: CommentDto;
  user: UserDto;
  className?: string;
  answerOnClick?: any;
  noAnswer?: boolean;
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  return (
    <div>
      <FlexContainer align="center" gap="8px">
        <CommentPicture>
          <Img src={comment.createdBy.picture}></Img>
        </CommentPicture>
        <FlexContainer gap="4px" align="center">
          <CommentUser className={className}>
            {comment.createdBy.name}
          </CommentUser>
          <span>|</span>
          <FlexContainer gap="3px" align="center">
            <AiFillClockCircle fill="#6a6970"></AiFillClockCircle>
            <CommentUser className={className}>
              {DateFromNow(comment.createdAt)}
            </CommentUser>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <Separator size={4}></Separator>
      <CommentContent>{comment.text}</CommentContent>
      <Separator size={8}></Separator>
      {!noAnswer && (
        <FlexContainer align="center" gap="8px">
          <CommentLikeDislike
            className={
              !!comment.likes.find((like) => like.user === user._id)
                ? "active"
                : ""
            }
            onClick={() => {
              if (comment.likes.find((like) => like.user === user._id)) {
                dispatch(
                  commentActions.manageLikeDislike({
                    _id: comment._id,
                    condition: "like",
                    action: "remove",
                    individual: true,
                  })
                );
              } else {
                dispatch(
                  commentActions.manageLikeDislike({
                    _id: comment._id,
                    condition: "like",
                    action: "add",
                    individual: true,
                  })
                );
              }
            }}
          >
            <FlexContainer gap="3px" align="center">
              <AiFillLike
                size={13}
                stroke={theme.colors.LightBlue}
                strokeWidth={
                  comment.likes.find((like) => like.user === user._id) ? 0 : 90
                }
                fill={
                  comment.likes.find((like) => like.user === user._id)
                    ? theme.colors.LightBlue
                    : "#fff"
                }
              ></AiFillLike>
              <CommentLikeDislikeNumber>
                {overThousandFixed(comment.likes.length)}
              </CommentLikeDislikeNumber>
            </FlexContainer>
          </CommentLikeDislike>
          <CommentLikeDislike
            className={
              !!comment.dislikes.find((like) => like.user === user._id)
                ? "active"
                : ""
            }
            onClick={() => {
              if (comment.dislikes.find((like) => like.user === user._id)) {
                dispatch(
                  commentActions.manageLikeDislike({
                    _id: comment._id,
                    condition: "dislike",
                    action: "remove",
                    individual: true,
                  })
                );
              } else {
                dispatch(
                  commentActions.manageLikeDislike({
                    _id: comment._id,
                    condition: "dislike",
                    action: "add",
                    individual: true,
                  })
                );
              }
            }}
          >
            <FlexContainer gap="3px" align="center">
              <AiFillDislike
                stroke={theme.colors.LightBlue}
                strokeWidth={
                  comment.dislikes.find((dislike) => dislike.user === user._id)
                    ? 0
                    : 90
                }
                fill={
                  comment.dislikes.find((dislike) => dislike.user === user._id)
                    ? theme.colors.LightBlue
                    : "#fff"
                }
                size={13}
              ></AiFillDislike>
              {/*  <LikeDislikeText>
              {overThousandFixed(comment.dislikes.length)}
            </LikeDislikeText> */}
            </FlexContainer>
          </CommentLikeDislike>
          {!noAnswer && (
            <FlexContainer
              onClick={() => {
                answerOnClick(comment);
              }}
              gap="3px"
              align="center"
            >
              <GrUndo size={13} color={theme.colors.LightBlue}></GrUndo>
              {comment.replyCount > 0 && (
                <CommentAnswerCount>{`${comment.replyCount} respuestas`}</CommentAnswerCount>
              )}
            </FlexContainer>
          )}
        </FlexContainer>
      )}
    </div>
  );
};

export default Component;
