import styled from "styled-components";

export const UserImage = styled("div")`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  overflow: hidden;
`;

export const UserText = styled("h3")`
  font-size: 16px;
  font-weight: 500;
  color: #051725;
`;
