import React, { CSSProperties, useContext, useEffect, useState } from "react";

const dashboardSkins = ({
  skin,
  sidebarCollapsed,
}: {
  skin: "default" | "ait";
  sidebarCollapsed: boolean;
}): Components => {
  switch (skin) {
    case "default":
      return {
        content: {},
        header: {},
        sidebar: {},
      };

    case "ait":
      return {
        content: {
          overflow: "auto",
        },
        header: {},
        sidebar: {},
      };

    default:
      return { content: {}, header: {}, sidebar: {} };
  }
};

declare global {
  interface Document {
    mozFullScreen: any;
    webkitIsFullScreen: any;
    cancelFullScreen;
    mozCancelFullScreen;
    webkitCancelFullScreen;
  }

  interface HTMLElement {
    mozRequestFullScreen: any;
    webkitRequestFullScreen: any;
  }
}

export interface Components {
  content: CSSProperties;
  sidebar: CSSProperties;
  header: CSSProperties;
}

export interface DashboardLayout {
  dashboardStyles: Components;
  sidebarCollapsed: boolean;
}

const initialValues: DashboardLayout = {
  dashboardStyles: dashboardSkins({ skin: "default", sidebarCollapsed: false }),
  sidebarCollapsed: false,
};

interface ContexInitialValuesDto extends DashboardLayout {
  setDashboardSkin: React.Dispatch<React.SetStateAction<"default" | "ait">>;
  setSidebarCollapsed?: any;
  resetDashboardLayout: () => void;
}

const contexInitialValues: ContexInitialValuesDto = {
  dashboardStyles: dashboardSkins({ skin: "default", sidebarCollapsed: false }),
  sidebarCollapsed: false,
  setDashboardSkin: () => {},
  setSidebarCollapsed: null,
  resetDashboardLayout: () => {},
};

export const DashboardLayoutProvidersContext =
  React.createContext(contexInitialValues);

export const useDashboardLayout = () =>
  useContext(DashboardLayoutProvidersContext);

const DashboardLayoutProvider = ({ children, useLocation }) => {
  const location = useLocation();
  const [dashboardStyles, setDashboardStyles] = useState<Components>(
    initialValues.dashboardStyles
  );
  const [dashboardSkin, setDashboardSkin] = useState<"default" | "ait">(
    "default"
  );
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(true);
  const resetDashboardLayout = () => {
    setDashboardStyles(dashboardSkins({ skin: "default", sidebarCollapsed }));
  };

  useEffect(() => {
    setDashboardStyles(
      dashboardSkins({ skin: dashboardSkin, sidebarCollapsed })
    );
  }, [dashboardSkin, sidebarCollapsed]);

  useEffect(() => {
    resetDashboardLayout();
  }, [location]);

  return (
    <DashboardLayoutProvidersContext.Provider
      value={{
        dashboardStyles,
        sidebarCollapsed,
        setDashboardSkin,
        setSidebarCollapsed,
        resetDashboardLayout,
      }}
    >
      {children}
    </DashboardLayoutProvidersContext.Provider>
  );
};

export default DashboardLayoutProvider;
