import { connect } from "react-redux";
import Grid from "../../../../../../components/Grid";
import LineGraph from "../../../../../../components/LineGraph";
import {
  ChartContainer,
  GrowCard,
} from "../../../../../../components/LineGraph/styles";
import Loader from "../../../../../../components/Loader";
import { AnalyticsUserDto } from "../../../../../../types/analytics.dto";
import NumericStats from "../NumericStats/NumericStats";
import {
  GrowingStages,
  GrowingStageWrapper,
  TitleStage,
} from "../NumericStats/styles";
import UserSearched from "../UserSearched/UserSearched";
import MultiLineGraph from "../../../../../../components/MultiLineGraph";
import { getRandomColor } from "../../../../../../helpers/generate-color";
import { arrayLengthDivision } from "../../../../../../helpers/notLengthDivision";

interface Props {
  analytics: AnalyticsUserDto[];
  selectedStudent?: string;
  cohortSelected: string;
}

const subarrays = (data): any[] => {
  return data.reduce((result, obj) => {
    const category = obj.skill._id;
    if (!result[category]) {
      result[category] = [];
    }
    result[category].push(obj);
    return result;
  }, {});
};

const Component = ({ selectedStudent, analytics, cohortSelected }: Props) => {
  if (!analytics) return <Loader color="Primary"></Loader>;

  const notesForEachStage = analytics
    .filter((analytic) => analytic.cohort === cohortSelected)[0]
    .skills.reduce((prev: any, current, index, array) => {
      if (prev.find((item) => item.criterion._id === current.skill._id))
        return prev;
      else {
        const sameCriterion = array.filter(
          (item) => item.skill._id === current.skill._id
        );
        const allStages = Array.from(
          new Set(
            sameCriterion.map((stage) => stage.stage).sort((a, b) => a - b)
          )
        );
        const getAllNotesFromEachStage = allStages.map((stage) => {
          return {
            stage: stage,
            notes: sameCriterion
              .filter((item) => item.stage === stage)
              .map((item) => item.note),
          };
        });

        return [
          ...prev,
          { criterion: current.skill, notesByStages: getAllNotesFromEachStage },
        ];
      }
    }, []);

  return (
    <Grid.Row>
      <Grid.Col style={{ padding: "0px" }} lg={4} sm={12}>
        <UserSearched section="analytics" userId={selectedStudent} />
        <GrowingStageWrapper>
          <GrowingStages>
            <NumericStats
              value={Number(
                analytics
                  .find((analytic) => analytic.cohort === cohortSelected)
                  ?.growFromPreviousStage.toFixed(1)
              )}
              label={"Crecimiento promedio global de ultima etapa a anteultima"}
            />
            <NumericStats
              value={Number(
                Object.entries(
                  analytics.filter(
                    (analytic) => analytic.cohort === cohortSelected
                  )[0].stagesNotes
                ).reduce((prev, current, currentIndex, array) => {
                  if (currentIndex === 0) return prev;
                  return (
                    prev +
                    ((current[1] - array[currentIndex - 1][1]) /
                      (array[currentIndex - 1][1] === 0
                        ? 1
                        : array[currentIndex - 1][1])) *
                      100
                  );
                }, 0) /
                  arrayLengthDivision(
                    Object.entries(
                      analytics.filter(
                        (analytic) => analytic.cohort === cohortSelected
                      )[0].stagesNotes
                    )
                  )
              )}
              label={"Crecimiento promedio global de todas las etapas"}
            />
          </GrowingStages>
        </GrowingStageWrapper>
      </Grid.Col>
      <Grid.Col
        offset={{ lg: 0.4 }}
        lg={7.6}
        sm={12}
        style={{ flexDirection: "column", gap: "20px" }}
      >
        <ChartContainer>
          <GrowCard>
            <TitleStage>Crecimiento de promedio global por etapas.</TitleStage>
            {!!analytics.find((analytic) => analytic.cohort === cohortSelected)
              ?.stagesNotes && (
              <LineGraph
                analytics={Object.entries(
                  analytics.filter(
                    (analytic) => analytic.cohort === cohortSelected
                  )[0].stagesNotes
                ).reduce((prev: any, current, currentIndex, array) => {
                  const labelValue = current[0];
                  if (currentIndex === 0)
                    return [{ [`${labelValue}`]: current[1] }];

                  return [
                    ...prev,
                    {
                      [`${labelValue}`]:
                        ((current[1] - array[currentIndex - 1][1]) /
                          array[currentIndex - 1][1]) *
                        100,
                    },
                  ];
                }, [])}
              />
            )}
          </GrowCard>
        </ChartContainer>
        <ChartContainer>
          <GrowCard>
            <TitleStage>Crecimiento de criterios por etapas.</TitleStage>
            {analytics.find(
              (analytic) => analytic.cohort === cohortSelected
            ) && (
              <MultiLineGraph
                scales={[]}
                datasets={notesForEachStage.map((note) => {
                  return {
                    color: getRandomColor(),
                    /* {stage:number,notes:[2,3,4,5]} */
                    data: note.notesByStages
                      .map((item) => {
                        const eachOne = item.notes.map((noteF) => {
                          return {
                            x: item.stage,
                            y: noteF,
                          };
                        });
                        return eachOne;
                      })
                      .flat(1),
                    label: note.criterion.name,
                  };
                })} /* [{color:string;data:[[{x:number,y:number}],[]],label:string}] */
                labels={[]}
              />
            )}
          </GrowCard>
        </ChartContainer>
      </Grid.Col>
    </Grid.Row>
  );
};

const states = ({ analyticStore }) => {
  const { states: analyticsStates, data: analytics } =
    analyticStore.getIndividualStats;
  return {
    analyticsStates,
    analytics,
  };
};

export default connect(states)(Component);
