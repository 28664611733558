import { Button } from "./styles";
import Loader from "../Loader";
interface ButtonProps {
  type?: "button" | "submit" | "reset";
  onClick?: (event?) => void;
  children: any;
  disabled?: boolean;
  forwardref?;
  form?: any;
  loading?: boolean;
  options: {
    type: "filled" | "outline" | "link";
    hoverSkin?: any;
    size: "xs" | "sm" | "md" | "lg" | "xl";
    skin:
      | "primary"
      | "secondary"
      | "gray"
      | "transparent"
      | "white"
      | "danger"
      | "violet"
      | "lightblue";
    block?: boolean;
    marginBottom?: string;
    loading?: boolean;
  };
  minwidth?: string;

  style?;
}

const Component = ({
  children,
  loading,
  forwardref,
  type = "button",
  ...rest
}: ButtonProps) => {
  return (
    <Button {...(forwardref && { ref: forwardref })} {...rest} type={type}>
      {loading ? <Loader /> : children}
    </Button>
  );
};

export default Component;
