import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import Button from "../../../../components/Button";
import Grid from "../../../../components/Grid";
import LineGraphHorizontal from "../../../../components/LineGraphHorizontal";
import Loader from "../../../../components/Loader";
import {
  Center,
  FlexContainer,
  PageTitle,
} from "../../../../components/StyledComponents";
import { DateEStoDateEN } from "../../../../helpers/format-date";
import { getCampusStats } from "../../../../store/actions/analytics";
import AnalyticCard from "../components/AnalyticCard";
import NotData from "../components/NotData";
import { RankingItem, RankingTable } from "../style";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useWindowSize } from "../../../../hooks/useWindowScreen";
import { formatNumber } from "../../../../helpers/number-fixed";
const Component = ({ analyticsCampus, analyticsCampusStates, user }) => {
  const printRef: any = React.useRef(null);
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const handleExportWithComponent = (event) => {
    printRef.current.save();
  };
  /*  const handleDownloadPdf = async () => {
    if (!!printRef.current) {
      const element = printRef.current;
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "px", [canvas.width, canvas.height]);
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

      pdf.addImage(data, "PNG", 5, 10, pdfWidth - 10, pdfHeight + 5);
      pdf.save("print.pdf");
    }
  }; */
  useEffect(() => {
    dispatch(getCampusStats({ _id: user._id }));
  }, []);
  /*  if (!analyticsCampus) return <Loader color="LightBlue"></Loader>; */
  if (!analyticsCampus) return <Loader color="LightBlue"></Loader>;
  return (
    <Grid.Container>
      <PageTitle>Dashboard</PageTitle>
      {isMobile ? (
        <Center>
          <Button
            onClick={(e) => {
              handleExportWithComponent(e);
            }}
            type="button"
            options={{
              type: "filled",
              skin: "violet",
              size: "md",
              marginBottom: "10px",
            }}
            style={{ marginLeft: "10px" }}
          >
            Descargar
          </Button>
        </Center>
      ) : (
        <Grid.Col offset={{ lg: 10 }} sm={6}>
          <Button
            onClick={(e) => {
              handleExportWithComponent(e);
            }}
            type="button"
            options={{
              type: "filled",
              skin: "violet",
              size: "md",
              marginBottom: "10px",
            }}
            style={{ marginLeft: "10px" }}
          >
            Descargar
          </Button>
        </Grid.Col>
      )}

      <PDFExport fileName={`Learning ${new Date().valueOf()}`} ref={printRef}>
        {/* USAR ESTE */}
        <FlexContainer direction="column" gap="6px">
          <AnalyticCard
            title={"Cantidad de horas en Campus"}
            graphTitle={"Cantidad de horas en Campus - Crecimiento Histórico "}
            data={{
              stats: [
                {
                  label: "Últimos 30 días",
                  value: analyticsCampus.screentime.lastMonth || 0,
                },
                {
                  label: "Últimos 7 días",
                  value: analyticsCampus.screentime.lastWeek || 0,
                },
                {
                  label: "Últimas 24 horas",
                  value: analyticsCampus.screentime.lastDay || 0,
                },
              ],
              graphData: Object.entries(analyticsCampus.screentime.growth)
                .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                .map((growthData) => {
                  return {
                    name: growthData[0],
                    value: formatNumber(growthData[1]) || 0,
                  };
                }),
            }}
          ></AnalyticCard>

          <AnalyticCard
            height="250px"
            title="Top 10 Academias en las que mas interactúas"
          >
            <NotData data={analyticsCampus.clicksGiven.topTenAcademies}>
              <LineGraphHorizontal
                data={analyticsCampus.clicksGiven.topTenAcademies.map(
                  (academy) => {
                    return {
                      label: academy.name,
                      value: academy.clicks,
                    };
                  }
                )}
                title={"Alumnos"}
              ></LineGraphHorizontal>
            </NotData>
          </AnalyticCard>
          <AnalyticCard
            height="250px"
            title="Top 10 Cohorts en los que mas interactúas"
          >
            <NotData data={analyticsCampus.clicksGiven.topTenCohorts}>
              <LineGraphHorizontal
                data={analyticsCampus.clicksGiven.topTenCohorts.map(
                  (cohort) => {
                    return {
                      label: cohort.name,
                      value: cohort.clicks,
                    };
                  }
                )}
                title={"Alumnos"}
              ></LineGraphHorizontal>
            </NotData>
          </AnalyticCard>

          <AnalyticCard height="auto" title="Cuales son tus intereses:">
            <NotData data={analyticsCampus.topInterests}>
              <RankingTable>
                {analyticsCampus.topInterests.map((user, index) => {
                  return (
                    <FlexContainer
                      style={{ marginBottom: "5px" }}
                      align="center"
                      gap="5px"
                    >
                      <span>{index + 1}</span>
                      <RankingItem>{user.name}</RankingItem>
                    </FlexContainer>
                  );
                })}
              </RankingTable>
            </NotData>
          </AnalyticCard>
          <AnalyticCard
            title={"Certificados recibidos"}
            graphTitle={"Certificados recibidos - Crecimiento Histórico"}
            data={{
              stats: [
                {
                  label: "Últimos 30 días",
                  value: analyticsCampus.certificates.lastMonth || 0,
                },
                {
                  label: "Últimos 7 días",
                  value: analyticsCampus.certificates.lastWeek || 0,
                },
                {
                  label: "Últimas 24 horas",
                  value: analyticsCampus.certificates.lastDay || 0,
                },
              ],
              graphData: Object.entries(analyticsCampus.certificates.growth)
                .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                .map((growthData) => {
                  return {
                    name: growthData[0],
                    value: formatNumber(growthData[1]) || 0,
                  };
                }),
            }}
          ></AnalyticCard>
        </FlexContainer>
      </PDFExport>
    </Grid.Container>
  );
};

const states = ({ analyticStore, userStore }) => {
  const { data: analyticsCampus, states: analyticsCampusStates } =
    analyticStore.getCampusStats;
  const { data: user } = userStore;
  return {
    analyticsCampus,
    analyticsCampusStates,
    user,
  };
};

export default connect(states)(Component);
