import React, { useEffect, useState } from "react";
import {
  GridCard,
  GridCardDescription,
  GridCardImage,
  GridCardImageContainer,
  GridCardSmallContainer,
  GridCardTitle,
  GridStructure,
} from "../../../components/Layout/Dashboard/styles";
import { HiUserGroup } from "react-icons/hi";
import {
  Center,
  FlexContainer,
  Separator,
} from "../../../components/StyledComponents";
import { CountNumber, ListItem, ListItemComment, ListItemTitle } from "./style";
import Button from "../../../components/Button";
import { connect, useDispatch } from "react-redux";
import { AcademyDto } from "../../../types/academy.dto";
import { UserDto } from "../../../types/user.dto";
import academyActions from "../../../store/actions/academies";
import FilterByName from "./components/FilterByName";
import { CohortDto } from "../../../types/cohort.dto";
import { useTheme } from "styled-components";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import { RequestDto } from "../../../types/request.dto";
import ModalConfirmDelete from "../../../components/ModalConfirmDelete";
import { showModal } from "../../../store/actions/modal";
import { useWindowSize } from "../../../hooks/useWindowScreen";
import { Img } from "../../SignIn/styles";
import { MdGroups } from "react-icons/md";
import { addClick } from "../../../store/actions/userTimes";
import MdCard from "../Home/components/MediaItems/components/MediaItem/Component/MdCard";
import Skeleton from "../../../components/Skeleton";
interface ComponentProps {
  academies: AcademyDto[];
  user: UserDto;
  cohorts: CohortDto[];
  updateStates;
  requests: RequestDto[];
}

const Component = ({
  academies,
  user,
  cohorts,
  updateStates,
  requests,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const theme = useTheme();
  const [selectedAcademy, setSelectedAcademy] = useState<AcademyDto | null>();
  const [currentAcademiesList, setCurrentAcademiesList] = useState<
    AcademyDto[]
  >([]);
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);

  const [
    filteredCurrentAcademiesListValues,
    setFilteredCurrentAcademiesListValues,
  ] = useState<AcademyDto[]>([]);

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter(
        (applyedFilter) => applyedFilter.field === newFilter.field
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    /**
     * Se elimina el filtro
     */
    if (filterRemoved) {
      setFilters([
        ...applyedFilters.filter(
          (applyedFilter) => applyedFilter.field !== newFilter.field
        ),
      ]);
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  useEffect(() => {
    if (!academies) {
      dispatch(academyActions.getAll({ filterBy: { active: true } }));
    }
  }, [academies, dispatch]);

  useEffect(() => {
    buildFilters({ field: "private", value: "no" });

    return () => {
      dispatch(academyActions.resetGetAllAcademies());
    };
  }, []);

  useEffect(() => {
    if (!!academies) {
      const currentAcademies = academies.filter((academy) =>
        academy.users.find((userF) => userF.user === user._id)
      );
      setCurrentAcademiesList(currentAcademies);
    }
  }, [academies]);

  useEffect(() => {
    const filteredTalents = currentAcademiesList?.filter(
      (currentTalentValues) => {
        const thereAreFilters = applyedFilters.length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter((applyedFilter) => !applyedFilter.special)
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value
          );
        });

        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );

    setFilteredCurrentAcademiesListValues(filteredTalents);
  }, [applyedFilters, currentAcademiesList]);

  if (!academies)
    return (
      <div style={{ maxWidth: "900px", margin: "0 auto" }}>
        <Skeleton
          itemsPerPage={9}
          itemsPerRow={3}
          animated
          size={{ height: 275, width: 165 }}
          icon="academy"
        />
      </div>
    );

  return (
    <>
      <div style={{ maxWidth: "600px", margin: "0 auto", width: "100%" }}>
        <FilterByName
          items={filteredCurrentAcademiesListValues.map((academy) => {
            return { value: academy.name, label: academy.name };
          })}
          setFilter={buildFilters}
        ></FilterByName>
      </div>

      <Center gap="26px">
        <Button
          type="button"
          onClick={() => buildFilters({ field: "private", value: "no" })}
          options={{
            size: "md",
            type: "filled",
            skin: "violet",
          }}
          minwidth="0px"
          style={{
            width: "auto",
            border: "none",
            height: "32px",
            padding: "7px 8px 8px",
            borderRadius: "5px",
            color: !applyedFilters.find((filter) => filter.field === "private")
              ? "#fff"
              : theme.colors.Night,
            backgroundColor: !applyedFilters.find(
              (filter) => filter.field === "private"
            )
              ? theme.colors.LightBlue
              : theme.colors.LightBlueHover,
          }}
        >
          Todos
        </Button>
        <Button
          type="button"
          onClick={() => buildFilters({ field: "private", value: false })}
          options={{
            size: "md",
            type: "filled",
            skin: "violet",
          }}
          minwidth="0px"
          style={{
            width: "auto",
            border: "none",
            height: "32px",
            padding: "7px 8px 8px",
            borderRadius: "5px",
            color: applyedFilters.find(
              (filter) => filter.field === "private" && filter.value === false
            )
              ? "#fff"
              : theme.colors.Night,
            backgroundColor: applyedFilters.find(
              (filter) => filter.field === "private" && filter.value === false
            )
              ? theme.colors.LightBlue
              : theme.colors.LightBlueHover,
          }}
        >
          Academias Publicas
        </Button>
        <Button
          type="button"
          onClick={() => buildFilters({ field: "private", value: true })}
          options={{
            size: "md",
            type: "filled",
            skin: "violet",
          }}
          minwidth="0px"
          style={{
            width: "auto",
            border: "none",
            height: "32px",
            padding: "7px 8px 8px",
            borderRadius: "5px",
            color: applyedFilters.find(
              (filter) => filter.field === "private" && filter.value === true
            )
              ? "#fff"
              : theme.colors.Night,
            backgroundColor: applyedFilters.find(
              (filter) => filter.field === "private" && filter.value === true
            )
              ? theme.colors.LightBlue
              : theme.colors.LightBlueHover,
          }}
        >
          Academias Privadas
        </Button>
      </Center>
      <div style={{ height: "24px" }}></div>
      {filteredCurrentAcademiesListValues.length > 0 ? (
        isMobile ? (
          <FlexContainer direction="column" gap="12px">
            {filteredCurrentAcademiesListValues.map((academy) => (
              <ListItem
                onClick={(ev) => {
                  dispatch(addClick({ id: academy._id, user: user._id }));
                  navigate(`/dashboard/academy/${academy._id}`);
                }}
              >
                <FlexContainer align="center" justify="space-between">
                  <FlexContainer gap="8px" align="center">
                    <GridCardSmallContainer>
                      <Img src={academy.picture} />
                    </GridCardSmallContainer>
                    <FlexContainer direction="column" gap="5px">
                      <ListItemTitle>{academy.name}</ListItemTitle>
                      <FlexContainer align="center" gap="5px">
                        <MdGroups
                          color={theme.colors.LightBlue}
                          size={20}
                        ></MdGroups>
                        <ListItemComment>{academy.userCount}</ListItemComment>
                      </FlexContainer>
                    </FlexContainer>
                  </FlexContainer>
                  <Button
                    type="button"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      setSelectedAcademy(academy);
                    }}
                    options={{
                      size: "md",
                      type: "filled",
                      skin: "danger",
                    }}
                  >
                    X
                  </Button>
                </FlexContainer>
              </ListItem>
            ))}
          </FlexContainer>
        ) : (
          <GridStructure
            style={{ gridAutoRows: "1fr" }}
            width="165px"
            limitCard="165px"
            maxWidth="900px"
          >
            {filteredCurrentAcademiesListValues.map((academy, key) => (
              <MdCard
                item={academy}
                onClick={(ev) => {
                  dispatch(
                    addClick({
                      id: academy._id,
                      user: user._id,
                      type: "ACADEMY",
                    })
                  );
                  navigate(`/dashboard/academy/${academy._id}`);
                }}
              >
                <>{console.log("nnncc", selectedAcademy)}</>
                <FlexContainer justify="center" style={{ marginTop: "10px" }}>
                  <Button
                    type="button"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      dispatch(
                        showModal("confirm-delete-item-secuencial-modal")
                      );
                      setSelectedAcademy(academy);
                    }}
                    options={{
                      size: "md",
                      type: "filled",
                      skin: "danger",
                    }}
                    minwidth="150px"
                    style={{
                      width: "150px",
                      backgroundColor: "#000",
                      borderColor: "#000",
                    }}
                  >
                    Salir
                  </Button>
                </FlexContainer>
              </MdCard>
            ))}
          </GridStructure>
        )
      ) : (
        <Center>
          <h3>No hay resultados</h3>
        </Center>
      )}
      {!!selectedAcademy && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Eliminar",
            style: {
              color: "Danger",

              style: {
                width: "150px",
                height: "31px",
              },
              options: {
                type: "filled",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={updateStates}
          elementActions={academyActions.deleteMember({
            _id: selectedAcademy._id,
            userId: user._id,
          })}
          onClose={() => {
            setSelectedAcademy(null);
          }}
          title={
            <FlexContainer direction="column" gap="11px">
              <span>
                {`¿Seguro quieres salir de la academia `}
                <span style={{ color: "#000" }}>{selectedAcademy.name}</span>?
              </span>
              <p>
                Perderás todos los accesos y suscripciones relacionados a esta
                Academia y los contenidos que no hayas descargado.
              </p>
            </FlexContainer>
          }
          resetAction={academyActions.resetUpdate}
          resetState={setSelectedAcademy}
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({ academyStore, userStore, cohortStore, requestStore }) => {
  const { data: academies } = academyStore.all;
  const { states: updateStates } = academyStore.update;
  const { data: user } = userStore;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: requests } = requestStore.allRequests;
  return {
    academies,
    user,
    cohorts,
    updateStates,
    requests,
  };
};

export default connect(states)(Component);
