import styled from "styled-components";
import { MediaInteractionButtons } from "../../pages/Dashboard/Byte/Player/style";

export const Item = styled("div")`
  button {
    background: none;
    border: none;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
    cursor: pointer;
    padding: 5px !important;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0) !important;
    transition: all 0.3s ease-in-out;

    &:active,
    &:hover {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }

    svg {
      border-radius: 100%;
      overflow: hidden;
    }
  }
`;

export const CircleButton = styled(MediaInteractionButtons)``;

export const Button = styled("button")`
  padding: 4px 6px;
  cursor: pointer;
  border-radius: 5px;
  border: solid 1px #2496ed;
  font-size: 12px;
  font-weight: 600;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  gap: 4px;
  color: #2496ed;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active {
    background-color: #2496ed;
    color: #fff;
  }
`;

export const Name = styled("span")`
  font-size: 12px;
  color: white;
  line-height: 16px;
`;

// export const ShareButton = styled('button')`
//   background: none;
//   border: none;
//   padding: 0px;
//   display: flex;
//   gap: 8px;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   width: fit-content;
//   cursor: pointer;
//   padding: 5px;
//   border-radius: 6px;
//   background-color: rgba(0,0,0,0);
//   transition: all .3s ease-in-out;

//   &:active {
//     background-color: rgba(0,0,0,.1);
//   }

//   svg {
//     border-radius: 100%;
//     overflow: hidden;
//   }
// `

export const Prev = styled("button")`
  display: block;
  width: 15px;
  height: 15px;
  border-top: 2px solid white;
  border-left: 2px solid white;
  position: absolute;
  left: -20px;
  top: 26px;
  transform: rotate(-45deg);
  cursor: pointer;
  z-index: 200;

  &:before {
    content: "";
  }
`;
export const Next = styled("button")`
  display: block;
  width: 15px;
  height: 15px;
  border-top: 2px solid white;
  border-left: 2px solid white;
  position: absolute;
  right: -20px;
  top: 26px;
  transform: rotate(135deg);
  cursor: pointer;
  z-index: 200;

  &:before {
    content: "";
  }
`;

export const CopyUrl = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px;
  border: 1px solid rgb(63, 63, 63);
  background-color: rgb(15, 15, 15);
  border-radius: 12px;
  height: 54px;

  span {
    border: none;
    overflow: hidden;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0);
    font-size: 14px;
    max-width: calc(100% - 130px);
    color: white;
  }
`;
export const CopyUrlButton = styled("button")<{ isCopied: boolean }>`
  border: 0px;
  padding: 0px;
  background: none;
  background-color: ${({ isCopied }) => (isCopied ? "#24ed68" : "#2496ed")};
  color: rgb(15, 15, 15);
  padding: 0 16px;
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  border-radius: 18px;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: ${({ isCopied }) => (isCopied ? "#24ed68" : "#48a2e7")};
  }
`;
