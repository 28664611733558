import Grid from "../../../../../../components/Grid";
import {
  CriterionCard,
  GrowCard,
  NoteCard,
} from "../../../../../../components/LineGraph/styles";
import UserSearched from "../UserSearched/UserSearched";
import BarChart from "../../../../../../components/BarChart/index";
import { TitleCriterion, TitleStage } from "../NumericStats/styles";
import { BigGrade } from "./styles";
import { connect } from "react-redux";
import { AnalyticsUserDto } from "../../../../../../types/analytics.dto";
import { maxValue } from "../../../../../../helpers/max-value";
import ShortString from "../../../../../../components/ShortString";
import { roundedNumbers } from "../../../../../../helpers/rounded-numbers";
import { arrayLengthDivision } from "../../../../../../helpers/notLengthDivision";
import { Separator } from "../../../../../../components/StyledComponents";

interface PropsComponent {
  selectedStudent?: string;
  stageLabel: string;
  stage: number;
  analyticsStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  analytics: AnalyticsUserDto[];
  cohortSelected: string;
}

const Component = ({
  selectedStudent,
  stageLabel,
  analyticsStates,
  analytics,
  cohortSelected,
  stage,
}: PropsComponent) => {
  const criterionFromStage = analytics
    .filter((analytic) => analytic.cohort === cohortSelected)[0]
    ?.skills.filter((skill) => skill.stage === stage);
  const criterionFromStageNoteSum = criterionFromStage?.reduce(
    (prev, current) => {
      return prev + current.note;
    },
    0
  );

  const notesFromStages = Array.from(
    new Set(criterionFromStage?.map((crit) => crit.note))
  ).sort((a, b) => a - b);

  console.log(notesFromStages, "mucaca");

  const maxNote = Math.max(...notesFromStages);
  const secondMaxNote = notesFromStages[1];
  const bestQualifiedCriterion = criterionFromStage?.filter(
    (criterion) => criterion.note === maxNote
  )[0];
  const secondBestQualifiedCriterion = criterionFromStage?.find(
    (criterion) => criterion.note === secondMaxNote
  );
  const worstNote = Math.min(...notesFromStages);
  const worstQualifiedCriterion = criterionFromStage?.find(
    (criterion) => criterion.note === worstNote
  );
  return (
    <>
      <Grid.Col style={{ flexDirection: "column", gap: "20px" }}>
        <Grid.Row>
          <Grid.Col sm={4}>
            <UserSearched section="analytics" userId={selectedStudent} />
          </Grid.Col>
          <Grid.Col sm={2}>
            <CriterionCard>
              <TitleCriterion>Promedio Global Etapa {stage}</TitleCriterion>
              <BigGrade>
                {!!criterionFromStage &&
                  roundedNumbers(
                    criterionFromStageNoteSum /
                      arrayLengthDivision(criterionFromStage)
                  )}
              </BigGrade>
            </CriterionCard>
          </Grid.Col>
          {isFinite(maxNote) && (
            <Grid.Col sm={2}>
              <CriterionCard>
                <TitleCriterion>{`Criterio mejor calificado :${bestQualifiedCriterion?.skill.name}`}</TitleCriterion>
                <BigGrade>{!!maxNote && roundedNumbers(maxNote)}</BigGrade>
              </CriterionCard>
            </Grid.Col>
          )}
          {!!secondBestQualifiedCriterion && (
            <Grid.Col sm={2}>
              <CriterionCard>
                <TitleCriterion>{`Segundo criterio mejor calificado :${secondBestQualifiedCriterion?.skill.name}`}</TitleCriterion>
                <BigGrade>
                  {!!secondMaxNote && roundedNumbers(secondMaxNote)}
                </BigGrade>
              </CriterionCard>
            </Grid.Col>
          )}
          {!!worstQualifiedCriterion && (
            <Grid.Col sm={2}>
              <CriterionCard>
                <TitleCriterion>{`Peor criterio calificado :${worstQualifiedCriterion?.skill.name}`}</TitleCriterion>
                <BigGrade>{!!worstNote && roundedNumbers(worstNote)}</BigGrade>
              </CriterionCard>
            </Grid.Col>
          )}
        </Grid.Row>
        <Separator size={12}></Separator>
        <Grid.Row>
          <NoteCard height={"379px"}>
            <TitleStage>
              Calificacion por criterio en etapa seleccionada
            </TitleStage>
            {!!analytics && !!criterionFromStage && (
              <BarChart
                labels={criterionFromStage.map((skill) => skill.skill.name)}
                data={criterionFromStage.map((skill) => skill.note)}
              ></BarChart>
            )}
          </NoteCard>
        </Grid.Row>
      </Grid.Col>
    </>
  );
};

const states = ({ analyticStore }) => {
  const { states: analyticsStates, data: analytics } =
    analyticStore.getIndividualStats;
  return {
    analyticsStates,
    analytics,
  };
};

export default connect(states)(Component);
