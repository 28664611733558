import { CSSProperties } from "react";
import { Loader, Circle } from "./styles";

interface ComponentProps {
  color?: "Primary" | "Secondary" | "White" | "Danger" | "Violet" | "LightBlue";
  style?: CSSProperties;
}

const Component = ({ style, ...rest }: ComponentProps) => {
  return (
    <Loader style={style}>
      <Circle {...rest}></Circle>
      <Circle {...rest}></Circle>
      <Circle {...rest}></Circle>
      <Circle {...rest}></Circle>
    </Loader>
  );
};

export default Component;
