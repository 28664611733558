import Modal from "../../../../../../components/Modal";
import NewMentorForm from "../../../New";

const Component = () => {
  return (
    <Modal name="new-mentor-modal" title="Nuevo Mentor">
      <NewMentorForm />
    </Modal>
  );
};

export default Component;
