import {
  initialState,
  Actions,
  State,
  GET_ALL_CDOCS,
  GET_ALL_CDOCS_ERROR,
  GET_ALL_CDOCS_SUCCESS,
  GET_CDOC,
  GET_CDOC_ERROR,
  GET_CDOC_SUCCESS,
  NEW_CDOC,
  NEW_CDOC_ERROR,
  NEW_CDOC_SUCCESS,
  RESET_NEW_CDOC,
  UPDATE_CDOC,
  UPDATE_CDOC_ERROR,
  UPDATE_CDOC_SUCCESS,
  RESET_UPDATE_CDOC,
  DELETE_CDOC,
  DELETE_CDOC_ERROR,
  DELETE_CDOC_SUCCESS,
  RESET_DELETE_CDOC,
  RESET_GET_CDOC,
  ADD_USER_CDOC,
  SAVE_CDOC,
  SHARED_CDOC,
  SAVED_CDOC,
  UNSAVED_CDOC,
  QUERY_CDOC,
  QUERY_CDOC_SUCCESS,
  QUERY_CDOC_ERROR,
  RESET_QUERY_CDOC,
  ADD_VIEW_CDOC,
  MANAGE_LIKE_DISLIKE_CDOC,
  UPDATE_ONE_CDOC,
  UPDATE_ONE_CDOC_ERROR,
  UPDATE_ONE_CDOC_SUCCESS,
  RESET_UPDATE_ONE_CDOC,
  SET_GET_CDOC,
  UPDATE_CURRENT_CDOC_LIST,
  GET_CDOCS_OFFSET,
  GET_CDOCS_OFFSET_ERROR,
  GET_CDOCS_OFFSET_SUCCESS,
  RESET_GET_CDOCS_OFFSET,
  RESET_GET_ONE_CDOC_DATA,
  RESET_GET_ALL_CDOC_DATA,
  UPDATE_ITEM_LIST_CDOC,
} from "../types/cdoc";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_CDOCS:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_CURRENT_CDOC_LIST:
      return {
        ...state,
        all: {
          ...state.all,
          data: action.payload,
        },
      };
    case UPDATE_ITEM_LIST_CDOC:
      return {
        ...state,
        update: {
          states: {
            success: false,
            error: false,
            loading: true,
          },
        },
      };
    case GET_ALL_CDOCS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case RESET_GET_ONE_CDOC_DATA:
      return {
        ...state,
        selected: initialState.selected,
      };
    case RESET_GET_ALL_CDOC_DATA:
      return {
        ...state,
        all: initialState.all,
      };
    case GET_ALL_CDOCS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_CDOCS_OFFSET:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case GET_CDOCS_OFFSET_ERROR:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case GET_CDOCS_OFFSET_SUCCESS:
      return {
        ...state,
        offSet: {
          data: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case RESET_GET_CDOCS_OFFSET:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: false,
            success: false,
            error: false,
          },
        },
      };
    case UPDATE_ONE_CDOC:
      return {
        ...state,
        updateOne: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_ONE_CDOC_ERROR:
      return {
        ...state,
        updateOne: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case UPDATE_ONE_CDOC_SUCCESS:
      return {
        ...state,
        updateOne: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case RESET_UPDATE_ONE_CDOC:
      return {
        ...state,
        updateOne: {
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case SET_GET_CDOC:
      return {
        ...state,
        selected: {
          ...state.selected,
          data: action.payload,
        },
      };
    case MANAGE_LIKE_DISLIKE_CDOC:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case ADD_VIEW_CDOC:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SAVED_CDOC:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UNSAVED_CDOC:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case QUERY_CDOC:
      return {
        ...state,
        query: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case QUERY_CDOC_SUCCESS:
      return {
        ...state,
        query: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case QUERY_CDOC_ERROR:
      return {
        ...state,
        query: {
          data: state.query.data,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_QUERY_CDOC:
      return {
        ...state,
        query: {
          ...state.query,
          states: initialState.query.states,
        },
      };
    case SHARED_CDOC:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SAVE_CDOC:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_CDOC:
      return {
        ...state,
        selected: {
          data: state.selected.data,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case GET_CDOC_SUCCESS:
      return {
        ...state,
        selected: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_CDOC_ERROR:
      return {
        ...state,
        selected: {
          data: state.selected.data,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_CDOC:
      return {
        ...state,
        selected: {
          ...state.selected,
          states: initialState.selected.states,
        },
      };
    case NEW_CDOC:
      return {
        ...state,
        new: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_CDOC_SUCCESS:
      return {
        ...state,
        new: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_CDOC_ERROR:
      return {
        ...state,
        new: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_CDOC:
      return {
        ...state,
        new: initialState.new,
      };

    case ADD_USER_CDOC:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_CDOC:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_CDOC_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_CDOC_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_CDOC:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_CDOC:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_CDOC_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_CDOC_ERROR:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_CDOC:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
