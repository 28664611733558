import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_SLIDESHOW,
  GET_SLIDESHOW_SUCCESS,
  GET_SLIDESHOW_ERROR,
  GET_ALL_SLIDESHOWS,
  GET_ALL_SLIDESHOWS_SUCCESS,
  GET_ALL_SLIDESHOWS_ERROR,
  NEW_SLIDESHOW,
  NEW_SLIDESHOW_ERROR,
  NEW_SLIDESHOW_SUCCESS,
  NewSlideShow,
  UPDATE_SLIDESHOW,
  UPDATE_SLIDESHOW_SUCCESS,
  UPDATE_SLIDESHOW_ERROR,
  DELETE_SLIDESHOW,
  DELETE_SLIDESHOW_SUCCESS,
  DELETE_SLIDESHOW_ERROR,
  GetSlideShow,
  GetAllSlideShows,
  UpdateSlideShow,
  DeleteSlideShow,
  UploadMedia,
  UPLOAD_MEDIA,
  AddUserToSlideShow,
  ADD_USER_TO_SLIDESHOW,
  SharedSlideShow,
  SHARED_SLIDESHOW,
  GET_SLIDESHOWS_OFFSET,
  GET_SLIDESHOWS_OFFSET_ERROR,
  GET_SLIDESHOWS_OFFSET_SUCCESS,
  GetSlideShowsOffset,
  ADD_CLICK_SLIDESHOW,
  AddClickSlideShow,
  QUERY_SLIDESHOW,
  QUERY_SLIDESHOW_ERROR,
  QUERY_SLIDESHOW_SUCCESS,
  QuerySlideShow,
  ManageLikeDislikeSlideshow,
  MANAGE_LIKE_DISLIKE_SLIDESHOW,
  SET_GET_SLIDESHOW,
  UPDATE_CURRENT_SLIDESHOW_LIST,
  SavedSlideshow,
  SAVED_SLIDESHOW,
  AddViewSlideshow,
  ADD_VIEW_SLIDESHOW,
  UPDATE_ITEM_LIST_SLIDESHOW,
} from "../types/slideshow";

const slideshowsOffset = ({ slideshowStore }) => slideshowStore.offSet.data;
const slideshowData = ({ slideshowStore }) => slideshowStore.all.data;
function* getAllSlideShows({ payload }: GetAllSlideShows) {
  const { response, error } = yield call(services.slideshow.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_SLIDESHOWS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_SLIDESHOWS_SUCCESS, payload: response });
  }
}

function* getSlideShow({ payload }) {
  const { response, error } = yield call(services.slideshow.getOne, payload);

  if (error) {
    yield put({
      type: GET_SLIDESHOW_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_SLIDESHOW_SUCCESS, payload: response });
  }
}

function* newSlideShow({ payload }) {
  const { response, error } = yield call(services.slideshow.new, payload);

  if (error) {
    yield put({
      type: NEW_SLIDESHOW_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_SLIDESHOW_SUCCESS, payload: response })]);
  }
}

function* updateSlideShow({ payload }) {
  const { response, error } = yield call(services.slideshow.update, payload);
  if (error) {
    yield put({
      type: UPDATE_SLIDESHOW_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_SLIDESHOW_SUCCESS, payload: response })]);
  }
}
function* addUserToSlideShow({ payload }) {
  const { response, error } = yield call(
    services.slideshow.addUserSlideShow,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_SLIDESHOW_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_SLIDESHOW_SUCCESS, payload: response })]);
  }
}

function* uploadMedia({ payload }) {
  const { response, error } = yield call(services.slideshow.update, payload);

  if (error) {
    yield put({
      type: UPDATE_SLIDESHOW_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_SLIDESHOW_SUCCESS, payload: response })]);
  }
}

function* deleteSlideShow({ payload }) {
  const { response, error } = yield call(services.slideshow.delete, payload);

  if (error) {
    yield put({
      type: DELETE_SLIDESHOW_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_SLIDESHOW_SUCCESS, payload: response })]);
  }
}

function* shareSlideShow({ payload }) {
  const { response, error } = yield call(
    services.slideshow.shareSlideShow,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_SLIDESHOW_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_SLIDESHOW_SUCCESS, payload: response })]);
  }
}

function* getSlideShowsOffset({ payload }) {
  const { response, error } = yield call(services.slideshow.getOffset, payload);
  if (error) {
    yield put({
      type: GET_SLIDESHOWS_OFFSET_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const offsetSlideShows = yield select(slideshowsOffset);
    const newOffsetSlideShows = !offsetSlideShows
      ? response
      : [...offsetSlideShows, ...response];
    yield put({
      type: GET_SLIDESHOWS_OFFSET_SUCCESS,
      payload: newOffsetSlideShows,
    });
  }
}

function* addClick({ payload }) {
  const { response, error } = yield call(services.slideshow.addClick, payload);
  /*  if (error) {
  } else {
    const currentListOfSlideShows = yield select(companyList);
    const newListofSlideShows = currentListOfSlideShows?.map((content) => {
      if (content._id === response._id) {
        return response;
      }
      return content;
    });
    yield put({
      type: UPDATE_CURRENT_SLIDESHOW_LIST,
      payload: newListofSlideShows,
    });
  } */
}

function* querySlideShow({ payload }) {
  const { response, error } = yield call(services.slideshow.query, payload);

  if (error) {
    yield put({
      type: QUERY_SLIDESHOW_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: QUERY_SLIDESHOW_SUCCESS, payload: response });
  }
}
function* manageLikeDislike({ payload }) {
  const { response, error } = yield call(
    services.slideshow.manageLikeDislike,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_SLIDESHOW_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_SLIDESHOW_SUCCESS, payload: response });

    if (!!payload.individual) {
      yield put({ type: SET_GET_SLIDESHOW, payload: response });
    }
  }
}
function* manageContent({ payload }) {
  const { response, error } = yield call(
    services.slideshow.manageContent,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_SLIDESHOW_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_SLIDESHOW_SUCCESS, payload: response });
    /*    yield put({
      type: UPDATE_CURRENT_SLIDESHOW_LIST,
      payload: newListofCohorts,
    }); */
    if (!!payload.individual) {
      yield put({ type: SET_GET_SLIDESHOW, payload: response });
    }
  }
}
function* addView({ payload }) {
  const { response, error } = yield call(services.slideshow.addView, payload);
  /* 
  if (error) {
    yield put({
      type: UPDATE_VIDEO_ERROR, 
      payload: error,
    });
  } else {
    const newListVideo = yield select(videoList);
      const newListVideoAdded = newListVideo.map((item) =>
      item._id === response._id ? item : response
    );
    yield all([put({ type: UPDATE_VIDEO_SUCCESS, payload: response })]);
  } */
}

function* updateSlideshowListItem({ payload }) {
  const { response, error } = yield call(services.slideshow.update, payload);

  if (error) {
    yield put({
      type: UPDATE_SLIDESHOW_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_SLIDESHOW_SUCCESS, payload: response }),
      put({ type: SET_GET_SLIDESHOW, payload: response }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllSlideShows>(GET_ALL_SLIDESHOWS, getAllSlideShows);
  yield takeLatest<GetSlideShow>(GET_SLIDESHOW, getSlideShow);
  yield takeLatest<NewSlideShow>(NEW_SLIDESHOW, newSlideShow);
  yield takeLatest<UpdateSlideShow>(UPDATE_SLIDESHOW, updateSlideShow);
  yield takeLatest<DeleteSlideShow>(DELETE_SLIDESHOW, deleteSlideShow);
  yield takeLatest<AddClickSlideShow>(ADD_CLICK_SLIDESHOW, addClick);
  yield takeLatest<UploadMedia>(UPLOAD_MEDIA, uploadMedia);
  yield takeLatest<AddUserToSlideShow>(
    ADD_USER_TO_SLIDESHOW,
    addUserToSlideShow
  );
  yield takeLatest<ManageLikeDislikeSlideshow>(
    MANAGE_LIKE_DISLIKE_SLIDESHOW,
    manageLikeDislike
  );
  yield takeLatest<QuerySlideShow>(QUERY_SLIDESHOW, querySlideShow);
  yield takeLatest<SharedSlideShow>(SHARED_SLIDESHOW, shareSlideShow);
  yield takeLatest<GetSlideShowsOffset>(
    GET_SLIDESHOWS_OFFSET,
    getSlideShowsOffset
  );
  yield takeLatest<AddViewSlideshow>(ADD_VIEW_SLIDESHOW, addView);

  yield takeLatest<SavedSlideshow>(SAVED_SLIDESHOW, manageContent);
  yield takeLatest<any>(UPDATE_ITEM_LIST_SLIDESHOW, updateSlideshowListItem);
}
