import { CDocDto } from "../../types/cdoc.dto";
import { PaginationDto } from "../../types/pagination.dto";

export const GET_ALL_CDOCS = "GET_ALL_CDOCS";
export const GET_ALL_CDOCS_ERROR = "GET_ALL_CDOCS_ERROR";
export const GET_ALL_CDOCS_SUCCESS = "GET_ALL_CDOCS_SUCCESS";
export const GET_CDOC = "GET_CDOC";
export const GET_CDOC_ERROR = "GET_CDOC_ERROR";
export const GET_CDOC_SUCCESS = "GET_CDOC_SUCCESS";
export const RESET_GET_CDOC = "RESET_GET_CDOC";
export const NEW_CDOC = "NEW_CDOC";
export const NEW_CDOC_ERROR = "NEW_CDOC_ERROR";
export const NEW_CDOC_SUCCESS = "NEW_CDOC_SUCCESS";
export const RESET_NEW_CDOC = "RESET_NEW_CDOC";
export const UPDATE_CDOC = "UPDATE_CDOC";
export const UPDATE_CDOC_ERROR = "UPDATE_CDOC_ERROR";
export const UPDATE_CDOC_SUCCESS = "UPDATE_CDOC_SUCCESS";
export const RESET_UPDATE_CDOC = "RESET_UPDATE_CDOC";
export const DELETE_CDOC = "DELETE_CDOC";
export const DELETE_CDOC_ERROR = "DELETE_CDOC_ERROR";
export const DELETE_CDOC_SUCCESS = "DELETE_CDOC_SUCCESS";
export const RESET_DELETE_CDOC = "RESET_DELETE_CDOC";
export const DELETE_CDOC_MEMBER = "DELETE_CDOC_MEMBER";
export const DELETE_CDOC_MEMBER_SUCCESS = "DELETE_CDOC_MEMBER_SUCCESS";
export const DELETE_CDOC_MEMBER_ERROR = "DELETE_CDOC_MEMBER_ERROR";
export const ADD_COHORT_CDOC = "ADD_COHORT_CDOC";
export const ADD_USER_CDOC = "ADD_USER_CDOC";
export const SAVE_CDOC = "SAVE_CDOC";
export const SHARED_CDOC = "SHARED_CDOC";
export const UNSAVED_CDOC = "UNSAVED_CDOC";
export const SAVED_CDOC = "SAVED_CDOC";
export const QUERY_CDOC = "QUERY_CDOC";
export const QUERY_CDOC_SUCCESS = "QUERY_CDOC_SUCCESS";
export const QUERY_CDOC_ERROR = "QUERY_CDOC_ERROR";
export const RESET_QUERY_CDOC = "RESET_QUERY_CDOC";
export const ADD_VIEW_CDOC = " ADD_VIEW_CDOC";
export const ADD_CLICK_CDOC = "ADD_CLICK_CDOC";
export const UPDATE_ONE_CDOC = "UPDATE_ONE_CDOC";
export const UPDATE_ONE_CDOC_SUCCESS = "UPDATE_ONE_CDOC_SUCCESS";
export const UPDATE_ONE_CDOC_ERROR = "UPDATE_ONE_CDOC_ERROR";
export const RESET_UPDATE_ONE_CDOC = "RESET_UPDATE_ONE_CDOC";
export const SET_GET_CDOC = "SET_GET_CDOC";
export const MANAGE_LIKE_DISLIKE_CDOC = "MANAGE_LIKE_DISLIKE_CDOC";
export const UPDATE_CURRENT_CDOC_LIST = "UPDATE_CURRENT_CDOC_LIST";
export const GET_CDOCS_OFFSET = "GET_CDOCS_OFFSET";
export const GET_CDOCS_OFFSET_SUCCESS = "GET_CDOCS_OFFSET_SUCCESS";
export const GET_CDOCS_OFFSET_ERROR = "GET_CDOCS_OFFSET_ERROR";
export const RESET_GET_CDOCS_OFFSET = "RESET_GET_CDOCS_OFFSET";
export const RESET_GET_ALL_CDOC_DATA = "RESET_GET_ALL_CDOC_DATA";
export const RESET_GET_ONE_CDOC_DATA = "RESET_GET_ONE_CDOC_DATA";
export const UPDATE_ITEM_LIST_CDOC = "UPDATE_ITEM_LIST_CDOC";
export interface ResetGetAllCdocData {
  type: typeof RESET_GET_ALL_CDOC_DATA;
  payload: null;
}

export interface ResetGetOneCdocData {
  type: typeof RESET_GET_ONE_CDOC_DATA;
  payload: null;
}

export interface UpdateItemListCdoc {
  type: typeof UPDATE_ITEM_LIST_CDOC;
  payload: any;
}

export interface GetCdocsOffset {
  type: typeof GET_CDOCS_OFFSET;
  payload: { limit: number; offset: number };
}

export interface GetCdocsOffsetError {
  type: typeof GET_CDOCS_OFFSET_ERROR;
  payload: string | boolean;
}

export interface GetCdocsOffsetSuccess {
  type: typeof GET_CDOCS_OFFSET_SUCCESS;
  payload: CDocDto[];
}

export interface ResetGetCdocsOffset {
  type: typeof RESET_GET_CDOCS_OFFSET;
  payload: null;
}

export interface SetGetImage {
  type: typeof SET_GET_CDOC;
  payload: CDocDto | null;
}

export interface UpdateCurrentCdocList {
  type: typeof UPDATE_CURRENT_CDOC_LIST;
  payload: CDocDto[];
}

export interface ManageLikeDislikeCdoc {
  type: typeof MANAGE_LIKE_DISLIKE_CDOC;
  payload: {
    _id: string;
    user: string;
    condition: "like" | "dislike";
    action: "add" | "remove";
  };
}
export interface UpdateOneCdoc {
  type: typeof UPDATE_ONE_CDOC;
  payload: any;
}
export interface UpdateOneCdocSuccess {
  type: typeof UPDATE_ONE_CDOC_SUCCESS;
  payload: CDocDto;
}
export interface AddClickCdoc {
  type: typeof ADD_CLICK_CDOC;
  payload: { _id: string };
}
export interface UpdateOneCdocError {
  type: typeof UPDATE_ONE_CDOC_ERROR;
  payload: boolean | string;
}
export interface ResetUpdateOneCdoc {
  type: typeof RESET_UPDATE_ONE_CDOC;
  payload: null;
}

export interface AddViewCdoc {
  type: typeof ADD_VIEW_CDOC;
  payload: { _id: string };
}

export interface QueryCdoc {
  type: typeof QUERY_CDOC;
  payload: string;
}
export interface QueryCdocSuccess {
  type: typeof QUERY_CDOC_SUCCESS;
  payload: CDocDto[];
}
export interface QueryCdocError {
  type: typeof QUERY_CDOC_ERROR;
  payload: string | boolean;
}

export interface ResetQueryCdoc {
  type: typeof RESET_QUERY_CDOC;
  payload: null;
}

export interface SavedCdocs {
  type: typeof SAVED_CDOC;
  payload: string;
}
export interface UnSavedCdocs {
  type: typeof UNSAVED_CDOC;
  payload: string;
}

export interface GetAllCDOCs {
  type: typeof GET_ALL_CDOCS;
  payload: PaginationDto;
}

export interface AddCohortCdoc {
  type: typeof ADD_COHORT_CDOC;
  payload: { _id: string; cohortId: string };
}

export interface SaveCdoc {
  type: typeof SAVE_CDOC;
  payload: { _id: string; condition: string };
}

export interface AddUserCdoc {
  type: typeof ADD_USER_CDOC;
  payload: any;
}
export interface GetAllCDOCsSuccess {
  type: typeof GET_ALL_CDOCS_SUCCESS;
  payload: CDocDto[];
}
export interface GetAllCDOCsError {
  type: typeof GET_ALL_CDOCS_ERROR;
  payload: boolean | string;
}

export interface GetCDOC {
  type: typeof GET_CDOC;
  payload: { _id: string };
}

export interface GetCDOCSuccess {
  type: typeof GET_CDOC_SUCCESS;
  payload: CDocDto;
}

export interface GetCDOCError {
  type: typeof GET_CDOC_ERROR;
  payload: boolean | string;
}

export interface ResetGetCDOC {
  type: typeof RESET_GET_CDOC;
  payload: null;
}

export interface NewCDOC {
  type: typeof NEW_CDOC;
  payload: CDocDto;
}

export interface NewCDOCSuccess {
  type: typeof NEW_CDOC_SUCCESS;
  payload: CDocDto;
}

export interface NewCDOCError {
  type: typeof NEW_CDOC_ERROR;
  payload: boolean | string;
}

export interface ResetNewCDOC {
  type: typeof RESET_NEW_CDOC;
  payload: null;
}

export interface UpdateCDOC {
  type: typeof UPDATE_CDOC;
  payload: any;
}

export interface UpdateCDOCSuccess {
  type: typeof UPDATE_CDOC_SUCCESS;
  payload: CDocDto;
}

export interface UpdateCDOCError {
  type: typeof UPDATE_CDOC_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateCDOC {
  type: typeof RESET_UPDATE_CDOC;
  payload: null;
}

export interface DeleteCDOC {
  type: typeof DELETE_CDOC;
  payload: { id: string };
}

export interface DeleteCDOCSuccess {
  type: typeof DELETE_CDOC_SUCCESS;
  payload: null;
}

export interface DeleteCDOCError {
  type: typeof DELETE_CDOC_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteCDOC {
  type: typeof RESET_DELETE_CDOC;
  payload: null;
}

export interface SharedCdoc {
  type: typeof SHARED_CDOC;
  payload: any;
}

export interface State {
  all: {
    data: CDocDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  selected: {
    data: CDocDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  new: {
    data: CDocDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  offSet: {
    data: CDocDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  query: {
    data: null | CDocDto[];
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateOne: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  selected: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  offSet: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateOne: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  new: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  query: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllCDOCs
  | GetAllCDOCsSuccess
  | GetAllCDOCsError
  | GetCDOC
  | GetCDOCSuccess
  | GetCDOCError
  | NewCDOC
  | NewCDOCSuccess
  | NewCDOCError
  | ResetNewCDOC
  | UpdateCDOC
  | UpdateCDOCSuccess
  | UpdateCDOCError
  | ResetUpdateCDOC
  | DeleteCDOC
  | DeleteCDOCSuccess
  | DeleteCDOCError
  | ResetDeleteCDOC
  | AddCohortCdoc
  | ResetGetCDOC
  | AddUserCdoc
  | SaveCdoc
  | SharedCdoc
  | SavedCdocs
  | UnSavedCdocs
  | QueryCdoc
  | QueryCdocSuccess
  | QueryCdocError
  | ResetQueryCdoc
  | AddViewCdoc
  | UpdateOneCdoc
  | UpdateOneCdocSuccess
  | UpdateOneCdocError
  | ResetUpdateOneCdoc
  | SetGetImage
  | AddClickCdoc
  | ManageLikeDislikeCdoc
  | UpdateCurrentCdocList
  | GetCdocsOffset
  | GetCdocsOffsetError
  | GetCdocsOffsetSuccess
  | ResetGetCdocsOffset
  | ResetGetAllCdocData
  | ResetGetOneCdocData
  | UpdateItemListCdoc;
