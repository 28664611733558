const size = {
  sm: {
    fontSize: "14px",
    height: "30px",
    borderRadius: "15px",
  },
  md: {
    fontSize: "14px",
    height: "32px",
    borderRadius: "16px",
  },
  lg: {
    fontSize: "16px",
    height: "40px",
    borderRadius: "10px",
  },
};

export const button = {
  size,
};
