import React, { useEffect, useState } from "react";
import {
  FlexContainer,
  Separator,
} from "../../../../components/StyledComponents";
import { Img } from "../../../SignIn/styles";
import {
  BannerAcademyPhoto,
  BannerImageContainer,
  BannerTag,
  BannerText,
  BannerTitle,
  BannerWrapper,
} from "./style";
import { MdGroups } from "react-icons/md";
import Button from "../../../../components/Button";
import { AcademyDto } from "../../../../types/academy.dto";
import { connect, useDispatch } from "react-redux";
import { UserDto } from "../../../../types/user.dto";
import { RequestDto } from "../../../../types/request.dto";
import invitationActions from "../../../../store/actions/invitation";
import { InvitationDto } from "../../../../types/invitation.dto";
import {
  deleteRequest,
  getAllRequests,
  getRequestsByUserEntity,
  newRequest,
  resetDeleteRequest,
  resetNewRequest,
  resetUpdateRequest,
} from "../../../../store/actions/request";
import { useTheme } from "styled-components";
import { FiEdit } from "react-icons/fi";
import academyActions from "../../../../store/actions/academies";
import { showModal } from "../../../../store/actions/modal";
import ModalUpdatePhotos from "./components/ModalUpdatePhotos";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
import { StateDto } from "../../../../types/states.dto";
import Rating from "../../../../components/Rating";
import { LikeDislikeBox, LikeDislikeText } from "../../../Cohort/Home/style";
import { AiFillEye, AiOutlineDislike, AiOutlineLike } from "react-icons/ai";
import {
  formatNumber,
  overThousandFixed,
} from "../../../../helpers/number-fixed";
import Loader from "../../../../components/Loader";
import { addClick } from "../../../../store/actions/userTimes";
import services from "../../../../services";
import ShareLink from "../../../../components/ShareLink";
import { TagDto } from "../../../../types/tag.dto";
import { useWindowSize } from "../../../../hooks/useWindowScreen";

interface ComponentProps {
  children?;
  selectedAcademy: AcademyDto;
  user: UserDto;
  requests: RequestDto[];
  invitations: InvitationDto[];
  deleteRequestStates: StateDto;
  updateAcademyStates: StateDto;
  createRequestStates: StateDto;
  invitationUpdateStates: StateDto;
  invitationUpdated: InvitationDto;
  updateRequestStates: StateDto;
  updatedRequest: RequestDto;
  deletedRequest: RequestDto;
  createdRequest: RequestDto;
}
/* Apreto la foto y tengo que ir a la academy */
const Component = ({
  children,
  selectedAcademy,
  user,
  requests,
  invitations,
  deleteRequestStates,
  updateAcademyStates,
  createRequestStates,
  invitationUpdateStates,
  invitationUpdated,
  updateRequestStates,
  updatedRequest,
  deletedRequest,
  createdRequest,
}: ComponentProps) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const [action, setAction] = useState<{ action: string; data: any }>();
  const [selectedAcademyBanner, setSelectedAcademyBanner] = useState<string>();
  const [rating, setRating] = useState<number | undefined>(0);
  const [academyRequests, setAcademyRequests] = useState<RequestDto[]>([]);
  const [academyInvitations, setAcademyInvitations] = useState<InvitationDto[]>(
    []
  );
  const [contentCount, setContentCount] = useState<number>(0);
  const [currentTags, setCurrentTags] = useState<TagDto[]>([]);
  const theme = useTheme();
  useEffect(() => {
    if (!!selectedAcademy && !!user) {
      setRating(
        selectedAcademy.ratings.find((rating) => user._id === rating.user)
          ?.stars
      );
    }
  }, [selectedAcademy]);

  useEffect(() => {
    if (selectedAcademy) {
      const fetchData = async () => {
        const responseContents = await services.generalContent.getAll({
          filterBy: { academy: selectedAcademy._id, active: true },
        });
        const responseTags: any = await services.tag.getAll({
          filterBy: { academy: selectedAcademy._id },
          limit: 4,
        });
        if (responseContents.response) {
          setContentCount(responseContents.response.length);
        }
        if (responseTags.response) {
          setCurrentTags(responseTags.response);
        }
      };
      fetchData();
    }
  }, [selectedAcademy]);

  useEffect(() => {
    if (!!requests) {
      setAcademyRequests((state) => [...state, ...requests]);
    }
    if (!requests && !!selectedAcademy) {
      const requestPayload = {
        entity: selectedAcademy._id,
        filterBy: {},
      };
      dispatch(getRequestsByUserEntity(requestPayload));
    }
  }, [requests, dispatch, selectedAcademy]);
  useEffect(() => {
    if (!!invitations) {
      setAcademyInvitations((state) => [...state, ...invitations]);
    }
    if (!invitations && !!selectedAcademy) {
      const inivitationPayload = {
        entity: selectedAcademy._id,
        filterBy: {},
      };
      dispatch(invitationActions.getAllByUserEntity(inivitationPayload));
    }
  }, [invitations, dispatch, selectedAcademy]);

  useEffect(() => {
    if (!!deleteRequestStates.success) {
      setAcademyRequests((state) =>
        state.filter((st) => st._id !== deletedRequest._id)
      );
    }
  }, [deleteRequestStates]);

  useEffect(() => {
    if (!!updateRequestStates.success) {
      setAcademyRequests((state) =>
        state.map((st) => (st._id === updatedRequest._id ? updatedRequest : st))
      );
      dispatch(resetUpdateRequest());
    }
  }, [updateRequestStates]);

  useEffect(() => {
    if (!!invitationUpdateStates.success) {
      setAcademyInvitations((state) =>
        state.map((st) =>
          st._id === invitationUpdated._id ? invitationUpdated : st
        )
      );
      dispatch(invitationActions.resetUpdate());
    }
  }, [invitationUpdateStates]);

  useEffect(() => {
    if (createRequestStates.success) {
      setAcademyRequests((state) => [...state, createdRequest]);
      dispatch(resetNewRequest());
    }
  }, [createRequestStates]);

  useEffect(() => {
    if (updateAcademyStates.success || updateAcademyStates.error) {
      dispatch(academyActions.resetUpdate());
    }
  }, [updateAcademyStates]);
  return (
    <>
      <BannerWrapper
        style={{
          ...(isMobile && {
            margin: "10px 10px 10px 10px",
          }),
        }}
      >
        {!selectedAcademy ? (
          <>
            <BannerImageContainer style={{ height: "303px" }}>
              <FlexContainer
                style={{ height: "100%", width: "100%" }}
                align="center"
                justify="center"
              >
                <Loader color="LightBlue"></Loader>
              </FlexContainer>
            </BannerImageContainer>
            <FlexContainer
              style={{ height: "300px" }}
              align="center"
              justify="center"
            >
              <Loader color="LightBlue"></Loader>
            </FlexContainer>
          </>
        ) : (
          <>
            <BannerImageContainer>
              <Img
                src={
                  selectedAcademy.bannerPic ??
                  "https://files.worldwildlife.org/wwfcmsprod/images/Tiger_resting_Bandhavgarh_National_Park_India/hero_small/6aofsvaglm_Medium_WW226365.jpg"
                }
              ></Img>
            </BannerImageContainer>
            <FlexContainer gap="10px" align="center">
              <BannerAcademyPhoto style={{ marginTop: "-40px", zIndex: 1 }}>
                <Img
                  src={
                    selectedAcademy.picture ||
                    "https://cdn.pixabay.com/photo/2015/11/19/08/52/banner-1050629__340.jpg"
                  }
                ></Img>
              </BannerAcademyPhoto>
              <BannerTitle
                hideLine={2}
                style={{ fontWeight: "bold" }}
                size={"16px"}
              >
                {selectedAcademy.name}
              </BannerTitle>
            </FlexContainer>
            <Separator size={10}></Separator>
            <FlexContainer
              justify="space-between"
              gap="10px"
              wrap="wrap"
              style={{ width: "100%" }}
            >
              <FlexContainer
                style={{
                  ...(isMobile && { maxWidth: "100px" }),
                }}
                direction="column"
                gap="16px"
              >
                <FlexContainer gap="12px" direction="column">
                  <FlexContainer align="center" gap="4px">
                    <FlexContainer align="center" gap="3px">
                      <MdGroups
                        color={theme.colors.LightBlue}
                        fontSize={20}
                      ></MdGroups>
                      <BannerText size={"12px"}>
                        {overThousandFixed(selectedAcademy.userCount)}
                      </BannerText>
                    </FlexContainer>
                    <span>|</span>
                    <FlexContainer align="center" gap="3px">
                      <AiFillEye
                        color={theme.colors.LightBlue}
                        fontSize={20}
                      ></AiFillEye>
                      <BannerText size={"12px"}>
                        {overThousandFixed(selectedAcademy.viewCount)}
                      </BannerText>
                    </FlexContainer>
                  </FlexContainer>
                  <BannerText size={"12px"}>{`Cant. contenidos: ${
                    contentCount ?? "-"
                  }`}</BannerText>
                  <FlexContainer gap="2px">
                    <BannerText size={"12px"}>Tags:</BannerText>
                    <div style={{ maxWidth: isMobile ? "170px" : "300px" }}>
                      <FlexContainer gap="3px" align="center" wrap="wrap">
                        {currentTags.map((tag) => {
                          return <BannerTag>{tag.name}</BannerTag>;
                        })}
                      </FlexContainer>
                    </div>
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer direction="column" align="center" gap="14px">
                <h2 style={{ fontSize: "16px", color: "#051725" }}>
                  {selectedAcademy.ratings.length > 0
                    ? formatNumber(
                        selectedAcademy.ratings.reduce((prev, next) => {
                          return prev + next.stars;
                        }, 0) / selectedAcademy.ratings.length
                      )
                    : 0}
                </h2>
                <Rating
                  starSize={18}
                  color={{ filled: "#2496ed", unfilled: "transparent" }}
                  count={5}
                  passiveValue={
                    selectedAcademy.ratings.length > 0
                      ? formatNumber(
                          selectedAcademy.ratings.reduce((prev, next) => {
                            return prev + next.stars;
                          }, 0) / selectedAcademy.ratings.length
                        )
                      : 0
                  }
                  rating={rating}
                  onRating={(rate) => {
                    dispatch(
                      academyActions.addRating({
                        id: selectedAcademy._id,
                        stars: rate,
                        individual: true,
                      })
                    );
                  }}
                ></Rating>
                {!invitations || !requests ? (
                  <Loader style={{ margin: "0px" }} color="LightBlue"></Loader>
                ) : !selectedAcademy.users.find(
                    (userF) => userF.user === user._id && !userF.roleEnd
                  ) ? (
                  selectedAcademy.private ? (
                    academyInvitations?.find(
                      (invitation) =>
                        invitation.active &&
                        invitation.academy._id === selectedAcademy._id &&
                        invitation.sentBy._id === user._id &&
                        invitation.status === "PENDING"
                    ) ? (
                      <Button
                        type="button"
                        onClick={() => {
                          dispatch(
                            invitationActions.update({
                              condition: "accept",
                              _id: user._id,
                            })
                          );
                          dispatch(
                            addClick({
                              id: selectedAcademy._id,
                              user: user._id,
                              type: "ACADEMY",
                            })
                          );
                        }}
                        style={{ borderRadius: "10px" }}
                        options={{
                          size: "md",
                          type: "filled",
                          skin: "lightblue",
                        }}
                      >
                        Aceptar Invitacion
                      </Button>
                    ) : !academyRequests?.find(
                        (request) =>
                          request.active &&
                          request.sentBy === user._id &&
                          request.academy === selectedAcademy._id
                      ) ? (
                      <Button
                        type="button"
                        onClick={() => {
                          dispatch(
                            newRequest({
                              academy: selectedAcademy._id,
                              platform: "CAMPUS",
                              sentBy: user._id,
                            })
                          );
                          dispatch(
                            addClick({
                              id: selectedAcademy._id,
                              user: user._id,
                              type: "ACADEMY",
                            })
                          );
                        }}
                        options={{
                          size: "md",
                          type: "filled",
                          skin: "lightblue",
                        }}
                        loading={createRequestStates.loading}
                      >
                        Solicitar Suscribirse
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        onClick={(ev) => {
                          setSelectedAcademyBanner(selectedAcademy.name);
                          dispatch(
                            addClick({
                              id: selectedAcademy._id,
                              user: user._id,
                              type: "ACADEMY",
                            })
                          );
                          setAction({
                            action: "cancel-request",
                            data: {
                              _id: academyRequests.find(
                                (request) =>
                                  !!request.active &&
                                  request.academy === selectedAcademy._id &&
                                  request.sentBy === user._id &&
                                  request.status === "PENDING"
                              )?._id,
                            },
                          });
                          dispatch(
                            showModal("confirm-delete-item-secuencial-modal")
                          );
                        }}
                        style={{ borderRadius: "10px" }}
                        options={{
                          size: "md",
                          type: "outline",
                          skin: "danger",
                        }}
                        minwidth="150px"
                      >
                        Cancelar Solicitud
                      </Button>
                    )
                  ) : (
                    <Button
                      type="button"
                      onClick={() => {
                        dispatch(
                          academyActions.addMember({
                            _id: selectedAcademy._id,
                            user: user._id,
                            role: "STUDENT",
                          })
                        );
                        dispatch(
                          addClick({
                            id: selectedAcademy._id,
                            user: user._id,
                            type: "ACADEMY",
                          })
                        );
                      }}
                      options={{
                        size: "md",
                        type: "filled",
                        skin: "lightblue",
                      }}
                      minwidth="150px"
                      style={{ borderRadius: "10px" }}
                      loading={updateAcademyStates.loading}
                    >
                      Suscribirse
                    </Button>
                  )
                ) : (
                  <Button
                    type="button"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      ev.preventDefault();
                      setSelectedAcademyBanner(selectedAcademy.name);
                      setAction({
                        action: "out-academy",
                        data: { _id: selectedAcademy._id, userId: user._id },
                      });
                      dispatch(
                        showModal("confirm-delete-item-secuencial-modal")
                      );
                    }}
                    options={{
                      size: "md",
                      type: "filled",
                      skin: "danger",
                    }}
                    minwidth="150px"
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "#000",
                      borderColor: "#000",
                    }}
                  >
                    Salir
                  </Button>
                )}
                <ShareLink></ShareLink>
              </FlexContainer>
            </FlexContainer>
          </>
        )}
      </BannerWrapper>
      <ModalUpdatePhotos></ModalUpdatePhotos>
      {!!selectedAcademyBanner && action?.action === "cancel-request" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Cancelar Solicitud",
            style: {
              color: "Danger",
              style: { height: "26px", width: "100px" },
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
                loading: "Danger",
              },
            },
          }}
          states={deleteRequestStates}
          title={
            <span>
              {`¿Seguro que quiere cancelar la solicitud a `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {selectedAcademyBanner}
              </span>
              ?
            </span>
          }
          elementActions={deleteRequest(action.data)}
          resetAction={resetDeleteRequest}
          resetState={setSelectedAcademyBanner}
        ></ModalConfirmDelete>
      )}
      {!!selectedAcademy && action?.action === "out-academy" && (
        <ModalConfirmDelete
          description="Perderas todos los accesos y suscripciones relacionados a esta Academia y los contendos que no hayas descargado"
          bntConfig={{
            content: "Salir",
            style: {
              style: {
                height: "26px",
                padding: "15px 0",
                width: "200px",
              },
              options: {
                type: "filled",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={updateAcademyStates}
          title={
            <span>
              {`¿Estas seguro que quieres salir de la academia `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {selectedAcademyBanner}
              </span>
              ?
            </span>
          }
          elementActions={academyActions.deleteMember(action.data)}
          resetState={setAction}
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({ academyStore, userStore, requestStore, invitationStore }) => {
  const { data: selectedAcademy } = academyStore.selected;
  const { data: user } = userStore;
  const { data: requests } = requestStore.allRequests;
  const { states: createRequestStates, data: createdRequest } =
    requestStore.newRequest;
  const { states: deleteRequestStates, data: deletedRequest } =
    requestStore.deleteRequest;
  const { states: updateRequestStates, data: updatedRequest } =
    requestStore.updateRequest;
  const { data: invitations } = invitationStore.all;
  const { states: invitationUpdateStates, data: invitationUpdated } =
    invitationStore.update;
  const { states: updateAcademyStates } = academyStore.update;
  return {
    selectedAcademy,
    user,
    requests,
    invitations,
    deleteRequestStates,
    createRequestStates,
    updateAcademyStates,
    invitationUpdateStates,
    invitationUpdated,
    updateRequestStates,
    updatedRequest,
    deletedRequest,
    createdRequest,
  };
};

export default connect(states)(Component);
/* (
            invitations?.find(
              (invitation) =>
                invitation.academy === selectedAcademy._id &&
                invitation.sentBy === user._id &&
                invitation.status === "PENDING"
            ) ? (
              <Button
                type="button"
                onClick={() =>
                  dispatch(
                    invitationActions.update({
                      condition: "accept",
                      _id: user._id,
                    })
                  )
                }
                options={{
                  size: "md",
                  type: "filled",
                  skin: "violet",
                  loading: "Violet",
                }}
                minwidth="150px"
                style={{ width: "150px", backgroundColor: "#000" }}
              >
                Aceptar Invitacion
              </Button>
            ) : !requests.find((request) => request.sentBy === user._id) ? (
              <Button
                type="button"
                onClick={() =>
                  dispatch(
                    newRequest({
                      academy: selectedAcademy._id,
                      sentBy: user._id,
                    })
                  )
                }
                options={{
                  size: "md",
                  type: "filled",
                  skin: "violet",
                  loading: "Violet",
                }}
                minwidth="150px"
                style={{ width: "150px" }}
              >
                Enviar Solicitud
              </Button>
            ) : (
              <></>
            )
          ) */
