import React, { useEffect, useState } from "react";
import { MdFlashlightOn } from "react-icons/md";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Button from "../../../../components/Button";
import Grid from "../../../../components/Grid";
import {
  GridCard,
  GridCardImage,
  GridCardImageContainer,
  GridCardSmallContainer,
  GridCardTitle,
  GridComment,
  GridStructure,
} from "../../../../components/Layout/Dashboard/styles";
import Loader from "../../../../components/Loader";
import {
  ButtonFilter,
  Center,
  FlexContainer,
  PageTitle,
  Separator,
} from "../../../../components/StyledComponents";
import { DateFromNow } from "../../../../helpers/format-date";
import {
  addViewCdoc,
  getAllCDOCs,
  newCDOCs,
  resetGetAllCdocs,
  resetNewCDOCs,
} from "../../../../store/actions/cdoc";
import { showModal } from "../../../../store/actions/modal";
import { AcademyDto } from "../../../../types/academy.dto";
import { CDocDto } from "../../../../types/cdoc.dto";
import { CohortDto } from "../../../../types/cohort.dto";
import { CurrentDto } from "../../../../types/current.dto";
import { StateDto } from "../../../../types/states.dto";
import { UserDto } from "../../../../types/user.dto";
import FilterButton from "./components/FilterButton";
import FilterByName from "./components/FilterByName";
import { DotContent, ItemDataText } from "./style";
import { useNavigate } from "react-router-dom";
import InfinityScroll from "../../../../components/InfinityScroll";
import academyActions from "../../../../store/actions/academies";
import ToggleSlider from "../../../../components/ToggleSlider";
import { addClick } from "../../../../store/actions/userTimes";

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const filterValues: any = [
  {
    fieldName: ["published", "order"],
    value: "no",
    name: "Todos",
  },
  {
    fieldName: "order",
    value: "recent",
    name: "Recientes",
    groupal: (array: CDocDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "order",
    value: "older",
    name: "Antiguos",
    groupal: (array: CDocDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "published",
    value: true,
    name: "Publicos",
  },
  {
    fieldName: "published",
    value: false,
    name: "Privados",
  },
];
const Component = ({
  cdocs,
  newCdoc,
  cdocsStates,
  newCdocStates,
  cohorts,
  user,
}: {
  cdocs: CDocDto[];
  newCdoc: CDocDto;
  newCdocStates: StateDto;
  cdocsStates: StateDto;
  cohorts: CohortDto[];
  user: UserDto;
}) => {
  const navigate = useNavigate();
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (data?: any, data2?: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [currentCdocsList, setCurrentCdocsList] = useState<any[]>([]);
  const [filteredCurrentCdocsListValues, setFilteredCurrentCdocsListValues] =
    useState<any[]>([]);
  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter((applyedFilter) =>
        !Array.isArray(newFilter.field)
          ? applyedFilter.field === newFilter.field
          : newFilter.field.includes(applyedFilter.field)
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    if (filterRemoved) {
      /**
       * Se elimina el filtro
       */
      if (Array.isArray(newFilter.field)) {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => !newFilter.field.includes(applyedFilter.field)
          ),
        ]);
      } else {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => applyedFilter.field !== newFilter.field
          ),
        ]);
      }
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };
  const dispatch = useDispatch();
  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 3,
      offset: 3 * Math.ceil(page / 2),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        published: true,
      },
    };
    getAllCDOCs(payload);
  };
  useEffect(() => {
    if (!isLoading && !!cdocs) {
      const filterCdocs = cdocs
        .filter((cdoc) => cdoc.active && cdoc.savedBy.includes(user._id))
        .map((cdoc) => {
          return {
            ...cdoc,
          };
        });
      setCurrentCdocsList(filterCdocs);
    }

    setThereAreItems(!!cdocs && cdocs?.length > 0);
  }, [cdocs, isLoading]);

  useEffect(() => {
    setIsLoading(cdocsStates.loading);
  }, [cdocsStates]);

  useEffect(() => {
    const filteredTalents = currentCdocsList?.filter((currentTalentValues) => {
      const thereAreFilters =
        applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
          .length > 0;
      const totalOfFilters = applyedFilters.filter(
        (applyedFilter) => !applyedFilter.groupal
      ).length;
      const passFilters = applyedFilters
        .filter(
          (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
        )
        .filter(
          (applyedFilter) =>
            currentTalentValues[applyedFilter.field] === applyedFilter.value
        ).length;
      const specialFilter = applyedFilters.filter(
        (applyedFilter) => !!applyedFilter.special
      );

      const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
        return applyedFilter.special(currentTalentValues, applyedFilter.value);
      });
      return (
        !thereAreFilters ||
        (thereAreFilters &&
          totalOfFilters === passFilters + specialFilter.length &&
          speacialFilterCheck.length === specialFilter.length)
      );
    });
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentCdocsListValues(initResult);
  }, [applyedFilters, currentCdocsList]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCdocs());
      dispatch(academyActions.resetGetAllAcademies());
    };
  }, []);

  useEffect(() => {
    if (newCdocStates.success) {
      dispatch(resetNewCDOCs());
    }
    if (newCdocStates.error) {
      toast.error(newCdocStates.error);
      dispatch(resetNewCDOCs());
    }
  }, [newCdocStates]);

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <PageTitle>CDocs</PageTitle>
          </Grid.Col>
        </Grid.Row>
        <Center>
          <Grid.Row style={{ width: "100%" }}>
            <Grid.Col lg={12} md={12} sm={12} style={{ marginBottom: "14px" }}>
              <FlexContainer
                style={{ width: "100%", maxWidth: "700px", margin: "0 auto" }}
                justify="space-between"
                align="center"
                gap="14px"
              >
                <FilterByName setFilter={buildFilters}></FilterByName>
              </FlexContainer>
            </Grid.Col>
          </Grid.Row>
        </Center>
        <Separator size={12}></Separator>
        {/*   <ToggleSlider>
          {filterValues.map((filterValue) => {
            return (
              <FilterButton
                setFilter={buildFilters}
                name={filterValue.name}
                fieldName={filterValue.fieldName}
                value={filterValue.value}
                filters={applyedFilters}
                {...((!!filterValue.special || !!filterValue.groupal) && {
                  special: !!filterValue.special
                    ? { name: "special", function: filterValue.special }
                    : { name: "groupal", function: filterValue.groupal },
                })}
              ></FilterButton>
            );
          })}
        </ToggleSlider> */}
        <Separator size={12}></Separator>
        <Grid.Row>
          <Grid.Col>
            {filteredCurrentCdocsListValues.length > 0 ? (
              <InfinityScroll
                action={fetchData}
                page={page}
                setPage={setPage}
                data={filteredCurrentCdocsListValues}
                active={thereAreItems}
                isLoading={cdocsStates.loading}
              >
                <GridStructure
                  width={"300px"}
                  maxWidth="auto"
                  style={{ width: "100%", margin: "0 auto" }}
                >
                  {filteredCurrentCdocsListValues.map((item) => {
                    return (
                      <GridCard
                        onClick={() => {
                          dispatch(
                            addClick({
                              id: item._id,
                              user: user._id,
                              type: "CONTENT",
                            })
                          );
                          navigate(`/dashboard/cdocs/${item._id}`);
                        }}
                        pd={"12px"}
                        key={item._id}
                      >
                        <GridCardImageContainer>
                          <GridCardImage
                            src={
                              item.picture ||
                              "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                            }
                          ></GridCardImage>
                        </GridCardImageContainer>

                        <FlexContainer style={{ marginLeft: "5px" }} gap="12px">
                          <GridCardSmallContainer>
                            <GridCardImage
                              src={
                                item.academy.picture ||
                                "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                              }
                            ></GridCardImage>
                          </GridCardSmallContainer>
                          <FlexContainer direction="column">
                            <GridCardTitle>{item.name}</GridCardTitle>
                            <FlexContainer gap="6px" align="center" wrap="wrap">
                              <FlexContainer gap="2px" align="center">
                                <DotContent></DotContent>
                                <GridComment>
                                  {` Vistas: `}
                                  <GridComment>{item.viewCount}</GridComment>
                                </GridComment>
                              </FlexContainer>
                              <FlexContainer gap="2px" align="center">
                                <DotContent></DotContent>
                                <GridComment>
                                  {DateFromNow(item.createdAt)}
                                </GridComment>
                              </FlexContainer>
                            </FlexContainer>
                          </FlexContainer>
                        </FlexContainer>
                      </GridCard>
                    );
                  })}
                </GridStructure>
              </InfinityScroll>
            ) : (
              <FlexContainer style={{ width: "100%" }} justify="center">
                <h2>No hay elementos</h2>
              </FlexContainer>
            )}
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </>
  );
};

const states = ({ cdocStore, cohortStore, academyStore, userStore }) => {
  const { data: cdocs, states: cdocsStates } = cdocStore.all;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: newCdoc, states: newCdocStates } = cdocStore.new;
  const { data: academies } = academyStore.all;
  const { data: user } = userStore;
  return {
    cdocs,
    newCdoc,
    newCdocStates,
    cohorts,
    academies,
    user,
    cdocsStates,
  };
};

export default connect(states)(Component);
