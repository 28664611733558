import styled from "styled-components";

export const CountNumber = styled("h3")`
  margin: 0;
  padding: 0;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
`;

export const DateString = styled("h3")`
  font-family: Lato;
  font-size: 12px;
  color: #6a6970;
`;

export const ListItem = styled("div")`
  padding: 6px 11px;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(79, 38, 162, 0.16);
`;

export const ListItemTitle = styled("h3")`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.Night};
`;

export const ListItemComment = styled("h5")`
  font-size: 12px;
  color: ${(props) => props.theme.colors.Night};
`;
