import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_ROADMAP,
  GET_ROADMAP_SUCCESS,
  GET_ROADMAP_ERROR,
  GET_ALL_ROADMAPS,
  GET_ALL_ROADMAPS_SUCCESS,
  GET_ALL_ROADMAPS_ERROR,
  NEW_ROADMAP,
  NEW_ROADMAP_ERROR,
  NEW_ROADMAP_SUCCESS,
  NewRoadmap,
  UPDATE_ROADMAP,
  UPDATE_ROADMAP_SUCCESS,
  UPDATE_ROADMAP_ERROR,
  DELETE_ROADMAP,
  DELETE_ROADMAP_SUCCESS,
  DELETE_ROADMAP_ERROR,
  GetRoadmap,
  GetAllRoadmaps,
  UpdateRoadmap,
  DeleteRoadmap,
  UploadMedia,
  UPLOAD_MEDIA,
  AddUserToRoadmap,
  ADD_USER_TO_ROADMAP,
  SharedRoadmap,
  SHARED_ROADMAP,
  SavedRoadmap,
  SAVED_ROADMAP,
  UNSAVED_ROADMAP,
  UnSavedRoadmap,
  QUERY_ROADMAP_SUCCESS,
  QUERY_ROADMAP_ERROR,
  QUERY_ROADMAP,
  AddClickRoadmap,
  ADD_CLICK_ROADMAP,
  UPDATE_CURRENT_ROADMAP_LIST,
  AddViewRoadmap,
  ADD_VIEW_ROADMAP,
  UPDATE_ONE_ROADMAP_ERROR,
  UPDATE_ONE_ROADMAP_SUCCESS,
  SET_GET_ROADMAP,
  ManageLikeDislikeRoadmap,
  MANAGE_LIKE_DISLIKE_ROADMAP,
  GET_ROADMAPS_OFFSET,
  GET_ROADMAPS_OFFSET_ERROR,
  GET_ROADMAPS_OFFSET_SUCCESS,
  GetRoadmapsOffset,
  UPDATE_ITEM_LIST_ROADMAP,
} from "../types/roadmap";

const roadmapList = ({ roadmapStore }) => roadmapStore.all.data;

const roadmapsOffset = ({ roadmapStore }) => roadmapStore.offSet.data;

function* getAllRoadmaps({ payload }) {
  const { response, error } = yield call(services.roadmap.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_ROADMAPS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_ROADMAPS_SUCCESS, payload: response });
  }
}

function* getRoadmap({ payload }) {
  const { response, error } = yield call(services.roadmap.getOne, payload);

  if (error) {
    yield put({
      type: GET_ROADMAP_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ROADMAP_SUCCESS, payload: response });
  }
}

function* addClick({ payload }) {
  const { response, error } = yield call(services.roadmap.addClick, payload);
  /*  if (error) {
  } else {
    const currentListOfRoadmaps = yield select(companyList);
    const newListofRoadmaps = currentListOfRoadmaps?.map((content) => {
      if (content._id === response._id) {
        return response;
      }
      return content;
    });
    yield put({
      type: UPDATE_CURRENT_ROADMAP_LIST,
      payload: newListofRoadmaps,
    });
  } */
}

function* newRoadmap({ payload }) {
  const { response, error } = yield call(services.roadmap.new, payload);

  if (error) {
    yield put({
      type: NEW_ROADMAP_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_ROADMAP_SUCCESS, payload: response })]);
  }
}

function* updateRoadmap({ payload }) {
  const { response, error } = yield call(services.roadmap.update, payload);
  if (error) {
    yield put({
      type: UPDATE_ROADMAP_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_ROADMAP_SUCCESS, payload: response })]);
  }
}

function* saveRoadmap({ payload }) {
  const { response, error } = yield call(services.listen.saveListen, payload);

  if (error) {
    yield put({
      type: UPDATE_ROADMAP_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_ROADMAP_SUCCESS, payload: response });
    if (!!payload.individual) {
      yield put({ type: SET_GET_ROADMAP, payload: response });
    }
  }
}

function* addUserToRoadmap({ payload }) {
  const { response, error } = yield call(
    services.roadmap.addUserRoadmap,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_ROADMAP_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_ROADMAP_SUCCESS, payload: response })]);
  }
}

function* queryRoadmap({ payload }) {
  const { response, error } = yield call(services.roadmap.query, payload);

  if (error) {
    yield put({
      type: QUERY_ROADMAP_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: QUERY_ROADMAP_SUCCESS, payload: response });
  }
}

function* uploadMedia({ payload }) {
  const { response, error } = yield call(services.roadmap.update, payload);

  if (error) {
    yield put({
      type: UPDATE_ROADMAP_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_ROADMAP_SUCCESS, payload: response })]);
  }
}

function* deleteRoadmap({ payload }) {
  const { response, error } = yield call(services.roadmap.delete, payload);

  if (error) {
    yield put({
      type: DELETE_ROADMAP_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_ROADMAP_SUCCESS, payload: response })]);
  }
}

function* manageContent({ payload }) {
  const { response, error } = yield call(
    services.roadmap.manageContent,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_ROADMAP_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_ROADMAP_SUCCESS, payload: response });
    if (!!payload.individual) {
      yield put({ type: SET_GET_ROADMAP, payload: response });
    }
  }
}

function* shareRoadmap({ payload }) {
  const { response, error } = yield call(
    services.roadmap.shareRoadmap,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_ROADMAP_ERROR,
      payload: error,
    });
  } else {
    yield all([put({ type: UPDATE_ROADMAP_SUCCESS, payload: response })]);
  }
}

function* addView({ payload }) {
  const { response, error } = yield call(services.roadmap.addView, payload);

  /*  if (error) {
    yield put({
      type: UPDATE_ROADMAP_ERROR,
      payload: error,
    });
  } else {
    yield all([put({ type: UPDATE_ROADMAP_SUCCESS, payload: response })]);
  } */
}

function* manageLike({ payload }) {
  const { response, error } = yield call(services.roadmap.manageLike, payload);
  if (error) {
    yield put({
      type: UPDATE_ONE_ROADMAP_ERROR,
      payload: error,
    });
  } else {
    yield all([
      put({ type: UPDATE_ONE_ROADMAP_SUCCESS, payload: response }),
      put({ type: SET_GET_ROADMAP, payload: response }),
    ]);
  }
}
function* manageDislike({ payload }) {
  const { response, error } = yield call(
    services.roadmap.manageDislike,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_ONE_ROADMAP_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_ONE_ROADMAP_SUCCESS, payload: response }),
      put({ type: SET_GET_ROADMAP, payload: response }),
    ]);
  }
}
function* manageLikeDislike({ payload }) {
  const { response, error } = yield call(
    services.roadmap.manageLikeDislike,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_ROADMAP_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_ROADMAP_SUCCESS, payload: response });

    if (payload.individual) {
      yield put({ type: SET_GET_ROADMAP, payload: response });
    }
  }
}

function* getRoadmapsOffset({ payload }) {
  const { response, error } = yield call(services.roadmap.getOffset, payload);
  if (error) {
    yield put({
      type: GET_ROADMAPS_OFFSET_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const offsetRoadmaps = yield select(roadmapsOffset);
    const newOffsetRoadmaps = !offsetRoadmaps
      ? response
      : [...offsetRoadmaps, ...response];
    yield put({
      type: GET_ROADMAPS_OFFSET_SUCCESS,
      payload: newOffsetRoadmaps,
    });
  }
}

function* updateRoadmapListItem({ payload }) {
  const { response, error } = yield call(services.roadmap.update, payload);

  if (error) {
    yield put({
      type: UPDATE_ROADMAP_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_ROADMAP_SUCCESS, payload: response }),
      put({ type: SET_GET_ROADMAP, payload: response }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllRoadmaps>(GET_ALL_ROADMAPS, getAllRoadmaps);
  yield takeLatest<GetRoadmap>(GET_ROADMAP, getRoadmap);
  yield takeLatest<NewRoadmap>(NEW_ROADMAP, newRoadmap);
  yield takeLatest<UpdateRoadmap>(UPDATE_ROADMAP, updateRoadmap);
  yield takeLatest<DeleteRoadmap>(DELETE_ROADMAP, deleteRoadmap);
  yield takeLatest<UploadMedia>(UPLOAD_MEDIA, uploadMedia);
  yield takeLatest<AddUserToRoadmap>(ADD_USER_TO_ROADMAP, addUserToRoadmap);
  yield takeLatest<SharedRoadmap>(SHARED_ROADMAP, shareRoadmap);
  yield takeLatest<SavedRoadmap>(SAVED_ROADMAP, manageContent);
  yield takeLatest<UnSavedRoadmap>(UNSAVED_ROADMAP, manageContent);
  yield takeLatest<UnSavedRoadmap>(QUERY_ROADMAP, queryRoadmap);
  yield takeLatest<AddClickRoadmap>(ADD_CLICK_ROADMAP, addClick);
  yield takeLatest<AddViewRoadmap>(ADD_VIEW_ROADMAP, addView);
  yield takeLatest<ManageLikeDislikeRoadmap>(
    MANAGE_LIKE_DISLIKE_ROADMAP,
    manageLikeDislike
  );
  yield takeLatest<GetRoadmapsOffset>(GET_ROADMAPS_OFFSET, getRoadmapsOffset);
  yield takeLatest<any>(UPDATE_ITEM_LIST_ROADMAP, updateRoadmapListItem);
}
