import {
  GetAllListens,
  GET_ALL_LISTENS,
  GetListen,
  GET_LISTEN,
  UpdateListen,
  UPDATE_LISTEN,
  DeleteListen,
  DELETE_LISTEN,
  GetListenSuccess,
  GET_LISTEN_SUCCESS,
  NewListen,
  NEW_LISTEN,
  ResetNewListen,
  RESET_NEW_LISTEN,
  ResetUpdateListen,
  RESET_UPDATE_LISTEN,
  ResetDeleteListen,
  RESET_DELETE_LISTEN,
  UploadMedia,
  UPLOAD_MEDIA,
  RESET_GET_LISTEN,
  ADD_USER_TO_LISTEN,
  SHARED_LISTEN,
  QUERY_LISTEN,
  RESET_QUERY_LISTEN,
  SaveListen,
  SAVE_LISTEN,
  UNSAVED_LISTEN,
  SAVED_LISTEN,
  ADD_VIEW_LISTEN,
  AddViewListen,
  ADD_CLICK_LISTEN,
  MANAGE_LIKE_DISLIKE_LISTEN,
  GET_LISTENS_OFFSET,
  GetListensOffset,
  RESET_GET_LISTENS_OFFSET,
  ResetGetListensOffset,
  RESET_GET_ALL_LISTEN_DATA,
  RESET_GET_ONE_LISTEN_DATA,
  UPDATE_ITEM_LIST_LISTEN,
} from "../types/listen";

export const getAllListens = (payload): GetAllListens => {
  return { type: GET_ALL_LISTENS, payload };
};

export const updateListenListItem = (payload) => {
  return { type: UPDATE_ITEM_LIST_LISTEN, payload };
};

export const getListen = (id): GetListen => {
  return { type: GET_LISTEN, payload: id };
};

export const saveListen = (payload): SaveListen => {
  return { type: SAVE_LISTEN, payload };
};

export const unSavedListen = (payload) => {
  return { type: UNSAVED_LISTEN, payload };
};
export const savedListen = (payload) => {
  return { type: SAVED_LISTEN, payload };
};

export const resetGetAllListens = () => {
  return { type: RESET_GET_ALL_LISTEN_DATA, payload: null };
};

export const resetGetOneListen = () => {
  return { type: RESET_GET_ONE_LISTEN_DATA, payload: null };
};

export const resetGetListen = () => {
  return { type: RESET_GET_LISTEN, payload: null };
};

export const setSelectedListen = (payload): GetListenSuccess => {
  return { type: GET_LISTEN_SUCCESS, payload };
};

export const newListen = (payload): NewListen => {
  return { type: NEW_LISTEN, payload };
};
export const uploadMediaListen = (payload): UploadMedia => {
  return { type: UPLOAD_MEDIA, payload };
};
export const resetNewListen = (): ResetNewListen => {
  return { type: RESET_NEW_LISTEN, payload: null };
};

export const updateListen = (payload): UpdateListen => {
  return { type: UPDATE_LISTEN, payload };
};

export const resetUpdateListen = (): ResetUpdateListen => {
  return { type: RESET_UPDATE_LISTEN, payload: null };
};

export const addUserToListen = (payload) => {
  return { type: ADD_USER_TO_LISTEN, payload };
};

export const offSetListens = (payload): GetListensOffset => {
  return { type: GET_LISTENS_OFFSET, payload };
};

export const resetOffsetListens = (): ResetGetListensOffset => {
  return { type: RESET_GET_LISTENS_OFFSET, payload: null };
};

export const deleteListen = (payload): DeleteListen => {
  return { type: DELETE_LISTEN, payload };
};

export const resetDeleteListen = (): ResetDeleteListen => {
  return { type: RESET_DELETE_LISTEN, payload: null };
};

export const shareListen = (payload) => {
  return { type: SHARED_LISTEN, payload };
};

export const queryListen = (payload) => {
  return { type: QUERY_LISTEN, payload };
};

export const addViewListen = (payload): AddViewListen => {
  return { type: ADD_VIEW_LISTEN, payload };
};

export const resetQueryListen = () => {
  return { type: RESET_QUERY_LISTEN, payload: null };
};

export const addClickListen = (payload) => {
  return { type: ADD_CLICK_LISTEN, payload };
};

export const manageLikeDislikeListen = (payload) => {
  return { type: MANAGE_LIKE_DISLIKE_LISTEN, payload };
};
