import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CohortDto } from "../../../../../../../types/cohort.dto";
import Carousel from "../../../../../../../components/AsynCarousel";
import { BannerTitle } from "../../../../../components/Banner/style";
import {
  Slide,
  SlideImg,
  SlideSubTitle,
  SlideTitle,
  WrapperSlide,
} from "../../../../../../../components/Slider/style";
import {
  addClick as addClickCohort,
  addRating,
  getAllCohorts,
  getCohortsByAcademy,
} from "../../../../../../../store/actions/cohort";
import { useWindowSize } from "../../../../../../../hooks/useWindowScreen";
import { useEffect, useState } from "react";
import { AcademyDto } from "../../../../../../../types/academy.dto";
import { addClick } from "../../../../../../../store/actions/userTimes";
import { UserDto } from "../../../../../../../types/user.dto";
import {
  FlexContainer,
  Separator,
} from "../../../../../../../components/StyledComponents";
import { BsFillCalendar2XFill, BsFillCalendarCheckFill } from "react-icons/bs";
import Rating from "../../../../../../../components/Rating";
import { formatNumber } from "../../../../../../../helpers/number-fixed";

const DEFAULT_IMAGE =
  "https://repository-images.githubusercontent.com/410214337/070f2aba-d9d6-4699-b887-9a0f29015b1b";
const ELEMENTS_PER_PAGE = 3;

interface FetchProps {
  page: number;
  filterBy?: { [key: string]: string | number | boolean | null };
}

interface ComponentProps {
  cohorts: CohortDto[];
  cohortsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  academy: AcademyDto;
  user: UserDto;
}

const Component = ({
  cohorts,
  cohortsStates,
  academy,
  user,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [items, setItems] = useState<CohortDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const getCohorts = ({ page, filterBy }: FetchProps) => {
    dispatch(
      getCohortsByAcademy({
        _id: academy._id,
        page,
        limit: ELEMENTS_PER_PAGE,
        offset: ELEMENTS_PER_PAGE * page,
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          hidden: false,
          academy: academy?._id,
        },
        select: {
          _id: 1,
          picture: 1,
          studentsIds: 1,
          viewCount: 1,
          name: 1,
          startDate: 1,
          endDate: 1,
          ratings: 1,
        },
      })
    );
  };

  useEffect(() => {
    setIsLoading(cohortsStates.loading);
  }, [cohortsStates.loading]);

  useEffect(() => {
    if (!isLoading) {
      setItems([...items, ...(cohorts && cohorts.length > 0 ? cohorts : [])]);
      setThereAreItems(
        !!cohorts &&
          cohorts?.length > 0 &&
          cohorts?.length === ELEMENTS_PER_PAGE
      );
    }
  }, [cohorts, isLoading]);

  return (
    <>
      {isMobile && <Separator size={11}></Separator>}
      <BannerTitle style={{ fontWeight: "bold" }} size="16px">
        Próximos Cohorts
      </BannerTitle>
      <Separator size={10}></Separator>
      <div style={{ padding: "0px 20px" }}>
        <Carousel
          data={cohorts}
          isLoading={isLoading}
          active={thereAreItems}
          action={getCohorts}
          setPage={setPage}
          page={page}
        >
          {items.map((cohort) => (
            <WrapperSlide>
              <Slide
                maxWidth={"278px"}
                key={cohort._id}
                onClick={() => {
                  dispatch(
                    addClick({ id: cohort._id, user: user._id, type: "COHORT" })
                  );
                  navigate(`/dashboard/cohort/${cohort._id}/home`);
                }}
              >
                <SlideImg
                  {...{ style: { height: "80px" } }}
                  src={cohort.picture ?? DEFAULT_IMAGE}
                ></SlideImg>
                <SlideTitle style={{ fontSize: "14px", color: "#051725" }}>
                  {cohort.name}
                </SlideTitle>
                {!isMobile && <Separator size={11}></Separator>}
                <FlexContainer gap="4px" align="center" wrap="wrap">
                  <FlexContainer gap="2px" align="center">
                    <SlideSubTitle bold>Suscriptores:</SlideSubTitle>
                    <SlideSubTitle>{cohort.studentsIds.length}</SlideSubTitle>
                  </FlexContainer>
                  <span>|</span>
                  <FlexContainer gap="2px" align="center">
                    <SlideSubTitle bold>Vistas:</SlideSubTitle>
                    <SlideSubTitle>{cohort.viewCount}</SlideSubTitle>
                  </FlexContainer>
                </FlexContainer>
                <FlexContainer justify="space-between" wrap="wrap">
                  <FlexContainer gap="7px" direction="column">
                    {cohort.startDate && cohort.type === "SYNCHRONOUS" && (
                      <FlexContainer gap="6px" align="center">
                        <BsFillCalendarCheckFill
                          size={12}
                        ></BsFillCalendarCheckFill>
                        <SlideSubTitle>
                          {`Inicio: ${new Date(
                            cohort.startDate
                          ).toLocaleDateString("en-GB")}`}
                        </SlideSubTitle>
                      </FlexContainer>
                    )}
                    {cohort.endDate && cohort.type === "SYNCHRONOUS" && (
                      <FlexContainer gap="6px" align="center">
                        <BsFillCalendar2XFill size={12}></BsFillCalendar2XFill>
                        <SlideSubTitle>
                          {`FInal: ${new Date(
                            cohort.endDate
                          ).toLocaleDateString("en-GB")}`}
                        </SlideSubTitle>
                      </FlexContainer>
                    )}
                  </FlexContainer>

                  <FlexContainer direction="column" gap="4px" align="center">
                    <span style={{ color: "#051725", fontSize: "12px" }}>
                      {cohort.ratings.length > 0
                        ? formatNumber(
                            cohort.ratings.reduce((prev, next) => {
                              return prev + next.stars;
                            }, 0) / cohort.ratings.length
                          )
                        : 0}
                    </span>
                    <Rating
                      starSize={15}
                      staticRating
                      color={{ filled: "#2496ed", unfilled: "transparent" }}
                      count={5}
                      passiveValue={
                        cohort.ratings.length > 0
                          ? formatNumber(
                              cohort.ratings.reduce((prev, next) => {
                                return prev + next.stars;
                              }, 0) / cohort.ratings.length
                            )
                          : 0
                      }
                      rating={0}
                      onRating={(rate) => {}}
                    ></Rating>
                  </FlexContainer>
                </FlexContainer>
              </Slide>
            </WrapperSlide>
          ))}
        </Carousel>
      </div>
    </>
  );
};

const states = ({ cohortStore, academyStore, userStore }) => {
  const { data: cohorts, states: cohortsStates } = cohortStore.allCohorts;
  const { data: academy } = academyStore.selected;
  const { data: user } = userStore;
  return {
    cohorts,
    cohortsStates,
    academy,
    user,
  };
};

export default connect(states)(Component);
