import React, { useEffect, useState } from "react";

import {
  Center,
  FlexContainer,
  Separator,
} from "../../../components/StyledComponents";
import EmptyState from "./components/EmptyState";
import { connect, useDispatch } from "react-redux";
import { AcademyDto } from "../../../types/academy.dto";
import academyActions from "../../../store/actions/academies";
import FilterByName from "./components/FilterByName";
import {
  deleteRequest,
  resetDeleteRequest,
} from "../../../store/actions/request";
import Loader from "../../../components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import ModalConfirmDelete from "../../../components/ModalConfirmDelete";
import { StateDto } from "../../../types/states.dto";
import { showModal } from "../../../store/actions/modal";
import MediaItems from "./components/MediaItems";
import Filters from "./components/Filters";
import { VideoDto } from "../../../types/video.dto";
import {
  addClickVideo,
  getAllVideos,
  resetGetAllVideos,
} from "../../../store/actions/video";
import Grid from "../../../components/Grid";
import { SlideShowDto } from "../../../types/slideshow.dto";
import {
  getAllSlideShows,
  resetGetAllSlideShows,
} from "../../../store/actions/slideshow";
import { ImageDto } from "../../../types/image.dto";
import { getAllImages, resetGetAllImages } from "../../../store/actions/image";
import InfiniteScroll from "../../../components/InfinityScroll";
import { PaginationDto } from "../../../types/pagination.dto";
import { getCookie, setCookie } from "../../../helpers/cookies-handler";
import VideoHistory from "./components/VideoHistory";
import SlideshowHistory from "./components/SlideshowHistory";
import AcademyHistory from "./components/AcademyHistory";
import { UserDto } from "../../../types/user.dto";
import { addClick } from "../../../store/actions/userTimes";
import { BsFillPlayFill, BsFillImageFill } from "react-icons/bs";
import { HiPresentationChartBar } from "react-icons/hi";
import moment from "moment";
import { secondsToHHMMSS } from "../../../helpers/format-date";
import { useWindowSize } from "../../../hooks/useWindowScreen";
export interface Filter {
  [key: string]: string;
}

const cookieName = "DEVPLACE_DASHBOARD_HOME_ADD_LIKES";

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

interface ListItems {
  academies?: AcademyDto[];
  videos?: VideoDto[];
  slideshows?: SlideShowDto[];
  images?: ImageDto[];
}

interface ComponentProps {
  updateAcademyStates: StateDto;
  deleteRequestStates: StateDto;
  academies: AcademyDto[];
  academiesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  videos: VideoDto[];
  videosStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  slideshows: SlideShowDto[];
  slideshowsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  images: ImageDto[];
  imagesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  user: UserDto;
}

const Component = ({
  updateAcademyStates,
  deleteRequestStates,
  academies,
  academiesStates,
  videos,
  videosStates,
  slideshows,
  slideshowsStates,
  images,
  imagesStates,
  user,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryMode, setQueryMode] = useState<string | null>(null);
  const [query, setQuery] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [items, setItems] = useState<ListItems[]>([]);
  const [filteredItems, setFilteredItems] = useState<ListItems[]>([]);
  const [appliedFilters, setAppliedFilters] = useState<Filter>({});
  const [action, setAction] = useState<{ action: string; data: any }>();
  const [selectedAcademy, setSelectedAcademy] = useState<string>();
  const location = useLocation();
  const { isMobile } = useWindowSize();
  const fetchData = ({ page, filterBy }: FetchData) => {
    const addPaginationByLikes = getCookie(cookieName) === "true";

    let payload: { [key: string]: PaginationDto } = {
      videos: {
        limit: 3,
        offset: 3 * Math.floor(page / 2),
        filterBy: {
          ...(filterBy ? filterBy : {}),
          url: { $exists: true },
          active: true,
          available: true,
          published: true,
        },
      },
      slideshows: {
        limit: 3,
        offset: 3 * Math.floor(page / 2),
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          available: true,
          published: true,
        },
      },
      academies: {
        limit: isMobile ? 4 : 5,
        offset: (isMobile ? 4 : 5) * Math.floor(page / 2),
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
        },
      },
      images: {
        limit: 3,
        offset: 3 * Math.floor(page / 2),
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          available: true,
          published: true,
        },
      },
    };

    // Calculate the scenari
    const scenario = addPaginationByLikes ? (page - 1) % 3 : (page - 1) % 2;

    //sortBy default value
    let sortBy = "createdAt";

    // Identify the scenario based on the remainder value and set the sortBy
    if (addPaginationByLikes) {
      if (scenario === 0) {
        sortBy = "likes";
      } else if (scenario === 1) {
        sortBy = "createdAt";
      } else if (scenario === 2) {
        sortBy = "viewCount";
      }
    } else {
      if (scenario === 0) {
        sortBy = "createdAt";
      } else if (scenario === 1) {
        sortBy = "viewCount";
      }
    }

    const sortDirection = "desc";
    const select = {
      _id: 1,
      name: 1,
      picture: 1,
      viewCount: 1,
      type: 1,
      createdAt: 1,
      duration: 1,
      slideCount: 1,
    };

    payload = Object.entries(payload).reduce((acc, [key, value]) => {
      acc[key] = {
        ...value,
        sortBy,
        sortDirection,
        select,
        ...(queryMode && { keyword: query }),
      };
      if (key === "academies") {
        acc[key] = {
          ...value,
          sortBy,
          sortDirection,
          select: {
            _id: 1,
            name: 1,
            picture: 1,
            users: 1,
            bannerPic: 1,
            userCount: 1,
          },
        };
      }
      return acc;
    }, {});

    dispatch(getAllVideos(payload.videos));
    dispatch(getAllSlideShows(payload.slideshows));
    dispatch(academyActions.getAll(payload.academies));
    dispatch(getAllImages(payload.images));
  };
  const handleQuery = (ev) => {
    const value = ev.target.value;
    setQuery(value.length === 0 ? "" : value);
  };
  const searchQuery = (ev) => {
    if (ev.keyCode === 13) {
      /*  dispatch(queryImage({ keyword: query })); */
      setQueryMode(query);
    }
  };

  useEffect(() => {
    if (location.state) {
      if ((location.state as any)?.denied) {
        dispatch(showModal("sign-in-content"));
      }
    }
  }, [location.state]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllVideos());
      dispatch(resetGetAllSlideShows());
      dispatch(academyActions.resetGetAllAcademies());
      dispatch(resetGetAllImages());
    };
  }, [dispatch]);

  useEffect(() => {
    if (items && items.length > 0) {
      const filteredList = () => {
        // If type is 'all' or name is 'all', return the original items array
        if (
          (!appliedFilters.type || appliedFilters.type === "all") &&
          (!appliedFilters.name || appliedFilters.name === "all")
        ) {
          return [...items];
        }

        // Create an empty array to store the filtered pages
        let filteredPages: ListItems[] = [...items];

        if (appliedFilters.type && appliedFilters.type !== "all") {
          filteredPages = [];
          let filteredPageType:
            | AcademyDto[]
            | VideoDto[]
            | SlideShowDto[]
            | ImageDto[] = [];

          for (const item of items) {
            // Check if the key exists in the item
            if (appliedFilters.type in item) {
              // Add all items of the specified key to the filteredPages array
              if (item[appliedFilters.type]) {
                filteredPageType.push(...item[appliedFilters.type]);
              }
            }
          }

          const elements = { [appliedFilters.type]: filteredPageType };
          filteredPages = [elements];
        }

        if (appliedFilters.name && appliedFilters.name !== "all") {
          // Iterate over each object in the items array
          for (const [index, filteredPage] of Object.entries(filteredPages)) {
            for (const [key, elements] of Object.entries(filteredPage)) {
              filteredPages[index] = {
                ...filteredPages[index],
                [key]: elements.filter((element) =>
                  element.name
                    .toLowerCase()
                    .includes(appliedFilters.name.toLowerCase())
                ),
              };
            }
          }
        }

        return filteredPages;
      };

      setFilteredItems(filteredList());
    }
  }, [appliedFilters, items]);
  console.log(thereAreItems, "ppooax");
  useEffect(() => {
    if (!isLoading && !!academies && !!videos && !!images && !!slideshows) {
      setItems([
        ...items,
        {
          ...(academies && academies.length > 0 ? { academies } : {}),
          ...(videos && videos.length > 0 ? { videos } : {}),
          ...(images && images.length > 0 ? { images } : {}),
          ...(slideshows && slideshows.length > 0 ? { slideshows } : {}),
        },
      ]);
      setThereAreItems(
        (!!academies && academies?.length > 0) ||
          (!!videos && videos?.length > 0) ||
          (!!images && images?.length > 0) ||
          (!!slideshows && slideshows?.length > 0)
      );
    }
  }, [academies, videos, images, slideshows, isLoading]);

  useEffect(() => {
    setIsLoading(
      academiesStates.loading ||
        videosStates.loading ||
        slideshowsStates.loading ||
        imagesStates.loading
    );
  }, [academiesStates, videosStates, imagesStates, slideshowsStates]);

  useEffect(() => {
    if (!!selectedAcademy && !!!action) {
      dispatch(showModal("confirm-delete-item-modal"));
    }
  }, [selectedAcademy, action]);

  const saveBahaviorCookie = () => {
    const cookieValue =
      getCookie(cookieName) && getCookie(cookieName) === "true";
    setCookie(cookieName, !cookieValue);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", saveBahaviorCookie);

    return () => {
      saveBahaviorCookie();
      window.removeEventListener("beforeunload", saveBahaviorCookie);
    };
  }, []);
  useEffect(() => {
    if (queryMode) {
      setPage(0);
      setIsLoading(false);
      setThereAreItems(true);
      setItems([]);
      setFilteredItems([]);
    }
  }, [queryMode]);

  return (
    <>
      <Grid.Container>
        <Center>
          <FlexContainer
            style={{
              width: "100%",
              maxWidth: "700px",
              margin: "0 auto",
            }}
          >
            <FilterByName
              searchQuery={searchQuery}
              setFilter={handleQuery}
              value={query}
            />
          </FlexContainer>
        </Center>

        <Separator size={12} />

        <Filters
          setFilter={setAppliedFilters}
          appliedFilters={appliedFilters}
        />
        <div style={{ height: "35px" }} />
        {!queryMode && user && (
          <>
            {appliedFilters.type === "all" ? (
              <>
                <VideoHistory />
                <SlideshowHistory />
                <AcademyHistory />
              </>
            ) : (
              <>
                {appliedFilters.type === "videos" && <VideoHistory />}
                {appliedFilters.type === "slideshows" && <SlideshowHistory />}
                {appliedFilters.type === "academies" && <AcademyHistory />}
              </>
            )}
          </>
        )}
        <InfiniteScroll
          action={fetchData}
          page={page}
          setPage={setPage}
          data={filteredItems}
          active={thereAreItems}
          isLoading={isLoading}
          loader={
            <>
              <div style={{ height: 20 }} />
              <EmptyState />
            </>
          }
        >
          <FlexContainer direction="column" gap="20px">
            {(!filteredItems || filteredItems.length === 0) && isLoading ? (
              <EmptyState />
            ) : !filteredItems ? (
              <h2 style={{ textAlign: "center" }}>No hay elementos</h2>
            ) : (
              filteredItems.map((page) => (
                <>
                  {!!page?.videos && page?.videos?.length > 0 && (
                    <MediaItems
                      style={{ margin: "0 auto" }}
                      size="lg"
                      items={page?.videos.map((video) => {
                        return {
                          ...video,
                          icon: BsFillPlayFill,
                          ...(video.duration && {
                            bottomLabel: secondsToHHMMSS(video.duration),
                          }),
                        };
                      })}
                      title={"Videos"}
                      onClick={(event, item) => {
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                      }}
                    />
                  )}
                  {!!page?.slideshows && page?.slideshows?.length > 0 && (
                    <MediaItems
                      style={{ margin: "0 auto" }}
                      size="lg"
                      items={page?.slideshows.map((slideshow) => {
                        return {
                          ...slideshow,
                          icon: HiPresentationChartBar,
                          ...(slideshow.slideCount && {
                            bottomLabel: `${slideshow.slideCount} paginas`,
                          }),
                        };
                      })}
                      title={"SlideShows"}
                      onClick={(event, item) => {
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                      }}
                    />
                  )}
                  {!!page?.academies && page?.academies?.length > 0 && (
                    <MediaItems
                      style={{ margin: 0 }}
                      size="md"
                      items={page?.academies}
                      title={"Academias"}
                      onClick={(event, item) => {
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "ACADEMY",
                          })
                        );
                      }}
                    />
                  )}
                  {!!page?.images && page?.images?.length > 0 && (
                    <MediaItems
                      style={{ margin: "0 auto" }}
                      size="lg"
                      items={page?.images.map((image) => {
                        return {
                          ...image,
                          icon: BsFillImageFill,
                        };
                      })}
                      title={"Imágenes"}
                      onClick={(event, item) => {
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                      }}
                    />
                  )}
                </>
              ))
            )}
          </FlexContainer>
        </InfiniteScroll>
      </Grid.Container>

      {!!selectedAcademy && action?.action === "cancel-request" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Quitar",
            style: {
              color: "Danger",
              style: { height: "26px" },
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
                loading: "Danger",
              },
            },
          }}
          states={deleteRequestStates}
          title={
            <span>
              {`¿Seguro que quiere cancelar la solicitud a `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {selectedAcademy}
              </span>
              ?
            </span>
          }
          elementActions={deleteRequest(action.data)}
          resetAction={resetDeleteRequest}
          resetState={setSelectedAcademy}
        />
      )}

      {!!selectedAcademy && action?.action === "out-academy" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Salir",
            style: {
              style: {
                height: "26px",
                backgroundColor: "#000",
                borderColor: "#000",
                color: "#fff",
              },
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={updateAcademyStates}
          title={
            <span>
              {`¿Estas seguro que quieres salir de la academia `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {selectedAcademy}
              </span>
              ?
            </span>
          }
          elementActions={academyActions.deleteMember(action.data)}
          resetAction={academyActions.resetUpdate}
          resetState={setSelectedAcademy}
        />
      )}
    </>
  );
};

const states = ({
  academyStore,
  requestStore,
  videoStore,
  slideshowStore,
  imageStore,
  userStore,
}) => {
  const { states: updateAcademyStates } = academyStore.update;
  const { states: deleteRequestStates } = requestStore.deleteRequest;
  const { data: academies, states: academiesStates } = academyStore.all;
  const { data: videos, states: videosStates } = videoStore.all;
  const { data: slideshows, states: slideshowsStates } = slideshowStore.all;
  const { data: images, states: imagesStates } = imageStore.all;
  const { data: user } = userStore;
  return {
    updateAcademyStates,
    deleteRequestStates,
    academies,
    academiesStates,
    videos,
    videosStates,
    slideshows,
    slideshowsStates,
    images,
    imagesStates,
    user,
  };
};

export default connect(states)(Component);
