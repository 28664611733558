import { put, call, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  NEW_COMMENT,
  NEW_COMMENT_ERROR,
  NEW_COMMENT_SUCCESS,
  NewComment,
  GET_ALL_COMMENTS,
  GET_ALL_COMMENTS_ERROR,
  GET_ALL_COMMENTS_SUCCESS,
  GetAllComments,
  UPDATE_COMMENT,
  UPDATE_COMMENT_ERROR,
  UPDATE_COMMENT_SUCCESS,
  UpdateComment,
  DELETE_COMMENT,
  UPDATE_CURRENT_COMMENT_LIST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_ERROR,
  ManageLikeDislikeComment,
  MANAGE_LIKE_DISLIKE_COMMENT,
  GET_REPLIES_ERROR,
  GET_REPLIES_SUCCESS,
  GET_REPLIES,
} from "../types/comment";

const companyList = ({ commentStore }) => commentStore.all.data;

function* newComment({ payload }) {
  const { response, error } = yield call(services.comment.new, payload);

  if (error) {
    yield put({
      type: NEW_COMMENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const currentListOfTechsComments = yield select(companyList);
    const newListofTechsComments = !!currentListOfTechsComments
      ? [...currentListOfTechsComments, response]
      : null;
    /*    yield put({
      type: UPDATE_CURRENT_COMMENT_LIST,
      payload: newListofTechsComments,
    }); */
    yield put({ type: NEW_COMMENT_SUCCESS, payload: response });
  }
}

function* getCommentReplies({ payload }) {
  const { response, error } = yield call(services.comment.getReplies, payload);
  if (error) {
    yield put({
      type: GET_REPLIES_ERROR,
      payload: {
        error: error.response?.data?.message || "Error",
        queueId: payload._id,
      },
    });
  } else {
    yield put({
      type: GET_REPLIES_SUCCESS,
      payload: { replies: response, queueId: payload._id },
    });
  }
}

function* deleteComment({ payload }) {
  const { response, error } = yield call(services.comment.delete, payload);

  if (error) {
    yield put({
      type: DELETE_COMMENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: DELETE_COMMENT_SUCCESS, payload: response });
  }
}

function* getAllComments({ payload }) {
  const { response, error } = yield call(services.comment.getAll, payload);
  if (error) {
    yield put({
      type: GET_ALL_COMMENTS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_COMMENTS_SUCCESS, payload: response });
  }
}

function* updatedComment({ payload }) {
  const { response, error } = yield call(services.comment.update, payload);

  if (error) {
    yield put({
      type: UPDATE_COMMENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_COMMENT_SUCCESS, payload: response });
  }
}

function* manageLikeDislike({ payload }) {
  const { response, error } = yield call(
    services.comment.manageLikeDislike,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_COMMENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_COMMENT_SUCCESS, payload: response });

    /*   yield put({
      type: UPDATE_CURRENT_COMMENT_LIST,
      payload: newListofCohorts,
    }); */
    /*   if (!!payload.individual) {
      yield put({ type: SET_GET_COMMENT, payload: response });
    } */
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewComment>(NEW_COMMENT, newComment);
  yield takeLatest<GetAllComments>(GET_ALL_COMMENTS, getAllComments);
  yield takeLatest<GetAllComments>(GET_REPLIES, getCommentReplies);
  yield takeLatest<UpdateComment>(UPDATE_COMMENT, updatedComment);
  yield takeLatest<UpdateComment>(DELETE_COMMENT, deleteComment);
  yield takeLatest<ManageLikeDislikeComment>(
    MANAGE_LIKE_DISLIKE_COMMENT,
    manageLikeDislike
  );
}
