import services from ".";
import action from "../helpers/rest-client";

export class ErrorImage extends Error {}

class Image {
  public new(payload) {
    return action
      .Post({
        url: "/contents",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public update({ _id, ...rest }) {
    return action
      .Patch({
        url: `/contents/${_id}`,
        body: rest.body,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll(payload) {
    const token = services.auth.getToken();
    if (payload.filterBy) {
      const newFilterBy = { ...payload.filterBy, type: "IMAGE" };
      payload.filterBy = JSON.stringify(newFilterBy);
    }
    if (payload.select) {
      payload.select = JSON.stringify(payload.select);
    }
    const queryString = new URLSearchParams(payload).toString();

    return action
      .Get({
        url: `/contents${token ? "" : "/guest/"}?platform=CAMPUS${
          queryString ? "&" + queryString : ""
        }`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne({ _id }) {
    return action
      .Get({
        url: `/contents/${_id}?platform=CAMPUS`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getOffset({ offset, limit }) {
    return action
      .Get({
        url: `/contents?platform=CAMPUS&limit=${limit}&offset=${offset}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public addUserImage({ _id, ...rest }) {
    return action
      .Patch({
        url: `/contents/add-user/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public shareImage({ _id, ...rest }) {
    return action
      .Post({
        url: `/contents/share/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public addClick({ _id }) {
    return action
      .Patch({ url: `/contents/add-click/${_id}` })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public saveImage({ _id, condition, __v }) {
    return action
      .Patch({
        url: `/contents/${condition}/${_id}`,
        body: { __v },
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public manageLikeDislike({ _id, ...rest }) {
    return action
      .Patch({
        url: `/contents/${rest.action}-${rest.condition}/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public addView({ _id }) {
    return action
      .Patch({
        url: `/contents/add-view/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public manageLike({ _id, condition }) {
    return action
      .Patch({
        url: `/contents/${condition}-like/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public manageDislike({ _id, condition }) {
    return action
      .Patch({
        url: `/contents/${condition}-dislike/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public query({ keyword }) {
    return action
      .Get({
        url: `/contents/keyword?keyword=${keyword}&platform=CAMPUS`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public delete({ _id }) {
    return action
      .Del({
        url: `/contents/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const video = new Image();
export default video;
