export const analyticTypes = (stages?) => {
  return [
    { label: "Status", value: "status" },
    { label: "Crecimiento", value: "crecimiento" },
  ];
};
export const analyticsBootcamp = [
  ...analyticTypes(),
  {
    label: "Status Promedios Tech",
    value: "prom-tech",
  },
  {
    label: "Status Promedios Soft",
    value: "prom-soft",
  },
];
