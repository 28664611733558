import styled from "styled-components";

export const PlayerInfoWrapper = styled("div")`
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(106, 105, 112, 0.25);
  padding: 10px;
`;

export const PlayerDescriptionTitle = styled("h4")`
  font-size: 12px;
  font-weight: 600;
  color: #2a2747;
`;

export const PlayerDescriptionText = styled("h4")`
  font-size: 12px;
  font-weight: normal;
  color: #2a2747;
  min-height: 80px;
`;
