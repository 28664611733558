import React, { useEffect, useState } from "react";
import { UnitDto } from "../../../../../types/unit.dto";
import { connect, useDispatch } from "react-redux";
import { StateDto } from "../../../../../types/states.dto";
import InfinityScroll from "../../../../../components/InfinityScroll";
import { getAllUnits } from "../../../../../store/actions/unit";
import { PaginationDto } from "../../../../../types/pagination.dto";
import { CohortDto } from "../../../../../types/cohort.dto";
import DropDown from "../DropDown";
import CenterTitle from "../../../../../components/CenterTitle";
import {
  FlexContainer,
  Separator,
} from "../../../../../components/StyledComponents";
import { BannerTitle } from "../../../../Academy/components/Banner/style";

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  units,
  unitsStates,
  cohortSelected,
  setActive,
  active,
  handleItemClick,
  currentContent,
  isLoadingContents,
}: {
  units: UnitDto[];
  unitsStates: StateDto;
  cohortSelected: CohortDto;
  setActive: any;
  active?: number;
  handleItemClick: any;
  currentContent: any;
  isLoadingContents: boolean;
}) => {
  const dispatch = useDispatch();
  const [currentUnits, setCurrentUnits] = useState<UnitDto[]>([]);
  const [page, setPage] = useState<number>(0);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload: { [key: string]: PaginationDto } = {
      units: {
        limit: 10,
        offset: 10 * page,
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          cohort: cohortSelected._id,
        },
      },
    };

    dispatch(getAllUnits(payload.units));
  };
  useEffect(() => {
    if (!!units && !isLoading) {
      setCurrentUnits((state) => [...state, ...units]);
    }
    setThereAreItems(!!units && units.length > 0);
  }, [units, isLoading]);
  useEffect(() => {
    setIsLoading(unitsStates.loading);
  }, [unitsStates.loading]);

  return (
    <>
      <BannerTitle size="16px">Modulos y Contenidos</BannerTitle>
      <Separator size="15"></Separator>
      <InfinityScroll
        action={fetchData}
        page={page}
        setPage={setPage}
        data={currentUnits}
        active={thereAreItems}
        isLoading={isLoading}
        {...(!thereAreItems &&
          currentUnits.length === 0 && {
            style: {
              minHeight: "150px",
              placeItems: "center",
              display: "flex",
            },
          })}
      >
        <FlexContainer
          direction="column"
          gap="15px"
          style={{ paddingBottom: "10px" }}
        >
          {currentUnits.map((unit, index) => {
            return (
              <DropDown
                onClick={() => {
                  setActive(index + 1 === active ? undefined : index + 1);
                  handleItemClick(unit._id);
                }}
                isLoadingContents={isLoadingContents}
                setActive={setActive}
                active={active}
                index={index + 1}
                name={unit.name}
                items={currentContent}
              ></DropDown>
            );
          })}
        </FlexContainer>
      </InfinityScroll>
    </>
  );
};

const states = ({ unitStore, cohortStore }) => {
  const { data: units, states: unitsStates } = unitStore.allUnits;
  const { data: cohortSelected } = cohortStore.cohort;
  return {
    units,
    unitsStates,
    cohortSelected,
  };
};

export default connect(states)(Component);
