import { MeetDto } from "../../types/meet.dto";
import { PaginationDto } from "../../types/pagination.dto";

export const GET_ALL_MEETS = "GET_ALL_MEETS";
export const GET_ALL_MEETS_ERROR = "GET_ALL_MEETS_ERROR";
export const GET_ALL_MEETS_SUCCESS = "GET_ALL_MEETS_SUCCESS";

export interface GetAllMeets {
  type: typeof GET_ALL_MEETS;
  payload: PaginationDto;
}

export interface GetAllMeetsSuccess {
  type: typeof GET_ALL_MEETS_SUCCESS;
  payload: MeetDto[];
}

export interface GetAllMeetsError {
  type: typeof GET_ALL_MEETS_ERROR;
  payload: boolean | string;
}

export interface State {
  allMeets: {
    data: MeetDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  allMeets: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions = GetAllMeets | GetAllMeetsSuccess | GetAllMeetsError;
