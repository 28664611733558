import action from "../helpers/rest-client";

export class ErrorConfirmationAuth extends Error {}

class ConfirmationAuth {
  public update(payload) {
    return action
      .Patch({
        url: `/confirmations/accept?token=${payload}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne(_id) {
    return action
      .Get({
        url: `/confirmations/byToken/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const confirmationAuth = new ConfirmationAuth();
export default confirmationAuth;
