import {
  useState,
  useEffect,
  useRef,
  CSSProperties,
  ReactElement,
} from "react";
import ReactDOM from "react-dom";
import { Transition } from "react-transition-group";
import { Animation } from "../Animation";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../store/actions/modal";
import Icon from "../Icon";

import {
  ModalBackdrop,
  Modal,
  ModalDialog,
  ModalContent,
  ModalHeader,
  ModalBody,
  Title,
  Close,
  ModalAnimation,
  Description,
} from "./styles";

import closeModalIcon from "../../assets/icons/close.svg";
import { useWindowSize } from "../../hooks/useWindowScreen";

export interface ModalProps {
  animation?: string;
  children: any;
  showClose?: boolean;
  title?: string | JSX.Element;
  name: string;
  onClose?: () => void;
  visibleModal?: string | null;
  styles?: any;
  width?: string;
  height?: string;
  position?: string;
  padding?: string;
  remove?: boolean;
  onCloseExternal?: () => void;
  description?: string;
  customClose?: ReactElement;
  style?: {
    title?: CSSProperties;
    body?: CSSProperties;
    wrapper?: CSSProperties;
  };
}

export const Component = ({
  animation = "fadeInSlideUp",
  showClose = true,
  children,
  title,
  name,
  onClose,
  visibleModal,
  styles,
  width,
  height,
  position,
  padding,
  remove,
  description,
  customClose,
  style,
}: ModalProps) => {
  const modalDOM = document.getElementById("modal") as HTMLElement;
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const ref: any = useRef();

  const handleClose = () => {
    dispatch(hideModal());
    setIsVisible(false);
    if (!!onClose) {
      onClose();
    }
  };

  const setBodyStyles = () => {
    if (isVisible) {
      document.body.style.height = "100%";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    setIsVisible(name === visibleModal);
  }, [name, visibleModal]);

  useEffect(() => {
    const checkIfClickOutside = (e) => {
      e.stopPropagation();
      if (isVisible && e.target.getAttribute("name") === "back") {
        handleClose();
      }
    };
    document.addEventListener("click", checkIfClickOutside);
    return () => {
      document.removeEventListener("click", checkIfClickOutside);
    };
  }, [isVisible]);

  useEffect(() => {
    setBodyStyles();
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <Transition in={isVisible} timeout={350} mountOnEnter>
      {(state) => (
        <>
          <ModalAnimation
            name={animation}
            className={`animation-${state}`}
            width={width}
            duration={0.4}
            position={!!position && position}
            {...(!!styles && styles)}
          >
            <Modal
              isMobile={isMobile}
              style={styles}
              ref={ref}
              name="back"
              data="hola"
            >
              <ModalDialog
                width={isMobile ? width ?? "none" : width}
                name="shadow"
              >
                <ModalContent
                  isMobile={isMobile}
                  padding={!!padding && padding}
                  height={height}
                  style={{ ...(!!style?.wrapper ? style.wrapper : {}) }}
                >
                  <ModalHeader>
                    {showClose && !remove && (
                      <Close onClick={() => handleClose()}>
                        {customClose ? (
                          customClose
                        ) : (
                          <Icon
                            icon={closeModalIcon}
                            size="16px"
                            color="black"
                          />
                        )}
                      </Close>
                    )}
                    {!!title && (
                      <Title
                        isMobile={isMobile}
                        style={{ ...(!!style?.title ? style.title : {}) }}
                      >
                        {title}
                      </Title>
                    )}
                    {!!description && (
                      <Description isMobile={isMobile}>
                        {description}
                      </Description>
                    )}
                  </ModalHeader>
                  <ModalBody
                    name="asdasd"
                    style={{ ...(!!style?.body ? style.body : {}) }}
                  >
                    {children}
                  </ModalBody>
                  {/* <ModalFooter></ModalFooter> */}
                </ModalContent>
              </ModalDialog>
            </Modal>
          </ModalAnimation>

          <Animation name="fadeIn" className={`animation-${state}`}>
            <ModalBackdrop onClick={() => handleClose()} />
          </Animation>
        </>
      )}
    </Transition>,
    modalDOM
  );
};

const state = ({ modalStore }) => ({ ...modalStore });

export default connect(state)(Component);
