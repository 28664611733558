import action from "../helpers/rest-client";

export class ErrorCertificate extends Error {}

class Certificate {
  public getOne({ _id }) {
    return action
      .Get({
        url: `/comments/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public manageLikeDislike({ _id, ...rest }) {
    return action
      .Patch({
        url: `/comments/${rest.action}-${rest.condition}/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public new(payload) {
    return action
      .Post({
        url: "/comments",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public update({ _id, ...rest }) {
    return action
      .Patch({
        url: `/comments/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll(payload) {
    if (payload.filterBy) {
      payload.filterBy = JSON.stringify(payload.filterBy);
    }
    if (payload.select) {
      payload.select = JSON.stringify(payload.select);
    }
    const queryString = new URLSearchParams(payload).toString();
    return action
      .Get({
        url: `/comments?platform=CAMPUS${queryString ? "&" + queryString : ""}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getReplies(payload) {
    if (payload.filterBy) {
      payload.filterBy = JSON.stringify(payload.filterBy);
    }
    if (payload.select) {
      payload.select = JSON.stringify(payload.select);
    }
    const queryString = new URLSearchParams(payload).toString();
    return action
      .Get({
        url: `/comments/by-parent/${payload._id}?platform=CAMPUS${
          queryString ? "&" + queryString : ""
        }`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public delete(commentId) {
    return action
      .Del({
        url: `/comments/${commentId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const comment = new Certificate();

export default comment;
