export function formatNumber(number) {
  const truncatedNumber = number.toFixed(1);
  return parseFloat(truncatedNumber);
}

export function overThousandFixed(number) {
  console.log(console.log(number, "52xc"));
  if (number > 1000) {
    const formattedNumber = (number / 1000).toFixed(1);
    return `${formattedNumber}k`;
  }
  if (Number.isInteger(number)) {
    return number.toString();
  } else {
    return number.toFixed(1);
  }
}
