import React, { useEffect, useRef, useState } from "react";
import Grid from "../../../../components/Grid";
import Share from "../../../../components/ShareLink";
import CustomPlayer from "../../../../components/CustomPlayer";
import { connect, useDispatch } from "react-redux";
import { TrianglePlay } from "../../../../components/CustomPlayer/style";
import {
  DotContent,
  FlexContainer,
  Separator,
} from "../../../../components/StyledComponents";
import {
  addViewListen,
  getAllListens,
  getListen,
  manageLikeDislikeListen,
  queryListen,
  resetGetAllListens,
  resetGetListen,
  resetQueryListen,
  resetUpdateListen,
  saveListen,
} from "../../../../store/actions/listen";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { ListenDto } from "../../../../types/listen.dto";
import {
  PlayerDescriptionText,
  PlayerDescriptionTitle,
  PlayerInfoWrapper,
} from "./style";
import {
  GridCard,
  GridCardDescription,
  GridCardImage,
  GridCardImageContainer,
  GridCardSmallContainer,
  GridCardTitle,
  GridComment,
  GridQuerySideImageContainer,
} from "../../../../components/Layout/Dashboard/styles";
import academyActions from "../../../../store/actions/academies";
import { AcademyDto } from "../../../../types/academy.dto";
import { DateFromNow } from "../../../../helpers/format-date";
import FilterByName from "./components/FilterByName";
import { ItemDataText } from "../List/style";
import { StateDto } from "../../../../types/states.dto";
import { toast } from "react-toastify";
import SideBar from "./components/SideBar";
import { FaSave, FaShare } from "react-icons/fa";
import { showModal } from "../../../../store/actions/modal";
import { BiLink } from "react-icons/bi";
import { UserDto } from "../../../../types/user.dto";
import {
  AiFillDislike,
  AiOutlineCloudDownload,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";
import { saveAs } from "file-saver";
import ShareModal from "./components/ShareModal";
import moment from "moment";
import {
  LikeDislikeBox,
  LikeDislikeBoxNumber,
  LikeDislikeText,
} from "../../../Cohort/Home/style";
import { overThousandFixed } from "../../../../helpers/number-fixed";
import CommentSection from "../../../../components/CommentSection";
import InfinityScroll from "../../../../components/InfinityScroll";
import {
  addClick,
  addView,
  userTime,
} from "../../../../store/actions/userTimes";
import useTrackTimeAndAPI from "../../../../hooks/useTrackTimeAndAPI";
import EmptyState from "./components/EmptyState";
import Skeleton from "../../../../components/Skeleton";

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  listen,
  academies,
  listens,
  queryListens,
  queryListenStates,
  user,
  updateListenStates,
  listensStates,
  getListenStates,
}: {
  listen: ListenDto;
  academies: AcademyDto[];
  listens: ListenDto[];
  queryListens: ListenDto[];
  queryListenStates: StateDto;
  user: UserDto;
  updateListenStates: StateDto;
  listensStates: StateDto;
  getListenStates: StateDto;
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [currentListensList, setCurrentListensList] = useState<any[]>([]);
  const [queryMode, setQueryMode] = useState<string | null>(null);
  const [query, setQuery] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [
    filteredCurrentListensListValues,
    setFilteredCurrentListensListValues,
  ] = useState<ListenDto[]>([]);
  const [openDropDown, setOpenDropDown] = useState<string[]>([]);
  const handleQuery = (ev) => {
    const value = ev.target.value;
    setQuery(value.length === 0 ? "" : value);
  };
  const searchQuery = (ev) => {
    if (ev.keyCode === 13) {
      /*  dispatch(queryImage({ keyword: query })); */
      setQueryMode(query);
    }
  };
  const ref: any = useRef(null);
  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter((applyedFilter) =>
        !Array.isArray(newFilter.field)
          ? applyedFilter.field === newFilter.field
          : newFilter.field.includes(applyedFilter.field)
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    if (filterRemoved) {
      /**
       * Se elimina el filtro
       */
      if (Array.isArray(newFilter.field)) {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => !newFilter.field.includes(applyedFilter.field)
          ),
        ]);
      } else {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => applyedFilter.field !== newFilter.field
          ),
        ]);
      }
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };
  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 6,
      offset: 6 * Math.floor(page / 2),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        published: true,
      },
    };
    const sortBy = page % 2 === 0 ? "createdAt" : "viewCount";

    const sortDirection = "desc";
    dispatch(
      getAllListens({
        ...payload,
        sortBy,
        sortDirection,
        ...(queryMode && { keyword: query }),
      })
    );
  };

  /*   useEffect(() => {
    if (!!listens && !queryListens) {
      const listenFilters = listens.filter(
        (listenFounded) =>
          listenFounded.active &&
          !!listenFounded.published &&
          !!listenFounded.available
      );
      setCurrentListensList(listenFilters);
    }
    if (!!queryListens) {
      setCurrentListensList(
        queryListens.filter(
          (listenFounded) =>
            listenFounded.active &&
            !!listenFounded.published &&
            !!listenFounded.available
        )
      );
    }
  }, [listens, queryListens]); */

  useEffect(() => {
    return () => {
      dispatch(resetGetAllListens());
    };
  }, []);

  const apiAction = (timeSpent) => {
    dispatch(
      userTime({ ...timeSpent, id: params.id, user: user._id, type: "LISTEN" })
    ); // Dispatch action to update Redux store or make API request
  };
  useTrackTimeAndAPI(apiAction, params.id && user);

  useEffect(() => {
    if (
      ((params.id && !listen) ||
        (params.id && listen && params.id !== listen?._id)) &&
      user
    ) {
      dispatch(getListen({ _id: params.id }));
      dispatch(addView({ id: params.id, user: user._id, type: "CONTENT" }));
    }
  }, [params.id, user]);

  useEffect(() => {
    const filteredTalents = currentListensList?.filter(
      (currentTalentValues) => {
        const thereAreFilters =
          applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
            .length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter(
            (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
          )
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value,
            currentTalentValues
          );
        });
        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentListensListValues(initResult);
  }, [applyedFilters, currentListensList]);

  useEffect(() => {
    if (!isLoading && !!listens) {
      setCurrentListensList((state) => [
        ...state,
        ...listens.filter((image) => image._id !== params.id),
      ]);
    }
    setThereAreItems(!!listens && listens?.length > 0);
  }, [listens, isLoading, params.id]);

  useEffect(() => {
    setIsLoading(listensStates.loading);
  }, [listensStates]);
  useEffect(() => {
    if (queryMode) {
      setPage(0);
      setIsLoading(false);
      setThereAreItems(true);
      setCurrentListensList([]);
    }
  }, [queryMode]);

  useEffect(() => {
    if (queryListenStates.error) {
      toast.error(queryListenStates.error);
      dispatch(resetQueryListen());
    }
    if (queryListenStates.success) {
      dispatch(resetQueryListen());
    }
  }, [queryListenStates]);
  useEffect(() => {
    if (updateListenStates.success) {
      dispatch(resetUpdateListen());
    }
    if (updateListenStates.error) {
      toast.error(updateListenStates.error);
      dispatch(resetUpdateListen());
    }
  }, [updateListenStates]);
  useEffect(() => {
    if (getListenStates.error) {
      toast.error(`${getListenStates.error}`);
      dispatch(resetGetListen());
    }
  }, [getListenStates]);

  const menu = (item) => [
    /*  {
      Icon: FaShare,
      onAvailable: () => !!item.available && !!item.published,
      onClick: () => {
        dispatch(showModal("share-user-listen"));
      },
    }, */
    {
      Icon: BiLink,
      onClick: () => {
        navigator.clipboard.writeText(
          `https://academia.catel.org.ar/dashboard/listens/${item._id}`
        );
        toast.success(`Link copiado`);
        dispatch(
          addClick({
            id: item._id,
            user: user._id,
            type: "CONTENT",
          })
        );
      },
    },

    /* {
      Icon: FaSave,
      activeFunction: () => item.savedBy.includes(user._id),
      onClick: () => {
        dispatch(
          saveListen({ _id: item._id, condition: "save", __v: item.__v })
        );
      },
      onClickActive: () => {
        dispatch(
          saveListen({ _id: item._id, condition: "unsave", __v: item.__v })
        );
      },
    }, */
    /*  {
      Icon: AiOutlineCloudDownload,
      onClick: (ev) => {
        saveAs(item.audio, `${item.name}.mp4`);
        toast.success(`Cdoc Descargado`);
      },
    }, */
  ];
  return (
    <>
      <Grid.Row>
        <Grid.Col style={{ marginBottom: "20px" }} lg={8}>
          {listen ? (
            <>
              <FlexContainer direction="column" gap="13px">
                <div
                  onClick={() => {
                    dispatch(
                      addClick({
                        id: listen._id,
                        user: user._id,
                        type: "CONTENT",
                      })
                    );
                  }}
                >
                  <CustomPlayer
                    CustomPlay={TrianglePlay}
                    lightImage={listen.picture}
                    playerHeight="394px"
                    video={listen.audio}
                    ref={ref}
                    audio={true}
                  ></CustomPlayer>
                </div>
                <PlayerInfoWrapper>
                  <FlexContainer justify="space-between">
                    <FlexContainer gap="16px">
                      <GridCardSmallContainer>
                        <GridCardImage
                          onClick={() => {
                            dispatch(
                              addClick({
                                id: listen.academy._id,
                                user: user._id,
                                type: "ACADEMY",
                              })
                            );
                            navigate(
                              `/dashboard/academy/${listen.academy._id}`
                            );
                          }}
                          style={{ cursor: "pointer" }}
                          src={listen?.academy.picture}
                        ></GridCardImage>
                      </GridCardSmallContainer>
                      <FlexContainer
                        style={{ width: "100%" }}
                        direction="column"
                        gap="10px"
                      >
                        <FlexContainer direction="column" gap="3px">
                          <GridCardTitle>{listen.name}</GridCardTitle>
                          <GridComment>{listen?.academy.name}</GridComment>
                          <FlexContainer gap="6px" align="center">
                            <FlexContainer gap="3px" align="center">
                              <DotContent></DotContent>
                              <GridComment>
                                {`Vistas: `}
                                <GridCardDescription display="inline-block">
                                  {listen.viewCount}
                                </GridCardDescription>
                              </GridComment>
                            </FlexContainer>
                            <FlexContainer gap="3px" align="center">
                              <DotContent></DotContent>
                              <GridComment>
                                {DateFromNow(listen.createdAt)}
                              </GridComment>
                            </FlexContainer>
                          </FlexContainer>
                        </FlexContainer>
                        <FlexContainer direction="column" gap="3px">
                          <PlayerDescriptionTitle>
                            Descripcion:
                          </PlayerDescriptionTitle>
                          <PlayerDescriptionText>
                            {listen.description}
                          </PlayerDescriptionText>
                        </FlexContainer>
                      </FlexContainer>
                    </FlexContainer>
                    <FlexContainer gap="5px" align="flex-start">
                      <FlexContainer
                        gap="12px"
                        align="center"
                        style={{ marginTop: "5px" }}
                      >
                        <LikeDislikeBox
                          className={
                            !!listen.likes.find(
                              (like) => like.user === user._id
                            )
                              ? "active"
                              : ""
                          }
                          onClick={() => {
                            if (
                              listen.likes.find(
                                (like) => like.user === user._id
                              )
                            ) {
                              dispatch(
                                manageLikeDislikeListen({
                                  _id: listen._id,
                                  condition: "like",
                                  action: "remove",
                                  individual: true,
                                  notReload: true,
                                })
                              );
                              dispatch(
                                addClick({
                                  id: listen._id,
                                  user: user._id,
                                  type: "CONTENT",
                                })
                              );
                            } else {
                              dispatch(
                                manageLikeDislikeListen({
                                  _id: listen._id,
                                  condition: "like",
                                  action: "add",
                                  individual: true,
                                  notReload: true,
                                })
                              );
                              dispatch(
                                addClick({
                                  id: listen._id,
                                  user: user._id,
                                  type: "CONTENT",
                                })
                              );
                            }
                          }}
                        >
                          <FlexContainer gap="3px" align="center">
                            <div
                              className="like-dislike-icon"
                              style={{ background: "#fff", padding: "4px" }}
                            >
                              <AiOutlineLike size={15} fill={"#2496ed"} />
                            </div>
                            <LikeDislikeBoxNumber className="count-likes">
                              <LikeDislikeText>
                                {overThousandFixed(listen.likes.length)}
                              </LikeDislikeText>
                            </LikeDislikeBoxNumber>
                          </FlexContainer>
                        </LikeDislikeBox>

                        <LikeDislikeBox
                          className={
                            !!listen.dislikes.find(
                              (like) => like.user === user._id
                            )
                              ? "active"
                              : ""
                          }
                          onClick={() => {
                            if (
                              listen.dislikes.find(
                                (like) => like.user === user._id
                              )
                            ) {
                              dispatch(
                                manageLikeDislikeListen({
                                  _id: listen._id,
                                  condition: "dislike",
                                  action: "remove",
                                  individual: true,
                                  notReload: true,
                                })
                              );
                              dispatch(
                                addClick({
                                  id: listen._id,
                                  user: user._id,
                                  type: "CONTENT",
                                })
                              );
                            } else {
                              dispatch(
                                manageLikeDislikeListen({
                                  _id: listen._id,
                                  condition: "dislike",
                                  action: "add",
                                  individual: true,
                                  notReload: true,
                                })
                              );
                              dispatch(
                                addClick({
                                  id: listen._id,
                                  user: user._id,
                                  type: "CONTENT",
                                })
                              );
                            }
                          }}
                        >
                          <FlexContainer gap="3px" align="center">
                            <div
                              className="like-dislike-icon"
                              style={{
                                padding: "7px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {listen.dislikes.find(
                                (like) => like.user === user._id
                              ) ? (
                                <AiFillDislike
                                  fill={"#fff"}
                                  size={15}
                                ></AiFillDislike>
                              ) : (
                                <AiOutlineDislike
                                  fill={"#2496ed"}
                                  size={15}
                                ></AiOutlineDislike>
                              )}
                            </div>
                          </FlexContainer>
                        </LikeDislikeBox>

                        <Share />
                      </FlexContainer>
                    </FlexContainer>
                  </FlexContainer>
                </PlayerInfoWrapper>
              </FlexContainer>
              <Separator size={15}></Separator>
              <CommentSection
                itemId={listen._id}
                section="content"
              ></CommentSection>
            </>
          ) : (
            <EmptyState></EmptyState>
          )}
        </Grid.Col>
        <Grid.Col lg={3.7} offset={{ lg: 0.3, md: 2, sm: 2 }} md={8} sm={8}>
          <Grid.Row>
            <FilterByName
              searchQuery={searchQuery}
              setFilter={handleQuery}
              value={query}
            ></FilterByName>
          </Grid.Row>
          <Grid.Row>
            <FlexContainer
              style={{
                width: "100%",
                marginTop: "10px",
                maxHeight: "500px",
                overflow: "auto",
                padding: "0 5px",
              }}
            >
              <InfinityScroll
                action={fetchData}
                page={page}
                setPage={setPage}
                data={filteredCurrentListensListValues}
                active={thereAreItems}
                isLoading={isLoading}
                style={{ width: "100%", paddingBottom: "10px" }}
                loader={
                  <Skeleton
                    itemsPerPage={10}
                    itemsPerRow={1}
                    animated
                    size={{ height: 58, width: 297 }}
                    spaceBetweenItems={10}
                    icon="video"
                  />
                }
              >
                <FlexContainer
                  direction="column"
                  gap="10px"
                  align="center"
                  justify="center"
                  style={{ paddingBottom: "10px" }}
                >
                  {filteredCurrentListensListValues
                    .filter((item) => item._id !== params.id)
                    .map((item) => {
                      return (
                        <GridCard
                          style={{ width: "100%", maxWidth: "300px" }}
                          onClick={() => {
                            dispatch(
                              addClick({
                                id: item._id,
                                user: user._id,
                                type: "CONTENT",
                              })
                            );
                            navigate(`../${item._id}`, {
                              replace: true,
                            });
                          }}
                          pd={"15px"}
                          key={item._id}
                        >
                          <FlexContainer gap="8px" align="center">
                            <GridQuerySideImageContainer>
                              <GridCardImage
                                src={item.picture || ""}
                              ></GridCardImage>
                            </GridQuerySideImageContainer>

                            <FlexContainer direction="column" gap="1px">
                              <GridCardTitle style={{ fontSize: "14px" }}>
                                {item.name}
                              </GridCardTitle>
                              <FlexContainer direction="column">
                                <FlexContainer
                                  onClick={(ev) => {
                                    ev.stopPropagation();
                                    dispatch(
                                      addClick({
                                        id: item.academy._id,
                                        user: user._id,
                                        type: "ACADEMY",
                                      })
                                    );
                                    navigate(
                                      `/dashboard/academy/${item.academy._id}`
                                    );
                                  }}
                                  align="center"
                                  gap="5px"
                                >
                                  <GridComment>{item.academy.name}</GridComment>
                                </FlexContainer>

                                <FlexContainer
                                  gap="10px"
                                  align="center"
                                  wrap="wrap"
                                >
                                  <FlexContainer align="center" gap="5px">
                                    <DotContent></DotContent>
                                    <ItemDataText>
                                      <GridComment>{`Vistas: `}</GridComment>
                                      {item.viewCount}
                                    </ItemDataText>
                                  </FlexContainer>
                                  <FlexContainer align="center" gap="5px">
                                    <DotContent></DotContent>
                                    <ItemDataText>
                                      <GridComment>
                                        {DateFromNow(item.createdAt)}
                                      </GridComment>
                                    </ItemDataText>
                                  </FlexContainer>
                                </FlexContainer>
                              </FlexContainer>
                            </FlexContainer>
                          </FlexContainer>
                        </GridCard>
                      );
                    })}
                </FlexContainer>
              </InfinityScroll>
            </FlexContainer>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>

      <ShareModal listen={listen}></ShareModal>
    </>
  );
};

const states = ({ listenStore, academyStore, userStore }) => {
  const { data: listen, states: getListenStates } = listenStore.listen;
  const { data: academies } = academyStore.all;
  const { data: listens, states: listensStates } = listenStore.all;
  const { data: queryListens, states: queryListenStates } = listenStore.query;
  const { states: updateListenStates } = listenStore.update;
  const { data: user } = userStore;
  return {
    listen,
    academies,
    listens,
    queryListens,
    queryListenStates,
    user,
    updateListenStates,
    listensStates,
    getListenStates,
  };
};

export default connect(states)(Component);
