import { CSSProperties, useState } from "react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "../Modal";
import Icon from "../Icon";
import {
  WhatsappIcon,
  FacebookMessengerIcon,
  TelegramIcon,
  LinkedinIcon,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import { showModal } from "../../store/actions/modal";
import {
  Item,
  Button,
  Name,
  CopyUrl,
  CopyUrlButton,
  Next,
  Prev,
} from "./styles";
import closeModalIcon from "../../assets/icons/close.svg";
import { FaShareAlt } from "react-icons/fa";
import {
  LikeDislikeBox,
  LikeDislikeBoxNumber,
  LikeDislikeText,
} from "../../pages/Cohort/Home/style";
import { FlexContainer } from "../StyledComponents";

const SampleNextArrow = (props) => {
  return <Next {...props} />;
};

const SamplePrevArrow = (props) => {
  return <Prev {...props} />;
};

interface ComponentProps {
  style?: {
    wrapper?: CSSProperties;
    text?: CSSProperties;
    icon?: CSSProperties;
    textBox?: CSSProperties;
  };
}

const Component = ({ style }: ComponentProps) => {
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);
  const currentUrl = window.location.href;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    } catch (error) {
      console.error("Error copying text to clipboard:", error);
    }
  };

  return (
    <>
      <LikeDislikeBox
        onClick={() => dispatch(showModal("share-modal"))}
        style={style?.wrapper}
      >
        <FlexContainer gap="3px" align="center">
          <div
            className="like-dislike-icon"
            style={{
              background: "#fff",
              padding: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ...style?.icon,
            }}
          >
            <FaShareAlt fill={"#2496ed"} size={15} />
          </div>
          <LikeDislikeBoxNumber style={style?.textBox} className="count-likes">
            <LikeDislikeText style={style?.text}>Compartir</LikeDislikeText>
          </LikeDislikeBoxNumber>
        </FlexContainer>
      </LikeDislikeBox>

      <Modal
        name="share-modal"
        title="Compartir"
        style={{
          title: {
            fontSize: 16,
            fontWeight: 400,
            textAlign: "left",
            color: "white",
          },
          wrapper: {
            background: "rgb(33, 33, 33)",
            padding: "20px 45px",
            borderRadius: "14px",
          },
        }}
        width="500px"
        customClose={<Icon icon={closeModalIcon} size="16px" color="white" />}
      >
        <Slider {...settings}>
          <Item>
            <WhatsappShareButton url={currentUrl}>
              <WhatsappIcon size={60} />
              <Name>Whatsapp</Name>
            </WhatsappShareButton>
          </Item>
          <Item>
            <FacebookMessengerShareButton appId="" url={currentUrl}>
              <FacebookMessengerIcon size={60} />
              <Name>Facebook Messenger</Name>
            </FacebookMessengerShareButton>
          </Item>
          <Item>
            <TelegramShareButton url={currentUrl}>
              <TelegramIcon size={60} />
              <Name>Telegram</Name>
            </TelegramShareButton>
          </Item>
          <Item>
            <LinkedinShareButton url={currentUrl}>
              <LinkedinIcon size={60} />
              <Name>LinkedIn</Name>
            </LinkedinShareButton>
          </Item>
          <Item>
            <EmailShareButton url={currentUrl}>
              <EmailIcon size={60} />
              <Name>Email</Name>
            </EmailShareButton>
          </Item>
          <Item>
            <FacebookShareButton url={currentUrl}>
              <FacebookIcon size={60} />
              <Name>Facebook</Name>
            </FacebookShareButton>
          </Item>
          {/* <Item>
          <PinterestShareButton media="" url={currentUrl}>
            <PinterestIcon size={60} />
            <Name>Pinterest</Name>
          </PinterestShareButton>
        </Item> */}
          <Item>
            <TwitterShareButton url={currentUrl}>
              <TwitterIcon size={60} />
              <Name>Twitter</Name>
            </TwitterShareButton>
          </Item>
        </Slider>

        <div style={{ height: 20 }} />

        <CopyUrl>
          <span>{currentUrl}</span>
          <CopyUrlButton
            onClick={() => copyToClipboard(currentUrl)}
            isCopied={isCopied}
          >
            {isCopied ? "Copiado" : "Copiar"}
          </CopyUrlButton>
        </CopyUrl>
      </Modal>
    </>
  );
};

export default Component;
