import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { UserDto } from "../../types/user.dto";

const Component = ({ user }: { user: UserDto }) => {
  /*   const navigate = useNavigate(); */
  const location = useLocation();
  const accessToken = (
    document.cookie.split(";").find((x) => x.includes("refreshToken")) || "="
  ).split("=")[1];
  /*   useEffect(() => {
    if (!accessToken && !user) {
      navigate("/dashboard/home", {
        state: { user: null, denied: true, pathname: location.pathname },
      });
    }
  }, [user, accessToken]); */
  console.log(accessToken, user, "verifa");
  return !accessToken && !user ? (
    <Navigate
      to={"/dashboard/home"}
      state={{ user: null, denied: true, pathname: location.pathname }}
    ></Navigate>
  ) : (
    <Outlet></Outlet>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;
  return {
    user,
  };
};

export default connect(states)(Component);
