import React, { useEffect, useState } from "react";
import Grid from "../../../../components/Grid";
import { connect, useDispatch } from "react-redux";
import {
  FlexContainer,
  ScrollSection,
} from "../../../../components/StyledComponents";
import {
  getAllImages,
  getImage,
  manageLikeDislikeImage,
  resetGetAllImages,
  resetGetImage,
  resetQueryImage,
  resetUpdateImage,
} from "../../../../store/actions/image";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { ImageDto } from "../../../../types/image.dto";
import { ImagePlayerContainer } from "./style";
import {
  GridCardContent,
  GridCardImage,
  GridCardTitle,
  GridComment,
  GridQuerySideImageContainer,
} from "../../../../components/Layout/Dashboard/styles";

import { AcademyDto } from "../../../../types/academy.dto";
import { DateFromNow } from "../../../../helpers/format-date";
import FilterByName from "./components/FilterByName";
import { ItemDataText } from "../List/style";
import { StateDto } from "../../../../types/states.dto";
import { toast } from "react-toastify";
import SideBar from "./components/SideBar";

import { BiLink } from "react-icons/bi";
import { UserDto } from "../../../../types/user.dto";
import { AiFillClockCircle, AiFillEye } from "react-icons/ai";
import { saveAs } from "file-saver";
import ShareModal from "./components/ShareModal";
import { UsersDto } from "../../../../types/users.dto";

import { overThousandFixed } from "../../../../helpers/number-fixed";

import InfinityScroll from "../../../../components/InfinityScroll";
import {
  addClick,
  addView,
  userTime,
} from "../../../../store/actions/userTimes";
import useTrackTimeAndAPI from "../../../../hooks/useTrackTimeAndAPI";

import { useTheme } from "styled-components";

import Skeleton from "../../../../components/Skeleton";
import DescriptionForContentsView from "../../../../components/DescriptionForContentsView";
import { FullScreenButton } from "../../../../components/SlideshowPlayer/styles";
import { BsFullscreen } from "react-icons/bs";
import FullScreenImage from "./components/FullScreenImage";
import { useWindowSize } from "../../../../hooks/useWindowScreen";
import academyActions from "../../../../store/actions/academies";
import DescriptionForContentsViewMobile from "../../../../components/DescriptionForContentsViewMobile";
import EmptyState from "./components/EmptyState";
interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  image,
  academies,
  images,
  queryImages,
  queryImageStates,
  user,
  updateImageStates,
  users,
  imagesStates,
  getImageStates,
  updatedAcademy,
  updateAcademyStates,
  updatedImage,
  academy,
}: {
  image: ImageDto;
  academies: AcademyDto[];
  images: ImageDto[];
  queryImages: ImageDto[];
  queryImageStates: StateDto;
  user: UserDto;
  updateImageStates: StateDto;
  users: UsersDto;
  imagesStates: StateDto;
  getImageStates: StateDto;
  updatedAcademy: AcademyDto;
  updateAcademyStates: StateDto;
  updatedImage: ImageDto;
  academy: AcademyDto;
}) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const params = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [currentImagesList, setCurrentImagesList] = useState<any[]>([]);
  const [currentImageAcademy, setCurrentImageAcademy] = useState<AcademyDto>();
  const [queryMode, setQueryMode] = useState<string | null>(null);
  const [query, setQuery] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [onFullScreen, setOnFullScreen] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<ImageDto>();
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [filteredCurrentImagesListValues, setFilteredCurrentImagesListValues] =
    useState<ImageDto[]>([]);
  const handleQuery = (ev) => {
    const value = ev.target.value;
    setQuery(value.length === 0 ? "" : value);
  };
  const searchQuery = (ev) => {
    if (ev.keyCode === 13) {
      /*  dispatch(queryImage({ keyword: query })); */
      setQueryMode(query);
    }
  };

  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 6,
      offset: 6 * Math.floor(page / 2),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        published: true,
      },
    };
    const sortBy = page % 2 === 0 ? "createdAt" : "viewCount";

    const sortDirection = "desc";
    dispatch(
      getAllImages({
        ...payload,
        sortBy,
        sortDirection,
        ...(queryMode && { keyword: query }),
      })
    );
  };

  const apiAction = (timeSpent) => {
    dispatch(
      userTime({ ...timeSpent, id: params.id, user: user._id, type: "IMAGE" })
    ); // Dispatch action to update Redux store or make API request
  };
  useTrackTimeAndAPI(apiAction, params.id && user);

  useEffect(() => {
    const filteredTalents = currentImagesList?.filter((currentTalentValues) => {
      const thereAreFilters =
        applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
          .length > 0;
      const totalOfFilters = applyedFilters.filter(
        (applyedFilter) => !applyedFilter.groupal
      ).length;
      const passFilters = applyedFilters
        .filter(
          (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
        )
        .filter(
          (applyedFilter) =>
            currentTalentValues[applyedFilter.field] === applyedFilter.value
        ).length;
      const specialFilter = applyedFilters.filter(
        (applyedFilter) => !!applyedFilter.special
      );

      const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
        return applyedFilter.special(
          currentTalentValues,
          applyedFilter.value,
          currentTalentValues
        );
      });
      return (
        !thereAreFilters ||
        (thereAreFilters &&
          totalOfFilters === passFilters + specialFilter.length &&
          speacialFilterCheck.length === specialFilter.length)
      );
    });
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentImagesListValues(initResult);
  }, [applyedFilters, currentImagesList]);

  useEffect(() => {
    if (!isLoading && !!images) {
      setCurrentImagesList((state) => [
        ...state,
        ...images.filter((image) => image._id !== params.id),
      ]);
    }
    setThereAreItems(!!images && images?.length > 0);
  }, [images, isLoading, params.id]);

  useEffect(() => {
    if (image) {
      setCurrentImage(image);
    }
  }, [image]);

  useEffect(() => {
    if (image && document.getElementById("goTop")) {
      const element: any = document.getElementById("goTop");
      window.scrollTo(0, element.scrollTop);
    }
  }, [image]);

  useEffect(() => {
    if (image) {
      dispatch(academyActions.getOne({ _id: image.academy._id }));
    }
  }, [image]);

  useEffect(() => {
    if (academy && !currentImageAcademy) {
      setCurrentImageAcademy(academy);
    }
  }, [academy]);

  useEffect(() => {
    setIsLoading(imagesStates.loading);
  }, [imagesStates]);

  useEffect(() => {
    if (params.id && !!user) {
      dispatch(getImage({ _id: params.id }));
      dispatch(addView({ id: params.id, user: user._id, type: "CONTENT" }));
      if (document.getElementById("scrollToTopTarget")) {
        const element: any = document.getElementById("scrollToTopTarget");

        element.scrollIntoView();
      }
    }
  }, [dispatch, params.id, user]);
  useEffect(() => {
    if (queryImageStates.error) {
      toast.error(queryImageStates.error);
      dispatch(resetQueryImage());
    }
    if (queryImageStates.success) {
      dispatch(resetQueryImage());
    }
  }, [queryImageStates, dispatch]);

  useEffect(() => {
    if (getImageStates.error) {
      toast.error(`${getImageStates.error}`);
      dispatch(resetGetImage());
    }
  }, [getImageStates, dispatch]);

  useEffect(() => {
    if (updateImageStates.success) {
      setCurrentImage(updatedImage);
      dispatch(resetUpdateImage());
    }
    if (updateImageStates.error) {
      toast.error(updateImageStates.error);
      dispatch(resetUpdateImage());
    }
  }, [updateImageStates]);

  useEffect(() => {
    if (queryMode) {
      setPage(0);
      setIsLoading(false);
      setThereAreItems(true);
      setCurrentImagesList([]);
    }
  }, [queryMode]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllImages());
      dispatch(academyActions.resetGetOneAcademy());
    };
  }, []);

  return (
    <>
      <div
        id="scrollToTopTarget"
        style={{ position: "absolute", top: 0, width: "0px", height: "0px" }}
      ></div>
      <Grid.Row>
        <Grid.Col style={{ marginBottom: "20px" }} lg={8}>
          {!currentImage ? (
            <EmptyState></EmptyState>
          ) : (
            <>
              {" "}
              <ImagePlayerContainer
                style={{
                  backgroundImage: `url(${currentImage.picture})`,
                  backgroundRepeat: "no-repeat",
                }}
              >
                <FullScreenButton
                  onClick={() => {
                    setOnFullScreen(true);
                  }}
                >
                  <BsFullscreen
                    color={
                      isMobile ? theme.colors.LightBlue : theme.colors.DarkGray
                    }
                    size={isMobile ? 18 : 32}
                  ></BsFullscreen>
                </FullScreenButton>
              </ImagePlayerContainer>
              {isMobile ? (
                <DescriptionForContentsViewMobile
                  currentContentAcademy={currentImageAcademy}
                  content={currentImage}
                  manageLikeDislikeContent={manageLikeDislikeImage}
                  searchQuery={searchQuery}
                  handleQuery={handleQuery}
                  query={query}
                  fetchData={fetchData}
                  page={page}
                  setPage={setPage}
                  thereAreItems={thereAreItems}
                  isLoading={isLoading}
                  currentContentList={filteredCurrentImagesListValues}
                ></DescriptionForContentsViewMobile>
              ) : (
                <DescriptionForContentsView
                  currentContentAcademy={currentImageAcademy}
                  content={currentImage}
                  manageDislikeLikeContent={manageLikeDislikeImage}
                ></DescriptionForContentsView>
              )}
            </>
          )}
        </Grid.Col>
        {!isMobile && (
          <Grid.Col offset={{ lg: 0.3 }}>
            <FilterByName
              searchQuery={searchQuery}
              setFilter={handleQuery}
              value={query}
            />

            <div style={{ height: 10 }} />

            <ScrollSection style={{ maxHeight: 450, boxShadow: "none" }}>
              <InfinityScroll
                action={fetchData}
                page={page}
                setPage={setPage}
                data={images}
                active={thereAreItems}
                isLoading={isLoading}
                style={{ width: "100%", paddingBottom: "10px" }}
                loader={
                  <Skeleton
                    itemsPerPage={10}
                    itemsPerRow={1}
                    animated
                    size={{ height: 58, width: 297 }}
                    spaceBetweenItems={10}
                    icon="video"
                  />
                }
              >
                <FlexContainer
                  direction="column"
                  gap="10px"
                  align="center"
                  justify="center"
                  style={{ paddingBottom: "10px" }}
                >
                  {filteredCurrentImagesListValues
                    .filter((item) => item._id !== params.id)
                    .map((item) => {
                      return (
                        <GridCardContent
                          onClick={() => {
                            dispatch(
                              addClick({
                                id: item._id,
                                user: user._id,
                                type: "CONTENT",
                              })
                            );
                            navigate(`../${item._id}`, {
                              replace: true,
                            });
                          }}
                          pd={"5px"}
                          key={item._id}
                        >
                          <FlexContainer gap="11.1px" align="center">
                            <GridQuerySideImageContainer>
                              <GridCardImage src={item.picture || ""} />
                            </GridQuerySideImageContainer>

                            <FlexContainer direction="column" gap="1px">
                              <GridCardTitle style={{ fontSize: "14px" }}>
                                {item.name}
                              </GridCardTitle>

                              <FlexContainer direction="column">
                                <FlexContainer
                                  onClick={(ev) => {
                                    ev.stopPropagation();
                                    dispatch(
                                      addClick({
                                        id: item.academy._id,
                                        user: user._id,
                                        type: "ACADEMY",
                                      })
                                    );
                                    navigate(
                                      `/dashboard/academy/${item.academy._id}`
                                    );
                                  }}
                                  align="center"
                                  gap="5px"
                                >
                                  <GridComment>{item.academy.name}</GridComment>
                                </FlexContainer>
                                <FlexContainer gap="5px" align="center">
                                  <FlexContainer align="center" gap="3px">
                                    <AiFillEye fill="#6a6970"></AiFillEye>

                                    <ItemDataText>
                                      <GridComment>
                                        {overThousandFixed(item.viewCount)}
                                      </GridComment>
                                    </ItemDataText>
                                  </FlexContainer>

                                  <span>|</span>
                                  <FlexContainer align="center" gap="3px">
                                    <AiFillClockCircle fill="#6a6970"></AiFillClockCircle>

                                    <ItemDataText>
                                      <GridComment>
                                        {DateFromNow(item.createdAt)}
                                      </GridComment>
                                    </ItemDataText>
                                  </FlexContainer>
                                </FlexContainer>
                              </FlexContainer>
                            </FlexContainer>
                          </FlexContainer>
                        </GridCardContent>
                      );
                    })}
                </FlexContainer>
              </InfinityScroll>
            </ScrollSection>
          </Grid.Col>
        )}
      </Grid.Row>
      {onFullScreen && currentImage && (
        <FullScreenImage
          setOnFullScreen={setOnFullScreen}
          src={currentImage.picture}
        ></FullScreenImage>
      )}
      {currentImage && <ShareModal image={currentImage}></ShareModal>}
    </>
  );
};

const states = ({ imageStore, academyStore, userStore, usersStore }) => {
  const { data: image, states: getImageStates } = imageStore.image;
  const { data: academies } = academyStore.all;
  const { data: academy } = academyStore.selected;
  const { data: images, states: imagesStates } = imageStore.all;
  const { data: queryImages, states: queryImageStates } = imageStore.query;
  const { states: updateImageStates, data: updatedImage } = imageStore.update;
  const { data: user } = userStore;
  const { data: users } = usersStore.all;
  const { data: updatedAcademy, states: updateAcademyStates } =
    academyStore.update;
  return {
    image,
    academies,
    images,
    queryImages,
    queryImageStates,
    user,
    updateImageStates,
    users,
    imagesStates,
    getImageStates,
    updatedAcademy,
    updateAcademyStates,
    updatedImage,
    academy,
  };
};

export default connect(states)(Component);
