import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getCookie } from "../../../../../helpers/cookies-handler";
import MediaItems from "../MediaItems";
import { PaginationDto } from "../../../../../types/pagination.dto";
import services from "../../../../../services";
import { ContentDto } from "../../../../../types/content.dto";
import Skeleton from "../../../../../components/Skeleton";
import { ListMediaTitle } from "./styles";
import { useNavigate } from "react-router-dom";
import { UserDto } from "../../../../../types/user.dto";
import { addClick } from "../../../../../store/actions/userTimes";
import { GeneralContentDto } from "../../../../../types/generalContent.dto";

interface FetchData {
  filterBy?: { [key: string]: string | number | boolean };
}

interface ComponentProps {
  user: UserDto;
}

const Component = ({ user }: ComponentProps) => {
  const [visitedElement, setVisitedElement] = useState<GeneralContentDto>();
  const [elements, setElements] = useState<GeneralContentDto[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = async ({ filterBy }: FetchData) => {
    let payload: PaginationDto = {
      limit: 3,
      offset: 0,
      sortBy: "createdAt",
      sortDirection: "desc",
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        available: true,
        published: true,
      },
    };

    let responses: GeneralContentDto[] = [];

    const { response: responseTwo } = await services.generalContent.getAll(
      payload
    );

    if (responseTwo) {
      responses = [...responses, ...responseTwo];
      setElements(responses);
    }
  };

  useEffect(() => {
    const cookieName = "DEVPLACE_VISITED_ACADEMY";
    const cookie = getCookie(cookieName);

    if (cookie) {
      const visitedElement = JSON.parse(cookie);

      if (visitedElement?._id) {
        fetchData({ filterBy: { academy: visitedElement._id } });
        setVisitedElement(visitedElement);
      }
    }
  }, []);

  if (!visitedElement) {
    return <></>;
  }

  if (!!visitedElement && elements.length <= 0) {
    return (
      <>
        <ListMediaTitle>{`Último cotenido de la academia "${visitedElement?.name}"`}</ListMediaTitle>
        <Skeleton
          itemsPerPage={3}
          itemsPerRow={3}
          animated
          size={{ height: 290, width: 276 }}
          icon="academy"
        />
        <div style={{ height: "35px" }} />
      </>
    );
  }

  return (
    <>
      <MediaItems
        style={{ margin: "0 auto" }}
        size="lg"
        items={elements}
        title={`Último cotenido de la academia "${visitedElement?.name}"`}
        onClick={(event, item) => {
          dispatch(addClick({ id: item._id, user: user._id, type: "CONTENT" }));
        }}
      />

      <div style={{ height: "35px" }} />
    </>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;

  return {
    user,
  };
};

export default connect(states)(Component);
