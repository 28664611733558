import {
  GetAllCategories,
  GET_ALL_CATEGORIES,
  GetCategory,
  GET_CATEGORY,
  UpdateCategory,
  UPDATE_CATEGORY,
  DeleteCategory,
  DELETE_CATEGORY,
  GetCategorySuccess,
  GET_CATEGORY_SUCCESS,
  NewCategory,
  NEW_CATEGORY,
  ResetNewCategory,
  RESET_NEW_CATEGORY,
  ResetUpdateCategory,
  RESET_UPDATE_CATEGORY,
  ResetDeleteCategory,
  RESET_DELETE_CATEGORY,
} from "../types/category";

export const getAllCategories = (): GetAllCategories => {
  return { type: GET_ALL_CATEGORIES, payload: null };
};

export const getCategory = (id): GetCategory => {
  return { type: GET_CATEGORY, payload: id };
};

export const setSelectedCategory = (payload): GetCategorySuccess => {
  return { type: GET_CATEGORY_SUCCESS, payload };
};

export const newCategory = (payload): NewCategory => {
  return { type: NEW_CATEGORY, payload };
};

export const resetNewCategory = (): ResetNewCategory => {
  return { type: RESET_NEW_CATEGORY, payload: null };
};

export const updateCategory = (id): UpdateCategory => {
  return { type: UPDATE_CATEGORY, payload: id };
};

export const resetUpdateCategory = (): ResetUpdateCategory => {
  return { type: RESET_UPDATE_CATEGORY, payload: null };
};

export const deleteCategory = (id): DeleteCategory => {
  return { type: DELETE_CATEGORY, payload: id };
};

export const resetDeleteCategory = (): ResetDeleteCategory => {
  return { type: RESET_DELETE_CATEGORY, payload: null };
};
