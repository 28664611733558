import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center } from "../../../../components/StyledComponents";
import Input from "../../../../components/Form/Input";
import Submit from "../../../../components/Form/Submit";
import ReactSelect from "../../../../components/Form/ReactSelect";
import { initialValues, schema } from "../../../../constants/form/student/edit";
import {
  resetUpdateStudent,
  updateStudent,
} from "../../../../store/actions/student";
import { getAllAbilities } from "../../../../store/actions/ability";
import { StudentDto } from "../../../../types/student.dto";
import { AbilityDto } from "../../../../types/ability.dto";
import Button from "../../../../components/Button";
import { hideModal } from "../../../../store/actions/modal";
import {
  getAllCohorts,
  resetGetAllCohorts,
} from "../../../../store/actions/cohort";
import { CohortDto } from "../../../../types/cohort.dto";

interface ComponentProps {
  student: StudentDto;
  abilities: AbilityDto[];
  cohorts: CohortDto[];
  updateStudentStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  student,
  abilities,
  cohorts,
  updateStudentStates,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [type, setType] = useState<string>();
  const [currentTypeAbilities, setCurrentTypeAbilities] =
    useState<AbilityDto[]>();

  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(updateStudent(values));
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  useEffect(() => {
    if (!!abilities) {
      setCurrentTypeAbilities(
        abilities.filter((ability) => ability.type === type)
      );
    }
  }, [type, abilities]);

  useEffect(() => {
    !abilities && dispatch(getAllAbilities());
  }, [abilities, dispatch]);
  useEffect(() => {
    if (!cohorts) dispatch(getAllCohorts({}));
  }, [cohorts, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohorts());
    };
  }, []);

  useEffect(() => {
    if (updateStudentStates.success) {
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetUpdateStudent());
      }, 2000);
    }
  }, [updateStudentStates]);

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...student,
      }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validateOnChange={formSubmmited}
      validateOnBlur={false}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <form
            className="theme-form"
            onSubmit={(student) => {
              setFormSubmmited(true);
              handleSubmit(student);
            }}
            id="edit-student-form"
          >
            <Input
              name="name"
              error={errors["name"]}
              touched={touched["name"]}
              value={values["name"]}
              type="text"
              placeholder="Nombre"
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Nombre",
                marginBottom: 20,
              }}
            />

            <Input
              name="lastName"
              error={errors["lastName"]}
              touched={touched["lastName"]}
              value={values["lastName"]}
              type="text"
              placeholder="Apellido"
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Apellido",
                marginBottom: 20,
              }}
            />

            <Input
              name="email"
              error={errors["email"]}
              touched={touched["email"]}
              value={values["email"]}
              type="text"
              placeholder="E-mail"
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "E-mail",
                marginBottom: 20,
              }}
            />

            {/* <Input
              name="password"
              error={errors["password"]}
              touched={touched["password"]}
              value={values["password"]}
              type="password"
              placeholder="Contraseña"
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Contraseña",
                marginBottom: 20,
              }}
            /> */}

            <Input
              name="dni"
              error={errors["dni"]}
              touched={touched["dni"]}
              value={values["dni"]}
              type="number"
              placeholder="DNI"
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "DNI",
                marginBottom: 20,
              }}
            />

            <Input
              name="phone"
              error={errors["phone"]}
              touched={touched["phone"]}
              value={values["phone"]}
              type="string"
              placeholder="Teléfono"
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Teléfono",
                marginBottom: 20,
              }}
            />

            <ReactSelect
              name="cohortsIds"
              error={errors["cohortsIds"]}
              touched={touched["cohortsIds"]}
              {...(!!cohorts && {
                items: cohorts?.map((cohort) => ({
                  label: cohort.name,
                  value: cohort._id,
                })),
              })}
              isMulti
              placeholder="Asigna un Co-hort"
              disabled={!abilities}
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Co-hort",
                marginBottom: 20,
              }}
            />

            <Center>
              <Submit
                isSubmmiting={isSubmitting}
                form="edit-student-form"
                color="Primary"
                options={{
                  type: "filled",
                  skin: "primary",
                  size: "lg",
                  marginBottom: "0px",
                }}
              >
                Confirmar
              </Submit>
              <Button
                onClick={closeModal}
                type="button"
                options={{
                  type: "outline",
                  skin: "danger",
                  size: "lg",
                  marginBottom: "0px",
                }}
                style={{ marginLeft: "10px" }}
              >
                Cancelar
              </Button>
            </Center>
          </form>
        );
      }}
    </Formik>
  );
};

const states = ({ studentStore, abilityStore, cohortStore }) => {
  const { data: student } = studentStore.student;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: abilities } = abilityStore.allAbilities;
  const { states: updateStudentStates } = studentStore.updateStudent;

  return {
    student,
    abilities,
    cohorts,
    updateStudentStates,
  };
};

export default connect(states)(Component);
