import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import auth from "./auth";
import modal from "./modal";
import ability from "./ability";
import category from "./category";
import cohort from "./cohort";
import event from "./event";
import mentor from "./mentor";
import student from "./student";
import user from "./user";
import meet from "./meet";
import academy from "./academy";
import users from "./users";
import talent from "./talent";
import analytics from "./analytics";
import content from "./content";
import request from "./request";
import certificate from "./certificate";
import invitation from "./invitation";
import unit from "./unit";
import notification from "./notification";
import cdoc from "./cdoc";
import storage from "redux-persist/lib/storage";
import theme from "./theme";
import confirmation from "./confirmation";
import confirmationAuth from "./confirmationAuth";
import video from "./video";
import roadmap from "./roadmap";
import image from "./image";
import slideshow from "./slideshow";
import { persistReducer } from "redux-persist";
import suscription from "./suscription";
import comment from "./comment";
import listen from "./listen";
import generalContent from "./generalContent";
import byte from "./byte";
import tag from "./tag";
const persistConfig = {
  key: "root1",
  storage,
};
const persistedReducer = persistReducer(persistConfig, theme);
const createRootReducer = (history: any) =>
  combineReducers({
    routerStore: connectRouter(history),
    themeStore: persistedReducer,
    videoStore: video,
    roadmapStore: roadmap,
    generalContentStore: generalContent,
    usersStore: users,
    slideshowStore: slideshow,
    tagStore: tag,
    listenStore: listen,
    imageStore: image,
    confirmationStore: confirmation,
    commentStore: comment,
    suscriptionStore: suscription,
    requestStore: request,
    confirmationAuthStore: confirmationAuth,
    notificationStore: notification,
    unitStore: unit,
    invitationStore: invitation,
    certificateStore: certificate,
    contentStore: content,
    analyticStore: analytics,
    talentStore: talent,
    authStore: auth,
    cdocStore: cdoc,
    academyStore: academy,
    modalStore: modal,
    userStore: user,
    abilityStore: ability,
    categoryStore: category,
    cohortStore: cohort,
    eventStore: event,
    mentorStore: mentor,
    studentStore: student,
    meetStore: meet,
    byteStore: byte,
  });

export default createRootReducer;
