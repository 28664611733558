// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}

.textLayer{
    z-index: -1;
}

.annotationLayer{
    z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/SlideShow/View/style.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":[".react-pdf__Page__canvas {\n    margin: 0 auto;\n    width: 100% !important;\n    height: 100% !important;\n}\n\n.textLayer{\n    z-index: -1;\n}\n\n.annotationLayer{\n    z-index: -1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
