import { Formik } from "formik";
import { Input } from "./style";
import { AiOutlineSearch } from "react-icons/ai";
interface ComponentProps {
  setFilter;
  items;
}

const Component = ({ setFilter, items }: ComponentProps) => {
  return (
    <div
      style={{
        width: "100%",
        flexGrow: 1,
        maxWidth: "450px",
      }}
    >
      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => {}}
        enableReinitialize
      >
        {({ handleSubmit }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                handleSubmit(event);
              }}
              id="userid-filter-form"
            >
              <div
                style={{
                  position: "relative",
                  boxShadow: "0 0 6px 0 rgba(36, 150, 237, 0.25)",
                  borderRadius: "10px",
                  overflow: "hidden",
                  flexGrow: 1,
                }}
              >
                <AiOutlineSearch
                  color="black"
                  size={20}
                  fontWeight={900}
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "12px",
                    zIndex: 2,
                  }}
                ></AiOutlineSearch>
                <Input
                  name="userId"
                  placeholder="Busca por nombre del cohort"
                  onChange={(ev) => {
                    setFilter({
                      field: "name",
                      value: ev.target.value !== "" ? ev.target.value : "no",
                      special: (academy, value) => {
                        return academy.name
                          .toLowerCase()
                          .includes(value.toLowerCase());
                      },
                    });
                  }}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Component;
