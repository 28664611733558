import { Filter } from "../..";
import { BoxShadowSearchWrapper, Input } from "./style";
import { AiOutlineSearch } from "react-icons/ai";
import { useEffect } from "react";

interface ComponentProps {
  searchQuery;
  setFilter;
  value;
}

const Component = ({ searchQuery, setFilter, value }: ComponentProps) => {
  return (
    <div
      style={{
        width: "calc(100% - 10px)",
        margin: "0 auto 10px auto",
      }}
    >
      <BoxShadowSearchWrapper>
        <AiOutlineSearch
          color="black"
          size={20}
          fontWeight={900}
          style={{
            position: "absolute",
            left: "8px",
            top: "12px",
            zIndex: 2,
          }}
        ></AiOutlineSearch>
        <Input
          name="name"
          placeholder="Busca por nombre"
          style={{ height: "45px" }}
          searchQuery={searchQuery}
          setFilter={setFilter}
          value={!value ? "" : value}
        />
      </BoxShadowSearchWrapper>
    </div>
  );
};

export default Component;
