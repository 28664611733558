import styled, { css } from "styled-components";
import { NavLink as NavLinkUi } from "react-router-dom";

export const DropdownMenu = styled("div")<{ amountItems; isActiveDropdown }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
  transition: height 0.3s ease-in-out, max-height 0.3s ease-in-out;
  opacity: ${({ isActiveDropdown }) => (isActiveDropdown ? 1 : 0)};
  height: ${({ amountItems }) => amountItems * 34 + 20}px;
  max-height: ${({ isActiveDropdown, amountItems }) =>
    isActiveDropdown ? amountItems * 34 + 20 : 0}px;
  ${(props) =>
    !!props.subChilds
      ? `max-height:${
          props.isActiveDropdown ? "unset" : `${props.amountItems * 34}px`
        }};height:unset`
      : ""}
`;

export const Link = css`
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  padding: 0px 15px 0px 35px;
  position: relative;
  border-radius: 0px 0px 0px 0px;
  transition: all 0.25s ease-in-out;
  color: ${(props) => props.theme.colors.Gray};
  font-size: 13px;
  //background-color: white;

  &.no-styles {
    border: 0;
    margin: 0;
    background: transparent;

    &:before,
    &:after {
      display: none;
    }
  }

  &.active {
    font-weight: 500;
    background-color: ${(props) => props.theme.colors.LightBlueHover};
    border-radius: 10px;
    color: ${(props) => props.theme.colors.LightBlue};
    svg {
      fill: ${(props) => props.theme.colors.LightBlueIcon};
    }
    i {
      &:before {
        background-color: ${(props) => props.theme.colors.LightBlue};
      }
    }

    .caret {
      transform: rotate(180deg);
    }
  }

  &:not(.active):hover {
    background-color: ${(props) => props.theme.colors.LightGray};
    border-radius: 10px;
    color: ${(props) => props.theme.colors.DarkGray};

    i {
      &:before {
        background-color: ${(props) => props.theme.colors.DarkGray};
      }
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
      margin-right: 10px;
      &:before {
        transition: all 0.25s ease-in-out;
      }
    }
  }
`;

export const ButtonLink = styled("button")`
  border: 0px;
  margin: 0px;
  background: none;
  cursor: pointer;
  margin-bottom: 2px;
  ${Link}
`;

export const NavLink = styled(NavLinkUi)`
  ${Link}
`;

export const Navbar = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Nav = styled("nav")`
  grid-area: nav;

  &.sidebar-collapsed {
    & > ul {
      & > li > ${ButtonLink},
      & > li > ${NavLink}{
          span {
            text-indent: -999px;
          }
        }

        ${ButtonLink} {
          div:last-child {
            display: none;
          }
        }
      }
    }
  }
`;

export const NavItems = styled("ul")`
  margin: 0px;
  padding: 0px;
  list-style: none;
`;

export const NavItem = styled("li")`
  padding: 0 10px;
  position: relative;

  &.sidebar-collapsed {
    &:hover {
      & > button {
        & + div {
          opacity: 1 !important;
          z-index: 10 !important;
          left: 100%;
        }
      }
    }

    & > button {
      & + div {
        opacity: 0 !important;
        z-index: -1 !important;
        height: auto;
        max-height: none;
        padding: 8px 10px;
        position: absolute;
        left: -1000%;
        bottom: 0px;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
`;

export const Caret = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
  transform: rotate(0deg);
  width: 24px;
  height: 24px;

  &.active {
    transform: rotate(90deg);
  }
`;

export const DropdownItem = styled("div")`
  min-width: 195px;
  margin-bottom: 1px;
`;

export const DropdownMenuChild = styled(DropdownMenu)`
  height: 100%;
  justify-content: unset;
  max-height: ${(props) =>
    props.isActiveDropdown ? props.amountItems * 75 : 0}px;
`;

export const SubNavLink = styled(NavLinkUi)`
  text-decoration: none;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 7px 10px;
  border-radius: 10px;
  transition: all 0.25s ease-in-out;
  color: ${(props) => props.theme.colors.Gray};
  background-color: white;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.no-styles {
    border: 0;
    margin: 0;
    background: transparent;

    &:before,
    &:after {
      display: none;
    }
  }

  &.active {
    font-weight: 500;
    background-color: ${(props) => props.theme.colors.LightBlueHover};
    color: ${(props) => props.theme.colors.LightBlue};
    svg {
      fill: ${(props) => props.theme.colors.LightBlueIcon};
    }
  }

  &:not(.active):hover {
    background-color: ${(props) => props.theme.colors.LightGray};
    color: ${(props) => props.theme.colors.DarkGray};
  }
`;
