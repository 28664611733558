import * as Yup from "yup";

export const schema = Yup.object().shape({
  name: Yup.string().required("Ingresa un nombre."),
});

interface FormValues {
  name: string;
}

export const initialValues: FormValues = {
  name: "",
};
