import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
/* import { AnyObject } from "chart.js/types/basic"; */
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const Component = ({ pieData }) => {
  /* const plugins: Plugin<"pie", AnyObject>[] = [
    {
      id: "",
    },
  ]; */

  const data = {
    labels: pieData.map((data) => data.label),
    datasets: [
      {
        label: "# of Votes",
        data: pieData.map((data) => data.value),
        backgroundColor: [
          "#210043",
          "#7137c7",
          "rgba(0, 15, 55, 0.8)",
          "rgba(113, 55, 199, 0.8)",
          "rgba(113, 55, 199, 0.7)",
          "rgba(113, 55, 199, 0.6)",
          "rgba(113, 55, 199, 0.4)",
          "rgba(33, 0, 67, 0.2)",
          "rgba(113, 55, 199, 0.15)",
          "rgba(33, 0, 67, 0.15)",
          "#eae0fb",
        ],
        borderColor: [
          "#210043",
          "#7137c7",
          "rgba(0, 15, 55, 0.8)",
          "rgba(113, 55, 199, 0.8)",
          "rgba(113, 55, 199, 0.7)",
          "rgba(113, 55, 199, 0.6)",
          "rgba(113, 55, 199, 0.4)",
          "rgba(33, 0, 67, 0.2)",
          "rgba(113, 55, 199, 0.15)",
          "rgba(33, 0, 67, 0.15)",
          "#eae0fb",
        ],
        borderWidth: 1,
        pieceLabel: { mode: "percentage", precision: 2 },
        labels: {
          render: "label",
        },
      },
    ],
  };
  return (
    <Pie
      data={data}
      options={{
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "#fff",
            align: "center",

            display: true,
            formatter(value, context) {
              return value;
            },
            font: {
              weight: "bold",
              size: 20,
            },
          },
        },
      }}
    />
  );
};

export default Component;
