import { UserDto } from "../../types/user.dto";

export const GET_USER_INFORMATION = "GET_USER_INFORMATION";
export const GET_USER_INFORMATION_SUCCESS = "GET_USER_INFORMATION_SUCCESS";
export const GET_USER_INFORMATION_ERROR = "GET_USER_INFORMATION_ERROR";

export const NEW_USER = "NEW_USER";
export const NEW_USER_ERROR = "NEW_USER_ERROR";
export const NEW_USER_SUCCESS = "NEW_USER_SUCCESS";
export const RESET_NEW_USER = "RESET_NEW_USER";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const RESET_UPDATE_USER = "RESET_UPDATE_USER";

export const SET_USER_INFORMATION = "SET_USER_INFORMATION";
export const SET_USER_INFORMATION_SUCCESS = "SET_USER_INFORMATION_SUCCESS";
export const SET_USER_INFORMATION_ERROR = "SET_USER_INFORMATION_ERROR";
export const RESET_SET_USER_INFORMATION = "RESET_SET_USER_INFORMATION";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const SHOWN_COHORT = "SHOWN_COHORT";

export interface ShownCohort {
  type: typeof SHOWN_COHORT;
  payload: { _id: string };
}

export interface ForgotPassword {
  type: typeof FORGOT_PASSWORD;
  payload: { _id: string; password: string };
}
export interface GetUserInformation {
  type: typeof GET_USER_INFORMATION;
  payload: string;
}

export interface GetUserInformationSuccess {
  type: typeof GET_USER_INFORMATION_SUCCESS;
  payload: UserDto;
}

export interface GetUserInformationError {
  type: typeof GET_USER_INFORMATION_ERROR;
  payload: boolean | string;
}

export interface SetUserInformation {
  type: typeof SET_USER_INFORMATION;
  payload: UserDto;
}

export interface SetUserInformationSuccess {
  type: typeof SET_USER_INFORMATION_SUCCESS;
  payload: UserDto;
}

export interface SetUserInformationOut {
  type: typeof SET_USER_INFORMATION_ERROR;
  payload: boolean | string;
}

export interface NewUser {
  type: typeof NEW_USER;
  payload: UserDto;
}

export interface NewUserSuccess {
  type: typeof NEW_USER_SUCCESS;
  payload: UserDto;
}

export interface ResetSetUserInformation {
  type: typeof RESET_SET_USER_INFORMATION;
  payload: null;
}

export interface NewUserError {
  type: typeof NEW_USER_ERROR;
  payload: boolean | string;
}

export interface ResetNewUser {
  type: typeof RESET_NEW_USER;
  payload: null;
}

export interface UpdateUser {
  type: typeof UPDATE_USER;
  payload: UserDto;
}

export interface UpdateUserSuccess {
  type: typeof UPDATE_USER_SUCCESS;
  payload: UserDto;
}

export interface UpdateUserError {
  type: typeof UPDATE_USER_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateUser {
  type: typeof RESET_UPDATE_USER;
  payload: null;
}

export interface State {
  data: UserDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetUserInformation
  | GetUserInformationSuccess
  | GetUserInformationError
  | SetUserInformation
  | SetUserInformationSuccess
  | SetUserInformationOut
  | NewUser
  | NewUserSuccess
  | NewUserError
  | ResetNewUser
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserError
  | ResetUpdateUser
  | ResetSetUserInformation
  | ForgotPassword
  | ShownCohort;
