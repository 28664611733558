import styled from "styled-components";

export const ListItem = styled("div")`
  background-color: #fff;
  padding: 4px 20px;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(79, 38, 162, 0.16);
`;

export const EntityImageContainer = styled("div")`
  width: 32px;
  height: 32px;
  overflow: hidden;
  box-shadow: 0 0 1px 0 rgba(0, 30, 108, 0.16);
  border-radius: 50%;
`;

export const EntityTitleSemiBold = styled("h3")`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  color: #2a2747;
`;
