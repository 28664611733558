import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_VIDEO,
  GET_VIDEO_SUCCESS,
  GET_VIDEO_ERROR,
  GET_ALL_VIDEOS,
  GET_ALL_VIDEOS_SUCCESS,
  GET_ALL_VIDEOS_ERROR,
  NEW_VIDEO,
  NEW_VIDEO_ERROR,
  NEW_VIDEO_SUCCESS,
  NewVideo,
  UPDATE_VIDEO,
  UPDATE_VIDEO_SUCCESS,
  UPDATE_VIDEO_ERROR,
  DELETE_VIDEO,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_ERROR,
  GetVideo,
  GetAllVideos,
  UpdateVideo,
  DeleteVideo,
  UploadMedia,
  UPLOAD_MEDIA,
  AddUserToVideo,
  ADD_USER_TO_VIDEO,
  SharedVideo,
  SHARED_VIDEO,
  QueryVideo,
  QUERY_VIDEO,
  QUERY_VIDEO_ERROR,
  QUERY_VIDEO_SUCCESS,
  SaveVideo,
  SAVE_VIDEO,
  UPDATE_CURRENT_VIDEO_LIST,
  UPDATE_CURRENT_VIDEO,
  SAVED_VIDEO,
  UNSAVED_VIDEO,
  AddViewVideo,
  ADD_VIEW_VIDEO,
  UPDATE_ONE_VIDEO_SUCCESS,
  UPDATE_ONE_VIDEO_ERROR,
  SET_GET_VIDEO,
  SetGetVideo,
  MANAGE_LIKE_DISLIKE_VIDEO,
  AddClickVideo,
  ADD_CLICK_VIDEO,
  ManageLikeDislikeVideo,
  GET_VIDEOS_OFFSET,
  GET_VIDEOS_OFFSET_ERROR,
  GET_VIDEOS_OFFSET_SUCCESS,
  GetVideosOffset,
  UPDATE_ITEM_LIST_VIDEO,
} from "../types/video";

const videosOffset = ({ videoStore }) => videoStore.offSet.data;
const allVideos = ({ videoStore }) => videoStore.all.data;
const user = ({ userStore }) => userStore.data;

function* getAllVideos({ payload }: GetAllVideos) {
  const { response, error } = yield call(services.video.getAll, payload);
  if (error) {
    yield put({
      type: GET_ALL_VIDEOS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_VIDEOS_SUCCESS, payload: response });
  }
}

function* getVideo({ payload }) {
  const { response, error } = yield call(services.video.getOne, payload);

  if (error) {
    yield put({
      type: GET_VIDEO_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_VIDEO_SUCCESS, payload: response });
  }
}

function* saveVideo({ payload }) {
  const { response, error } = yield call(services.video.saveVideo, payload);

  if (error) {
    yield put({
      type: UPDATE_VIDEO_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const currentVideos = yield select(allVideos);
    const currentUser = yield select(user);
    const updatedCurrentVideos = currentVideos.map((item) => {
      if (item._id === payload._id) {
        if (payload.condition === "unsave") {
          const index = item.savedBy.indexOf(currentUser._id);

          if (index !== -1) {
            item.savedBy.splice(index, 1);
          }

          return item;
        } else {
          return { ...item, savedBy: [...item.savedBy, currentUser._id] };
        }
      } else {
        return item;
      }
    });

    yield all([
      put({ type: UPDATE_VIDEO_SUCCESS, payload: response }),
      ...(!!payload.individual
        ? [put({ type: SET_GET_VIDEO, payload: response })]
        : [
            put({
              type: GET_ALL_VIDEOS_SUCCESS,
              payload: updatedCurrentVideos,
            }),
          ]),
    ]);
  }
}

function* queryVideo({ payload }) {
  const { response, error } = yield call(services.video.query, payload);

  if (error) {
    yield put({
      type: QUERY_VIDEO_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: QUERY_VIDEO_SUCCESS, payload: response });
  }
}

function* newVideo({ payload }) {
  const { response, error } = yield call(services.video.new, payload);

  if (error) {
    yield put({
      type: NEW_VIDEO_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_VIDEO_SUCCESS, payload: response })]);
  }
}

function* updateVideo({ payload }) {
  const { response, error } = yield call(services.video.update, payload);

  if (error) {
    yield put({
      type: UPDATE_VIDEO_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_VIDEO_SUCCESS, payload: response })]);
  }
}
function* addUserToVideo({ payload }) {
  const { response, error } = yield call(services.video.addUserVideo, payload);

  if (error) {
    yield put({
      type: UPDATE_VIDEO_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_VIDEO_SUCCESS, payload: response })]);
  }
}

function* uploadMedia({ payload }) {
  const { response, error } = yield call(services.video.update, payload);

  if (error) {
    yield put({
      type: UPDATE_VIDEO_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_VIDEO_SUCCESS, payload: response })]);
  }
}

function* deleteVideo({ payload }) {
  const { response, error } = yield call(services.video.delete, payload);

  if (error) {
    yield put({
      type: DELETE_VIDEO_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_VIDEO_SUCCESS, payload: response })]);
  }
}

function* shareVideo({ payload }) {
  const { response, error } = yield call(services.video.shareVideo, payload);

  if (error) {
    yield put({
      type: UPDATE_VIDEO_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_VIDEO_SUCCESS, payload: response })]);
  }
}

function* addView({ payload }) {
  const { response, error } = yield call(services.video.addView, payload);
  /* 
  if (error) {
    yield put({
      type: UPDATE_VIDEO_ERROR, 
      payload: error,
    });
  } else {
    const newListVideo = yield select(videoList);
      const newListVideoAdded = newListVideo.map((item) =>
      item._id === response._id ? item : response
    );
    yield all([put({ type: UPDATE_VIDEO_SUCCESS, payload: response })]);
  } */
}

function* addClick({ payload }) {
  const { response, error } = yield call(services.video.addClick, payload);
  /*  if (error) {
  } else {
    const currentListOfRoadmaps = yield select(companyList);
    const newListofRoadmaps = currentListOfRoadmaps?.map((content) => {
      if (content._id === response._id) {
        return response;
      }
      return content;
    });
    yield put({
      type: UPDATE_CURRENT_ROADMAP_LIST,
      payload: newListofRoadmaps,
    });
  } */
}

function* manageLikeDislike({ payload }) {
  const { response, error } = yield call(
    services.video.manageLikeDislike,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_VIDEO_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_VIDEO_SUCCESS, payload: response });

    /*  if (payload.individual) {
      yield put({ type: SET_GET_VIDEO, payload: response });
    } */
  }
}

function* getVideosOffset({ payload }) {
  const { response, error } = yield call(services.video.getOffset, payload);
  if (error) {
    yield put({
      type: GET_VIDEOS_OFFSET_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const offsetVideos = yield select(videosOffset);
    const newOffsetVideos = !offsetVideos
      ? response
      : [...offsetVideos, ...response];
    yield put({
      type: GET_VIDEOS_OFFSET_SUCCESS,
      payload: newOffsetVideos,
    });
  }
}

function* updateVideoListItem({ payload }) {
  const { response, error } = yield call(services.video.update, payload);

  if (error) {
    yield put({
      type: UPDATE_VIDEO_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_VIDEO_SUCCESS, payload: response }),
      put({ type: SET_GET_VIDEO, payload: response }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllVideos>(GET_ALL_VIDEOS, getAllVideos);
  yield takeLatest<GetVideo>(GET_VIDEO, getVideo);
  yield takeLatest<NewVideo>(NEW_VIDEO, newVideo);
  yield takeLatest<UpdateVideo>(UPDATE_VIDEO, updateVideo);
  yield takeLatest<DeleteVideo>(DELETE_VIDEO, deleteVideo);
  yield takeLatest<UploadMedia>(UPLOAD_MEDIA, uploadMedia);
  yield takeLatest<AddUserToVideo>(ADD_USER_TO_VIDEO, addUserToVideo);
  yield takeLatest<SharedVideo>(SHARED_VIDEO, shareVideo);
  yield takeLatest<QueryVideo>(QUERY_VIDEO, queryVideo);
  yield takeLatest<SaveVideo>(SAVED_VIDEO, saveVideo);
  yield takeLatest<SaveVideo>(SAVE_VIDEO, saveVideo);
  yield takeLatest<AddViewVideo>(ADD_VIEW_VIDEO, addView);
  yield takeLatest<AddClickVideo>(ADD_CLICK_VIDEO, addClick);
  yield takeLatest<ManageLikeDislikeVideo>(
    MANAGE_LIKE_DISLIKE_VIDEO,
    manageLikeDislike
  );
  yield takeLatest<GetVideosOffset>(GET_VIDEOS_OFFSET, getVideosOffset);
  yield takeLatest<any>(UPDATE_ITEM_LIST_VIDEO, updateVideoListItem);
}
