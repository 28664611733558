import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";

import { hideModal } from "../../../../../../store/actions/modal";

import {
  initialValues,
  schema,
} from "../../../../../../constants/form/suscription/change-suscription";

import Modal from "../../../../../../components/Modal";
import { Center } from "../../../../../../components/StyledComponents";

/* interface ComponentProps {
  updateUserStates: StateDto;
  user: UserDto;
} */

const Component = ({ onClose, title, children }) => {
  return (
    <Modal onClose={onClose} name="status-suscription-modal" title={title}>
      <Center>{children}</Center>
    </Modal>
  );
};

const states = ({ userStore }) => {
  return {};
};

export default connect(states)(Component);
