import { put, call, takeLatest, select, all } from "redux-saga/effects";
import services from "../../services";
import {
  NEW_CONTENT,
  NEW_CONTENT_ERROR,
  NEW_CONTENT_SUCCESS,
  NewContent,
  GET_ALL_CONTENTS,
  GET_ALL_CONTENTS_ERROR,
  GET_ALL_CONTENTS_SUCCESS,
  GetAllContents,
  UPDATE_CONTENT,
  UPDATE_CONTENT_ERROR,
  UPDATE_CONTENT_SUCCESS,
  UpdateContent,
  DELETE_CONTENT,
  UPDATE_CURRENT_CONTENT_LIST,
  DELETE_CONTENT_SUCCESS,
  DELETE_CONTENT_ERROR,
  UNSAVED_CONTENT,
  SAVED_CONTENT,
  AddClickContent,
  ADD_CLICK_CONTENT,
  AddViewContent,
  ADD_VIEW_CONTENT,
  UPDATE_ONE_CONTENT_ERROR,
  UPDATE_ONE_CONTENT_SUCCESS,
  ManageLikeDislikeContent,
  MANAGE_LIKE_DISLIKE_CONTENT,
  GET_CONTENTS_OFFSET,
  GET_CONTENTS_OFFSET_ERROR,
  GET_CONTENTS_OFFSET_SUCCESS,
  GetContentsOffset,
  SELECT_CONTENT,
  UPDATE_ITEM_LIST_CONTENT,
} from "../types/content";

const contentsOffset = ({ contentStore }) => contentStore.offSet.data;
const allContents = ({ contentStore }) => contentStore.all.data;
const user = ({ userStore }) => userStore.data;

function* newContent({ payload }) {
  const { response, error } = yield call(services.content.new, payload);

  if (error) {
    yield put({
      type: NEW_CONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: NEW_CONTENT_SUCCESS, payload: response });
  }
}

function* manageContent({ payload }) {
  const { response, error } = yield call(
    services.content.manageContent,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_CONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const currentContents = yield select(allContents);
    const currentUser = yield select(user);
    const updatedCurrentContents = currentContents.map((item) => {
      if (item._id === payload._id) {
        if (payload.condition === "unsave") {
          const index = item.savedBy.indexOf(currentUser._id);

          if (index !== -1) {
            item.savedBy.splice(index, 1);
          }

          return item;
        } else {
          return { ...item, savedBy: [...item.savedBy, currentUser._id] };
        }
      } else {
        return item;
      }
    });

    yield all([
      put({ type: UPDATE_CONTENT_SUCCESS, payload: response }),
      ...(!!payload.individual
        ? [put({ type: SELECT_CONTENT, payload: response })]
        : [
            put({
              type: GET_ALL_CONTENTS_SUCCESS,
              payload: updatedCurrentContents,
            }),
          ]),
    ]);
  }
}

function* deleteContent({ payload }) {
  const { response, error } = yield call(services.content.delete, payload);

  if (error) {
    yield put({
      type: DELETE_CONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: DELETE_CONTENT_SUCCESS, payload: response });
  }
}

function* updateContentListItem({ payload }) {
  const { response, error } = yield call(services.content.update, payload);

  if (error) {
    yield put({
      type: UPDATE_CONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_CONTENT_SUCCESS, payload: response }),
      put({ type: SELECT_CONTENT, payload: response }),
    ]);
  }
}

function* getAllContents({ payload }) {
  const { response, error } = yield call(services.content.getAll, payload);
  if (error) {
    yield put({
      type: GET_ALL_CONTENTS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_CONTENTS_SUCCESS, payload: response });
  }
}

function* updatedContent({ payload }) {
  const { response, error } = yield call(services.content.update, payload);

  if (error) {
    yield put({
      type: UPDATE_CONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_CONTENT_SUCCESS, payload: response });
  }
}
function* addClick({ payload }) {
  const { response, error } = yield call(services.content.addClick, payload);
  /*  if (error) {
  } else {
    const currentListOfContents = yield select(allContents);
    const newListofContents = currentListOfContents?.map((content) => {
      if (content._id === response._id) {
        return response;
      }
      return content;
    });
    yield put({
      type: UPDATE_CURRENT_CONTENT_LIST,
      payload: newListofContents,
    });
  } */
}

function* addView({ payload }) {
  const { response, error } = yield call(services.content.addView, payload);

  /* if (error) {
    yield put({
      type: UPDATE_CONTENT_ERROR,
      payload: error,
    });
  } else {
    const newListVideo = yield select(videoList);
      const newListVideoAdded = newListVideo.map((item) =>
      item._id === response._id ? item : response
    );
    yield all([put({ type: UPDATE_CONTENT_SUCCESS, payload: response })]);
  } */
}

function* manageLikeDislike({ payload }) {
  const { response, error } = yield call(
    services.content.manageLikeDislike,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_CONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_CONTENT_SUCCESS, payload: response });

    /* if (!!payload.individual) {
      yield put({ type: SET_GET_CONTENT, payload: response });
    } */
  }
}

function* getContentsOffset({ payload }) {
  const { response, error } = yield call(services.content.getOffset, payload);
  if (error) {
    yield put({
      type: GET_CONTENTS_OFFSET_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const offsetContents = yield select(contentsOffset);
    const newOffsetContents = !offsetContents
      ? response
      : [...offsetContents, ...response];
    yield put({
      type: GET_CONTENTS_OFFSET_SUCCESS,
      payload: newOffsetContents,
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewContent>(NEW_CONTENT, newContent);
  yield takeLatest<GetAllContents>(GET_ALL_CONTENTS, getAllContents);
  yield takeLatest<UpdateContent>(UPDATE_CONTENT, updatedContent);
  yield takeLatest<UpdateContent>(DELETE_CONTENT, deleteContent);
  yield takeLatest<any>(UNSAVED_CONTENT, manageContent);
  yield takeLatest<any>(SAVED_CONTENT, manageContent);
  yield takeLatest<AddClickContent>(ADD_CLICK_CONTENT, addClick);
  yield takeLatest<AddViewContent>(ADD_VIEW_CONTENT, addView);
  yield takeLatest<ManageLikeDislikeContent>(
    MANAGE_LIKE_DISLIKE_CONTENT,
    manageLikeDislike
  );
  yield takeLatest<GetContentsOffset>(GET_CONTENTS_OFFSET, getContentsOffset);
  yield takeLatest<any>(UPDATE_ITEM_LIST_CONTENT, updateContentListItem);
}
