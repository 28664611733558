import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FlexContainer } from "../../../../../components/StyledComponents";
import { CohortDto } from "../../../../../types/cohort.dto";
import Carousel from "../../../../../components/AsynCarousel";
import { BannerTitle } from "../../../components/Banner/style";
import {
  Slide,
  SlideImg,
  SlideSubTitle,
  SlideTitle,
} from "../../../../../components/Slider/style";
import {
  addClick as addClickCohort,
  getAllCohorts,
  getCohortsByAcademy,
} from "../../../../../store/actions/cohort";
import { useWindowSize } from "../../../../../hooks/useWindowScreen";
import { useEffect, useState } from "react";
import { AcademyDto } from "../../../../../types/academy.dto";
import { addClick } from "../../../../../store/actions/userTimes";
import { UserDto } from "../../../../../types/user.dto";
import CohortsCarousel from "../Home/components/CohortsCarousel";

const DEFAULT_IMAGE =
  "https://repository-images.githubusercontent.com/410214337/070f2aba-d9d6-4699-b887-9a0f29015b1b";
const ELEMENTS_PER_PAGE = 6;

interface FetchProps {
  page: number;
  filterBy?: { [key: string]: string | number | boolean | null };
}

interface ComponentProps {
  cohorts: CohortDto[];
  user: UserDto;
  cohortsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  academy: AcademyDto;
}

const Component = ({
  cohorts,
  cohortsStates,
  academy,
  user,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const [thereAreItems, setThereAreItems] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [items, setItems] = useState<CohortDto[]>([]);

  const getCohorts = ({ page, filterBy }: FetchProps) => {
    dispatch(
      getCohortsByAcademy({
        _id: academy._id,
        page,
        limit: ELEMENTS_PER_PAGE,
        offset: ELEMENTS_PER_PAGE * page,
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          hidden: false,
          academy: academy?._id,
        },
        select: {
          _id: 1,
          picture: 1,
          name: 1,
          startDate: 1,
          endDate: 1,
          type: 1,
        },
      })
    );
  };

  useEffect(() => {
    if (!cohortsStates.loading) {
      setItems([...items, ...(cohorts && cohorts.length > 0 ? cohorts : [])]);

      setThereAreItems(
        !!cohorts &&
          cohorts?.length > 0 &&
          cohorts?.length === ELEMENTS_PER_PAGE
      );
    }
  }, [cohorts, cohortsStates.loading]);

  return <CohortsCarousel />;
};

const states = ({ cohortStore, academyStore, userStore }) => {
  const { data: cohorts, states: cohortsStates } = cohortStore.allCohorts;
  const { data: academy } = academyStore.selected;
  const { data: user } = userStore;

  return {
    cohorts,
    cohortsStates,
    academy,
    user,
  };
};

export default connect(states)(Component);
