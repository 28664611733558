import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_IMAGE,
  GET_IMAGE_SUCCESS,
  GET_IMAGE_ERROR,
  GET_ALL_IMAGES,
  GET_ALL_IMAGES_SUCCESS,
  GET_ALL_IMAGES_ERROR,
  NEW_IMAGE,
  NEW_IMAGE_ERROR,
  NEW_IMAGE_SUCCESS,
  NewImage,
  UPDATE_IMAGE,
  UPDATE_IMAGE_SUCCESS,
  UPDATE_IMAGE_ERROR,
  DELETE_IMAGE,
  DELETE_IMAGE_SUCCESS,
  DELETE_IMAGE_ERROR,
  GetImage,
  GetAllImages,
  UpdateImage,
  DeleteImage,
  UploadMedia,
  UPLOAD_MEDIA,
  AddUserToImage,
  ADD_USER_TO_IMAGE,
  SharedImage,
  SHARED_IMAGE,
  QUERY_IMAGE_SUCCESS,
  QUERY_IMAGE_ERROR,
  QueryImage,
  QUERY_IMAGE,
  SaveImage,
  SAVED_IMAGE,
  AddViewImage,
  ADD_VIEW_IMAGE,
  UPDATE_ONE_IMAGE_ERROR,
  SET_GET_IMAGE,
  UPDATE_ONE_IMAGE_SUCCESS,
  MANAGE_LIKE_DISLIKE_IMAGE,
  AddClickImage,
  ADD_CLICK_IMAGE,
  ManageLikeDislikeImage,
  UPDATE_CURRENT_IMAGE_LIST,
  GET_IMAGES_OFFSET,
  GET_IMAGES_OFFSET_ERROR,
  GET_IMAGES_OFFSET_SUCCESS,
  GetImagesOffset,
  UPDATE_ITEM_LIST_IMAGE,
} from "../types/image";

const imageList = ({ imageStore }) => imageStore.all.data;

const imagesOffset = ({ imageStore }) => imageStore.offSet.data;

function* getAllImages({ payload }: GetAllImages) {
  const { response, error } = yield call(services.image.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_IMAGES_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_IMAGES_SUCCESS, payload: response });
  }
}

function* getImage({ payload }) {
  const { response, error } = yield call(services.image.getOne, payload);

  if (error) {
    yield put({
      type: GET_IMAGE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_IMAGE_SUCCESS, payload: response });
  }
}

function* saveImage({ payload }) {
  const { response, error } = yield call(services.image.saveImage, payload);

  if (error) {
    yield put({
      type: UPDATE_IMAGE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_IMAGE_SUCCESS, payload: response });
    if (!!payload.individual) {
      yield put({ type: SET_GET_IMAGE, payload: response });
    }
  }
}

function* newImage({ payload }) {
  const { response, error } = yield call(services.image.new, payload);

  if (error) {
    yield put({
      type: NEW_IMAGE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_IMAGE_SUCCESS, payload: response })]);
  }
}

function* queryImage({ payload }) {
  const { response, error } = yield call(services.image.query, payload);

  if (error) {
    yield put({
      type: QUERY_IMAGE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: QUERY_IMAGE_SUCCESS, payload: response });
  }
}

function* updateImage({ payload }) {
  const { response, error } = yield call(services.image.update, payload);
  if (error) {
    yield put({
      type: UPDATE_IMAGE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_IMAGE_SUCCESS, payload: response })]);
  }
}
function* addUserToImage({ payload }) {
  const { response, error } = yield call(services.image.addUserImage, payload);

  if (error) {
    yield put({
      type: UPDATE_IMAGE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_IMAGE_SUCCESS, payload: response })]);
  }
}

function* uploadMedia({ payload }) {
  const { response, error } = yield call(services.image.update, payload);

  if (error) {
    yield put({
      type: UPDATE_IMAGE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_IMAGE_SUCCESS, payload: response })]);
  }
}

function* deleteImage({ payload }) {
  const { response, error } = yield call(services.image.delete, payload);

  if (error) {
    yield put({
      type: DELETE_IMAGE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_IMAGE_SUCCESS, payload: response })]);
  }
}

function* shareImage({ payload }) {
  const { response, error } = yield call(services.image.shareImage, payload);

  if (error) {
    yield put({
      type: UPDATE_IMAGE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_IMAGE_SUCCESS, payload: response })]);
  }
}

function* addView({ payload }) {
  const { response, error } = yield call(services.image.addView, payload);

  /* if (error) {
    yield put({
      type: UPDATE_IMAGE_ERROR,
      payload: error,
    });
  } else {
    const newListVideo = yield select(videoList);
      const newListVideoAdded = newListVideo.map((item) =>
      item._id === response._id ? item : response
    );
    yield all([put({ type: UPDATE_IMAGE_SUCCESS, payload: response })]);
  } */
}

function* addClick({ payload }) {
  const { response, error } = yield call(services.image.addClick, payload);
  /*  if (error) {
  } else {
    const currentListOfContents = yield select(companyList);
    const newListofContents = currentListOfContents?.map((content) => {
      if (content._id === response._id) {
        return response;
      }
      return content;
    });
    yield put({
      type: UPDATE_CURRENT_CONTENT_LIST,
      payload: newListofContents,
    });
  } */
}

function* manageLikeDislike({ payload }) {
  const { response, error } = yield call(
    services.image.manageLikeDislike,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_IMAGE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_IMAGE_SUCCESS, payload: response });

    if (!!payload.individual) {
      yield put({ type: SET_GET_IMAGE, payload: response });
    }
  }
}
function* getImagesOffset({ payload }) {
  const { response, error } = yield call(services.image.getOffset, payload);
  if (error) {
    yield put({
      type: GET_IMAGES_OFFSET_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const offsetImages = yield select(imagesOffset);
    const newOffsetImages = !offsetImages
      ? response
      : [...offsetImages, ...response];
    yield put({
      type: GET_IMAGES_OFFSET_SUCCESS,
      payload: newOffsetImages,
    });
  }
}

function* updateImageListItem({ payload }) {
  const { response, error } = yield call(services.image.update, payload);

  if (error) {
    yield put({
      type: UPDATE_IMAGE_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_IMAGE_SUCCESS, payload: response }),
      put({ type: SET_GET_IMAGE, payload: response }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllImages>(GET_ALL_IMAGES, getAllImages);
  yield takeLatest<GetImage>(GET_IMAGE, getImage);
  yield takeLatest<NewImage>(NEW_IMAGE, newImage);
  yield takeLatest<UpdateImage>(UPDATE_IMAGE, updateImage);
  yield takeLatest<DeleteImage>(DELETE_IMAGE, deleteImage);
  yield takeLatest<UploadMedia>(UPLOAD_MEDIA, uploadMedia);
  yield takeLatest<AddUserToImage>(ADD_USER_TO_IMAGE, addUserToImage);
  yield takeLatest<SharedImage>(SHARED_IMAGE, shareImage);
  yield takeLatest<QueryImage>(QUERY_IMAGE, queryImage);
  yield takeLatest<SaveImage>(SAVED_IMAGE, saveImage);
  yield takeLatest<AddViewImage>(ADD_VIEW_IMAGE, addView);
  yield takeLatest<AddClickImage>(ADD_CLICK_IMAGE, addClick);
  yield takeLatest<ManageLikeDislikeImage>(
    MANAGE_LIKE_DISLIKE_IMAGE,
    manageLikeDislike
  );
  yield takeLatest<GetImagesOffset>(GET_IMAGES_OFFSET, getImagesOffset);
  yield takeLatest<any>(UPDATE_ITEM_LIST_IMAGE, updateImageListItem);
}
