import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import ReactSelect from "../../../../components/Form/ReactSelect/index";
import Input from "../../../../components/Form/Input/index";
import StatusChart from "./components/Status/index";
import SprintStats from "./components/SprintStats/index";
import Grid from "../../../../components/Grid";
import {
  FlexContainer,
  PageTitle,
} from "../../../../components/StyledComponents";
import Growing from "./components/Growing/index";
import { analyticTypes } from "../../../../constants/analytics-select";
import {
  schema,
  initialValues,
} from "../../../../constants/form/analytics/individual/index";
import { IndividualCard } from "../../../../components/LineGraph/styles";
import { connect, useDispatch } from "react-redux";
import {
  getIndividualStats,
  resetGetIndividualStats,
} from "../../../../store/actions/analytics";
import { toast } from "react-toastify";
import { getAllCohorts } from "../../../../store/actions/cohort";
import { useParams } from "react-router-dom";
import { StateDto } from "../../../../types/states.dto";
import { AnalyticsUserDto } from "../../../../types/analytics.dto";
import { CohortDto } from "../../../../types/cohort.dto";
import { UsersDto } from "../../../../types/users.dto";
import { AcademyDto } from "../../../../types/academy.dto";
import { CurrentDto } from "../../../../types/current.dto";
import { UserDto } from "../../../../types/user.dto";
import { GrowingStageWrapper } from "./components/NumericStats/styles";
import Loader from "../../../../components/Loader";
const Component = ({
  individualStatsState,
  individualData,
  cohorts,
  users,
  user,
  academies,
}: {
  individualStatsState: StateDto;
  individualData: AnalyticsUserDto[];
  cohorts: CohortDto[];
  users: UsersDto[];
  user: UserDto;
  academies: AcademyDto[];
}) => {
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [selectedStudent, setSelectedStudent] = useState<string>();
  const [selectedStudentId, setSelectedStudentId] = useState<{
    name?: string;
    id?: string;
  }>();
  const [chartSelected, setChartSelected] = useState<{
    label: string;
    value: string;
  }>();

  const [applyedFilters, setFilters] = useState<
    { field: string; value: string }[]
  >([]);

  const [cohortSelected, setCohortSelected] = useState<string>();

  /*  */
  const dispatch = useDispatch();
  const params = useParams();
  let formikRef: any = useRef(null);
  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter(
        (applyedFilter) => applyedFilter.field === newFilter.field
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    /**
     * Se elimina el filtro
     */
    if (filterRemoved) {
      setFilters([
        ...applyedFilters.filter(
          (applyedFilter) => applyedFilter.field !== newFilter.field
        ),
      ]);
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  /*   useEffect(() => {
    if (currentUsersList.length > 0) {
      const currentUsersValues = currentUsersList
        .filter((user) => user.active)
        .filter((user) => !user.hidden);
      setCurrentUsersListValues(currentUsersValues);
    }
  }, [currentUsersList, academies]); */

  useEffect(() => {
    !cohorts &&
      fetchOn &&
      dispatch(
        getAllCohorts({ filterBy: { studentsIds: user._id, active: true } })
      );
  }, [cohorts, fetchOn]);

  /*  useEffect(() => {
    if (params.id && users) {
      const userFounded = users.find((user) => user._id === params.id);
      if (userFounded) {
        dispatch(getIndividualStats(params.id));
        setSelectedStudent(`${userFounded?.name} ${userFounded?.lastName}`);
        setSelectedStudentId({
          id: userFounded._id,
          name: userFounded.name,
        });
      }
    }
  }, [params, users, dispatch]); */

  useEffect(() => {
    if (!cohorts) {
      setFetchOn(true);
    }
  }, [cohorts]);

  useEffect(() => {
    if (individualStatsState.success) {
      toast.success("👌🏼 Usero encontrado");
      if (!params.id) {
        setSelectedStudent(selectedStudentId?.name);
      }
      setTimeout(() => {
        formActions?.resetForm();
        dispatch(resetGetIndividualStats());
      }, 1000);
    }

    if (individualStatsState.error) {
      toast.error(individualStatsState.error);
      setTimeout(() => {
        dispatch(resetGetIndividualStats());
      }, 2000);
      setSelectedStudent(undefined);
    }
  }, [individualStatsState]);
  /* OFFSET DEJAR COL VACIAS
    LG largo
  */
  return (
    <>
      <PageTitle>Analytics Individual del Talento</PageTitle>
      <Grid.Row>
        <Grid.Col lg={12}>
          <Formik
            innerRef={(ref) => (formikRef = ref)}
            initialValues={initialValues}
            onSubmit={(values, actions) => {}}
            enableReinitialize
            validationSchema={schema}
          >
            {({
              handleSubmit,
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
            }) => {
              return (
                <form
                  onSubmit={(event) => {
                    handleSubmit(event);
                  }}
                  id="userid-a-form"
                >
                  <Grid.Row>
                    <Grid.Col lg={3}>
                      <ReactSelect
                        name="bootcamp"
                        error={errors["bootcamp"]}
                        touched={touched["bootcamp"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onOptionSelected={(newValue) => {
                          if (newValue) {
                            dispatch(
                              getIndividualStats({
                                _id: user._id,
                                cohorts: [newValue.value],
                              })
                            );
                            setCohortSelected(newValue.value);
                          }
                        }}
                        options={{
                          label: "Co-hort:",
                          marginBottom: 0,
                        }}
                        disabled={!cohorts}
                        {...(!!cohorts && {
                          items: cohorts
                            .filter((cohort) =>
                              cohort.studentsIds.find((el) => el === user._id)
                            )
                            .filter((cohort) => cohort.active)
                            .map((cohort) => {
                              return {
                                value: cohort._id,
                                label: cohort.name,
                              };
                            }),
                        })}
                      />
                    </Grid.Col>

                    <Grid.Col lg={3}>
                      <ReactSelect
                        name="chart"
                        error={errors["chart"]}
                        touched={touched["chart"]}
                        {...(!!individualData &&
                          !!individualData?.find(
                            (data) => data.cohort === values.bootcamp
                          ) &&
                          !!values.bootcamp && {
                            items: [
                              ...analyticTypes(),
                              ...[
                                ...Object.keys(
                                  individualData.filter(
                                    (data) => data.cohort === values.bootcamp
                                  )[0]?.stagesNotes
                                ).map((item) => {
                                  return { label: item, value: item };
                                }),
                              ].map((stage, index) => {
                                return {
                                  value: stage.value,
                                  label: `Etapa ${index + 1}`,
                                };
                              }),
                            ],
                          })}
                        disabled={!values.bootcamp}
                        onChange={handleChange}
                        notLoading
                        onBlur={handleBlur}
                        onOptionSelected={(newValue) =>
                          setChartSelected(newValue)
                        }
                        options={{
                          label: "Tablero",
                          marginBottom: 0,
                        }}
                      ></ReactSelect>
                    </Grid.Col>
                  </Grid.Row>
                </form>
              );
            }}
          </Formik>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row style={{ marginTop: "40px" }}>
        <Grid.Col>
          {individualData && cohortSelected && chartSelected ? (
            <IndividualCard>
              {chartSelected.value === "crecimiento" && (
                <Growing
                  cohortSelected={cohortSelected}
                  selectedStudent={user._id}
                ></Growing>
              )}
              {Object.keys(
                individualData.filter(
                  (data) => data.cohort === cohortSelected
                )[0]?.stagesNotes
              ).map((stage, index) => {
                return chartSelected.value === stage ? (
                  <SprintStats
                    cohortSelected={cohortSelected}
                    stage={index + 1}
                    stageLabel={stage}
                    selectedStudent={user._id}
                  ></SprintStats>
                ) : null;
              })}
              {chartSelected.value === "status" && (
                <StatusChart
                  cohortSelected={cohortSelected}
                  selectedStudent={user._id}
                ></StatusChart>
              )}
            </IndividualCard>
          ) : (
            <GrowingStageWrapper>
              <FlexContainer
                style={{ width: "100%", height: "100%" }}
                align="center"
                justify="center"
              >
                {individualStatsState.loading ? (
                  <Loader color="Primary"></Loader>
                ) : (
                  "Esperando seleccion"
                )}
              </FlexContainer>
            </GrowingStageWrapper>
          )}
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

const states = ({
  cohortStore,
  analyticStore,
  recruitingStore,
  usersStore,
  userStore,
  academyStore,
}) => {
  const { states: individualStatsState, data: individualData } =
    analyticStore.getIndividualStats;
  const { data: users } = usersStore.all;
  const { data: user } = userStore;
  const { states: cohortsStates, data: cohorts } = cohortStore.allCohorts;
  const { data: academies } = academyStore.all;
  return {
    individualStatsState,
    individualData,
    users,
    user,
    cohortsStates,
    cohorts,
    academies,
  };
};

export default connect(states)(Component);
