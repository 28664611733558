import styled from "styled-components";

export const Container = styled("div")<{ marginA?: number }>`
  ${(props) => {
    const { gutter, margin } = props.theme.grid;
    let styles = `
      width: 100%;
      padding-right: ${!!props.marginA ? props.marginA : 60}px;
      padding-left: ${!!props.marginA ? props.marginA : 60}px;
      margin-right: auto;
      margin-left: auto;
      
      @media ( max-width: 767px ) {
        padding-right: ${gutter}px;
        padding-left: ${gutter}px;
      }
      `;

    return styles;
  }}
`;
