import React from "react";
import {
  GridCardImageContainer,
  GridCardImage,
  GridCardTitle,
  GridHomeCard,
} from "../../../../../../../components/Layout/Dashboard/styles";
import { FlexContainer } from "../../../../../../../components/StyledComponents";
import { ItemDataText } from "../../../../../../Mobile/Personal/Home/style";
import { Img } from "../../../../../../SignIn/styles";
import { Icon } from "../../../../../Content/style";
import {
  BottomLabel,
  DescriptionSmallItem,
  LogoSmallItem,
  LogoWrapper,
  TextSmallItem,
} from "./style";
import { connect } from "react-redux";
import { DateFromNow } from "../../../../../../../helpers/format-date";
import { AiFillLock } from "react-icons/ai";
import MdCard from "./Component/MdCard";
import { AcademyDto } from "../../../../../../../types/academy.dto";

const Component = ({
  item,
  size,
  onClick,
  logoOnclick,
  academies,
  cardStyle,
}: {
  item;
  size: string;
  onClick;
  logoOnclick;
  academies: AcademyDto[];
  cardStyle?: {
    maxWidthCard?: string;
    cardImageHeight?: string;
    cardImageWidth?: string;
  };
}): JSX.Element => {
  return (
    <>
      {size === "lg" && (
        <GridHomeCard
          style={{ maxWidth: cardStyle?.maxWidthCard }}
          key={item._id}
          onClick={(ev) => {
            ev.preventDefault();
            if (onClick) onClick(item);
          }}
          pd={"10px"}
        >
          {item.icon && (
            <LogoWrapper>
              <item.icon color={"#fff"} size={21}></item.icon>
            </LogoWrapper>
          )}
          <GridCardImageContainer
            style={{ height: cardStyle?.cardImageHeight }}
          >
            {item.bottomLabel && <BottomLabel>{item.bottomLabel}</BottomLabel>}
            <GridCardImage
              src={
                item?.picture ||
                "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
              }
            ></GridCardImage>
          </GridCardImageContainer>
          <FlexContainer gap="12px">
            <Icon
              style={{ cursor: "pointer" }}
              onClick={(ev) => {
                if (logoOnclick) {
                  ev.preventDefault();
                  ev.stopPropagation();
                  logoOnclick(
                    item.academy && item.academy["_id"]
                      ? item.academy["_id"]
                      : item.academy
                  );
                }
              }}
            >
              <Img src={item.academy.picture}></Img>
            </Icon>
            <FlexContainer direction="column" gap="5px">
              <GridCardTitle style={{ fontSize: "14px" }}>
                {item.name}
              </GridCardTitle>

              <ItemDataText>{item.academy.name}</ItemDataText>

              <FlexContainer align="center" gap="5px">
                <ItemDataText>{`Vistas: ${item.viewCount}`}</ItemDataText>

                <span>|</span>

                <ItemDataText>{DateFromNow(item.createdAt)}</ItemDataText>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </GridHomeCard>
      )}
      {size === "sm" && (
        <GridHomeCard
          style={{ padding: "10px" }}
          key={item._id}
          onClick={(ev) => {
            ev.preventDefault();
            if (onClick) onClick(item);
          }}
        >
          {!!item.private && (
            <div style={{ position: "absolute", top: 0, right: 0 }}>
              <AiFillLock size={20}></AiFillLock>
            </div>
          )}
          <FlexContainer
            direction="column"
            gap="5px"
            align="center"
            justify="space-between"
          >
            <LogoSmallItem size="60px">
              <Img
                src={
                  item.picture ||
                  "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                }
              ></Img>
            </LogoSmallItem>
            <TextSmallItem>{item.name}</TextSmallItem>
            <DescriptionSmallItem>{`Miembros: ${item.userCount}`}</DescriptionSmallItem>
          </FlexContainer>
        </GridHomeCard>
      )}
      {size === "md" && <MdCard item={item} onClick={onClick}></MdCard>}
    </>
  );
};

const states = ({ academyStore }) => {
  const { data: academies } = academyStore.all;
  return { academies };
};

export default connect(states)(Component);
