import * as Yup from "yup";

export const schema = Yup.object().shape({
  password: Yup.string()
    .required("Este campo es requerido")
    .min(5, "Tiene que tener al menos 5 caracteres")
    .max(40, "Tiene que tener menos de 40 caracteres"),
  "confirm-password": Yup.string()
    .required("Este campo es requerido")
    .oneOf([Yup.ref("password"), null], "La contraseña no coincide"),
});

interface FormValues {
  password: string;
  "confirm-password": string;
}

export const initialValues = (intial): FormValues => {
  return { password: "", "confirm-password": "", ...intial };
};
