import { createEditorStateWithText } from "@draft-js-plugins/editor";
import React, { useEffect, useState } from "react";
import Share from "../../../../components/ShareLink";
import Editor from "@draft-js-plugins/editor";
import "./style.css";
import { FontSizes } from "../../../../constants/fontSizeCdos";
import { convertFromRaw, convertToRaw, EditorState, RichUtils } from "draft-js";
import { fontsFamilies } from "../../../../constants/fontSizeCdocs";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getCDOCs,
  manageLikeDislikeCdoc,
  resetUpdateCDOCs,
  setGetCdoc,
} from "../../../../store/actions/cdoc";
import { CDocDto } from "../../../../types/cdoc.dto";
import {
  FlexContainer,
  Separator,
} from "../../../../components/StyledComponents";
import { FiUpload } from "react-icons/fi";
import {
  AiFillDislike,
  AiFillLock,
  AiFillUnlock,
  AiOutlineCloudDownload,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";
import { HiUserAdd } from "react-icons/hi";
import { FaSave, FaShare } from "react-icons/fa";
import { BiLink } from "react-icons/bi";
import { BsFillMoonFill, BsFillTrash2Fill, BsSunFill } from "react-icons/bs";
import { CurrentDto } from "../../../../types/current.dto";
import SideBar from "./components/SideBar";
import { showModal } from "../../../../store/actions/modal";
import Loader from "../../../../components/Loader";
import { StateDto } from "../../../../types/states.dto";
import { toast } from "react-toastify";
import { UsersDto } from "../../../../types/users.dto";
import { CdocTitle } from "./style";

import ShareModal from "./components/ShareModal";
import { PDFExport } from "@progress/kendo-react-pdf";
import { overThousandFixed } from "../../../../helpers/number-fixed";
import {
  LikeDislikeBox,
  LikeDislikeBoxNumber,
  LikeDislikeText,
} from "../../../Cohort/Home/style";
import CommentSection from "../../../../components/CommentSection";
import { CommentDto } from "../../../../types/comment.dto";

import {
  addClick,
  addView,
  userTime,
} from "../../../../store/actions/userTimes";
import useTrackTimeAndAPI from "../../../../hooks/useTrackTimeAndAPI";
import Skeleton from "../../../../components/Skeleton";
export const stylesMapGlobal = [...FontSizes, ...fontsFamilies].reduce(
  (a, v) => ({ ...a, [v.label]: { [v.prop]: v.value } }),
  {}
);

const Component = ({
  cdoc,
  updateStatesCdoc,
  users,
  deleteCdocStates,
  theme,
  user,
  comments,
  getCdocStates,
}: {
  cdoc: CDocDto;
  updateStatesCdoc: StateDto;
  users: UsersDto[];
  deleteCdocStates: StateDto;
  theme: "light" | "dark";
  user: UsersDto;
  comments: CommentDto[];
  getCdocStates: StateDto;
}) => {
  const [editorState, setEditorState] = useState<EditorState>(
    createEditorStateWithText("")
  );
  const [previewState, setPreviewState] = useState<EditorState>(
    createEditorStateWithText("")
  );

  const [cdocName, setCdocName] = useState("");
  const params = useParams();

  const dispatch = useDispatch();
  let editorRef = React.useRef<any>(null);
  let printRef: any = React.useRef(null);
  const stylesMap = [...FontSizes, ...fontsFamilies].reduce(
    (a, v) => ({ ...a, [v.label]: { [v.prop]: v.value } }),
    {}
  );
  const handleExportWithComponent = (event) => {
    editorRef.current.save();
  };
  const textEditor = convertToRaw(editorState.getCurrentContent())
    .blocks.map((block) => (!block.text.trim() && "\n") || block.text)
    .join("\n");
  const apiAction = (timeSpent) => {
    dispatch(
      userTime({ ...timeSpent, id: params.id, user: user._id, type: "CDOC" })
    ); // Dispatch action to update Redux store or make API request
  };
  useEffect(() => {
    RichUtils.toggleInlineStyle(editorState, "FONT_FAMILY_Lato");
    RichUtils.toggleInlineStyle(editorState, "FONT_SIZE_10");
  }, []);

  useEffect(() => {
    if (params.id && user) {
      dispatch(addView({ id: params.id, user: user._id, type: "CONTENT" }));
      dispatch(getCDOCs(params.id));
    }
  }, [params.id, user]);
  useEffect(() => {
    if (!!cdoc) {
      setCdocName(cdoc.name);
      setEditorState(EditorState.createWithContent(convertFromRaw(cdoc.text)));
    }
  }, [cdoc]);

  useTrackTimeAndAPI(apiAction, params.id && user);
  useEffect(() => {
    return () => {
      dispatch(setGetCdoc(undefined));
    };
  }, []);

  useEffect(() => {
    if (updateStatesCdoc.success) {
      dispatch(getCDOCs(cdoc._id));
      toast.success(`👌🏼 Cdoc guardado`);
      dispatch(resetUpdateCDOCs());
    }
    if (updateStatesCdoc.error) {
    }
  }, [updateStatesCdoc]);

  useEffect(() => {
    if (getCdocStates.error) {
      toast.error(`${getCdocStates.error}`);
    }
  }, [getCdocStates]);

  const onChangeEditorState = (newStateEditor) => {
    setEditorState((state) => {
      setPreviewState(state);
      return newStateEditor;
    });
  };

  const options = {
    filename: `Professional ${new Date().valueOf()}`,
  };

  const isSaved = () => cdoc?.savedBy.includes(user._id);

  const menu = {
    ADMIN: [
      /* {
        Icon: FaShare,
        toolTipName: "Compartir",
        onAvailable: () => !!cdoc.available && !!cdoc.published,
        onClick: () => {
          dispatch(showModal("share-user-cdoc"));
        },
      }, */
      {
        Icon: BiLink,
        toolTipName: "Copiar Link",
        onClick: () => {
          navigator.clipboard.writeText(
            `${process.env.REACT_APP_URL}/dashboard/cdocs/${cdoc._id}`
          );
          toast.success(`Link copiado`);
          dispatch(
            addClick({
              id: cdoc._id,
              user: user._id,
              type: "CONTENT",
            })
          );
        },
      },

      /*   {
        Icon: FaSave,
        toolTipName: isSaved() ? "Guardado" : "Guardar",
        onClick: () => {
          dispatch(savedCdoc({ _id: cdoc._id, condition: "save" }));
        },
        activeFunction: () => isSaved(),
        onClickActive: () => {
          dispatch(savedCdoc({ _id: cdoc._id, condition: "unsave" }));
        },
      }, */
      /*  {
        Icon: AiOutlineCloudDownload,
        toolTipName: "Descargar",
        onClick: (ev) => {
          handleExportWithComponent(ev);
          toast.success(`Cdoc Descargado`);
        },
      }, */
    ],
    OBSERVER: {},
  };

  if (!cdoc)
    return (
      <Skeleton
        itemsPerPage={1}
        itemsPerRow={1}
        animated
        size={{ height: 800 }}
        icon="cdoc"
      />
    );

  return (
    <>
      <FlexContainer justify="space-between" align="center">
        <CdocTitle>{cdoc.name}</CdocTitle>

        <FlexContainer gap="12px" align="center">
          <LikeDislikeBox
            className={
              !!cdoc.likes.find((like) => like.user === user._id)
                ? "active"
                : ""
            }
            onClick={() => {
              if (cdoc.likes.find((like) => like.user === user._id)) {
                dispatch(
                  manageLikeDislikeCdoc({
                    _id: cdoc._id,
                    condition: "like",
                    action: "remove",
                    individual: true,
                  })
                );
                dispatch(
                  addClick({
                    id: cdoc._id,
                    user: user._id,
                    type: "CONTENT",
                  })
                );
              } else {
                dispatch(
                  manageLikeDislikeCdoc({
                    _id: cdoc._id,
                    condition: "like",
                    action: "add",
                    individual: true,
                  })
                );
                dispatch(
                  addClick({
                    id: cdoc._id,
                    user: user._id,
                    type: "CONTENT",
                  })
                );
              }
            }}
          >
            <FlexContainer gap="3px" align="center">
              <div
                className="like-dislike-icon"
                style={{ background: "#fff", padding: "4px" }}
              >
                <AiOutlineLike size={15} fill={"#2496ed"} />
              </div>
              <LikeDislikeBoxNumber className="count-likes">
                <LikeDislikeText>
                  {overThousandFixed(cdoc.likes.length)}
                </LikeDislikeText>
              </LikeDislikeBoxNumber>
            </FlexContainer>
          </LikeDislikeBox>
          <LikeDislikeBox
            className={
              !!cdoc.dislikes.find((like) => like.user === user._id)
                ? "active"
                : ""
            }
            onClick={() => {
              if (cdoc.dislikes.find((like) => like.user === user._id)) {
                dispatch(
                  manageLikeDislikeCdoc({
                    _id: cdoc._id,
                    condition: "dislike",
                    action: "remove",
                    individual: true,
                  })
                );
                dispatch(
                  addClick({
                    id: cdoc._id,
                    user: user._id,
                    type: "CONTENT",
                  })
                );
              } else {
                dispatch(
                  manageLikeDislikeCdoc({
                    _id: cdoc._id,
                    condition: "dislike",
                    action: "add",
                    individual: true,
                  })
                );
                dispatch(
                  addClick({
                    id: cdoc._id,
                    user: user._id,
                    type: "CONTENT",
                  })
                );
              }
            }}
          >
            <FlexContainer gap="3px" align="center">
              <div
                className="like-dislike-icon"
                style={{
                  padding: "7px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {cdoc.dislikes.find((like) => like.user === user._id) ? (
                  <AiFillDislike fill={"#fff"} size={15}></AiFillDislike>
                ) : (
                  <AiOutlineDislike
                    fill={"#2496ed"}
                    size={15}
                  ></AiOutlineDislike>
                )}
              </div>
            </FlexContainer>
          </LikeDislikeBox>
          <Share />
        </FlexContainer>
      </FlexContainer>

      <Separator size={15}></Separator>

      <div style={{ width: "100%", padding: "0 10px" }}>
        <PDFExport fileName={`${cdoc.name}`} ref={editorRef}>
          <div
            onClick={(ev) => {
              dispatch(
                addClick({
                  id: cdoc?._id,
                  user: user._id,
                  type: "CONTENT",
                })
              );
            }}
          >
            <Editor
              editorState={editorState}
              onChange={(newEditorState) => {
                onChangeEditorState(newEditorState);
              }}
              readOnly={true}
              preserveSelectionOnBlur
              plugins={[]}
              customStyleMap={stylesMap}
              ref={editorRef}
            />
          </div>
        </PDFExport>
      </div>
      <Separator size={20}></Separator>
      <CommentSection itemId={cdoc._id} section="content"></CommentSection>
      {!!cdoc && <ShareModal cdoc={cdoc}></ShareModal>}
    </>
  );
};

const states = ({
  cdocStore,
  usersStore,
  themeStore,
  userStore,
  commentStore,
}) => {
  const { data: cdoc, states: getCdocStates } = cdocStore.selected;
  const { states: updateStatesCdoc } = cdocStore.update;
  const { data: users } = usersStore.all;
  const { states: deleteCdocStates } = cdocStore.delete;
  const { theme } = themeStore;
  const { data: user } = userStore;
  const { data: comments } = commentStore.all;
  return {
    cdoc,
    updateStatesCdoc,
    users,
    deleteCdocStates,
    theme,
    user,
    comments,
    getCdocStates,
  };
};

export default connect(states)(Component);
