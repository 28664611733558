import {
  Actions,
  GET_INDIVIDUAL_STATS,
  GET_INDIVIDUAL_STATS_ERROR,
  GET_INDIVIDUAL_STATS_SUCCESS,
  State,
  initialValues,
  RESET_GET_INDIVIDUAL_STATS,
  GET_COHORT_STATS,
  GET_COHORT_STATS_SUCCESS,
  GET_COHORT_STATS_ERROR,
  RESET_GET_COHORT_STATS,
  GET_ALL_STUDENTS_STATS,
  GET_ALL_STUDENTS_STATS_SUCCESS,
  GET_ALL_STUDENTS_STATS_ERROR,
  GET_CAMPUS_STATS,
  GET_CAMPUS_STATS_SUCCESS,
  GET_CAMPUS_STATS_ERROR,
  RESET_GET_CAMPUS_STATS,
  GET_ACADEMY_USER_TIME,
  GET_ACADEMY_USER_TIME_ERROR,
  GET_ACADEMY_USER_TIME_SUCCESS,
  GET_COHORT_USER_TIME,
  GET_COHORT_USER_TIME_ERROR,
  GET_COHORT_USER_TIME_SUCCESS,
  RESET_GET_ACADEMY_USER_TIME,
  RESET_GET_COHORT_USER_TIME,
  GET_USER_TIME,
  GET_USER_TIME_ERROR,
  GET_USER_TIME_SUCCESS,
  RESET_GET_USER_TIME,
} from "../types/analytics";
const reducers = (state = initialValues, action: Actions): State => {
  switch (action.type) {
    case GET_INDIVIDUAL_STATS:
      return {
        ...state,
        getIndividualStats: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_INDIVIDUAL_STATS_SUCCESS:
      return {
        ...state,
        getIndividualStats: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_INDIVIDUAL_STATS_ERROR:
      return {
        ...state,
        getIndividualStats: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_INDIVIDUAL_STATS:
      return {
        ...state,
        getIndividualStats: {
          data: state.getIndividualStats.data,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };

    case GET_COHORT_USER_TIME:
      return {
        ...state,
        cohortUserTimes: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_COHORT_USER_TIME_SUCCESS:
      return {
        ...state,
        cohortUserTimes: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_COHORT_USER_TIME_ERROR:
      return {
        ...state,
        cohortUserTimes: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_COHORT_USER_TIME:
      return {
        ...state,
        cohortUserTimes: initialValues.cohortUserTimes,
      };
    case GET_USER_TIME:
      return {
        ...state,
        userTimes: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_USER_TIME_SUCCESS:
      return {
        ...state,
        userTimes: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_USER_TIME_ERROR:
      return {
        ...state,
        userTimes: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_USER_TIME:
      return {
        ...state,
        userTimes: initialValues.userTimes,
      };
    case GET_ACADEMY_USER_TIME:
      return {
        ...state,
        academyUserTimes: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ACADEMY_USER_TIME_SUCCESS:
      return {
        ...state,
        academyUserTimes: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ACADEMY_USER_TIME_ERROR:
      return {
        ...state,
        academyUserTimes: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ACADEMY_USER_TIME:
      return {
        ...state,
        academyUserTimes: initialValues.academyUserTimes,
      };

    case GET_CAMPUS_STATS:
      return {
        ...state,
        getCampusStats: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_CAMPUS_STATS_SUCCESS:
      return {
        ...state,
        getCampusStats: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_CAMPUS_STATS_ERROR:
      return {
        ...state,
        getCampusStats: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_CAMPUS_STATS:
      return {
        ...state,
        getCampusStats: {
          data: state.getCampusStats.data,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case GET_COHORT_STATS:
      return {
        ...state,
        getCohortStats: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case RESET_GET_COHORT_STATS:
      return {
        ...state,
        getCohortStats: {
          data: state.getCohortStats.data,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_STUDENTS_STATS:
      return {
        ...state,
        getAllStudentsStats: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_STUDENTS_STATS_SUCCESS:
      return {
        ...state,
        getAllStudentsStats: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_STUDENTS_STATS_ERROR:
      return {
        ...state,
        getAllStudentsStats: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_COHORT_STATS_ERROR:
      return {
        ...state,
        getCohortStats: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_COHORT_STATS_SUCCESS:
      return {
        ...state,
        getCohortStats: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    default:
      return state;
  }
};

export default reducers;
