import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Grid from "../../../../components/Grid";
import { GridStructure } from "../../../../components/Layout/Dashboard/styles";
import {
  Center,
  FlexContainer,
  PageTitle,
  Separator,
} from "../../../../components/StyledComponents";

import {
  getAllListens,
  queryListen,
  resetGetAllListens,
  resetNewListen,
} from "../../../../store/actions/listen";

import academyActions from "../../../../store/actions/academies";
import { CohortDto } from "../../../../types/cohort.dto";
import { StateDto } from "../../../../types/states.dto";
import { ListenDto } from "../../../../types/listen.dto";
import FilterButton from "./components/FilterButton";
import FilterModal from "./components/FilterModal";
import { AcademyDto } from "../../../../types/academy.dto";
import { UserDto } from "../../../../types/user.dto";
import FilterByName from "./components/FilterByName";
import { useNavigate } from "react-router-dom";
import InfinityScroll from "../../../../components/InfinityScroll";
import ToggleSlider from "../../../../components/ToggleSlider";
import { addClick } from "../../../../store/actions/userTimes";
import ContentCard from "../../../../components/ContentCard";
import { MdAudiotrack } from "react-icons/md";
import Skeleton from "../../../../components/Skeleton";

/* const filterValues: any = [
 {
    fieldName: ["visibility", "order"],
    value: "no",
    name: "Todos",
  },
  {
    fieldName: "order",
    value: "recent",
    name: "Recientes",
    groupal: (array: ListenDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "order",
    value: "older",
    name: "Antiguos",
    groupal: (array: ListenDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "published",
    value: true,
    name: "Publicos",
  },
  {
    fieldName: "published",
    value: false,
    name: "Privados",
  },
]; */

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  listens,
  newListenData,
  newListenDataStates,
  cohorts,
  academies,
  user,
  queryListens,
  queryListensStates,
  listensStates,
}: {
  listens: ListenDto[];
  newListenData: ListenDto;
  newListenDataStates: StateDto;
  cohorts: CohortDto[];
  academies: AcademyDto[];
  user: UserDto;
  queryListens: ListenDto[];
  queryListensStates: StateDto;
  listensStates: StateDto;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [queryMode, setQueryMode] = useState<string | null>(null);
  const [query, setQuery] = useState<string | null>(null);
  const filterValues: any = [
    {
      fieldName: "private",
      value: "no",
      name: "Todos",
    },
    {
      fieldName: "private",
      value: true,
      name: "Academias Privadas",
      special: (array, value, currentItem) => {
        return currentItem.academy.private === value;
      },
    },
    {
      fieldName: "private",
      value: false,
      name: "Academias Publicas",
      special: (array, value, currentItem) => {
        return currentItem.academy.private === value;
      },
    },
  ];

  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [currentListensList, setCurrentListensList] = useState<any[]>([]);
  const [
    filteredCurrentListensListValues,
    setFilteredCurrentListensListValues,
  ] = useState<ListenDto[]>([]);
  const handleQuery = (ev) => {
    const value = ev.target.value;
    setQuery(value.length === 0 ? "" : value);
  };
  const searchQuery = (ev) => {
    if (ev.keyCode === 13) {
      /*  dispatch(queryImage({ keyword: query })); */
      setQueryMode(query);
    }
  };

  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 9,
      offset: 9 * Math.floor(page / 2),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        published: true,
      },
    };
    const sortBy = page % 2 === 0 ? "createdAt" : "viewCount";

    const sortDirection = "desc";
    dispatch(
      getAllListens({
        ...payload,
        sortBy,
        sortDirection,
        ...(queryMode && { keyword: query }),
      })
    );
  };

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter((applyedFilter) =>
        !Array.isArray(newFilter.field)
          ? applyedFilter.field === newFilter.field
          : newFilter.field.includes(applyedFilter.field)
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    if (filterRemoved) {
      /**
       * Se elimina el filtro
       */
      if (Array.isArray(newFilter.field)) {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => !newFilter.field.includes(applyedFilter.field)
          ),
        ]);
      } else {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => applyedFilter.field !== newFilter.field
          ),
        ]);
      }
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  /* useEffect(() => {
    if (!!listens && !!academies && !queryListens) {
      const filterListens = listens.filter(
        (listen) => !!listen.active && !!listen.available && !!listen.published
      );

      setCurrentListensList(filterListens);
    }
    if (!!listens && !!academies && !!queryListens) {
      const filterListens = queryListens.filter(
        (listen) => !!listen.active && !!listen.available && !!listen.published
      );

      setCurrentListensList(filterListens);
    }
  }, [listens, academies, queryListens]); */

  useEffect(() => {
    if (queryMode) {
      setPage(0);
      setIsLoading(false);
      setThereAreItems(true);
      setCurrentListensList([]);
    }
  }, [queryMode]);

  useEffect(() => {
    const filteredTalents = currentListensList?.filter(
      (currentTalentValues) => {
        const thereAreFilters =
          applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
            .length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter(
            (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
          )
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value,
            currentTalentValues
          );
        });
        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentListensListValues(initResult);
  }, [applyedFilters, currentListensList]);

  useEffect(() => {
    if (!isLoading && !!listens) {
      setCurrentListensList((state) => [...state, ...listens]);
    }
    setThereAreItems(!!listens && listens?.length > 0);
  }, [listens, isLoading]);

  useEffect(() => {
    setIsLoading(listensStates.loading);
  }, [listensStates]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllListens());
    };
  }, []);

  useEffect(() => {
    if (newListenDataStates.success) {
      dispatch(resetNewListen());
    }
    if (newListenDataStates.error) {
      toast.error(newListenDataStates.error);
      dispatch(resetNewListen());
    }
  }, [newListenDataStates]);

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <PageTitle>Listens</PageTitle>
          </Grid.Col>
        </Grid.Row>
        <Center>
          <Grid.Row style={{ width: "100%" }}>
            <Grid.Col lg={12} md={12} sm={12} style={{ marginBottom: "14px" }}>
              <FlexContainer
                style={{ width: "100%", maxWidth: "700px", margin: "0 auto" }}
              >
                <FilterByName
                  searchQuery={searchQuery}
                  setFilter={handleQuery}
                  value={query}
                ></FilterByName>
              </FlexContainer>
            </Grid.Col>
          </Grid.Row>
        </Center>
        <Separator size={12}></Separator>
        {/*  <ToggleSlider>
          {filterValues.map((filterValue) => {
            return (
              <FilterButton
                setFilter={buildFilters}
                name={filterValue.name}
                fieldName={filterValue.fieldName}
                value={filterValue.value}
                filters={applyedFilters}
                {...((!!filterValue.special || !!filterValue.groupal) && {
                  special: !!filterValue.special
                    ? { name: "special", function: filterValue.special }
                    : { name: "groupal", function: filterValue.groupal },
                })}
              ></FilterButton>
            );
          })}
        </ToggleSlider> */}
        <Separator size={12}></Separator>
        <Grid.Row>
          <Grid.Col>
            <InfinityScroll
              action={fetchData}
              page={page}
              setPage={setPage}
              data={filteredCurrentListensListValues}
              active={thereAreItems}
              isLoading={isLoading}
              loader={
                <Skeleton
                  itemsPerPage={9}
                  itemsPerRow={3}
                  animated
                  size={{ height: 290, width: 276 }}
                  icon="listen"
                />
              }
            >
              <GridStructure
                width={"275px"}
                maxWidth="auto"
                style={{ width: "100%", margin: "0 auto" }}
                limitCard={"275px"}
              >
                {filteredCurrentListensListValues.map((item) => {
                  return (
                    <ContentCard
                      ContentTypeLogo={{ icon: MdAudiotrack }}
                      key={item._id}
                      item={item}
                      logoOnclick={(item) => {
                        dispatch(
                          addClick({
                            id: item.academy?._id,
                            user: user._id,
                            type: "ACADEMY",
                          })
                        );
                        navigate(`/dashboard/academy/${item.academy?._id}`);
                      }}
                      cardOnClick={(item) => {
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "CONTENT",
                          })
                        );
                        navigate(`/dashboard/listens/${item._id}`);
                      }}
                    ></ContentCard>
                  );
                })}
              </GridStructure>
            </InfinityScroll>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      {!!cohorts && <FilterModal buildFilters={buildFilters}></FilterModal>}
    </>
  );
};

const states = ({ listenStore, cohortStore, academyStore, userStore }) => {
  const { data: listens, states: listensStates } = listenStore.all;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: newListenData, states: newListenDataStates } = listenStore.new;
  const { data: academies } = academyStore.all;
  const { data: user } = userStore;
  const { data: queryListens, states: queryListensStates } = listenStore.query;
  return {
    listens,
    listensStates,
    newListenData,
    newListenDataStates,
    academies,
    cohorts,
    user,
    queryListens,
    queryListensStates,
  };
};

export default connect(states)(Component);
