import React, { useEffect, useState } from "react";
import { FlexContainer, Separator } from "../StyledComponents";
import {
  AiFillClockCircle,
  AiFillDislike,
  AiFillEye,
  AiOutlineCheck,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";
import Button from "../Button";
import { connect, useDispatch } from "react-redux";
import InfinityScroll from "../InfinityScroll";
import Grid from "../Grid";
import { DateFromNow } from "../../helpers/format-date";
import { useNavigate, useParams } from "react-router-dom";
import { addClick, addView, userTime } from "../../store/actions/userTimes";
import { UserDto } from "../../types/user.dto";
import academyActions from "../../store/actions/academies";
import {
  GridMobileCard,
  GridMobileImageCard,
  GridMobileInfoCard,
  ShowDescriptionText,
} from "../../pages/Mobile/style";
import {
  GridCardImage,
  GridCardSmallContainer,
  GridCardTitle,
  GridCardTitleMobile,
  GridComment,
  GridCommentMobile,
} from "../Layout/Dashboard/styles";
import Loader from "../Loader";
import {
  LikeDislikeBox,
  LikeDislikeBoxNumber,
  LikeDislikeText,
} from "../../pages/Cohort/Home/style";
import { overThousandFixed } from "../../helpers/number-fixed";
import ShareLink from "../ShareLink";
import CommentSectionMobile from "../CommentSectionMobile";
import FilterByName from "./components/FilterByName";
import useTrackTimeAndAPI from "../../hooks/useTrackTimeAndAPI";
import DescriptionModal from "../DescriptionModal";
import { AcademyDto } from "../../types/academy.dto";
import { GeneralContentDto } from "../../types/generalContent.dto";
import { StateDto } from "../../types/states.dto";
import { useTheme } from "styled-components";
import ModalConfirmDelete from "../ModalConfirmDelete";

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  content,
  user,
  currentContentAcademy,
  updateAcademyStates,
  manageLikeDislikeContent,
  searchQuery,
  handleQuery,
  query,
  fetchData,
  page,
  setPage,
  thereAreItems,
  isLoading,
  currentContentList,
}: {
  content: GeneralContentDto;
  user: UserDto;
  currentContentAcademy?: AcademyDto;
  updateAcademyStates: StateDto;
  manageLikeDislikeContent;
  searchQuery;
  handleQuery;
  query: string | null;
  fetchData;
  page: number;
  setPage;
  thereAreItems: boolean;
  isLoading: boolean;
  currentContentList: GeneralContentDto[];
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [openDescription, setOpenDescription] = useState<boolean>(false);
  const [action, setAction] = useState<{ action: string; data: any }>();
  const [openCommentsModal, setOpenCommentsModal] = useState<boolean>(false);

  return (
    <>
      <GridMobileCard>
        <div style={{ marginTop: "4px", padding: "0 10px" }}>
          <GridCardTitle style={{ fontSize: "14px" }}>
            {content.name}
          </GridCardTitle>
          <Separator size={8}></Separator>
          <FlexContainer gap="7px" align="center">
            <GridComment>{`Vistas: ${content.viewCount}`}</GridComment>
            <span>|</span>
            <FlexContainer gap="3px" align="center">
              <AiFillClockCircle fill="#6a6970"></AiFillClockCircle>
              <GridComment>{DateFromNow(content.createdAt)}</GridComment>
            </FlexContainer>
            <ShowDescriptionText
              onClick={() => {
                setOpenDescription(true);
              }}
            >
              Ver Descripcion
            </ShowDescriptionText>
          </FlexContainer>
          <FlexContainer
            wrap="wrap"
            gap="2px"
            align="center"
            justify="space-between"
          >
            <FlexContainer gap="7px" wrap="wrap" align="center">
              <GridCardSmallContainer>
                <GridCardImage src={content.academy.picture}></GridCardImage>
              </GridCardSmallContainer>
              <FlexContainer direction="column">
                <GridCommentMobile style={{ color: theme.colors.DarkNight }}>
                  {content.academy.name}
                </GridCommentMobile>

                <GridCommentMobile>{`${currentContentAcademy?.userCount} Suscriptos`}</GridCommentMobile>
              </FlexContainer>
            </FlexContainer>
            {currentContentAcademy ? (
              !currentContentAcademy.users.find(
                (userF) => userF.user === user._id && !userF.roleEnd
              ) ? (
                !currentContentAcademy.private ? (
                  <Button
                    type="button"
                    onClick={() => {
                      dispatch(
                        academyActions.addMember({
                          _id: currentContentAcademy._id,
                          user: user._id,
                          role: "STUDENT",
                        })
                      );
                      dispatch(
                        addClick({
                          id: currentContentAcademy._id,
                          user: user._id,
                          type: "ACADEMY",
                        })
                      );
                    }}
                    options={{
                      size: "md",
                      type: "filled",
                      skin: "lightblue",
                    }}
                    loading={updateAcademyStates.loading}
                  >
                    Suscribirse
                  </Button>
                ) : (
                  <></>
                )
              ) : (
                <Button
                  type="button"
                  onClick={() => {
                    setAction({
                      data: null,
                      action: "out-of-academy",
                    });
                    dispatch(
                      addClick({
                        id: currentContentAcademy._id,
                        user: user._id,
                        type: "ACADEMY",
                      })
                    );
                  }}
                  options={{
                    size: "md",
                    type: "outline",
                    skin: "lightblue",
                  }}
                  loading={updateAcademyStates.loading}
                >
                  <FlexContainer gap="4px" align="center">
                    <AiOutlineCheck
                      size={14}
                      color={theme.colors.LightBlue}
                    ></AiOutlineCheck>
                    <span>Suscripto</span>
                  </FlexContainer>
                </Button>
              )
            ) : (
              <Loader color="LightBlue"></Loader>
            )}
          </FlexContainer>
          <Separator size={10}></Separator>
          <FlexContainer gap="5px">
            <FlexContainer gap="12px" align="center">
              <LikeDislikeBox
                className={
                  !!content.likes.find((like) => like.user === user._id)
                    ? "active"
                    : ""
                }
                onClick={() => {
                  if (content.likes.find((like) => like.user === user._id)) {
                    dispatch(
                      manageLikeDislikeContent({
                        _id: content._id,
                        condition: "like",
                        action: "remove",
                        individual: true,
                        notReload: true,
                      })
                    );
                  } else {
                    dispatch(
                      manageLikeDislikeContent({
                        _id: content._id,
                        condition: "like",
                        action: "add",
                        individual: true,
                        notReload: true,
                      })
                    );
                  }
                }}
              >
                <FlexContainer gap="3px" align="center">
                  <div
                    className="like-dislike-icon"
                    style={{ background: "#fff", padding: "4px" }}
                  >
                    <AiOutlineLike size={15} fill={"#2496ed"} />
                  </div>
                  <LikeDislikeBoxNumber className="count-likes">
                    <LikeDislikeText>
                      {overThousandFixed(content.likes.length)}
                    </LikeDislikeText>
                  </LikeDislikeBoxNumber>
                </FlexContainer>
              </LikeDislikeBox>
              <LikeDislikeBox
                className={
                  !!content.dislikes.find((like) => like.user === user._id)
                    ? "active"
                    : ""
                }
                onClick={() => {
                  if (content.dislikes.find((like) => like.user === user._id)) {
                    dispatch(
                      addClick({
                        id: content._id,
                        user: user._id,
                        type: "CONTENT",
                      })
                    );
                    dispatch(
                      manageLikeDislikeContent({
                        _id: content._id,
                        condition: "dislike",
                        action: "remove",
                        individual: true,
                        notReload: true,
                      })
                    );
                  } else {
                    dispatch(
                      addClick({
                        id: content._id,
                        user: user._id,
                        type: "CONTENT",
                      })
                    );
                    dispatch(
                      manageLikeDislikeContent({
                        _id: content._id,
                        condition: "dislike",
                        action: "add",
                        individual: true,
                        notReload: true,
                      })
                    );
                  }
                }}
              >
                <FlexContainer gap="3px" align="center">
                  <div
                    className="like-dislike-icon"
                    style={{
                      padding: "7px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {content.dislikes.find((like) => like.user === user._id) ? (
                      <AiFillDislike fill={"#fff"} size={15}></AiFillDislike>
                    ) : (
                      <AiOutlineDislike
                        fill={"#2496ed"}
                        size={15}
                      ></AiOutlineDislike>
                    )}
                  </div>
                </FlexContainer>
              </LikeDislikeBox>

              <ShareLink
                style={{
                  text: {
                    color: theme.colors.White,
                  },
                  textBox: {
                    backgroundColor: theme.colors.LightBlue,
                  },
                }}
              />
            </FlexContainer>
          </FlexContainer>
          {/* <DropDownDescription text={content.description}></DropDownDescription> */}
          <Separator size={12} />
          <CommentSectionMobile
            isModalOpen={openCommentsModal}
            setIsModalOpen={setOpenCommentsModal}
            itemId={content._id}
            section="content"
          ></CommentSectionMobile>
          <Separator size={12} />
          <Grid.Row>
            <Grid.Col xs={12}>
              <FilterByName
                searchQuery={searchQuery}
                setFilter={handleQuery}
                value={query}
              ></FilterByName>

              <FlexContainer
                style={{
                  width: "100%",
                  marginTop: "10px",
                  maxHeight: "500px",
                  overflow: "auto",
                  padding: "0 5px",
                }}
              >
                <InfinityScroll
                  action={fetchData}
                  page={page}
                  setPage={setPage}
                  data={currentContentList}
                  active={thereAreItems}
                  isLoading={isLoading}
                  style={{ width: "100%", paddingBottom: "10px" }}
                >
                  {currentContentList.map((contentFounded) => {
                    if (contentFounded._id === content._id) return null;
                    return (
                      <GridMobileCard
                        style={{ cursor: "pointer", marginBottom: "10px" }}
                        onClick={() => {
                          console.log("cuukx");
                          dispatch(
                            addClick({
                              id: contentFounded._id,
                              user: user._id,
                              type: "CONTENT",
                            })
                          );
                          navigate(`../${contentFounded._id}`, {
                            replace: true,
                          });
                        }}
                      >
                        <GridMobileImageCard>
                          <GridCardImage
                            src={contentFounded.picture}
                          ></GridCardImage>
                        </GridMobileImageCard>
                        <GridMobileInfoCard>
                          <FlexContainer gap="12px">
                            <GridCardSmallContainer
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                dispatch(
                                  addClick({
                                    id: contentFounded.academy._id,
                                    user: user._id,
                                    type: "ACADEMY",
                                  })
                                );
                                navigate(
                                  `/dashboard/academy/${contentFounded.academy._id}`
                                );
                              }}
                            >
                              <GridCardImage
                                src={contentFounded.academy.picture}
                              ></GridCardImage>
                            </GridCardSmallContainer>
                            <FlexContainer direction="column" gap="1px">
                              <GridCardTitleMobile>
                                {contentFounded.name}
                              </GridCardTitleMobile>
                              <FlexContainer direction="column" gap="4px">
                                <FlexContainer align="center" gap="5px">
                                  <FlexContainer align="center" gap="3px">
                                    <AiFillEye fill="#6a6970"></AiFillEye>

                                    <GridCommentMobile>
                                      {contentFounded.viewCount}
                                    </GridCommentMobile>
                                  </FlexContainer>
                                  <FlexContainer align="center" gap="5px">
                                    <AiFillClockCircle fill="#6a6970"></AiFillClockCircle>

                                    <GridCommentMobile>
                                      {DateFromNow(contentFounded.createdAt)}
                                    </GridCommentMobile>
                                  </FlexContainer>
                                </FlexContainer>
                              </FlexContainer>
                            </FlexContainer>
                          </FlexContainer>
                        </GridMobileInfoCard>
                      </GridMobileCard>
                    );
                  })}
                </InfinityScroll>
              </FlexContainer>
            </Grid.Col>
          </Grid.Row>
        </div>
      </GridMobileCard>
      <DescriptionModal
        isModalOpen={openDescription}
        setIsModalOpen={setOpenDescription}
        content={content}
      ></DescriptionModal>
      {currentContentAcademy && action?.action === "out-of-academy" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Desuscribirse",
            style: {
              color: "Danger",

              style: {
                width: "150px",
                height: "31px",
              },
              options: {
                type: "filled",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={updateAcademyStates}
          elementActions={academyActions.deleteMember({
            _id: currentContentAcademy._id,
            userId: user._id,
          })}
          onClose={() => {
            setAction(undefined);
          }}
          title={
            <FlexContainer direction="column" gap="11px">
              <span>
                {`¿Seguro quieres salir de la academia `}
                <span style={{ color: "#000" }}>
                  {currentContentAcademy.name}
                </span>
                ?
              </span>
              <p>
                Perderás todos los accesos y suscripciones relacionados a esta
                Academia y los contenidos que no hayas descargado.
              </p>
            </FlexContainer>
          }
          /*  resetState={() => {
            setAction(undefined);
          }} */
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({ userStore, academyStore }) => {
  const { data: user } = userStore;
  const { states: updateAcademyStates } = academyStore.update;
  return {
    user,
    updateAcademyStates,
  };
};

export default connect(states)(Component);
