import {
  GET_CONFIRMATIONAUTH,
  UPDATE_CONFIRMATIONAUTH,
  GetConfirmationAuth,
  UpdateConfirmationAuth,
  ResetGetConfirmationAuth,
  RESET_GET_CONFIRMATIONAUTH,
  RESET_UPDATE_CONFIRMATIONAUTH,
  ResetUpdateConfirmationAuth,
} from "../types/confirmationAuth";

export const getConfirmationAuth = (_id): GetConfirmationAuth => {
  return { type: GET_CONFIRMATIONAUTH, payload: _id };
};

export const resetGetConfirmationAuth = (): ResetGetConfirmationAuth => {
  return { type: RESET_GET_CONFIRMATIONAUTH, payload: null };
};

export const updateConfirmationAuth = (payload): UpdateConfirmationAuth => {
  return { type: UPDATE_CONFIRMATIONAUTH, payload: payload };
};

export const resetUpdateConfirmationAuth = (): ResetUpdateConfirmationAuth => {
  return { type: RESET_UPDATE_CONFIRMATIONAUTH, payload: null };
};
