import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import Button from "../../../../components/Button";
import Grid from "../../../../components/Grid";
import { GridStructure } from "../../../../components/Layout/Dashboard/styles";
import LineGraphHorizontal from "../../../../components/LineGraphHorizontal";
import Loader from "../../../../components/Loader";
import PieChart from "../../../../components/PieChart";
import {
  Center,
  FlexContainer,
  PageTitle,
} from "../../../../components/StyledComponents";
import { DateEStoDateEN } from "../../../../helpers/format-date";
import { getCampusStats } from "../../../../store/actions/analytics";
import AnalyticCard from "../components/AnalyticCard";
import NotData from "../components/NotData";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useWindowSize } from "../../../../hooks/useWindowScreen";
const Component = ({ analyticsCampus, analyticsCampusStates, user }) => {
  const printRef: any = React.useRef();
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const handleExportWithComponent = (event) => {
    printRef.current.save();
  };
  useEffect(() => {
    dispatch(getCampusStats({ _id: user._id }));
  }, []);
  if (!analyticsCampus) return <Loader color="LightBlue"></Loader>;

  return (
    <>
      <Grid.Container>
        <PageTitle>Dashboard</PageTitle>

        {isMobile ? (
          <Center>
            <Button
              onClick={(e) => {
                handleExportWithComponent(e);
              }}
              type="button"
              options={{
                type: "filled",
                skin: "violet",
                size: "md",
                marginBottom: "10px",
              }}
              style={{ marginLeft: "10px" }}
            >
              Descargar
            </Button>
          </Center>
        ) : (
          <Grid.Col offset={{ lg: 10 }} sm={6}>
            <Button
              onClick={(e) => {
                handleExportWithComponent(e);
              }}
              type="button"
              options={{
                type: "filled",
                skin: "violet",
                size: "md",
                marginBottom: "10px",
              }}
              style={{ marginLeft: "10px" }}
            >
              Descargar
            </Button>
          </Grid.Col>
        )}
        <PDFExport
          fileName={`Professional ${new Date().valueOf()}`}
          ref={printRef}
        >
          <FlexContainer direction="column" gap="6px">
            <AnalyticCard
              graphTitle="Cantidad de vistas de mi perfil en constana - Crecimiento Histórico "
              title={"Cantidad de vistas de mi perfil en constana"}
              data={{
                stats: [
                  {
                    label: "Últimos 30 días",
                    value: analyticsCampus.views.lastMonth,
                  },
                  {
                    label: "Últimos 7 días",
                    value: analyticsCampus.views.lastWeek,
                  },
                  {
                    label: "Últimas 24 horas",
                    value: analyticsCampus.views.lastDay,
                  },
                ],
                graphData: Object.entries(analyticsCampus.views.growth)
                  .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                  .map((growthData) => {
                    return {
                      name: growthData[0],
                      value: growthData[1],
                    };
                  }),
              }}
            ></AnalyticCard>
            <AnalyticCard
              title={"Cantidad interacciones de mi perfil en constana"}
              graphTitle="Cantidad de interacciones de mi perfil en constana - Crecimiento Histórico"
              data={{
                stats: [
                  {
                    label: "Últimos 30 días",
                    value: analyticsCampus.clicksReceived.lastMonth,
                  },
                  {
                    label: "Últimos 7 días",
                    value: analyticsCampus.clicksReceived.lastWeek,
                  },
                  {
                    label: "Últimas 24 horas",
                    value: analyticsCampus.clicksReceived.lastDay,
                  },
                ],
                graphData: Object.entries(analyticsCampus.clicksReceived.growth)
                  .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                  .map((growthData) => {
                    return {
                      name: growthData[0],
                      value: growthData[1],
                    };
                  }),
              }}
            ></AnalyticCard>

            <AnalyticCard
              height="250px"
              title="Top 10 organizaciones que interactuaron con mi perfil"
            >
              <NotData
                data={analyticsCampus.clicksReceived.topTenOrganizations}
              >
                <LineGraphHorizontal
                  data={analyticsCampus.clicksReceived.topTenOrganizations.map(
                    (organization) => {
                      return {
                        label: organization.name,
                        value: organization.clicks,
                      };
                    }
                  )}
                  title={"Alumnos"}
                ></LineGraphHorizontal>
              </NotData>
            </AnalyticCard>
            <AnalyticCard
              height="250px"
              title="Top 10 personas que interactuaron con mi perfil"
            >
              <NotData data={analyticsCampus.clicksReceived.topTenUsers}>
                <LineGraphHorizontal
                  data={analyticsCampus.clicksReceived.topTenUsers.map(
                    (user) => {
                      return {
                        label: user.name,
                        value: user.clicks,
                      };
                    }
                  )}
                  title={"Alumnos"}
                ></LineGraphHorizontal>
              </NotData>
            </AnalyticCard>
            <GridStructure
              style={{ width: "100%" }}
              type="auto-fit"
              width="400px"
            >
              <AnalyticCard
                height="300px"
                title="Genero de personas que interactuaron con mi perfil"
              >
                <NotData data={analyticsCampus.clicksReceived.genders}>
                  <PieChart
                    pieData={Object.entries(
                      analyticsCampus.clicksReceived.genders
                    ).map((contact) => {
                      return {
                        label: contact[0],
                        value: contact[1],
                      };
                    })}
                  ></PieChart>
                </NotData>
              </AnalyticCard>
              <AnalyticCard
                height="300px"
                title="Edad de personas que interactuaron con mi perfil"
              >
                <NotData data={analyticsCampus.clicksReceived.ages}>
                  <PieChart
                    pieData={Object.entries(
                      analyticsCampus.clicksReceived.ages
                    ).map((age) => {
                      return {
                        label: age[0],
                        value: age[1],
                      };
                    })}
                  ></PieChart>
                </NotData>
              </AnalyticCard>
            </GridStructure>
          </FlexContainer>
        </PDFExport>
      </Grid.Container>
    </>
  );
};

const states = ({ analyticStore, userStore }) => {
  const { data: analyticsCampus, states: analyticsCampusStates } =
    analyticStore.getCampusStats;
  const { data: user } = userStore;
  return {
    analyticsCampus,
    analyticsCampusStates,
    user,
  };
};

export default connect(states)(Component);
