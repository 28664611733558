import React from "react";
import Warning from "../../assets/images/warning.png";
import { useWindowSize } from "../../hooks/useWindowScreen";
const Index = () => {
  const { isMobile } = useWindowSize();
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {!!isMobile ? (
        <div style={{ height: "300px", width: "300px" }}>
          <img
            style={{ objectFit: "cover", height: "100%", width: "100%" }}
            src={Warning}
            alt=""
          />
        </div>
      ) : (
        <img src={Warning} alt="" />
      )}
      <h4 style={{ fontSize: "20px", textAlign: "center" }}>
        Alto ahi!, nos encontramos en construcción
      </h4>
    </div>
  );
};

export default Index;
