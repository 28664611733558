import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import Modal from "../../../../../../components/Modal";
import Icon from "../../../../../../components/Icon";
import FileUploader from "../../../../../../components/Form/FileUploader";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/academy/update-photos-banner";
import { hideModal } from "../../../../../../store/actions/modal";
import UploadIcon from "../../../../../../assets/icons/cloud-upload.svg";
import academyActions from "../../../../../../store/actions/academies";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { AcademyDto } from "../../../../../../types/academy.dto";
interface ComponentProps {
  uploadStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  selectedAcademy: AcademyDto;
}

const Component = ({ uploadStates, selectedAcademy }: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [avatarPreview, setAvatarPreview] = useState<HTMLInputElement>();
  const [bannerPic, setBannerPic] = useState<HTMLInputElement>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    const formValues = {
      ...values,
      ...(!!avatarPreview &&
        !!avatarPreview?.files && {
          picture: avatarPreview?.files[0],
        }),
      ...(!!bannerPic &&
        !!bannerPic?.files && {
          bannerPic: bannerPic?.files[0],
        }),
    };
    dispatch(
      academyActions.update({ _id: selectedAcademy._id, ...formValues })
    );
    setFormActions(actions);
  };

  useEffect(() => {
    if (uploadStates.success) {
      toast("👌🏼 Academia modificada correctamente", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });

      dispatch(academyActions.resetUpdate());
      dispatch(hideModal());
      setTimeout(() => {
        formActions?.resetForm();
      }, 500);
    }

    if (uploadStates.error) {
      toast(`😱 ${uploadStates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });

      dispatch(academyActions.resetUpdate());
      dispatch(hideModal());
      setTimeout(() => {}, 500);
    }
  }, [uploadStates]);

  return (
    <Modal
      name="edit-photos-academies"
      title={
        <span>
          {`Editar fotos de `}{" "}
          <span style={{ color: "#000" }}>{selectedAcademy.name}</span>{" "}
        </span>
      }
    >
      <Formik
        initialValues={{
          ...initialValues,
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="talent-create-modal"
            >
              <Grid.Row>
                <Grid.Col>
                  <FileUploader
                    name="picture"
                    error={errors["picture"]}
                    touched={touched["picture"]}
                    placeholder={
                      <Icon icon={UploadIcon} size="24px" color="black" />
                    }
                    onChange={(event) => {
                      setAvatarPreview(event.target);
                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    accept="image/jpg;image/png;image/jpeg;capture=camera"
                    options={{
                      label: "Foto de perfil",
                      marginBottom: 24,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <FileUploader
                    name="bannerPic"
                    error={errors["bannerPic"]}
                    touched={touched["bannerPic"]}
                    placeholder={
                      <Icon icon={UploadIcon} size="24px" color="black" />
                    }
                    onChange={(event) => {
                      setBannerPic(event.target);
                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    accept="image/jpg;image/png;image/jpeg;capture=camera"
                    options={{
                      label: "Foto de Banner",
                      marginBottom: 24,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Center>
                <Submit
                  isSubmmiting={uploadStates.loading}
                  form="talent-create-modal"
                  color="Violet"
                  style={{ height: "26px" }}
                  options={{
                    type: "filled",
                    loading: "Violet",
                    skin: "violet",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                >
                  Confirmar
                </Submit>
              </Center>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ academyStore }) => {
  const { states: uploadStates } = academyStore.update;
  const { data: selectedAcademy } = academyStore.selected;
  return {
    uploadStates,
    selectedAcademy,
  };
};

export default connect(states)(Component);
