import {
  GET_ALL_USERS,
  GET_USERS_BY_ACADEMY,
  RESET_GET_ALL_USERS_DATA,
  RESET_UPDATE_USERS,
  UPDATE_USERS,
} from "../types/users";

const getAll = (payload) => {
  return { type: GET_ALL_USERS, payload };
};

const update = (payload) => {
  return { type: UPDATE_USERS, payload };
};

const resetGetAllUsers = () => {
  return { type: RESET_GET_ALL_USERS_DATA, payload: null };
};

const getByAcademy = (payload) => {
  return { type: GET_USERS_BY_ACADEMY, payload };
};

const resetUpdate = () => {
  return { type: RESET_UPDATE_USERS, payload: null };
};

const actions = {
  getAll,
  update,
  resetUpdate,
  resetGetAllUsers,
  getByAcademy,
};

export default actions;
