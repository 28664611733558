import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import commentActions from "../../../../store/actions/comment";
import {
  initialValues,
  schema,
} from "../../../../constants/form/comment/create";
import { FlexContainer } from "../../../StyledComponents";
import Input from "../../../Form/Input";
import { AddCommentInputWrapper } from "./style";
import { HiPaperAirplane } from "react-icons/hi2";
import { useTheme } from "styled-components";
const Component = ({ section, itemId, replyTo }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();

  const onSubmit = ({ values, actions }) => {
    dispatch(
      commentActions.create({ ...values, [`${section}`]: itemId, replyTo })
    );
    setFormActions(actions);

    actions.resetForm();
  };

  return (
    <AddCommentInputWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validationSchema={schema}
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <form
              style={{ width: "100%" }}
              onSubmit={(event) => {
                handleSubmit(event);
              }}
              id="add-reply-comment"
            >
              <FlexContainer align="center" gap="10px">
                <Input
                  name="text"
                  error={errors["text"]}
                  touched={touched["text"]}
                  value={values["text"]}
                  type="text"
                  inputStyles={{
                    fontSize: "12px",
                    fontWeight: "500",
                    height: "29px",
                    borderRadius: "10px",
                  }}
                  placeholder="Responde al comentario"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    marginBottom: 10,
                    skin: "gray",
                  }}
                />
                <button
                  style={{
                    all: "unset",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  type="submit"
                >
                  <HiPaperAirplane
                    color={theme.colors.LightBlue}
                    fontSize={20}
                  ></HiPaperAirplane>
                </button>
              </FlexContainer>
            </form>
          );
        }}
      </Formik>
    </AddCommentInputWrapper>
  );
};

export default Component;
