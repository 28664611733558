import { IconType } from "react-icons/lib";
import { FaGraduationCap, FaPlay, FaUniversity } from "react-icons/fa";
import { RiOrganizationChart, RiVideoAddFill } from "react-icons/ri";
import { HiDocumentText, HiPresentationChartBar } from "react-icons/hi";
import { BsFillImageFill } from "react-icons/bs";
import { MdAudiotrack } from "react-icons/md";
import { AiFillBell } from "react-icons/ai";

export type IconsTypes =
  | "cdoc"
  | "cohort"
  | "trayecto"
  | "video"
  | "slide"
  | "image"
  | "listen"
  | "byte"
  | "notification"
  | "academy";

const icons: {
  type: string;
  icon: IconType;
}[] = [
  {
    type: "cdoc",
    icon: HiDocumentText,
  },
  {
    type: "cohort",
    icon: FaGraduationCap,
  },
  {
    type: "trayecto",
    icon: RiOrganizationChart,
  },
  {
    type: "video",
    icon: FaPlay,
  },
  {
    type: "slide",
    icon: HiPresentationChartBar,
  },
  {
    type: "image",
    icon: BsFillImageFill,
  },
  {
    type: "notification",
    icon: AiFillBell,
  },
  {
    type: "listen",
    icon: MdAudiotrack,
  },
  {
    type: "byte",
    icon: RiVideoAddFill,
  },
  {
    type: "academy",
    icon: FaUniversity,
  },
];

const Component = ({ icon }: { icon: IconsTypes | undefined }) => {
  if (icon) {
    const Icon = icons.find((element) => element.type === icon)?.icon;
    return Icon ? <Icon size={36} color={"#6a6970"} /> : <></>;
  }

  return <></>;
};

export default Component;
