import action from "../helpers/rest-client";

export class ErrorTalent extends Error {}

class Talent {
  public async new(payload) {
    const formData = new FormData();

    for (let key in payload) {
      const currentElement = payload[key];
      if (key !== "curriculum" && key !== "avatar" && currentElement) {
        formData.append(key, currentElement);
      }
    }

    for (let key in payload) {
      const currentElement = payload[key];

      if (key === "curriculum" && currentElement) {
        if (typeof currentElement !== "string") {
          formData.append("curriculum", currentElement, currentElement.name);
        } else {
          formData.append(key, currentElement);
        }
      }

      if (key === "avatar" && currentElement) {
        if (typeof currentElement !== "string") {
          formData.append("avatar", currentElement, currentElement.name);
        } else {
          formData.append(key, currentElement);
        }
      }
    }

    return action
      .Post({
        url: "/talent",
        body: formData,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public shownCohort(payload) {
    return action
      .Patch({
        url: `users/make-shown-cohort/${payload}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getAll(payload) {
    if (payload.filterBy) {
      payload.filterBy = JSON.stringify(payload.filterBy);
    }
    if (payload.select) {
      payload.select = JSON.stringify(payload.select);
    }
    const queryString = new URLSearchParams(payload).toString();
    return action
      .Get({
        url: `/talent?platform=CAMPUS${queryString ? "&" + queryString : ""}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const talent = new Talent();
export default talent;
