import styled from "styled-components";

export const Card = styled("div")`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 21px 10px;
  border-radius: 20px;
  box-shadow: 0 0 6px 0 rgba(79, 38, 162, 0.16);

  @media (min-width: 960px) {
    flex-direction: row;
    width: 100%;
    max-width: 760px;
  }
`;

export const TitleStage = styled("h3")`
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.Black};
  font-size: 12px;
  margin: 0;
`;
export const CardRow = styled("div")`
  display: flex;
`;
export const CardCol = styled("div")`
  display: flex;
  flex-direction: column;
`;
export const Avatar = styled("div")<{ avatar: string }>`
  padding: 0px;
  background: rgb(30, 120, 232);
  border-radius: 100%;
  background-color: gray;
  ${({ avatar }) => "background-image: url(" + avatar + ");"}
  background-size: cover;
  background-position: center;
  margin-right: 8px;
  font-size: 32px;
  width: 80px;
  height: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  & > * {
    width: 100%;
    border-radius: 100%;
    object-fit: cover;
  }
`;
export const Information = styled("div")`
  padding: 5px 0px;
`;
export const Name = styled("p")`
  margin: 0px 0px 2px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #707070;
`;
export const Profile = styled("p")`
  margin: 0px 0px 5px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #334253;
`;
export const Location = styled("p")`
  margin: 0px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #334253;

  span {
    text-transform: capitalize;
  }
`;
export const ItemLink = styled("a")``;
export const Commets = styled("p")`
  margin: 0px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  color: #697482;
  margin-bottom: 20px;

  @media (min-width: 960px) {
    margint-bottom: 0px;
  }
`;

export const List = styled("ul")`
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
export const Item = styled("li")`
  list-style: none;
`;

export const SmallCardChart = styled("div")`
  padding: 10px;
  border-radius: 20px;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  width: 150px;
  height: 70px;
`;
export const BigCardChart = styled("div")`
  border-radius: 20px;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
  width: 150px;
  height: 150px;
  padding: 10px;
`;

export const NoteCard = styled("h3")`
  margin: 6px;
  font-size: 40px;
  font-weight: 500;
  color: #334253;
  text-align: center;
`;
