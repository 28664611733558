import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { GridStructure } from "../../../../../components/Layout/Dashboard/styles";
import NotData from "../NotData";
import NumberStat from "./components/NumberStat";
import {
  AnalyticCardSection,
  AnalyticCardSubTitle,
  AnalyticCardTitle,
  AnalyticCardWrapper,
} from "./style";
import { useTheme } from "styled-components";
const Component = ({
  title,
  data,
  children,
  height,
  graphTitle,
}: {
  title: string;
  graphTitle?: string;
  data?: any;
  children?: any;
  height?: string;
}) => {
  const theme = useTheme();
  return (
    <AnalyticCardWrapper>
      <AnalyticCardTitle>{title}</AnalyticCardTitle>
      {!!children ? (
        <div {...(!!height && !!children && { style: { height } })}>
          {children}
        </div>
      ) : (
        <GridStructure type="auto-fit" width="300px" gap="24px">
          <AnalyticCardSection>
            <GridStructure
              type="auto-fit"
              width="min-content"
              gap="20px"
              style={{
                height: "100%",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              {data.stats.map((stat) => {
                return <NumberStat value={stat.value} label={stat.label} />;
              })}
            </GridStructure>
          </AnalyticCardSection>
          <AnalyticCardSection>
            <AnalyticCardSubTitle>{graphTitle}</AnalyticCardSubTitle>
            <NotData data={data.graphData}>
              <ResponsiveContainer>
                <AreaChart
                  data={data.graphData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                  style={{ fontWeight: "400" }}
                >
                  <XAxis dataKey="name" axisLine={false} tick={false} />
                  <YAxis axisLine={false} />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke={theme.colors.LightBlue}
                    fill="rgba(123, 104, 239, 0.2)"
                    strokeWidth={1.5}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </NotData>
          </AnalyticCardSection>
        </GridStructure>
      )}
    </AnalyticCardWrapper>
  );
};

export default Component;
