import { PaginationDto } from "../../types/pagination.dto";
import { VideoDto } from "../../types/video.dto";

export const GET_ALL_VIDEOS = "GET_ALL_VIDEO";
export const GET_ALL_VIDEOS_ERROR = "GET_ALL_VIDEO_ERROR";
export const GET_ALL_VIDEOS_SUCCESS = "GET_ALL_VIDEO_SUCCESS";
export const GET_VIDEO = "GET_VIDEO";
export const GET_VIDEO_ERROR = "GET_VIDEO_ERROR";
export const SET_GET_VIDEO = "SET_GET_VIDEO";
export const GET_VIDEO_SUCCESS = "GET_VIDEO_SUCCESS";
export const RESET_GET_VIDEO = "RESET_GET_VIDEO";
export const NEW_VIDEO = "NEW_VIDEO";
export const NEW_VIDEO_ERROR = "NEW_VIDEO_ERROR";
export const NEW_VIDEO_SUCCESS = "NEW_VIDEO_SUCCESS";
export const RESET_NEW_VIDEO = "RESET_NEW_VIDEO";
export const UPDATE_VIDEO = "UPDATE_VIDEO";
export const UPDATE_VIDEO_ERROR = "UPDATE_VIDEO_ERROR";
export const UPDATE_VIDEO_SUCCESS = "UPDATE_VIDEO_SUCCESS";
export const RESET_UPDATE_VIDEO = "RESET_UPDATE_VIDEO";
export const DELETE_VIDEO = "DELETE_VIDEO";
export const DELETE_VIDEO_ERROR = "DELETE_VIDEO_ERROR";
export const DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS";
export const RESET_DELETE_VIDEO = "RESET_DELETE_VIDEO";
export const UPLOAD_MEDIA = "UPLOAD_MEDIA";
export const UPDATE_VIDEO_LOADING = "UPDATE_VIDEO_LOADING";
export const ADD_USER_TO_VIDEO = "ADD_USER_TO_VIDEO";
export const SHARED_VIDEO = "SHARED_VIDEO";
export const QUERY_VIDEO = "QUERY_VIDEO";
export const QUERY_VIDEO_SUCCESS = "QUERY_VIDEO_SUCCESS";
export const QUERY_VIDEO_ERROR = "QUERY_VIDEO_ERROR";
export const RESET_QUERY_VIDEO = "RESET_QUERY_VIDEO";
export const SAVE_VIDEO = "SAVE_VIDEO";
export const UPDATE_CURRENT_VIDEO_LIST = "UPDATE_CURRENT_VIDEO_LIST";
export const UPDATE_CURRENT_VIDEO = "UPDATE_CURRENT_VIDEO";

export const UNSAVED_VIDEO = "UNSAVED_VIDEO";
export const SAVED_VIDEO = "SAVED_VIDEO";

export const ADD_VIEW_VIDEO = "ADD_VIEW_VIDEO";

export const ADD_CLICK_VIDEO = "ADD_CLICK_VIDEO";

export const MANAGE_LIKE_DISLIKE_VIDEO = "MANAGE_LIKE_DISLIKE_VIDEO";

export const UPDATE_ONE_VIDEO = "UPDATE_ONE_VIDEO";
export const UPDATE_ONE_VIDEO_SUCCESS = "UPDATE_ONE_VIDEO_SUCCESS";
export const UPDATE_ONE_VIDEO_ERROR = "UPDATE_ONE_VIDEO_ERROR";
export const RESET_UPDATE_ONE_VIDEO = "RESET_UPDATE_ONE_VIDEO";
export const GET_VIDEOS_OFFSET = "GET_VIDEOS_OFFSET";
export const GET_VIDEOS_OFFSET_SUCCESS = "GET_VIDEOS_OFFSET_SUCCESS";
export const GET_VIDEOS_OFFSET_ERROR = "GET_VIDEOS_OFFSET_ERROR";
export const RESET_GET_VIDEOS_OFFSET = "RESET_GET_VIDEOS_OFFSET";
export const SET_OFFSET_VIDEO = "SET_OFFSET_VIDEO";
export const RESET_GET_ALL_VIDEO_DATA = "RESET_GET_ALL_VIDEO_DATA";
export const RESET_GET_ONE_VIDEO_DATA = "RESET_GET_ONE_VIDEO_DATA";
export const UPDATE_ITEM_LIST_VIDEO = "UPDATE_ITEM_LIST_VIDEO";

export interface ResetGetAllVideoData {
  type: typeof RESET_GET_ALL_VIDEO_DATA;
  payload: null;
}

export interface ResetGetOneVideoData {
  type: typeof RESET_GET_ONE_VIDEO_DATA;
  payload: null;
}

export interface UpdateItemListVideo {
  type: typeof UPDATE_ITEM_LIST_VIDEO;
  payload: any;
}

export interface GetVideosOffset {
  type: typeof GET_VIDEOS_OFFSET;
  payload: { limit: number; offset: number };
}

export interface GetVideosOffsetError {
  type: typeof GET_VIDEOS_OFFSET_ERROR;
  payload: string | boolean;
}

export interface SetOffsetVideo {
  type: typeof SET_OFFSET_VIDEO;
  payload: VideoDto[] | null;
}

export interface GetVideosOffsetSuccess {
  type: typeof GET_VIDEOS_OFFSET_SUCCESS;
  payload: VideoDto[];
}

export interface ResetGetVideosOffset {
  type: typeof RESET_GET_VIDEOS_OFFSET;
  payload: null;
}
export interface UpdateOneVideo {
  type: typeof UPDATE_ONE_VIDEO;
  payload: any;
}

export interface SetGetVideo {
  type: typeof SET_GET_VIDEO;
  payload: VideoDto;
}

export interface UpdateOneVideoSuccess {
  type: typeof UPDATE_ONE_VIDEO_SUCCESS;
  payload: VideoDto;
}
export interface UpdateOneVideoError {
  type: typeof UPDATE_ONE_VIDEO_ERROR;
  payload: boolean | string;
}
export interface ResetUpdateOneVideo {
  type: typeof RESET_UPDATE_ONE_VIDEO;
  payload: null;
}

export interface ManageLikeDislikeVideo {
  type: typeof MANAGE_LIKE_DISLIKE_VIDEO;
  payload: {
    _id: string;
    user: string;
    condition: "like" | "dislike";
    action: "add" | "remove";
  };
}

export interface SavedVideos {
  type: typeof SAVED_VIDEO;
  payload: string;
}
export interface UnSavedVideos {
  type: typeof UNSAVED_VIDEO;
  payload: string;
}
export interface SharedVideo {
  type: typeof SHARED_VIDEO;
  payload: any;
}

export interface AddClickVideo {
  type: typeof ADD_CLICK_VIDEO;
  payload: { _id: string };
}
export interface GetAllVideos {
  type: typeof GET_ALL_VIDEOS;
  payload?: PaginationDto;
}

export interface AddViewVideo {
  type: typeof ADD_VIEW_VIDEO;
  payload: { _id: string };
}

export interface SaveVideo {
  type: typeof SAVE_VIDEO;
  payload: { _id: string; condition: string };
}

export interface QueryVideo {
  type: typeof QUERY_VIDEO;
  payload: string;
}
export interface QueryVideoSuccess {
  type: typeof QUERY_VIDEO_SUCCESS;
  payload: VideoDto[];
}
export interface QueryVideoError {
  type: typeof QUERY_VIDEO_ERROR;
  payload: string | boolean;
}

export interface UpdateCurrentVideoList {
  type: typeof UPDATE_CURRENT_VIDEO_LIST;
  payload: VideoDto[];
}

export interface ResetQueryVideo {
  type: typeof RESET_QUERY_VIDEO;
  payload: null;
}

export interface GetAllVideosSuccess {
  type: typeof GET_ALL_VIDEOS_SUCCESS;
  payload: VideoDto[];
}

export interface GetAllVideosError {
  type: typeof GET_ALL_VIDEOS_ERROR;
  payload: boolean | string;
}

export interface AddUserToVideo {
  type: typeof ADD_USER_TO_VIDEO;
  payload: any;
}

export interface GetVideo {
  type: typeof GET_VIDEO;
  payload: { id: string };
}

export interface GetVideoSuccess {
  type: typeof GET_VIDEO_SUCCESS;
  payload: VideoDto;
}

export interface GetVideoError {
  type: typeof GET_VIDEO_ERROR;
  payload: boolean | string;
}

export interface NewVideo {
  type: typeof NEW_VIDEO;
  payload: VideoDto;
}

export interface NewVIDEOuccess {
  type: typeof NEW_VIDEO_SUCCESS;
  payload: VideoDto;
}

export interface ResetGetVideo {
  type: typeof RESET_GET_VIDEO;
  payload: null;
}

export interface NewVideoError {
  type: typeof NEW_VIDEO_ERROR;
  payload: boolean | string;
}

export interface UploadMedia {
  type: typeof UPLOAD_MEDIA;
  payload: any;
}

export interface ResetNewVideo {
  type: typeof RESET_NEW_VIDEO;
  payload: null;
}

export interface UpdateVideo {
  type: typeof UPDATE_VIDEO;
  payload: VideoDto;
}

export interface UpdateVideoLoading {
  type: typeof UPDATE_VIDEO_LOADING;
  payload: VideoDto;
}

export interface UpdateVIDEOuccess {
  type: typeof UPDATE_VIDEO_SUCCESS;
  payload: VideoDto;
}

export interface UpdateVideoError {
  type: typeof UPDATE_VIDEO_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateVideo {
  type: typeof RESET_UPDATE_VIDEO;
  payload: null;
}

export interface DeleteVideo {
  type: typeof DELETE_VIDEO;
  payload: { _id: string };
}

export interface DeleteVIDEOuccess {
  type: typeof DELETE_VIDEO_SUCCESS;
  payload: null;
}

export interface DeleteVideoError {
  type: typeof DELETE_VIDEO_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteVideo {
  type: typeof RESET_DELETE_VIDEO;
  payload: null;
}

export interface UpdateCurrentVideo {
  type: typeof UPDATE_CURRENT_VIDEO;
  payload: VideoDto;
}

export interface State {
  all: {
    data: VideoDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  video: {
    data: VideoDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  new: {
    data: VideoDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  offSet: {
    data: VideoDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  query: {
    data: VideoDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    data: null | VideoDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateOne: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  video: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  new: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  offSet: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  query: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateOne: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllVideos
  | GetAllVideosSuccess
  | GetAllVideosError
  | GetVideo
  | GetVideoSuccess
  | GetVideoError
  | NewVideo
  | NewVIDEOuccess
  | NewVideoError
  | ResetNewVideo
  | UpdateVideo
  | UpdateVIDEOuccess
  | UpdateVideoError
  | ResetUpdateVideo
  | DeleteVideo
  | DeleteVIDEOuccess
  | DeleteVideoError
  | ResetDeleteVideo
  | UploadMedia
  | UpdateVideoLoading
  | ResetGetVideo
  | AddUserToVideo
  | SharedVideo
  | QueryVideo
  | QueryVideoSuccess
  | QueryVideoError
  | ResetQueryVideo
  | SaveVideo
  | UpdateCurrentVideoList
  | UpdateCurrentVideo
  | SavedVideos
  | UnSavedVideos
  | AddViewVideo
  | AddClickVideo
  | ManageLikeDislikeVideo
  | UpdateOneVideo
  | UpdateOneVideoSuccess
  | UpdateOneVideoError
  | ResetUpdateOneVideo
  | SetGetVideo
  | GetVideosOffset
  | GetVideosOffsetError
  | GetVideosOffsetSuccess
  | ResetGetVideosOffset
  | SetOffsetVideo
  | ResetGetAllVideoData
  | ResetGetOneVideoData
  | UpdateItemListVideo;
