import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_LISTEN,
  GET_LISTEN_SUCCESS,
  GET_LISTEN_ERROR,
  GET_ALL_LISTENS,
  GET_ALL_LISTENS_SUCCESS,
  GET_ALL_LISTENS_ERROR,
  NEW_LISTEN,
  NEW_LISTEN_ERROR,
  NEW_LISTEN_SUCCESS,
  NewListen,
  UPDATE_LISTEN,
  UPDATE_LISTEN_SUCCESS,
  UPDATE_LISTEN_ERROR,
  DELETE_LISTEN,
  DELETE_LISTEN_SUCCESS,
  DELETE_LISTEN_ERROR,
  GetListen,
  GetAllListens,
  UpdateListen,
  DeleteListen,
  UploadMedia,
  UPLOAD_MEDIA,
  AddUserToListen,
  ADD_USER_TO_LISTEN,
  SharedListen,
  SHARED_LISTEN,
  QueryListen,
  QUERY_LISTEN,
  QUERY_LISTEN_ERROR,
  QUERY_LISTEN_SUCCESS,
  SaveListen,
  SAVE_LISTEN,
  UPDATE_CURRENT_LISTEN_LIST,
  UPDATE_CURRENT_LISTEN,
  SAVED_LISTEN,
  UNSAVED_LISTEN,
  AddViewListen,
  ADD_VIEW_LISTEN,
  UPDATE_ONE_LISTEN_SUCCESS,
  UPDATE_ONE_LISTEN_ERROR,
  SET_GET_LISTEN,
  SetGetListen,
  MANAGE_LIKE_DISLIKE_LISTEN,
  AddClickListen,
  ADD_CLICK_LISTEN,
  ManageLikeDislikeListen,
  GET_LISTENS_OFFSET,
  GET_LISTENS_OFFSET_ERROR,
  GET_LISTENS_OFFSET_SUCCESS,
  GetListensOffset,
  UPDATE_ITEM_LIST_LISTEN,
} from "../types/listen";

const listensOffset = ({ listenStore }) => listenStore.offSet.data;

function* getAllListens({ payload }) {
  const { response, error } = yield call(services.listen.getAll, payload);
  if (error) {
    yield put({
      type: GET_ALL_LISTENS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_LISTENS_SUCCESS, payload: response });
  }
}

function* getListen({ payload }) {
  const { response, error } = yield call(services.listen.getOne, payload);

  if (error) {
    yield put({
      type: GET_LISTEN_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_LISTEN_SUCCESS, payload: response });
  }
}

function* saveListen({ payload }) {
  const { response, error } = yield call(services.listen.saveListen, payload);

  if (error) {
    yield put({
      type: UPDATE_LISTEN_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_LISTEN_SUCCESS, payload: response });
    if (!!payload.individual) {
      yield put({ type: SET_GET_LISTEN, payload: response });
    }
  }
}

function* queryListen({ payload }) {
  const { response, error } = yield call(services.listen.query, payload);

  if (error) {
    yield put({
      type: QUERY_LISTEN_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: QUERY_LISTEN_SUCCESS, payload: response });
  }
}

function* newListen({ payload }) {
  const { response, error } = yield call(services.listen.new, payload);

  if (error) {
    yield put({
      type: NEW_LISTEN_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_LISTEN_SUCCESS, payload: response })]);
  }
}

function* updateListen({ payload }) {
  const { response, error } = yield call(services.listen.update, payload);

  if (error) {
    yield put({
      type: UPDATE_LISTEN_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_LISTEN_SUCCESS, payload: response })]);
  }
}
function* addUserToListen({ payload }) {
  const { response, error } = yield call(
    services.listen.addUserListen,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_LISTEN_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_LISTEN_SUCCESS, payload: response })]);
  }
}

function* uploadMedia({ payload }) {
  const { response, error } = yield call(services.listen.update, payload);

  if (error) {
    yield put({
      type: UPDATE_LISTEN_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_LISTEN_SUCCESS, payload: response })]);
  }
}

function* deleteListen({ payload }) {
  const { response, error } = yield call(services.listen.delete, payload);

  if (error) {
    yield put({
      type: DELETE_LISTEN_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_LISTEN_SUCCESS, payload: response })]);
  }
}

function* shareListen({ payload }) {
  const { response, error } = yield call(services.listen.shareListen, payload);

  if (error) {
    yield put({
      type: UPDATE_LISTEN_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_LISTEN_SUCCESS, payload: response })]);
  }
}

function* addView({ payload }) {
  const { response, error } = yield call(services.listen.addView, payload);
  /* 
  if (error) {
    yield put({
      type: UPDATE_LISTEN_ERROR, 
      payload: error,
    });
  } else {
    const newListListen = yield select(listenList);
      const newListListenAdded = newListListen.map((item) =>
      item._id === response._id ? item : response
    );
    yield all([put({ type: UPDATE_LISTEN_SUCCESS, payload: response })]);
  } */
}

function* addClick({ payload }) {
  const { response, error } = yield call(services.listen.addClick, payload);
  /*  if (error) {
  } else {
    const currentListOfRoadmaps = yield select(companyList);
    const newListofRoadmaps = currentListOfRoadmaps?.map((content) => {
      if (content._id === response._id) {
        return response;
      }
      return content;
    });
    yield put({
      type: UPDATE_CURRENT_ROADMAP_LIST,
      payload: newListofRoadmaps,
    });
  } */
}

function* manageLikeDislike({ payload }) {
  const { response, error } = yield call(
    services.listen.manageLikeDislike,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_LISTEN_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    if (!payload.notReload) {
      yield put({ type: UPDATE_LISTEN_SUCCESS, payload: null });
    }

    if (payload.individual) {
      yield put({ type: SET_GET_LISTEN, payload: response });
    }
  }
}

function* getListensOffset({ payload }) {
  const { response, error } = yield call(services.listen.getOffset, payload);
  if (error) {
    yield put({
      type: GET_LISTENS_OFFSET_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const offsetListens = yield select(listensOffset);
    const newOffsetListens = !offsetListens
      ? response
      : [...offsetListens, ...response];
    yield put({
      type: GET_LISTENS_OFFSET_SUCCESS,
      payload: newOffsetListens,
    });
  }
}

function* updateListenListItem({ payload }) {
  const { response, error } = yield call(services.listen.update, payload);

  if (error) {
    yield put({
      type: UPDATE_LISTEN_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_LISTEN_SUCCESS, payload: response }),
      put({ type: SET_GET_LISTEN, payload: response }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllListens>(GET_ALL_LISTENS, getAllListens);
  yield takeLatest<GetListen>(GET_LISTEN, getListen);
  yield takeLatest<NewListen>(NEW_LISTEN, newListen);
  yield takeLatest<UpdateListen>(UPDATE_LISTEN, updateListen);
  yield takeLatest<DeleteListen>(DELETE_LISTEN, deleteListen);
  yield takeLatest<UploadMedia>(UPLOAD_MEDIA, uploadMedia);
  yield takeLatest<AddUserToListen>(ADD_USER_TO_LISTEN, addUserToListen);
  yield takeLatest<SharedListen>(SHARED_LISTEN, shareListen);
  yield takeLatest<QueryListen>(QUERY_LISTEN, queryListen);
  yield takeLatest<SaveListen>(SAVE_LISTEN, saveListen);
  yield takeLatest<AddViewListen>(ADD_VIEW_LISTEN, addView);
  yield takeLatest<AddClickListen>(ADD_CLICK_LISTEN, addClick);
  yield takeLatest<ManageLikeDislikeListen>(
    MANAGE_LIKE_DISLIKE_LISTEN,
    manageLikeDislike
  );
  yield takeLatest<GetListensOffset>(GET_LISTENS_OFFSET, getListensOffset);
  yield takeLatest<any>(UPDATE_ITEM_LIST_LISTEN, updateListenListItem);
}
