import React, { useState, useEffect, useRef } from "react";
import { AcademyDto } from "../../../../../../../../../types/academy.dto";
import {
  GridCard,
  GridCardImage,
  GridCardImageContainer,
  GridMdCard,
} from "../../../../../../../../../components/Layout/Dashboard/styles";
import {
  FlexContainer,
  Separator,
} from "../../../../../../../../../components/StyledComponents";
import { LogoMdCard, TitleMdCard, SubTextMdCard, TagsLabel } from "../../style";
import services from "../../../../../../../../../services";
import { TagDto } from "../../../../../../../../../types/tag.dto";

const Component = ({
  item,
  onClick,
  children,
}: {
  item: AcademyDto;
  onClick: (...args: any) => void;
  children?;
  stopPropagation?;
}) => {
  const [currentTags, setCurrentTags] = useState<TagDto[]>();
  const textRef: any = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  useEffect(() => {
    const textElement: any = textRef.current;
    if (textElement) {
      const maxLines = 2; // Adjust this to the desired number of lines
      const computedStyle = getComputedStyle(textElement);

      // Use the default line-height value if computedStyle.lineHeight is NaN
      const lineHeight = parseFloat(computedStyle.lineHeight) || 1.2;

      // Calculate the maximum height for 2 lines
      const maxHeight = maxLines * lineHeight;

      // Check if the content exceeds the maximum allowed height
      if (textElement.scrollHeight > maxHeight) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    }
  }, [item, textRef]);
  useEffect(() => {
    if (item) {
      const fetchData = async () => {
        const response: any = await services.tag.getAll({
          filterBy: { academy: item._id },
          limit: 4,
          offset: 0,
        });
        if (response.response) {
          setCurrentTags(response.response);
        }
      };
      fetchData();
    }
  }, [item]);

  const textClass = isOverflowing ? "text-ellipsis" : "";

  /*   console.log(isOverflowing, "mendez"); */
  return (
    <GridMdCard
      key={item._id}
      onClick={(ev) => {
        if (onClick) onClick(item);
      }}
    >
      <GridCardImageContainer style={{ height: "70px", marginBottom: "0px" }}>
        <GridCardImage src={item.bannerPic}></GridCardImage>
      </GridCardImageContainer>
      <FlexContainer
        justify="space-between"
        direction="column"
        style={{ padding: "2px 4px 11px 14px", flex: 1 }}
      >
        <div>
          <FlexContainer gap="3px">
            <LogoMdCard>
              <GridCardImage src={item.picture}></GridCardImage>
            </LogoMdCard>
            <TitleMdCard ref={textRef} className={`${textClass}`}>
              {item.name}
            </TitleMdCard>
          </FlexContainer>
          <Separator size={6}></Separator>
          <FlexContainer gap="3px">
            <FlexContainer align="center" gap="1px" wrap="wrap">
              <SubTextMdCard dark>{`Suscriptores: `}</SubTextMdCard>
              <SubTextMdCard>{item.userCount}</SubTextMdCard>
            </FlexContainer>

            <span>|</span>
            <FlexContainer align="center" gap="1px" wrap="wrap">
              <SubTextMdCard dark>{`Vistas: `}</SubTextMdCard>
              <SubTextMdCard>{item.viewCount ?? 0}</SubTextMdCard>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer wrap="wrap" gap="3px">
            <SubTextMdCard dark>Tags:</SubTextMdCard>
            <FlexContainer align="center" wrap="wrap" gap="2px">
              {(currentTags ?? []).map((tag) => {
                return <TagsLabel>{tag.name}</TagsLabel>;
              })}
            </FlexContainer>
          </FlexContainer>
        </div>

        {children}
      </FlexContainer>
    </GridMdCard>
  );
};

export default Component;
