import action from "../helpers/rest-client";

export class ErrorInvitation extends Error {}

class Notification {
  public new(payload) {
    return action
      .Post({
        url: "/notifications",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll(payload) {
    if (payload.filterBy) {
      payload.filterBy = JSON.stringify(payload.filterBy);
    }
    if (payload.select) {
      payload.select = JSON.stringify(payload.select);
    }
    const queryString = new URLSearchParams(payload).toString();
    return action
      .Get({
        url: `/notifications?platform=CAMPUS${
          queryString ? "&" + queryString : ""
        }`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public seeAll() {
    return action
      .Patch({
        url: `/notifications/see-all`,
        body: {},
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public delete({ _id }) {
    return action
      .Patch({
        url: `/notifications/erase/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public update({ _id, ...rest }) {
    return action
      .Patch({
        url: `/notifications/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const notification = new Notification();

export default notification;
