import Modal from "../../../../../../components/Modal";
import NewStudentForm from "../../../New";

const Component = () => {
  return (
    <Modal name="new-student-modal" title="Nuevo Estudiante">
      <NewStudentForm />
    </Modal>
  );
};

export default Component;
