import {
  initialState,
  Actions,
  State,
  GET_ALL_CONTENTS,
  GET_ALL_CONTENTS_ERROR,
  GET_ALL_CONTENTS_SUCCESS,
  NEW_CONTENT,
  NEW_CONTENT_ERROR,
  NEW_CONTENT_SUCCESS,
  RESET_NEW_CONTENT,
  UPDATE_CONTENT,
  UPDATE_CONTENT_ERROR,
  UPDATE_CONTENT_SUCCESS,
  RESET_UPDATE_CONTENT,
  DELETE_CONTENT,
  DELETE_CONTENT_ERROR,
  DELETE_CONTENT_SUCCESS,
  RESET_DELETE_CONTENT,
  SAVED_CONTENT,
  UNSAVED_CONTENT,
  UPDATE_CURRENT_CONTENT_LIST,
  ADD_VIEW_CONTENT,
  RESET_UPDATE_ONE_CONTENT,
  UPDATE_ONE_CONTENT_SUCCESS,
  UPDATE_ONE_CONTENT_ERROR,
  UPDATE_ONE_CONTENT,
  MANAGE_LIKE_DISLIKE_CONTENT,
  GET_CONTENTS_OFFSET,
  GET_CONTENTS_OFFSET_ERROR,
  GET_CONTENTS_OFFSET_SUCCESS,
  RESET_GET_CONTENTS_OFFSET,
  RESET_GET_ONE_CONTENT_DATA,
  RESET_GET_ALL_CONTENT_DATA,
  UPDATE_ITEM_LIST_CONTENT,
  SELECT_CONTENT,
} from "../types/content";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_CONTENTS:
      return {
        ...state,
        all: {
          data: state.all.data,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_CONTENTS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    /* case RESET_GET_ONE_CONTENT_DATA:
      return {
        ...state,
        : initialState.selected,
      }; */
    case RESET_GET_ALL_CONTENT_DATA:
      return {
        ...state,
        all: initialState.all,
      };
    case SELECT_CONTENT:
      return {
        ...state,
        content: {
          ...state.content,
          data: action.payload,
        },
      };
    case UPDATE_ITEM_LIST_CONTENT:
      return {
        ...state,
        update: {
          states: {
            success: false,
            error: false,
            loading: true,
          },
        },
      };
    case SAVED_CONTENT:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UNSAVED_CONTENT:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_CONTENTS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_CONTENTS_OFFSET:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case GET_CONTENTS_OFFSET_ERROR:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case GET_CONTENTS_OFFSET_SUCCESS:
      return {
        ...state,
        offSet: {
          data: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case RESET_GET_CONTENTS_OFFSET:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: false,
            success: false,
            error: false,
          },
        },
      };
    case UPDATE_CURRENT_CONTENT_LIST:
      return {
        ...state,
        all: {
          ...state.all,
          data: action.payload,
        },
      };

    case MANAGE_LIKE_DISLIKE_CONTENT:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_ONE_CONTENT:
      return {
        ...state,
        updateOne: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_ONE_CONTENT_ERROR:
      return {
        ...state,
        updateOne: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case UPDATE_ONE_CONTENT_SUCCESS:
      return {
        ...state,
        updateOne: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case RESET_UPDATE_ONE_CONTENT:
      return {
        ...state,
        updateOne: {
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case ADD_VIEW_CONTENT:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_CONTENT:
      return {
        ...state,
        create: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_CONTENT_SUCCESS:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };

    case NEW_CONTENT_ERROR:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_CONTENT:
      return {
        ...state,
        create: initialState.create,
      };
    case DELETE_CONTENT:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_CONTENT_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_CONTENT:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_CONTENT:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_CONTENT_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_CONTENT_ERROR:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_CONTENT:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
