import styled from "styled-components";

export const GridMobileCard = styled("div")`
  position: relative;
  &.listed svg {
    transition: fill 0.3s ease-in-out;
  }
  &.listed:hover svg {
    fill: ${(props) => props.theme.colors.White};
  }
`;

export const GridMobileImageCard = styled("div")`
  height: 200px;
  width: 100%;
`;

export const GridMobileInfoCard = styled("div")`
  padding: 8px 10px 2px 10px;
`;

export const TrianglePlay = styled("div")`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 0 25px 50px;
  border-color: transparent transparent transparent
    ${(props) => props.theme.colors.LightBlue};
`;

export const ShowDescriptionText = styled("h3")`
  font-size: 12px;
  color: ${(props) => props.theme.colors.LightBlue};
  text-decoration: underline;
  cursor: pointer;
`;
