import styled from "styled-components";

export const Wrapper = styled("div")`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 3px 0 rgba(106, 105, 112, 0.25);
`;

export const ArrowMobileButton = styled("button")`
  padding: 0px;
  border: 0px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  border: solid 1px ${(props) => props.theme.colors.LightBlue};
  &:active {
    background-color: ${(props) => props.theme.colors.LightBlue};
  }

  &:active svg {
    color: #fff !important;
  }
`;

export const FullScreenButton = styled("div")`
  position: absolute;
  z-index: 1;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
`;

export const CurrentSlide = styled("div")`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0%);
  margin: 0px;

  display: flex;
  justify-content: center;
  flex: 1;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  width: fit-content;
  background-color: #9b9b9b;
  padding: 5px;
  z-index: 2;
`;
export const Buttons = styled("div")`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Prev = styled("button")`
  padding: 0px;
  border: 0px;
  background: none;
  cursor: pointer;
  position: relative;

  svg {
    position: relative;
    left: 10px;
    z-index: 2;
    opacity: 0.7;
    transition: all 0.3s ease-in-out;
  }

  &:before {
    content: "";
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: #2496ed;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    svg {
      left: 6px;
      opacity: 1;
    }

    &:before {
      opacity: 1;
    }
  }
`;
export const Next = styled("button")`
  padding: 0px;
  border: 0px;
  background: none;
  cursor: pointer;
  position: relative;

  svg {
    position: relative;
    right: 10px;
    z-index: 2;
    opacity: 0.7;
    transition: all 0.3s ease-in-out;
  }

  &:before {
    content: "";
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: #2496ed;
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0%;
    transform: translate(50%, -50%);
    opacity: 0.2;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    svg {
      right: 6px;
      opacity: 1;
    }

    &:before {
      opacity: 1;
    }
  }
`;
