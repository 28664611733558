import styled from "styled-components";

export const CommunityWrapper = styled("div")`
  width: 100%;
  padding: 10px 10px 10px 0;
  border-radius: 10px;
`;

export const CommunityComments = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CommunityCommentsWrapper = styled("div")`
  max-height: 250px;
  width: 100%;
  overflow: auto;
  padding: 10px;
`;
