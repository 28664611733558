import styled from "styled-components";
import { CardWrapper } from "../../style";

export const AccordeonWrapper = styled(CardWrapper)`
  padding: 10px 20px;
  cursor: pointer;
`;
export const AccordeonContent = styled("div")`
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(79, 38, 162, 0.16);
  padding: 12px 38px;
  min-height: 200px;
  max-height: 200px;
  overflow-y: auto;
  margin: 10px;
  cursor: initial;
`;

export const AccordeonContentWrapper = styled("div")`
  max-height: 0px;
  overflow: auto;
  transition: max-height 0.2s;
  &.active {
    max-height: 600px;
  }
`;

export const ArrowIcon = styled("div")`
  &.active > svg {
    transform: rotate(180deg);
  }
  & > svg {
    transition: transform 0.3s ease-in-out;
  }
`;

export const ImageContent = styled("div")`
  position: relative;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(79, 38, 162, 0.16);
`;
