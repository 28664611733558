import {
  GetAllImages,
  GET_ALL_IMAGES,
  GetImage,
  GET_IMAGE,
  UpdateImage,
  UPDATE_IMAGE,
  DeleteImage,
  DELETE_IMAGE,
  GetImageSuccess,
  GET_IMAGE_SUCCESS,
  NewImage,
  NEW_IMAGE,
  ResetNewImage,
  RESET_NEW_IMAGE,
  ResetUpdateImage,
  RESET_UPDATE_IMAGE,
  ResetDeleteImage,
  RESET_DELETE_IMAGE,
  UploadMedia,
  UPLOAD_MEDIA,
  RESET_GET_IMAGE,
  ADD_USER_TO_IMAGE,
  SHARED_IMAGE,
  QUERY_IMAGE,
  RESET_QUERY_IMAGE,
  SAVED_IMAGE,
  SaveImage,
  AddViewImage,
  ADD_VIEW_IMAGE,
  ADD_CLICK_IMAGE,
  AddClickImage,
  MANAGE_LIKE_DISLIKE_IMAGE,
  GetImagesOffset,
  GET_IMAGES_OFFSET,
  RESET_GET_IMAGES_OFFSET,
  ResetGetImagesOffset,
  RESET_GET_ALL_IMAGE_DATA,
  RESET_GET_ONE_IMAGE_DATA,
  UPDATE_ITEM_LIST_IMAGE,
} from "../types/image";

export const getAllImages = (payload): GetAllImages => {
  return { type: GET_ALL_IMAGES, payload };
};

export const queryImage = (payload) => {
  return { type: QUERY_IMAGE, payload };
};

export const getImage = (id): GetImage => {
  return { type: GET_IMAGE, payload: id };
};

export const resetGetImage = () => {
  return { type: RESET_GET_IMAGE, payload: null };
};

export const setSelectedImage = (payload): GetImageSuccess => {
  return { type: GET_IMAGE_SUCCESS, payload };
};

export const offSetImages = (payload): GetImagesOffset => {
  return { type: GET_IMAGES_OFFSET, payload };
};

export const resetOffsetImages = (): ResetGetImagesOffset => {
  return { type: RESET_GET_IMAGES_OFFSET, payload: null };
};

export const resetGetAllImages = () => {
  return { type: RESET_GET_ALL_IMAGE_DATA, payload: null };
};

export const resetGetOneImage = () => {
  return { type: RESET_GET_ONE_IMAGE_DATA, payload: null };
};

export const newImage = (payload): NewImage => {
  return { type: NEW_IMAGE, payload };
};
export const uploadMediaImage = (payload): UploadMedia => {
  return { type: UPLOAD_MEDIA, payload };
};
export const resetNewImage = (): ResetNewImage => {
  return { type: RESET_NEW_IMAGE, payload: null };
};

export const updateImage = (payload): UpdateImage => {
  return { type: UPDATE_IMAGE, payload };
};

export const resetUpdateImage = (): ResetUpdateImage => {
  return { type: RESET_UPDATE_IMAGE, payload: null };
};

export const addUserToImage = (payload) => {
  return { type: ADD_USER_TO_IMAGE, payload };
};

export const deleteImage = (payload): DeleteImage => {
  return { type: DELETE_IMAGE, payload };
};

export const resetDeleteImage = (): ResetDeleteImage => {
  return { type: RESET_DELETE_IMAGE, payload: null };
};

export const shareImage = (payload) => {
  return { type: SHARED_IMAGE, payload };
};

export const resetQueryImage = () => {
  return { type: RESET_QUERY_IMAGE, payload: null };
};

export const updateImageListItem = (payload) => {
  return { type: UPDATE_ITEM_LIST_IMAGE, payload };
};

export const saveImage = (payload): SaveImage => {
  return { type: SAVED_IMAGE, payload };
};

export const addViewImage = (payload): AddViewImage => {
  return { type: ADD_VIEW_IMAGE, payload };
};

export const addClickImage = (payload): AddClickImage => {
  return { type: ADD_CLICK_IMAGE, payload };
};

export const manageLikeDislikeImage = (payload) => {
  return { type: MANAGE_LIKE_DISLIKE_IMAGE, payload };
};
