import styled from "styled-components";

export const MobileNavbar = styled("nav")`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 2px 10px;
  background-color: #ffffff;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  -webkit-position: fixed;
  -webkit-transform: translateZ(0);
`;

export const MobileMenuItemLabel = styled("h3")`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) =>
    !!props.active ? props.theme.colors.LightBlue : "#6a6970"};
`;
