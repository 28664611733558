import {
  GetAllVideos,
  GET_ALL_VIDEOS,
  GetVideo,
  GET_VIDEO,
  UpdateVideo,
  UPDATE_VIDEO,
  DeleteVideo,
  DELETE_VIDEO,
  GetVideoSuccess,
  GET_VIDEO_SUCCESS,
  NewVideo,
  NEW_VIDEO,
  ResetNewVideo,
  RESET_NEW_VIDEO,
  ResetUpdateVideo,
  RESET_UPDATE_VIDEO,
  ResetDeleteVideo,
  RESET_DELETE_VIDEO,
  UploadMedia,
  UPLOAD_MEDIA,
  RESET_GET_VIDEO,
  ADD_USER_TO_VIDEO,
  SHARED_VIDEO,
  QUERY_VIDEO,
  RESET_QUERY_VIDEO,
  SaveVideo,
  SAVE_VIDEO,
  UNSAVED_VIDEO,
  SAVED_VIDEO,
  ADD_VIEW_VIDEO,
  AddViewVideo,
  ADD_CLICK_VIDEO,
  MANAGE_LIKE_DISLIKE_VIDEO,
  GET_VIDEOS_OFFSET,
  GetVideosOffset,
  RESET_GET_VIDEOS_OFFSET,
  ResetGetVideosOffset,
  SET_OFFSET_VIDEO,
  RESET_GET_ALL_VIDEO_DATA,
  RESET_GET_ONE_VIDEO_DATA,
  UPDATE_ITEM_LIST_VIDEO,
} from "../types/video";

export const getAllVideos = (payload): GetAllVideos => {
  return { type: GET_ALL_VIDEOS, payload };
};

export const updateVideoListItem = (payload) => {
  return { type: UPDATE_ITEM_LIST_VIDEO, payload };
};

export const getVideo = (id): GetVideo => {
  return { type: GET_VIDEO, payload: id };
};

export const saveVideo = (payload): SaveVideo => {
  return { type: SAVE_VIDEO, payload };
};

export const unSavedVideo = (payload) => {
  return { type: UNSAVED_VIDEO, payload };
};
export const savedVideo = (payload) => {
  return { type: SAVED_VIDEO, payload };
};

export const resetGetAllVideos = () => {
  return { type: RESET_GET_ALL_VIDEO_DATA, payload: null };
};

export const resetGetOneVideo = () => {
  return { type: RESET_GET_ONE_VIDEO_DATA, payload: null };
};

export const resetGetVideo = () => {
  return { type: RESET_GET_VIDEO, payload: null };
};

export const setSelectedVideo = (payload): GetVideoSuccess => {
  return { type: GET_VIDEO_SUCCESS, payload };
};

export const newVideo = (payload): NewVideo => {
  return { type: NEW_VIDEO, payload };
};
export const uploadMediaVideo = (payload): UploadMedia => {
  return { type: UPLOAD_MEDIA, payload };
};
export const resetNewVideo = (): ResetNewVideo => {
  return { type: RESET_NEW_VIDEO, payload: null };
};

export const updateVideo = (payload): UpdateVideo => {
  return { type: UPDATE_VIDEO, payload };
};

export const resetUpdateVideo = (): ResetUpdateVideo => {
  return { type: RESET_UPDATE_VIDEO, payload: null };
};

export const addUserToVideo = (payload) => {
  return { type: ADD_USER_TO_VIDEO, payload };
};

export const offSetVideos = (payload): GetVideosOffset => {
  return { type: GET_VIDEOS_OFFSET, payload };
};

export const resetOffsetVideos = (): ResetGetVideosOffset => {
  return { type: RESET_GET_VIDEOS_OFFSET, payload: null };
};

export const deleteVideo = (payload): DeleteVideo => {
  return { type: DELETE_VIDEO, payload };
};

export const resetDeleteVideo = (): ResetDeleteVideo => {
  return { type: RESET_DELETE_VIDEO, payload: null };
};

export const setOffsetVideo = (payload) => {
  return { type: SET_OFFSET_VIDEO, payload };
};

export const shareVideo = (payload) => {
  return { type: SHARED_VIDEO, payload };
};

export const queryVideo = (payload) => {
  return { type: QUERY_VIDEO, payload };
};

export const addViewVideo = (payload): AddViewVideo => {
  return { type: ADD_VIEW_VIDEO, payload };
};

export const resetQueryVideo = () => {
  return { type: RESET_QUERY_VIDEO, payload: null };
};

export const addClickVideo = (payload) => {
  return { type: ADD_CLICK_VIDEO, payload };
};

export const manageLikeDislikeVideo = (payload) => {
  return { type: MANAGE_LIKE_DISLIKE_VIDEO, payload };
};
