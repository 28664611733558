import styled from "styled-components";

export const ItemDataText = styled("h5")`
  font-size: 12px;
  color: #6a6970;
`;

export const DotContent = styled("div")`
  width: 6px;
  height: 6px;
  background-color: #d9d9d9;
`;

export const GridButton = styled("div")`
  width: 32px;
  height: 32px;
  border-radius: 10px;
  color: ${(props) => (!!props.active ? "#fff" : props.theme.colors.LightBlue)};
  background-color: ${(props) =>
    !!props.active ? props.theme.colors.LightBlue : "#fff"};
  display: flex;
  align-items: center;
  justify-content: center;
`;
