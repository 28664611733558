import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Title, Img, Link, AuthWrapper, GridStructure, Logo } from "./styles";
import { initialValues, schema } from "../../constants/form/auth/sign-in";
import { AuthContext } from "../../provider/AuthProvider";
import Input from "../../components/Form/Input";
import Submit from "../../components/Form/Submit";
import {
  recoverPassword,
  resetPassword,
  resetRecoverPassword,
  resetResetPassword,
  resetSignIn,
  signIn,
  signInSocial,
} from "../../store/actions/auth";
import Constana from "../../assets/images/isotipo-constana-violet.png";
import LogoConstana from "../../assets/images/Subtraction-30-violet.png";
import { toast, ToastContainer } from "react-toastify";
import {
  Center,
  FlexContainer,
  Separator,
} from "../../components/StyledComponents";
import { useGoogleLogin } from "@react-oauth/google";
import {
  LoginSocialFacebook,
  LoginSocialInstagram,
} from "reactjs-social-login";
import {
  schema as recoveryCodeSchema,
  initialValues as recoveryCodeInitialValues,
} from "../../constants/form/auth/recovery-code";

import {
  schema as passwordRecoverySchema,
  initialValues as passwordRecoveryInitialValues,
} from "../../constants/form/auth/password-recovery";

import { FcGoogle } from "react-icons/fc";
import FacebookButton from "../../components/FacebookButton";
import { FaFacebookSquare, FaGithub, FaLinkedin } from "react-icons/fa";
import SocialButton from "../../components/SocialButton";
import { getGitHubUrl } from "../../helpers/githubUrl";
import InstagramButton from "../../components/InstagramButton";
import { getLinkedinUrl } from "../../helpers/linkedinUrl";
import UserWindow from "../../hooks/useScreenSize";
import Button from "../../components/Button";
import ConstanaLogo from "../../assets/icons/constanalightblue.svg";
import Modal from "../../components/Modal";
import { useWindowSize } from "../../hooks/useWindowScreen";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { CautionText } from "../SignUp/style";
import { hideModal, showModal } from "../../store/actions/modal";
import services from "../../services";

interface CustomizedState {
  siginStates: {
    loading: boolean;
    success: boolean;
    error: boolean;
  };
  from: {
    pathname: string;
    search: string;
    hash: string;
  };
}

const Component = ({
  signinStates,
  sendSecurityCodeStates,
  sendSecurityCode,
  resetPasswordStates,
}) => {
  const token = services.auth.getToken();
  const [formSubmmited, setFormSubmmited] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [showLogin, setShowLogin] = useState(true);
  const [showPasswordRecovery, setShowPasswordRecovery] = useState(false);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [accountToRecover, setAccountToRecover] = useState("");
  const { screenSize } = UserWindow();
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let { isAuthenticated } = React.useContext(AuthContext);
  let from = !(location.state as CustomizedState)?.from?.search
    ? (location.state as CustomizedState)?.from?.pathname || "/dashboard"
    : `${(location.state as CustomizedState)?.from?.pathname}/${
        (location.state as CustomizedState)?.from?.search
      }`;

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      dispatch(
        signInSocial({ code: codeResponse.access_token, social: "google" })
      );
    },
  });
  const changePassword = ({ values, actions }) => {
    dispatch(resetPassword(values));
  };
  const onSubmit = ({ values, actions }) => {
    dispatch(signIn(values));
  };
  const sendRecoveryCode = ({ values, actions }) => {
    dispatch(recoverPassword(values));
    setAccountToRecover(values.email);
  };
  /*   useEffect(() => {
    if (isAuthenticated) {
      navigate(from, { replace: true });
    }
    return () => {};
  }, [isAuthenticated]); */

  useEffect(() => {
    if (signinStates.success) {
      setTimeout(() => {
        dispatch(hideModal());
      }, 1000);
    }
  }, [signinStates]);

  useEffect(() => {
    if (sendSecurityCodeStates.success) {
      toast.success(`Se envio el codigo de seguridad`, {
        containerId: "container-sign-in",
      });
      setShowPasswordChange(true);
      dispatch(resetRecoverPassword());
    }
    if (sendSecurityCodeStates.error) {
      toast.error(sendSecurityCodeStates.error, {
        containerId: "container-sign-in",
      });
      dispatch(resetRecoverPassword());
    }
  }, [sendSecurityCodeStates]);
  useEffect(() => {
    if (resetPasswordStates.success) {
      toast.success(`Se cambio la contraseña correctamente`, {
        containerId: "container-sign-in",
      });

      dispatch(resetResetPassword());
    }
    if (resetPasswordStates.error) {
      toast.error(resetPasswordStates.error);
      dispatch(resetResetPassword());
    }
  }, [resetPasswordStates]);
  return (
    <Modal
      padding="27px"
      width={isMobile ? `315px` : `340px`}
      name="sign-in-content"
      style={{ wrapper: { minHeight: isMobile ? `min-content` : "611px" } }}
    >
      <Center>
        <Logo>
          <div style={{ width: "160px", height: "26px" }}>
            <Img src={ConstanaLogo} />
          </div>
        </Logo>
      </Center>
      {!showPasswordChange && !showPasswordRecovery && (
        <>
          <Title>Ingresar</Title>
          <GridStructure>
            <SocialButton
              color={"#2a2747"}
              background={"#fff"}
              active={"#1268a9"}
              text={"Continuar con Google"}
              onClick={() => {
                login();
              }}
              icon={<FcGoogle size={20}></FcGoogle>}
            ></SocialButton>

            <FacebookLogin
              appId="1149804909391563"
              fields="name,email,picture,gender"
              onSuccess={(response) => {
                dispatch(
                  signInSocial({
                    social: "facebook",
                    body: response.userID,
                    code: response.accessToken,
                  })
                );
              }}
              scope="public_profile, email, user_gender"
              onFail={(error) => {
                toast.error(`Error al acceder`, {
                  containerId: "container-sign-in",
                });
              }}
              /* onProfileSuccess={(response) => {
                console.log("Get Profile Success!", response);
              }} */
              render={({ onClick, logout }) => (
                <SocialButton
                  color={"#2a2747"}
                  background={"#fff"}
                  active={"#1268a9"}
                  text={"Continuar con Facebook"}
                  onClick={onClick}
                  icon={
                    <FaFacebookSquare
                      size={20}
                      color="#0866ff"
                    ></FaFacebookSquare>
                  }
                ></SocialButton>
              )}
            />
            <SocialButton
              color={"#2a2747"}
              background={"#fff"}
              active={"#1268a9"}
              text={"Continuar con Linkedin"}
              onClick={() => {
                window.location.href = getLinkedinUrl(from);
              }}
              icon={<FaLinkedin color={"#177FB1"} size={20}></FaLinkedin>}
            ></SocialButton>
          </GridStructure>
          <Separator size={14}></Separator>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              textAlign: "center",
            }}
          >
            <div
              style={{
                flexGrow: "1",
                borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
              }}
            ></div>
            <div
              style={{
                width: "49px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              ó
            </div>
            <div
              style={{
                flexGrow: "1",
                borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
              }}
            ></div>
          </div>
          <Separator size={14}></Separator>
          <Formik
            initialValues={{ ...initialValues }}
            onSubmit={(values, actions) => {
              if (onSubmit) onSubmit({ values, actions });
            }}
            validateOnChange={formSubmmited}
            validateOnMount={false}
            validateOnBlur={false}
            validationSchema={schema}
            enableReinitialize
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <form
                  className="theme-form"
                  onSubmit={(event) => {
                    setFormSubmmited(true);
                    handleSubmit(event);
                  }}
                  id="sign-in-form"
                >
                  <Input
                    name="user"
                    error={errors["user"]}
                    touched={touched["user"]}
                    type="text"
                    placeholder="Nombre de usuario ó e-mail"
                    onChange={handleChange}
                    fieldStyles={{
                      height: "29.5px",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                    inputStyles={{
                      padding: "7.9px 9px 7.9px 9px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                    onBlur={handleBlur}
                    options={{
                      marginBottom: 11,

                      skin: "gray",
                    }}
                  />

                  <Input
                    name="password"
                    error={errors["password"]}
                    touched={touched["password"]}
                    type="password"
                    placeholder="Contraseña"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fieldStyles={{
                      height: "29.5px",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                    inputStyles={{
                      padding: "7.9px 9px 7.9px 9px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                    options={{
                      marginBottom: 14,
                      skin: "gray",
                    }}
                  />
                  <FlexContainer align="center" justify="center">
                    <Link
                      style={{
                        fontSize: "10px",
                        fontWeight: "600",
                      }}
                      onClick={() => {
                        setShowLogin(false);
                        setShowPasswordRecovery(true);
                        setShowPasswordChange(false);
                      }}
                    >
                      ¿Olvidaste tu contraseña?
                    </Link>
                  </FlexContainer>
                  <Separator size={10}></Separator>

                  <Submit
                    isSubmmiting={signinStates.loading}
                    form="sign-in-form"
                    type="submit"
                    color="Primary"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      height: "29px",
                      borderRadius: "10px",
                    }}
                    options={{
                      block: true,
                      type: "filled",
                      skin: "lightblue",
                      loading: "LightBlue",
                      size: "sm",
                      marginBottom: "17px",
                    }}
                  >
                    Ingresar
                  </Submit>
                  <FlexContainer align="center" justify="center">
                    <Link
                      style={{
                        fontSize: "10px",
                        fontWeight: "600",
                      }}
                      onClick={() => dispatch(showModal("sign-up-content"))}
                    >
                      ¿No tenés una cuenta?
                    </Link>
                  </FlexContainer>
                </form>
              );
            }}
          </Formik>
        </>
      )}

      {showPasswordRecovery && !showPasswordChange && (
        <>
          <Title>Recuperar Contraseña</Title>
          <CautionText>
            Por favor, escribe la dirección de correo electrónico asociado con
            tu cuenta de Constana, y te enviaremos un correo con un enlace para
            recuperar tu contraseña.
          </CautionText>
          <Separator size={17}></Separator>
          <Formik
            initialValues={{ ...recoveryCodeInitialValues }}
            onSubmit={(values, actions) => {
              if (sendRecoveryCode) sendRecoveryCode({ values, actions });
            }}
            validateOnChange={formSubmmited}
            validateOnBlur={false}
            validationSchema={recoveryCodeSchema}
            enableReinitialize
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <form
                  className="theme-form"
                  onSubmit={(event) => {
                    setFormSubmmited(true);
                    handleSubmit(event);
                  }}
                  id="recovery-code-form"
                >
                  <Input
                    name="email"
                    error={errors["email"]}
                    touched={touched["email"]}
                    type="text"
                    placeholder="Ingresar e-mail"
                    onChange={handleChange}
                    fieldStyles={{ height: "29.5px", borderRadius: "10px" }}
                    inputStyles={{
                      padding: "7.9px 9px 7.9px 9px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                    onBlur={handleBlur}
                    options={{
                      marginBottom: 11,
                      skin: "gray",
                    }}
                  />

                  <Submit
                    form="recovery-code-form"
                    isSubmmiting={sendSecurityCodeStates.loading}
                    color="Primary"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      height: "29px",
                      borderRadius: "10px",
                    }}
                    options={{
                      block: true,
                      type: "filled",
                      skin: "lightblue",
                      loading: "LightBlue",
                      size: "sm",
                      marginBottom: "10px",
                    }}
                  >
                    Recuperar contraseña
                  </Submit>

                  <Button
                    type="button"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      height: "29px",
                      borderRadius: "10px",
                    }}
                    options={{
                      hoverSkin: true,
                      block: true,
                      type: "outline",
                      skin: "danger",
                      size: "sm",
                      marginBottom: "19px",
                    }}
                    onClick={() => {
                      setShowLogin(true);
                      setShowPasswordRecovery(false);
                      setShowPasswordChange(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </form>
              );
            }}
          </Formik>
        </>
      )}

      {showPasswordChange && (
        <>
          <Title>Recupera tu contraseña</Title>
          <CautionText>
            Ingresa el código de recuperación que enviamos a tu correo
            electrónico y luego rellena los campos con la nueva contraseña.
          </CautionText>

          <Formik
            initialValues={{
              ...passwordRecoveryInitialValues,
              ...sendSecurityCode,
            }}
            onSubmit={(values, actions) => {
              if (changePassword) changePassword({ values, actions });
            }}
            validateOnChange={formSubmmited}
            validateOnBlur={false}
            validationSchema={passwordRecoverySchema}
            enableReinitialize
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <form
                  className="theme-form"
                  onSubmit={(event) => {
                    setFormSubmmited(true);
                    handleSubmit(event);
                  }}
                  id="recover-password-form"
                >
                  <input
                    type="hidden"
                    name="varificationCode"
                    value={values["verificationCode"]}
                  />

                  <Input
                    name="email"
                    error={errors["email"]}
                    touched={touched["email"]}
                    value={values["email"]}
                    type="text"
                    placeholder="Ingresar email"
                    fieldStyles={{ height: "29.5px", borderRadius: "10px" }}
                    inputStyles={{
                      padding: "7.9px 9px 7.9px 9px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      skin: "gray",
                      marginBottom: 11,
                    }}
                  />

                  <Input
                    name="recoveryCode"
                    error={errors["recoveryCode"]}
                    touched={touched["recoveryCode"]}
                    value={values["recoveryCode"]}
                    type="text"
                    placeholder="Código de recuperación"
                    onChange={handleChange}
                    fieldStyles={{ height: "29.5px", borderRadius: "10px" }}
                    inputStyles={{
                      padding: "7.9px 9px 7.9px 9px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                    onBlur={handleBlur}
                    options={{
                      label: "Código de recuperación",
                      skin: "gray",
                      marginBottom: 11,
                    }}
                    autocomplete="off"
                  />

                  <Input
                    name="password"
                    error={errors["password"]}
                    touched={touched["password"]}
                    type="password"
                    placeholder="Nueva contraseña"
                    fieldStyles={{ height: "29.5px", borderRadius: "10px" }}
                    inputStyles={{
                      padding: "7.9px 9px 7.9px 9px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Nueva contraseña",
                      skin: "gray",
                      marginBottom: 11,
                    }}
                    autocomplete="off"
                  />

                  <Input
                    name="confirm-password"
                    error={errors["confirm-password"]}
                    touched={touched["confirm-password"]}
                    type="password"
                    placeholder="Repetir nueva contraseña"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Confirmar contraseña",
                      skin: "gray",
                      marginBottom: 36,
                    }}
                    autocomplete="off"
                  />

                  <Submit
                    form="recover-password-form"
                    isSubmmiting={resetPasswordStates.loading}
                    options={{
                      block: true,
                      type: "filled",
                      skin: "primary",
                      size: "lg",
                      marginBottom: "20px",
                    }}
                  >
                    Cambiar contraseña
                  </Submit>

                  <Button
                    type="button"
                    options={{
                      hoverSkin: true,
                      block: true,
                      skin: "danger",
                      size: "lg",
                      type: "outline",
                    }}
                    onClick={() => {
                      setShowLogin(true);
                      setShowPasswordRecovery(false);
                      setShowPasswordChange(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </form>
              );
            }}
          </Formik>
        </>
      )}
      <ToastContainer
        containerId="container-sign-in"
        enableMultiContainer
        limit={1}
      ></ToastContainer>
    </Modal>
  );
};

const state = ({ authStore }) => {
  const { states: signinStates } = authStore.signin;
  const { states: sendSecurityCodeStates, data: sendSecurityCode } =
    authStore.recoverPassword;
  const { states: resetPasswordStates } = authStore.resetPassword;
  return {
    signinStates,
    sendSecurityCodeStates,
    sendSecurityCode,
    resetPasswordStates,
  };
};

export default connect(state)(Component);
