import {
  initialState,
  Actions,
  State,
  GET_ALL_SUSCRIPTION,
  GET_ALL_SUSCRIPTION_SUCCESS,
  GET_ALL_SUSCRIPTION_ERROR,
  NEW_SUSCRIPTION,
  NEW_SUSCRIPTION_ERROR,
  NEW_SUSCRIPTION_SUCCESS,
  DELETE_SUSCRIPTION,
  DELETE_SUSCRIPTION_SUCCESS,
  DELETE_SUSCRIPTION_ERROR,
  RESET_DELETE_SUSCRIPTION,
  RESET_NEW_SUSCRIPTION,
  UPDATE_SUSCRIPTION,
  UPDATE_SUSCRIPTION_ERROR,
  UPDATE_SUSCRIPTION_SUCCESS,
  RESET_UPDATE_SUSCRIPTION,
  GET_SUSCRIPTION_ERROR,
  GET_SUSCRIPTION_SUCCESS,
  GET_SUSCRIPTION,
  RESET_GET_SUSCRIPTION,
} from "../types/suscription";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_SUSCRIPTION:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_SUSCRIPTION_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_SUSCRIPTION_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_SUSCRIPTION:
      return {
        ...state,
        suscription: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_SUSCRIPTION_SUCCESS:
      return {
        ...state,
        suscription: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_SUSCRIPTION_ERROR:
      return {
        ...state,
        suscription: {
          ...state.suscription,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_SUSCRIPTION:
      return {
        ...state,
        suscription: {
          ...state.suscription,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case NEW_SUSCRIPTION:
      return {
        ...state,
        create: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_SUSCRIPTION_SUCCESS:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_SUSCRIPTION_ERROR:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_SUSCRIPTION:
      return {
        ...state,
        create: initialState.create,
      };

    case UPDATE_SUSCRIPTION:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_SUSCRIPTION_ERROR:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case UPDATE_SUSCRIPTION_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case RESET_UPDATE_SUSCRIPTION:
      return {
        ...state,
        update: {
          states: initialState.update.states,
        },
      };
    case DELETE_SUSCRIPTION:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_SUSCRIPTION_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_SUSCRIPTION_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_SUSCRIPTION:
      return {
        ...state,
        delete: initialState.delete,
      };
    default:
      return state;
  }
};

export default reducers;
