// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-pdf-page-desktop {
  flex: 1 1;
  height: 80%;
  border-radius: 10px;
}

.react-pdf-page-mobile {
  flex: 1 1;
  height: 70%;
  border-radius: 10px;
}

.react-pdf-page-one {
  height: 100%;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/SlideShow/View/components/FullScreenSlideshow/style.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".react-pdf-page-desktop {\r\n  flex: 1;\r\n  height: 80%;\r\n  border-radius: 10px;\r\n}\r\n\r\n.react-pdf-page-mobile {\r\n  flex: 1;\r\n  height: 70%;\r\n  border-radius: 10px;\r\n}\r\n\r\n.react-pdf-page-one {\r\n  height: 100%;\r\n  border-radius: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
