import { ContentDto } from "../../types/content.dto";

export const GET_ALL_CONTENTS = "GET_ALL_CONTENTS";
export const GET_ALL_CONTENTS_ERROR = "GET_ALL_CONTENTS_ERROR";
export const GET_ALL_CONTENTS_SUCCESS = "GET_ALL_CONTENTS_SUCCESS";
export const GET_CONTENT = "GET_CONTENT";
export const GET_CONTENT_ERROR = "GET_CONTENT_ERROR";
export const GET_CONTENT_SUCCESS = "GET_CONTENT_SUCCESS";
export const NEW_CONTENT = "NEW_CONTENT";
export const NEW_CONTENT_ERROR = "NEW_CONTENT_ERROR";
export const NEW_CONTENT_SUCCESS = "NEW_CONTENT_SUCCESS";
export const RESET_NEW_CONTENT = "RESET_NEW_CONTENT";
export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const UPDATE_CONTENT_ERROR = "UPDATE_CONTENT_ERROR";
export const UPDATE_CONTENT_SUCCESS = "UPDATE_CONTENT_SUCCESS";
export const RESET_UPDATE_CONTENT = "RESET_UPDATE_CONTENT";
export const DELETE_CONTENT = "DELETE_CONTENT";
export const DELETE_CONTENT_ERROR = "DELETE_CONTENT_ERROR";
export const DELETE_CONTENT_SUCCESS = "DELETE_CONTENT_SUCCESS";
export const RESET_DELETE_CONTENT = "RESET_DELETE_CONTENT";
export const SET_UPDATE_CONTENT_DATA = "SET_UPDATE_CONTENT_DATA";
export const SET_NEW_CONTENT_DATA = "SET_NEW_CONTENT_DATA";
export const UPDATE_CURRENT_CONTENT_LIST = "UPDATE_CURRENT_CONTENT_LIST";

export const UNSAVED_CONTENT = "UNSAVED_CONTENT";

export const SAVED_CONTENT = "SAVED_CONTENT";

export const SELECT_CONTENT = "SELECT_CONTENT";
export const UPDATE_CONTENT_ROLE = "UPDATE_CONTENT_ROLE";
export const DELETE_CONTENT_MEMBER = "DELETE_CONTENT_MEMBER";

export const ADD_VIEW_CONTENT = "ADD_VIEW_CONTENT";
export const ADD_CLICK_CONTENT = "ADD_CLICK_CONTENT";

export const MANAGE_LIKE_DISLIKE_CONTENT = "MANAGE_LIKE_DISLIKE_CONTENT";

export const UPDATE_ONE_CONTENT = "UPDATE_ONE_CONTENT";
export const UPDATE_ONE_CONTENT_SUCCESS = "UPDATE_ONE_CONTENT_SUCCESS";
export const UPDATE_ONE_CONTENT_ERROR = "UPDATE_ONE_CONTENT_ERROR";
export const RESET_UPDATE_ONE_CONTENT = "RESET_UPDATE_ONE_CONTENT";
export const GET_CONTENTS_OFFSET = "GET_CONTENTS_OFFSET";
export const GET_CONTENTS_OFFSET_SUCCESS = "GET_CONTENTS_OFFSET_SUCCESS";
export const GET_CONTENTS_OFFSET_ERROR = "GET_CONTENTS_OFFSET_ERROR";
export const RESET_GET_CONTENTS_OFFSET = "RESET_GET_CONTENTS_OFFSET";
export const RESET_GET_ALL_CONTENT_DATA = "RESET_GET_ALL_CONTENT_DATA";
export const RESET_GET_ONE_CONTENT_DATA = "RESET_GET_ONE_CONTENT_DATA";
export const UPDATE_ITEM_LIST_CONTENT = "UPDATE_ITEM_LIST_CONTENT";

export interface ResetGetAllContentData {
  type: typeof RESET_GET_ALL_CONTENT_DATA;
  payload: null;
}

export interface ResetGetOneContentData {
  type: typeof RESET_GET_ONE_CONTENT_DATA;
  payload: null;
}

export interface UpdateItemListContent {
  type: typeof UPDATE_ITEM_LIST_CONTENT;
  payload: any;
}

export interface GetContentsOffset {
  type: typeof GET_CONTENTS_OFFSET;
  payload: { limit: number; offset: number };
}

export interface GetContentsOffsetError {
  type: typeof GET_CONTENTS_OFFSET_ERROR;
  payload: string | boolean;
}

export interface GetContentsOffsetSuccess {
  type: typeof GET_CONTENTS_OFFSET_SUCCESS;
  payload: ContentDto[];
}

export interface ResetGetContentsOffset {
  type: typeof RESET_GET_CONTENTS_OFFSET;
  payload: null;
}

export interface UpdateOneContent {
  type: typeof UPDATE_ONE_CONTENT;
  payload: any;
}
export interface UpdateOneContentSuccess {
  type: typeof UPDATE_ONE_CONTENT_SUCCESS;
  payload: ContentDto;
}
export interface UpdateOneContentError {
  type: typeof UPDATE_ONE_CONTENT_ERROR;
  payload: boolean | string;
}
export interface ResetUpdateOneContent {
  type: typeof RESET_UPDATE_ONE_CONTENT;
  payload: null;
}

export interface ManageLikeDislikeContent {
  type: typeof MANAGE_LIKE_DISLIKE_CONTENT;
  payload: {
    _id: string;
    user: string;
    condition: "like" | "dislike";
    action: "add" | "remove";
  };
}

export interface SelectContent {
  type: typeof SELECT_CONTENT;
  payload: ContentDto;
}

export interface AddViewContent {
  type: typeof ADD_VIEW_CONTENT;
  payload: { _id: string };
}

export interface SavedContent {
  type: typeof SAVED_CONTENT;
  payload: string;
}
export interface UnSavedContent {
  type: typeof UNSAVED_CONTENT;
  payload: string;
}

export interface UpdateCurrentCONTENTList {
  type: typeof UPDATE_CURRENT_CONTENT_LIST;
  payload: ContentDto[];
}

export interface GetAllContents {
  type: typeof GET_ALL_CONTENTS;
  payload: null;
}

export interface AddClickContent {
  type: typeof ADD_CLICK_CONTENT;
  payload: { _id: string; currentCohort?: string; currentAcademy?: string };
}

export interface GetAllContentsSuccess {
  type: typeof GET_ALL_CONTENTS_SUCCESS;
  payload: ContentDto[];
}

export interface GetAllContentsError {
  type: typeof GET_ALL_CONTENTS_ERROR;
  payload: boolean | string;
}

export interface GetContent {
  type: typeof GET_CONTENT;
  payload: { _id: string };
}

export interface GetContentSuccess {
  type: typeof GET_CONTENT_SUCCESS;
  payload: ContentDto;
}

export interface GetContentError {
  type: typeof GET_CONTENT_ERROR;
  payload: boolean | string;
}

export interface NewContent {
  type: typeof NEW_CONTENT;
  payload: ContentDto;
}

export interface NewContentSuccess {
  type: typeof NEW_CONTENT_SUCCESS;
  payload: ContentDto;
}

export interface NewContentError {
  type: typeof NEW_CONTENT_ERROR;
  payload: boolean | string;
}

export interface SetUpdateContentData {
  type: typeof SET_UPDATE_CONTENT_DATA;
  payload: ContentDto;
}

export interface SetNewContentData {
  type: typeof SET_NEW_CONTENT_DATA;
  payload: ContentDto;
}

export interface ResetNewContent {
  type: typeof RESET_NEW_CONTENT;
  payload: null;
}

export interface UpdateContent {
  type: typeof UPDATE_CONTENT;
  payload: ContentDto;
}

export interface UpdateContentSuccess {
  type: typeof UPDATE_CONTENT_SUCCESS;
  payload: ContentDto;
}

export interface UpdateContentError {
  type: typeof UPDATE_CONTENT_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateContent {
  type: typeof RESET_UPDATE_CONTENT;
  payload: null;
}

export interface DeleteContent {
  type: typeof DELETE_CONTENT;
  payload: { _id: string };
}

export interface DeleteContentSuccess {
  type: typeof DELETE_CONTENT_SUCCESS;
  payload: null;
}

export interface DeleteContentError {
  type: typeof DELETE_CONTENT_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteContent {
  type: typeof RESET_DELETE_CONTENT;
  payload: null;
}

export interface DeleteContentMember {
  type: typeof DELETE_CONTENT_MEMBER;
  payload: { _id: string; userId: string };
}

export interface State {
  all: {
    data: ContentDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  offSet: {
    data: ContentDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  content: {
    data: null | ContentDto;
    states: {
      success: boolean;
      error: boolean | string;
      loading: boolean;
    };
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateOne: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  offSet: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  content: {
    data: null,
    states: {
      success: false,
      error: false,
      loading: false,
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateOne: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllContents
  | GetAllContentsSuccess
  | GetAllContentsError
  | GetContent
  | GetContentSuccess
  | GetContentError
  | NewContent
  | NewContentSuccess
  | NewContentError
  | ResetNewContent
  | UpdateContent
  | UpdateContentSuccess
  | UpdateContentError
  | ResetUpdateContent
  | DeleteContent
  | DeleteContentSuccess
  | DeleteContentError
  | ResetDeleteContent
  | SavedContent
  | UnSavedContent
  | AddClickContent
  | UpdateCurrentCONTENTList
  | AddViewContent
  | ManageLikeDislikeContent
  | UpdateOneContent
  | UpdateOneContentSuccess
  | UpdateOneContentError
  | ResetUpdateOneContent
  | GetContentsOffset
  | GetContentsOffsetError
  | GetContentsOffsetSuccess
  | ResetGetContentsOffset
  | ResetGetAllContentData
  | ResetGetOneContentData
  | UpdateItemListContent
  | SelectContent;
