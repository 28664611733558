import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Center, FlexContainer } from "../../../components/StyledComponents";
import Button from "../../../components/Button";
import { connect, useDispatch } from "react-redux";
import { AcademyDto } from "../../../types/academy.dto";
import { UserDto } from "../../../types/user.dto";
import FilterByName from "./components/FilterByName";
import { CohortDto } from "../../../types/cohort.dto";
import invitationActions from "../../../store/actions/invitation";
import academyActions from "../../../store/actions/academies";
import Loader from "../../../components/Loader";
import { InvitationDto } from "../../../types/invitation.dto";
import { EntityImageContainer, EntityTitleSemiBold, ListItem } from "./style";
import { Img } from "../../SignIn/styles";
import { StateDto } from "../../../types/states.dto";
import {
  getAllCohorts,
  resetGetAllCohorts,
} from "../../../store/actions/cohort";
import { useWindowSize } from "../../../hooks/useWindowScreen";
import InfinityScroll from "../../../components/InfinityScroll";
interface ComponentProps {
  user: UserDto;
  cohorts: CohortDto[];
  invitations: InvitationDto[];
  academies: AcademyDto[];
  invitationsStates: StateDto;
  updateInvitationStates: StateDto;
}
interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  user,
  cohorts,
  invitations,
  academies,
  invitationsStates,
  updateInvitationStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();

  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 9,
      offset: 9 * Math.floor(page),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        published: true,
      },
    };
    const sortBy = "createdAt";

    const sortDirection = "desc";
    dispatch(invitationActions.getAll({ ...payload, sortBy, sortDirection }));
  };
  const theme = useTheme();
  const [currentInvitationsList, setCurrentInvitationsList] = useState<
    InvitationDto[]
  >([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);

  const [
    filteredCurrentInvitationsListValues,
    setFilteredCurrentInvitationsListValues,
  ] = useState<InvitationDto[]>([]);

  const [invitationSelected, setInvitationSelected] = useState<string>();

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter(
        (applyedFilter) => applyedFilter.field === newFilter.field
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    /**
     * Se elimina el filtro
     */
    if (filterRemoved) {
      setFilters([
        ...applyedFilters.filter(
          (applyedFilter) => applyedFilter.field !== newFilter.field
        ),
      ]);
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  useEffect(() => {
    buildFilters({ field: "section", value: "no" });
    buildFilters({ field: "entityName", value: "no" });
    return () => {
      dispatch(invitationActions.resetGetAllInvitations());
    };
  }, []);

  useEffect(() => {
    if (!!invitations && !isLoading) {
      const currentInvitations = invitations
        .filter((invitation) => invitation.active)
        .filter(
          (invitation) =>
            invitation.email === user.email &&
            invitation.status === "PENDING" &&
            (invitation.academy || invitation.cohort)
        )
        .map((invitationA) => {
          return {
            ...invitationA,
            section: invitationA.academy ? "academy" : "cohort",
            entityName: invitationA.academy
              ? invitationA.academy.name
              : invitationA.cohort.name,
            entityData: invitationA.academy
              ? invitationA.academy
              : invitationA.cohort,
          };
        });
      setCurrentInvitationsList(currentInvitations);
    }
    setThereAreItems(!!invitations && invitations?.length > 0);
  }, [invitations, isLoading]);

  useEffect(() => {
    setIsLoading(invitationsStates.loading);
  }, [invitationsStates]);

  useEffect(() => {
    const filteredInvitations = currentInvitationsList?.filter(
      (currentTalentValues) => {
        const thereAreFilters = applyedFilters.length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter((applyedFilter) => !applyedFilter.special)
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value
          );
        });

        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    setFilteredCurrentInvitationsListValues(filteredInvitations);
  }, [applyedFilters, currentInvitationsList]);

  return (
    <>
      <div style={{ maxWidth: "600px", margin: "0 auto", width: "100%" }}>
        <FilterByName
          items={filteredCurrentInvitationsListValues.map((academy) => {
            return { value: academy.entityName, label: academy.entityName };
          })}
          setFilter={buildFilters}
        ></FilterByName>
      </div>

      <Center gap="26px">
        <Button
          type="button"
          onClick={() => buildFilters({ field: "section", value: "no" })}
          options={{
            size: "md",
            type: "filled",
            skin: "violet",
          }}
          minwidth="0px"
          style={{
            width: "auto",
            border: "none",
            height: "32px",
            padding: "7px 8px 8px",
            borderRadius: "5px",
            color: !applyedFilters.find((filter) => filter.field === "section")
              ? "#fff"
              : "#2a2747",
            backgroundColor: !applyedFilters.find(
              (filter) => filter.field === "section"
            )
              ? theme.colors.LightBlue
              : theme.colors.LightBlueHover,
          }}
        >
          Todos
        </Button>
        <Button
          type="button"
          onClick={() => buildFilters({ field: "section", value: "cohort" })}
          options={{
            size: "md",
            type: "filled",
            skin: "violet",
          }}
          minwidth="0px"
          style={{
            width: "auto",
            border: "none",
            height: "32px",
            padding: "7px 8px 8px",
            borderRadius: "5px",
            color: applyedFilters.find(
              (filter) =>
                filter.field === "section" && filter.value === "cohort"
            )
              ? "#fff"
              : "#2a2747",
            backgroundColor: applyedFilters.find(
              (filter) =>
                filter.field === "section" && filter.value === "cohort"
            )
              ? theme.colors.LightBlue
              : theme.colors.LightBlueHover,
          }}
        >
          Cohorts
        </Button>
        <Button
          type="button"
          onClick={() => buildFilters({ field: "section", value: "academy" })}
          options={{
            size: "md",
            type: "filled",
            skin: "violet",
          }}
          minwidth="0px"
          style={{
            width: "auto",
            border: "none",
            height: "32px",
            padding: "7px 8px 8px",
            borderRadius: "5px",
            color: applyedFilters.find(
              (filter) =>
                filter.field === "section" && filter.value === "academy"
            )
              ? "#fff"
              : "#2a2747",
            backgroundColor: applyedFilters.find(
              (filter) =>
                filter.field === "section" && filter.value === "academy"
            )
              ? theme.colors.LightBlue
              : theme.colors.LightBlueHover,
          }}
        >
          Academies
        </Button>
      </Center>
      <div style={{ height: "24px" }}></div>
      <InfinityScroll
        action={fetchData}
        page={page}
        setPage={setPage}
        data={filteredCurrentInvitationsListValues}
        active={thereAreItems}
        isLoading={isLoading}
      >
        <FlexContainer direction="column" gap="14px">
          {filteredCurrentInvitationsListValues.map((invitation) => {
            return (
              <ListItem>
                <FlexContainer justify="space-between" align="center">
                  <FlexContainer align="center" gap="10px">
                    <EntityImageContainer>
                      <Img src={invitation.entityData?.picture}></Img>
                    </EntityImageContainer>
                    <EntityTitleSemiBold>
                      {invitation.entityName}
                    </EntityTitleSemiBold>
                  </FlexContainer>
                  <FlexContainer gap="20px">
                    {isMobile ? (
                      <>
                        <Button
                          type="button"
                          onClick={(ev) => {
                            setInvitationSelected(invitation._id);
                            dispatch(
                              invitationActions.update({
                                condition: "accept",
                                _id: invitation._id,
                              })
                            );
                          }}
                          options={{
                            size: "md",
                            type: "filled",
                            skin: "violet",
                          }}
                        >
                          ✓
                        </Button>
                        <Button
                          type="button"
                          onClick={(ev) => {
                            setInvitationSelected(invitation._id);
                            dispatch(
                              invitationActions.update({
                                condition: "reject",
                                _id: invitation._id,
                              })
                            );
                          }}
                          options={{
                            size: "md",
                            type: "filled",
                            skin: "danger",
                          }}
                        >
                          X
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          type="button"
                          onClick={() => {
                            setInvitationSelected(invitation._id);
                            dispatch(
                              invitationActions.update({
                                condition: "accept",
                                _id: invitation._id,
                              })
                            );
                          }}
                          style={{ height: "26px" }}
                          options={{
                            size: "md",
                            type: "filled",
                            skin: "violet",
                          }}
                          loading={
                            updateInvitationStates.loading &&
                            invitationSelected === invitation._id
                          }
                        >
                          Aceptar Invitación
                        </Button>
                        <Button
                          type="button"
                          onClick={() => {
                            setInvitationSelected(invitation._id);
                            dispatch(
                              invitationActions.update({
                                condition: "reject",
                                _id: invitation._id,
                              })
                            );
                          }}
                          style={{ height: "26px" }}
                          options={{
                            size: "md",
                            type: "filled",
                            skin: "danger",
                          }}
                          loading={
                            updateInvitationStates.loading &&
                            invitationSelected === invitation._id
                          }
                        >
                          Rechazar
                        </Button>{" "}
                      </>
                    )}
                  </FlexContainer>
                </FlexContainer>
              </ListItem>
            );
          })}
        </FlexContainer>
      </InfinityScroll>
    </>
  );
};

const states = ({ userStore, cohortStore, invitationStore, academyStore }) => {
  const { data: invitations, states: invitationsStates } = invitationStore.all;
  const { states: updateInvitationStates } = invitationStore.update;
  const { data: academies } = academyStore.all;
  const { data: user } = userStore;
  const { data: cohorts } = cohortStore.allCohorts;

  return {
    invitations,
    user,
    cohorts,
    academies,
    updateInvitationStates,
    invitationsStates,
  };
};

export default connect(states)(Component);
