import { connect } from "react-redux";
import Tabs from "../../../components/Tabs";
import { Tab } from "../../../components/Tabs/styles";
import Banner from "../components/Banner";
import Alumni from "./components/Alumni";
import Certificate from "./components/Certificate";
import Cohort from "./components/Cohort";
import Content from "./components/Content";
import Home from "./components/Home";
import Profile from "./components/Profile";
import Loader from "../../../components/Loader";
import Community from "./components/Community";
import { useEffect } from "react";
import { setCookie } from "../../../helpers/cookies-handler";
import { AcademyDto } from "../../../types/academy.dto";
import EmptyState from "../components/EmptyState";

interface ComponentProps {
  selectedAcademy: AcademyDto;
}

const Component = ({ selectedAcademy }: ComponentProps) => {
  useEffect(() => {
    if (!!selectedAcademy) {
      setCookie(
        "DEVPLACE_VISITED_ACADEMY",
        JSON.stringify({ _id: selectedAcademy._id, name: selectedAcademy.name })
      );
    }
  }, [selectedAcademy]);

  if (!selectedAcademy) {
    return <EmptyState></EmptyState>;
  }

  return (
    <>
      <Banner />
      <Tabs
        style={{
          backgroundColor: "#fff",
          width: "100%",
        }}
      >
        <Tab id="home" label="Home">
          <Home />
        </Tab>

        <Tab id="cohorts" label="Cohorts">
          <Cohort />
        </Tab>

        <Tab id="contents" label="Contenidos">
          <Content />
        </Tab>

        <Tab id="profile" label="Perfil">
          <Profile />
        </Tab>

        <Tab id="alumni" label="Alumni">
          <Alumni />
        </Tab>

        <Tab id="community" label="Comunidad">
          <Community />
        </Tab>

        {/*  <Tab id="certificates" label="Certificados">
          <Certificate />
        </Tab> */}
      </Tabs>
    </>
  );
};

const states = ({ academyStore }) => {
  const { data: selectedAcademy } = academyStore.selected;
  return {
    selectedAcademy,
  };
};

export default connect(states)(Component);
