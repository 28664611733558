import styled, { keyframes } from "styled-components";

export const CardsGrid = styled("div")<{
  itemsPerRow: number;
  size?: {
    width: number;
    height: number;
  };
  animated: boolean;
  spaceBetweenItems: number;
}>`
  display: grid;
  gap: ${({ spaceBetweenItems }) =>
    spaceBetweenItems ? spaceBetweenItems : 16}px;
  grid-auto-rows: ${({ size }) => (size?.height ? size?.height : 174)}px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      ${({ size, itemsPerRow }) =>
        size?.width
          ? `${size?.width}px`
          : `calc(100vw / ${itemsPerRow ? itemsPerRow * 2 : 6})`},
      1fr
    )
  );
`;

const shimmer = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const EmptyStateCardBackground = styled("div")`
  width: 100%;
  height: 100%;
  position: absolute;

  background: linear-gradient(-45deg, #aeaeae, #dddddd, #aeaeae, #dddddd);
  background-size: 400% 400%;
  animation: ${shimmer} 2s ease infinite;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyStateCard = styled("div")<{
  itemsPerRow: number;
  size?: {
    width: number;
    height: number;
  };
  animated: boolean;
}>`
  text-decoration: none;
  color: inherit;
  display: block;
  background-color: #e0e4ea;
  overflow: hidden;
  border-radius: 16px;
  min-height: ${({ size }) => (size?.height ? size?.height : 174)}px;
  position: relative;
  margin-bottom: 16px;
`;
