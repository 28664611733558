import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { eventFrecuency } from "../../../../constants/events-frecuency";
import WeekDays from "../components/WeekDays";
import { Center } from "../../../../components/StyledComponents";
import { InLine } from "../../../../components/Form/Field";
import Input from "../../../../components/Form/Input";
import TextArea from "../../../../components/Form/TextArea";
import Submit from "../../../../components/Form/Submit";
import ReactSelect from "../../../../components/Form/ReactSelect";
import { initialValues, schema } from "../../../../constants/form/event/new";
import { AbilityDto } from "../../../../types/ability.dto";
import { eventsTypes } from "../../../../constants/events-types";
import { getAllAbilities } from "../../../../store/actions/ability";
import Button from "../../../../components/Button";
import { hideModal } from "../../../../store/actions/modal";
import { newEvent, resetNewEvent } from "../../../../store/actions/event";
import { minInputDate } from "../../../../helpers/data-handler";

interface ComponentProps {
  abilities: AbilityDto[];
  newEventStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ abilities, newEventStates }: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [frecuency, setFrecuency] = useState<string>();
  const [type, setType] = useState<string>();
  const [currentTypeAbilities, setCurrentTypeAbilities] =
    useState<AbilityDto[]>();

  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    if (frecuency === "unique") {
      values.endDate = null;
      values.eventDays = [];
    }
    dispatch(newEvent(values));
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  useEffect(() => {
    if (!!abilities) {
      setCurrentTypeAbilities(
        abilities.filter((ability) => ability.type === type)
      );
    }
  }, [type, abilities]);

  useEffect(() => {
    !abilities && dispatch(getAllAbilities());
  }, [abilities, dispatch]);

  useEffect(() => {
    if (newEventStates?.success) {
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetNewEvent());
      }, 2000);
    }
  }, [newEventStates]);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validateOnChange={formSubmmited}
      validateOnBlur={false}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <form
            className="theme-form"
            onSubmit={(event) => {
              setFormSubmmited(true);
              handleSubmit(event);
            }}
            id="new-event-form"
          >
            <Input
              name="name"
              error={errors["name"]}
              touched={touched["name"]}
              value={values["name"]}
              type="text"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Nombre del co-hort",
                marginBottom: 20,
              }}
            />

            <ReactSelect
              name="type"
              error={errors["type"]}
              touched={touched["type"]}
              items={eventsTypes}
              placeholder="Seleccione un tipo"
              onChange={handleChange}
              onBlur={handleBlur}
              onOptionSelected={(option) => {
                setType(option.value);
              }}
              options={{
                label: "Tipo",
                marginBottom: 20,
              }}
            />

            <ReactSelect
              name="abilityId"
              error={errors["abilityId"]}
              touched={touched["abilityId"]}
              {...(!!currentTypeAbilities && {
                items: currentTypeAbilities?.map((ability) => ({
                  label: ability.name,
                  value: ability._id,
                })),
              })}
              placeholder="Selecciona una habilidad"
              disabled={!abilities}
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Habilidad",
                marginBottom: 20,
              }}
            />

            <TextArea
              name="description"
              error={errors["description"]}
              touched={touched["description"]}
              value={values["description"]}
              placeholder="Ingresa una descripción de evento"
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Eventos",
                marginBottom: 50,
              }}
            />

            <InLine>
              <Input
                name="startDate"
                error={errors["startDate"]}
                touched={touched["startDate"]}
                value={values["startDate"]}
                type="date"
                min={minInputDate()}
                placeholder="Fecha inicio"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  label: "Fecha inicio",
                  marginBottom: 20,
                }}
              />

              <Input
                name="startHour"
                error={errors["startHour"]}
                touched={touched["startHour"]}
                value={values["startHour"]}
                type="time"
                placeholder="Hora inicio"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  label: "Hora inicio",
                  marginBottom: 20,
                }}
              />

              <Input
                name="endHour"
                error={errors["endHour"]}
                touched={touched["endHour"]}
                value={values["endHour"]}
                type="time"
                placeholder="Hora fin"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  label: "Hora fin",
                  marginBottom: 20,
                }}
              />
            </InLine>

            <ReactSelect
              name="frecuency"
              error={errors["frecuency"]}
              touched={touched["frecuency"]}
              items={eventFrecuency}
              placeholder=""
              disabled={!abilities}
              onChange={handleChange}
              onBlur={handleBlur}
              onOptionSelected={(data) => {
                setFrecuency(data.value);
              }}
              options={{
                label: "Frecuencia",
                marginBottom: 20,
              }}
            />
            {frecuency === "personalized" && (
              <>
                <WeekDays
                  error={errors["eventDays"]}
                  touched={touched["eventDays"]}
                  value={values["eventDays"]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <Input
                  name="endDate"
                  error={errors["endDate"]}
                  touched={touched["endDate"]}
                  value={values["endDate"]}
                  type="date"
                  min={minInputDate()}
                  placeholder="Fecha fin"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    label: "Fecha fin",
                    marginBottom: 50,
                  }}
                />
              </>
            )}
            <Center>
              <Submit
                isSubmmiting={newEventStates.loading}
                form="new-event-form"
                color="Primary"
                options={{
                  type: "filled",
                  skin: "primary",
                  size: "lg",
                  marginBottom: "0px",
                }}
              >
                Confirmar
              </Submit>
              <Button
                onClick={closeModal}
                type="button"
                options={{
                  type: "outline",
                  skin: "danger",
                  size: "lg",
                  marginBottom: "0px",
                }}
                style={{ marginLeft: "10px" }}
              >
                Cancelar
              </Button>
            </Center>
          </form>
        );
      }}
    </Formik>
  );
};

const states = ({ abilityStore, eventStore }) => {
  const { data: abilities } = abilityStore.allAbilities;
  const { states: newEventStates } = eventStore.newEvent;
  return {
    abilities,
    newEventStates,
  };
};

export default connect(states)(Component);
