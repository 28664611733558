import styled from "styled-components";

export const Paragraph = styled("p")`
  margin: 10px 0;
  font-size: 14px;
`;

export const Title = styled("h2")`
  font-weight: bold;
  font-size: 18px;
  margin: 15px 0;
`;
