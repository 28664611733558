export const USER_TIME = "USER_TIME";
export const ADD_CLICK = "ADD_CLICK";
export const ADD_VIEW = "ADD_VIEW";

export interface UserTime {
  type: typeof USER_TIME;
  payload: any;
}

export interface AddClick {
  type: typeof ADD_CLICK;
  payload: any;
}
export interface AddView {
  type: typeof ADD_VIEW;
  payload: any;
}
