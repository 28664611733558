import {
  initialState,
  Actions,
  State,
  GET_ALL_INVITATION,
  GET_ALL_INVITATION_SUCCESS,
  GET_ALL_INVITATION_ERROR,
  NEW_INVITATION,
  NEW_INVITATION_ERROR,
  NEW_INVITATION_SUCCESS,
  DELETE_INVITATION,
  DELETE_INVITATION_SUCCESS,
  DELETE_INVITATION_ERROR,
  RESET_DELETE_INVITATION,
  RESET_NEW_INVITATION,
  UPDATE_INVITATION,
  UPDATE_INVITATION_ERROR,
  UPDATE_INVITATION_SUCCESS,
  RESET_UPDATE_INVITATION,
  GET_INVITATION_ERROR,
  GET_INVITATION_SUCCESS,
  GET_INVITATION,
  RESET_GET_INVITATION,
  RESET_GET_ONE_INVITATION_DATA,
  RESET_GET_ALL_INVITATION_DATA,
  GET_INVITATIONS_USER_BY_ENTITY,
} from "../types/invitation";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_INVITATION:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_INVITATIONS_USER_BY_ENTITY:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_INVITATION_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_INVITATION_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_INVITATION:
      return {
        ...state,
        invitation: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_INVITATION_SUCCESS:
      return {
        ...state,
        invitation: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_INVITATION_ERROR:
      return {
        ...state,
        invitation: {
          ...state.invitation,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_INVITATION:
      return {
        ...state,
        invitation: {
          ...state.invitation,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };

    /*  case RESET_GET_ONE_INVITATION_DATA:
      return {
        ...state,
        selected: initialState.selected,
      }; */
    case RESET_GET_ALL_INVITATION_DATA:
      return {
        ...state,
        all: initialState.all,
      };
    case NEW_INVITATION:
      return {
        ...state,
        create: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_INVITATION_SUCCESS:
      return {
        ...state,
        create: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_INVITATION_ERROR:
      return {
        ...state,
        create: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_INVITATION:
      return {
        ...state,
        create: initialState.create,
      };

    case UPDATE_INVITATION:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_INVITATION_ERROR:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case UPDATE_INVITATION_SUCCESS:
      return {
        ...state,
        update: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case RESET_UPDATE_INVITATION:
      return {
        ...state,
        update: initialState.update,
      };
    case DELETE_INVITATION:
      return {
        ...state,
        delete: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_INVITATION_SUCCESS:
      return {
        ...state,
        delete: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_INVITATION_ERROR:
      return {
        ...state,
        delete: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_INVITATION:
      return {
        ...state,
        delete: initialState.delete,
      };
    default:
      return state;
  }
};

export default reducers;
