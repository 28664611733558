import styled from "styled-components";

export const AddCommentInputWrapper = styled("div")`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 5px;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.colors.White};
`;
