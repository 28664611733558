import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import Button from "../../../../../components/Button";
import Grid from "../../../../../components/Grid";
import Loader from "../../../../../components/Loader";
import {
  Center,
  FlexContainer,
  PageTitle,
  Separator,
} from "../../../../../components/StyledComponents";
import { DateEStoDateEN } from "../../../../../helpers/format-date";

import AnalyticCard from "../../components/AnalyticCard";
import { PDFExport } from "@progress/kendo-react-pdf";
import { StateDto } from "../../../../../types/states.dto";

import { AcademyUserTimesDto } from "../../../../../types/analytics.dto";
import { Formik } from "formik";
import ReactSelect from "../../../../../components/Form/ReactSelect";
import NotData from "../../components/NotData";
import LineGraphHorizontal from "../../../../../components/LineGraphHorizontal";
import { AcademyDto } from "../../../../../types/academy.dto";

import academyActions from "../../../../../store/actions/academies";

import { UserDto } from "../../../../../types/user.dto";
import { getAcademyUserTimeStats } from "../../../../../store/actions/analytics";
import {
  formatNumber,
  overThousandFixed,
} from "../../../../../helpers/number-fixed";
const Component = ({
  analyticsAcademyUserTime,
  analyticsAcademyUserTimeStates,
  academies,
  user,
}: {
  analyticsAcademyUserTime: AcademyUserTimesDto;
  analyticsAcademyUserTimeStates: StateDto;
  academies: AcademyDto[];
  user: UserDto;
}) => {
  const printRef: any = React.useRef();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handleExportWithComponent = () => {
    printRef.current.save();
  };

  useEffect(() => {
    if (!academies && fetchOn)
      dispatch(
        academyActions.getAll({
          filterBy: {
            users: {
              $elemMatch: {
                user: user._id,
                roleEnd: { $exists: false },
              },
            },
            active: true,
          },
        })
      );
  }, [academies, fetchOn]);

  useEffect(() => {
    if (!academies) {
      setFetchOn(true);
    }
  }, [academies]);

  useEffect(() => {
    return () => {
      dispatch(academyActions.resetGetAllAcademies());
    };
  }, []);
  return (
    <Grid.Container>
      <div style={{ minHeight: "100vh" }}>
        <PageTitle>Dashboard</PageTitle>
        <Formik
          initialValues={{}}
          onSubmit={(values, actions) => {}}
          validateOnBlur={false}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {}}
                id="edit-academy-visibility-form"
              >
                <Grid.Row>
                  <Grid.Col md={3} sm={12}>
                    <ReactSelect
                      name="academy"
                      {...(academies && {
                        items: academies.map((academy) => ({
                          label: academy.name,
                          value: academy._id,
                        })),
                      })}
                      placeholder="Seleccione un Academy"
                      onChange={handleChange}
                      disabled={!academies}
                      onOptionSelected={(newValue) => {
                        if (newValue) {
                          dispatch(
                            getAcademyUserTimeStats({
                              academyId: newValue.value,
                              topContents: 10,
                              userId: user._id,
                            })
                          );
                        }
                      }}
                      notLoading={true}
                      options={{
                        label: "Academia",
                        marginBottom: 10,
                        skin: "base",
                      }}
                    />
                  </Grid.Col>
                </Grid.Row>

                {analyticsAcademyUserTimeStates.loading ? (
                  <Loader color="Primary"></Loader>
                ) : !analyticsAcademyUserTime ? (
                  <>
                    <Separator size={30}></Separator>
                    <Center>
                      <h2>Esperando seleccion</h2>
                    </Center>
                  </>
                ) : (
                  <>
                    <Grid.Col offset={{ sm: 10 }} sm={12}>
                      <Button
                        onClick={() => {
                          handleExportWithComponent();
                        }}
                        type="button"
                        options={{
                          type: "filled",
                          skin: "primary",
                          size: "md",
                          marginBottom: "10px",
                        }}
                        style={{ marginLeft: "10px" }}
                      >
                        Descargar
                      </Button>
                    </Grid.Col>

                    <PDFExport
                      fileName={`Constana General ${new Date().valueOf()}`}
                      ref={printRef}
                    >
                      <FlexContainer direction="column" gap="6px">
                        <AnalyticCard
                          title={"Horas totales de consumo."}
                          graphTitle={
                            "Horas totales de consumo de todos los contenidos del cohort- Crecimiento Histórico"
                          }
                          data={{
                            stats: [
                              {
                                label: "Últimos 30 días",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.lastMonth
                                ),
                              },
                              {
                                label: "Últimos 7 días",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.lastWeek
                                ),
                              },
                              {
                                label: "Últimas 24 horas",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.lastDay
                                ),
                              },
                            ],
                            graphData: Object.entries(
                              analyticsAcademyUserTime.growth
                            )
                              .sort(
                                (a, b) =>
                                  DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                              )
                              .map((growthData) => {
                                return {
                                  name: growthData[0],
                                  value: formatNumber(growthData[1]),
                                };
                              }),
                          }}
                        ></AnalyticCard>
                        <AnalyticCard
                          height="250px"
                          title="Top 10 contenidos mas consumidos de la academia"
                        >
                          <NotData data={analyticsAcademyUserTime.topContents}>
                            <LineGraphHorizontal
                              data={analyticsAcademyUserTime.topContents.total.map(
                                (contact) => {
                                  return {
                                    label: contact.name,
                                    value: overThousandFixed(contact.total),
                                  };
                                }
                              )}
                              title={""}
                            ></LineGraphHorizontal>
                          </NotData>
                        </AnalyticCard>
                        <AnalyticCard
                          title={
                            "Horas totales de consumo de contenido de videos."
                          }
                          graphTitle={
                            "Horas totales de consumo de videos de la academia- Crecimiento Histórico"
                          }
                          data={{
                            stats: [
                              {
                                label: "Últimos 30 días",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.videos.lastMonth
                                ),
                              },
                              {
                                label: "Últimos 7 días",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.videos.lastWeek
                                ),
                              },
                              {
                                label: "Últimas 24 horas",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.videos.lastDay
                                ),
                              },
                            ],
                            graphData: Object.entries(
                              analyticsAcademyUserTime.videos.growth
                            )
                              .sort(
                                (a, b) =>
                                  DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                              )
                              .map((growthData) => {
                                return {
                                  name: growthData[0],
                                  value: formatNumber(growthData[1]),
                                };
                              }),
                          }}
                        ></AnalyticCard>
                        <AnalyticCard
                          title={
                            "Horas totales de consumo de contenido de Slideshows."
                          }
                          graphTitle={
                            "Horas totales de consumo de contenido de Slideshows.- Crecimiento Histórico"
                          }
                          data={{
                            stats: [
                              {
                                label: "Últimos 30 días",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.slideshows.lastMonth
                                ),
                              },
                              {
                                label: "Últimos 7 días",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.slideshows.lastWeek
                                ),
                              },
                              {
                                label: "Últimas 24 horas",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.slideshows.lastDay
                                ),
                              },
                            ],
                            graphData: Object.entries(
                              analyticsAcademyUserTime.slideshows.growth
                            )
                              .sort(
                                (a, b) =>
                                  DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                              )
                              .map((growthData) => {
                                return {
                                  name: growthData[0],
                                  value: formatNumber(growthData[1]),
                                };
                              }),
                          }}
                        ></AnalyticCard>
                        <AnalyticCard
                          title={
                            "Horas totales de consumo de contenido de bytes."
                          }
                          graphTitle={
                            "Horas totales de consumo de contenido de bytes.- Crecimiento Histórico"
                          }
                          data={{
                            stats: [
                              {
                                label: "Últimos 30 días",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.bytes.lastMonth
                                ),
                              },
                              {
                                label: "Últimos 7 días",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.bytes.lastWeek
                                ),
                              },
                              {
                                label: "Últimas 24 horas",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.bytes.lastDay
                                ),
                              },
                            ],
                            graphData: Object.entries(
                              analyticsAcademyUserTime.bytes.growth
                            )
                              .sort(
                                (a, b) =>
                                  DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                              )
                              .map((growthData) => {
                                return {
                                  name: growthData[0],
                                  value: formatNumber(growthData[1]),
                                };
                              }),
                          }}
                        ></AnalyticCard>
                        <AnalyticCard
                          title={
                            "Horas totales de consumo de contenido de imágenes."
                          }
                          graphTitle={
                            "Horas totales de consumo de contenido de imágenes.- Crecimiento Histórico"
                          }
                          data={{
                            stats: [
                              {
                                label: "Últimos 30 días",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.images.lastMonth
                                ),
                              },
                              {
                                label: "Últimos 7 días",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.images.lastWeek
                                ),
                              },
                              {
                                label: "Últimas 24 horas",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.images.lastDay
                                ),
                              },
                            ],
                            graphData: Object.entries(
                              analyticsAcademyUserTime.images.growth
                            )
                              .sort(
                                (a, b) =>
                                  DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                              )
                              .map((growthData) => {
                                return {
                                  name: growthData[0],
                                  value: formatNumber(growthData[1]),
                                };
                              }),
                          }}
                        ></AnalyticCard>
                        <AnalyticCard
                          title={
                            "Horas totales de consumo de contenido de cdocs."
                          }
                          graphTitle={
                            "Horas totales de consumo de contenido de cdocs.- Crecimiento Histórico"
                          }
                          data={{
                            stats: [
                              {
                                label: "Últimos 30 días",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.cdocs.lastMonth
                                ),
                              },
                              {
                                label: "Últimos 7 días",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.cdocs.lastWeek
                                ),
                              },
                              {
                                label: "Últimas 24 horas",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.cdocs.lastDay
                                ),
                              },
                            ],
                            graphData: Object.entries(
                              analyticsAcademyUserTime.cdocs.growth
                            )
                              .sort(
                                (a, b) =>
                                  DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                              )
                              .map((growthData) => {
                                return {
                                  name: growthData[0],
                                  value: formatNumber(growthData[1]),
                                };
                              }),
                          }}
                        ></AnalyticCard>
                        <AnalyticCard
                          title={
                            "Horas totales de consumo de contenido de listens."
                          }
                          graphTitle={
                            "Horas totales de consumo de contenido de listens.- Crecimiento Histórico"
                          }
                          data={{
                            stats: [
                              {
                                label: "Últimos 30 días",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.listens.lastMonth
                                ),
                              },
                              {
                                label: "Últimos 7 días",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.listens.lastWeek
                                ),
                              },
                              {
                                label: "Últimas 24 horas",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.listens.lastDay
                                ),
                              },
                            ],
                            graphData: Object.entries(
                              analyticsAcademyUserTime.listens.growth
                            )
                              .sort(
                                (a, b) =>
                                  DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                              )
                              .map((growthData) => {
                                return {
                                  name: growthData[0],
                                  value: formatNumber(growthData[1]),
                                };
                              }),
                          }}
                        ></AnalyticCard>
                        <AnalyticCard
                          title={
                            "Horas totales de consumo de contenido de trayectos."
                          }
                          graphTitle={
                            "Horas totales de consumo de contenido de trayectos.- Crecimiento Histórico"
                          }
                          data={{
                            stats: [
                              {
                                label: "Últimos 30 días",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.roadmaps.lastMonth
                                ),
                              },
                              {
                                label: "Últimos 7 días",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.roadmaps.lastWeek
                                ),
                              },
                              {
                                label: "Últimas 24 horas",
                                value: overThousandFixed(
                                  analyticsAcademyUserTime.roadmaps.lastDay
                                ),
                              },
                            ],
                            graphData: Object.entries(
                              analyticsAcademyUserTime.roadmaps.growth
                            )
                              .sort(
                                (a, b) =>
                                  DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                              )
                              .map((growthData) => {
                                return {
                                  name: growthData[0],
                                  value: formatNumber(growthData[1]),
                                };
                              }),
                          }}
                        ></AnalyticCard>
                      </FlexContainer>
                    </PDFExport>
                  </>
                )}
              </form>
            );
          }}
        </Formik>
      </div>
    </Grid.Container>
  );
};

const states = ({ analyticStore, academyStore, userStore }) => {
  const {
    data: analyticsAcademyUserTime,
    states: analyticsAcademyUserTimeStates,
  } = analyticStore.academyUserTimes;
  const { data: user } = userStore;

  const { data: academies } = academyStore.all;

  return {
    analyticsAcademyUserTime,
    analyticsAcademyUserTimeStates,
    user,
    academies,
  };
};

export default connect(states)(Component);
