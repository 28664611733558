import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import Button from "../../../../../components/Button";
import Grid from "../../../../../components/Grid";
import Loader from "../../../../../components/Loader";
import {
  FlexContainer,
  PageTitle,
} from "../../../../../components/StyledComponents";
import { DateEStoDateEN } from "../../../../../helpers/format-date";

import AnalyticCard from "../../components/AnalyticCard";
import { PDFExport } from "@progress/kendo-react-pdf";
import { StateDto } from "../../../../../types/states.dto";

import { UserTimesDto } from "../../../../../types/analytics.dto";
import { Formik } from "formik";
import ReactSelect from "../../../../../components/Form/ReactSelect";
import NotData from "../../components/NotData";
import LineGraphHorizontal from "../../../../../components/LineGraphHorizontal";
import { AcademyDto } from "../../../../../types/academy.dto";

import academyActions from "../../../../../store/actions/academies";

import { UserDto } from "../../../../../types/user.dto";
import {
  getAcademyUserTimeStats,
  getUserTimeStats,
} from "../../../../../store/actions/analytics";
import {
  formatNumber,
  overThousandFixed,
} from "../../../../../helpers/number-fixed";
const Component = ({
  analyticsUserTime,
  analyticsUserTimeStates,
  user,
}: {
  analyticsUserTime: UserTimesDto;
  analyticsUserTimeStates: StateDto;
  user: UserDto;
}) => {
  const printRef: any = React.useRef();
  const dispatch = useDispatch();
  const handleExportWithComponent = () => {
    printRef.current.save();
  };

  useEffect(() => {
    dispatch(getUserTimeStats({ userId: user._id }));
  }, []);

  return (
    <Grid.Container>
      <div style={{ minHeight: "100vh" }}>
        <PageTitle>Dashboard</PageTitle>
        {analyticsUserTimeStates.loading || !analyticsUserTime ? (
          <Loader color="Primary"></Loader>
        ) : (
          <>
            <Grid.Col offset={{ sm: 10 }} sm={12}>
              <Button
                onClick={() => {
                  handleExportWithComponent();
                }}
                type="button"
                options={{
                  type: "filled",
                  skin: "primary",
                  size: "md",
                  marginBottom: "10px",
                }}
                style={{ marginLeft: "10px" }}
              >
                Descargar
              </Button>
            </Grid.Col>

            <PDFExport
              fileName={`Constana General ${new Date().valueOf()}`}
              ref={printRef}
            >
              <FlexContainer direction="column" gap="6px">
                <AnalyticCard
                  title={"Horas totales de consumo."}
                  graphTitle={
                    "Horas totales de consumo de todos los contenidos del cohort- Crecimiento Histórico"
                  }
                  data={{
                    stats: [
                      {
                        label: "Últimos 30 días",
                        value: overThousandFixed(analyticsUserTime.lastMonth),
                      },
                      {
                        label: "Últimos 7 días",
                        value: overThousandFixed(analyticsUserTime.lastWeek),
                      },
                      {
                        label: "Últimas 24 horas",
                        value: overThousandFixed(analyticsUserTime.lastDay),
                      },
                    ],
                    graphData: Object.entries(analyticsUserTime.growth)
                      .sort(
                        (a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                      )
                      .map((growthData) => {
                        return {
                          name: growthData[0],
                          value: formatNumber(growthData[1]),
                        };
                      }),
                  }}
                ></AnalyticCard>
                <AnalyticCard
                  title={"Horas totales de consumo de contenido de videos."}
                  graphTitle={
                    "Horas totales de consumo de videos de la academia- Crecimiento Histórico"
                  }
                  data={{
                    stats: [
                      {
                        label: "Últimos 30 días",
                        value: overThousandFixed(
                          analyticsUserTime.videos.lastMonth
                        ),
                      },
                      {
                        label: "Últimos 7 días",
                        value: overThousandFixed(
                          analyticsUserTime.videos.lastWeek
                        ),
                      },
                      {
                        label: "Últimas 24 horas",
                        value: overThousandFixed(
                          analyticsUserTime.videos.lastDay
                        ),
                      },
                    ],
                    graphData: Object.entries(analyticsUserTime.videos.growth)
                      .sort(
                        (a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                      )
                      .map((growthData) => {
                        return {
                          name: growthData[0],
                          value: formatNumber(growthData[1]),
                        };
                      }),
                  }}
                ></AnalyticCard>
                <AnalyticCard
                  title={"Horas totales de consumo de contenido de Slideshows."}
                  graphTitle={
                    "Horas totales de consumo de contenido de Slideshows.- Crecimiento Histórico"
                  }
                  data={{
                    stats: [
                      {
                        label: "Últimos 30 días",
                        value: overThousandFixed(
                          analyticsUserTime.slideshows.lastMonth
                        ),
                      },
                      {
                        label: "Últimos 7 días",
                        value: overThousandFixed(
                          analyticsUserTime.slideshows.lastWeek
                        ),
                      },
                      {
                        label: "Últimas 24 horas",
                        value: overThousandFixed(
                          analyticsUserTime.slideshows.lastDay
                        ),
                      },
                    ],
                    graphData: Object.entries(
                      analyticsUserTime.slideshows.growth
                    )
                      .sort(
                        (a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                      )
                      .map((growthData) => {
                        return {
                          name: growthData[0],
                          value: formatNumber(growthData[1]),
                        };
                      }),
                  }}
                ></AnalyticCard>
                <AnalyticCard
                  title={"Horas totales de consumo de contenido de bytes."}
                  graphTitle={
                    "Horas totales de consumo de contenido de bytes.- Crecimiento Histórico"
                  }
                  data={{
                    stats: [
                      {
                        label: "Últimos 30 días",
                        value: overThousandFixed(
                          analyticsUserTime.bytes.lastMonth
                        ),
                      },
                      {
                        label: "Últimos 7 días",
                        value: overThousandFixed(
                          analyticsUserTime.bytes.lastWeek
                        ),
                      },
                      {
                        label: "Últimas 24 horas",
                        value: overThousandFixed(
                          analyticsUserTime.bytes.lastDay
                        ),
                      },
                    ],
                    graphData: Object.entries(analyticsUserTime.bytes.growth)
                      .sort(
                        (a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                      )
                      .map((growthData) => {
                        return {
                          name: growthData[0],
                          value: formatNumber(growthData[1]),
                        };
                      }),
                  }}
                ></AnalyticCard>
                <AnalyticCard
                  title={"Horas totales de consumo de contenido de imágenes."}
                  graphTitle={
                    "Horas totales de consumo de contenido de imágenes.- Crecimiento Histórico"
                  }
                  data={{
                    stats: [
                      {
                        label: "Últimos 30 días",
                        value: overThousandFixed(
                          analyticsUserTime.images.lastMonth
                        ),
                      },
                      {
                        label: "Últimos 7 días",
                        value: overThousandFixed(
                          analyticsUserTime.images.lastWeek
                        ),
                      },
                      {
                        label: "Últimas 24 horas",
                        value: overThousandFixed(
                          analyticsUserTime.images.lastDay
                        ),
                      },
                    ],
                    graphData: Object.entries(analyticsUserTime.images.growth)
                      .sort(
                        (a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                      )
                      .map((growthData) => {
                        return {
                          name: growthData[0],
                          value: formatNumber(growthData[1]),
                        };
                      }),
                  }}
                ></AnalyticCard>
                <AnalyticCard
                  title={"Horas totales de consumo de contenido de cdocs."}
                  graphTitle={
                    "Horas totales de consumo de contenido de cdocs.- Crecimiento Histórico"
                  }
                  data={{
                    stats: [
                      {
                        label: "Últimos 30 días",
                        value: overThousandFixed(
                          analyticsUserTime.cdocs.lastMonth
                        ),
                      },
                      {
                        label: "Últimos 7 días",
                        value: overThousandFixed(
                          analyticsUserTime.cdocs.lastWeek
                        ),
                      },
                      {
                        label: "Últimas 24 horas",
                        value: overThousandFixed(
                          analyticsUserTime.cdocs.lastDay
                        ),
                      },
                    ],
                    graphData: Object.entries(analyticsUserTime.cdocs.growth)
                      .sort(
                        (a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                      )
                      .map((growthData) => {
                        return {
                          name: growthData[0],
                          value: formatNumber(growthData[1]),
                        };
                      }),
                  }}
                ></AnalyticCard>
                <AnalyticCard
                  title={"Horas totales de consumo de contenido de listens."}
                  graphTitle={
                    "Horas totales de consumo de contenido de listens.- Crecimiento Histórico"
                  }
                  data={{
                    stats: [
                      {
                        label: "Últimos 30 días",
                        value: overThousandFixed(
                          analyticsUserTime.listens.lastMonth
                        ),
                      },
                      {
                        label: "Últimos 7 días",
                        value: overThousandFixed(
                          analyticsUserTime.listens.lastWeek
                        ),
                      },
                      {
                        label: "Últimas 24 horas",
                        value: overThousandFixed(
                          analyticsUserTime.listens.lastDay
                        ),
                      },
                    ],
                    graphData: Object.entries(analyticsUserTime.listens.growth)
                      .sort(
                        (a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                      )
                      .map((growthData) => {
                        return {
                          name: growthData[0],
                          value: formatNumber(growthData[1]),
                        };
                      }),
                  }}
                ></AnalyticCard>
                <AnalyticCard
                  title={"Horas totales de consumo de contenido de trayectos."}
                  graphTitle={
                    "Horas totales de consumo de contenido de trayectos.- Crecimiento Histórico"
                  }
                  data={{
                    stats: [
                      {
                        label: "Últimos 30 días",
                        value: overThousandFixed(
                          analyticsUserTime.roadmaps.lastMonth
                        ),
                      },
                      {
                        label: "Últimos 7 días",
                        value: overThousandFixed(
                          analyticsUserTime.roadmaps.lastWeek
                        ),
                      },
                      {
                        label: "Últimas 24 horas",
                        value: overThousandFixed(
                          analyticsUserTime.roadmaps.lastDay
                        ),
                      },
                    ],
                    graphData: Object.entries(analyticsUserTime.roadmaps.growth)
                      .sort(
                        (a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0])
                      )
                      .map((growthData) => {
                        return {
                          name: growthData[0],
                          value: formatNumber(growthData[1]),
                        };
                      }),
                  }}
                ></AnalyticCard>
              </FlexContainer>
            </PDFExport>
          </>
        )}
      </div>
    </Grid.Container>
  );
};

const states = ({ analyticStore, userStore }) => {
  const { data: analyticsUserTime, states: analyticsUserTimeStates } =
    analyticStore.userTimes;
  const { data: user } = userStore;

  return {
    analyticsUserTime,
    analyticsUserTimeStates,
    user,
  };
};

export default connect(states)(Component);
