import * as Yup from "yup";

export const schema = Yup.object().shape({
  cardInfo: Yup.object().required(),
  purchaseMethod: Yup.object().required(),
});

interface FormValues {
  cardInfo: any;
  purchaseMethod: any;
}

export const initialValues: FormValues = {
  cardInfo: null,
  purchaseMethod: null,
};
