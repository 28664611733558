import { PaginationDto } from "../../types/pagination.dto";
import { TagDto } from "../../types/tag.dto";

export const GET_ALL_TAGS = "GET_ALL_TAGS";
export const GET_ALL_TAGS_ERROR = "GET_ALL_TAGS_ERROR";
export const GET_ALL_TAGS_SUCCESS = "GET_ALL_TAGS_SUCCESS";
export const RESET_GET_ALL_TAGS = "RESET_GET_ALL_TAGS";

export interface GetAllTags {
  type: typeof GET_ALL_TAGS;
  payload: PaginationDto;
}

export interface GetAllTagsSuccess {
  type: typeof GET_ALL_TAGS_SUCCESS;
  payload: TagDto[];
}

export interface GetAllTagsError {
  type: typeof GET_ALL_TAGS_ERROR;
  payload: boolean | string;
}

export interface ResetAllTags {
  type: typeof RESET_GET_ALL_TAGS;
  payload: null;
}

export interface State {
  all: {
    data: TagDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllTags
  | GetAllTagsSuccess
  | GetAllTagsError
  | ResetAllTags;
