import {
  initialState,
  Actions,
  State,
  GET_ALL_NOTIFICATION,
  GET_ALL_NOTIFICATION_SUCCESS,
  GET_ALL_NOTIFICATION_ERROR,
  NEW_NOTIFICATION,
  NEW_NOTIFICATION_ERROR,
  NEW_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_ERROR,
  RESET_DELETE_NOTIFICATION,
  RESET_NEW_NOTIFICATION,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_ERROR,
  UPDATE_NOTIFICATION_SUCCESS,
  RESET_UPDATE_NOTIFICATION,
  UPDATE_MANY_NOTIFICATIONS,
  UPDATE_MANY_NOTIFICATIONS_SUCCESS,
  UPDATE_MANY_NOTIFICATIONS_ERROR,
  RESET_UPDATE_MANY_NOTIFICATIONS,
  SEE_ALL_NOTIFICATIONS,
} from "../types/notification";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_NOTIFICATION:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_NOTIFICATION_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case SEE_ALL_NOTIFICATIONS:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_NOTIFICATIONS:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        updateMany: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_MANY_NOTIFICATIONS_ERROR:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_MANY_NOTIFICATIONS:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };

    case UPDATE_NOTIFICATION:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_NOTIFICATION_ERROR:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case RESET_UPDATE_NOTIFICATION:
      return {
        ...state,
        update: {
          states: initialState.update.states,
        },
      };
    case NEW_NOTIFICATION:
      return {
        ...state,
        create: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_NOTIFICATION_SUCCESS:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_NOTIFICATION_ERROR:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_NOTIFICATION:
      return {
        ...state,
        create: initialState.create,
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_NOTIFICATION_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_NOTIFICATION:
      return {
        ...state,
        delete: initialState.delete,
      };
    default:
      return state;
  }
};

export default reducers;
