import { ADD_CLICK, ADD_VIEW, USER_TIME } from "../types/userTimes";

export const userTime = (payload) => {
  return { type: USER_TIME, payload };
};

export const addClick = (payload) => {
  console.log("wakanas", payload);
  return { type: ADD_CLICK, payload };
};

export const addView = (payload) => {
  return { type: ADD_VIEW, payload };
};
