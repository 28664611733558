import styled from "styled-components";
import { AnimationFixed } from "../Animation";

export const ModalBackdrop = styled("div")`
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  height: calc(100vh * 2);
  background-color: #000;
`;

export const Modal = styled("div")`
  min-height: 100%;

  position: relative;

  overflow: auto;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalDialog = styled("div")<{ width? }>`
  width: 100%;
  padding: 5px 8px;
  max-width: ${(props) => props.width || `288px`};
  max-height: 100vh;
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 0px;
    max-width: ${(props) => props.width || `580px`};
  }
`;

export const ModalContent = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  outline: 0;
  background-color: #fff;
  width: 100%;
  overflow: auto;
  padding: ${(props) => (props.padding ? props.padding : "40px 65px")};
  border-radius: 16px;
  height: ${(props) => (props.height ? props.height : "inherit")};
  ${(props) => {
    if (props.isMobile) return "max-height:calc(100vh - 100px);";
  }}
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    border-radius: 8px;
    width: auto;
    min-height: 0;
  }
`;

export const ModalHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Description = styled("div")`
  font-weight: 500;
  text-align: center;
  font-size: ${(props) => (props.isMobile ? "12px" : "16px")};
  margin-bottom: 20px;
`;

export const Title = styled("div")`
  font-size: ${(props) => (props.isMobile ? "18px" : "20px")};
  font-family: Lato;
  text-align: center;
  margin-top: 0px;
  margin-bottom: ${(props) => (props.isMobile ? "20px" : "25px")};
  color: ${(props) => props.theme.colors.Gray};
  font-weight: bold;
  & > * {
    font-size: ${(props) => (props.isMobile ? "12px" : "18px")};
    font-weight: ${(props) => (props.isMobile ? "500" : "normal")};
    font-family: Lato;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 30px;
    color: ${(props) => props.theme.colors.Gray};
  }
`;

export const Close = styled("button")`
  background: transparent;
  position: absolute;
  cursor: pointer;
  top: 18px;
  right: 18px;
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 1;

  &:focus {
    outline: transparent;
  }
`;

export const ModalBody = styled("div")`
  position: relative;
  line-height: 1.75rem;
  z-index: 200;
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    flex: 1 1 auto;
  }

  .rdt_Table {
    overflow: auto;
    min-height: 280px;
    max-height: 280px;
  }

  .rdt_TableHead {
    position: sticky;
    z-index: 10;
    top: 0;
    background-color: white;
    width: calc(100% + 6px);
    margin: 0px -3px 3px;
    padding: 0px 3px;
  }

  .data-table-extensions {
  }
`;

export const ModalFooter = styled("div")`
  padding: 0 0 24px;
`;

export const ModalAnimation = styled(AnimationFixed)`
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  pointer-events: none;
  z-index: 9001;
`;
