import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Carousel from "../../../../../../../components/AsynCarousel";
import { BannerTitle } from "../../../../../components/Banner/style";
import {
  Slide,
  SlideImg,
  SlideSubTitle,
  SlideTitle,
  WrapperSlide,
} from "../../../../../../../components/Slider/style";

import usersActions from "../../../../../../../store/actions/users";
import { useWindowSize } from "../../../../../../../hooks/useWindowScreen";
import { useEffect, useState } from "react";
import { AcademyDto } from "../../../../../../../types/academy.dto";
import { UserDto } from "../../../../../../../types/user.dto";
import { UserImage, UserText } from "./style";
import { Img } from "../../../../../../SignIn/styles";
import {
  FlexContainer,
  Separator,
} from "../../../../../../../components/StyledComponents";

const DEFAULT_IMAGE =
  "https://repository-images.githubusercontent.com/410214337/070f2aba-d9d6-4699-b887-9a0f29015b1b";
const ELEMENTS_PER_PAGE = 9;

interface FetchProps {
  page: number;
  filterBy?: { [key: string]: string | number | boolean | null };
}

function divideArray(array: any[], tamanoSubarray: number) {
  var subarrays: any[] = [];

  for (var i = 0; i < array.length; i += tamanoSubarray) {
    subarrays.push(array.slice(i, i + tamanoSubarray));
  }

  return subarrays;
}

interface ComponentProps {
  users: UserDto[];
  usersStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  academy: AcademyDto;
}

const Component = ({ users, usersStates, academy }: ComponentProps) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [items, setItems] = useState<UserDto[][]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const getUsers = ({ page, filterBy }: FetchProps) => {
    dispatch(
      usersActions.getByAcademy({
        _id: academy._id,
        page,
        limit: ELEMENTS_PER_PAGE,
        offset: ELEMENTS_PER_PAGE * page,
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
        },
      })
    );
  };

  useEffect(() => {
    if (!isLoading) {
      const makeSubArrays = divideArray(users ?? [], 3);
      setItems([...items, ...(users && makeSubArrays)]);

      setThereAreItems(
        !!users && users?.length > 0 && users?.length === ELEMENTS_PER_PAGE
      );
    }
  }, [users, isLoading]);

  useEffect(() => {
    setIsLoading(usersStates.loading);
  }, [usersStates.loading]);

  return (
    <>
      <BannerTitle style={{ fontWeight: "bold" }} size="16px">
        Alumnos de Nuestra Academia
      </BannerTitle>
      <Separator size={20}></Separator>
      <div style={{ padding: "0px 20px" }}>
        <Carousel
          data={users}
          isLoading={usersStates.loading}
          active={thereAreItems}
          action={getUsers}
          setPage={setPage}
          page={page}
        >
          {items.map((userColumn, key) => (
            <WrapperSlide>
              <Slide maxWidth="300px" key={key}>
                {userColumn.map((userRow) => {
                  return (
                    <FlexContainer key={userRow._id} gap="10px" align="center">
                      <UserImage>
                        <Img src={userRow.picture}></Img>
                      </UserImage>
                      <UserText>{`${userRow.name} ${userRow.lastName}`}</UserText>
                    </FlexContainer>
                  );
                })}
              </Slide>
            </WrapperSlide>
          ))}
        </Carousel>
      </div>
    </>
  );
};

const states = ({ usersStore, academyStore }) => {
  const { data: users, states: usersStates } = usersStore.all;
  const { data: academy } = academyStore.selected;

  return {
    users,
    usersStates,
    academy,
  };
};

export default connect(states)(Component);
