import {
  AcademyUserTimesDto,
  AnalyticsUserDto,
  CohortUserTimeDto,
  UserTimesDto,
} from "../../types/analytics.dto";

export const GET_INDIVIDUAL_STATS = "GET_INDIVIDUAL_STATS";
export const GET_INDIVIDUAL_STATS_SUCCESS = "GET_INDIVIDUAL_STATS_SUCCESS";
export const GET_INDIVIDUAL_STATS_ERROR = "GET_INDIVIDUAL_STATS_ERROR";
export const RESET_GET_INDIVIDUAL_STATS = "RESET_GET_INDIVIDUAL_STATS";
export const GET_ALL_STUDENTS_STATS = "GET_ALL_STUDENTS_STATS";
export const GET_COHORT_STATS = "GET_COHORT_STATS";
export const GET_COHORT_STATS_SUCCESS = "GET_COHORT_STATS_SUCCESS";
export const GET_COHORT_STATS_ERROR = "GET_COHORT_STATS_ERROR";
export const RESET_GET_COHORT_STATS = "RESET_GET_COHORT_STATS";
export const GET_ALL_STUDENTS_STATS_SUCCESS = "GET_ALL_STUDENTS_STATS_SUCCESS";
export const GET_ALL_STUDENTS_STATS_ERROR = "GET_ALL_STUDENTS_STATS_ERROR";
export const GET_CAMPUS_STATS = "GET_CAMPUS_STATS";
export const GET_CAMPUS_STATS_SUCCESS = "GET_CAMPUS_STATS_SUCCESS";
export const GET_CAMPUS_STATS_ERROR = "GET_CAMPUS_STATS_ERROR";
export const RESET_GET_CAMPUS_STATS = "RESET_GET_CAMPUS_STATS";
export const GET_COHORT_USER_TIME = "GET_COHORT_USER_TIME";
export const GET_COHORT_USER_TIME_ERROR = "GET_COHORT_USER_TIME_ERROR";
export const GET_COHORT_USER_TIME_SUCCESS = "GET_COHORT_USER_TIME_SUCCESS";
export const RESET_GET_COHORT_USER_TIME = "RESET_GET_COHORT_USER_TIME";
export const GET_ACADEMY_USER_TIME = "GET_ACADEMY_USER_TIME";
export const GET_ACADEMY_USER_TIME_SUCCESS = "GET_ACADEMY_USER_TIME_SUCCESS";
export const GET_ACADEMY_USER_TIME_ERROR = "GET_ACADEMY_USER_TIME_ERROR";
export const RESET_GET_ACADEMY_USER_TIME = "RESET_GET_ACADEMY_USER_TIME";
export const GET_USER_TIME = "GET_USER_TIME";
export const GET_USER_TIME_SUCCESS = "GET_USER_TIME_SUCCESS";
export const GET_USER_TIME_ERROR = "GET_USER_TIME_ERROR";
export const RESET_GET_USER_TIME = "RESET_GET_USER_TIME";

export interface GetUserTime {
  type: typeof GET_USER_TIME;
  payload: {
    userId: string;
  };
}

export interface GetUserTimeSuccess {
  type: typeof GET_USER_TIME_SUCCESS;
  payload: UserTimesDto;
}

export interface GetUserTimeError {
  type: typeof GET_USER_TIME_ERROR;
  payload: string | boolean;
}

export interface ResetGetUserTime {
  type: typeof RESET_GET_USER_TIME;
  payload: null;
}

export interface GetCohortUserTime {
  type: typeof GET_COHORT_USER_TIME;
  payload: {
    cohortId: string;
    userId: string;
  };
}

export interface GetCohortUserTimeSuccess {
  type: typeof GET_COHORT_USER_TIME_SUCCESS;
  payload: CohortUserTimeDto;
}

export interface GetCohortUserTimeError {
  type: typeof GET_COHORT_USER_TIME_ERROR;
  payload: string | boolean;
}

export interface ResetGetCohortUserTime {
  type: typeof RESET_GET_COHORT_USER_TIME;
  payload: null;
}
export interface GetAcademyUserTime {
  type: typeof GET_ACADEMY_USER_TIME;
  payload: {
    academyId: string;
    userId: string;
    topContents: number;
  };
}

export interface GetAcademyUserTimeSuccess {
  type: typeof GET_ACADEMY_USER_TIME_SUCCESS;
  payload: AcademyUserTimesDto;
}

export interface GetAcademyUserTimeError {
  type: typeof GET_ACADEMY_USER_TIME_ERROR;
  payload: string | boolean;
}

export interface ResetGetAcademyUserTime {
  type: typeof RESET_GET_ACADEMY_USER_TIME;
  payload: null;
}

export interface GetCampusStats {
  type: typeof GET_CAMPUS_STATS;
  payload: string;
}

export interface GetCampusStatsSuccess {
  type: typeof GET_CAMPUS_STATS_SUCCESS;
  payload: any;
}

export interface GetCampusStatsError {
  type: typeof GET_CAMPUS_STATS_ERROR;
  payload: string | boolean;
}
export interface ResetGetCampusStats {
  type: typeof RESET_GET_CAMPUS_STATS;
  payload: null;
}
export interface GetIndividualStats {
  type: typeof GET_INDIVIDUAL_STATS;
  payload: { _id: string; cohort?: string };
}

export interface GetCohortsStats {
  type: typeof GET_COHORT_STATS;
  payload: string;
}

export interface GetCohortsStatsSuccess {
  type: typeof GET_COHORT_STATS_SUCCESS;
  payload: any;
}
export interface GetCohortsStatsError {
  type: typeof GET_COHORT_STATS_ERROR;
  payload: string | boolean;
}

export interface ResetGetCohortStats {
  type: typeof RESET_GET_COHORT_STATS;
  payload: null;
}

export interface GetAllStudentsStats {
  type: typeof GET_ALL_STUDENTS_STATS;
  payload: null;
}

export interface GetAllStudentsStatsSuccess {
  type: typeof GET_ALL_STUDENTS_STATS_SUCCESS;
  payload: any;
}

export interface GetAllStudentsStatsError {
  type: typeof GET_ALL_STUDENTS_STATS_ERROR;
  payload: string | boolean;
}

export interface GetIndividualStatsSuccess {
  type: typeof GET_INDIVIDUAL_STATS_SUCCESS;
  payload: AnalyticsUserDto;
}

export interface ResetGetIndividualStats {
  type: typeof RESET_GET_INDIVIDUAL_STATS;
  payload: null;
}

export interface GetIndividualStatsError {
  type: typeof GET_INDIVIDUAL_STATS_ERROR;
  payload: string | boolean;
}

export interface State {
  getIndividualStats: {
    data: AnalyticsUserDto | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  getCampusStats: {
    data: any;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  academyUserTimes: {
    data: null | AcademyUserTimesDto;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  cohortUserTimes: {
    data: null | CohortUserTimeDto;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  userTimes: {
    data: null | UserTimesDto;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  getCohortStats: {
    data: any;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  getAllStudentsStats: {
    data: any;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
}

export const initialValues: State = {
  getIndividualStats: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  academyUserTimes: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  cohortUserTimes: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  userTimes: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  getCampusStats: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  getCohortStats: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  getAllStudentsStats: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetIndividualStats
  | GetIndividualStatsSuccess
  | GetIndividualStatsError
  | ResetGetIndividualStats
  | GetCohortsStats
  | GetCohortsStatsSuccess
  | GetCohortsStatsError
  | ResetGetCohortStats
  | GetAllStudentsStats
  | GetAllStudentsStatsSuccess
  | GetAllStudentsStatsError
  | GetCampusStats
  | GetCampusStatsSuccess
  | GetCampusStatsError
  | ResetGetCampusStats
  | GetUserTime
  | GetUserTimeSuccess
  | GetUserTimeError
  | ResetGetUserTime
  | GetCohortUserTime
  | GetCohortUserTimeSuccess
  | GetCohortUserTimeError
  | ResetGetCohortUserTime
  | GetAcademyUserTime
  | GetAcademyUserTimeSuccess
  | GetAcademyUserTimeError
  | ResetGetAcademyUserTime;
