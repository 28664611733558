import styled from "styled-components";

export const PageNumberWrapper = styled("div")`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
`;

export const PageNumber = styled("h3")`
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  width: min-content;
  background-color: #9b9b9b;
  padding: 5px;
  z-index: 2;
`;

export const SlideshowDescriptionText = styled("h4")`
  font-size: 12px;
  font-weight: normal;
  color: #2a2747;
`;

export const SlideshowInfoWrapper = styled("div")`
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(106, 105, 112, 0.25);
  padding: 10px;
`;

export const SlideshowTitle = styled("h2")`
  color: ${(props) => props.theme.theme.text};
  font-size: 16px;
  font-weight: bold;
`;

export const SidebarWrapper = styled("div")`
  position: relative;
  width: 33px;
  height: 33px;
  border-radius: 6px;
  max-height: 33px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  z-index: 3;
  &.active {
    max-height: 300px;
    overflow: visible;
  }
`;

export const LikeDislikeBox = styled("div")`
  padding: 4px;
  cursor: pointer;
  border-radius: 5px;
  border: solid 1px #2496ed;
  svg {
    transition: fill 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  h3 {
    transition: color 0.3s ease-in-out;
  }
  &:not(.active):hover {
    svg {
      color: #fff;
      fill: #fff;
    }
    h3 {
      color: #fff;
    }
    background-color: #2496ed;
    color: #fff;
  }
  &.active {
    background-color: #2496ed;
    h3 {
      color: #fff;
    }
  }
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
`;

export const LikeDislikeText = styled("h3")`
  font-size: 12px;
  font-weight: 600;
  color: #2496ed;
  transition: color 0.3s ease-in-out;
`;
