import {
  initialState,
  Actions,
  State,
  GET_ALL_COHORTS,
  GET_ALL_COHORTS_ERROR,
  GET_ALL_COHORTS_SUCCESS,
  GET_COHORT,
  GET_COHORT_ERROR,
  GET_COHORT_SUCCESS,
  NEW_COHORT,
  NEW_COHORT_ERROR,
  NEW_COHORT_SUCCESS,
  RESET_NEW_COHORT,
  UPDATE_COHORT,
  UPDATE_COHORT_ERROR,
  UPDATE_COHORT_SUCCESS,
  RESET_UPDATE_COHORT,
  DELETE_COHORT,
  DELETE_COHORT_ERROR,
  DELETE_COHORT_SUCCESS,
  RESET_DELETE_COHORT,
  DELETE_COHORT_MEMBER,
  SELECT_COHORT,
  UPDATE_CURRENT_COHORT_LIST,
  ADD_COHORT_MEMBER,
  QUERY_COHORT,
  QUERY_COHORT_SUCCESS,
  QUERY_COHORT_ERROR,
  RESET_QUERY_COHORT,
  ADD_RATING_COHORT,
  MANAGE_LIKE_DISLIKE_COHORT,
  GET_COHORTS_OFFSET,
  GET_COHORTS_OFFSET_ERROR,
  GET_COHORTS_OFFSET_SUCCESS,
  RESET_GET_COHORTS_OFFSET,
  RESET_GET_ALL_COHORT_DATA,
  RESET_GET_ONE_COHORT_DATA,
  GET_COHORTS_BY_ACADEMY,
  SET_GET_ONE_COHORT,
} from "../types/cohort";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_COHORTS:
      return {
        ...state,
        allCohorts: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_COHORTS_SUCCESS:
      return {
        ...state,
        allCohorts: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_CURRENT_COHORT_LIST:
      return {
        ...state,
        allCohorts: {
          data: action.payload,
          states: state.allCohorts.states,
        },
      };
    case GET_COHORTS_BY_ACADEMY:
      return {
        ...state,
        allCohorts: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SET_GET_ONE_COHORT:
      return {
        ...state,
        cohort: {
          ...state.cohort,
          data: action.payload,
        },
      };
    case RESET_GET_ONE_COHORT_DATA:
      return {
        ...state,
        cohort: initialState.cohort,
      };
    case RESET_GET_ALL_COHORT_DATA:
      return {
        ...state,
        allCohorts: initialState.allCohorts,
      };
    case GET_COHORTS_OFFSET:
      return {
        ...state,
        offSetCohort: {
          ...state.offSetCohort,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case GET_COHORTS_OFFSET_ERROR:
      return {
        ...state,
        offSetCohort: {
          ...state.offSetCohort,
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case GET_COHORTS_OFFSET_SUCCESS:
      return {
        ...state,
        offSetCohort: {
          data: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case RESET_GET_COHORTS_OFFSET:
      return {
        ...state,
        offSetCohort: {
          ...state.offSetCohort,
          states: {
            loading: false,
            success: false,
            error: false,
          },
        },
      };
    case MANAGE_LIKE_DISLIKE_COHORT:
      return {
        ...state,
        updateCohort: {
          data: null,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case ADD_RATING_COHORT:
      return {
        ...state,
        updateCohort: {
          data: null,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case GET_ALL_COHORTS_ERROR:
      return {
        ...state,
        allCohorts: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case QUERY_COHORT:
      return {
        ...state,
        queryCohort: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case QUERY_COHORT_SUCCESS:
      return {
        ...state,
        queryCohort: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case QUERY_COHORT_ERROR:
      return {
        ...state,
        queryCohort: {
          data: state.queryCohort.data,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_QUERY_COHORT:
      return {
        ...state,
        queryCohort: {
          ...state.queryCohort,
          states: initialState.queryCohort.states,
        },
      };
    case ADD_COHORT_MEMBER:
      return {
        ...state,
        updateCohort: {
          data: null,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case GET_COHORT:
      return {
        ...state,
        cohort: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_COHORT_SUCCESS:
      return {
        ...state,
        cohort: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_COHORT_ERROR:
      return {
        ...state,
        cohort: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_COHORT:
      return {
        ...state,
        newCohort: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_COHORT_SUCCESS:
      return {
        ...state,
        newCohort: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_COHORT_ERROR:
      return {
        ...state,
        newCohort: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_COHORT:
      return {
        ...state,
        newCohort: initialState.newCohort,
      };
    case DELETE_COHORT:
      return {
        ...state,
        deleteCohort: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_COHORT_SUCCESS:
      return {
        ...state,
        deleteCohort: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_COHORT_ERROR:
      return {
        ...state,
        deleteCohort: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_COHORT:
      return {
        ...state,
        deleteCohort: initialState.deleteCohort,
      };
    case SELECT_COHORT:
      return {
        ...state,
        selectCohort: {
          data: action.payload,
        },
      };

    case UPDATE_COHORT:
      return {
        ...state,
        updateCohort: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_COHORT_MEMBER:
      return {
        ...state,
        updateCohort: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_COHORT_SUCCESS:
      return {
        ...state,
        updateCohort: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_COHORT_ERROR:
      return {
        ...state,
        updateCohort: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_COHORT:
      return {
        ...state,
        updateCohort: initialState.updateCohort,
      };
    default:
      return state;
  }
};

export default reducers;
