import { BsFillImageFill, BsFillPlayFill } from "react-icons/bs";
import { HiDocumentText } from "react-icons/hi";
import { HiPresentationChartBar } from "react-icons/hi2";
import { IconType } from "react-icons/lib";
import { RiOrganizationChart } from "react-icons/ri";

export const getContentIcon = (content) => {
  let icon: IconType | null = null;
  if (content.type) {
    switch (content.type) {
      case "VIDEO":
        icon = BsFillPlayFill;

        break;
      case "SLIDESHOW":
        icon = HiPresentationChartBar;

        break;
      case "CDOC":
        icon = HiDocumentText;

        break;

      case "ROADMAP":
        icon = RiOrganizationChart;

        break;
      case "IMAGE":
        icon = BsFillImageFill;

        break;
    }
  }
  console.log(icon, content, "iconget");
  return icon;
};
