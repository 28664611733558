import styled from "styled-components";
import UiButton from "../../../../../components/Button";

export const Button = styled(UiButton)<{ active: boolean }>`
  max-width: min-content;
  border: none;
  box-shadow: none;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.LightBlue : theme.colors.LightBlueHover};
  height: 32px;
  color: ${({ active }) => (active ? "white" : "#2a2747")};
  padding: 7px 8px 8px;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  margin-right: 10px;
`;
