import { Link } from "react-router-dom";
import styled from "styled-components";
import { Avatar } from "../Avatar/styles";

export const Profile = styled(Link)<{ sidebarCollapsed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  padding: 30px 23px 23px;
  background-color: white;
  position: relative;
  width: 260px;
  transition: opacity 0.8s ease-in-out;
  visibility: ${({ sidebarCollapsed }) =>
    sidebarCollapsed ? "hidden" : "visible"};
  opacity: ${({ sidebarCollapsed }) => (sidebarCollapsed ? 0 : 1)};

  &:after {
    content: "";
    display: block;
    height: 1px;
    width: calc(100% - 46px);
    background-color: #a1b0c4;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
`;

export const ProfileNoLink = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  padding: 30px 23px 23px;
  background-color: white;
  position: relative;
  width: 260px;
  transition: opacity 0.8s ease-in-out;
  visibility: ${({ sidebarCollapsed }) =>
    sidebarCollapsed ? "hidden" : "visible"};
  opacity: ${({ sidebarCollapsed }) => (sidebarCollapsed ? 0 : 1)};

  &:after {
    content: "";
    display: block;
    height: 1px;
    width: calc(100% - 46px);
    background-color: #a1b0c4;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
`;

export const TalentAvatar = styled(Avatar)`
  height: ${(props) => (!!props.height ? props.height : "30px")};
  width: ${(props) => (!!props.width ? props.width : "30px")};
  padding: 1.5px;
  overflow: hidden;
  & > img {
    ${(props) => !!props.borderRadius && `border-radius:${props.borderRadius};`}
  }
`;

export const MenuContainer = styled("div")`
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
  &:after {
    content: "";
    display: block;
    height: 1px;
    width: calc(100% - 46px);
    background-color: #a1b0c4;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -200%);
  }
`;

export const Name = styled("span")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 14px;
`;
export const FirstName = styled("span")`
  color: #334253;
  font-size: 14px;
  font-weight: bold;
`;
export const LastName = styled("span")`
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 14px;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  color: ${(props) => props.theme.colors.Gray};
`;
