import React, { useEffect, useRef, useState } from "react";
import Grid from "../../../../../components/Grid";
import EmptyState from "../EmptyState";
import Skeleton from "../../../../../components/Skeleton";

import VideoPlayer from "../../../../../components/VideoPlayer";
import { connect, useDispatch } from "react-redux";
import {
  FlexContainer,
  ScrollSection,
} from "../../../../../components/StyledComponents";
import {
  addViewVideo,
  getAllVideos,
  getVideo,
  manageLikeDislikeVideo,
  resetGetAllVideos,
  resetGetVideo,
  resetQueryVideo,
  resetUpdateVideo,
  setSelectedVideo,
} from "../../../../../store/actions/video";
import { useNavigate, useParams } from "react-router-dom";

import { VideoDto } from "../../../../../types/video.dto";

import {
  GridCardContent,
  GridCardImage,
  GridCardTitle,
  GridComment,
  GridQuerySideImageContainer,
} from "../../../../../components/Layout/Dashboard/styles";
import academyActions from "../../../../../store/actions/academies";
import { AcademyDto } from "../../../../../types/academy.dto";
import {
  DateFromNow,
  secondsToHHMMSS,
} from "../../../../../helpers/format-date";
import FilterByName from "./components/FilterByName";
import { ItemDataText } from "../List/style";
import { StateDto } from "../../../../../types/states.dto";
import { toast } from "react-toastify";

import { UserDto } from "../../../../../types/user.dto";
import { AiFillClockCircle, AiFillEye } from "react-icons/ai";

import { overThousandFixed } from "../../../../../helpers/number-fixed";

import InfinityScroll from "../../../../../components/InfinityScroll";
import { setCookie } from "../../../../../helpers/cookies-handler";
import {
  addClick,
  addView,
  userTime,
} from "../../../../../store/actions/userTimes";
import useTrackTimeAndAPI from "../../../../../hooks/useTrackTimeAndAPI";

import ModalConfirmDelete from "../../../../../components/ModalConfirmDelete";
import { useWindowSize } from "../../../../../hooks/useWindowScreen";
import DescriptionForContentsViewMobile from "../../../../../components/DescriptionForContentsViewMobile";
import DescriptionForContentsView from "../../../../../components/DescriptionForContentsView";
import { BottomLabel } from "../../../Home/components/MediaItems/components/MediaItem/style";
interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  video,
  academies,
  videos,
  queryVideos,
  queryVideoStates,
  user,
  updateVideoStates,
  videosStates,
  updatedVideo,
  getVideoStates,
  academy,
  getAcademyStates,
  updateAcademyStates,
  updatedAcademy,
}: {
  video: VideoDto;
  academies: AcademyDto[];
  videos: VideoDto[];
  queryVideos: VideoDto[];
  queryVideoStates: StateDto;
  user: UserDto;
  updateVideoStates: StateDto;
  videosStates: StateDto;
  updatedVideo;
  getVideoStates: StateDto;
  academy: AcademyDto;
  getAcademyStates: StateDto;
  updatedAcademy: AcademyDto;
  updateAcademyStates: StateDto;
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const inputRef: any = useRef(null);
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const [currentVideosList, setCurrentVideosList] = useState<any[]>([]);
  const [queryMode, setQueryMode] = useState<string | null>(null);
  const [query, setQuery] = useState<string | null>(null);
  const [currentVideoAcademy, setCurrentVideoAcademy] = useState<AcademyDto>();
  const [currentVideo, setCurrentVideo] = useState<VideoDto>();
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [action, setAction] = useState<{ action: string; data: any }>();
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);

  const [filteredCurrentVideosListValues, setFilteredCurrentVideosListValues] =
    useState<VideoDto[]>([]);

  const handleQuery = (ev) => {
    const value = ev.target.value;
    setQuery(value.length === 0 ? "" : value);
  };

  const searchQuery = (ev) => {
    if (ev.keyCode === 13) {
      /*  dispatch(queryImage({ keyword: query })); */
      setQueryMode(query);
    }
  };

  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 6,
      offset: 6 * Math.floor(page / 2),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        available: true,
        published: true,
      },
    };
    const sortBy = page % 2 === 0 ? "createdAt" : "viewCount";

    const sortDirection = "desc";
    dispatch(
      getAllVideos({
        ...payload,
        sortBy,
        sortDirection,
        ...(queryMode && { keyword: query }),
      })
    );
  };

  useEffect(() => {
    if (video) {
      setCurrentVideo(video);
      dispatch(academyActions.getOne({ _id: video.academy._id }));
    }
  }, [video]);

  useEffect(() => {
    if (academy) {
      setCurrentVideoAcademy(academy);
    }
  }, [academy]);

  useEffect(() => {
    if (queryMode) {
      setPage(0);
      setIsLoading(false);
      setThereAreItems(true);
      setCurrentVideosList([]);
    }
  }, [queryMode]);

  useEffect(() => {
    if (updateAcademyStates.success && currentVideoAcademy) {
      setCurrentVideoAcademy(updatedAcademy);
      dispatch(academyActions.resetUpdate());
    }
    if (updateAcademyStates.error) {
      toast.error(`${updateAcademyStates.error}`);
      dispatch(academyActions.resetUpdate());
    }
  }, [updateAcademyStates]);

  useEffect(() => {
    const filteredTalents = currentVideosList?.filter((currentTalentValues) => {
      const thereAreFilters =
        applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
          .length > 0;
      const totalOfFilters = applyedFilters.filter(
        (applyedFilter) => !applyedFilter.groupal
      ).length;
      const passFilters = applyedFilters
        .filter(
          (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
        )
        .filter(
          (applyedFilter) =>
            currentTalentValues[applyedFilter.field] === applyedFilter.value
        ).length;
      const specialFilter = applyedFilters.filter(
        (applyedFilter) => !!applyedFilter.special
      );

      const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
        return applyedFilter.special(
          currentTalentValues,
          applyedFilter.value,
          currentTalentValues
        );
      });
      return (
        !thereAreFilters ||
        (thereAreFilters &&
          totalOfFilters === passFilters + specialFilter.length &&
          speacialFilterCheck.length === specialFilter.length)
      );
    });
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentVideosListValues(initResult);
  }, [applyedFilters, currentVideosList]);

  useEffect(() => {
    if (!isLoading && !!videos) {
      setCurrentVideosList((state) => [...state, ...videos]);
    }
    setThereAreItems(!!videos && videos?.length > 0);
  }, [videos, isLoading]);

  useEffect(() => {
    setIsLoading(videosStates.loading);
  }, [videosStates]);

  useEffect(() => {
    if (getVideoStates.error) {
      toast.error(`${getVideoStates.error}`);
      dispatch(resetGetVideo());
    }
  }, [getVideoStates, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllVideos());
      dispatch(academyActions.resetGetOneAcademy());
    };
  }, []);

  useEffect(() => {
    if (
      ((params.id && !video) ||
        (params.id && video && params.id !== video?._id)) &&
      user
    ) {
      dispatch(getVideo({ _id: params.id }));
    }
  }, [params.id, user]);

  useEffect(() => {
    if (params.id && user) {
      dispatch(addView({ id: params.id, user: user._id, type: "CONTENT" }));
      if (document.getElementById("scrollToTopTarget")) {
        const element: any = document.getElementById("scrollToTopTarget");

        element.scrollIntoView();
      }
    }
  }, [params.id, user]);

  useEffect(() => {
    if (queryVideoStates.error) {
      toast.error(queryVideoStates.error);
      dispatch(resetQueryVideo());
    }
    if (queryVideoStates.success) {
      dispatch(resetQueryVideo());
    }
  }, [queryVideoStates]);

  useEffect(() => {
    if (updateVideoStates.success) {
      dispatch(setSelectedVideo(updatedVideo));
      dispatch(resetUpdateVideo());
    }
    if (updateVideoStates.error) {
      toast.error(updateVideoStates.error);
      dispatch(resetUpdateVideo());
    }
  }, [updateVideoStates]);

  const apiAction = (timeSpent) => {
    dispatch(
      userTime({ ...timeSpent, id: params.id, user: user._id, type: "VIDEO" })
    ); // Dispatch action to update Redux store or make API request
  };
  useTrackTimeAndAPI(apiAction, params.id && user);

  useEffect(() => {
    if (!!video) {
      setCookie(
        "DEVPLACE_VISITED_VIDEO",
        JSON.stringify({ _id: video._id, name: video.name })
      );
    }
  }, [video]);

  return (
    <>
      <div
        id="scrollToTopTarget"
        style={{ position: "absolute", top: 0, width: "0px", height: "0px" }}
      ></div>
      <Grid.Row>
        <Grid.Col style={{ marginBottom: "20px" }} lg={9.0}>
          <FlexContainer direction="column" gap="11px">
            {!currentVideo || getVideoStates.loading || !currentVideo.url ? (
              <EmptyState />
            ) : (
              <>
                <div onClick={() => {}}>
                  <VideoPlayer
                    src={currentVideo.url}
                    cover={currentVideo.picture}
                    style={{
                      height: "calc(100vw / 1.89)",
                      width: "100%",
                      maxHeight: "522px",
                    }}
                    onPlay={() => {
                      dispatch(addViewVideo({ _id: currentVideo._id }));
                    }}
                  />
                </div>
                {isMobile ? (
                  <DescriptionForContentsViewMobile
                    content={currentVideo}
                    currentContentAcademy={currentVideoAcademy}
                    page={page}
                    setPage={setPage}
                    isLoading={isLoading}
                    thereAreItems={thereAreItems}
                    query={query}
                    manageLikeDislikeContent={manageLikeDislikeVideo}
                    searchQuery={searchQuery}
                    handleQuery={handleQuery}
                    fetchData={fetchData}
                    currentContentList={filteredCurrentVideosListValues}
                  ></DescriptionForContentsViewMobile>
                ) : (
                  <DescriptionForContentsView
                    content={currentVideo}
                    manageDislikeLikeContent={manageLikeDislikeVideo}
                    currentContentAcademy={currentVideoAcademy}
                  ></DescriptionForContentsView>
                )}
              </>
            )}
          </FlexContainer>
        </Grid.Col>

        {!isMobile && (
          <Grid.Col offset={{ lg: 0.3 }}>
            <FilterByName
              searchQuery={searchQuery}
              setFilter={handleQuery}
              value={query}
            />

            <div style={{ height: 10 }} />

            <ScrollSection style={{ maxHeight: 450, boxShadow: "none" }}>
              <InfinityScroll
                action={fetchData}
                page={page}
                setPage={setPage}
                data={filteredCurrentVideosListValues}
                active={thereAreItems}
                isLoading={isLoading}
                style={{ width: "100%", paddingBottom: "10px" }}
                loader={
                  <Skeleton
                    itemsPerPage={10}
                    itemsPerRow={1}
                    animated
                    size={{ height: 58, width: 297 }}
                    spaceBetweenItems={10}
                    icon="video"
                  />
                }
              >
                <FlexContainer
                  direction="column"
                  gap="10px"
                  align="center"
                  justify="center"
                  style={{ paddingBottom: "10px" }}
                >
                  {filteredCurrentVideosListValues
                    .filter((item) => item._id !== params.id)
                    .map((item) => {
                      return (
                        <GridCardContent
                          onClick={() => {
                            dispatch(
                              addClick({
                                id: item._id,
                                user: user._id,
                                type: "CONTENT",
                              })
                            );
                            navigate(`../${item._id}`, {
                              replace: true,
                            });
                          }}
                          pd={"5px"}
                          key={item._id}
                        >
                          <FlexContainer gap="11.1px" align="center">
                            <GridQuerySideImageContainer>
                              <GridCardImage src={item.picture || ""} />
                              {item.duration && (
                                <BottomLabel>
                                  {secondsToHHMMSS(video.duration)}
                                </BottomLabel>
                              )}
                            </GridQuerySideImageContainer>

                            <FlexContainer direction="column" gap="1px">
                              <GridCardTitle style={{ fontSize: "14px" }}>
                                {item.name}
                              </GridCardTitle>

                              <FlexContainer direction="column">
                                <FlexContainer
                                  onClick={(ev) => {
                                    ev.stopPropagation();
                                    dispatch(
                                      addClick({
                                        id: item.academy._id,
                                        user: user._id,
                                        type: "ACADEMY",
                                      })
                                    );
                                    navigate(
                                      `/dashboard/academy/${item.academy._id}`
                                    );
                                  }}
                                  align="center"
                                  gap="5px"
                                >
                                  <GridComment>{item.academy.name}</GridComment>
                                </FlexContainer>
                                <FlexContainer gap="5px" align="center">
                                  <FlexContainer align="center" gap="3px">
                                    <AiFillEye fill="#6a6970"></AiFillEye>

                                    <ItemDataText>
                                      <GridComment>
                                        {overThousandFixed(item.viewCount)}
                                      </GridComment>
                                    </ItemDataText>
                                  </FlexContainer>

                                  <span>|</span>
                                  <FlexContainer align="center" gap="3px">
                                    <AiFillClockCircle fill="#6a6970"></AiFillClockCircle>

                                    <ItemDataText>
                                      <GridComment>
                                        {DateFromNow(item.createdAt)}
                                      </GridComment>
                                    </ItemDataText>
                                  </FlexContainer>
                                </FlexContainer>
                              </FlexContainer>
                            </FlexContainer>
                          </FlexContainer>
                        </GridCardContent>
                      );
                    })}
                </FlexContainer>
              </InfinityScroll>
            </ScrollSection>
          </Grid.Col>
        )}
      </Grid.Row>

      {currentVideoAcademy && action?.action === "out-of-academy" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Desuscribirse",
            style: {
              color: "Danger",

              style: {
                width: "150px",
                height: "31px",
              },
              options: {
                type: "filled",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={updateAcademyStates}
          elementActions={academyActions.deleteMember({
            _id: currentVideoAcademy._id,
            userId: user._id,
          })}
          onClose={() => {
            setAction(undefined);
          }}
          title={
            <FlexContainer direction="column" gap="11px">
              <span>
                {`¿Seguro quieres salir de la academia `}
                <span style={{ color: "#000" }}>
                  {currentVideoAcademy.name}
                </span>
                ?
              </span>
              <p>
                Perderás todos los accesos y suscripciones relacionados a esta
                Academia y los contenidos que no hayas descargado.
              </p>
            </FlexContainer>
          }
          /*  resetState={() => {
            setAction(undefined);
          }} */
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({ videoStore, academyStore, userStore }) => {
  const { data: video, states: getVideoStates } = videoStore.video;
  const { data: academies } = academyStore.all;
  const { data: academy, states: getAcademyStates } = academyStore.selected;
  const { data: updatedAcademy, states: updateAcademyStates } =
    academyStore.update;
  const { data: videos, states: videosStates } = videoStore.all;
  const { data: queryVideos, states: queryVideoStates } = videoStore.query;
  const { states: updateVideoStates, data: updatedVideo } = videoStore.update;
  const { data: user } = userStore;
  return {
    video,
    academies,
    videos,
    queryVideos,
    queryVideoStates,
    user,
    updateVideoStates,
    videosStates,
    updatedVideo,
    getVideoStates,
    academy,
    getAcademyStates,
    updatedAcademy,
    updateAcademyStates,
  };
};

export default connect(states)(Component);
