import {
  initialState,
  Actions,
  State,
  GET_ALL_SLIDESHOWS,
  GET_ALL_SLIDESHOWS_ERROR,
  GET_ALL_SLIDESHOWS_SUCCESS,
  GET_SLIDESHOW,
  GET_SLIDESHOW_ERROR,
  GET_SLIDESHOW_SUCCESS,
  NEW_SLIDESHOW,
  NEW_SLIDESHOW_ERROR,
  NEW_SLIDESHOW_SUCCESS,
  RESET_NEW_SLIDESHOW,
  UPDATE_SLIDESHOW,
  UPDATE_SLIDESHOW_ERROR,
  UPDATE_SLIDESHOW_SUCCESS,
  RESET_UPDATE_SLIDESHOW,
  DELETE_SLIDESHOW,
  DELETE_SLIDESHOW_ERROR,
  DELETE_SLIDESHOW_SUCCESS,
  RESET_DELETE_SLIDESHOW,
  UPLOAD_MEDIA,
  UPDATE_SLIDESHOW_LOADING,
  RESET_GET_SLIDESHOW,
  ADD_USER_TO_SLIDESHOW,
  SHARED_SLIDESHOW,
  SET_GET_SLIDESHOW,
  GET_SLIDESHOWS_OFFSET,
  GET_SLIDESHOWS_OFFSET_ERROR,
  GET_SLIDESHOWS_OFFSET_SUCCESS,
  RESET_GET_SLIDESHOWS_OFFSET,
  ADD_CLICK_SLIDESHOW,
  QUERY_SLIDESHOW,
  QUERY_SLIDESHOW_ERROR,
  QUERY_SLIDESHOW_SUCCESS,
  RESET_QUERY_SLIDESHOW,
  MANAGE_LIKE_DISLIKE_SLIDESHOW,
  UPDATE_CURRENT_SLIDESHOW_LIST,
  SAVED_SLIDESHOW,
  ADD_VIEW_SLIDESHOW,
  RESET_GET_ONE_SLIDESHOW_DATA,
  RESET_GET_ALL_SLIDESHOW_DATA,
  UPDATE_ITEM_LIST_SLIDESHOW,
} from "../types/slideshow";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_SLIDESHOWS:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_SLIDESHOWS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_SLIDESHOWS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_SLIDESHOWS_OFFSET:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };

    case RESET_GET_ONE_SLIDESHOW_DATA:
      return {
        ...state,
        slideshow: initialState.slideshow,
      };
    case RESET_GET_ALL_SLIDESHOW_DATA:
      return {
        ...state,
        all: initialState.all,
      };
    case UPDATE_ITEM_LIST_SLIDESHOW:
      return {
        ...state,
        update: {
          data: null,
          states: {
            success: false,
            error: false,
            loading: true,
          },
        },
      };
    case ADD_VIEW_SLIDESHOW:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SAVED_SLIDESHOW:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case MANAGE_LIKE_DISLIKE_SLIDESHOW:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_CURRENT_SLIDESHOW_LIST:
      return {
        ...state,
        all: {
          ...state.all,
          data: action.payload,
        },
      };
    case QUERY_SLIDESHOW:
      return {
        ...state,
        query: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case QUERY_SLIDESHOW_SUCCESS:
      return {
        ...state,
        query: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case QUERY_SLIDESHOW_ERROR:
      return {
        ...state,
        query: {
          data: state.query.data,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_QUERY_SLIDESHOW:
      return {
        ...state,
        query: {
          ...state.query,
          states: initialState.query.states,
        },
      };
    case ADD_CLICK_SLIDESHOW:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_SLIDESHOWS_OFFSET_ERROR:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case GET_SLIDESHOWS_OFFSET_SUCCESS:
      return {
        ...state,
        offSet: {
          data: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case RESET_GET_SLIDESHOWS_OFFSET:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: false,
            success: false,
            error: false,
          },
        },
      };
    case UPLOAD_MEDIA:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case ADD_USER_TO_SLIDESHOW:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SHARED_SLIDESHOW:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_SLIDESHOW_LOADING:
      return {
        ...state,
        new: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_SLIDESHOW:
      return {
        ...state,
        slideshow: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_SLIDESHOW_SUCCESS:
      return {
        ...state,
        slideshow: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_SLIDESHOW_ERROR:
      return {
        ...state,
        slideshow: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_SLIDESHOW:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_SLIDESHOW_SUCCESS:
      return {
        ...state,
        new: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case SET_GET_SLIDESHOW:
      return {
        ...state,
        slideshow: {
          ...state.slideshow,
          data: !!action.payload ? action.payload : null,
        },
      };
    case RESET_GET_SLIDESHOW:
      return {
        ...state,
        slideshow: {
          ...state.slideshow,
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case NEW_SLIDESHOW_ERROR:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_SLIDESHOW:
      return {
        ...state,
        new: {
          data: state.new.data,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_SLIDESHOW:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_SLIDESHOW_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_SLIDESHOW_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_SLIDESHOW:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_SLIDESHOW:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_SLIDESHOW_SUCCESS:
      return {
        ...state,
        update: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_SLIDESHOW_ERROR:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_SLIDESHOW:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
