import styled from "styled-components";

export const ByteWrapper = styled("div")``;

export const ByteVideoWrapper = styled("div")`
  border-radius: ${(props) => (props.mobile ? "0px" : "20px")};
  overflow: hidden;
  background-color: #051725;
  max-width: ${(props) => (props.mobile ? "none" : "300px")};
  height: ${(props) => (props.mobile ? "calc(100vh - 100px)" : "552px")};
  position: relative;
`;

export const AcademyPicture = styled("div")`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
`;

export const ByteTitle = styled("h2")`
  font-size: 13px;
  font-weight: bold;
  color: #6a6970;
`;

export const ByteMobileFooter = styled("div")`
  position: absolute;
  padding: 10px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
`;

export const ByteMobileFooterTitle = styled("h1")`
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  ${(props) =>
    props.hideLine
      ? `display: -webkit-box;
  -webkit-line-clamp: ${props.hideLine}; 
  -webkit-box-orient: vertical;
  overflow:hidden;
text-overflow: ellipsis;
white-space: normal;`
      : ""}
`;

export const ByteMobileSide = styled("div")`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 2px;
  z-index: 2;
`;

export const MediaInteractionButtons = styled("div")`
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: rgba(36, 150, 237, 0.25);
  svg {
    ${(props) =>
      props.svgColor
        ? `color:${props.svgColor} !important;fill:${props.svgColor} !important;`
        : ""}
    transition: fill 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  &:not(.active):hover {
    svg {
      color: #fff;
      fill: #fff;
    }

    background-color: ${(props) => props.theme.colors.LightBlue};
    color: #fff;
  }
  &.active {
    background-color: ${(props) => props.theme.colors.LightBlue};
    svg {
      color: #fff;
      fill: #fff;
    }
  }
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
`;

export const ByteSkippedButtons = styled("div")`
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const ByteSkipped = styled("div")`
  cursor: pointer;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 3px 0 rgba(79, 38, 162, 0.25);
  background-color: ${(props) => props.theme.colors["White"]};
`;
