import React from "react";
import {
  ContentCardTitle,
  ContentCardWrapper,
  ContentDataText,
  ContentIcon,
  ContentImageBottomLabel,
  ContentLogoWrapper,
} from "./style";
import {
  GridCardImage,
  GridCardImageContainer,
} from "../Layout/Dashboard/styles";
import { FlexContainer } from "../StyledComponents";
import { Img } from "../Header/styles";
import { DateFromNow } from "../../helpers/format-date";
import Icon from "../Icon";

const Component = ({
  item,
  cardOnClick,
  logoOnclick,
  ContentTypeLogo,
  IconAbsolute,
  iconAbsoluteOnClick,
  children,
}: {
  item;
  cardOnClick?;
  logoOnclick?;
  ContentTypeLogo?: { icon?; image? };
  IconAbsolute?;
  iconAbsoluteOnClick?;
  children?;
}) => {
  return (
    <ContentCardWrapper
      onClick={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (cardOnClick) cardOnClick(item);
      }}
      pd={"10px"}
    >
      {ContentTypeLogo?.icon && (
        <ContentLogoWrapper>
          <ContentTypeLogo.icon color={"#fff"} size={21}></ContentTypeLogo.icon>
        </ContentLogoWrapper>
      )}
      {ContentTypeLogo?.image && (
        <ContentLogoWrapper>
          <Icon icon={ContentTypeLogo?.image} color="#fff" size="21px"></Icon>
        </ContentLogoWrapper>
      )}
      {IconAbsolute && (
        <IconAbsolute
          onClick={(ev) => {
            ev.stopPropagation();
            iconAbsoluteOnClick && iconAbsoluteOnClick(item);
          }}
        ></IconAbsolute>
      )}
      <GridCardImageContainer>
        {item.bottomLabel && (
          <ContentImageBottomLabel>{item.bottomLabel}</ContentImageBottomLabel>
        )}
        <GridCardImage
          src={
            item?.picture ||
            "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
          }
        ></GridCardImage>
      </GridCardImageContainer>

      {children ? (
        children
      ) : (
        <FlexContainer gap="12px">
          <ContentIcon
            style={{ cursor: "pointer" }}
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              if (logoOnclick)
                logoOnclick(
                  item.academy && item.academy["_id"]
                    ? item.academy["_id"]
                    : item.academy
                );
            }}
          >
            <Img src={item.academy.picture}></Img>
          </ContentIcon>
          <FlexContainer direction="column" gap="5px">
            <ContentCardTitle style={{ fontSize: "14px" }}>
              {item.name}
            </ContentCardTitle>

            <ContentDataText>{item.academy.name}</ContentDataText>

            <FlexContainer align="center" gap="5px">
              <ContentDataText>{`Vistas: ${item.viewCount}`}</ContentDataText>

              <span>|</span>

              <ContentDataText>{DateFromNow(item.createdAt)}</ContentDataText>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      )}
    </ContentCardWrapper>
  );
};

export default Component;
