import {
  GetIndividualStats,
  GET_INDIVIDUAL_STATS,
  RESET_GET_INDIVIDUAL_STATS,
  ResetGetIndividualStats,
  GetCohortsStats,
  GET_COHORT_STATS,
  RESET_GET_COHORT_STATS,
  ResetGetCohortStats,
  GetAllStudentsStats,
  GET_ALL_STUDENTS_STATS,
  GetCampusStats,
  GET_CAMPUS_STATS,
  ResetGetCampusStats,
  RESET_GET_CAMPUS_STATS,
  GET_COHORT_USER_TIME,
  GET_ACADEMY_USER_TIME,
  GET_USER_TIME,
  GetAcademyUserTime,
} from "../types/analytics";

export const getIndividualStats = (payload): GetIndividualStats => {
  return { type: GET_INDIVIDUAL_STATS, payload };
};

export const getCampusStats = (payload) => {
  return { type: GET_CAMPUS_STATS, payload };
};

export const getCohortUserTimeStats = (payload) => {
  return { type: GET_COHORT_USER_TIME, payload };
};
export const getAcademyUserTimeStats = (payload: {
  academyId: string;
  userId: string;
  topContents: number;
}): GetAcademyUserTime => {
  return { type: GET_ACADEMY_USER_TIME, payload };
};
export const getUserTimeStats = (payload) => {
  return { type: GET_USER_TIME, payload };
};

export const resetCampusStats = () => {
  return { type: RESET_GET_CAMPUS_STATS, payload: null };
};

export const resetGetIndividualStats = (): ResetGetIndividualStats => {
  return { type: RESET_GET_INDIVIDUAL_STATS, payload: null };
};

export const getCohortStats = (id: string): GetCohortsStats => {
  return { type: GET_COHORT_STATS, payload: id };
};

export const resetGetCohortStats = (): ResetGetCohortStats => {
  return { type: RESET_GET_COHORT_STATS, payload: null };
};

export const analyticsStudents = (): GetAllStudentsStats => {
  return { type: GET_ALL_STUDENTS_STATS, payload: null };
};
