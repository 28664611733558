import { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { resetCampusStats } from "../../../store/actions/analytics";

const Component = ({ analyticsCampus, analyticsCampusStates }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (analyticsCampusStates.success) {
      setTimeout(() => {
        dispatch(resetCampusStats());
      }, 1000);
    }

    if (analyticsCampusStates.error) {
      toast.error(`😱 ${analyticsCampusStates.error}`);

      setTimeout(() => {
        dispatch(resetCampusStats());
      }, 1000);
    }
  }, [analyticsCampusStates]);

  return <Outlet></Outlet>;
};

const states = ({ analyticStore }) => {
  const { data: analyticsCampus, states: analyticsCampusStates } =
    analyticStore.getCampusStats;

  return {
    analyticsCampus,
    analyticsCampusStates,
  };
};

export default connect(states)(Component);
