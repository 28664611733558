import {
  NewAcademy,
  NEW_ACADEMY,
  ResetNewAcademy,
  RESET_NEW_ACADEMY,
  GET_ALL_ACADEMIES,
  GetAllAcademies,
  UpdateAcademy,
  UPDATE_ACADEMY,
  ResetUpdateAcademy,
  RESET_UPDATE_ACADEMY,
  GetAcademy,
  DeleteAcademy,
  DELETE_ACADEMY,
  GET_ACADEMY,
  RESET_DELETE_ACADEMY,
  NEW_INVITE_LINK,
  RESET_NEW_INVITE_LINK,
  DELETE_INVITE_LINK,
  RESET_DELETE_INVITE_LINK,
  SELECT_ACADEMY,
  UPDATE_ACADEMY_ROLE,
  DELETE_ACADEMY_MEMBER,
  DeleteAcademyMember,
  ACADEMY_ADD_VIEW,
  AddViewAcademy,
  ACADEMY_ADD_CLICK,
  ADD_ACADEMY_MEMBER,
  AddAcademyMember,
  ADD_RATING_ACADEMY,
  AddRatingAcademy,
  MANAGE_LIKE_DISLIKE_ACADEMY,
  GET_ACADEMIES_OFFSET,
  GetAcademiesOffset,
  ResetGetAcademiesOffset,
  RESET_GET_ACADEMIES_OFFSET,
  RESET_GET_ALL_ACADEMY_DATA,
  RESET_GET_ONE_ACADEMY_DATA,
} from "../types/academy";

const create = (payload): NewAcademy => {
  return { type: NEW_ACADEMY, payload };
};
const resetCreate = (): ResetNewAcademy => {
  return { type: RESET_NEW_ACADEMY, payload: null };
};

const update = (payload): UpdateAcademy => {
  return { type: UPDATE_ACADEMY, payload };
};

const offSet = (payload): GetAcademiesOffset => {
  return { type: GET_ACADEMIES_OFFSET, payload };
};

const resetOffset = (): ResetGetAcademiesOffset => {
  return { type: RESET_GET_ACADEMIES_OFFSET, payload: null };
};

const resetUpdate = (): ResetUpdateAcademy => {
  return { type: RESET_UPDATE_ACADEMY, payload: null };
};

const getAll = (payload): GetAllAcademies => {
  return { type: GET_ALL_ACADEMIES, payload: payload };
};

const getOne = (payload: { _id: string }): GetAcademy => {
  return { type: GET_ACADEMY, payload };
};

const deleteOne = (payload: { _id: string }): DeleteAcademy => {
  return { type: DELETE_ACADEMY, payload };
};

const resetDeleteOne = () => {
  return { type: RESET_DELETE_ACADEMY, payload: null };
};

const newLink = (payload) => {
  return { type: NEW_INVITE_LINK, payload };
};

const resetNewLink = () => {
  return { type: RESET_NEW_INVITE_LINK, payload: null };
};

const deleteLink = (payload) => {
  return { type: DELETE_INVITE_LINK, payload };
};

const resetDeleteLink = () => {
  return { type: RESET_DELETE_INVITE_LINK, payload: null };
};

const selectOne = (payload) => {
  return { type: SELECT_ACADEMY, payload };
};

const updateRole = (payload) => {
  return { type: UPDATE_ACADEMY_ROLE, payload };
};

const deleteMember = (payload): DeleteAcademyMember => {
  return { type: DELETE_ACADEMY_MEMBER, payload };
};

const addMember = (payload): AddAcademyMember => {
  return { type: ADD_ACADEMY_MEMBER, payload };
};

export const addView = (payload): AddViewAcademy => {
  return { type: ACADEMY_ADD_VIEW, payload };
};

const addRating = (payload): AddRatingAcademy => {
  return { type: ADD_RATING_ACADEMY, payload };
};

const manageLikeDislike = (payload) => {
  return { type: MANAGE_LIKE_DISLIKE_ACADEMY, payload };
};

const resetGetAllAcademies = () => {
  return { type: RESET_GET_ALL_ACADEMY_DATA, payload: null };
};

const resetGetOneAcademy = () => {
  return { type: RESET_GET_ONE_ACADEMY_DATA, payload: null };
};

export const addClick = (payload) => {
  return { type: ACADEMY_ADD_CLICK, payload };
};

const actions = {
  resetDeleteLink,
  deleteMember,
  updateRole,
  selectOne,
  deleteLink,
  newLink,
  resetNewLink,
  create,
  resetCreate,
  update,
  resetUpdate,
  resetDeleteOne,
  getAll,
  getOne,
  deleteOne,
  addMember,
  addRating,
  manageLikeDislike,
  offSet,
  resetOffset,
  resetGetAllAcademies,
  resetGetOneAcademy,
};

export default actions;
