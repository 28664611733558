import styled from "styled-components";

export const Row = styled("div")<{ gutter }>`
  ${(props) => {
    const { gutter: customGutter } = props;
    const { gutter: defaultGutter } = props.theme.grid;
    const { breakpoints } = props.theme;
    const gutter = customGutter >= 0 ? customGutter : defaultGutter;

    return `
      display: flex;
      flex-wrap: wrap;
      margin-right: -${gutter / 2}px;
      margin-left: -${gutter / 2}px;`;
  }}
`;
