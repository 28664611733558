import React from "react";
import CohortsCarousel from './components/CohortsCarousel'
import { FlexContainer } from "../../../../../components/StyledComponents";
import AvailableContentCarousel from "./components/AvailableContentCarousel";

interface ComponentProps { }

const Component = ({ }: ComponentProps) => {
  return (
    <FlexContainer direction="column" gap="8px">
      <CohortsCarousel />
      <div style={{ height: 20 }} />
      <AvailableContentCarousel />
    </FlexContainer>
  );
};



export default Component;
