import {
  initialState,
  Actions,
  State,
  GET_ALL_COMMENTS,
  GET_ALL_COMMENTS_ERROR,
  GET_ALL_COMMENTS_SUCCESS,
  NEW_COMMENT,
  NEW_COMMENT_ERROR,
  NEW_COMMENT_SUCCESS,
  RESET_NEW_COMMENT,
  UPDATE_COMMENT,
  UPDATE_COMMENT_ERROR,
  UPDATE_COMMENT_SUCCESS,
  RESET_UPDATE_COMMENT,
  DELETE_COMMENT,
  DELETE_COMMENT_ERROR,
  DELETE_COMMENT_SUCCESS,
  RESET_DELETE_COMMENT,
  UPDATE_CURRENT_COMMENT_LIST,
  MANAGE_LIKE_DISLIKE_COMMENT,
  RESET_GET_ALL_COMMENT_DATA,
  GET_REPLIES,
  GET_REPLIES_SUCCESS,
  GET_REPLIES_ERROR,
  RESET_GET_REPLIES,
  RESET_GET_REPLIES_DATA,
} from "../types/comment";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_COMMENTS:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_COMMENTS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_COMMENTS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    /*  case RESET_GET_ONE_CDOC_DATA:
      return {
        ...state,
        : initialState.,
      }; */
    case RESET_GET_ALL_COMMENT_DATA:
      return {
        ...state,
        all: initialState.all,
      };

    case GET_REPLIES:
      return {
        ...state,
        replies: {
          data: null,
          states: {
            id: action.payload._id,
            data: [...state.replies.states.data, action.payload._id],
            success: false,
            error: false,
            loading: true,
          },
        },
      };
    case GET_REPLIES_SUCCESS:
      return {
        ...state,
        replies: {
          data: action.payload.replies,
          states: {
            ...state.replies.states,
            data: state.replies.states.data.filter(
              (id) => id !== action.payload.queueId
            ),
            success: true,
            error: false,
            loading: false,
          },
        },
      };
    case GET_REPLIES_ERROR:
      return {
        ...state,
        replies: {
          ...state.replies,
          states: {
            data: state.replies.states.data.filter(
              (id) => id !== action.payload.queueId
            ),
            id: null,
            success: false,
            error: action.payload.error,
            loading: false,
          },
        },
      };
    case RESET_GET_REPLIES:
      return {
        ...state,
        replies: {
          ...state.replies,
          states: {
            ...state.replies.states,
            success: false,
            error: false,
            loading: false,
          },
        },
      };
    case RESET_GET_REPLIES_DATA:
      return {
        ...state,
        replies: initialState.replies,
      };
    case MANAGE_LIKE_DISLIKE_COMMENT:
      return {
        ...state,
        update: {
          ...state.update,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_COMMENT:
      return {
        ...state,
        create: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_COMMENT_SUCCESS:
      return {
        ...state,
        create: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };

    case NEW_COMMENT_ERROR:
      return {
        ...state,
        create: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_COMMENT:
      return {
        ...state,
        create: initialState.create,
      };
    case DELETE_COMMENT:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_COMMENT_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_COMMENT:
      return {
        ...state,
        delete: initialState.delete,
      };

    case UPDATE_CURRENT_COMMENT_LIST:
      return {
        ...state,
        all: {
          ...state.all,
          data: action.payload,
        },
      };
    case UPDATE_COMMENT:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_COMMENT_SUCCESS:
      return {
        ...state,
        update: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_COMMENT_ERROR:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_COMMENT:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
