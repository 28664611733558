import action from "../helpers/rest-client";

export class ErrorConfirmation extends Error {}

class Confirmation {
  public update({ _id, condition }) {
    return action
      .Patch({
        url: `/invitations/${condition}?token=${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne(_id) {
    return action
      .Get({
        url: `/invitations/byToken/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const confirmation = new Confirmation();
export default confirmation;
