import React from "react";
import { AiFillGithub, AiFillLinkedin, AiOutlineMail } from "react-icons/ai";
import { FiSmartphone, FiEdit } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { TbWorld } from "react-icons/tb";
import { Logo } from "../../../../../components/Header/styles";
import { DataText, LinkText } from "../../../../../components/Profile2/style";
import {
  FlexContainer,
  Separator,
} from "../../../../../components/StyledComponents";
import { IconCard, Title } from "../../../../Dashboard/Profile/styles";
import { Card } from "./style";
import Entity from "../../../../../assets/images/team-icon-24 copy.png";
import { connect, useDispatch } from "react-redux";
import ModalEditAcademyProfile from "./components/ModalEditAcademyProfile";
import { showModal } from "../../../../../store/actions/modal";
import { useWindowSize } from "../../../../../hooks/useWindowScreen";
import { MdEmail } from "react-icons/md";
import { BsFillPhoneFill, BsLink45Deg } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";

const Component = ({ selectedAcademy, user }) => {
  const { isMobile } = useWindowSize();
  return (
    <>
      <Title stlye={{ fontSize: "16px" }}>Datos de Contacto</Title>

      {/*   {selectedAcademy.users.find(
          (userF) => userF.user === user._id && userF.role === "ADMIN"
        ) && (
          <IconCard
            onClick={() => {
              dispatch(showModal("edit-profile-contact"));
            }}
            style={{ top: "8px", right: "8px" }}
          >
            <FiEdit fontSize={20}></FiEdit>
          </IconCard>
        )} */}
      <Separator size={20}></Separator>
      <FlexContainer
        style={{ wordBreak: "break-all" }}
        direction="column"
        gap="16px"
      >
        <FlexContainer align="center" gap="8px">
          <MdEmail fontSize={20}></MdEmail>
          <FlexContainer align="center" gap="6px">
            <DataText>Email:</DataText>
            <DataText style={{ color: "#6a6970", wordBreak: "break-all" }}>
              {selectedAcademy.email}
            </DataText>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer align="center" gap="8px">
          <BsFillPhoneFill fontSize={20}></BsFillPhoneFill>
          <FlexContainer align="center" gap="6px">
            <DataText>Telefono:</DataText>
            <DataText style={{ color: "#6a6970", wordBreak: "break-all" }}>
              {selectedAcademy.phone}
            </DataText>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer align="center" gap="8px">
          <HiLocationMarker fontSize={20}></HiLocationMarker>
          <FlexContainer align="center" gap="6px">
            <DataText>Ubicacion</DataText>
          </FlexContainer>
          <DataText style={{ color: "#6a6970", wordBreak: "break-all" }}>
            {selectedAcademy.location}
          </DataText>
        </FlexContainer>

        <FlexContainer align="center" gap="8px">
          <BsLink45Deg fontSize={20}></BsLink45Deg>
          <FlexContainer align="center" gap="6px">
            <DataText>Web:</DataText>
            <LinkText
              style={{ wordBreak: "break-all" }}
              href={selectedAcademy.website}
              target="_blank"
            >
              {selectedAcademy.website}
            </LinkText>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

const states = ({ academyStore, userStore }) => {
  const { data: selectedAcademy } = academyStore.selected;
  const { data: user } = userStore;
  return {
    selectedAcademy,
    user,
  };
};

export default connect(states)(Component);
