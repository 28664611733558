import auth from "./auth";
import meet from "./meet";
import category from "./category";
import cohort from "./cohort";
import technology from "./technology";
import ability from "./ability";
import event from "./event";
import user from "./user";
import mentor from "./mentor";
import student from "./student";
import academy from "./academy";
import users from "./users";
import talent from "./talent";
import analytics from "./analytics";
import content from "./content";
import request from "./request";
import certificate from "./certificate";
import invitation from "./invitation";
import unit from "./unit";
import notification from "./notification";
import cdoc from "./cdoc";
import video from "./video";
import confirmation from "./confirmation";
import confirmationAuth from "./confirmationAuth";
import roadmap from "./roadmap";
import image from "./image";
import slideshow from "./slideshow";
import suscription from "./suscription";
import comment from "./comment";
import listen from "./listen";
import generalContent from "./generalContent";
import byte from "./byte";
import tag from "./tag";
const services = {
  auth,
  unit,
  video,
  listen,
  analytics,
  cdoc,
  notification,
  generalContent,
  tag,
  confirmation,
  slideshow,
  suscription,
  byte,
  comment,
  image,
  roadmap,
  confirmationAuth,
  invitation,
  certificate,
  content,
  request,
  talent,
  academy,
  users,
  meet,
  ability,
  category,
  cohort,
  technology,
  user,
  event,
  mentor,
  student,
};

export default services;
