import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center } from "../../../../components/StyledComponents";
import Input from "../../../../components/Form/Input";
import Submit from "../../../../components/Form/Submit";
import { initialValues, schema } from "../../../../constants/form/category/new";
import { CategoryDto } from "../../../../types/category.dto";
import Button from "../../../../components/Button";
import { hideModal } from "../../../../store/actions/modal";
import {
  resetUpdateCategory,
  updateCategory,
} from "../../../../store/actions/category";

interface ComponentProps {
  category: CategoryDto;
  updateCategoryStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ category, updateCategoryStates }: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(updateCategory(values));
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  useEffect(() => {
    if (updateCategoryStates.success) {
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetUpdateCategory());
      }, 2000);
    }
  }, [updateCategoryStates]);

  return (
    <Formik
      initialValues={{ ...initialValues, ...category }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validateOnChange={formSubmmited}
      validateOnBlur={false}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <form
            className="theme-form"
            onSubmit={(event) => {
              setFormSubmmited(true);
              handleSubmit(event);
            }}
            id="edit-event-form"
          >
            <Input
              name="name"
              error={errors["name"]}
              touched={touched["name"]}
              value={values["name"]}
              type="text"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Nombre de la categoria",
                marginBottom: 20,
              }}
            />

            <Center>
              <Submit
                isSubmmiting={isSubmitting}
                form="edit-event-form"
                color="Primary"
                options={{
                  type: "filled",
                  skin: "primary",
                  size: "lg",
                  marginBottom: "0px",
                }}
              >
                Confirmar
              </Submit>
              <Button
                onClick={closeModal}
                type="button"
                options={{
                  type: "outline",
                  skin: "danger",
                  size: "lg",
                  marginBottom: "0px",
                }}
                style={{ marginLeft: "10px" }}
              >
                Cancelar
              </Button>
            </Center>
          </form>
        );
      }}
    </Formik>
  );
};

const states = ({ categoryStore }) => {
  const { data: category } = categoryStore.category;
  const { states: updateCategoryStates } = categoryStore.updateCategory;

  return {
    category,
    updateCategoryStates,
  };
};

export default connect(states)(Component);
