import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  GET_MENTOR,
  GET_MENTOR_SUCCESS,
  GET_MENTOR_ERROR,
  GET_ALL_MENTORS,
  GET_ALL_MENTORS_SUCCESS,
  GET_ALL_MENTORS_ERROR,
  NEW_MENTOR,
  NEW_MENTOR_ERROR,
  NEW_MENTOR_SUCCESS,
  UPDATE_MENTOR,
  UPDATE_MENTOR_SUCCESS,
  UPDATE_MENTOR_ERROR,
  DELETE_MENTOR,
  DELETE_MENTOR_SUCCESS,
  DELETE_MENTOR_ERROR,
  GetMentor,
  GetAllMentors,
  NewMentor,
  UpdateMentor,
  DeleteMentor,
} from "../types/mentor";

function* getAllMentors() {
  const { response, error } = yield call(services.mentor.getAll);

  if (error) {
    yield put({
      type: GET_ALL_MENTORS_ERROR,
      payload: error,
    });
  } else {
    yield put({ type: GET_ALL_MENTORS_SUCCESS, payload: response });
  }
}

function* getMentor({ payload }) {
  const { response, error } = yield call(services.mentor.getOne, payload);

  if (error) {
    yield put({
      type: GET_MENTOR_ERROR,
      payload: error,
    });
  } else {
    yield put({ type: GET_MENTOR_SUCCESS, payload: response });
  }
}

function* newMentor({ payload }) {
  const { response, error } = yield call(services.event.new, payload);

  if (error) {
    yield put({
      type: NEW_MENTOR_ERROR,
      payload: error,
    });
  } else {
    yield all([
      put({ type: NEW_MENTOR_SUCCESS, payload: response }),
      put({ type: GET_ALL_MENTORS, payload: null }),
    ]);
  }
}

function* updateMentor({ payload }) {
  const { response, error } = yield call(services.mentor.update, payload);

  if (error) {
    yield put({
      type: UPDATE_MENTOR_ERROR,
      payload: error,
    });
  } else {
    yield all([
      put({ type: UPDATE_MENTOR_SUCCESS, payload: response }),
      put({ type: GET_ALL_MENTORS, payload: null }),
    ]);
  }
}

function* deleteMentor({ payload }) {
  const { response, error } = yield call(services.mentor.delete, payload);

  if (error) {
    yield put({
      type: DELETE_MENTOR_ERROR,
      payload: error,
    });
  } else {
    yield all([
      put({ type: DELETE_MENTOR_SUCCESS, payload: response }),
      put({ type: GET_ALL_MENTORS, payload: null }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllMentors>(GET_ALL_MENTORS, getAllMentors);
  yield takeLatest<GetMentor>(GET_MENTOR, getMentor);
  yield takeLatest<NewMentor>(NEW_MENTOR, newMentor);
  yield takeLatest<UpdateMentor>(UPDATE_MENTOR, updateMentor);
  yield takeLatest<DeleteMentor>(DELETE_MENTOR, deleteMentor);
}
