import {
  initialState,
  Actions,
  State,
  GET_ALL_ROADMAPS,
  GET_ALL_ROADMAPS_ERROR,
  GET_ALL_ROADMAPS_SUCCESS,
  GET_ROADMAP,
  GET_ROADMAP_ERROR,
  GET_ROADMAP_SUCCESS,
  NEW_ROADMAP,
  NEW_ROADMAP_ERROR,
  NEW_ROADMAP_SUCCESS,
  RESET_NEW_ROADMAP,
  UPDATE_ROADMAP,
  UPDATE_ROADMAP_ERROR,
  UPDATE_ROADMAP_SUCCESS,
  RESET_UPDATE_ROADMAP,
  DELETE_ROADMAP,
  DELETE_ROADMAP_ERROR,
  DELETE_ROADMAP_SUCCESS,
  RESET_DELETE_ROADMAP,
  UPLOAD_MEDIA,
  UPDATE_ROADMAP_LOADING,
  RESET_GET_ROADMAP,
  ADD_USER_TO_ROADMAP,
  SHARED_ROADMAP,
  SET_GET_ROADMAP,
  UNSAVED_ROADMAP,
  SAVED_ROADMAP,
  QUERY_ROADMAP,
  QUERY_ROADMAP_SUCCESS,
  QUERY_ROADMAP_ERROR,
  RESET_QUERY_ROADMAP,
  UPDATE_CURRENT_ROADMAP_LIST,
  ADD_VIEW_ROADMAP,
  UPDATE_ONE_ROADMAP,
  UPDATE_ONE_ROADMAP_ERROR,
  UPDATE_ONE_ROADMAP_SUCCESS,
  RESET_UPDATE_ONE_ROADMAP,
  MANAGE_LIKE_DISLIKE_ROADMAP,
  GET_ROADMAPS_OFFSET,
  GET_ROADMAPS_OFFSET_ERROR,
  GET_ROADMAPS_OFFSET_SUCCESS,
  RESET_GET_ROADMAPS_OFFSET,
  RESET_GET_ONE_ROADMAP_DATA,
  RESET_GET_ALL_ROADMAP_DATA,
  UPDATE_ITEM_LIST_ROADMAP,
} from "../types/roadmap";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_ROADMAPS:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_ROADMAPS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };

    case RESET_GET_ONE_ROADMAP_DATA:
      return {
        ...state,
        roadmap: initialState.roadmap,
      };
    case RESET_GET_ALL_ROADMAP_DATA:
      return {
        ...state,
        all: initialState.all,
      };

    case UPDATE_ITEM_LIST_ROADMAP:
      return {
        ...state,
        update: {
          states: {
            success: false,
            error: false,
            loading: true,
          },
        },
      };
    case UPDATE_ONE_ROADMAP:
      return {
        ...state,
        updateOne: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_ONE_ROADMAP_ERROR:
      return {
        ...state,
        updateOne: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case UPDATE_ONE_ROADMAP_SUCCESS:
      return {
        ...state,
        updateOne: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case RESET_UPDATE_ONE_ROADMAP:
      return {
        ...state,
        updateOne: {
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case MANAGE_LIKE_DISLIKE_ROADMAP:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case QUERY_ROADMAP:
      return {
        ...state,
        query: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case QUERY_ROADMAP_SUCCESS:
      return {
        ...state,
        query: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case QUERY_ROADMAP_ERROR:
      return {
        ...state,
        query: {
          data: state.query.data,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_QUERY_ROADMAP:
      return {
        ...state,
        query: {
          ...state.query,
          states: initialState.query.states,
        },
      };
    case GET_ROADMAPS_OFFSET:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case GET_ROADMAPS_OFFSET_ERROR:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case GET_ROADMAPS_OFFSET_SUCCESS:
      return {
        ...state,
        offSet: {
          data: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case RESET_GET_ROADMAPS_OFFSET:
      return {
        ...state,
        offSet: {
          ...state.offSet,
          states: {
            loading: false,
            success: false,
            error: false,
          },
        },
      };
    case ADD_VIEW_ROADMAP:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_CURRENT_ROADMAP_LIST:
      return {
        ...state,
        all: {
          ...state.all,
          data: action.payload,
        },
      };
    case SAVED_ROADMAP:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_ROADMAPS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };

    case SAVED_ROADMAP:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UNSAVED_ROADMAP:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPLOAD_MEDIA:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case ADD_USER_TO_ROADMAP:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SHARED_ROADMAP:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_ROADMAP_LOADING:
      return {
        ...state,
        new: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ROADMAP:
      return {
        ...state,
        roadmap: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ROADMAP_SUCCESS:
      return {
        ...state,
        roadmap: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ROADMAP_ERROR:
      return {
        ...state,
        roadmap: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_ROADMAP:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_ROADMAP_SUCCESS:
      return {
        ...state,
        new: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case SET_GET_ROADMAP:
      return {
        ...state,
        roadmap: {
          ...state.roadmap,
          data: !!action.payload ? action.payload : null,
        },
      };
    case RESET_GET_ROADMAP:
      return {
        ...state,
        roadmap: {
          ...state.roadmap,
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case NEW_ROADMAP_ERROR:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_ROADMAP:
      return {
        ...state,
        new: {
          data: state.new.data,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_ROADMAP:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_ROADMAP_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_ROADMAP_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_ROADMAP:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_ROADMAP:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_ROADMAP_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_ROADMAP_ERROR:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_ROADMAP:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
