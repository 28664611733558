import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Share from "../../../../components/ShareLink";
import { toast } from "react-toastify";
import Grid from "../../../../components/Grid";
import {
  GridCardImage,
  GridCardImageContainer,
  GridCardSmallContainer,
  GridCardTitle,
  GridComment,
  GridStructure,
} from "../../../../components/Layout/Dashboard/styles";
import Loader from "../../../../components/Loader";
import {
  Center,
  FlexContainer,
  PageTitle,
  Separator,
} from "../../../../components/StyledComponents";
import { DateFromNow } from "../../../../helpers/format-date";

import {
  getAllCohorts,
  queryCohort,
  resetGetAllCohorts,
} from "../../../../store/actions/cohort";

import { StateDto } from "../../../../types/states.dto";
import { CohortDto } from "../../../../types/cohort.dto";
import FilterButton from "./components/FilterButton";
import FilterModal from "./components/FilterModal";
import { DotContent, ItemDataText } from "./style";
import { UserDto } from "../../../../types/user.dto";
import FilterByName from "./components/FilterByName";
import { useNavigate, useSearchParams } from "react-router-dom";
import InfinityScroll from "../../../../components/InfinityScroll";
import { AiFillLock } from "react-icons/ai";
import { addClick } from "../../../../store/actions/userTimes";
import { ContentCardWrapper } from "../../../../components/ContentCard/style";
import Skeleton from "../../../../components/Skeleton";

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

/* const filterValues: any = [
  {
    fieldName: ["visibility", "order"],
    value: "no",
    name: "Todos",
  },
  {
    fieldName: "order",
    value: "recent",
    name: "Recientes",
    groupal: (array: CDocDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "order",
    value: "older",
    name: "Antiguos",
    groupal: (array: CDocDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "published",
    value: true,
    name: "Publicos",
  },
  {
    fieldName: "published",
    value: false,
    name: "Privados",
  },
]; */

const Component = ({
  cohorts,
  user,
  cohortsStates,
}: /*   queryCohorts,
  queryCohortsStates, */
{
  /*  newCohortData: CDocDto; */
  cohorts: CohortDto[];
  user: UserDto;
  cohortsStates: StateDto;
  /*  queryCohorts: CohortDto[];
    queryCohortsStates: StateDto; */
  /*  queryCohorts: CDocDto[]; */
  /* queryCohortsStates: StateDto; */
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const filterValues: any = [
    /* {
      fieldName: "private",
      value: "no",
      name: "Todos",
    },
    {
      fieldName: "private",
      value: true,
      name: "Academias Privadas",
      special: (array, value, currentItem) => {
        return currentItem.academy.private === value;
      },
    },
    {
      fieldName: "private",
      value: false,
      name: "Academias Publicas",
      special: (array, value, currentItem) => {
        return currentItem.academy.private === value;
      },
    }, */
  ];
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [query, setQuery] = useState<string>();
  const [currentCohortsList, setCurrentCohortsList] = useState<any[]>([]);
  const [
    filteredCurrentCohortsListValues,
    setFilteredCurrentCohortsListValues,
  ] = useState<CohortDto[]>([]);

  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 9,
      offset: 9 * page,
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        hidden: false,
      },
    };
    const sortBy = "createdAt";
    const select = {
      _id: 1,
      picture: 1,
      academy: 1,
      name: 1,
      viewCount: 1,
      createdAt: 1,
    };
    const sortDirection = "desc";
    dispatch(getAllCohorts({ ...payload, sortBy, sortDirection, select }));
  };

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter((applyedFilter) =>
        !Array.isArray(newFilter.field)
          ? applyedFilter.field === newFilter.field
          : newFilter.field.includes(applyedFilter.field)
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    if (filterRemoved) {
      /**
       * Se elimina el filtro
       */
      if (Array.isArray(newFilter.field)) {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => !newFilter.field.includes(applyedFilter.field)
          ),
        ]);
      } else {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => applyedFilter.field !== newFilter.field
          ),
        ]);
      }
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  useEffect(() => {
    if (searchParams.get("filter") && searchParams.get("filterValue")) {
      buildFilters({
        field: searchParams.get("filter"),
        value: searchParams.get("filterValue") === "true" ? true : false,
        special: (array, value, currentItem) => {
          return currentItem.academy.private === value;
        },
      });
    }
  }, [searchParams]);

  /*  useEffect(() => {
    if (
      !!cohorts &&
      !!academies &&
      !queryCohorts &&
      !queryCohortsStates.loading
    ) {
      console.log("cuidado 1");
      const filterCdocs = cohorts.filter(
        (cohort) => !!cohort.active && !cohort.hidden
      );

      setCurrentCohortsList(filterCdocs);
    }
    if (!!cohorts && !!academies && !!queryCohorts) {
      console.log("cuidado 2");
      const filterCdocs = queryCohorts.filter(
        (cohort) => !!cohort.active && !cohort.hidden
      );

      setCurrentCohortsList(filterCdocs);
    }
  }, [cohorts, academies, queryCohorts, queryCohortsStates.loading]); */

  useEffect(() => {
    if (!isLoading && !!cohorts) {
      setCurrentCohortsList((state) => [...state, ...cohorts]);
    }
    setThereAreItems(!!cohorts && cohorts?.length > 0);
  }, [cohorts, isLoading]);

  useEffect(() => {
    setIsLoading(cohortsStates.loading);
  }, [cohortsStates]);

  useEffect(() => {
    const filteredTalents = currentCohortsList?.filter(
      (currentTalentValues) => {
        const thereAreFilters =
          applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
            .length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter(
            (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
          )
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value,
            currentTalentValues
          );
        });
        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentCohortsListValues(initResult);
  }, [applyedFilters, currentCohortsList]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohorts());
    };
  }, []);

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <PageTitle>Cohorts</PageTitle>
          </Grid.Col>
        </Grid.Row>
        <Center>
          <Grid.Row style={{ width: "100%" }}>
            <Grid.Col lg={12} md={12} sm={12} style={{ marginBottom: "14px" }}>
              <FlexContainer
                style={{ width: "100%", maxWidth: "700px", margin: "0 auto" }}
              >
                <FilterByName
                  searchQuery={{}}
                  setFilter={buildFilters}
                  value={{}}
                />
              </FlexContainer>
            </Grid.Col>
          </Grid.Row>
        </Center>

        <Separator size={12}></Separator>

        <Grid.Row>
          <Grid.Col>
            <InfinityScroll
              action={fetchData}
              page={page}
              setPage={setPage}
              data={cohorts}
              active={thereAreItems}
              isLoading={isLoading}
              loader={
                <Skeleton
                  itemsPerPage={9}
                  itemsPerRow={3}
                  animated
                  size={{ height: 290, width: 276 }}
                  icon="cohort"
                />
              }
            >
              <GridStructure
                width={"275px"}
                maxWidth="auto"
                style={{ width: "100%", margin: "0 auto" }}
                limitCard={"275px"}
              >
                {filteredCurrentCohortsListValues.map((item) => {
                  return (
                    <ContentCardWrapper
                      onClick={(ev) => {
                        ev.stopPropagation();
                        dispatch(
                          addClick({
                            id: item._id,
                            user: user._id,
                            type: "COHORT",
                          })
                        );
                        navigate(`/dashboard/cohort/${item._id}/home`);
                      }}
                      pd={"15px"}
                      key={item._id}
                    >
                      {!!item.private && (
                        <div style={{ position: "absolute", top: 0, right: 0 }}>
                          <AiFillLock size={20}></AiFillLock>
                        </div>
                      )}
                      <GridCardImageContainer>
                        <GridCardImage src={item.picture || ""}></GridCardImage>
                      </GridCardImageContainer>
                      <FlexContainer gap="12px">
                        <GridCardSmallContainer
                          onClick={(ev) => {
                            ev.stopPropagation();

                            dispatch(
                              addClick({
                                id: item.academy._id,
                                user: user._id,
                                type: "ACADEMY",
                              })
                            );
                            navigate(`/dashboard/academy/${item.academy._id}`);
                          }}
                        >
                          <GridCardImage
                            src={item.academy.picture}
                          ></GridCardImage>
                        </GridCardSmallContainer>
                        <FlexContainer direction="column" gap="1px">
                          <GridCardTitle>{item.name}</GridCardTitle>
                          <FlexContainer direction="column">
                            <FlexContainer align="center" gap="5px">
                              <GridComment>{item.academy.name}</GridComment>
                            </FlexContainer>

                            <FlexContainer
                              gap="10px"
                              align="center"
                              wrap="wrap"
                            >
                              <FlexContainer align="center" gap="5px">
                                <DotContent></DotContent>
                                <FlexContainer align="center" gap="2px">
                                  <GridComment>{`Vistas: `}</GridComment>
                                  <ItemDataText>{item.viewCount}</ItemDataText>
                                </FlexContainer>
                              </FlexContainer>
                              <FlexContainer align="center" gap="5px">
                                <DotContent></DotContent>
                                <FlexContainer align="center" gap="2px">
                                  <ItemDataText>Creacion:</ItemDataText>
                                  <GridComment>
                                    {`Hace ${DateFromNow(item.createdAt)}`}
                                  </GridComment>
                                </FlexContainer>
                              </FlexContainer>
                            </FlexContainer>
                          </FlexContainer>
                        </FlexContainer>
                      </FlexContainer>
                    </ContentCardWrapper>
                  );
                })}
              </GridStructure>
            </InfinityScroll>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      {!!cohorts && <FilterModal buildFilters={buildFilters}></FilterModal>}
    </>
  );
};

const states = ({ cohortStore, academyStore, userStore }) => {
  const { data: cohorts, states: cohortsStates } = cohortStore.allCohorts;
  const { data: queryCohorts, states: queryCohortsStates } =
    cohortStore.queryCohort;
  const { data: academies } = academyStore.all;
  const { data: user } = userStore;
  return {
    cohorts,
    /* academies, */
    user,
    cohortsStates,
    /*   queryCohorts,
    queryCohortsStates, */
  };
};

export default connect(states)(Component);
