/**
 *  Colors
 */

const Blue = "#1573e5";
const LightBlue = "#2496ed";
const LightBlueHover = "#E9F4FD";
const LightBlueIcon = "#1a73e8";
const LightGray = "#f5f5f5";
const Gray = "#697482";
const DarkGray = "#707070";

const Danger = "#f76564";
const Warning = "#f3dd53";
const Success = "#26ab6d";
const Info = "#52ddfc";

const Primary = Blue;

const FuryRed = "#ff2020";

const Black = "#2D3436";
const Black24 = "#C2C2C2";

const White = "#FFFFFF";

const Transparent = "rgba(255,255,255,0)";

const Night = "#273241";

const GrayText = "#6A6970";

const DarkNight = "#051725";

const Violet = "#7b68ef";
const LightViolet = "#CF9FFF";

export const colors = {
  Black,
  Black24,
  White,
  Transparent,
  Blue,
  LightBlue,
  LightGray,
  Violet,
  Gray,
  DarkGray,
  FuryRed,
  Danger,
  Warning,
  Success,
  Info,
  Primary,
  LightViolet,
  LightBlueHover,
  Night,
  LightBlueIcon,
  GrayText,
  DarkNight,
};
