import React from "react";
import { Transition } from "react-transition-group";
import {
  MobileContentModal,
  MobileContentModalBody,
  MobileContentModalContent,
  MobileContentModalHeader,
  MobileContentModalHeaderTitle,
} from "./style";
import { FlexContainer } from "../StyledComponents";
import { AiOutlineClose } from "react-icons/ai";
import { useWindowSize } from "../../hooks/useWindowScreen";

const Component = ({
  title,
  setIsVisible,
  Icon,
  isVisible,
  onClose,
  children,
  iconAction,
}: {
  title: string;
  setIsVisible: any;
  Icon?: any;
  isVisible: boolean;
  onClose?: any;
  children;
  iconAction?: any;
}) => {
  const { isMobile } = useWindowSize();
  return (
    <Transition in={isVisible} timeout={350} mountOnEnter>
      {(state) => (
        <MobileContentModal
          name={"fadeInSlideUp"}
          className={`animation-${state}`}
          duration={0.2}
          {...(!isMobile && { style: { bottom: "0px" } })}
        >
          <MobileContentModalBody>
            <MobileContentModalHeader>
              <FlexContainer justify="space-between" align="center">
                <FlexContainer align="center" gap="8.3px">
                  {Icon && (
                    <div
                      onClick={() => {
                        !!iconAction && iconAction();
                      }}
                    >
                      <Icon size={18} color={"#6a6970"}></Icon>
                    </div>
                  )}
                  <MobileContentModalHeaderTitle>
                    {title}
                  </MobileContentModalHeaderTitle>
                </FlexContainer>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (onClose) onClose();
                    setIsVisible(false);
                  }}
                >
                  <AiOutlineClose size={16} color="#6a6970"></AiOutlineClose>
                </div>
              </FlexContainer>
            </MobileContentModalHeader>
            <MobileContentModalContent>{children}</MobileContentModalContent>
          </MobileContentModalBody>
        </MobileContentModal>
      )}
    </Transition>
  );
};

export default Component;
