import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FlexContainer } from "../../../../../components/StyledComponents";
import { GeneralContentDto } from "../../../../../types/generalContent.dto";
import Carousel from "../../../../../components/AsynCarousel";
import { WrapperSlide } from "../../../../../components/Slider/style";
import { getAllGeneralContents } from "../../../../../store/actions/generalContent";
import { useWindowSize } from "../../../../../hooks/useWindowScreen";
import { useEffect, useState } from "react";
import { BannerTitle } from "../../../../Academy/components/Banner/style";
import { CohortDto } from "../../../../../types/cohort.dto";
import { UserDto } from "../../../../../types/user.dto";
import MediaItem from "../../../../Dashboard/Home/components/MediaItems/components/MediaItem";
import { getContentIcon } from "../../../../../helpers/getContentIcon";
import { getPathContent } from "../../../../../helpers/getPathContent";
const DEFAULT_IMAGE =
  "https://repository-images.githubusercontent.com/410214337/070f2aba-d9d6-4699-b887-9a0f29015b1b";
const ELEMENTS_PER_PAGE = 3;

interface FetchProps {
  page: number;
  filterBy?: { [key: string]: string | number | boolean | null };
}

interface ComponentProps {
  generalContents: GeneralContentDto[];
  generalContentsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  cohortSelected: CohortDto;
  user: UserDto;
}

const Component = ({
  generalContents,
  generalContentsStates,
  cohortSelected,
  user,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [items, setItems] = useState<GeneralContentDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(generalContentsStates.loading);
  }, [generalContentsStates.loading]);

  const getCohorts = ({ page, filterBy }: FetchProps) => {
    dispatch(
      getAllGeneralContents({
        page,
        limit: ELEMENTS_PER_PAGE,
        offset: ELEMENTS_PER_PAGE * page,
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          published: true,
          available: true,
          cohorts: cohortSelected?._id,
        },
      })
    );
  };

  useEffect(() => {
    if (!isLoading && !!generalContents) {
      setItems((state) => [...state, ...generalContents]);

      setThereAreItems(
        !!generalContents &&
          generalContents?.length > 0 &&
          generalContents?.length === ELEMENTS_PER_PAGE
      );
    }
  }, [generalContents, isLoading]);

  return (
    <FlexContainer direction="column" gap="8px">
      <BannerTitle size="16px">Contenidos Publicos</BannerTitle>
      <Carousel
        data={items}
        isLoading={isLoading}
        active={thereAreItems}
        action={getCohorts}
        setPage={setPage}
        page={page}
      >
        {items.map((generalContent) => (
          <WrapperSlide>
            <MediaItem
              cardStyle={{
                maxWidthCard: "278px",
                cardImageHeight: "150px",
              }}
              size={"lg"}
              onClick={() => {
                navigate(getPathContent(generalContent));
              }}
              item={{ ...generalContent, icon: getContentIcon(generalContent) }}
              logoOnclick={() => {
                navigate(`/dashboard/academy/${generalContent.academy._id}`);
              }}
            ></MediaItem>
          </WrapperSlide>
        ))}
      </Carousel>
    </FlexContainer>
  );
};

const states = ({ generalContentStore, cohortStore, userStore }) => {
  const { data: generalContents, states: generalContentsStates } =
    generalContentStore.all;
  const { data: cohortSelected } = cohortStore.cohort;
  const { data: user } = userStore;
  return {
    generalContents,
    generalContentsStates,
    cohortSelected,
    user,
  };
};

export default connect(states)(Component);
