import styled from "styled-components";

export const LogoSmallItem = styled("div")`
  ${(props) => {
    return `
    width:${props.size};
    height:${props.size};
    border-radius:10px;
    overflow:hidden;
    `;
  }}}
`;

export const BottomLabel = styled("div")`
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 2px;
  border-radius: 5px;
  font-size: 12px;
  background-color: rgba(5, 23, 37, 0.6);
  color: ${(props) => props.theme.colors.White};
`;

export const LogoMdCard = styled("div")`
  width: 34px;
  background: white;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 4px 0 rgba(36, 150, 237, 0.25);
  margin-top: -20px;
  z-index: 1;

  display: block;
`;
export const TitleMdCard = styled("h2")`
  color: #051725;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Ajusta esto al número deseado de líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 12px;
  font-weight: bold;
`;

export const SubTextMdCard = styled("span")`
  font-size: 10px;
  font-weight: ${(props) => props.weight ?? 500};
  ${(props) => (props.dark ? `color:#051725;` : `color:#6a6970;`)}
`;

export const TagsLabel = styled("div")`
  padding: 1px 8.5px;
  border-radius: 5px;
  background-color: rgba(36, 150, 237, 0.15);
  font-size: 10px;
  color: #6a6970;
`;

export const LogoWrapper = styled("div")`
  width: 34.1px;
  height: 34.1px;
  display: flex;
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: #051725;
`;

export const TextSmallItem = styled("h3")`
  font-size: 14px;
  font-weight: 500;
  color: #2a2747;
  text-align: center;
  word-break: break-word;
`;

export const DescriptionSmallItem = styled("h5")`
  font-size: 10px;
  font-weight: 500;
  text-align: center;
`;
