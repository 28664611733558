import { FaHome, FaPlay } from "react-icons/fa";
import { HiPresentationChartBar, HiUser } from "react-icons/hi";
import ByteTrayced from "../assets/icons/byte-traced.svg";
import VideoTrayced from "../assets/icons/video-traced.svg";
import { RiSlideshow3Fill, RiVideoAddFill } from "react-icons/ri";

export const menuMobileItems = [
  {
    link: "/home",
    title: "Home",
    icon: FaHome,
  },
  {
    link: "/bytes",
    title: "Bytes",
    icon: ByteTrayced,
    isImage: true,
  },
  /*  {
    link: "/cdocs",
    title: "CDocs",
    icon: HiOutlineDocumentText,
  }, */
  /*  {
    link: "/roadmaps",
    title: "Trayectos",
    icon: RiOrganizationChart,
  }, */
  {
    link: "/videos",
    title: "Videos",
    icon: VideoTrayced,
    isImage: true,
  },
  /* {
    link: "/listens",
    title: "Listens",
    icon: MdAudiotrack,
  }, */
  {
    link: "/slideshows",
    title: "SlideShows",
    icon: RiSlideshow3Fill,
  },

  {
    link: "/personal",
    title: "Personal",
    icon: HiUser,
  },
];
