import styled from "styled-components";

export const ChartContainer = styled("div")`
  margin-bottom: 20px;
`;

export const IndividualCard = styled("div")`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(79, 38, 162, 0.16);
  background: #fff;
  padding: 10px;
  max-width: none;
`;

export const GrowCard = styled(IndividualCard)`
  flex-direction: column;
  padding: 20px;
  height: 234px;
  @media (max-width: 1023px) {
    margin-bottom: 10px;
  }
`;

export const SoloGraph = styled("div")`
  padding: 20px;
  height: 234px;
  margin: 0 0 0 9px;
`;
export const NoteCard = styled(GrowCard)`
  height: ${(props) => props.height || "auto"};
`;

export const CriterionCard = styled(NoteCard)`
  gap: 30px;
  height: 100%;
`;

export const CriterionNoGap = styled(CriterionCard)`
  gap: 0px;
  height: 100%;
`;

export const ListCriterions = styled(GrowCard)`
  justify-content: initial;
`;
/* export const ChartTitle = styled(SubTitle); */
