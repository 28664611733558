import { CohortDto } from "../../types/cohort.dto";
import { PaginationDto } from "../../types/pagination.dto";

export const GET_ALL_COHORTS = "GET_ALL_COHORTS";
export const GET_ALL_COHORTS_ERROR = "GET_ALL_COHORTS_ERROR";
export const GET_ALL_COHORTS_SUCCESS = "GET_ALL_COHORTS_SUCCESS";
export const GET_COHORT = "GET_COHORT";
export const GET_COHORT_ERROR = "GET_COHORT_ERROR";
export const GET_COHORT_SUCCESS = "GET_COHORT_SUCCESS";
export const NEW_COHORT = "NEW_COHORT";
export const NEW_COHORT_ERROR = "NEW_COHORT_ERROR";
export const NEW_COHORT_SUCCESS = "NEW_COHORT_SUCCESS";
export const RESET_NEW_COHORT = "RESET_NEW_COHORT";
export const UPDATE_COHORT = "UPDATE_COHORT";
export const UPDATE_COHORT_ERROR = "UPDATE_COHORT_ERROR";
export const UPDATE_COHORT_SUCCESS = "UPDATE_COHORT_SUCCESS";
export const RESET_UPDATE_COHORT = "RESET_UPDATE_COHORT";
export const QUERY_COHORT = "QUERY_COHORT";
export const QUERY_COHORT_SUCCESS = "QUERY_COHORT_SUCCESS";
export const QUERY_COHORT_ERROR = "QUERY_COHORT_ERROR";
export const RESET_QUERY_COHORT = "RESET_QUERY_COHORT";
export const DELETE_COHORT = "DELETE_COHORT";
export const DELETE_COHORT_ERROR = "DELETE_COHORT_ERROR";
export const DELETE_COHORT_SUCCESS = "DELETE_COHORT_SUCCESS";
export const RESET_DELETE_COHORT = "RESET_DELETE_COHORT";
export const DELETE_COHORT_MEMBER = "DELETE_COHORT_MEMBER";
export const GET_COHORTS_BY_ACADEMY = "GET_COHORTS_BY_ACADEMY";
export const SELECT_COHORT = "SELECT_COHORT";
export const ADD_VIEW_COHORT = "ADD_VIEW_COHORT";
export const ADD_CLICK_COHORT = "ADD_CLICK_COHORT";
export const UPDATE_CURRENT_COHORT_LIST = "UPDATE_CURRENT_COHORT_LIST";
export const ADD_COHORT_MEMBER = "ADD_COHORT_MEMBER";
export const ADD_RATING_COHORT = "ADD_RATING_COHORT";
export const MANAGE_LIKE_DISLIKE_COHORT = "ADD_LIKE_COHORT";
export const GET_COHORTS_OFFSET = "GET_COHORTS_OFFSET";
export const GET_COHORTS_OFFSET_SUCCESS = "GET_COHORTS_OFFSET_SUCCESS";
export const GET_COHORTS_OFFSET_ERROR = "GET_COHORTS_OFFSET_ERROR";
export const RESET_GET_COHORTS_OFFSET = "RESET_GET_COHORTS_OFFSET";
export const RESET_GET_ALL_COHORT_DATA = "RESET_GET_ALL_COHORT_DATA";
export const RESET_GET_ONE_COHORT_DATA = "RESET_GET_ONE_COHORT_DATA";
export const SET_GET_ONE_COHORT = "SET_GET_ONE_COHORT";

export interface ResetGetAllCohortData {
  type: typeof RESET_GET_ALL_COHORT_DATA;
  payload: null;
}

export interface ResetGetOneCohortData {
  type: typeof RESET_GET_ONE_COHORT_DATA;
  payload: null;
}

export interface SetGetOneCohort {
  type: typeof SET_GET_ONE_COHORT;
  payload: CohortDto;
}

export interface GetCohortsOffset {
  type: typeof GET_COHORTS_OFFSET;
  payload: { limit: number; offset: number };
}

export interface GetCohortsByAcademy {
  type: typeof GET_COHORTS_BY_ACADEMY;
  payload: PaginationDto & { _id: string };
}

export interface GetCohortsOffsetError {
  type: typeof GET_COHORTS_OFFSET_ERROR;
  payload: string | boolean;
}

export interface GetCohortsOffsetSuccess {
  type: typeof GET_COHORTS_OFFSET_SUCCESS;
  payload: CohortDto[];
}

export interface ResetGetCohortsOffset {
  type: typeof RESET_GET_COHORTS_OFFSET;
  payload: null;
}

export interface ManageLikDislikeCohort {
  type: typeof MANAGE_LIKE_DISLIKE_COHORT;
  payload: {
    _id: string;
    action: "add" | "remove";
    condition: "like" | "dislike";
  };
}

export interface GetAllCohorts {
  type: typeof GET_ALL_COHORTS;
  payload?: PaginationDto;
}

export interface AddRatingCohort {
  type: typeof ADD_RATING_COHORT;
  payload: { id: string; stars: number };
}

export interface UpdateCurrenCohortList {
  type: typeof UPDATE_CURRENT_COHORT_LIST;
  payload: CohortDto[];
}

export interface AddClickCohort {
  type: typeof ADD_CLICK_COHORT;
  payload: { _id: string };
}

export interface AddViewCohort {
  type: typeof ADD_VIEW_COHORT;
  payload: { _id: string };
}
export interface QueryCohort {
  type: typeof QUERY_COHORT;
  payload: string;
}
export interface QueryCohortSuccess {
  type: typeof QUERY_COHORT_SUCCESS;
  payload: CohortDto[];
}
export interface QueryCohortError {
  type: typeof QUERY_COHORT_ERROR;
  payload: string | boolean;
}

export interface ResetQueryCohort {
  type: typeof RESET_QUERY_COHORT;
  payload: null;
}

export interface GetAllCohortsSuccess {
  type: typeof GET_ALL_COHORTS_SUCCESS;
  payload: CohortDto[];
}

export interface SelectCohort {
  type: typeof SELECT_COHORT;
  payload: null | CohortDto;
}

export interface GetAllCohortsError {
  type: typeof GET_ALL_COHORTS_ERROR;
  payload: boolean | string;
}

export interface DeleteCohortMember {
  type: typeof DELETE_COHORT_MEMBER;
  payload: { _id: string; userId: string };
}

export interface GetCohort {
  type: typeof GET_COHORT;
  payload: { id: string };
}

export interface AddCohortMember {
  type: typeof ADD_COHORT_MEMBER;
  payload: { user: string; role: string; _id: string };
}

export interface GetCohortSuccess {
  type: typeof GET_COHORT_SUCCESS;
  payload: CohortDto;
}

export interface GetCohortError {
  type: typeof GET_COHORT_ERROR;
  payload: boolean | string;
}

export interface NewCohort {
  type: typeof NEW_COHORT;
  payload: CohortDto;
}

export interface NewCohortSuccess {
  type: typeof NEW_COHORT_SUCCESS;
  payload: CohortDto;
}

export interface NewCohortError {
  type: typeof NEW_COHORT_ERROR;
  payload: boolean | string;
}

export interface ResetNewCohort {
  type: typeof RESET_NEW_COHORT;
  payload: null;
}

export interface UpdateCohort {
  type: typeof UPDATE_COHORT;
  payload: CohortDto;
}

export interface UpdateCohortSuccess {
  type: typeof UPDATE_COHORT_SUCCESS;
  payload: CohortDto;
}

export interface UpdateCohortError {
  type: typeof UPDATE_COHORT_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateCohort {
  type: typeof RESET_UPDATE_COHORT;
  payload: null;
}

export interface DeleteCohort {
  type: typeof DELETE_COHORT;
  payload: { id: string };
}

export interface DeleteCohortSuccess {
  type: typeof DELETE_COHORT_SUCCESS;
  payload: null;
}

export interface DeleteCohortError {
  type: typeof DELETE_COHORT_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteCohort {
  type: typeof RESET_DELETE_COHORT;
  payload: null;
}

export interface State {
  allCohorts: {
    data: CohortDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  cohort: {
    data: CohortDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  offSetCohort: {
    data: CohortDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newCohort: {
    data: CohortDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  deleteCohort: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  queryCohort: {
    data: null | CohortDto[];
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateCohort: {
    data: null | CohortDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  selectCohort: {
    data: null | CohortDto;
  };
}

export const initialState: State = {
  allCohorts: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  queryCohort: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  offSetCohort: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  cohort: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newCohort: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  deleteCohort: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateCohort: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  selectCohort: {
    data: null,
  },
};

export type Actions =
  | GetAllCohorts
  | GetAllCohortsSuccess
  | GetAllCohortsError
  | GetCohort
  | GetCohortSuccess
  | GetCohortError
  | NewCohort
  | NewCohortSuccess
  | NewCohortError
  | ResetNewCohort
  | UpdateCohort
  | UpdateCohortSuccess
  | UpdateCohortError
  | ResetUpdateCohort
  | DeleteCohort
  | DeleteCohortSuccess
  | DeleteCohortError
  | ResetDeleteCohort
  | DeleteCohortMember
  | SelectCohort
  | AddViewCohort
  | UpdateCurrenCohortList
  | AddClickCohort
  | AddCohortMember
  | QueryCohort
  | QueryCohortSuccess
  | QueryCohortError
  | ResetQueryCohort
  | AddRatingCohort
  | ManageLikDislikeCohort
  | GetCohortsOffset
  | GetCohortsOffsetError
  | GetCohortsOffsetSuccess
  | ResetGetCohortsOffset
  | ResetGetAllCohortData
  | ResetGetOneCohortData
  | GetCohortsByAcademy
  | SetGetOneCohort;
