import styled from "styled-components";

export const Layout = styled("div")`
  max-width: 100vw;
  overflow: hidden;
  min-height: 100vh;
`;

export const Content = styled("main")`
  padding: 46px 0;
  margin: 0 auto;
  max-width: 700px;
`;

export const Title = styled("h1")`
  font-size: 40px;
  font-weight: 500;
  line-height: normal;
  color: black;
  margin: 0px 0px 53px;
`;

export const Logo = styled("img")`
  margin-bottom: 81px;
`;
