import styled from "styled-components";

export const Wrapper = styled("div")`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 3px 0 rgba(106, 105, 112, 0.25);
`;
export const Cover = styled("div")`
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
export const PlayButton = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border: 0px;
  padding: 0px;
  /*  background-color: rgba(255,255,255,.85); */
  /*  width: 100px;
  height: 100px; */
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  zoom: 1;
  transition: all 0.3s ease-in-out;
  & > svg {
    transition: all 0.3s ease-in-out;
  }
  &:hover svg {
    color: #3aa2f1 !important;
  }

  &:active {
    zoom: 0.9;
  }
`;

export const ReplayButton = styled("button")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border: 0px;
  padding: 0px;
  background-color: rgba(255, 255, 255, 0.85);
  width: 100px;
  height: 100px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  zoom: 1;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  &:active {
    zoom: 0.9;
  }
`;
