import {
  GetAllCohorts,
  GET_ALL_COHORTS,
  GetCohort,
  GET_COHORT,
  UpdateCohort,
  UPDATE_COHORT,
  DeleteCohort,
  DELETE_COHORT,
  GET_COHORT_SUCCESS,
  GetCohortSuccess,
  NewCohort,
  NEW_COHORT,
  ResetNewCohort,
  RESET_NEW_COHORT,
  ResetDeleteCohort,
  RESET_DELETE_COHORT,
  ResetUpdateCohort,
  RESET_UPDATE_COHORT,
  DELETE_COHORT_MEMBER,
  SELECT_COHORT,
  SelectCohort,
  AddViewCohort,
  ADD_VIEW_COHORT,
  AddClickCohort,
  ADD_CLICK_COHORT,
  AddCohortMember,
  ADD_COHORT_MEMBER,
  QUERY_COHORT,
  GET_COHORTS_BY_ACADEMY,
  AddRatingCohort,
  ADD_RATING_COHORT,
  MANAGE_LIKE_DISLIKE_COHORT,
  GET_COHORTS_OFFSET,
  GetCohortsOffset,
  RESET_GET_COHORTS_OFFSET,
  ResetGetCohortsOffset,
  RESET_GET_ALL_COHORT_DATA,
  RESET_GET_ONE_COHORT_DATA,
} from "../types/cohort";

export const getAllCohorts = (payload): GetAllCohorts => {
  return { type: GET_ALL_COHORTS, payload };
};

export const getCohort = (id): GetCohort => {
  return { type: GET_COHORT, payload: id };
};

export const setSelectedCohort = (payload): GetCohortSuccess => {
  return { type: GET_COHORT_SUCCESS, payload };
};

export const addView = (payload): AddViewCohort => {
  return { type: ADD_VIEW_COHORT, payload };
};

export const getCohortsByAcademy = (payload) => {
  return { type: GET_COHORTS_BY_ACADEMY, payload };
};

export const addClick = (payload): AddClickCohort => {
  return { type: ADD_CLICK_COHORT, payload };
};

export const selectCohort = (payload): SelectCohort => {
  return { type: SELECT_COHORT, payload };
};

export const offSetCohorts = (payload): GetCohortsOffset => {
  return { type: GET_COHORTS_OFFSET, payload };
};

export const resetGetAllCohorts = () => {
  return { type: RESET_GET_ALL_COHORT_DATA, payload: null };
};

export const resetGetOneCohort = () => {
  return { type: RESET_GET_ONE_COHORT_DATA, payload: null };
};

export const resetOffsetCohorts = (): ResetGetCohortsOffset => {
  return { type: RESET_GET_COHORTS_OFFSET, payload: null };
};

export const deleteMember = (payload) => {
  return { type: DELETE_COHORT_MEMBER, payload };
};

export const updateCohort = (payload): UpdateCohort => {
  return { type: UPDATE_COHORT, payload };
};

export const resetUpdateCohort = (): ResetUpdateCohort => {
  return { type: RESET_UPDATE_COHORT, payload: null };
};

export const newCohort = (payload): NewCohort => {
  return { type: NEW_COHORT, payload };
};

export const resetNewCohort = (): ResetNewCohort => {
  return { type: RESET_NEW_COHORT, payload: null };
};

export const deleteCohort = (id): DeleteCohort => {
  return { type: DELETE_COHORT, payload: id };
};

export const resetDeleteCohort = (): ResetDeleteCohort => {
  return { type: RESET_DELETE_COHORT, payload: null };
};

export const addCohortMember = (payload): AddCohortMember => {
  return { type: ADD_COHORT_MEMBER, payload };
};

export const addRating = (payload): AddRatingCohort => {
  return { type: ADD_RATING_COHORT, payload };
};

export const manageLikeDislike = (payload) => {
  return { type: MANAGE_LIKE_DISLIKE_COHORT, payload };
};

export const queryCohort = (payload) => {
  return { type: QUERY_COHORT, payload };
};
