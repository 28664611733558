import Modal from "../../../../../../components/Modal";
import EditForm from "../../../Edit";

const Component = () => {
  return (
    <Modal name="edit-student-modal" title="Editar Estudiante">
      <EditForm />
    </Modal>
  );
};

export default Component;
