import { CSSProperties, useEffect, useRef, useState } from "react";
import {
  Header,
  HeaderDropDown,
  HearderDropDownWrapper,
  Img,
  NotificationCount,
} from "./styles";
import { useTheme } from "styled-components";
import { connect } from "react-redux";
import { UserDto } from "../../types/user.dto";
import ConstanaLogo from "../../assets/images/constana-new.svg";
import { useNavigate } from "react-router-dom";
import { FlexContainer } from "../StyledComponents";
import { MdOutlineFileUpload } from "react-icons/md";
import { AiOutlineBell } from "react-icons/ai";
import ContentDropDownItems from "./components/ContentDropDownItems";
import UserDropDownItems from "./components/UserDropDownItems";
import CreateAcademy from "../../assets/icons/create-academy.svg";
import { Icon } from "../Icon/styles";
import CurrentNotifications from "./components/CurrentNotifications";
import ToolTip from "./components/ToolTip";
import { useWindowSize } from "../../hooks/useWindowScreen";
import NotificationMobile from "../../pages/Mobile/Notification";
import NotificationList from "./components/NotificationList";
import ConstanaIcon from "../../assets/images/isotipo-constana.svg";
interface ComponentProps {
  sidebarCollapsed: boolean;
  style?: CSSProperties;

  user: UserDto;

  modal;
}

const Component = (props: ComponentProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openDropdown, setOpenDropdown] = useState(null);
  const { isMobile } = useWindowSize();
  const dropdownRefs: any = {
    item1: useRef(null),
    item2: useRef(null),
    item3: useRef(null),
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      // Si se hace clic en cualquier parte del documento excepto en los elementos del dropdown, cierra el dropdown.
      if (
        openDropdown &&
        !event.target.closest(".pole") &&
        !event.target.closest(".dropdown")
      ) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [openDropdown]);

  const handleItemClick = (item) => {
    if (openDropdown === item) {
      // Si se hace clic en el ítem activo, cierra el dropdown.
      setOpenDropdown(null);
    } else {
      setOpenDropdown(item);
    }
  };
  return (
    <Header {...props}>
      <FlexContainer
        onClick={() => {
          navigate("/dashboard/home");
        }}
        align="center"
        gap="6px"
        style={{ cursor: "pointer" }}
      >
        <div
          style={{
            height: isMobile ? "20px" : "26px",
            width: isMobile ? "20px" : "26px",
          }}
        >
          <Img src={ConstanaIcon} alt="icon-constana"></Img>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: isMobile ? "11px" : "16px",
            width: isMobile ? "100px" : "133.2px",
          }}
        >
          <Img src={ConstanaLogo} alt="text-icon-constana"></Img>
        </div>
      </FlexContainer>

      <FlexContainer align="center" gap="39px">
        <FlexContainer align="center" gap="21px">
          {!isMobile && (
            <>
              <HearderDropDownWrapper
                dropdownName={"create-content"}
                className={`${openDropdown === "item1" ? "open" : ""} dropdown`}
                onClick={(ev) => {
                  ev.stopPropagation();
                  handleItemClick("item1");
                }}
              >
                <ToolTip className={"header-create-content"}>
                  Subir Contenido
                </ToolTip>
                <MdOutlineFileUpload
                  size={22}
                  color={theme.colors.LightBlue}
                ></MdOutlineFileUpload>

                <HeaderDropDown
                  className={`${openDropdown === "item1" ? "open" : ""} pole`}
                  ref={dropdownRefs.item1}
                  onClick={(ev) => {
                    ev.stopPropagation();
                  }}
                >
                  <ContentDropDownItems></ContentDropDownItems>
                </HeaderDropDown>
              </HearderDropDownWrapper>
              <HearderDropDownWrapper
                dropdownName={"create-academy"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  window.open(
                    "https://academy.academia.catel.org.ar/sign-in",
                    "_autoblank"
                  );
                }}
              >
                <ToolTip className={"header-create-academy"}>
                  Crear Academia
                </ToolTip>
                <Icon
                  size={"18px"}
                  color={theme.colors.LightBlue}
                  icon={CreateAcademy}
                ></Icon>
              </HearderDropDownWrapper>
            </>
          )}
          {props.user && (
            <HearderDropDownWrapper
              dropdownName={"notifications"}
              className={`${openDropdown === "item3" ? "open" : ""} dropdown`}
              style={{ width: "auto", height: "auto" }}
              onClick={() => {
                if (isMobile) {
                  navigate("/dashboard/notifications");
                } else {
                  handleItemClick("item3");
                }
              }}
            >
              <CurrentNotifications></CurrentNotifications>
              {!isMobile && (
                <HeaderDropDown
                  style={{
                    overflowY: "auto",
                    maxHeight: "400px",
                    padding: "2px",
                    width: "360px",
                  }}
                  className={`${openDropdown === "item3" ? "open" : ""} pole`}
                  ref={dropdownRefs.item3}
                  onClick={(ev) => {
                    ev.stopPropagation();
                  }}
                >
                  <NotificationList
                    openDropdown={openDropdown}
                  ></NotificationList>
                </HeaderDropDown>
              )}
            </HearderDropDownWrapper>
          )}
        </FlexContainer>
        <HearderDropDownWrapper
          dropdownName={"user-account"}
          className={`${openDropdown === "item2" ? "open" : ""} dropdown`}
          style={{ width: "auto", height: "auto" }}
          onClick={(ev) => {
            ev.stopPropagation();
            handleItemClick("item2");
          }}
        >
          <ToolTip className={"header-user-account"}>Cuenta</ToolTip>
          <div
            style={{
              height: "32px",
              width: "32px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            {props.user ? (
              <Img src={props.user.picture} alt={"icono usuario"}></Img>
            ) : (
              <Img
                src={`https://cdn1.iconfinder.com/data/icons/user-pictures/100/unknown-512.png`}
                alt={"icono usuario desconocido"}
              ></Img>
            )}
          </div>
          <HeaderDropDown
            className={`${openDropdown === "item2" ? "open" : ""} pole`}
            ref={dropdownRefs.item2}
            onClick={(ev) => {
              ev.stopPropagation();
            }}
          >
            <UserDropDownItems></UserDropDownItems>
          </HeaderDropDown>
        </HearderDropDownWrapper>
      </FlexContainer>
    </Header>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;
  return {
    user,
  };
};

export default connect(states)(Component);
