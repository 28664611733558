import * as Yup from "yup";

export const initialValues = {
  bannerPic: "",
  picture: "",
};

export const schema = Yup.object().shape({
  bannerPic: Yup.string(),
  picture: Yup.string(),
});
