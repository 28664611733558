import { PaginationDto } from "../../types/pagination.dto";
import { RequestDto } from "../../types/request.dto";

export const GET_ALL_REQUESTS = "GET_ALL_REQUESTS";
export const GET_ALL_REQUESTS_ERROR = "GET_ALL_REQUESTS_ERROR";
export const GET_ALL_REQUESTS_SUCCESS = "GET_ALL_REQUESTS_SUCCESS";
export const GET_REQUEST = "GET_REQUEST";
export const GET_REQUEST_ERROR = "GET_REQUEST_ERROR";
export const GET_REQUEST_SUCCESS = "GET_REQUEST_SUCCESS";
export const NEW_REQUEST = "NEW_REQUEST";
export const NEW_REQUEST_ERROR = "NEW_REQUEST_ERROR";
export const NEW_REQUEST_SUCCESS = "NEW_REQUEST_SUCCESS";
export const RESET_NEW_REQUEST = "RESET_NEW_REQUEST";
export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const UPDATE_REQUEST_ERROR = "UPDATE_REQUEST_ERROR";
export const UPDATE_REQUEST_SUCCESS = "UPDATE_REQUEST_SUCCESS";
export const RESET_UPDATE_REQUEST = "RESET_UPDATE_REQUEST";
export const DELETE_REQUEST = "DELETE_REQUEST";
export const DELETE_REQUEST_ERROR = "DELETE_REQUEST_ERROR";
export const DELETE_REQUEST_SUCCESS = "DELETE_REQUEST_SUCCESS";
export const RESET_DELETE_REQUEST = "RESET_DELETE_REQUEST";
export const RESET_GET_ALL_REQUEST_DATA = "RESET_GET_ALL_REQUEST_DATA";
export const RESET_GET_ONE_REQUEST_DATA = "RESET_GET_ONE_REQUEST_DATA";
export const GET_REQUESTS_BY_USER_ENTITY = "GET_REQUESTS_BY_USER_ENTITY";

export interface ResetGetAllRequestData {
  type: typeof RESET_GET_ALL_REQUEST_DATA;
  payload: null;
}

export interface GetRequestsByUserEntity {
  type: typeof GET_REQUESTS_BY_USER_ENTITY;
  payload: PaginationDto & { entity: string };
}

export interface ResetGetOneRequestData {
  type: typeof RESET_GET_ONE_REQUEST_DATA;
  payload: null;
}

export interface GetAllRequests {
  type: typeof GET_ALL_REQUESTS;
  payload: null;
}

export interface GetAllRequestsSuccess {
  type: typeof GET_ALL_REQUESTS_SUCCESS;
  payload: RequestDto[];
}

export interface GetAllRequestsError {
  type: typeof GET_ALL_REQUESTS_ERROR;
  payload: boolean | string;
}

export interface GetRequest {
  type: typeof GET_REQUEST;
  payload: { id: string };
}

export interface GetRequestSuccess {
  type: typeof GET_REQUEST_SUCCESS;
  payload: RequestDto;
}

export interface GetRequestError {
  type: typeof GET_REQUEST_ERROR;
  payload: boolean | string;
}

export interface NewRequest {
  type: typeof NEW_REQUEST;
  payload: RequestDto;
}

export interface NewRequestSuccess {
  type: typeof NEW_REQUEST_SUCCESS;
  payload: RequestDto;
}

export interface NewRequestError {
  type: typeof NEW_REQUEST_ERROR;
  payload: boolean | string;
}

export interface ResetNewRequest {
  type: typeof RESET_NEW_REQUEST;
  payload: null;
}

export interface UpdateRequest {
  type: typeof UPDATE_REQUEST;
  payload: RequestDto;
}

export interface UpdateRequestSuccess {
  type: typeof UPDATE_REQUEST_SUCCESS;
  payload: RequestDto;
}

export interface UpdateRequestError {
  type: typeof UPDATE_REQUEST_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateRequest {
  type: typeof RESET_UPDATE_REQUEST;
  payload: null;
}

export interface DeleteRequest {
  type: typeof DELETE_REQUEST;
  payload: { id: string };
}

export interface DeleteRequestSuccess {
  type: typeof DELETE_REQUEST_SUCCESS;
  payload: RequestDto;
}

export interface DeleteRequestError {
  type: typeof DELETE_REQUEST_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteRequest {
  type: typeof RESET_DELETE_REQUEST;
  payload: null;
}

export interface State {
  allRequests: {
    data: RequestDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  category: {
    data: RequestDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newRequest: {
    data: null | RequestDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  deleteRequest: {
    data: null | RequestDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateRequest: {
    data: null | RequestDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  allRequests: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  category: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newRequest: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  deleteRequest: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateRequest: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllRequests
  | GetAllRequestsSuccess
  | GetAllRequestsError
  | GetRequest
  | GetRequestSuccess
  | GetRequestError
  | NewRequest
  | NewRequestSuccess
  | NewRequestError
  | ResetNewRequest
  | UpdateRequest
  | UpdateRequestSuccess
  | UpdateRequestError
  | ResetUpdateRequest
  | DeleteRequest
  | DeleteRequestSuccess
  | DeleteRequestError
  | ResetDeleteRequest
  | ResetGetAllRequestData
  | ResetGetOneRequestData
  | GetRequestsByUserEntity;
