import styled from "styled-components";
import ButtonUi from "../../Button";
import { buttonStyles } from "../../Button/styles";

export const Button = styled(ButtonUi)<{ skin }>`
  /* &:disabled {
    background-color: ${(props) => {
    return buttonStyles[props.options.type][props.options.skin].backgroundColor;
  }};
    border-color: ${(props) =>
    buttonStyles[props.options.type][props.options.skin].borderColor};
  } */
`;
