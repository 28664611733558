export const CHANGE_THEME = "CHANGE_THEME";

export interface ChangeTheme {
  type: typeof CHANGE_THEME;
  payload: string;
}

export interface State {
  theme: string;
}

export const initialState: State = {
  theme: "light",
};

export type Actions = ChangeTheme;
