import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Icon from "../../../../components/Icon";
import { ActionButton, NewButton } from "./styles";

import EditModal from "./components/EditModal";
import NewModal from "./components/NewModal";
import DeleteModal from "./components/DeleteModal";

import PlusIcon from "../../../../assets/icons/plus.svg";
import DeleteUserIcon from "../../../../assets/icons/delete.svg";
import EditUserIcon from "../../../../assets/icons/edit.svg";
import {
  getAllEvents,
  setSelectedEvent,
} from "../../../../store/actions/event";
import { EventDto } from "../../../../types/event.dto";
import { showModal } from "../../../../store/actions/modal";
import Loader from "../../../../components/LogoLoader";
import { AbilityDto } from "../../../../types/ability.dto";
import { getAllAbilities } from "../../../../store/actions/ability";
import { eventsTypes } from "../../../../constants/events-types";
import { StateDto } from "../../../../types/states.dto";
import InfinityScroll from "../../../../components/InfinityScroll";

interface ComponentProps {
  events: EventDto[];
  abilities: AbilityDto[];
  eventsStates: StateDto;
}

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({ events, abilities, eventsStates }: ComponentProps) => {
  const [activeEvents, setActiveEvents] = useState<EventDto[]>([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const dispatch = useDispatch();

  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 9,
      offset: 9 * Math.floor(page / 2),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        published: true,
      },
    };
    const sortBy = page % 2 === 0 ? "createdAt" : "viewCount";

    const sortDirection = "desc";
    dispatch(getAllEvents({ ...payload, sortBy, sortDirection }));
  };

  useEffect(() => {
    if (!isLoading && !!events) {
      setActiveEvents((state) => [...state, ...events]);
    }
    setThereAreItems(!!events && events?.length > 0);
  }, [events, isLoading]);

  useEffect(() => {
    setIsLoading(eventsStates.loading);
  }, [eventsStates]);

  const handleEdit = (row, index) => {
    dispatch(setSelectedEvent(row));
    dispatch(showModal("edit-event-modal"));
  };

  const handleDelete = (row, index) => {
    dispatch(setSelectedEvent(row));
    dispatch(showModal("delete-event-modal"));
  };

  const handleNew = (row, index) => {
    dispatch(showModal("new-event-modal"));
  };

  const columns = [
    {
      with: "100%",
      minWidth: "300px",
      name: "Nombre del Evento",
      selector: (row) => row.name,
    },
    {
      name: "Tipo",
      cell: (row) => {
        return eventsTypes?.filter(
          (eventType) => eventType.value === row.type
        )[0].label;
      },
    },
    {
      name: "Habilidad",
      cell: (row) => {
        return abilities?.filter((ability) => ability._id === row.abilityId)[0]
          .name;
      },
    },
    {
      right: true,
      name: "",
      cell: (row, index) => {
        const Edit = () => (
          <ActionButton type="button" onClick={() => handleEdit(row, index)}>
            <Icon icon={EditUserIcon} color="#1673e5" size="18px" />
          </ActionButton>
        );

        const Delete = () => (
          <ActionButton type="button" onClick={() => handleDelete(row, index)}>
            <Icon icon={DeleteUserIcon} color="#fd433a" size="18px" />
          </ActionButton>
        );

        return (
          <>
            <Edit />
            <Delete />
          </>
        );
      },
    },
  ];

  const handleRowClicked = (data) => {};

  return (
    <>
      <NewButton onClick={handleNew}>
        <Icon icon={PlusIcon} size="14px" color="#1573e5" />
      </NewButton>
      <InfinityScroll
        action={fetchData}
        page={page}
        setPage={setPage}
        data={activeEvents}
        active={thereAreItems}
        isLoading={isLoading}
      >
        <DataTableExtensions
          filterDigit={0}
          export={false}
          print={false}
          filterPlaceholder="Buscar"
          columns={columns}
          data={activeEvents}
        >
          <DataTable
            pagination
            disabled={!activeEvents}
            columns={columns}
            data={activeEvents}
            noDataComponent={<p>Sin Elementos</p>}
            onRowClicked={(row) => handleRowClicked(row)}
          />
        </DataTableExtensions>
      </InfinityScroll>

      <EditModal />
      <NewModal />
      <DeleteModal />
    </>
  );
};

const states = ({ eventStore, abilityStore }) => {
  const { data: events, states: eventsStates } = eventStore.allEvents;
  const { data: abilities } = abilityStore.allAbilities;

  return {
    eventsStates,
    events,
    abilities,
  };
};
export default connect(states)(Component);
