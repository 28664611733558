import {
  GetAllRequests,
  GET_ALL_REQUESTS,
  GetRequest,
  GET_REQUEST,
  UpdateRequest,
  UPDATE_REQUEST,
  DeleteRequest,
  DELETE_REQUEST,
  GetRequestSuccess,
  GET_REQUEST_SUCCESS,
  NewRequest,
  NEW_REQUEST,
  ResetNewRequest,
  RESET_NEW_REQUEST,
  ResetUpdateRequest,
  RESET_UPDATE_REQUEST,
  ResetDeleteRequest,
  RESET_DELETE_REQUEST,
  RESET_GET_ALL_REQUEST_DATA,
  GetRequestsByUserEntity,
  GET_REQUESTS_BY_USER_ENTITY,
} from "../types/request";

export const getAllRequests = (payload): GetAllRequests => {
  return { type: GET_ALL_REQUESTS, payload };
};

export const getRequestsByUserEntity = (payload): GetRequestsByUserEntity => {
  return { type: GET_REQUESTS_BY_USER_ENTITY, payload };
};

export const getRequest = (id): GetRequest => {
  return { type: GET_REQUEST, payload: id };
};

export const setSelectedRequest = (payload): GetRequestSuccess => {
  return { type: GET_REQUEST_SUCCESS, payload };
};

export const newRequest = (payload): NewRequest => {
  return { type: NEW_REQUEST, payload };
};

export const resetNewRequest = (): ResetNewRequest => {
  return { type: RESET_NEW_REQUEST, payload: null };
};

export const resetGetAllRequests = () => {
  return { type: RESET_GET_ALL_REQUEST_DATA, payload: null };
};

export const updateRequest = (id): UpdateRequest => {
  return { type: UPDATE_REQUEST, payload: id };
};

export const resetUpdateRequest = (): ResetUpdateRequest => {
  return { type: RESET_UPDATE_REQUEST, payload: null };
};

export const deleteRequest = (id): DeleteRequest => {
  return { type: DELETE_REQUEST, payload: id };
};

export const resetDeleteRequest = (): ResetDeleteRequest => {
  return { type: RESET_DELETE_REQUEST, payload: null };
};
