import styled from "styled-components";
import Avatar from "../Avatar";

export const NameAit = styled("p")`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #707070;
`;

export const TalentAvatar = styled(Avatar)`
  height: ${(props) => (!!props.height ? props.height : "30px")};
  width: ${(props) => (!!props.width ? props.width : "30px")};
`;

export const Header = styled("header")<{ sidebarCollapsed }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 20.4px 3px 25px;
  background-color: ${(props) => props.theme.colors.White};
  transition: left 0.4s ease-in-out;
  box-shadow: 0px 0px 4px 1px rgba(36, 150, 237, 0.1);
  position: fixed;
  width: 100%;
  right: 0px;
  top: 0px;
  z-index: ${(props) => (props.modal ? "-1" : "8000")};
`;

export const Img = styled("img")`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const CardRow = styled("div")`
  display: flex;
`;
export const CardCol = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const Name = styled(NameAit)`
  font-size: 13px;
  color: #fff;
  height: 17px;
`;

export const Ocupation = styled(NameAit)`
  font-size: 11px;
  color: #fff;
  height: 14px;
`;

export const Logo = styled("img")`
  padding: ${(props) => props.padding};
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const HeaderTools = styled("div")`
  display: flex;
  align-items: center;
  gap: 49px;
`;

export const ImgSvgWrapper = styled("div")`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  mask: url(${(props) => props.svg});
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
`;

export const HearderDropDownWrapper = styled("div")`
  position: relative;
  height: 22px;
  width: 22px;
  &:hover:not(.open) .header-${(props) => props.dropdownName} {
    display: block;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    z-index: -1;
    transition: background-color 0.3s ease;
  }
  &:hover::after {
    background-color: ${(props) => props.theme.colors.LightBlueHover};
  }
  &.open::after {
    background-color: ${(props) => props.theme.colors.LightBlue};
  }
  &.open > svg {
    color: ${(props) => props.theme.colors.White} !important;
  }
`;

export const HeaderDropDownTitle = styled("h2")`
  font-size: 15px;
  font-weight: 500;
  color: #3f3f3f;
`;

export const HeaderDropDown = styled("div")`
  visibility: hidden; /* Cambiamos display por visibility */
  overflow: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: absolute;
  top: 30px;
  right: 0;
  border-radius: 15px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 4px;
  width: 180px;
  padding: 8px;
  opacity: 0;
  transform: translateY(-10px);
  z-index: 1;
  &.open {
    visibility: visible; /* Mostramos el dropdown cuando el item está en hover */
    opacity: 1;
    transform: translateY(0);
  }
`;

export const NotificationCount = styled("div")`
  position: absolute;
  top: -4px;
  left: 12px;
  display: inline-block;
  padding: 0 4px;
  min-width: 8px;
  pointer-events: none;
  border-radius: 22px;
  text-align: center;
  height: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  background-color: #c00;
  color: #fff;
`;

export const MarkerWrapper = styled("div")`
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  &.active {
    background-color: rgba(245, 245, 245, 0.2);
    border-radius: 5px;
    .caret {
      transform: rotate(0deg);
    }
  }
  &:not(.active):hover {
    background-color: rgba(245, 245, 245, 0.2);
    border-radius: 5px;
  }
`;

export const ListItem = styled("div")`
  padding: 5px;
  transition: 0.3s background-color ease-in-out;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.LightBlueHover};
  }
  /* &:hover svg,
  &:hover h3 {
    color: ${(props) => props.theme.colors.White} !important;
  }
  &:hover i::before {
    background-color: ${(props) => props.theme.colors.White} !important;
  } */
`;

export const ListItemText = styled("h3")`
  font-size: 13px;
  font-weight: 500;
  color: #6a6970;
`;
