import React, { useEffect, useState } from "react";
import Grid from "../Grid";
import {
  Card,
  CardColumn,
  CardRow,
  CardTitle,
  DataText,
  LinkText,
} from "./style";
import { AiFillLinkedin, AiOutlineMail, AiFillGithub } from "react-icons/ai";
import { BsPersonCircle } from "react-icons/bs";
import {
  initialValues,
  schema,
} from "../../constants/form/user/profile/selectCohort";
import { FiSmartphone } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { TbWorld } from "react-icons/tb";
import { connect, useDispatch } from "react-redux";
import EditButton from "./components/EditButton";
import { Logo } from "../Header/styles";
import usersActions from "../../store/actions/users";
import EditProfileModal from "./components/EditProfileModal";
import EditContactModal from "./components/EditContactModal";
import Loader from "../Loader";
import ReactSelect from "../Form/ReactSelect";
import { Formik } from "formik";
import { getAllCohorts, resetGetAllCohorts } from "../../store/actions/cohort";
import academiesActions from "../../store/actions/academies";
import { Center } from "../StyledComponents";
import TalentCard from "./components/TalentCard";
import { getTalents, resetGetAllTalents } from "../../store/actions/talent";
import { CohortDto } from "../../types/cohort.dto";
import { UsersDto } from "../../types/users.dto";
import {
  makeShownCohort,
  resetSetUserInformation,
  resetUpdateUser,
} from "../../store/actions/user";
import { toast } from "react-toastify";
import { hideModal, showModal } from "../../store/actions/modal";
import moment from "moment";
import AddPasswordModal from "./components/AddPasswordModal";
import { UserDto } from "../../types/user.dto";
import { AcademyDto } from "../../types/academy.dto";
import { StateDto } from "../../types/states.dto";
const Component = ({
  user,
  users,
  academies,
  cohorts,

  updateUserStates,
}: {
  user: UserDto;
  users: UsersDto[];
  academies: AcademyDto[];
  cohorts: CohortDto[];
  updateUserStates: StateDto;
}) => {
  const [cohortSelected, setCohortSelected] = useState<string>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!cohorts && !!user)
      dispatch(getAllCohorts({ filterBy: { studentsIds: user._id } }));
  }, [cohorts, dispatch]);
  useEffect(() => {
    if (!academies)
      dispatch(
        academiesActions.getAll({
          filterBy: {
            users: {
              $elemMatch: {
                user: user._id,
                roleEnd: { $exists: false },
              },
            },
          },
          select: { _id: 1, name: 1 },
        })
      );
  }, [academies, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(usersActions.resetGetAllUsers());
      dispatch(resetGetAllCohorts());
      dispatch(academiesActions.resetGetAllAcademies());
    };
  }, []);

  useEffect(() => {
    if (updateUserStates.success) {
      toast.success(`Se actualizo usuario`);
      dispatch(resetUpdateUser());
      dispatch(hideModal());
    }
    if (updateUserStates.error) {
      toast.error(updateUserStates.error);
      dispatch(hideModal());
      dispatch(resetUpdateUser());
    }
  }, [updateUserStates]);

  useEffect(() => {
    if (user) {
      setCohortSelected(user.cohorts[0] ?? undefined);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const createDate = moment(new Date(user.createdAt)).utc();
      const today = moment().utc();
      if (!user.password && today.diff(createDate, "hours") < 1) {
        dispatch(showModal("change-password"));
      }
    }
  }, [user]);

  if (!user || !academies || !!updateUserStates.loading)
    return <Loader color="LightBlue"></Loader>;

  return (
    <>
      <Grid.ContainerFluid margin={1}>
        <Grid.Row>
          <Grid.Col sm={4}>
            <Grid.Row>
              <Grid.Col style={{ marginBottom: "20px" }}>
                <Card>
                  <CardColumn gap="20px">
                    <CardRow align="center" gap="5px">
                      <CardColumn>
                        <div
                          style={{
                            height: "50px",
                            width: "50px",
                            overflow: "hidden",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                        >
                          <Logo src={user?.picture} alt={user?.position} />
                        </div>
                      </CardColumn>
                      <CardColumn gap="5px">
                        <CardColumn>
                          <CardRow gap="5px">
                            <CardTitle>{user?.name}</CardTitle>
                            <CardTitle>{user?.lastName}</CardTitle>
                          </CardRow>
                        </CardColumn>
                        <CardColumn>
                          <DataText>{user?.position}</DataText>
                        </CardColumn>
                      </CardColumn>
                    </CardRow>
                    <CardRow>
                      <CardColumn gap="14px">
                        <CardRow align="center" gap="5px">
                          <DataText>{`Nacionalidad:`}</DataText>
                          <DataText>{user?.nationality}</DataText>
                        </CardRow>
                        <CardRow align="center" gap="5px">
                          <DataText>{`Fecha de Nacimiento:`}</DataText>
                          <DataText>{user?.birthDate}</DataText>
                        </CardRow>
                        <CardRow align="center" gap="5px">
                          <DataText>{`Genero:`}</DataText>
                          <DataText>{user?.gender}</DataText>
                        </CardRow>
                        <CardRow align="center" gap="5px">
                          <DataText>{`Estado Civil:`}</DataText>
                          <DataText>{user?.civilStatus}</DataText>
                        </CardRow>
                        <CardRow align="center" gap="5px">
                          <DataText>{`DNI:`}</DataText>
                          <DataText>{user?.dni}</DataText>
                        </CardRow>
                      </CardColumn>
                    </CardRow>
                  </CardColumn>
                  <EditButton modalName="edit-user-profile" />
                </Card>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Card>
                  <CardColumn gap="20px">
                    <CardRow align="center" gap="10.6px">
                      <BsPersonCircle fontSize={32}></BsPersonCircle>
                      <CardTitle>Datos de Contacto</CardTitle>
                    </CardRow>
                    <CardRow>
                      <CardColumn gap="16px">
                        <CardRow gap="8px" align="center">
                          <AiOutlineMail fontSize={20}></AiOutlineMail>
                          <DataText>{user?.email}</DataText>
                        </CardRow>
                        <CardRow gap="8px" align="center">
                          <FiSmartphone fontSize={20}></FiSmartphone>
                          <DataText>{user?.phone}</DataText>
                        </CardRow>
                        <CardRow gap="8px" align="center">
                          <GoLocation fontSize={20}></GoLocation>
                          <DataText>{user?.location}</DataText>
                        </CardRow>
                        <CardRow gap="8px" align="center">
                          <AiFillLinkedin fontSize={20}></AiFillLinkedin>
                          <LinkText
                            href={user?.linkedinProfile}
                            target="_blank"
                          >
                            {user?.linkedinProfile}
                          </LinkText>
                        </CardRow>
                        <CardRow gap="8px" align="center">
                          <AiFillGithub fontSize={20}></AiFillGithub>
                          <LinkText href={user?.github} target="_blank">
                            {user?.github}
                          </LinkText>
                        </CardRow>
                        <CardRow gap="8px" align="center">
                          <TbWorld fontSize={20}></TbWorld>
                          <LinkText href={user?.website} target="_blank">
                            {user?.website}
                          </LinkText>
                        </CardRow>
                      </CardColumn>
                    </CardRow>
                  </CardColumn>
                  <EditButton modalName="edit-profile-contact"></EditButton>
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col sm={8}>
            <Card padding="20px">
              <h3 style={{ fontWeight: "500", marginBottom: "14px" }}>
                Vista previa de tu tarjeta
              </h3>
              <DataText>
                Completa la vista previa de tu tarjeta, que será publicada
                online en el Marketplace de CONSTANA, donde hay empresas que
                buscan perfiles como el que tienes tu. Si eres parte del co-hort
                de al menos una Academia y estas calificado, podrás seleccionar
                los analytics de ese co-hort en particular.
              </DataText>
              <div style={{ height: "24px" }}></div>
              <Formik
                initialValues={{
                  ...initialValues,
                  cohort: user.cohorts[0],
                }}
                onSubmit={(values, actions) => {}}
                validateOnChange={false}
                validationSchema={schema}
                validateOnBlur={false}
                enableReinitialize
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => {
                  return (
                    <form
                      onSubmit={(event) => {
                        handleSubmit(event);
                      }}
                      id="layout-search"
                    >
                      <Grid.ContainerFluid margin={1}>
                        <Grid.Row>
                          <Center>
                            <Grid.Col sm={5}>
                              <ReactSelect
                                name="academy"
                                error={errors["academy"]}
                                touched={touched["academy"]}
                                isClearable
                                {...(!!academies &&
                                  !!cohorts && {
                                    items: Array.from(
                                      new Set(
                                        cohorts
                                          ?.filter((cohort) =>
                                            cohort.studentsIds.includes(
                                              user._id
                                            )
                                          )
                                          .map((cohort) => cohort.academy)
                                      )
                                    ).map((academyM) => {
                                      const academyFounded = academies.find(
                                        (academy) =>
                                          academy._id === academyM._id
                                      );

                                      return {
                                        value: academyFounded?._id ?? "",
                                        label: academyFounded?.name ?? "",
                                      };
                                    }),
                                  })}
                                placeholder="Seleccione una academia"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                options={{
                                  skin: "base",
                                  label: "Academia",
                                  marginBottom: 20,
                                }}
                              />
                            </Grid.Col>
                            <Grid.Col sm={5}>
                              <ReactSelect
                                name="cohort"
                                isClearable
                                error={errors["cohort"]}
                                touched={touched["cohort"]}
                                {...(cohorts && {
                                  items: values.academy
                                    ? cohorts
                                        ?.filter(
                                          (cohort) =>
                                            cohort.active &&
                                            cohort.academy._id ===
                                              values.academy &&
                                            cohort.studentsIds.includes(
                                              user._id
                                            )
                                        )
                                        .map((cohort) => {
                                          return {
                                            value: cohort._id,
                                            label: cohort.name,
                                          };
                                        })
                                    : cohorts
                                        ?.filter(
                                          (cohort) =>
                                            cohort.active &&
                                            cohort.studentsIds.includes(
                                              user._id
                                            )
                                        )
                                        .map((cohort) => {
                                          return {
                                            value: cohort._id,
                                            label: cohort.name,
                                          };
                                        }),
                                })}
                                onOptionSelected={(newValue) => {
                                  if (newValue) {
                                    setCohortSelected(newValue.value);
                                    dispatch(
                                      makeShownCohort({ _id: newValue.value })
                                    );
                                  }
                                }}
                                placeholder="Seleccione un Cohort"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                options={{
                                  skin: "base",
                                  label: "Cohort",
                                  marginBottom: 20,
                                }}
                              />
                            </Grid.Col>
                          </Center>
                        </Grid.Row>
                      </Grid.ContainerFluid>
                      {!!cohortSelected && (
                        <Grid.Row>
                          <Grid.Col style={{ marginTop: "58px" }}>
                            <TalentCard cohort={cohortSelected}></TalentCard>
                          </Grid.Col>
                        </Grid.Row>
                      )}
                    </form>
                  );
                }}
              </Formik>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Grid.ContainerFluid>
      <EditProfileModal></EditProfileModal>
      <EditContactModal></EditContactModal>
      {user && <AddPasswordModal></AddPasswordModal>}
    </>
  );
};

const states = ({
  userStore,
  usersStore,
  academyStore,
  cohortStore,
  talentStore,
}) => {
  const { data: user } = userStore;
  const { states: updateUserStates } = userStore.update;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: users } = usersStore.all;
  const { data: academies } = academyStore.all;
  const { data: talents } = talentStore.allTalents;
  return {
    user,
    users,
    academies,
    cohorts,
    talents,
    updateUserStates,
  };
};

export default connect(states)(Component);

/*                    {color:"Violet"
                      style:{ height: "26px" }
                      options:{
                        type: "filled",
                        loading: "Violet",
                        skin: "violet",
                        size: "lg",
                        marginBottom: "0px",
                      } */
