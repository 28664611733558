// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.youtube-short {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.youtube-short.slide-in {
    animation-name: slideIn;
}

.youtube-short.slide-out {
    animation-name: slideOut;
}

@keyframes slideIn {
    0% {
        transform: translateY(120%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes slideOut {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(-120%);
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Byte/Player/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,sBAAsB;IACtB,6BAA6B;AACjC;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI;QACI,2BAA2B;IAC/B;;IAEA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI;QACI,yBAAyB;IAC7B;;IAEA;QACI,4BAA4B;IAChC;AACJ","sourcesContent":[".youtube-short {\n    position: relative;\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n    animation-duration: 1s;\n    animation-fill-mode: forwards;\n}\n\n.youtube-short.slide-in {\n    animation-name: slideIn;\n}\n\n.youtube-short.slide-out {\n    animation-name: slideOut;\n}\n\n@keyframes slideIn {\n    0% {\n        transform: translateY(120%);\n    }\n\n    100% {\n        transform: translateY(0%);\n    }\n}\n\n@keyframes slideOut {\n    0% {\n        transform: translateY(0%);\n    }\n\n    100% {\n        transform: translateY(-120%);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
