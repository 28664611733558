import styled from "styled-components";
import { TrashIcon } from "../../../Dashboard/Content/style";

export const BannerWrapper = styled("div")`
  border-radius: 10px;
  padding: 12px 12px 19px;
  box-shadow: 0 0.3px 4px 0 rgba(106, 105, 112, 0.25);
  background-color: #fff;
  margin-bottom: 15px;
`;

export const BannerImageContainer = styled("div")`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  height: 303px;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 0 16px 0;
  width: 100%;
`;

export const IconUpload = styled(TrashIcon)`
  & > input {
    visibility: hidden;
    width: 0;
    height: 0;
  }
`;

export const BannerAcademyPhoto = styled("div")`
  border-radius: 15px;
  min-width: 69px;
  max-width: 69px;
  height: 69px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(36, 150, 237, 0.25);
`;

export const BannerTag = styled("div")`
  padding: 1px 5px;
  border-radius: 5px;
  background-color: rgba(36, 150, 237, 0.15);
`;

export const BannerTitle = styled("h3")`
  font-size: ${(props) => props.size};
  font-weight: 500;
  color: ${(props) => props.theme.colors.Night};
  ${(props) =>
    props.hideLine
      ? `display: -webkit-box;
  -webkit-line-clamp: ${props.hideLine}; 
  -webkit-box-orient: vertical;
  overflow:hidden;
text-overflow: ellipsis;
white-space: normal;`
      : ""}
`;
export const BannerText = styled("p")`
  font-size: ${(props) => props.size};
  font-weight: 500;
  color: ${(props) => props.theme.colors.Gray};
`;
