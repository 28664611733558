const size = {
  sm: {
    fontSize: "14px",
    lineHeight: "30px",
    borderRadius: "15px",
  },
  md: {
    fontSize: "14px",
    lineHeight: "32px",
    borderRadius: "16px",
  },
  lg: {
    fontSize: "20px",
    lineHeight: "50px",
    borderRadius: "25px",
  },
};

export const link = {
  size,
};
