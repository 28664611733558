import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";

import { hideModal } from "../../../../../../store/actions/modal";

import {
  initialValues,
  schema,
} from "../../../../../../constants/form/suscription/change-suscription";

import Modal from "../../../../../../components/Modal";
import {
  Center,
  FlexContainer,
  Separator,
} from "../../../../../../components/StyledComponents";
import Submit from "../../../../../../components/Form/Submit";
import Button from "../../../../../../components/Button";
import Slider from "./components/Slider";
import { CardWrapper } from "./components/Slider/style";
import { Img } from "../../../../../SignIn/styles";

/* interface ComponentProps {
  updateUserStates: StateDto;
  user: UserDto;
} */

const Component = ({ onClose, setAction }) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [numberCard, setNumberCard] = useState(0);
  const dispatch = useDispatch();
  console.log(numberCard);

  const onSubmit = ({ values, actions }) => {
    /*  dispatch(forgotPassword({ _id: user._id, password: values.password })); */
    setAction({
      action: "confirm-change-purchase-method",
      modalName: "confirm-change-purchase-method",
      data: {},
    });
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  const selectCard = (oldIndex, newIndex) => {
    setNumberCard(newIndex);
  };

  return (
    <Modal
      onClose={onClose}
      description="El cambio se aplicara a partir del mes que viene."
      name="change-purchase-method"
      title="Cambio de metodo de pago para suscripcion mensual"
    >
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-event-form"
            >
              <h3>Tus tarjetas</h3>
              <Slider onCurrentSlide={selectCard}>
                <FlexContainer justify="center">
                  <FlexContainer justify="center">
                    <CardWrapper>
                      <Img
                        src={
                          "https://upload.wikimedia.org/wikipedia/commons/7/76/Seehund11cele4_edit.jpg"
                        }
                      ></Img>
                    </CardWrapper>
                  </FlexContainer>
                </FlexContainer>
                <FlexContainer justify="center">
                  <FlexContainer justify="center">
                    <CardWrapper>
                      <Img
                        src={
                          "https://upload.wikimedia.org/wikipedia/commons/7/76/Seehund11cele4_edit.jpg"
                        }
                      ></Img>
                    </CardWrapper>
                  </FlexContainer>
                </FlexContainer>
              </Slider>
              <Separator size={10}></Separator>
              <h3>Tus tarjetas</h3>
              <Slider onCurrentSlide={selectCard}>
                <FlexContainer justify="center">
                  <FlexContainer justify="center">
                    <CardWrapper>
                      <Img
                        src={
                          "https://upload.wikimedia.org/wikipedia/commons/7/76/Seehund11cele4_edit.jpg"
                        }
                      ></Img>
                    </CardWrapper>
                  </FlexContainer>
                </FlexContainer>
                <FlexContainer justify="center">
                  <FlexContainer justify="center">
                    <CardWrapper>
                      <Img
                        src={
                          "https://upload.wikimedia.org/wikipedia/commons/7/76/Seehund11cele4_edit.jpg"
                        }
                      ></Img>
                    </CardWrapper>
                  </FlexContainer>
                </FlexContainer>
              </Slider>
              <Separator size={10}></Separator>
              <Center>
                <Submit
                  isSubmmiting={false}
                  form="edit-event-form"
                  color="Violet"
                  options={{
                    type: "filled",
                    skin: "violet",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                >
                  Confirmar
                </Submit>
              </Center>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default Component;
