import { ThemeProvider } from "styled-components";
import { main } from "./skins/main";
import { GlobalStyles } from "./GlobalStyles";
import { DataTableGlobalStyles } from "./DataTableGlobalStyles";
import { CalendarGlobalStyles } from "./CalendarGlobalStyles";
import { connect } from "react-redux";

const themes: any = {
  main,
};

const Component = ({ portal, children, theme }: any) => {
  const currentTheme = { ...main, theme: main["themes"][theme] };

  return (
    <ThemeProvider theme={themes[portal] || currentTheme}>
      <GlobalStyles />
      <DataTableGlobalStyles />
      <CalendarGlobalStyles />
      {children}
    </ThemeProvider>
  );
};

const states = ({ themeStore }) => {
  const { theme } = themeStore;
  return { theme };
};

export default connect(states)(Component);
