import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import academyActions from "../../store/actions/academies";
import { getAllCohorts, resetGetAllCohorts } from "../../store/actions/cohort";
import invitationStore from "../../store/actions/invitation";
import contentActions from "../../store/actions/content";
import usersActions from "../../store/actions/users";
import certificatesActions from "../../store/actions/certificate";
import {
  getAllRequests,
  resetGetAllRequests,
} from "../../store/actions/request";
import { getTalents, resetGetAllTalents } from "../../store/actions/talent";
import { AcademyDto } from "../../types/academy.dto";
import { CohortDto } from "../../types/cohort.dto";
import { ContentDto } from "../../types/content.dto";
import { InvitationDto } from "../../types/invitation.dto";
import { RequestDto } from "../../types/request.dto";
import { TalentDto } from "../../types/talent.dto";
import Loader from "../Loader";
import { UsersDto } from "../../types/users.dto";
import { CertificateDto } from "../../types/certificate.dto";
import { CommentDto } from "../../types/comment.dto";
import commentActions from "../../store/actions/comment";
import { resetGetAllVideos } from "../../store/actions/video";
import { addView, userTime } from "../../store/actions/userTimes";
import useTrackTimeAndAPI from "../../hooks/useTrackTimeAndAPI";
import { UserDto } from "../../types/user.dto";
const Component = ({
  children,
  academy,
  academies,
  talents,
  invitations,
  requests,
  cohorts,
  contents,
  users,
  certificates,
  comments,
  user,
}: {
  children: JSX.Element;
  academy: AcademyDto;
  academies: AcademyDto[];
  talents: TalentDto[];
  invitations: InvitationDto[];
  requests: RequestDto[];
  cohorts: CohortDto[];
  contents: ContentDto[];
  users: UsersDto[];
  certificates: CertificateDto[];
  comments: CommentDto[];
  user: UserDto;
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  useEffect(() => {
    if (!!params.id) {
      dispatch(academyActions.getOne({ _id: params.id }));
    }
  }, [params.id]);

  useEffect(() => {
    if (academy && fetchOn) {
      const startTime = Date.now();
      return () => {
        dispatch(
          addView({
            id: academy._id,
            startTime,
            endTime: Date.now(),
            user: user._id,
            type: "ACADEMY",
          })
        );
      };
    }
  }, [academy, fetchOn]);
  useEffect(() => {
    if (!academy && !fetchOn) {
      setFetchOn(true);
    }
  }, [academy]);

  /*   const apiAction = (timeSpent) => {
    dispatch(
      userTime({ ...timeSpent, id: params.id, user: user._id, type: "ACADEMY" })
    ); // Dispatch action to update Redux store or make API request
  };
  useTrackTimeAndAPI(apiAction, params.id); */

  useEffect(() => {
    return () => {
      dispatch(resetGetAllVideos());
      dispatch(resetGetAllTalents());
      dispatch(invitationStore.resetGetAllInvitations());
      dispatch(resetGetAllRequests());
      dispatch(resetGetAllCohorts());
      dispatch(contentActions.resetGetAllContents());
      dispatch(usersActions.resetGetAllUsers());
      dispatch(certificatesActions.resetGetAllCertificates());
      dispatch(commentActions.resetGetAllComments());
      dispatch(academyActions.resetGetOneAcademy());
    };
  }, []);

  /* if (
    !academy ||
    !talents ||
    !invitations ||
    !requests ||
    !cohorts ||
    !comments
  ) {
    return <Loader color="LightBlue" />;
  }
 */
  return children;
};

const states = ({
  academyStore,
  talentStore,
  invitationStore,
  requestStore,
  cohortStore,
  contentStore,
  usersStore,
  certificateStore,
  commentStore,
  userStore,
}) => {
  const { data: academy } = academyStore.selected;
  const { data: academies } = academyStore.all;
  const { data: talents } = talentStore.allTalents;
  const { data: invitations } = invitationStore.all;
  const { data: requests } = requestStore.allRequests;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: contents } = contentStore.all;
  const { data: users } = usersStore.all;
  const { data: comments } = commentStore.all;
  const { data: certificates } = certificateStore.all;
  const { data: user } = userStore;
  return {
    academy,
    academies,
    talents,
    invitations,
    requests,
    cohorts,
    contents,
    users,
    certificates,
    comments,
    user,
  };
};

export default connect(states)(Component);
