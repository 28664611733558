import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center, Separator } from "../../../../../components/StyledComponents";
import { InLine } from "../../../../../components/Form/Field";
import Input from "../../../../../components/Form/Input";
import Submit from "../../../../../components/Form/Submit";
import { initialValues, schema } from "../../../../../constants/form/card/new";
import Button from "../../../../../components/Button";
import { hideModal } from "../../../../../store/actions/modal";
import Cards from "react-credit-cards";
import Modal from "../../../../../components/Modal";
import "react-credit-cards/es/styles-compiled.css";
interface ComponentProps {}

const Component = ({}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [focused, setFocused] = useState<any>("");
  const dispatch = useDispatch();
  const formatNumber = (value) => {
    const formattedValue = value.replace(/\s/g, "").replace(/(\d{4})/g, "$1 ");
    return formattedValue.trim();
  };

  const formatExpiry = (value) => {
    return value
      .replace(
        /[^0-9]/g,
        "" // To allow only numbers
      )
      .replace(
        /^([2-9])$/g,
        "0$1" // To handle 3 > 03
      )
      .replace(
        /^(1{1})([3-9]{1})$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^0{1,}/g,
        "0" // To handle 00 > 0
      )
      .replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
        "$1/$2" // To handle 113 > 11/3
      );
  };

  const formatCvc = (value) => {
    return value.slice(0, 3); // Limit the length to 3 characters
  };
  const onSubmit = ({ values, actions }) => {};

  const handleInputFocus = (ev) => {
    setFocused(ev.target.name);
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  /*  useEffect(() => {
    !abilities && dispatch(getAllAbilities());
  }, []); */

  /* useEffect(() => {
    if (newEventStates?.success) {
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetNewEvent());
      }, 2000);
    }
  }, [newEventStates]);
 */
  return (
    <Modal name="new-payment-card" title="Nueva Tarjeta">
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="new-event-form"
            >
              <Cards
                number={values["cardNumber"]}
                expiry={values["expirationDate"]}
                cvc={values["securityCode"]}
                name={values["cardholderName"]}
                focused={focused === "securityCode" ? "cvc" : focused}
              />
              <Separator size={12}></Separator>
              <Input
                name="cardNumber"
                error={errors["cardNumber"]}
                touched={touched["cardNumber"]}
                value={formatNumber(values["cardNumber"])}
                type="text"
                placeholder=""
                onChange={(ev) =>
                  handleChange({
                    ...ev,
                    target: {
                      ...ev.target,
                      name: "cardNumber",
                      value: ev.target.value.slice(0, 19),
                    },
                  })
                }
                onBlur={handleBlur}
                onFocus={handleInputFocus}
                options={{
                  label: "Numero de la tarjeta",
                  marginBottom: 20,
                }}
              />
              <Input
                name="cardholderName"
                error={errors["cardholderName"]}
                touched={touched["cardholderName"]}
                value={values["cardholderName"]}
                type="text"
                placeholder=""
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleInputFocus}
                options={{
                  label: "Nombre del Propietario",
                  marginBottom: 20,
                }}
              />

              {/* 
            <ReactSelect
              name="abilityId"
              error={errors["abilityId"]}
              touched={touched["abilityId"]}
              {...(!!currentTypeAbilities && {
                items: currentTypeAbilities?.map((ability) => ({
                  label: ability.name,
                  value: ability._id,
                })),
              })}
              placeholder="Selecciona una habilidad"
              disabled={!abilities}
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Habilidad",
                marginBottom: 20,
              }}
            /> */}

              <InLine column="1fr 1fr">
                {/* <Input
                name="startDate"
                error={errors["startDate"]}
                touched={touched["startDate"]}
                value={values["startDate"]}
                type="date"
                min={minInputDate()}
                placeholder="Fecha inicio"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  label: "Fecha inicio",
                  marginBottom: 20,
                }}
              /> */}
                <Input
                  name="securityCode"
                  error={errors["securityCode"]}
                  touched={touched["securityCode"]}
                  value={formatCvc(values["securityCode"])}
                  type="text"
                  placeholder="Codigo Seguridad"
                  onChange={(ev) =>
                    handleChange({
                      ...ev,
                      target: {
                        ...ev.target,
                        name: "securityCode",
                        value: formatCvc(ev.target.value),
                      },
                    })
                  }
                  onBlur={handleBlur}
                  onFocus={handleInputFocus}
                  options={{
                    label: "CVC",
                    marginBottom: 20,
                  }}
                />
                <Input
                  name="expirationDate"
                  error={errors["expirationDate"]}
                  touched={touched["expirationDate"]}
                  value={formatExpiry(values["expirationDate"])}
                  type="text"
                  placeholder="MM/YY"
                  onChange={(ev) =>
                    handleChange({
                      ...ev,
                      target: {
                        ...ev.target,
                        name: "expirationDate",
                        value: formatExpiry(ev.target.value),
                      },
                    })
                  }
                  onBlur={handleBlur}
                  onFocus={handleInputFocus}
                  options={{
                    label: "Fecha de Expiracion",
                    marginBottom: 20,
                  }}
                />
              </InLine>

              <Center>
                <Submit
                  isSubmmiting={false}
                  form="new-event-form"
                  color="Violet"
                  options={{
                    type: "filled",
                    skin: "violet",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                >
                  Confirmar
                </Submit>
                <Button
                  onClick={closeModal}
                  type="button"
                  options={{
                    type: "outline",
                    skin: "danger",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                  style={{ marginLeft: "50px" }}
                >
                  Cancelar
                </Button>
              </Center>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ abilityStore, eventStore }) => {
  return {};
};

export default connect(states)(Component);
