import { connect, useDispatch } from "react-redux";
import { Text } from "./styles";
import { Center } from "../../../../../../components/StyledComponents";
import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { AbilityDto } from "../../../../../../types/ability.dto";
import { hideModal } from "../../../../../../store/actions/modal";
import { useEffect } from "react";
import {
  updateAbility,
  resetUpdateAbility,
} from "../../../../../../store/actions/ability";

interface ComponentProps {
  ability: AbilityDto;
  updateAbilityStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ ability, updateAbilityStates }: ComponentProps) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(hideModal());
  };

  const deleteAbility = () => {
    dispatch(updateAbility({ ...ability, active: false }));
  };

  useEffect(() => {
    if (updateAbilityStates.success) {
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetUpdateAbility());
      }, 2000);
    }
  }, [updateAbilityStates]);

  return (
    <Modal name="delete-ability-modal" title="Eliminar Habilidad">
      <Text>
        ¿Estas seguro de eliminar la Habilidad <br />{" "}
        <strong>{ability?.name}</strong> ?
      </Text>
      <Center>
        <Submit
          form=""
          isSubmmiting={updateAbilityStates.loading}
          onClick={deleteAbility}
          type="button"
          style={{ marginRight: "10px" }}
        >
          Eliminar
        </Submit>
        <Button
          onClick={closeModal}
          type="button"
          options={{
            type: "outline",
            skin: "danger",
            size: "lg",
            marginBottom: "0px",
          }}
        >
          Cancelar
        </Button>
      </Center>
    </Modal>
  );
};

const states = ({ abilityStore }) => {
  const { data: ability } = abilityStore.ability;
  const { states: updateAbilityStates } = abilityStore.updateAbility;

  return {
    ability,
    updateAbilityStates,
  };
};

export default connect(states)(Component);
