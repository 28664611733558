import action from "../helpers/rest-client";

export class ErrorUser extends Error {}

class User {
  public getAll(payload) {
    if (payload.filterBy) {
      payload.filterBy = JSON.stringify(payload.filterBy);
    }
    if (payload.select) {
      payload.select = JSON.stringify(payload.select);
    }
    const queryString = new URLSearchParams(payload).toString();
    return action
      .Get({
        url: `/users?platform=CAMPUS${queryString ? "&" + queryString : ""}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getUsersByAcademy(payload) {
    if (payload.filterBy) {
      payload.filterBy = JSON.stringify(payload.filterBy);
    }
    if (payload.select) {
      payload.select = JSON.stringify(payload.select);
    }
    const queryString = new URLSearchParams(payload).toString();
    return action
      .Get({
        url: `/users/academy/${payload._id}?platform=CAMPUS${
          queryString ? "&" + queryString : ""
        }`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public update({ _id, ...rest }) {
    const formData = new FormData();

    for (let key in rest) {
      const currentElement = rest[key];

      if (key !== "picture" && currentElement) {
        formData.append(key, currentElement);
      }
    }

    for (let key in rest) {
      const currentElement = rest[key];
      if (key === "picture" && currentElement) {
        if (typeof currentElement !== "string") {
          formData.append(key, currentElement, currentElement.name);
        } else {
          formData.append(key, currentElement);
        }
      }
    }
    return action
      .Patch({
        url: `/users/${_id}`,
        body: formData,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const user = new User();
export default user;
