import Modal from "../../../../../../components/Modal";
import NewEventForm from "../../../New";

const Component = () => {
  return (
    <Modal name="new-event-modal" title="Nuevo Evento">
      <NewEventForm />
    </Modal>
  );
};

export default Component;
