import { CommentDto } from "../../types/comment.dto";

export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";
export const GET_ALL_COMMENTS_ERROR = "GET_ALL_COMMENTS_ERROR";
export const GET_ALL_COMMENTS_SUCCESS = "GET_ALL_COMMENTS_SUCCESS";
export const GET_COMMENT = "GET_COMMENT";
export const GET_COMMENT_ERROR = "GET_COMMENT_ERROR";
export const GET_COMMENT_SUCCESS = "GET_COMMENT_SUCCESS";
export const NEW_COMMENT = "NEW_COMMENT";
export const NEW_COMMENT_ERROR = "NEW_COMMENT_ERROR";
export const NEW_COMMENT_SUCCESS = "NEW_COMMENT_SUCCESS";
export const RESET_NEW_COMMENT = "RESET_NEW_COMMENT";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const UPDATE_COMMENT_ERROR = "UPDATE_COMMENT_ERROR";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const RESET_UPDATE_COMMENT = "RESET_UPDATE_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const RESET_DELETE_COMMENT = "RESET_DELETE_COMMENT";
export const SET_UPDATE_COMMENT_DATA = "SET_UPDATE_COMMENT_DATA";
export const SET_NEW_COMMENT_DATA = "SET_NEW_COMMENT_DATA";
export const UPDATE_CURRENT_COMMENT_LIST = "UPDATE_CURRENT_COMMENT_LIST";
export const SELECT_COMMENT = "SELECT_COMMENT";
export const UPDATE_COMMENT_ROLE = "UPDATE_COMMENT_ROLE";
export const DELETE_COMMENT_MEMBER = "DELETE_COMMENT_MEMBER";
export const MANAGE_LIKE_DISLIKE_COMMENT = "MANAGE_LIKE_DISLIKE_COMMENT";
export const RESET_GET_ALL_COMMENT_DATA = "RESET_GET_ALL_COMMENT_DATA";
export const RESET_GET_ONE_COMMENT_DATA = "RESET_GET_ONE_COMMENT_DATA";
export const GET_REPLIES = "GET_REPLIES";
export const GET_REPLIES_SUCCESS = "GET_REPLIES_SUCCESS";
export const GET_REPLIES_ERROR = "GET_REPLIES_ERROR";
export const RESET_GET_REPLIES = "RESET_GET_REPLIES";
export const RESET_GET_REPLIES_DATA = "RESET_GET_REPLIES_DATA";

export interface GetReplies {
  type: typeof GET_REPLIES;
  payload: any;
}

export interface GetRepliesSuccess {
  type: typeof GET_REPLIES_SUCCESS;
  payload: { replies: CommentDto[]; queueId: string };
}

export interface GetRepliesError {
  type: typeof GET_REPLIES_ERROR;
  payload: { error: string | boolean; queueId: string };
}

export interface ResetGetReplies {
  type: typeof RESET_GET_REPLIES;
  payload: null;
}

export interface ResetGetRepliesData {
  type: typeof RESET_GET_REPLIES_DATA;
  payload: null;
}

export interface ResetGetAllCommentData {
  type: typeof RESET_GET_ALL_COMMENT_DATA;
  payload: null;
}

export interface ResetGetOneCommentData {
  type: typeof RESET_GET_ONE_COMMENT_DATA;
  payload: null;
}

export interface ManageLikeDislikeComment {
  type: typeof MANAGE_LIKE_DISLIKE_COMMENT;
  payload: {
    _id: string;
    user: string;
    condition: "like" | "dislike";
    action: "add" | "remove";
  };
}

export interface SelectCOMMENT {
  type: typeof SELECT_COMMENT;
  payload: CommentDto;
}

export interface UpdateCurrentCommentList {
  type: typeof UPDATE_CURRENT_COMMENT_LIST;
  payload: CommentDto[];
}

export interface GetAllComments {
  type: typeof GET_ALL_COMMENTS;
  payload: null;
}

export interface GetAllCommentsSuccess {
  type: typeof GET_ALL_COMMENTS_SUCCESS;
  payload: CommentDto[];
}

export interface GetAllCommentsError {
  type: typeof GET_ALL_COMMENTS_ERROR;
  payload: boolean | string;
}

export interface GetComment {
  type: typeof GET_COMMENT;
  payload: { _id: string };
}

export interface GetCommentSuccess {
  type: typeof GET_COMMENT_SUCCESS;
  payload: CommentDto;
}

export interface GetCommentError {
  type: typeof GET_COMMENT_ERROR;
  payload: boolean | string;
}

export interface NewComment {
  type: typeof NEW_COMMENT;
  payload: CommentDto;
}

export interface NewCommentSuccess {
  type: typeof NEW_COMMENT_SUCCESS;
  payload: CommentDto;
}

export interface NewCommentError {
  type: typeof NEW_COMMENT_ERROR;
  payload: boolean | string;
}

export interface SetUpdateCommentData {
  type: typeof SET_UPDATE_COMMENT_DATA;
  payload: CommentDto;
}

export interface SetNewCommentData {
  type: typeof SET_NEW_COMMENT_DATA;
  payload: CommentDto;
}

export interface ResetNewComment {
  type: typeof RESET_NEW_COMMENT;
  payload: null;
}

export interface UpdateComment {
  type: typeof UPDATE_COMMENT;
  payload: CommentDto;
}

export interface UpdateCommentSuccess {
  type: typeof UPDATE_COMMENT_SUCCESS;
  payload: CommentDto;
}

export interface UpdateCommentError {
  type: typeof UPDATE_COMMENT_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateComment {
  type: typeof RESET_UPDATE_COMMENT;
  payload: null;
}

export interface DeleteComment {
  type: typeof DELETE_COMMENT;
  payload: { _id: string };
}

export interface DeleteCommentSuccess {
  type: typeof DELETE_COMMENT_SUCCESS;
  payload: null;
}

export interface DeleteCommentError {
  type: typeof DELETE_COMMENT_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteComment {
  type: typeof RESET_DELETE_COMMENT;
  payload: null;
}

export interface DeleteCommentMember {
  type: typeof DELETE_COMMENT_MEMBER;
  payload: { _id: string; userId: string };
}

export interface State {
  all: {
    data: CommentDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    data: CommentDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  replies: {
    data: null | CommentDto[];
    states: {
      data: string[];
      id: string | null;
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };

  update: {
    data: null | CommentDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  replies: {
    data: null,
    states: {
      data: [],
      id: null,
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllComments
  | GetAllCommentsSuccess
  | GetAllCommentsError
  | GetComment
  | GetCommentSuccess
  | GetCommentError
  | NewComment
  | NewCommentSuccess
  | NewCommentError
  | ResetNewComment
  | UpdateComment
  | UpdateCommentSuccess
  | UpdateCommentError
  | ResetUpdateComment
  | DeleteComment
  | DeleteCommentSuccess
  | DeleteCommentError
  | ResetDeleteComment
  | UpdateCurrentCommentList
  | ManageLikeDislikeComment
  | ResetGetAllCommentData
  | ResetGetOneCommentData
  | GetReplies
  | GetRepliesSuccess
  | GetRepliesError
  | ResetGetReplies
  | ResetGetRepliesData;
