import React, { useEffect, useRef, useState } from "react";
import {
  CommunityComments,
  CommunityCommentsWrapper,
  CommunityWrapper,
} from "./style";
import Button from "../../components/Button";
import { Formik } from "formik";
import Input from "../../components/Form/Input";
import { BiMessageRoundedAdd } from "react-icons/bi";
import {
  Center,
  FlexContainer,
  Separator,
} from "../../components/StyledComponents";
import Comment from "../../components/Comment";
import { connect, useDispatch } from "react-redux";
import { CommentDto } from "../../types/comment.dto";
import commentActions from "../../store/actions/comment";
import { initialValues, schema } from "../../constants/form/comment/create";
import { StateDto } from "../../types/states.dto";
import { toast } from "react-toastify";
import CenterTitle from "../CenterTitle";
import Loader from "../Loader";
import InfinityScroll from "../InfinityScroll";
import { HiPaperAirplane } from "react-icons/hi2";
import { useTheme } from "styled-components";
import services from "../../services";
interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  comments,
  newCommentStates,
  section,
  itemId,
  commentsStates,
  queueReplies,
  replies,
  currentReplyId,
  newComment,
  statesReplies,
  updateCommentStates,
  updatedComment,
}: {
  comments: CommentDto[];
  newCommentStates: StateDto;
  section: string;
  itemId: string;
  commentsStates: StateDto;
  queueReplies: string[];
  replies: CommentDto[];
  currentReplyId: string;
  newComment: CommentDto;
  statesReplies: any;
  updateCommentStates: StateDto;
  updatedComment: CommentDto;
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [currentComments, setCurrentComments] = useState<CommentDto[]>([]);
  const [currentReplyComments, setCurrentReplyComments] = useState<
    CommentDto[]
  >([]);
  const [alredyRepliesParent, setAlreadyRepliesParent] = useState<string[]>([]);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [hasNoMoreReplies, setHasNoMoreReplies] = useState<string[]>([]);
  const [totallyCommentsCount, setTotallyCommentsCount] = useState<number>();
  const inputRef: any = useRef(null);
  const dispatch = useDispatch();
  const theme = useTheme();

  const fetchCommentsCount = async () => {
    const response: any = await services.comment.getAll({
      filterBy: {
        active: true,
        replyTo: { $exists: false },
        [`${section}`]: { $exists: true },
        [`${section}`]: itemId,
      },
    });
    if (response.response) {
      setTotallyCommentsCount(response.response.length);
    }
  };

  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 9,
      offset: 9 * page,
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        replyTo: { $exists: false },
        [`${section}`]: { $exists: true },
        [`${section}`]: itemId,
      },
    };
    const sortBy = "createdAt";

    const sortDirection = "desc";
    dispatch(commentActions.getAll({ ...payload, sortBy, sortDirection }));
  };
  const onSubmit = ({ values, actions }) => {
    dispatch(commentActions.create({ ...values, [`${section}`]: itemId }));
    setFormActions(actions);
  };

  useEffect(() => {
    if (itemId) {
      fetchCommentsCount();
    }
  }, [itemId]);

  useEffect(() => {
    if (!!replies) {
      setCurrentReplyComments((state) => [
        ...state,
        ...replies.filter(
          (reply) => !state.find((item) => item._id === reply._id)
        ),
      ]);
    }
    /*   if (!!replies && replies.length === 0 && currentReplyId) {
      setHasNoMoreReplies((state) => [...state, currentReplyId]);
    } */
  }, [replies]);

  useEffect(() => {
    if (statesReplies.success && !!currentReplyId) {
      setAlreadyRepliesParent((state) => [...state, currentReplyId]);
    }
  }, [currentReplyId, statesReplies]);

  useEffect(() => {
    if (!isLoading && !!comments) {
      setCurrentComments((state) => [
        ...state,
        ...comments.filter((comment) => comment[section] === itemId),
      ]);
    }
    setThereAreItems(!!comments && comments?.length > 0);
  }, [comments, isLoading, itemId, section]);

  useEffect(() => {
    setIsLoading(commentsStates.loading);
  }, [commentsStates]);

  useEffect(() => {
    if (newCommentStates.success && newComment) {
      formActions?.resetForm();

      dispatch(commentActions.resetCreate());
      if (!!newComment.replyTo) {
        setCurrentReplyComments((state) =>
          state.find((el) => el._id === newComment._id)
            ? [...state]
            : [newComment, ...state]
        );
      } else {
        setTotallyCommentsCount((state) => (state ?? 0) + 1);
        setCurrentComments((state) =>
          state.find((el) => el._id === newComment._id)
            ? [...state]
            : [newComment, ...state]
        );
      }
    }
    if (newCommentStates.error) {
      toast.error(newCommentStates.error);
      dispatch(commentActions.resetCreate());
    }
  }, [newCommentStates, newComment]);

  useEffect(() => {
    if (updateCommentStates.success && !!updatedComment) {
      if (!!updatedComment.replyTo) {
        setCurrentReplyComments((state) => [
          ...state.map((item) =>
            item._id === updatedComment._id ? updatedComment : item
          ),
        ]);
      } else {
        setCurrentComments((state) => [
          ...state.map((item) =>
            item._id === updatedComment._id ? updatedComment : item
          ),
        ]);
      }
      dispatch(commentActions.resetUpdate());
    }
    if (updateCommentStates.error) {
      dispatch(commentActions.resetUpdate());
    }
  }, [updateCommentStates, updatedComment, dispatch]);

  const toggleReplyForm = () => {
    if (inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <CommunityWrapper>
      <h4
        style={{ fontSize: "12px", fontWeight: "500", color: "#051725" }}
      >{`Comentarios: ${totallyCommentsCount}`}</h4>
      <CommunityComments justify="center" style={{ flexDirection: "column" }}>
        <Separator size={10}></Separator>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validationSchema={schema}
          validateOnChange={true}
          validateOnBlur={false}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <form
                style={{ width: "100%" }}
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="layout-search"
              >
                {inputRef && (
                  <FlexContainer align="center" gap="12px">
                    <Input
                      forwardRef={inputRef}
                      name="text"
                      error={errors["text"]}
                      touched={touched["text"]}
                      value={values["text"]}
                      type="text"
                      inputStyles={{
                        fontSize: "12px",
                        fontWeight: "500",
                        height: "29px",
                      }}
                      placeholder="Agregar comentario"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        marginBottom: 0,
                        skin: "gray",
                      }}
                    />
                    <button
                      style={{
                        all: "unset",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      type="submit"
                    >
                      <HiPaperAirplane
                        color={theme.colors.LightBlue}
                        fontSize={20}
                      ></HiPaperAirplane>
                    </button>
                  </FlexContainer>
                )}
              </form>
            );
          }}
        </Formik>

        <Separator size={20}></Separator>

        <CommunityCommentsWrapper>
          <InfinityScroll
            action={fetchData}
            page={page}
            setPage={setPage}
            data={currentComments}
            active={thereAreItems}
            isLoading={isLoading}
            noMessage={true}
          >
            <FlexContainer direction="column">
              {currentComments.map((comment, key) => {
                const commentChildrens = currentReplyComments?.filter(
                  (com) =>
                    com.replyTo === comment._id && comment[section] === itemId
                );

                return (
                  <Comment
                    isLoading={queueReplies.includes(comment._id)}
                    hasNoMoreReplies={hasNoMoreReplies}
                    itemId={itemId}
                    section={section}
                    key={comment._id}
                    commentChilds={commentChildrens}
                    commentReplies={currentReplyComments}
                    className="child"
                    level={0}
                    comment={comment}
                    alredyRepliesParent={alredyRepliesParent}
                  />
                );
              })}
            </FlexContainer>
          </InfinityScroll>
        </CommunityCommentsWrapper>
      </CommunityComments>
    </CommunityWrapper>
  );
};

const states = ({ commentStore }) => {
  const { data: comments, states: commentsStates } = commentStore.all;
  const {
    data: queueReplies,
    id: currentReplyId,
    ...statesReplies
  } = commentStore.replies.states;
  const { states: newCommentStates, data: newComment } = commentStore.create;
  const { data: replies } = commentStore.replies;
  const { states: updateCommentStates, data: updatedComment } =
    commentStore.update;
  return {
    comments,
    newCommentStates,
    commentsStates,
    queueReplies,
    replies,
    currentReplyId,
    newComment,
    statesReplies,
    updateCommentStates,
    updatedComment,
  };
};

export default connect(states)(Component);
