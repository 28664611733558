import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center } from "../../../../components/StyledComponents";
import Input from "../../../../components/Form/Input";
import Submit from "../../../../components/Form/Submit";
import { initialValues, schema } from "../../../../constants/form/category/new";
import {
  newCategory,
  resetNewCategory,
} from "../../../../store/actions/category";
import Button from "../../../../components/Button";
import { hideModal } from "../../../../store/actions/modal";

interface ComponentProps {
  newCategoryStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ newCategoryStates }: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(newCategory(values));
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  useEffect(() => {
    if (newCategoryStates.success) {
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetNewCategory());
      }, 2000);
    }
  }, [newCategoryStates]);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validateOnChange={formSubmmited}
      validateOnBlur={false}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <form
            className="theme-form"
            onSubmit={(event) => {
              setFormSubmmited(true);
              handleSubmit(event);
            }}
            id="edit-event-form"
          >
            <Input
              name="name"
              error={errors["name"]}
              touched={touched["name"]}
              value={values["name"]}
              type="text"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Nombre de la categoria",
                marginBottom: 20,
              }}
            />

            <Center>
              <Submit
                isSubmmiting={isSubmitting}
                form="edit-event-form"
                color="Primary"
                options={{
                  type: "filled",
                  skin: "primary",
                  size: "lg",
                  marginBottom: "0px",
                }}
              >
                Confirmar
              </Submit>
              <Button
                onClick={closeModal}
                type="button"
                options={{
                  type: "outline",
                  skin: "danger",
                  size: "lg",
                  marginBottom: "0px",
                }}
                style={{ marginLeft: "10px" }}
              >
                Cancelar
              </Button>
            </Center>
          </form>
        );
      }}
    </Formik>
  );
};

const states = ({ categoryStore }) => {
  const { states: newCategoryStates } = categoryStore.newCategory;

  return {
    newCategoryStates,
  };
};

export default connect(states)(Component);
