import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Grid from "../../../../components/Grid";
import {
  DotContent,
  FlexContainer,
  Separator,
} from "../../../../components/StyledComponents";
import { Img } from "../../../SignIn/styles";
import {
  DropdownItem,
  DropdownMenu,
  ItemDataText,
  ItemTitle,
  ListItem,
  LogoSmallItem,
  NotificationBox,
  TextSmallItem,
  TitleSection,
} from "./style";
import { BiPaperPlane } from "react-icons/bi";
import { HiDocumentText, HiOutlineDocumentText } from "react-icons/hi";
import { RiDashboardFill, RiOrganizationChart } from "react-icons/ri";
import { FaGraduationCap } from "react-icons/fa";
import { TiStarburst } from "react-icons/ti";
import { BsFillImageFill } from "react-icons/bs";
import { AiFillCalendar } from "react-icons/ai";
import { Caret } from "../../../../components/Menu/styles";
import CarretDown from "../../../../assets/icons/menu/caret-down.svg";
import Icon from "../../../../components/Icon";
import { connect, useDispatch } from "react-redux";
import { InvitationDto } from "../../../../types/invitation.dto";
import { UserDto } from "../../../../types/user.dto";
import invitationActions from "../../../../store/actions/invitation";
import LoaderSpinner from "../../../../components/LoaderSpinner";
import { resetGetAllCohorts } from "../../../../store/actions/cohort";
import Loader from "../../../../components/Loader";
import { VideoDto } from "../../../../types/video.dto";
import {
  getAllVideos,
  resetGetAllVideos,
} from "../../../../store/actions/video";
import academyActions from "../../../../store/actions/academies";
import { DateFromNow } from "../../../../helpers/format-date";
import { MdAudiotrack } from "react-icons/md";
const Component = ({
  invitations,
  user,
  videos,
}: {
  invitations: InvitationDto[];
  user: UserDto;
  videos: VideoDto[];
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeDropdown, setActiveDropdown] = useState<string>();

  useEffect(() => {
    dispatch(invitationActions.getAll({ filterBy: { seen: false } }));
    const interval = setInterval(() => {
      dispatch(invitationActions.getAll({ filterBy: { seen: false } }));
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!videos) dispatch(getAllVideos({}));
  }, [videos, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohorts());
      dispatch(resetGetAllVideos());
      dispatch(academyActions.resetGetAllAcademies());
    };
  }, []);

  const menuItems = [
    {
      link: "/dashboard/personal/myinvitations",
      label: "Invitaciones",
      extraIcon: (
        <NotificationBox>
          {invitations ? (
            invitations?.filter(
              (invitation) =>
                invitation.status === "PENDING" &&
                !!invitation.active &&
                invitation.email === user.email
            ).length
          ) : (
            <LoaderSpinner size={"10px"}></LoaderSpinner>
          )}
        </NotificationBox>
      ),
      icon: <BiPaperPlane size={16} color={"#2a2747"}></BiPaperPlane>,
    },
    {
      link: "/dashboard/personal/mycdocs",
      label: "Mis Docs",
      icon: <HiDocumentText size={16} color={"#2a2747"}></HiDocumentText>,
    },
    {
      link: "/dashboard/personal/myroadmaps",
      label: "Mis Trayectorias",
      icon: (
        <RiOrganizationChart size={16} color={"#2a2747"}></RiOrganizationChart>
      ),
    },
    {
      link: "/dashboard/personal/myacademies",
      label: "Mis Academias",
      icon: <FaGraduationCap size={16} color={"#2a2747"}></FaGraduationCap>,
    },
    {
      link: "/dashboard/personal/mycertificates",
      label: "Mis Certificaciones",
      icon: <TiStarburst size={16} color={"#2a2747"}></TiStarburst>,
    },
    /*  {
      link: "/dashboard/personal/myanalytics",
      label: "Mis Analytics",
      icon: (
        <BsFillBarChartFill size={16} color={"#2a2747"}></BsFillBarChartFill>
      ),
    }, */
    {
      link: "/dashboard/personal/mycohorts",
      label: "Mis Cohorts",
      icon: <FaGraduationCap size={16} color={"#2a2747"}></FaGraduationCap>,
    },
    {
      link: "/dashboard/personal/mydashboards",
      label: "Mis Dashboards",
      icon: <RiDashboardFill size={16} color={"#2a2747"}></RiDashboardFill>,
      children: [
        { link: "/professional", label: "Profesional" },
        { link: "/education", label: "Educacion" },
      ],
    },
    {
      link: "/dashboard/personal/mycalendar",
      label: "Mi Calendario",
      icon: <AiFillCalendar size={16} color={"#2a2747"}></AiFillCalendar>,
    },
  ];
  const discoverItems = [
    {
      link: "/dashboard/cohorts?filter=private&filterValue=false",
      label: "Cohort Publicos",
      icon: <FaGraduationCap size={16} color={"#2a2747"}></FaGraduationCap>,
    },

    {
      link: "/dashboard/images",
      label: "Imagenes",
      icon: <BsFillImageFill size={16} color={"#2a2747"}></BsFillImageFill>,
    },
    {
      link: "/dashboard/cdocs",
      label: "Cdocs",
      icon: (
        <HiOutlineDocumentText
          size={16}
          color={"#2a2747"}
        ></HiOutlineDocumentText>
      ),
    },
    {
      link: "/dashboard/roadmaps",
      label: "Roadmaps",
      icon: (
        <RiOrganizationChart size={16} color={"#2a2747"}></RiOrganizationChart>
      ),
    },
    {
      link: "/dashboard/listens",
      label: "Listens",
      icon: <MdAudiotrack size={16} color={"#2a2747"}></MdAudiotrack>,
    },
  ];

  if (!videos) return <Loader color="LightBlue"></Loader>;

  const firstVideo = videos.filter(
    (video) => !!video.active && !!video.published && !!video.available
  )[0];

  return (
    <Grid.Container>
      {firstVideo ? (
        <Grid.Row>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/dashboard/videos/${firstVideo._id}`);
            }}
          >
            <Grid.Col>
              <Grid.Row>
                <Grid.Col>
                  <TitleSection btm={10}>Videos Recientes</TitleSection>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <LogoSmallItem height="90px" width="160px">
                    <Img src={firstVideo.picture}></Img>
                  </LogoSmallItem>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <ItemTitle>{firstVideo.name}</ItemTitle>
                  <Separator size={4}></Separator>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <TextSmallItem>{firstVideo.academy.name}</TextSmallItem>
                  <Separator size={1}></Separator>
                </Grid.Col>
              </Grid.Row>
              <FlexContainer align="center" gap="5px">
                <FlexContainer align="center" gap="5px">
                  <DotContent></DotContent>
                  <ItemDataText>{`Vistas: ${firstVideo.viewCount}`}</ItemDataText>
                </FlexContainer>

                <FlexContainer align="center" gap="5px">
                  <DotContent></DotContent>
                  <ItemDataText>{`Publicado: ${DateFromNow(
                    firstVideo.createdAt
                  )}`}</ItemDataText>
                </FlexContainer>
              </FlexContainer>
            </Grid.Col>
          </div>
        </Grid.Row>
      ) : (
        <h2>No hay videos recientes</h2>
      )}
      <Separator size={12}></Separator>
      <Grid.Row>
        <Grid.Col>
          <Grid.Row>
            <Grid.Col>
              <TitleSection btm={6}>Descubre mas:</TitleSection>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              {discoverItems.map((item) => {
                return (
                  <NavLink
                    style={{
                      display: "block",
                      textDecoration: "none",
                      marginBottom: "5px",
                    }}
                    to={item.link}
                  >
                    <ListItem>
                      <FlexContainer justify="space-between" align="center">
                        <FlexContainer align="center" gap="6px">
                          {!!item.icon && item.icon}
                          <TextSmallItem>{item.label}</TextSmallItem>
                        </FlexContainer>
                      </FlexContainer>
                    </ListItem>
                  </NavLink>
                );
              })}
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      <Separator size={12}></Separator>
      <Grid.Row>
        <Grid.Col>
          <Grid.Row>
            <Grid.Col>
              <TitleSection btm={6}>Mis Recursos</TitleSection>
            </Grid.Col>
          </Grid.Row>
          <FlexContainer direction="column" gap="6px">
            {menuItems.map((item) => {
              return !!item.children ? (
                <>
                  <ListItem
                    className={
                      `${item.link}` === activeDropdown ? "active" : ""
                    }
                    onClick={(ev) => {
                      setActiveDropdown(
                        `${item.link}` === activeDropdown ? "" : item.link
                      );
                    }}
                  >
                    <FlexContainer justify="space-between" align="center">
                      <FlexContainer align="center" gap="6px">
                        {item.icon}
                        <TextSmallItem>{item.label}</TextSmallItem>
                      </FlexContainer>
                      <Caret className="caret">
                        <Icon icon={CarretDown} color="#697482" size="16px" />
                      </Caret>
                    </FlexContainer>
                  </ListItem>
                  <DropdownMenu
                    amountItems={item.children.length}
                    isActiveDropdown={`${item.link}` === activeDropdown}
                    style={{
                      maxWidth: "100px",
                    }}
                  >
                    {item.children.map((child) => (
                      <NavLink
                        style={{
                          textDecoration: "none",
                          paddingLeft: "20px",
                        }}
                        to={`${item.link}${child.link}`}
                      >
                        <DropdownItem>{child.label}</DropdownItem>
                      </NavLink>
                    ))}
                  </DropdownMenu>
                </>
              ) : (
                <NavLink style={{ textDecoration: "none" }} to={item.link}>
                  <ListItem>
                    <FlexContainer justify="space-between" align="center">
                      <FlexContainer align="center" gap="6px">
                        {!!item.icon && item.icon}
                        <TextSmallItem>{item.label}</TextSmallItem>
                      </FlexContainer>
                      {!!item.extraIcon && item.extraIcon}
                    </FlexContainer>
                  </ListItem>
                </NavLink>
              );
            })}
          </FlexContainer>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

const states = ({
  invitationStore,
  userStore,
  cohortStore,
  videoStore,
  academyStore,
  talentStore,
}) => {
  const { data: invitations } = invitationStore.all;
  const { data: user } = userStore;
  const { data: videos } = videoStore.all;
  const { data: talents } = talentStore.allTalents;
  return {
    invitations,
    user,
    videos,
    talents,
  };
};

export default connect(states)(Component);
