import styled from "styled-components";

export const ActionButton = styled("button")`
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin: 0px 16px;

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }
`;

export const NewButton = styled("button")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background-color: white;
  border: 0px;
  border-radius: 100%;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 20%);
  float: right;
  cursor: pointer;
`;
