import styled from "styled-components";

export const ToolTipWrapper = styled("div")`
  display: none;
  position: absolute;
  background-color: #051725;
  text-align: center;
  color: #ffffff;
  padding: 5px 6px 6px;

  border-radius: 5px;
  left: 50%; /* Centrar horizontalmente el tooltip */
  transform: translateX(-50%) translateY(0); /* Moverlo arriba y centrarlo horizontalmente */
  top: 40px;
  box-shadow: 0 0 4px 0 rgba(5, 23, 37, 0.25);
  animation: fadeInDown 0.3s ease-in-out;
  &:after {
    content: "";
    position: absolute;
    top: -9px; /* Ajusta la posición del triángulo en la parte superior del tooltip */
    left: 50%;
    transform: translateX(-50%); /* Centra el triángulo horizontalmente */
    border-width: 5px; /* Tamaño del triángulo */
    border-style: solid;
    border-color: transparent transparent #333 transparent; /* Color del triángulo y borde transparente en la parte superior */
  }

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateX(-50%) translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }
`;
